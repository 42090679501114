import * as stagesTraffic from "../../Stages/StagesTraffic";
import { AbstractCase } from "../../../types/AbstractCase";

export const trafficRequiredFields = {
  [stagesTraffic.STAGE_INCOMPLETE]: (traffic: AbstractCase) => [],
  [stagesTraffic.STAGE_READY]: (traffic: AbstractCase) => [],
  [stagesTraffic.STAGE_CHECKED]: (traffic: AbstractCase) => {
    let requiredFields = ["paymentType", "responsibleAuthority", "authorityNotification"];

    if (!traffic.processStoppedDate) {
      requiredFields.push("fineNoticeReference");
    }

    if (traffic.legalExpenseInsurance === true || traffic.insuranceRequested === "yes") {
      requiredFields.push("insurance.deductible", "insurance.insurance", "insurance.insurancePolicyNumber");
    }
    return requiredFields;
  },
  [stagesTraffic.STAGE_AUTHORIZED]: (traffic: AbstractCase) =>
    trafficRequiredFields[stagesTraffic.STAGE_CHECKED](traffic),
  [stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST]: (traffic: AbstractCase) => ["finalFineAmount"],
  [stagesTraffic.STAGE_ACCESS_TO_RECORDS]: (traffic: AbstractCase) => {
    let requiredFields = ["responsibleAuthority", "finalFineAmount"];
    if (!traffic.processStoppedDate) {
      requiredFields.push("fineNoticeReceivedDate");
      requiredFields.push("fineNoticeDate");
    }
    return requiredFields;
  },
  [stagesTraffic.STAGE_EXTRAJUDICIAL_PAID]: (traffic: AbstractCase) => {
    let requiredFields = ["responsibleAuthority"];
    if (traffic.paymentType === "insurance") {
      requiredFields.push("insuranceCoverageDate");
    }
    return requiredFields;
  },
  [stagesTraffic.STAGE_OPINION_SUBMITTED]: (traffic: AbstractCase) => {
    let requiredFields = ["responsibleAuthority", "opinionRequired"];

    if (traffic.opinionRequired) {
      requiredFields.push("opinionSubmitted");
    }
    return requiredFields;
  },
  [stagesTraffic.STAGE_AUTHORITY_ANSWERED]: (traffic: AbstractCase) => {
    return ["authorityAnswered", "authorityDecision", "responsibleAuthority", "opinionRequired"];
  },
  [stagesTraffic.STAGE_TRIAL]: (traffic: AbstractCase) => {
    return ["trialWanted", "responsibleCourtAddress.postalCode", "responsibleAuthority"];
  },
  [stagesTraffic.STAGE_TRIAL_DATE_SET]: (traffic: AbstractCase) => {
    return ["courtNotice", "courtReference", "responsibleAuthority"];
  },
  [stagesTraffic.STAGE_TRIAL_PAID]: (traffic: AbstractCase) => {
    if (traffic.paymentType !== "private") {
      return [];
    }

    return ["responsibleAuthority"];
  },
  [stagesTraffic.STAGE_DECISION_MADE]: (traffic: AbstractCase) => {

    if (traffic.processStoppedDate) {
      return [];
    }

    let requiredFields = ["responsibleAuthority"];

    if (traffic.legalExpenseInsurance === true || traffic.insuranceRequested === "yes") {
      requiredFields.push("insurance.deductible");
    }

    if (traffic.courtNotice === "trial" && traffic.appealRevoked === null) {
      requiredFields.push("courtDecisionDate");
    }

    if (traffic.paymentType !== "private") {
      requiredFields.push("insurance.caseReferenceNumber");
    }

    return requiredFields;
  },
  [stagesTraffic.STAGE_COMPLETED]: (traffic: AbstractCase) => {
    return ["responsibleAuthority"];
  },
};
