import { useDocumentClassLabelAi } from "../../provider/DocumentClassLabelAiProvider";
import React, { useEffect, useState } from "react";
import { AutoCompleteOptionWithValue } from "../../types/AutoCompleteOptionWithValue";
import { useActionIdentifier } from "../../provider/ActionIdentifierProvider";
import Grid from "@mui/material/Grid";
import { MediaObject } from "../../types/MediaObject";
import { AutocompleteElement, SelectElement, TextFieldElement, useFormContext } from "react-hook-form-mui";
import { requiredFieldDefault } from "../../services/reactHookFormValidationRules";

type MediaObjectLabelAndActionFormProps = {
  productClassName: string;
  mediaObject?: MediaObject;
  showExtraFields: boolean;
};

export default function MediaObjectLabelAndActionForm({
  productClassName,
  mediaObject,
  showExtraFields,
}: MediaObjectLabelAndActionFormProps) {
  const [documentClassLabelAiOptions, setDocumentClassLabelAiOptions] = useState<AutoCompleteOptionWithValue[]>([]);
  const [actionIdentifierOptions, setActionIdentifierOptions] = useState<AutoCompleteOptionWithValue[]>([]);
  const formContext = useFormContext();
  const {
    getDocumentClassLabelsAiByProductClass,
    prepareAndSortLabels,
    isLoading: isLoadingDocumentClassLabels,
  } = useDocumentClassLabelAi();

  const {
    getActionIdentifiersByLabel,
    prepareAndSortActionIdentifiers,
    isLoading: isLoadingActionIdentifiers,
  } = useActionIdentifier();

  useEffect(() => {
    const newDocumentClassLabelAiOptions = prepareAndSortLabels(
      getDocumentClassLabelsAiByProductClass(productClassName)
    );
    setDocumentClassLabelAiOptions(newDocumentClassLabelAiOptions);
    if (mediaObject?.documentClassLabelAi) {
      formContext.setValue(
        "documentClassLabelAi",
        newDocumentClassLabelAiOptions.find((option) => option.value === mediaObject.documentClassLabelAi) ?? null
      );
    }
    if (mediaObject?.actionIdentifier) {
      formContext.setValue(
        "actionIdentifier",
        prepareAndSortActionIdentifiers(getActionIdentifiersByLabel(mediaObject.documentClassLabelAi!)).find(
          (option) => option.value === mediaObject.actionIdentifier
        ) ?? null
      );
    }
  }, [isLoadingDocumentClassLabels, isLoadingActionIdentifiers]);

  useEffect(() => {
    if (!formContext.watch("documentClassLabelAi")) {
      return;
    }
    let labelSpecificIdentifiers = prepareAndSortActionIdentifiers(
      getActionIdentifiersByLabel(formContext.watch("documentClassLabelAi")?.value)
    );
    setActionIdentifierOptions(labelSpecificIdentifiers);
    if (labelSpecificIdentifiers.find((option) => option.value === formContext.watch("actionIdentifier")?.value)) {
      return;
    }
    formContext.setValue(
      "actionIdentifier",
      labelSpecificIdentifiers.length === 1 ? labelSpecificIdentifiers[0] : null
    );
  }, [formContext.watch("documentClassLabelAi"), isLoadingActionIdentifiers, mediaObject]);

  return (
    <>
      <Grid item xs={6}>
        <AutocompleteElement
          name="documentClassLabelAi"
          options={documentClassLabelAiOptions}
          label={"Dokumentenart"}
          rules={{ ...requiredFieldDefault }}
          autocompleteProps={{
            isOptionEqualToValue: (option: AutoCompleteOptionWithValue, value: AutoCompleteOptionWithValue) =>
              option.value === value?.value,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteElement
          name="actionIdentifier"
          options={actionIdentifierOptions}
          label={"Aktionskenner"}
          autocompleteProps={{
            disabled: formContext.watch("documentClassLabelAi")?.value === "NO_LABEL",
            isOptionEqualToValue: (option: AutoCompleteOptionWithValue, value: AutoCompleteOptionWithValue) =>
              option.value === value?.value,
          }}
          rules={{ ...requiredFieldDefault }}
        />
      </Grid>
      {showExtraFields && (
        <>
          <Grid item xs={12}>
            <TextFieldElement name={"description"} label={"Beschreibungstext"} rules={{ ...requiredFieldDefault }} />
          </Grid>
          <Grid item xs={7}>
            <SelectElement
              name={"showInGallery"}
              label={"Anzeige für Mandantschaft"}
              rules={{ ...requiredFieldDefault }}
              options={[
                { id: "true", label: "Ja" },
                { id: "false", label: "Nein" },
              ]}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectElement
              name={"electronicFileFolderPath"}
              label={"Ablage in Ordner"}
              rules={{ ...requiredFieldDefault }}
              options={[
                { id: "Mandantschaft", label: "Mandantschaft" },
                { id: "Gerichtliches Verfahren", label: "Gerichtliches Verfahren" },
                { id: "Außergerichtliches Verfahren", label: "Außergerichtliches Verfahren" },
                { id: "RSV", label: "RSV" },
                { id: "ToDo", label: "ToDo" },
                { id: "Rechnungen", label: "Rechnungen" },
                { id: "Sonstiges", label: "Sonstiges" },
              ]}
            />
          </Grid>
        </>
      )}
    </>
  );
}
