import React, { useMemo } from "react";
import _ from "lodash";
import {
  getDeadlinesActivitiesSet,
  getFullActivitiesSet,
  transformActivitySetToAutoCompleteOptions,
} from "../../../../services/Activities/activityTypes";
import LegalbirdAutoComplete from "../../../AutoComplete/LegalbirdAutoComplete";
import { AutoCompleteOptionWithId } from "../../../../types/AutoCompleteOptionWithId";

type ActivityTypeFilterProps = {
  activityTypeFilter: string;
  setActivityTypeFilter: Function;
  isDeadlineView: boolean;
};
const ActivityTypeFilter = ({ activityTypeFilter, setActivityTypeFilter, isDeadlineView }: ActivityTypeFilterProps) => {
  const activityTypes = useMemo(() => {
    return transformActivitySetToAutoCompleteOptions(
      isDeadlineView ? getDeadlinesActivitiesSet() : getFullActivitiesSet()
    );
  }, []);

  const activityTypeAutoCompleteOptions = [
    isDeadlineView
      ? {
          label: "Alle Fristen",
          id: "allDeadlines",
        }
      : { label: "Alle Aktivitäten", id: "all" },
    ...activityTypes,
  ];

  const activityTypeAutoCompleteValue = _.find(
    activityTypeAutoCompleteOptions,
    (activityType) => activityType.id === activityTypeFilter
  );

  return (
    <LegalbirdAutoComplete<AutoCompleteOptionWithId>
      setValue={(e, value) => {
        if (!value) {
          return;
        }
        setActivityTypeFilter(value.id);
      }}
      value={activityTypeAutoCompleteValue}
      options={activityTypeAutoCompleteOptions}
      label={"Aktivitätstyp"}
    />
  );
};

export default ActivityTypeFilter;
