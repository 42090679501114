import { Box, CircularProgress, Grid } from "@mui/material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { displayMediaObjectStyles } from "./displayMediaObjectStyle";
import IconButton from "@mui/material/IconButton";
import { ZoomIn, ZoomOut, ZoomOutMap } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {MediaObject} from "../../types/MediaObject";

type DisplayMediaObjectContentImageProps = {
  mediaObject: MediaObject;
  displayUrl: string;
  mediaRotation: number;
};
export default function DisplayMediaObjectContentImage({ mediaObject, displayUrl, mediaRotation }: DisplayMediaObjectContentImageProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, [mediaObject]);

  return (
    <Grid container justifyContent={"center"}>
      <Grid item>
        <TransformWrapper
          wheel={{
            disabled: true,
          }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              {isLoading && (
                <Box sx={displayMediaObjectStyles.loadingWrapper}>
                  <CircularProgress />
                </Box>
              )}
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <IconButton onClick={() => zoomIn()} size="large">
                    <ZoomIn />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => zoomOut()} size="large">
                    <ZoomOut />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => resetTransform()} size="large">
                    <ZoomOutMap />
                  </IconButton>
                </Grid>
              </Grid>
              <TransformComponent>
                <img
                  onLoad={() => setIsLoading(false)}
                  src={displayUrl}
                  alt={mediaObject.originalName}
                  style={{ ...displayMediaObjectStyles.image, transform: `rotate(${mediaRotation}deg)` }}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Grid>
    </Grid>
  );
}
