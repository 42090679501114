import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import * as stagesCase from "../../Stages/StagesCase";
import _ from "lodash";

const serviceProductSpecificDefinition = (product) => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.reasonForDispute`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "costEstimateExceeded" },
      { value: "unfinishedWork" },
      { value: "hasDefects" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.serviceTitle`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorNumberField",
    path: `${product.productClassName}.costEstimate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.reasonForDispute !== "costEstimateExceeded",
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.costEstimateAdjustment`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.reasonForDispute !== "costEstimateExceeded",
    options: () => [{ value: "notAtAll" }, { value: "asap" }, { value: "tooLate" }],
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.costEstimateAdjustmentDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) =>
      product.reasonForDispute !== "costEstimateExceeded" ||
      !_.includes(["asap", "tooLate"], product.costEstimateAdjustment),
  },
  {
    type: "ValidatorNumberField",
    path: `${product.productClassName}.costReal`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.defectsDescriptionClient`,
    isDisabled: () => true,
    isHidden: ({ product }) => !_.includes(["hasDefects", "unfinishedWork"], product.reasonForDispute),
    additionalProps: {
      multiline: true,
      fullWidth: true,
    },
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.numberRepairAttempts`,
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
    isHidden: ({ product }) => !_.includes(["hasDefects", "unfinishedWork"], product.reasonForDispute),
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
];

export default serviceProductSpecificDefinition;
