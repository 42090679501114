import React from "react";
import { Activity } from "../../../types/Activity";
import Note from "../../Case/CaseNote/Note";

type ActivityNoteProps = {
  activity: Activity;
};

const convertActivityToNote = (activity: Activity) => {
  return {
    isWarning: false,
    //display activitytype instead of author
    created: activity.created,
    updated: activity.updated,
    content: activity.note || "",
  };
};

export default function ActivityNote({ activity }: ActivityNoteProps) {
  return <Note note={convertActivityToNote(activity)} isEditable={false} />;
}
