import React, { useState } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { MediaObject } from "../../types/MediaObject";

type ChangeMediaObjectDescriptionModalProps = {
  mediaObject: MediaObject;
  updateMediaObjects: Function;
  changeDescriptionOpen: boolean;
  setChangeDescriptionOpen: Function;
};

export default function ChangeMediaObjectDescriptionModal({
  mediaObject,
  updateMediaObjects,
  changeDescriptionOpen,
  setChangeDescriptionOpen,
}: ChangeMediaObjectDescriptionModalProps) {
  const [description, setDescription] = useState(mediaObject.description || "");
  const updateMutation = useMutation(updateResource);

  const saveDescription = () => {
    updateMutation
      .mutateAsync({
        uri: "media_objects",
        id: mediaObject.id,
        data: {
          description: description,
        },
      })
      .then(() => updateMediaObjects());
    setChangeDescriptionOpen(false);
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setChangeDescriptionOpen(false);
      }}
      open={changeDescriptionOpen}
      title={"Beschreibungstext ändern"}
      submitButton={
        <Button disabled={description.length < 5} variant={"contained"} onClick={saveDescription}>
          Speichern
        </Button>
      }
    >
      <TextField
        value={description}
        onChange={({ target }) => setDescription(target.value)}
        inputProps={{ maxLength: 150 }}
      />
    </LegalbirdIoModal>
  );
}
