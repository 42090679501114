import _ from "lodash";
import { Navigate, useLocation } from "react-router-dom";
import React, { ReactElement } from "react";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { Roles } from "../../types/BackofficeUser";

type RequireRoleProps = {
  children: ReactElement;
  allowedRoles: Roles[];
  redirectTo?: string;
};

export default function RequireRole({ children, allowedRoles, redirectTo = "/login" }: RequireRoleProps) {
  const currentUser = useCurrentUser();
  const location = useLocation();
  if (!currentUser || _.intersection(currentUser.roles, allowedRoles).length === 0) {
    return <Navigate to={redirectTo} state={{ from: location }} />;
  }
  return children;
}
