import React from "react";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import Button from "@mui/material/Button";
import { CurrentRefinementsProvided } from "react-instantsearch-core";

type LegalbirdClearRefinementsProps = Pick<CurrentRefinementsProvided, "refine" | "items">;
const LegalbirdClearRefinements = ({ items, refine }: LegalbirdClearRefinementsProps) => {
  return (
    <Button onClick={() => refine(items)} disabled={!items.length}>
      Filter entfernen
    </Button>
  );
};

export default connectCurrentRefinements(LegalbirdClearRefinements);
