import personTranslations from "./personTranslations";

const insuranceTranslations = {
  insurancePolicyNumber: {
    label: "Versicherungsnummer",
  },
  caseReferenceNumber: {
    label: "Schadennummer",
  },
  deductible: {
    label: "Selbstbehalt",
  },
  extrajudicialCoverageRequestedDate: {
    label: "Deckung a.g. angefragt am",
  },
  extrajudicialCoverageRequestAnsweredDate: {
    label: "Antwort RSV außergerichtlich",
  },
  extrajudicialCoverageGranted: {
    label: "Deckung außergerichtlich",
  },
  judicialCoverageRequestedDate: {
    label: "Deckung gerichtl. angefragt am",
  },
  judicialCoverageRequestAnsweredDate: {
    label: "Antwort RSV gerichtlich",
  },
  judicialCoverageGranted: {
    label: "Deckung gerichtlich",
  },
  policyHolderType: {
    label: "Selbst oder mitversichert",
    values: {
      me: "Selbst versichert",
      familyMember: "Mitversichert",
    },
  },
  policyHolder: {
    ...personTranslations,
    gender: {
      label: "Anrede VN",
      values: {
        male: "Herr",
        female: "Frau",
      },
    },
    givenName: {
      label: "Vorname VN",
    },
    familyName: {
      label: "Nachname VN",
    },
  },
  insurance: {
    label: "Versicherungsgesellschaft",
  },
};

export default insuranceTranslations;
