import React, { createContext, useContext } from "react";
import { sortAlphabetically } from "../services/backofficeUserService";
import _ from "lodash";
import { BackofficeUser, Roles } from "../types/BackofficeUser";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../services/ReactQuery/reactQueryService";

interface BackofficeUserProviderData {
  backofficeUsers: Array<BackofficeUser>;
  accountManagers: Array<BackofficeUser>;
  lawyers: Array<BackofficeUser>;
  agents: Array<BackofficeUser>;
  internals: Array<BackofficeUser>;
  externals: Array<BackofficeUser>;
  systemUser: BackofficeUser | undefined;
  isLoading: boolean;
  getUserData: (iri: string | null) => BackofficeUser | undefined;
}

const BackofficeUserProviderContext = createContext<BackofficeUserProviderData | null>(null);

export default function BackofficeUserProvider({ children }: { children?: React.ReactNode }) {
  const { data: backofficeUserResponse, isLoading } = useQuery(queryKeys.collection("backoffice_users"), () =>
    fetchCollection("backoffice_users")
  );
  const backofficeUserFullList = !!backofficeUserResponse ? backofficeUserResponse["hydra:member"] : [];
  const backofficeUserList = _.filter(
    backofficeUserFullList,
    (backofficeUser: BackofficeUser) => backofficeUser.status === "active"
  );

  const accountManagers = backofficeUserList
    ? sortAlphabetically(_.filter(backofficeUserList, "isAccountManager"))
    : [];

  const lawyers = backofficeUserList ? sortAlphabetically(_.filter(backofficeUserList, "isLawyer")) : [];

  const internals = _.sortBy(
    _.filter(backofficeUserList, (backofficeUser) => {
      return (
        _.intersection(backofficeUser.roles, [
          Roles.lawyer,
          Roles.accountmanager,
          Roles.traineeLawyer,
          Roles.accounting,
        ]).length > 0
      );
    }),
    (internal) => {
      return internal.person.fullname;
    }
  );

  const agents = _.sortBy(
    _.filter(backofficeUserList, (backofficeUser) => {
      return (
        _.intersection(backofficeUser.roles, [
          Roles.lawyer,
          Roles.accountmanager,
          Roles.external,
          Roles.traineeLawyer,
          Roles.accounting,
        ]).length > 0
      );
    }),
    (agent) => {
      return agent.person.fullname;
    }
  );

  const externals = _.sortBy(
    _.filter(backofficeUserList, (backofficeUser) => {
      return _.includes(backofficeUser.roles, Roles.external);
    }),
    (externalUser) => {
      return externalUser.person.fullname;
    }
  );

  const systemUser = _.find(backofficeUserList, (backofficeUser) => {
    return _.includes(backofficeUser.roles, Roles.system);
  });

  const getUserData = (iri: string | null) => {
    if (iri === null) {
      return undefined;
    }
    return _.head(
      _.filter(backofficeUserFullList, (backofficeUser) => {
        return backofficeUser["@id"] === iri;
      })
    );
  };

  const data: BackofficeUserProviderData = {
    isLoading,
    backofficeUsers: backofficeUserList,
    accountManagers,
    lawyers,
    agents,
    internals,
    externals,
    systemUser,
    getUserData,
  };

  return <BackofficeUserProviderContext.Provider value={data}>{children}</BackofficeUserProviderContext.Provider>;
}

export const useBackofficeUser = () => {
  const backofficeUserContext = useContext(BackofficeUserProviderContext);
  if (!backofficeUserContext) {
    throw new Error("useBackofficeUser can only be used inside BackofficeUserProvider");
  }
  return backofficeUserContext;
};
