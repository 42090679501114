const uploadFileStyles = {
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  dialogContainer: {
    padding: "1rem",
    maxWidth: "700px",
  },
  checkbox: {
    color: "#137f7b",
  },
  checkboxContainer: {
    paddingLeft: "1rem",
  },
  fileContainer: {
    padding: "1rem",
    textAlign: "center",
  },
  border: {
    border: "1px solid #d6d6d6",
    borderRadius: "5px",
  },
  borderWarning: {
    border: "2px solid red",
    borderRadius: "5px",
  },
  icon: {
    margin: "0 auto",
    width: "60px",
    height: "40px",
    display: "block",
    color: "#d6d6d6",
  },
  fileInput: {
    display: "none",
  },
  chooseFile: {
    color: "blue",
    cursor: "pointer",
  },
  warning: {
    color: "red",
  },
  centerText: {
    textAlign: "center",
  },
  fileName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export default uploadFileStyles;
