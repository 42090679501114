import React from "react";
import pageHeadlineStyle from "./pageHeadlineStyle";
import { getCaseLabel, getFullProductLabel } from "../../services/Product/ProductService";
import { Box, Typography } from "@mui/material";
import { AbstractCase } from "../../types/AbstractCase";

type ProductHeadlineProps = {
  product: AbstractCase;
  title: string;
};

export function ProductHeadline({ product, title }: ProductHeadlineProps) {
  return <PageHeadline sup={title} main={getCaseLabel(product)} sub={getFullProductLabel(product)} />;
}

type PageHeadlineProps = {
  sup?: string;
  main: string;
  sub?: string;
};
export default function PageHeadline({ sup = "", main, sub = "" }: PageHeadlineProps) {
  return (
    <Box sx={pageHeadlineStyle.headlineContainer}>
      <Typography variant="h5">{sup}</Typography>
      <Typography sx={{ margin: "0.5rem" }} variant="h1">
        {main}
      </Typography>
      <Typography variant="h5">{sub}</Typography>
    </Box>
  );
}
