import { Button, Grid } from "@mui/material";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import AdvoAssistOpenFeeAgreementModal from "./Modals/AdvoAssistOpenFeeAgreementModal";
import AdvoAssistPostponeModal from "./Modals/AdvoAssistPostponeModal";
import advoAssistActionsStyle from "./advoAssistActionsStyle";
import AdvoAssistCancelModal from "./Modals/AdvoAssistCancelModal";
import { AdvoAssist } from "../../types/AdvoAssist/AdvoAssist";
import { Appointment } from "../../types/Appointment";
import { AbstractCase } from "../../types/AbstractCase";

type AdvoAssistActionsProps = {
  advoAssist: AdvoAssist;
  legalbirdAppointment: Appointment;
  product: AbstractCase;
  refreshPage: Function;
};
export default function AdvoAssistActions({
  advoAssist,
  legalbirdAppointment,
  product,
  refreshPage,
}: AdvoAssistActionsProps) {
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitAction = async (action: string, data: Record<string, any>, userFriendlyErrorMessage = "") => {
    setIsLoading(true);
    try {
      await ApiClient.post("services/advo_assist/" + action + "/" + _.toNumber(advoAssist?.id), {
        body: JSON.stringify(data),
      });
    } catch (e: any) {
      alert((userFriendlyErrorMessage ? userFriendlyErrorMessage + "\n" : "") + "[" + e.status + "]: " + e.error);
      refreshPage();
      return;
    }
    setIsLoading(false);
  };

  return (
    <>
      <Grid item md={6} xs={12} sx={advoAssistActionsStyle.actionButton}>
        <ButtonLoading
          onClick={() => setModal("openFeeAgreement")}
          variant={"contained"}
          isLoading={isLoading}
          disabled={!!advoAssist.appointmentAssigned}
        >
          Neu ausschreiben
        </ButtonLoading>
        <AdvoAssistOpenFeeAgreementModal
          open={modal === "openFeeAgreement"}
          handleClose={() => setModal("")}
          submitAction={submitAction}
          refreshPage={refreshPage}
        />
      </Grid>

      <Grid item md={6} xs={12} sx={advoAssistActionsStyle.actionButton}>
        <ButtonLoading onClick={() => setModal("postpone")} variant={"contained"} isLoading={isLoading}>
          Termin verschieben
        </ButtonLoading>
        <AdvoAssistPostponeModal
          open={modal === "postpone"}
          handleClose={() => setModal("")}
          legalbirdAppointment={legalbirdAppointment}
          product={product}
          submitAction={submitAction}
          refreshPage={refreshPage}
        />
      </Grid>

      <Grid item md={6} xs={12} sx={advoAssistActionsStyle.actionButton}>
        <ButtonLoading
          onClick={() => submitAction("create_credit_note", {})}
          disabled={!advoAssist.appointmentAssigned || !!advoAssist.creditNoteCreated}
          variant={"contained"}
          isLoading={isLoading}
        >
          Gutschrift erstellen
        </ButtonLoading>
      </Grid>

      <Grid item md={6} xs={12} sx={advoAssistActionsStyle.actionButton}>
        <ButtonLoading
          onClick={() => setModal("cancel")}
          disabled={!!advoAssist.creditNoteCreated}
          variant={"contained"}
          isLoading={isLoading}
        >
          Termin absagen
        </ButtonLoading>
        <AdvoAssistCancelModal
          open={modal === "cancel"}
          handleClose={() => setModal("")}
          submitAction={submitAction}
          refreshPage={refreshPage}
        />
      </Grid>
      <Grid item md={6} xs={12} sx={advoAssistActionsStyle.actionButton}>
        <Button
          component="a"
          variant="contained"
          href={"https://www.advo-assist.de/mitglieder/details/?Nr=" + advoAssist.advoAssistAppointmentId}
          target="_blank"
          rel="noreferrer"
        >
          Zum AdvoAssist Termin
        </Button>
      </Grid>
      <Grid item md={6} xs={12} sx={advoAssistActionsStyle.actionButton}>
        <ButtonLoading
          onClick={() => submitAction("reload_appointment", {})}
          variant={"contained"}
          isLoading={isLoading}
        >
          AdvoAssist synchronisieren
        </ButtonLoading>
      </Grid>
    </>
  );
}
