import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import validatorStyle from "./validatorStyle";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { ValidatorType } from "../../types/ValidatorType";
import { OutlinedInputProps } from "@mui/material";

type ValidatorTextFieldProps = {
  name: string;
  value: string | number;
  registerValidators?: Function;
  validators?: Array<ValidatorType>;
  isHighlighted?: boolean;
  initialDependentValidationFields?: Array<string>;
  label: string;
  InputProps?: OutlinedInputProps;
} & TextFieldProps;

const ValidatorTextField = ({
  name,
  value,
  registerValidators = () => {},
  validators = [],
  isHighlighted = false,
  initialDependentValidationFields = [],
  label,
  ...rest
}: ValidatorTextFieldProps) => {
  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, initialDependentValidationFields);
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, []);

  return (
    <TextField
      {...rest}
      name={name}
      value={value}
      label={label}
      sx={isHighlighted && (value === null || value === "") ? validatorStyle.mandatory : null}
    />
  );
};

export default ValidatorTextField;
