const tabStyle = {
  color: "rgba(0,0,0,0.6)",
  flexDirection: "row",
  justifyContent: "flex-start",
  textTransform: "initial !important",
};

const taggedTabStyles = {
  tableHeadline: {
    textTransform: "initial",
    minWidth: 200,
  },
  tabItemTagStyle: {
    ...tabStyle,
    backgroundColor: "primary.main",
    color: "#fff",
    fontSize: "0.75rem",
    borderRadius: 2,
    lineHeight: "16px",
    padding: "1px 4px",
    letterSpacing: 0.3,
    marginLeft: 1,
    whiteSpace: "nowrap",
    fontWeight: "normal",
  },
  tabItemLabelStyle: {
    ...tabStyle,
    display: "flex",
    alignItems: "center",
  },
  tabItemLabelGroupStyle: {
    ...tabStyle,
    minWidth: 0,
  },
};

export default taggedTabStyles;
