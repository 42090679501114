import { useParams } from "react-router-dom";

const useProductParams = () => {
  const { productUrlPart, productId } = useParams();

  return {
    productUrlPart: productUrlPart,
    productId: productId ? parseInt(productId) : 0,
  };
};

export default useProductParams;
