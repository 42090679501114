import { translate } from "../../Translations/translatorService";

export const trueFalseDefaultOptions = (path) => {
  return [
    { value: "__null__", disabled: true, label: translate(`${path}.values.__null__`, " ") },
    { value: true, label: translate(`${path}.values.true`, "Ja") },
    { value: false, label: translate(`${path}.values.false`, "Nein") },
  ];
};

export const trueFalseNullDefaultOptions = (path) => {
  return [
    { value: null , label: translate(`${path}.values.__null__`, "-") },
    { value: true, label: translate(`${path}.values.true`, "Ja") },
    { value: false, label: translate(`${path}.values.false`, "Nein") },
  ];
};

export const yesNoDefaultOptions = (path) => {
  return [
    { value: "__null__", disabled: true, label: translate(`${path}.values.__null__`, " ") },
    { value: "yes", label: translate(`${path}.values.yes`, "Ja") },
    { value: "no", label: translate(`${path}.values.no`, "Nein") },
  ];
};

export const yesNoUnknownDefaultOptions = (path) => {
  return [
    { value: "__null__", disabled: true, label: translate(`${path}.values.__null__`, " ") },
    { value: "yes", label: translate(`${path}.values.yes`, "Ja") },
    { value: "no", label: translate(`${path}.values.no`, "Nein") },
    { value: "unknown", label: translate(`${path}.values.unknown`, "Nicht sicher") },
  ];
};
