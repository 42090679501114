import React, { FocusEventHandler, ReactNode } from "react";
import { FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";

type LabeledSelectProps = {
  label: string;
  value?: string;
  onChange: (event: SelectChangeEvent) => void;
  children: Array<ReactNode>;
  disabled?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  fullWidth?: boolean;
  defaultValue?: string;
};

export default function LabeledSelect({
  label,
  value,
  onChange,
  children,
  disabled,
  onFocus,
  onBlur,
  defaultValue,
  fullWidth = true,
}: LabeledSelectProps) {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        label={label}
        labelId="select-label"
        value={value}
        onChange={onChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={defaultValue}
      >
        {children}
      </Select>
    </FormControl>
  );
}
