import algoliasearch from "algoliasearch/lite";
import _ from "lodash";
import {
  ALGOLIA_APPLICATION_ID,
  ALGOLIA_AUTHORITY_INDEX,
  ALGOLIA_COURT_INDEX,
  ALGOLIA_ORGANIZATION_INDEX,
  ALGOLIA_PROSECUTION_INDEX,
  ALGOLIA_SEARCH_API_KEY,
} from "../../config/_entrypoint";
import { DatasetConfig } from "./datasetTypes";
import {
  contractualPartnerFields,
  debtCollectionAgencyFields,
} from "../../services/Product/FormDefinitions/modules/organizationFields";
import OrganizationHit from "../Case/CaseEntityPicker/Hit/OrganizationHit";
import { authorityFields } from "../../services/Product/FormDefinitions/modules/authorityFields";
import AuthorityHit from "../Case/CaseEntityPicker/Hit/AuthorityHit";
import { prosecutionFields } from "../../services/Product/FormDefinitions/modules/prosecutionFields";
import ProsecutionHit from "../Case/CaseEntityPicker/Hit/ProsecutionHit";
import CourtHit from "../CourtPicker/CourtHit";
import { courtFields } from "../../services/Product/FormDefinitions/modules/courtFields";

export const datasetConfigs: { [x: string]: DatasetConfig } = {
  contractualPartnerLegalEntity: {
    entityApiData: {
      entityApiUri: "organizations",
      baseEntityAttribute: "contractualPartnerLegalEntity",
      additionalSaveData: {
        isChecked: true,
        type: "contractualPartnerLegalEntity",
      },
      additionalBaseEntityData: {},
    },
    searchConfig: {
      indexName: ALGOLIA_ORGANIZATION_INDEX,
      requestOptions: { filters: "type:contractualPartnerLegalEntity" },
      idPrefix: "organization",
    },
    resultListConfig: {
      itemSize: 150,
    },
    entityFormElements: contractualPartnerFields({
      path: "",
      translationPrefix: "contractualPartnerLegalEntity",
    }),
    HitComponent: OrganizationHit,
  },
  debtCollectionAgency: {
    entityApiData: {
      entityApiUri: "organizations",
      baseEntityAttribute: "debtCollectionAgency",
      additionalSaveData: {
        isChecked: true,
        type: "debtCollectionAgency",
      },
      additionalBaseEntityData: {},
    },
    searchConfig: {
      indexName: ALGOLIA_ORGANIZATION_INDEX,
      requestOptions: { filters: "type:debtCollectionAgency" },
      idPrefix: "organization",
    },
    resultListConfig: {
      itemSize: 150,
    },
    entityFormElements: debtCollectionAgencyFields({ path: "", translationPrefix: "debtCollectionAgency" }),
    HitComponent: OrganizationHit,
  },
  authority: {
    entityApiData: {
      entityApiUri: "authorities",
      baseEntityAttribute: "responsibleAuthority",
      additionalSaveData: {},
      additionalBaseEntityData: {},
    },
    searchConfig: {
      indexName: ALGOLIA_AUTHORITY_INDEX,
      idPrefix: "authority",
    },
    resultListConfig: {
      itemSize: 150,
    },
    entityFormElements: authorityFields({ path: "", translationPrefix: "authority" }),
    HitComponent: AuthorityHit,
  },
  prosecution: {
    entityApiData: {
      entityApiUri: "prosecutions",
      baseEntityAttribute: "responsibleProsecution",
      additionalSaveData: {},
      additionalBaseEntityData: {},
    },
    searchConfig: {
      indexName: ALGOLIA_PROSECUTION_INDEX,
      idPrefix: "prosecution",
    },
    resultListConfig: {
      itemSize: 150,
    },
    entityFormElements: prosecutionFields({ path: "", translationPrefix: "prosecution" }),
    HitComponent: ProsecutionHit,
  },
  court: {
    entityApiData: {
      entityApiUri: "courts",
      baseEntityAttribute: "responsibleCourt",
      additionalSaveData: {},
      additionalBaseEntityData: {},
    },
    searchConfig: {
      indexName: ALGOLIA_COURT_INDEX,
      idPrefix: "court",
    },
    resultListConfig: {
      itemSize: 200,
    },
    formFieldTransformations: {
      advoAssistId: (value) => _.toNumber(value),
    },
    entityFormElements: courtFields({ path: "", translationPrefix: "court" }),
    HitComponent: CourtHit,
  },
};

export const getDatasetConfigFromProduct = (datasetType: string, product: any) => {
  let dataSetConfig = { ...datasetConfigs[datasetType] };
  _.set(dataSetConfig, "entityApiData.baseEntityApiUri", product["@id"]);
  if (_.includes(["settlement"], product.productClassName)) {
    _.set(dataSetConfig, "entityApiData.additionalBaseEntityData", {
      backofficeCaseId: product.backofficeCase.id,
      basePath: "extended",
    });
  }
  switch (datasetType) {
    case "contractualPartnerLegalEntity":
      _.set(
        dataSetConfig,
        "entityFormElements",
        contractualPartnerFields({
          path: product.productClassName + ".contractualPartnerLegalEntity",
          hideAddressAddition: false,
        })
      );
      break;
    case "debtCollectionAgency":
      _.set(
        dataSetConfig,
        "entityFormElements",
        debtCollectionAgencyFields({ path: product.productClassName + ".debtCollectionAgency" })
      );
      break;
    case "authority":
      _.set(
        dataSetConfig,
        "entityFormElements",
        authorityFields({
          path: product.productClassName + ".responsibleAuthority",
        })
      );
      break;
    case "prosecution":
      _.set(
        dataSetConfig,
        "entityFormElements",
        prosecutionFields({
          path: product.productClassName + ".responsibleProsecution",
        })
      );
      break;
  }
  return dataSetConfig;
};

interface DuplicationConfig {
  entitySearchIndex: string;
  algoliaSearchOptions?: { filters: string };
  searchField: string;
  additionalFields: Array<string>;
  idPrefix: string;
}

const duplicationConfigs: { [x: string]: DuplicationConfig } = {
  contractualPartnerLegalEntity: {
    entitySearchIndex: ALGOLIA_ORGANIZATION_INDEX,
    algoliaSearchOptions: { filters: "type:contractualPartnerLegalEntity" },
    searchField: "legalName",
    additionalFields: ["name"],
    idPrefix: "organization",
  },
  debtCollectionAgency: {
    entitySearchIndex: ALGOLIA_ORGANIZATION_INDEX,
    algoliaSearchOptions: { filters: "type:debtCollectionAgency" },
    searchField: "name",
    additionalFields: [],
    idPrefix: "organization",
  },
  responsibleAuthority: {
    entitySearchIndex: ALGOLIA_AUTHORITY_INDEX,
    searchField: "name",
    additionalFields: ["department", "subDepartment"],
    idPrefix: "authority",
  },
  responsibleProsecution: {
    entitySearchIndex: ALGOLIA_PROSECUTION_INDEX,
    searchField: "name",
    additionalFields: ["department", "subDepartment"],
    idPrefix: "prosecution",
  },
  responsibleCourt: {
    entitySearchIndex: ALGOLIA_COURT_INDEX,
    searchField: "name",
    additionalFields: [],
    idPrefix: "court",
  },
};

export interface DuplicationCheckResult {
  hasPartialMatch: boolean;
  hasFullMatch: boolean;
  searchField: string;
}

export async function duplicationChecker({
  values,
  type,
  currentFieldname = "",
  currentId = 0,
}: {
  values: {};
  type: string;
  currentFieldname: string;
  currentId?: number;
}): Promise<DuplicationCheckResult | null> {
  const duplicationConfig = duplicationConfigs[type];
  const fieldsToCheck = [...duplicationConfig.additionalFields, duplicationConfig.searchField];
  if (!fieldsToCheck.includes(currentFieldname)) {
    return null;
  }

  const searchIndex = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY).initIndex(
    duplicationConfig.entitySearchIndex
  );
  const searchValue = _.get(values, duplicationConfig.searchField);
  const hits = await searchIndex.search(searchValue, duplicationConfig.algoliaSearchOptions);
  const hasPartialMatch = hits.nbHits > 0;
  let hasFullMatch = false;
  for (const hit of hits.hits) {
    hasFullMatch =
      //-- do skip the search entry for the current id!
      duplicationConfig.idPrefix + "::" + currentId !== hit.objectID &&
      //-- check other search entries for equality
      fieldsToCheck.reduce((aggregator: boolean, field: string) => {
        const valuesValue = _.get(values, field, "") || "";
        const fieldsValue = _.get(hit, field, "");
        return valuesValue == fieldsValue && aggregator;
      }, true);
    if (hasFullMatch) {
      break;
    }
  }

  return {
    hasPartialMatch,
    hasFullMatch,
    searchField: duplicationConfig.searchField,
  };
}
