import React from "react";
import { IMaskInput } from "react-imask";
import IMask from "imask";
import {InputBaseComponentProps} from "@mui/material/InputBase";

type IbanInputProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
} & InputBaseComponentProps;

export const IbanInput = React.forwardRef<IMask.MaskElement, IbanInputProps>(function IbanInput(props, ref) {
  const { onChange, name, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00 0000 0000 0000 0000 00"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: name, value: String(value) } })}
    />
  );
});
