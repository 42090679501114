import { Box, Drawer, Paper, Stack } from "@mui/material";
import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { Close } from "@mui/icons-material";
import ComposeEmailOrMessageForm from "./ComposeEmailOrMessageForm";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ComposeEmailOrMessageDrawerStyle from "./ComposeEmailOrMessageDrawerStyle";

type MessageDrawerProps = {
  open?: string;
  setMessageDrawerOpen: Function;
  messageDrawerMaximized: boolean;
  setMessageDrawerMaximized: Function;
  closeDialog: () => void;
  messageConfig?: {
    conversationIri?: string;
  };
};

export default function ComposeEmailOrMessageDrawer({
  open,
  setMessageDrawerOpen,
  messageDrawerMaximized,
  setMessageDrawerMaximized,
  closeDialog,
  messageConfig,
}: MessageDrawerProps) {
  const toggleDrawer = (changeDrawerMaximizedState: boolean) => {
    setMessageDrawerMaximized(changeDrawerMaximizedState);
  };

  useEffect(() => {
    if (open) {
      toggleDrawer(false);
    }
  }, [open]);

  return (
    <>
      {!messageDrawerMaximized && (
        <Stack sx={ComposeEmailOrMessageDrawerStyle.stackDrawer} direction={"row"}>
          <Paper>
            <Stack direction={"row"} alignItems={"center"}>
              <Box sx={ComposeEmailOrMessageDrawerStyle.stackDrawerBox}>Nachricht schreiben</Box>
              <Box sx={ComposeEmailOrMessageDrawerStyle.boxIcon}>
                <IconButton onClick={() => toggleDrawer(true)} size="medium">
                  <OpenInFullIcon />
                </IconButton>
                <IconButton onClick={() => setMessageDrawerOpen(false)} size="medium">
                  <Close />
                </IconButton>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      )}
      <Drawer
        open={messageDrawerMaximized}
        anchor={"bottom"}
        PaperProps={ComposeEmailOrMessageDrawerStyle.drawerPaperProps}
        ModalProps={{
          keepMounted: true,
        }}
        variant="persistent"
      >
        <Stack sx={{ margin: "0.5rem" }}>
          <Stack direction={"row"}>
            <Box sx={ComposeEmailOrMessageDrawerStyle.drawerStackBox}>Nachricht schreiben</Box>
            <Box sx={ComposeEmailOrMessageDrawerStyle.boxIcon}>
              <IconButton onClick={() => toggleDrawer(false)} size="medium">
                <MinimizeIcon />
              </IconButton>
              <IconButton onClick={() => setMessageDrawerOpen(false)} size="medium">
                <Close />
              </IconButton>
            </Box>
          </Stack>
          <Stack sx={ComposeEmailOrMessageDrawerStyle.drawerForm}>
            <ComposeEmailOrMessageForm open={messageDrawerMaximized} closeDialog={closeDialog} messageConfig={messageConfig} />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
