import React, { useContext } from "react";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../services/ReactQuery/reactQueryService";

interface ActionIdentifiers {
  [actionIdentifier: string]: string;
}

interface ActionIdentifiersByLabel {
  [documentClassLabelAi: string]: ActionIdentifiers;
}

interface FileActionIdentifierContextInterface {
  actionIdentifiers: ActionIdentifiersByLabel | null;
  getActionIdentifiersByLabel: (a: string) => ActionIdentifiers;
  prepareAndSortActionIdentifiers: (a: ActionIdentifiers) => { label: string; value: string }[];
  getPreparedActionIdentifierOptionByLabelAndIdentifier: (
    a: string,
    b: string
  ) => {
    label: string;
    value: string;
  } | null;
  isLoading: boolean;
  translateActionIdentifier: (documentClassLabelAi: string, identifier: string) => string;
}

const FileActionIdentifierContext = React.createContext<FileActionIdentifierContextInterface | null>(null);

export default function ActionIdentifierProvider({ children }: { children?: React.ReactNode }) {
  const { data: actionIdentifiers, isLoading } = useQuery(
    queryKeys.all("actionIdentifiers"),
    () => fetchCollection("lb/services/action_identifiers"),
    {
      retry: false,
    }
  );

  const getActionIdentifiersByLabel = (documentClassLabelAi: string) => {
    if (!actionIdentifiers) {
      return null;
    }
    return _.merge(actionIdentifiers[documentClassLabelAi], actionIdentifiers.common);
  };

  const prepareAndSortActionIdentifiers = (actionIdentifiers: ActionIdentifiers) => {
    let labelSpecificActionIdentifiers = _.map(actionIdentifiers, (translation, key) => {
      return {
        label: key === "NO_ACTION" ? translation : key + " - " + translation,
        value: key,
      };
    });
    return _.sortBy(labelSpecificActionIdentifiers, (option) => option.label);
  };

  const getPreparedActionIdentifierOptionByLabelAndIdentifier = (labelName: string, identifierName: string) => {
    if (
      !actionIdentifiers ||
      !actionIdentifiers[labelName] ||
      !actionIdentifiers[labelName][identifierName] ||
      typeof actionIdentifiers[labelName][identifierName] !== "string"
    ) {
      return null;
    }
    return {
      label:
        identifierName === "NO_ACTION"
          ? actionIdentifiers[labelName][identifierName]
          : identifierName + " - " + actionIdentifiers[labelName][identifierName],
      value: identifierName,
    };
  };

  const translateActionIdentifier = (documentClassLabelAi: string, identifier: string) => {
    const availableActionIdentifiers = getActionIdentifiersByLabel(documentClassLabelAi);
    if (!availableActionIdentifiers) {
      return "";
    }
    if (!availableActionIdentifiers[identifier]) {
      return `Übersetzung fehlt: ${identifier}`;
    }
    return availableActionIdentifiers[identifier];
  };

  const data: FileActionIdentifierContextInterface = {
    actionIdentifiers: actionIdentifiers,
    getActionIdentifiersByLabel: getActionIdentifiersByLabel,
    prepareAndSortActionIdentifiers: prepareAndSortActionIdentifiers,
    getPreparedActionIdentifierOptionByLabelAndIdentifier: getPreparedActionIdentifierOptionByLabelAndIdentifier,
    isLoading,
    translateActionIdentifier,
  };

  return <FileActionIdentifierContext.Provider value={data}>{children}</FileActionIdentifierContext.Provider>;
}

export const useActionIdentifier = () => {
  const actionIdentifierContext = useContext(FileActionIdentifierContext);
  if (!actionIdentifierContext) {
    throw new Error("useActionIdentifier can only be used inside ActionIdentifierProvider");
  }
  return actionIdentifierContext;
};
