import React from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import AddInquiryToCustomerForm from "../../Request/AddInquiryToCustomerForm";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import Typography from "@mui/material/Typography";

interface AddInquiryModalProps {
  product: AbstractCase;
  open: boolean;
  handleClose: Function;
}

export default function AddInquiryToCustomerModal({ open, handleClose, product }: AddInquiryModalProps) {
  let inquiryFilter = {
    productId: product.id,
    "status[lt]": 40,
  };
  const { data: caseOpenInquiries } = useQuery(
    queryKeys.collection("inquiry_to_customers", inquiryFilter),
    () => fetchCollection("inquiry_to_customers", inquiryFilter),
    {
      enabled: !!product,
    }
  );

  if (!caseOpenInquiries) {
    return null;
  }

  if (caseOpenInquiries["hydra:totalItems"] > 1) {
    return (
      <LegalbirdIoModal
        title={"Maximale Anzahl an Anfragen erreicht"}
        handleClose={handleClose}
        open={open}
        hideCancelButton={true}
        submitButton={<ButtonLoading onClick={() => handleClose()}>Schließen</ButtonLoading>}
      >
        <Typography>
          Es sind bereits zwei Anfragen an die Mandantschaft gestellt worden. Diese Anfragen müssen erst abschließend
          bearbeitet oder geschlossen werden, damit eine neue Anfrage gestellt werden kann.
        </Typography>
      </LegalbirdIoModal>
    );
  }

  return <AddInquiryToCustomerForm handleClose={handleClose} open={open} product={product} />;
}
