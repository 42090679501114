import _ from "lodash";
import * as divorceStages from "../Stages/StagesDivorce";
import { stageMetaDataDivorce } from "../Stages/StagesDivorce";
import * as settlementStages from "../Stages/StagesSettlement";
import { stageMetaDataSettlement } from "../Stages/StagesSettlement";
import * as trafficStages from "../Stages/StagesTraffic";
import { stageMetaDataTraffic } from "../Stages/StagesTraffic";
import * as documentStages from "../Stages/StagesDocument";
import { stageMetaDataDocument } from "../Stages/StagesDocument";
import * as caseStages from "../Stages/StagesCase";
import { stageMetaDataCase } from "../Stages/StagesCase";
import * as divorceStatus from "../LeadStatus/StatusDivorce";
import * as settlementStatus from "../LeadStatus/StatusSettlement";
import * as trafficStatus from "../LeadStatus/StatusTraffic";
import * as documentStatus from "../LeadStatus/StatusDocument";
import * as basicCaseStatus from "../LeadStatus/StatusBasicCase";
import * as caseStatus from "../LeadStatus/StatusCase";
import * as basicCaseStages from "../Stages/StagesBasicCase";
import { stageMetaDataBasicCase } from "../Stages/StagesBasicCase";
import { translate } from "../Translations/translatorService";
import { fullFormDefinition as fullFormDefinitionAlimony } from "./FormDefinitions/document/alimony";
import { fullFormDefinition as fullFormDefinitionContract } from "./FormDefinitions/contract/fullFormDefinition";
import { fullFormDefinition as fullFormDefinitionDataLeakContract } from "./FormDefinitions/dataLeakContract/fullFormDefinition";
import { fullFormDefinition as fullFormDefinitionDeath } from "./FormDefinitions/document/death";
import { fullFormDefinition as fullFormDefinitionDivorce } from "./FormDefinitions/divorce/fullFormDefinition";
import { fullFormDefinition as fullFormDefinitionPatientDecree } from "./FormDefinitions/patientDecree";
import { fullFormDefinition as fullFormDefinitionPowerOfAttorney } from "./FormDefinitions/powerOfAttorney";
import { fullFormDefinition as fullFormDefinitionRentalContract } from "./FormDefinitions/rentalContract/fullFormDefinition";
import { fullFormDefinition as fullFormDefinitionSettlement } from "./FormDefinitions/settlement/fullFormDefinition";
import { fullFormDefinition as fullFormDefinitionShortTimeWork } from "./FormDefinitions/shortTimeWork";
import { fullFormDefinition as fullFormDefinitionTraffic } from "./FormDefinitions/traffic/fullFormDefinition";
import { fullFormDefinition as fullFormDefinitionFamilyLawCase } from "./FormDefinitions/familyLawCase/fullFormDefinition";
import { stageFormDefinition as stageFormDefinitionContract } from "./FormDefinitions/contract/stageFormDefinition";
import { stageFormDefinition as stageFormDefinitionDataLeakContract } from "./FormDefinitions/dataLeakContract/stageFormDefinitions";
import { stageFormDefinition as stageFormDefinitionDivorce } from "./FormDefinitions/divorce/stageFormDefinition";
import { stageFormDefinition as stageFormDefinitionDocument } from "./FormDefinitions/document";
import { stageFormDefinition as stageFormDefinitionFamily } from "./FormDefinitions/familyLawCase/stageFormDefinition";
import { stageFormDefinition as stageFormDefinitionRentalContract } from "./FormDefinitions/rentalContract/stageFormDefinition";
import { stageFormDefinition as stageFormDefinitionSettlement } from "./FormDefinitions/settlement/stageFormDefinition";
import { stageFormDefinition as stageFormDefinitionTraffic } from "./FormDefinitions/traffic/stageFormDefinition";
import {
  persistAlimony,
  persistContractCase,
  persistDataLeakContract,
  persistDivorce,
  persistDocument,
  persistFamilyLawCase,
  persistRentalContractCase,
  persistSettlement,
  persistTraffic,
} from "../Persist/Persister";
import divorceTransformations from "./FormTransformations/divorceTransformations";
import settlementTransformations from "./FormTransformations/settlementTransformations";
import trafficTransformations from "./FormTransformations/trafficTransformations";
import alimonyTransformations from "./FormTransformations/alimonyTransformations";
import advisoryTransformations from "./FormTransformations/advisoryTransformations";
import documentTransformations from "./FormTransformations/documentTransformations";
import shortTimeWorkTransformations from "./FormTransformations/shortTimeWorkTransformations";
import contractTransformations from "./FormTransformations/contractTransformations";
import eventProductSpecificDefinition from "./FormDefinitions/eventContract";
import membershipProductSpecificDefinition from "./FormDefinitions/membershipContract";
import providerProductSpecificDefinition from "./FormDefinitions/providerContract";
import purchaseProductSpecificDefinition from "./FormDefinitions/purchaseContract";
import serviceProductSpecificDefinition from "./FormDefinitions/serviceContract";
import subscriptionProductSpecificDefinition from "./FormDefinitions/subscriptionContract";
import travelProductSpecificDefinition from "./FormDefinitions/travelContract";
import vehicleProductSpecificDefinition from "./FormDefinitions/vehicleContract";
import formDefinitionExternals from "./FormDefinitions/externals";
import { contractCaseDetailsSpecialActions } from "./CaseDetailsSpecialActions/contractCaseDetailsSpecialActions";
import { LEGALBIRD_BASE_URL } from "../../config/_entrypoint";
import moment from "moment";
import otherProductSpecificDefinition from "./FormDefinitions/otherContract";
import { userHasRole } from "../backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { paymentTypeSwitchDataTraffic } from "./PaymentTypeSwitchDataDefinitions/paymentTypeSwitchDataTraffic";
import { paymentTypeSwitchDataSettlement } from "./PaymentTypeSwitchDataDefinitions/paymentTypeSwitchDataSettlement";
import employmentContractSpecificDefinition from "./FormDefinitions/employmentContract";
import dataLeakTransformations from "./FormTransformations/dataLeakTransformations";
import rentalContractTransformations from "./FormTransformations/rentalContractTransformations";
import { rentalContractCaseDetailsSpecialActions } from "./CaseDetailsSpecialActions/rentalContractCaseDetailsSpecialActions";
import { getRentalContractBadges } from "./Badges/getRentalContractBadges";
import { getContractLawBadges } from "./Badges/getContractLawBadges";
import { getDataLeakContractBadges } from "./Badges/getDataLeakContractBadges";

const placeholderPaymentTypeSwitchData = {
  getPaymentSwitchAllowed: () => {
    return { allowed: true };
  },
  getActionAvailable: () => false,
  options: [],
};
const productData = {
  alimony: {
    urlPart: "unterhalt",
    lbUrlPart: "unterhalt",
    label: translate("alimony.label"),
    generalLabel: translate("alimony.label"),
    apiUri: "alimonies",
    persist: persistAlimony,
    stages: stageMetaDataDocument,
    stageConstants: documentStages,
    minimumCaseStatus: documentStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: documentStages.STAGE_INCOMPLETE,
    setBackCaseStage: documentStages.STAGE_READY,
    paymentType: {
      creditcard: "Kreditkarte",
      paypal: "Paypal",
      sofort: "Sofortüberweisung",
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\Document\\Alimony",
    formTransformations: alimonyTransformations,
    formDefinition: ({ product }) => stageFormDefinitionDocument(product.productClassName),
    formDefinitionExternals: () => formDefinitionExternals["alimony"],
    fullFormDefinition: ({ product }) => fullFormDefinitionAlimony(product),
    productSpecificDefinition: () => [],
    productCategory: "familyLaw",
    productSpecificBadges: ({}) => [],
  },
  dataLeakContract: {
    urlPart: "datenweitergabe",
    lbUrlPart: "datenweitergabe",
    label: translate("dataLeakContract.label"),
    generalLabel: "Datenweitergabe",
    apiUri: "data_leak_contracts",
    persist: persistDataLeakContract,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_DATA_COMPLETED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\DataLeakContract\\DataLeakContract",
    formTransformations: dataLeakTransformations,
    formDefinition: ({ product }) => stageFormDefinitionDataLeakContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionDataLeakContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionDataLeakContract(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getDataLeakContractBadges(product),
  },
  death: {
    urlPart: "tod",
    lbUrlPart: "todesfall",
    label: translate("death.label"),
    generalLabel: translate("death.label"),
    apiUri: "deaths",
    persist: persistDocument,
    stages: stageMetaDataDocument,
    stageConstants: documentStages,
    minimumCaseStatus: documentStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: documentStages.STAGE_INCOMPLETE,
    setBackCaseStage: documentStages.STAGE_READY,
    paymentType: {
      creditcard: "Kreditkarte",
      paypal: "Paypal",
      sofort: "Sofortüberweisung",
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\Document\\Death",
    formTransformations: documentTransformations,
    formDefinition: ({ product }) => stageFormDefinitionDocument(product.productClassName),
    formDefinitionExternals: () => formDefinitionExternals["death"],
    fullFormDefinition: ({ product }) => fullFormDefinitionDeath(product),
    productSpecificDefinition: () => [],
    productCategory: "familyLaw",
    productSpecificBadges: ({}) => [],
  },
  divorce: {
    urlPart: "scheidung",
    lbUrlPart: "scheidung",
    label: translate("divorce.label"),
    generalLabel: translate("divorce.label"),
    apiUri: "divorces",
    persist: persistDivorce,
    stages: stageMetaDataDivorce,
    stageConstants: divorceStages,
    minimumCaseStatus: divorceStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: divorceStages.STAGE_INCOMPLETE,
    setBackCaseStage: divorceStages.STAGE_READY,
    paymentType: {
      insurance: "RSV",
      processCostSupport: "VKH",
      private: "Rechnung",
      installment: "Raten",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\Divorce\\Divorce",
    formTransformations: divorceTransformations,
    formDefinition: () => stageFormDefinitionDivorce,
    formDefinitionExternals: () => formDefinitionExternals["divorce"],
    fullFormDefinition: ({ product }) => fullFormDefinitionDivorce(product),
    productSpecificDefinition: () => [],
    productCategory: "familyLaw",
    productSpecificBadges: ({}) => [],
  },
  eventContract: {
    urlPart: "veranstaltung",
    lbUrlPart: "veranstaltung",
    label: translate("eventContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "event_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\EventContract\\EventContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => eventProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  familyLawCase: {
    urlPart: "familie",
    lbUrlPart: "erstberatung",
    label: translate("familyLawCase.label"),
    generalLabel: translate("familyLawCase.label"),
    apiUri: "family_law_cases",
    persist: persistFamilyLawCase,
    stages: stageMetaDataBasicCase,
    stageConstants: basicCaseStages,
    minimumCaseStatus: basicCaseStatus.STATUS_CREATED,
    minimumCaseStage: basicCaseStages.STAGE_CREATED,
    setBackCaseStage: basicCaseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\BasicCase\\FamilyLawCase",
    formTransformations: advisoryTransformations,
    formDefinition: ({ product }) => stageFormDefinitionFamily(product),
    formDefinitionExternals: () => formDefinitionExternals["familyLawCase"],
    fullFormDefinition: ({ product }) => fullFormDefinitionFamilyLawCase(product),
    productSpecificDefinition: () => [],
    productCategory: "familyLaw",
    productSpecificBadges: ({}) => [],
  },
  membershipContract: {
    urlPart: "mitgliedschaft",
    lbUrlPart: "mitgliedschaft",
    label: translate("membershipContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "membership_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\MembershipContract\\MembershipContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => membershipProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  otherContract: {
    urlPart: "sonstige",
    lbUrlPart: "sonstige",
    label: translate("otherContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "other_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\OtherContract\\OtherContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => otherProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  patientDecree: {
    urlPart: "patientenverfuegung",
    lbUrlPart: "patientenverfuegung",
    label: translate("patientDecree.label"),
    generalLabel: translate("patientDecree.label"),
    apiUri: "patient_decrees",
    persist: persistDocument,
    stages: stageMetaDataDocument,
    stageConstants: documentStages,
    minimumCaseStatus: documentStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: documentStages.STAGE_INCOMPLETE,
    setBackCaseStage: documentStages.STAGE_READY,
    paymentType: {
      creditcard: "Kreditkarte",
      paypal: "Paypal",
      sofort: "Sofortüberweisung",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\Document\\PatientDecree",
    formTransformations: documentTransformations,
    formDefinition: ({ product }) => stageFormDefinitionDocument(product.productClassName),
    formDefinitionExternals: () => formDefinitionExternals["patientDecree"],
    fullFormDefinition: () => fullFormDefinitionPatientDecree,
    productSpecificDefinition: () => [],
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productSpecificBadges: ({}) => [],
  },
  powerOfAttorney: {
    urlPart: "vorsorgevollmacht",
    lbUrlPart: "vorsorgevollmacht",
    label: translate("powerOfAttorney.label"),
    generalLabel: translate("powerOfAttorney.label"),
    apiUri: "power_of_attorneys",
    persist: persistDocument,
    stages: stageMetaDataDocument,
    stageConstants: documentStages,
    minimumCaseStatus: documentStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: documentStages.STAGE_INCOMPLETE,
    setBackCaseStage: documentStages.STAGE_READY,
    paymentType: {
      creditcard: "Kreditkarte",
      paypal: "Paypal",
      sofort: "Sofortüberweisung",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\Document\\PowerOfAttorney",
    formTransformations: documentTransformations,
    formDefinition: ({ product }) => stageFormDefinitionDocument(product.productClassName),
    formDefinitionExternals: () => formDefinitionExternals["powerOfAttorney"],
    fullFormDefinition: () => fullFormDefinitionPowerOfAttorney,
    productSpecificDefinition: () => [],
  },
  providerContract: {
    urlPart: "versorger",
    lbUrlPart: "versorger",
    label: translate("providerContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "provider_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\ProviderContract\\ProviderContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => providerProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  purchaseContract: {
    urlPart: "kauf",
    lbUrlPart: "kauf",
    label: translate("purchaseContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "purchase_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\PurchaseContract\\PurchaseContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => purchaseProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  serviceContract: {
    urlPart: "dienstleistung",
    lbUrlPart: "dienstleistung",
    label: translate("serviceContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "service_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\ServiceContract\\ServiceContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => serviceProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  settlement: {
    urlPart: "abfindung",
    lbUrlPart: "kuendigung",
    label: translate("settlement.label"),
    generalLabel: translate("settlement.label"),
    apiUri: "settlements",
    persist: persistSettlement,
    stages: stageMetaDataSettlement,
    stageConstants: settlementStages,
    minimumCaseStatus: settlementStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: settlementStages.STAGE_INCOMPLETE,
    setBackCaseStage: settlementStages.STAGE_READY,
    paymentType: {
      nwnf: "No-Win-No-Fee",
      insurance: "RSV",
      private: "Selbstzahler",
    },
    paymentTypeSwitchData: paymentTypeSwitchDataSettlement,
    lbProductClass: "App\\Entity\\Legalbird\\Settlement\\Settlement",
    formTransformations: settlementTransformations,
    formDefinition: () => stageFormDefinitionSettlement,
    formDefinitionExternals: () => formDefinitionExternals["settlement"],
    fullFormDefinition: ({ product }) => fullFormDefinitionSettlement(product),
    productSpecificDefinition: () => [],
    productCategory: "employmentLaw",
    productSpecificBadges: ({}) => [],
  },
  shortTimeWork: {
    urlPart: "kurzarbeit",
    lbUrlPart: "kurzarbeit",
    label: translate("shortTimeWork.label"),
    generalLabel: translate("shortTimeWork.label"),
    apiUri: "short_time_works",
    persist: persistDocument,
    stages: stageMetaDataDocument,
    stageConstants: documentStages,
    minimumCaseStatus: documentStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: documentStages.STAGE_INCOMPLETE,
    setBackCaseStage: documentStages.STAGE_READY,
    paymentType: {
      creditcard: "Kreditkarte",
      paypal: "Paypal",
      sofort: "Sofortüberweisung",
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\Document\\ShortTimeWork",
    formTransformations: shortTimeWorkTransformations,
    formDefinition: () => [],
    formDefinitionExternals: () => [],
    fullFormDefinition: ({ product }) => fullFormDefinitionShortTimeWork(product),
    productSpecificDefinition: () => [],
    productCategory: "employmentLaw",
    productSpecificBadges: ({}) => [],
  },
  employmentContract: {
    urlPart: "arbeit",
    lbUrlPart: "arbeit",
    label: translate("employmentContract.label"),
    generalLabel: translate("employmentContract.label"),
    apiUri: "employment_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      creditcard: "Kreditkarte",
      paypal: "Paypal",
      sofort: "Sofortüberweisung",
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\EmploymentLaw\\EmploymentContract\\EmploymentContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => employmentContractSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "employmentLaw",
    productSpecificBadges: ({}) => [],
  },
  rentalContract: {
    urlPart: "miete",
    lbUrlPart: "miete",
    label: translate("rentalContract.label"),
    generalLabel: translate("rentalContract.label"),
    apiUri: "rental_contracts",
    persist: persistRentalContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      creditcard: "Kreditkarte",
      paypal: "Paypal",
      sofort: "Sofortüberweisung",
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\TenancyLaw\\RentalContract\\RentalContract",
    formTransformations: rentalContractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionRentalContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionRentalContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionRentalContract(product),
    caseDetailsSpecialActions: ({ product }) => rentalContractCaseDetailsSpecialActions(product),
    productCategory: "tenancyLaw",
    productSpecificBadges: ({ product }) => getRentalContractBadges(product),
  },
  subscriptionContract: {
    urlPart: "abonnement",
    lbUrlPart: "abonnement",
    label: translate("subscriptionContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "subscription_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\SubscriptionContract\\SubscriptionContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => subscriptionProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  traffic: {
    urlPart: "verkehr",
    lbUrlPart: "ordnungswidrigkeit",
    label: translate("traffic.label"),
    generalLabel: translate("traffic.label"),
    apiUri: "traffic",
    persist: persistTraffic,
    stages: stageMetaDataTraffic,
    stageConstants: trafficStages,
    minimumCaseStatus: trafficStatus.STATUS_DATA_COMPLETED,
    minimumCaseStage: trafficStages.STAGE_INCOMPLETE,
    setBackCaseStage: trafficStages.STAGE_READY,
    paymentType: {
      backwards_insurance: "ARAG Verso",
      insurance: "RSV",
      private: "Selbstzahler",
    },
    paymentTypeSwitchData: paymentTypeSwitchDataTraffic,
    lbProductClass: "App\\Entity\\Legalbird\\Traffic\\Traffic",
    formTransformations: trafficTransformations,
    formDefinition: () => stageFormDefinitionTraffic,
    formDefinitionExternals: () => formDefinitionExternals["traffic"],
    fullFormDefinition: ({ product }) => fullFormDefinitionTraffic(product),
    productSpecificDefinition: () => [],
    productCategory: "trafficLaw",
    productSpecificBadges: ({}) => [],
  },
  travelContract: {
    urlPart: "reise",
    lbUrlPart: "reise",
    label: translate("travelContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "travel_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\TravelContract\\TravelContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => travelProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
  vehicleContract: {
    urlPart: "fahrzeugkauf",
    lbUrlPart: "fahrzeugkauf",
    label: translate("vehicleContract.label"),
    generalLabel: "Beratung / Vertretung Vertragsrecht",
    apiUri: "vehicle_contracts",
    persist: persistContractCase,
    stages: stageMetaDataCase,
    stageConstants: caseStages,
    minimumCaseStatus: caseStatus.STATUS_CREATED,
    minimumCaseStage: caseStages.STAGE_CREATED,
    setBackCaseStage: caseStages.STAGE_DATA_COMPLETED,
    paymentType: {
      insurance: "RSV",
    },
    paymentTypeSwitchData: placeholderPaymentTypeSwitchData,
    lbProductClass: "App\\Entity\\Legalbird\\ContractLaw\\VehicleContract\\VehicleContract",
    formTransformations: contractTransformations,
    formDefinition: ({ product }) => stageFormDefinitionContract(product),
    formDefinitionExternals: ({ product }) => stageFormDefinitionContract(product)[product.stage],
    fullFormDefinition: ({ product }) => fullFormDefinitionContract(product),
    productSpecificDefinition: ({ product }) => vehicleProductSpecificDefinition(product),
    caseDetailsSpecialActions: ({ product }) => contractCaseDetailsSpecialActions(product),
    productCategory: "contractLaw",
    productSpecificBadges: ({ product }) => getContractLawBadges(product),
  },
};

const getProductHeadline = (urlPart) => {
  return getProductLabel(getProductName(urlPart));
};

const getProductName = (urlPart) => {
  return _.findKey(productData, ["urlPart", urlPart]);
};

const getProductNameBy = (path, value) => {
  return _.findKey(productData, [path, value]);
};

const getProductData = (productClassName, path, defaultValue = "") => {
  productClassName = _.camelCase(productClassName);
  return _.get(productData, `${productClassName}.${path}`, defaultValue);
};

const getFormDefinition = (product) => {
  const formDefinitionFunction = getProductData(product.productClassName, "formDefinition", () => {});
  return formDefinitionFunction({ product: product });
};

const getFormDefinitionExternals = (product) => {
  const formDefinitionFunction = getProductData(product.productClassName, "formDefinitionExternals", () => {});
  return formDefinitionFunction({ product: product });
};

const getFullFormDefinition = (product) => {
  const formDefinitionFunction = getProductData(product.productClassName, "fullFormDefinition", () => {});
  return formDefinitionFunction({ product: product });
};

const getProductSpecificDefinition = (product) => {
  const productSpecificDefinition = getProductData(product.productClassName, "productSpecificDefinition", () => {});
  return productSpecificDefinition({ product: product });
};

const getResponsibleInstitutes = (product) => {
  let courtPath = "";
  let authorityPath = "";
  let prosecutionPath = "";

  switch (product.productClassName) {
    case "divorce":
      courtPath = "responsibleCourt";
      break;
    case "traffic":
      courtPath = "responsibleCourt";
      authorityPath = "responsibleAuthority";
      prosecutionPath = "responsibleProsecution";
      break;
    case "settlement":
      courtPath = "responsibleCourt";
      break;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "dataLeakContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
    case "rentalContract":
      courtPath = "responsibleCourt";
      prosecutionPath = "responsibleProsecution";
      break;
    default:
      break;
  }
  return { courtPath, authorityPath, prosecutionPath };
};

const getBadgesMinimal = (product) => {
  let badges = [
    {
      text: getProductData(product.productClassName, "label"),
      isActive: true,
    },
  ];

  if (product.acquisitionPartner && product.insurance && product.insurance.insuranceType === "bonus") {
    badges.push({
      text: "Nur Bonusberatung",
      isActive: true,
    });
  }

  return badges;
};
const getBadges = (product, currentUser) => {
  const getCaseIdentifier = (productClassName, id) => {
    return productClassName === "traffic" ? " " + id : "";
  };

  let badges = [
    {
      text: getProductData(product.productClassName, "label"),
      isActive: true,
      lbProductId: product.id,
    },
  ];

  if (product.backofficeCase && product.backofficeCase.relatedCases) {
    _.forEach(product.backofficeCase.relatedCases, (relatedBackofficeCase) => {
      const productClassName = getProductNameBy("lbProductClass", relatedBackofficeCase.lbProductClass);
      badges.push({
        text:
          getProductLabel(productClassName) + getCaseIdentifier(productClassName, relatedBackofficeCase.lbProductId),
        isActive: false,
        lbProductId: relatedBackofficeCase.lbProductId,
        linkTo: getCaseLink(
          {
            productClassName,
            id: relatedBackofficeCase.lbProductId,
          },
          currentUser && userHasRole(currentUser, Roles.callcenter) ? "/callcenter" : ""
        ),
      });

      badges = _.orderBy(badges, "lbProductId", "desc");
    });
  }

  switch (product.insurance?.insuranceType) {
    case "bonus":
      badges.push({
        text: "Nur Bonusberatung",
        isActive: true,
      });
      break;
    case "uncovered":
      badges.push({
        text: "Nicht versichert",
        isActive: true,
      });
      break;
    default:
      break;
  }

  const productSpecificBadges = getProductData(product.productClassName, "productSpecificBadges")({ product });
  badges = badges.concat(productSpecificBadges);

  return badges;
};

const getIsApplicationComplete = (product) => {
  switch (product.productClassName) {
    case "generic":
      return true;
    default:
      return product.leadStatus >= getProductData(product.productClassName, "minimumCaseStatus");
  }
};

const getIsChecked = (product) => {
  return product.stage > getProductData(product.productClassName, "setBackCaseStage");
};

const getIsReady = (product) => {
  return product.stage === getProductData(product.productClassName, "setBackCaseStage");
};

const getStageList = (productClassName) => {
  return getProductData(productClassName, "stages");
};

const getProductStageList = (product) => {
  if (!_.has(product, "leadStatusList")) {
    return getStageList(product.productClassName);
  }
  return _.keyBy(
    _.filter(getStageList(product.productClassName), (stage) => {
      return _.includes(product.leadStatusList, stage.leadStatus) || stage.stage === 0;
    }),
    "stage"
  );
};

const getCaseLabel = (product) => {
  if (!product.petitioner?.givenName) {
    return product.reference;
  }
  return (
    translate(`${product.productClassName}.petitioner.gender.values.${product.petitioner.gender}`, "") +
    (product.petitioner.gender ? " " : "") +
    product.petitioner.givenName +
    " " +
    product.petitioner.familyName
  );
};

const getProductLabel = (productClassName) => {
  return getProductData(productClassName, "label");
};

const getProductCategory = (product) => {
  return _.capitalize(translate("general.productCategory." + product.productCategory));
};

const getFullProductLabel = (product) => {
  return getProductCategory(product) + ": " + getProductLabel(product.productClassName);
};

const getCaseLink = (product, subFolder = "") => {
  return "/services" + subFolder + "/fall/" + getProductData(product.productClassName, "urlPart") + "/" + product.id;
};

const getCaseLinkByBackofficeCase = (backofficeCase, subFolder = "") => {
  const product = {
    productClassName: getProductNameBy("lbProductClass", backofficeCase.lbProductClass),
    id: backofficeCase.lbProductId,
  };
  return getCaseLink(product, subFolder);
};

const getCaseDetailsSpecialActions = (product) => {
  return getProductData(product.productClassName, "caseDetailsSpecialActions", () => null)({ product });
};

const getAdvoAssistRepresentative = (product) => {
  const appointments = _.get(product, "courtAppointments", []);
  //same order as in CourtAppointments.tsx
  const latestAppointment = _.head(_.sortBy(appointments, (c) => -c.id));
  return latestAppointment?.advoAssist?.partnerLawyer;
};

const getNextStage = (product) => {
  const orderedStages = _.sortBy(getProductData(product.productClassName, "stages"), "stage");
  const currentStage = _.findIndex(orderedStages, (orderedStage) => {
    return orderedStage.stage === product.stage;
  });
  const nextStage = currentStage + 1;
  for (let i = nextStage; i < orderedStages.length; i++) {
    if (orderedStages[i].isClickable({ product })) {
      return orderedStages[i];
    }
  }
  return null;
};

const getNextStages = (product) => {
  const orderedStages = _.sortBy(getProductData(product.productClassName, "stages"), "stage");
  const currentStage = _.findIndex(orderedStages, (orderedStage) => {
    return orderedStage.stage === product.stage;
  });
  if (_.has(orderedStages[currentStage], "nextStages")) {
    return _.get(orderedStages[currentStage], "nextStages");
  }
  if (!getNextStage(product)) {
    return [];
  }
  return [getNextStage(product).stage];
};

const getLeadStatusByStage = (productClassName, stage) => {
  return getStageList(productClassName)[stage].leadStatus;
};

const hasLockedInsurance = (product) => {
  return _.includes(["roland", "arag", "advocard"], product.acquisitionPartner);
};

const isDocumentProduct = (productClassName) => {
  return _.includes(
    ["shortTimeWork", "patientDecree", "powerOfAttorney", "documents", "death", "alimony"],
    productClassName
  );
};

const getServiceWorldCaseLink = (product, hash) => {
  const uri =
    LEGALBIRD_BASE_URL +
    "/servicewelt/" +
    translate("general.productCategory." + product.productCategory) +
    "/" +
    getProductData(product.productClassName, "lbUrlPart") +
    "/" +
    product.id;
  if (hash) {
    return uri + "#" + hash;
  }
  return uri;
};

//in the future use productCategory === "contractLaw" instead
const isContractProduct = (productClassName) => {
  return _.includes(
    [
      "eventContract",
      "membershipContract",
      "otherContract",
      "providerContract",
      "dataLeakContract",
      "purchaseContract",
      "serviceContract",
      "subscriptionContract",
      "travelContract",
      "vehicleContract",
      "rentalContract",
    ],
    productClassName
  );
};

const hasActiveCourtAppointment = (courtAppointments) => {
  return _.some(courtAppointments, (courtAppointment) => {
    const appointmentDate = moment(courtAppointment?.courtDate?.appointmentDate);
    return appointmentDate.isValid() && appointmentDate.isSameOrAfter(new Date(), "day") && courtAppointment.advoAssist;
  });
};

export {
  productData,
  getLeadStatusByStage,
  getCaseLink,
  getCaseLinkByBackofficeCase,
  getCaseDetailsSpecialActions,
  getProductLabel,
  getFullProductLabel,
  getCaseLabel,
  getStageList,
  getProductStageList,
  getProductHeadline,
  getProductData,
  getProductName,
  getFormDefinition,
  getFullFormDefinition,
  getProductSpecificDefinition,
  getBadges,
  getBadgesMinimal,
  getIsApplicationComplete,
  getNextStage,
  getNextStages,
  getProductNameBy,
  getIsChecked,
  getIsReady,
  hasLockedInsurance,
  isDocumentProduct,
  isContractProduct,
  getResponsibleInstitutes,
  getFormDefinitionExternals,
  getServiceWorldCaseLink,
  getAdvoAssistRepresentative,
  hasActiveCourtAppointment,
};
