import LegalbirdIoModal from "./LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import React, { ReactNode, useState } from "react";

type LegalbirdIoConfirmType = {
  open: boolean;
  handleClose: Function;
  headline: string;
  content: ReactNode;
  confirmText: string;
  handleConfirm: Function;
};
export default function LegalbirdIoConfirm({
  open,
  handleClose,
  headline = "",
  content = "",
  confirmText = "Bestätigen",
  handleConfirm = () => {},
}: LegalbirdIoConfirmType) {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    await handleConfirm(e);
    setIsLoading(false);
    handleClose();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={headline}
      submitButton={
        <ButtonLoading onClick={handleClick} color="primary" variant={"contained"} isLoading={isLoading}>
          {confirmText}
        </ButtonLoading>
      }
    >
      {content}
    </LegalbirdIoModal>
  );
}
