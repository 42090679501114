const historyItemTranslations = {
  emailMessage: {
    label: "Emails",
  },
  lawyer: {
    label: "Anwalt",
  },
  fax: {
    label: "Faxe",
  },
  accountManager: {
    label: "Zuständiger",
  },
  assignedUserChange: {
    label: "Zuständiger Benutzer",
  },
  beaMessage: {
    label: "beA Nachrichten",
  },
  paidTask: {
    label: "Ausschreibungen",
  },
  passwordReset: {
    label: "Passwort zurückgesetzt",
  },
  mailPause: {
    label: "Emails und Nachrichten pausiert",
  },
  activitiesPause: {
    label: "Aktivitäten pausiert",
  },
  activity: {
    label: "Aktivitäten",
  },
  note: {
    label: "Notizen",
  },
  mediaObject: {
    label: "Dokumente",
  },
  customerRegistration: {
    label: "Registrierung",
  },
  caseCreated: {
    label: "Fall erstellt",
  },
  entityStatusChange: {
    label: "Statusänderung",
  },
  entityDataChange: {
    label: "Änderung",
  },
  customerDataChange: {
    label: "Änderung",
  },
  caseStatusChange: {
    label: "Zustand geändert",
    lost: {
      label: "Fall verloren",
      lostCategory: {
        noResponse: "Kein Kontakt möglich / Keine Rückmeldung",
        caseChange: "Wechsel auf anderen Fall",
        customerRefusal: "Ablehnung weiteres Vorgehen durch Kunde (ohne Begründung)",
        noInsurance: "Fehlende RSV / Schlechtes Kosten-Nutzen-Verhältnis",
        noLegalBasis: "Rechtliches Vorgehen nicht möglich / Kein Case",
        localLawyer: "Lokaler Anwalt",
        nonPayment: "Verloren wegen Nichtzahlung",
        noCorrespondingProduct: "Kein Legalbird-Rechtsgebiet",
        other: "Sonstiges",
      },
    },
    won: {
      label: "Fall gewonnen",
    },
    open: {
      label: "Fall wieder geöffnet",
    },
    inactive: {
      label: "Fall inaktiv",
    },
  },
  invoiceTypeIdentifierChange: {
    label: "Änderung",
  },
  invoiceDelete: {
    label: "Buchungseintrag",
  },
  invoiceReassign: {
    label: "Neuzuweisung Buchungseintrag",
  },
};

export default historyItemTranslations;
