import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { AbstractCase } from "../../types/AbstractCase";
import { scrollToAnchor } from "../../theme/commonStyles";
import ContentBox from "../ContentBox/ContentBox";
import Grid from "@mui/material/Grid";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import { Conversation } from "../../types/Messaging/Conversation";
import CaseMessagesConversation from "./CaseMessagesConversation";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import Button from "@mui/material/Button";
import _ from "lodash";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const DEFAULT_NUMBER_OF_CONVERSATIONS_TO_DISPLAY = 6;

const CaseMessagesBox = ({ product }: { product: AbstractCase }) => {
  const [channelFilter, setChannelFilter] = useState("all");
  const [creatorFilter, setCreatorFilter] = useState<"backofficeUser" | "customer" | "system" | string>("all");
  const [numberOfConversationsToDisplay, setNumberOfConversationsToDisplay] = useState(
    DEFAULT_NUMBER_OF_CONVERSATIONS_TO_DISPLAY
  );
  const currentUser = useCurrentUser();

  const queryClient = useQueryClient();
  const queryFilters = { "channel.case": product.id };
  const { data: conversations } = useQuery<Array<Conversation>, Error>(
    queryKeys.collection("/conversations", queryFilters),
    () => fetchCollection("/conversations", queryFilters, { headers: new Headers({ accept: "application/json" }) })
  );

  const refetchConversations = () =>
    queryClient.invalidateQueries(queryKeys.collection("/conversations", queryFilters));

  const creatorFilterOptions = [{ value: "all", label: "Alle Benutzer" }];

  if (!conversations) {
    return (
      <>
        <Box sx={scrollToAnchor} id={"messages"} />
        <ContentBox headline={"Nachrichten"}>
          <LegalbirdLoader />
        </ContentBox>
      </>
    );
  }

  const channelFilteredConversations = conversations.filter((conversation: Conversation) => {
    if (channelFilter === "all") {
      return true;
    }
    return conversation.channel.type === channelFilter;
  });

  const conversationCreators = _.uniqBy(
    channelFilteredConversations.map((conversation) => conversation.creator),
    "id"
  );

  if (conversationCreators.some((creator) => creator.messagingUserType === "system")) {
    creatorFilterOptions.push({ value: "system", label: "Legalbird" });
  }

  const customerName = conversationCreators.find(
    (creator) => creator.messagingUserType === "customer"
  )?.messagingUserFullName;

  if (customerName) {
    creatorFilterOptions.push({ value: customerName, label: `${customerName} (Kunde)` });
  }

  const backofficeUserNames = conversationCreators
    .filter((creator) => creator.messagingUserType === "backofficeUser")
    .map((creator) => creator.messagingUserFullName);

  if (backofficeUserNames.length > 0) {
    creatorFilterOptions.push({ value: "allBackofficeUsers", label: "Alle Backoffice-Nutzer" });
    backofficeUserNames.forEach((name) => {
      creatorFilterOptions.push({ value: name, label: name });
    });
  }


  const userFilteredConversations = channelFilteredConversations.filter((conversation: Conversation) => {
    switch (creatorFilter) {
      case "all":
        return true;
      case "customer":
        return conversation.creator.messagingUserType === "customer";
      case "allBackofficeUsers":
        return conversation.creator.messagingUserType === "backofficeUser";
      case "system":
        return conversation.creator.messagingUserType === "system";
      default:
        return conversation.creator.messagingUserFullName === creatorFilter;
    }
  });

  const sortComparisonFunction = (conversationA: Conversation, conversationB: Conversation) => {
    if (conversationA.latestMessageCreatedDate < conversationB.latestMessageCreatedDate) {
      return 1;
    }
    if (conversationA.latestMessageCreatedDate > conversationB.latestMessageCreatedDate) {
      return -1;
    }
    return 0;
  };

  const conversationsToDisplay = userFilteredConversations.sort(sortComparisonFunction);

  return (
    <>
      <Box sx={scrollToAnchor} id={"messages"} />
      <ContentBox headline={"Nachrichten"}>
        <Grid container spacing={2}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            {!currentUser.isExternal && (
              <ValidatorSelect
                label={"Channel"}
                onChange={({ target }) => {
                  setCreatorFilter("all");
                  setChannelFilter(target.value);
                }}
                value={channelFilter}
              >
                <MenuItem value={"all"}>Alle</MenuItem>
                <MenuItem value={"internalPlusLegalExpenseInsurance"}>RSV</MenuItem>
              </ValidatorSelect>
            )}
          </Grid>
          <Grid item xs={4}>
            <ValidatorSelect
              label={"Ersteller"}
              onChange={({ target }: { target: any }) => {
                setCreatorFilter(target.value);
              }}
              value={creatorFilter}
            >
              {creatorFilterOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </ValidatorSelect>
          </Grid>
        </Grid>
        {conversations && conversations.length > 0 ? (
          <>
            <Box marginBottom={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} />
                  </TableRow>
                  {conversationsToDisplay.slice(0, numberOfConversationsToDisplay).map((conversation) => (
                    <CaseMessagesConversation
                      key={conversation.id}
                      conversation={conversation}
                      refetchConversations={refetchConversations}
                      product={product}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
            {numberOfConversationsToDisplay < conversationsToDisplay.length && (
              <Button
                onClick={() => {
                  setNumberOfConversationsToDisplay(numberOfConversationsToDisplay + 6);
                }}
              >
                Mehr anzeigen
              </Button>
            )}
            {numberOfConversationsToDisplay > DEFAULT_NUMBER_OF_CONVERSATIONS_TO_DISPLAY && (
              <Button
                sx={{ marginTop: "1rem" }}
                onClick={() => {
                  setNumberOfConversationsToDisplay(DEFAULT_NUMBER_OF_CONVERSATIONS_TO_DISPLAY);
                }}
              >
                Weniger anzeigen
              </Button>
            )}
          </>
        ) : (
          <Box sx={{ textAlign: "center", color: "#dcdcdc", margin: "2rem" }}>
            <Typography variant={"h4"}>Es sind noch keine Nachrichten vorhanden</Typography>
          </Box>
        )}
      </ContentBox>
    </>
  );
};
export default CaseMessagesBox;
