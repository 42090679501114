import CostBoxTraffic from "../CaseCostBoxes/CostBoxTraffic";
import CostBoxSettlement from "../CaseCostBoxes/CostBoxSettlement";
import CostBoxDivorce from "../CaseCostBoxes/CostBoxDivorce";
import React from "react";
import { AbstractCase } from "../../../types/AbstractCase";

type CaseCostBoxProps = {
  product: AbstractCase;
};
const CaseCostBox = ({ product }: CaseCostBoxProps) => {
  switch (product.productClassName) {
    case "traffic":
      return <CostBoxTraffic traffic={product} />;
    case "settlement":
      return <CostBoxSettlement settlement={product} />;
    case "divorce":
      return <CostBoxDivorce divorce={product} />;
    default:
      return null;
  }
};
export default CaseCostBox;
