import { useEffect, useMemo, useState } from "react";
import PageHeadline from "../../PageHeadline/PageHeadline";
import PageContentContainer from "../../Container/PageContentContainer";
import MediaObjectsGallery from "../../MediaObjectsGallery/MediaObjectsGallery";
import { useSearchParams } from "react-router-dom";
import ApiClient from "../../../services/ApiClient";
import _ from "lodash";
import { MediaObject } from "../../../types/MediaObject";

export default function MediaObjectsGalleryPage() {
  const [mediaObjects, setMediaObjects] = useState<Array<MediaObject>>([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const mediaObjectIdList = _.split(searchParams.get("mediaObjectIds"), "-");
    if (!searchParams.get("mediaObjectIds") || mediaObjectIdList.length === 0) {
      return;
    }
    const idFilterQuery = "id[]=" + _.join(mediaObjectIdList, "&id[]=");
    ApiClient.get("media_objects?" + idFilterQuery).then((mediaObjectsResponse) =>
      setMediaObjects(mediaObjectsResponse["hydra:member"])
    );
  }, []);

  const initialMediaObject = useMemo(
    () => _.find(mediaObjects, (mediaObject) => "" + mediaObject.id === searchParams.get("initialMediaObjectId")),
    [mediaObjects]
  );

  return (
    <PageContentContainer size={"large"}>
      <PageHeadline main="Dokumente" />
      <MediaObjectsGallery mediaObjects={mediaObjects} initialMediaObject={initialMediaObject} />
    </PageContentContainer>
  );
}
