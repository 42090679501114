import React, { ReactNode, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import validatorStyle from "./validatorStyle";
import { ValidatorType } from "../../types/ValidatorType";

export type ValidatorSelectProps = {
  label: string;
  registerValidators?: Function;
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<string>;
  helperText?: string;
  isHighlighted?: boolean;
  error?: boolean;
  children: ReactNode;
} & SelectProps<any>;
const ValidatorSelect = ({
  label,
  registerValidators = () => {},
  validators = [],
  dependentValidationFields = [],
  helperText = "",
  isHighlighted = false,
  error = false,
  children,
  endAdornment,
  name,
  disabled,
  value,
  ...rest
}: ValidatorSelectProps) => {
  useEffect(() => {
    if (registerValidators && validators && !disabled) {
      registerValidators(name, validators, dependentValidationFields);
    }
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, [registerValidators, dependentValidationFields, name, disabled]);

  const iconClass = endAdornment ? { icon: validatorStyle.icon } : undefined;

  return (
    <FormControl
      fullWidth={true}
      margin={"normal"}
      sx={isHighlighted && (value === null || value === "") ? validatorStyle.mandatory : undefined}
      error={error}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <Select {...rest} {...{ endAdornment, name, value, disabled }} label={label} sx={iconClass}>
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ValidatorSelect;
