import React from "react";
import Grid from "@mui/material/Grid";
import ProductFilter from "./ProductFilter/ProductFilter";
import UserFilter from "./UserFilter/UserFilter";
import DateRangeFilter from "./DateRangeFilter/DateRangeFilter";
import ActivityTypeFilter from "./ActivityTypeFilter/ActivityTypeFilter";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import _ from "lodash";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import { MenuItem, Stack } from "@mui/material";
import SubjectFilter from "./SubjectFilter/SubjectFilter";
import ActivityFilterChip from "../../Chip/ActivityFilterChip";
import { Activity } from "../../../types/Activity";
import { BackofficeUser } from "../../../types/BackofficeUser";
import { activityOverviewHeaderStyles } from "./activityOverviewHeaderStyles";

type ActivityOverviewHeaderProps = {
  userActivitiesToday: Activity[];
  productFilter: string;
  isDeadlineView?: boolean;
  setProductFilter: (value: string) => void;
  activityTypeFilter: string;
  setActivityTypeFilter: (value: string) => void;
  userFilter: string;
  setUserFilter: (value: string | number) => void;
  timespanFilter: string;
  setTimespanFilter: (value: string) => void;
  setSubjectFilter: (value: string) => void;
  agents: BackofficeUser[];
  openOrDoneFilter?: string | null;
  setOpenOrDoneFilter?: (value: string) => void;
};

const ActivityOverviewHeader = ({
  userActivitiesToday,
  productFilter,
  isDeadlineView = false,
  setProductFilter,
  activityTypeFilter,
  setActivityTypeFilter,
  userFilter,
  setUserFilter,
  timespanFilter,
  setTimespanFilter,
  setSubjectFilter,
  agents,
  openOrDoneFilter = null,
  setOpenOrDoneFilter = (value: string) => {},
}: ActivityOverviewHeaderProps) => {
  const userActivitiesOverdue = _.filter(
    userActivitiesToday,
    (activity) => activity.activityStatus === ACTIVITY_OVERDUE
  );

  const showAmount = !["all", "accountManager", "lawyer"].some((filter) => filter === userFilter);

  return (
    <Grid container sx={activityOverviewHeaderStyles.filterContainer} spacing={2} alignItems="center">
      <Grid item xs={isDeadlineView ? 5 : 3}>
        <Stack justifyContent={"center"} sx={activityOverviewHeaderStyles.subjectFilter}>
          <SubjectFilter setSubjectFilter={setSubjectFilter} />
        </Stack>
      </Grid>
      {!isDeadlineView && (
        <Grid item xs={2}>
          <ValidatorSelect
            label="Status"
            onChange={({ target }) => {
              setOpenOrDoneFilter(target.value);
            }}
            value={openOrDoneFilter}
            sx={activityOverviewHeaderStyles.filterSelect}
            fullWidth={false}
          >
            <MenuItem value={"open"}>Offen</MenuItem>
            <MenuItem value={"done"}>Erledigt</MenuItem>
          </ValidatorSelect>
        </Grid>
      )}
      <Grid item xs={2}>
        <ProductFilter productFilter={productFilter} setProductFilter={setProductFilter} />
      </Grid>
      <Grid item xs={2}>
        <ActivityTypeFilter
          activityTypeFilter={activityTypeFilter}
          setActivityTypeFilter={setActivityTypeFilter}
          isDeadlineView={isDeadlineView}
        />
      </Grid>
      <Grid item xs={3}>
        <UserFilter
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          agents={agents}
          isDeadlineView={isDeadlineView}
        />
      </Grid>
      <Grid item xs={9}>
        <Stack direction="row" spacing={2}>
          <ActivityFilterChip
            label={"Überfällig"}
            isActive={timespanFilter === "overdue"}
            onClick={() => setTimespanFilter("overdue")}
            amount={showAmount ? userActivitiesOverdue.length : undefined}
          />
          <ActivityFilterChip
            label={"Heute"}
            isActive={timespanFilter === "today"}
            onClick={() => setTimespanFilter("today")}
            amount={showAmount ? userActivitiesToday.length : undefined}
          />
          {openOrDoneFilter !== "done" && (
            <ActivityFilterChip
              label={"Morgen"}
              isActive={timespanFilter === "tomorrow"}
              onClick={() => setTimespanFilter("tomorrow")}
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <DateRangeFilter timespanFilter={timespanFilter} setTimespanFilter={setTimespanFilter} />
      </Grid>
    </Grid>
  );
};
export default ActivityOverviewHeader;
