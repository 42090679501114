import * as stagesDocument from "../../../Stages/StagesDocument";
import { postalCodeDefault, textFieldDefault } from "../../../validationRules";
import basicInsuranceFields from "../modules/basicInsuranceFields";
import { AbstractCase } from "../../../../types/AbstractCase";

export const fullFormDefinition = (product: AbstractCase) => ({
  product: {
    sections: [
      {
        label: "Angaben Antragsteller",
        elements: [
          {
            type: "ValidatorSelect",
            path: "death.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }, { value: "diverse" }],
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.givenName",
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.familyName",
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.residenceAddress.streetAddress",
            validators: textFieldDefault,
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesDocument.STAGE_CHECKED,
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, postalCodeDefault],
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesDocument.STAGE_CHECKED,
          },
          {
            type: "ValidatorTextField",
            path: "death.petitioner.residenceAddress.addressLocality",
            validators: textFieldDefault,
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesDocument.STAGE_CHECKED,
          },
        ],
      },
      {
        label: "Daten zum Anliegen",
        elements: [
          {
            type: "ValidatorSelect",
            path: "death.generalCategory",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesDocument.STAGE_CHECKED,
            options: () => [{ value: "death" }, { value: "inheritance" }, { value: "both" }],
          },
          {
            type: "ValidatorSelect",
            path: "death.deathCategory",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesDocument.STAGE_CHECKED,
            options: () => [{ value: "current" }, { value: "preparation" }],
          },
          {
            type: "ValidatorSelect",
            path: "death.inheritanceCategory",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesDocument.STAGE_CHECKED,
            options: () => [{ value: "heir" }, { value: "bequeather" }],
          },
          {
            type: "ValidatorTextField",
            path: "death.personalTextMessage",
            isDisabled: ({ product }: { product: AbstractCase }) =>
              (product.stage || 0) >= stagesDocument.STAGE_CHECKED,
            additionalProps: {
              multiline: true,
              rows: 12,
              fullWidth: true,
            },
          },
        ],
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "death.reference",
            isDisabled: () => true,
          },
        ],
      },
      {
        label: "Angaben zur Versicherung",
        elements: basicInsuranceFields({ path: "death.insurance", product: product }),
      },
    ],
  },
});
