export const lawyerAbsenceActivitiesStyles = {
  paper: {
    border: "1px solid #db1919",
    padding: "1rem 24px 1rem 24px",
    marginBottom: "2rem",
  },
  headline: {
    fontSize: "1.05rem",
    textAlign: "center",
    paddingBottom: "1rem",
  },
  chip: {
    margin: "2px 4px",
  },
};

export default lawyerAbsenceActivitiesStyles;
