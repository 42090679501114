import React, { ReactElement } from "react";
import { Box, Grid, IconButton, IconButtonProps } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import MuiLink from "@mui/material/Link";
import { Accessible, SvgIconComponent } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { actionButtonStyle } from "./actionButtonStyle";

export type ActionButtonProps = {
  icon?: SvgIconComponent;
  to?: string;
  onClick?: () => void;
  isHidden?: boolean;
  divider?: boolean;
  text: string;
  useCondensedView?: boolean;
  href?: string;
};

const ActionButton = ({
  icon: Icon = Accessible,
  text,
  to,
  href,
  onClick = () => {},
  isHidden = false,
  useCondensedView,
  divider = false,
  ...rest
}: ActionButtonProps) => {
  if (isHidden) {
    return null;
  }

  let iconButtonProps: IconButtonProps<React.ElementType> = {
    onClick: onClick,
  };
  if (to) {
    iconButtonProps.component = Link;
    iconButtonProps.to = to;
  }
  if (href) {
    iconButtonProps.component = MuiLink;
    iconButtonProps.href = href;
    iconButtonProps.target = "_blank";
    iconButtonProps.rel = "noreferrer";
    iconButtonProps.underline = "none";
  }

  const InternalTooltip = ({ children }: { children: ReactElement }) => {
    if (!useCondensedView) {
      return <>{children}</>;
    }
    return (
      <Tooltip title={text} placement={"left"}>
        {children}
      </Tooltip>
    );
  };

  return (
    <Grid container direction={"column"} alignItems={"center"} {...rest} className={"actionButton"}>
      <Grid item>
        <IconButton {...iconButtonProps} size="large">
          <InternalTooltip>
            <Icon fontSize={useCondensedView ? "medium" : "large"} />
          </InternalTooltip>
        </IconButton>
      </Grid>
      {!useCondensedView && (
        <Grid item sx={actionButtonStyle.actionTitle}>
          {text}
        </Grid>
      )}
      {divider && <Box sx={actionButtonStyle.actionButtonDivider} />}
    </Grid>
  );
};

export default ActionButton;
