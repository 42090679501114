import Contact from "../../Form/Contact";
import ExtendedContact from "../../Form/ExtendedContact";
import React from "react";
import ContentBox from "../../ContentBox/ContentBox";
import { AbstractCase } from "../../../types/AbstractCase";

type CaseContactProps = {
  product: AbstractCase;
  headline?: string;
};
const CaseContact = ({ product, headline = "Kontakt" }: CaseContactProps) => {
  return (
    <ContentBox headline={headline}>
      <Contact />
      <ExtendedContact product={product} />
    </ContentBox>
  );
};
export default CaseContact;
