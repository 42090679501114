import React from "react";
import { Box } from "@mui/material";
import pageContentContainerStyle from "./pageContentContainerStyle";

type PageContainerProps = {
  children: React.ReactNode;
  backgroundColor: "default" | "white";
};

export function PageContainer({ children, backgroundColor }: PageContainerProps) {
  return (
    <Box sx={[pageContentContainerStyle.pageContainer, pageContentContainerStyle[backgroundColor]]}>{children}</Box>
  );
}

type ContentContainerProps = {
  size: "large" | "small" | "tiny";
  children: React.ReactNode;
};

export function ContentContainer({ size, children }: ContentContainerProps) {
  return (
    <Box sx={{ ...pageContentContainerStyle.contentContainer, ...pageContentContainerStyle[size] }}>{children}</Box>
  );
}

type PageContentContainerProps = {
  size?: "large" | "small" | "tiny";
  backgroundColor?: "default" | "white";
  children: React.ReactNode;
};

export default function PageContentContainer({
  size = "large",
  children,
  backgroundColor = "default",
}: PageContentContainerProps) {
  return (
    <PageContainer backgroundColor={backgroundColor}>
      <ContentContainer size={size}>{children}</ContentContainer>
    </PageContainer>
  );
}
