import Grid from "@mui/material/Grid";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import _ from "lodash";
import {
  getActivitiesSet,
  getActivityData,
  transformActivitySetToAutoCompleteOptions,
} from "../../../services/Activities/activityTypes";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { dateFieldDefault, isTodayOrFutureDate, requiredFieldDefault } from "../../../services/validationRules";
import ValidatorDateField from "../../Validator/ValidatorDatePicker";
import moment, { Moment } from "moment";
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import useForm, { UseFormProps } from "../../../hooks/useForm";
import editActivityStyles from "./editActivityStyles";
import SubmitButton from "../../Button/SubmitButton";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ActivityHistory from "./ActivityHistory";
import { formValue } from "../../../services/formServiceFunctions";
import { Editor } from "react-draft-wysiwyg";
import LegalbirdAutoComplete from "../../AutoComplete/LegalbirdAutoComplete";
import { getRoleToDisplay, sortAssignedUsersToTop, sortByRole } from "../../../services/backofficeUserService";
import ApiClient from "../../../services/ApiClient";
import { ReportProblemOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import WorkingHoursHint from "./WorkingHoursHint";
import { useHolidays } from "../../../provider/HolidayProvider";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { AbstractCase } from "../../../types/AbstractCase";
import { Activity } from "../../../types/Activity";
import { EditorState } from "draft-js";
import { BackofficeUser } from "../../../types/BackofficeUser";
import { BackofficeCase } from "../../../types/BackofficeCase";
import { AutoCompleteOptionWithId } from "../../../types/AutoCompleteOptionWithId";

const availabilityInfoTranslations = {
  absence: "Der Nutzer ist an dem Tag nicht anwesend",
  capacityReached: "Der Nutzer ist an dem Tag bereits zeitlich ausgelastet",
  available: "",
};

type ActivityFormProps = {
  open: boolean;
  initialValues: Record<string, any>;
  onSubmit: UseFormProps["onSubmit"];
  closeDialog: Function;
  product?: AbstractCase;
  productClassName: string;
  activity?: Activity;
  title: string;
};

const ActivityForm = ({
  open,
  initialValues,
  onSubmit,
  closeDialog,
  product,
  productClassName,
  activity,
  title,
}: ActivityFormProps) => {
  const currentUser = useCurrentUser();
  const { agents, internals, externals } = useBackofficeUser();
  const durations = [1, 3, 5, 10, 15, 20, 30, 45, 60, 90, 120, 180];
  const [userAvailabilityStatus, setUserAvailabilityStatus] = useState("");
  const [mandatorySelectDisabled, setMandatorySelectDisabled] = useState(false);
  const { holidays } = useHolidays();
  const {
    values,
    errors,
    handleChange,
    handleDateChange,
    handleSubmit,
    registerValidators,
    handleBlur,
    touchedValues,
    clearForm,
  } = useForm({
    initialValues,
    onSubmit,
    identifier: !!activity ? activity : "new",
    clearFormAfterSubmit: true,
  });

  const fetchAvailabilityStatus = useCallback(
    (assignedUser: string, dueDate: Moment | string) => {
      const dueDateMoment = moment(dueDate);
      if (
        !dueDateMoment.isValid() ||
        !assignedUser ||
        !open ||
        _.find(externals, (external) => external["@id"] === assignedUser)
      ) {
        setUserAvailabilityStatus("");
        return;
      }

      ApiClient.get(
        `services/human_resource_management/user_availability_status/${_.replace(
          assignedUser,
          "/backoffice_users/",
          ""
        )}/${dueDateMoment.format("YYYY-MM-DD")}`
      ).then((result) => {
        setUserAvailabilityStatus(result.status);
      });
    },
    [currentUser, open]
  );

  useEffect(() => {
    if (values.duration === undefined || values.duration === null || !durations.includes(values.duration)) {
      handleChange({
        target: {
          name: "duration",
          value: 1,
        },
      });
    }
  }, [values.duration, handleChange]);

  useEffect(() => {
    fetchAvailabilityStatus(values.assignedUser, values.dueDate);
    updateMandatoryActivity(values.assignedUser, values.dueDate, values.type);
  }, [open]);

  const internalCloseDialog = () => {
    clearForm();
    closeDialog();
  };

  const updateMandatoryActivity = (assignedUser: string, dueDate: Moment, activityType: string) => {
    if (
      (moment().isSame(dueDate, "day") && currentUser["@id"] !== assignedUser) ||
      _.includes(["deadline", "peremptory_term", "court_date"], activityType)
    ) {
      handleChange({ target: { name: "mandatory", value: "true" } });
      setMandatorySelectDisabled(true);
      return;
    }

    if (!activity) {
      handleChange({ target: { name: "mandatory", value: "false" } });
    }
    setMandatorySelectDisabled(false);
  };

  const handleEditorStateChange = (editorState: EditorState) => {
    handleChange({
      target: {
        name: "note",
        value: editorState,
      },
    });
  };

  const getAgentsList = useCallback(() => {
    switch (true) {
      case !!activity:
        return agents;
      case !!product:
      default:
        return internals;
    }
  }, [activity, product, agents, internals]);

  const users = useMemo(() => {
    return getUsers(sortByRole(getAgentsList()), activity ? activity.case : product?.backofficeCase || null);
  }, [activity, product]);

  const activityTypes = useMemo(() => {
    return transformActivitySetToAutoCompleteOptions(getActivitiesSet(productClassName));
  }, [product]);

  const userAutoCompleteValue = _.find(users, (user) => user.id === (values.assignedUser || null));

  const activityTypeAutoCompleteValue = _.find(activityTypes, (activityType) => activityType.id === values.type);

  const handleActivityTypeValueChange = (event: SyntheticEvent, value: AutoCompleteOptionWithId | null) => {
    if (!value) {
      return;
    }
    //must be before handleChange to get the old value
    const previousActivityType = getActivityData(productClassName, formValue(values, "type"));

    handleChange({
      target: {
        name: "type",
        value: value.id,
      },
    });
    updateMandatoryActivity(values.assignedUser, values.dueDate, value.id);

    const activityData = getActivityData(productClassName, value.id);

    handleChange({
      target: {
        name: "duration",
        value: activityData?.defaultDuration ?? 1,
      },
    });

    if (!touchedValues.subject || _.includes([previousActivityType?.label, ""], formValue(values, "subject"))) {
      const changeDescriptionEvent = {
        target: {
          name: "subject",
          value: _.get(activityData, "prefillDescription") ? _.get(activityData, "label", "N/A") : "",
        },
      };
      handleChange(changeDescriptionEvent);
    }
  };

  const getActionsInfo = () => {
    if (_.includes(holidays, moment(values.dueDate).format("YYYY-MM-DD"))) {
      return (
        <Box sx={editActivityStyles.availabilityStatus}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <ReportProblemOutlined />
            Dieser Tag ist ein Feiertag
          </Stack>
        </Box>
      );
    }
    if (!_.includes(["", "available"], userAvailabilityStatus)) {
      return (
        <Box sx={editActivityStyles.availabilityStatus}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <ReportProblemOutlined />
            {availabilityInfoTranslations[userAvailabilityStatus as keyof typeof availabilityInfoTranslations]}
          </Stack>
        </Box>
      );
    } else if (!!values.assignedUser) {
      return <WorkingHoursHint dueDate={values.dueDate} dueTime={values.dueTime} assignedUser={values.assignedUser} />;
    }
  };

  const dueDateDisabled = activity && !!(activity.doneTime || activity.dueDate < moment().format("YYYY-MM-DD"));
  let dueDateValidators = [...requiredFieldDefault, ...dateFieldDefault];

  if (!dueDateDisabled) {
    dueDateValidators.push({
      validator: isTodayOrFutureDate,
      message: "Es können keine vergangenen Tage ausgewählt werden",
    });
  }

  return (
    <LegalbirdIoModal
      handleClose={internalCloseDialog}
      open={open}
      disableBackdropClick
      title={title}
      submitButton={
        <SubmitButton
          onClick={handleSubmit}
          variant={"contained"}
          error={_.keys(errors).length > 0}
          disabled={_.keys(touchedValues).length === 0 || values.type === "no_type" || !values.assignedUser}
        >
          Speichern
        </SubmitButton>
      }
      actionsInfo={getActionsInfo()}
    >
      <Grid container>
        <Grid item xs={12} sm={6} sx={editActivityStyles.activityGridItem}>
          <LegalbirdAutoComplete<AutoCompleteOptionWithId>
            setValue={(e, value) => {
              if (!value) {
                return;
              }
              handleChange({
                target: {
                  name: "assignedUser",
                  value: value.id,
                },
              });
              updateMandatoryActivity(value.id, values.dueDate, values.type);
              fetchAvailabilityStatus(value.id, values.dueDate);
            }}
            getOptionDisabled={(option) => option.id === null}
            value={userAutoCompleteValue}
            options={users}
            label={"Zu erledigen durch"}
            disabled={!!activity?.doneTime}
            groupBy={(option) => option.orderBy || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={editActivityStyles.activityGridItem}>
          <LegalbirdAutoComplete<AutoCompleteOptionWithId>
            setValue={handleActivityTypeValueChange}
            getOptionDisabled={(option) => option.disabled || false}
            value={activityTypeAutoCompleteValue}
            options={activityTypes}
            label={"Aktivitätstyp"}
            disabled={!!activity?.doneTime}
          />
        </Grid>
        <Grid item xs={12} sx={editActivityStyles.activityGridItem}>
          <ValidatorTextField
            label={"Beschreibung"}
            name={"subject"}
            value={formValue(values, "subject")}
            onChange={handleChange}
            onBlur={handleBlur}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!errors["subject"]}
            helperText={errors["subject"]}
            inputProps={{ maxLength: 100 }}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={editActivityStyles.activityGridItem}>
          <ValidatorDateField
            format="DD.MM.YYYY"
            label={"Datum"}
            name={"dueDate"}
            disabled={dueDateDisabled}
            shouldDisableDate={(date) => {
              return date.day() === 0 || date.day() === 6;
            }}
            onChange={(date) => {
              if (!date) {
                return;
              }
              handleDateChange(date, "dueDate");
              updateMandatoryActivity(values.assignedUser, date, values.type);
              fetchAvailabilityStatus(values.assignedUser, date);
            }}
            handleBlur={handleBlur}
            value={formValue(values, "dueDate")}
            helperText={errors["dueDate"]}
            registerValidators={registerValidators}
            validators={dueDateValidators}
            disablePast
            slotProps={{
              toolbar: {
                hidden: false,
              },
            }}
            withSkipWeeksToolbar
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={editActivityStyles.activityGridItem}>
          <ValidatorTextField
            label={"Uhrzeit"}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{
              shrink: true,
            }}
            type={"time"}
            name={"dueTime"}
            disabled={!!activity?.doneTime}
            value={formValue(values, "dueTime")}
            registerValidators={registerValidators}
            error={!!errors["dueTime"]}
            helperText={errors["dueTime"]}
            validators={[]}
            isHighlighted={false}
            initialDependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={editActivityStyles.activityGridItem}>
          <ValidatorSelect
            label={"Pflicht?"}
            name={"mandatory"}
            value={formValue(values, "mandatory")}
            onChange={handleChange}
            disabled={mandatorySelectDisabled}
          >
            <MenuItem value={"true"}>Pflicht</MenuItem>
            <MenuItem value={"false"}>Keine Pflicht</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} sm={6} sx={editActivityStyles.activityGridItem}>
          <ValidatorSelect
            label={"Dauer"}
            name={"duration"}
            // "/backoffice_users/13" is the system user
            disabled={activity && !!(activity.creator === "/backoffice_users/13" || activity.doneTime)}
            value={formValue(values, "duration")}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors["duration"]}
            helperText={errors["duration"]}
          >
            {_.map(durations, (duration) => (
              <MenuItem key={duration} value={duration}>
                {duration} {duration === 1 ? "Minute" : "Minuten"}
              </MenuItem>
            ))}
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} sx={editActivityStyles.activityGridItem}>
          <Editor
            toolbar={{
              options: ["inline", "list", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            editorState={formValue(values, "note")}
            onEditorStateChange={handleEditorStateChange}
            editorStyle={editActivityStyles.editor}
            placeholder={"Notiz"}
            localization={{ locale: "de" }}
            spellCheck
          />
        </Grid>
      </Grid>
      {activity && <ActivityHistory activity={activity} productClassName={productClassName} />}
    </LegalbirdIoModal>
  );
};

const isAssignedUser = (user: BackofficeUser, backofficeCase: BackofficeCase | null) => {
  switch (true) {
    case _.isString(backofficeCase?.accountManager) || _.isString(backofficeCase?.lawyer):
      return (
        (backofficeCase?.accountManager as unknown as string) === user["@id"] ||
        (backofficeCase?.lawyer as unknown as string) === user["@id"]
      );
    case _.isObject(backofficeCase?.accountManager) || _.isObject(backofficeCase?.lawyer):
      return _.get(backofficeCase, "accountManager.id") === user.id || _.get(backofficeCase, "lawyer.id") === user.id;
    default:
      return false;
  }
};

const getUsers = (agents: BackofficeUser[], backofficeCase: BackofficeCase | null) => {
  const users = sortAssignedUsersToTop(agents, backofficeCase);
  const userOptions: AutoCompleteOptionWithId[] = _.map(users, (user) => {
    return {
      label: user.person.fullname,
      id: user["@id"],
      orderBy: isAssignedUser(user, backofficeCase) ? "Zuständig" : getRoleToDisplay(user),
    };
  });
  return [{ label: "Nicht zugeordnet", id: null }, ...userOptions];
};

export default ActivityForm;
