import React, {useMemo, useState} from "react";
import PopUpMenu from "../PopUpMenu/PopUpMenu";
import {MenuItem} from "@mui/material";
import EmailTransmissionReportModal from "./EmailTransmissionReportModal";
import ApiClient from "../../services/ApiClient";
import MediaObjectFormModal from "../Case/CaseUploadFile/MediaObjectFormModal";
import moment from "moment";
import {AbstractCase} from "../../types/AbstractCase";
import {EmailMessage} from "../../types/EmailMessage";
import {useCurrentUser} from "../../provider/CurrentUserProvider";
import DocumentClassLabelAiProvider from "../../provider/DocumentClassLabelAiProvider";
import ActionIdentifierProvider from "../../provider/ActionIdentifierProvider";

type EmailMoreMenuProps = {
  emailMessage: EmailMessage;
  product: AbstractCase;
  refetchEmails: Function;
};

export default function EmailMoreMenu({emailMessage, product, refetchEmails}: EmailMoreMenuProps) {
  const [emailTransmissionReportModalOpen, setEmailTransmissionReportModalOpen] = useState(false);
  const [addMailAsMediaObjectModalOpen, setAddMailAsMediaObjectModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();

  const toggleHiddenExternally = async () => {
    setIsLoading(true);
    await ApiClient.put(emailMessage["@id"], {
      body: JSON.stringify({
        hiddenExternally: !emailMessage.hiddenExternally,
      }),
    });
    await refetchEmails();
    setIsLoading(false);
  };

  const transmissionReportDisabled = useMemo(() => {
    const sender = emailMessage.from?.email;
    return (
      !sender ||
      (sender !== "service@legalbird.de" && sender !== "anwalt@legalbird-kanzlei.de") ||
      moment(emailMessage.created).add(30, "days").isBefore(moment(), "days")
    );
  }, [emailMessage]);

  return (
    <>
      <PopUpMenu>
        <MenuItem onClick={() => setAddMailAsMediaObjectModalOpen(true)}>Als Datei hinzufügen</MenuItem>
        <MenuItem onClick={toggleHiddenExternally} disabled={isLoading}>
          Für Extern {emailMessage.hiddenExternally ? "sichtbar" : "unsichtbar"} machen
        </MenuItem>
        <MenuItem disabled={transmissionReportDisabled} onClick={() => setEmailTransmissionReportModalOpen(true)}>
          Sendebericht anfordern
        </MenuItem>
      </PopUpMenu>
      <EmailTransmissionReportModal
        emailMessage={emailMessage}
        product={product}
        isOpen={emailTransmissionReportModalOpen}
        setIsOpen={setEmailTransmissionReportModalOpen}
      />
      <DocumentClassLabelAiProvider>
        <ActionIdentifierProvider>
          <MediaObjectFormModal
            product={product}
            closeDialog={() => setAddMailAsMediaObjectModalOpen(false)}
            open={addMailAsMediaObjectModalOpen}
            requestUri={"/lb/services/generate_file_from_mail"}
            contentType={"application/json"}
            errorMessage={"Email konnte nicht umgewandelt werden"}
            fileData={{
              emailContent: emailMessage.messageContent.html || emailMessage.messageContent.text,
              originalName: `${moment(emailMessage.created).format("YYYYMMDD")}_${product.reference}_Email_von_${moment(
                emailMessage.created
              ).format("HH-mm")}Uhr.pdf`,
              createdBy: currentUser["@id"],
              emailInfoString: `E-Mail von <i>${emailMessage?.from?.email}</i> an <i>${
                emailMessage?.to?.email
              }</i> am ${moment(emailMessage.created).format("DD.MM.YYYY")} um ${moment(emailMessage.created).format(
                "HH:mm"
              )} Uhr | Betreff: ${emailMessage.messageContent.subject}`,
            }}
          />
        </ActionIdentifierProvider>
      </DocumentClassLabelAiProvider>
    </>
  );
}
