import _ from "lodash";
import { BackofficeUser, Roles } from "../types/BackofficeUser";
import { BackofficeCase } from "../types/BackofficeCase";

export function userHasOneOfTheseRoles(backofficeUser: BackofficeUser, rolesToCheck: Roles[]) {
  return _.intersection(backofficeUser.roles, rolesToCheck).length > 0;
}

export function userHasRole(backofficeUser: BackofficeUser, role: Roles) {
  return userHasOneOfTheseRoles(backofficeUser, [role]);
}

export function getRoleToDisplay(agent: BackofficeUser) {
  //accounting must be before accountmanager so that harald gets the right role
  switch (true) {
    case userHasRole(agent, Roles.admin):
      return "Admin";
    case userHasRole(agent, Roles.accounting):
      return "Buchhaltung";
    case userHasRole(agent, Roles.accountmanager):
      return "Operations";
    case userHasRole(agent, Roles.lawyer):
      return "Anwälte";
    case userHasRole(agent, Roles.traineeLawyer):
      return "Referendare";
    case userHasRole(agent, Roles.external):
      return "Externe Anwälte";
    case userHasRole(agent, Roles.callcenter):
      return "Externer Kundenberater";
    default:
      return "";
  }
}

export function sortByRole(users: BackofficeUser[]) {
  if (!users) {
    return [];
  }

  const accountManagers = _.filter(
    users,
    (user) =>
      userHasRole(user, Roles.accountmanager) &&
      //special case for harald so that he gets accounting instead of accountmanager
      !userHasRole(user, Roles.accounting)
  );
  const internalLawyers = _.filter(users, (user) => userHasRole(user, Roles.lawyer));
  const externalLawyers = _.filter(users, (user) => userHasRole(user, Roles.external));
  const accountingEmployees = _.filter(users, (user) => userHasRole(user, Roles.accounting));
  const callCenterEmployees = _.filter(users, (user) => userHasRole(user, Roles.callcenter));
  const traineeLawyers = _.filter(users, (user) => userHasRole(user, Roles.traineeLawyer));

  return [
    ...accountManagers,
    ...internalLawyers,
    ...externalLawyers,
    ...accountingEmployees,
    ...callCenterEmployees,
    ...traineeLawyers,
  ];
}

export function sortAssignedUsersToTop(users: BackofficeUser[] | null, backofficeCase: BackofficeCase | null) {
  if (!users || !backofficeCase) {
    return [];
  }

  const accountManager = _.remove(users, (user) => {
    if (_.isString(backofficeCase.accountManager)) {
      return user["@id"] === backofficeCase.accountManager;
    }
    return user.id === backofficeCase.accountManager?.id;
  });

  const lawyer = _.remove(users, (user) => {
    if (_.isString(backofficeCase.lawyer)) {
      return user["@id"] === backofficeCase.lawyer;
    }
    return user.id === backofficeCase.lawyer?.id;
  });

  if (lawyer.length > 0) {
    users.unshift(lawyer[0]);
  }

  if (accountManager.length > 0) {
    users.unshift(accountManager[0]);
  }

  return users;
}

export function sortAlphabetically(users: BackofficeUser[]) {
  if (!users) {
    return [];
  }
  return _.sortBy(users, (user) => user.person.fullname);
}

export const getCreatedBy = (createdBy: string | null, backofficeUsers: BackofficeUser[]) => {
  switch (createdBy) {
    case "system":
      return "System";
    case "customer":
      return "Mandant";
    default:
      if (createdBy === null) {
        return "Unbekannt";
      }
      const createdByUser = _.find(backofficeUsers, (backofficeUser) => {
        return backofficeUser["@id"] === createdBy;
      });
      if (!createdByUser) {
        return "Unbekannter Nutzer";
      }
      return createdByUser.person.fullname;
  }
};
