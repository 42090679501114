import React from "react";
import ValidatorSelect, { ValidatorSelectProps } from "../Validator/ValidatorSelect";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem";
import { insurances } from "../../services/formServiceFunctions";

type InsurancePickerProps = {
  name?: string;
  value?: any;
  disabled: boolean;
  label: string;
  isMandatory?: boolean;
} & Omit<ValidatorSelectProps, "children">;
const InsurancePicker = ({
  disabled = false,
  label = "Versicherungsgesellschaft",
  isMandatory = false,
  ...rest
}: InsurancePickerProps) => {
  const sortableLabel = (label: string) => {
    let newLabel = label.toLowerCase();
    newLabel = _.replace(newLabel, "ö", "o");
    newLabel = _.replace(newLabel, "ä", "a");
    newLabel = _.replace(newLabel, "ü", "u");
    return newLabel;
  };

  const sortedInsurances = _.sortBy(insurances, (insurance) => {
    return sortableLabel(insurance.label);
  });

  return (
    <ValidatorSelect label={label} disabled={disabled} isHighlighted={isMandatory} {...rest}>
      <MenuItem disabled value={"__null__"}>
        {" "}
      </MenuItem>
      {_.map(sortedInsurances, (insurance) => {
        return (
          <MenuItem key={insurance.id} value={insurance.id}>
            {insurance.label}
          </MenuItem>
        );
      })}
    </ValidatorSelect>
  );
};

export default InsurancePicker;
