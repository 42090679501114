import React from "react";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import Paper from "@mui/material/Paper";
import { dutyNotificationStyles } from "../Notifications/dutyNotificationStyles";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import ActivityNote from "../Activities/ActivityNote";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export type SystemNoteNotificationSnackbarProps = {
  systemNote: any;
  caseLink: string;
};

export const SystemNoteNotificationSnackbar = React.forwardRef<
  HTMLDivElement,
  SystemNoteNotificationSnackbarProps & CustomContentProps
>(({ systemNote, caseLink }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const closeNotification = () => {
    closeSnackbar();
  };
  return (
    <SnackbarContent ref={ref}>
      <Paper sx={dutyNotificationStyles.wideNotificationContainer}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={2} />
          <Grid item xs={8} sx={dutyNotificationStyles.headline}>
            Neue Systemnotiz!
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={dutyNotificationStyles.icon} onClick={closeNotification} size="large">
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={dutyNotificationStyles.noteContainerOverride}>
            <ActivityNote
              noteContent={systemNote.content}
              isFolded={true}
              noteContainerStyles={dutyNotificationStyles.noteContainerOverride}
            />
          </Grid>
          <Grid item xs={6}>
            <Button onClick={closeNotification} component={Link} to={caseLink + "/verlauf"}>
              Zum Verlauf
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={closeNotification}>Ok</Button>
          </Grid>
        </Grid>
      </Paper>
    </SnackbarContent>
  );
});
