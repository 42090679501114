const doneActivityStyle = {
  headline: {
    fontSize: "1rem",
    textDecoration: "line-through",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: "700px",
    flexGrow: 1,
  },
  activityActions: {
    minWidth: "30px",
  },
  placeholderGridItem: {
    width: "42px",
  },
  imageGridItem: {
    width: "72px",
  },
  activityContent: {
    width: "calc(100% - 72px)",
  },
  activityContainer: {
    margin: "30px 0 0 0",
  },
  outerActivityContainer: {
    position: "relative",
    width: "100%",
  },
};

export default doneActivityStyle;
