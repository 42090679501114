import {Theme} from "@mui/material";

const generalActionsStyle ={
  bottomNavigationContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
  },
  navigation: {
    backgroundColor: "#F1F4FB",
  },
  navigationActionLabel: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  }),
  navigationAction: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      minWidth: 70,
      maxWidth: 70,
    },
  }),
};

export default generalActionsStyle;
