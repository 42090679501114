import React from "react";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { SelectChangeEvent } from "@mui/material";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import ValidatorSelect from "../Validator/ValidatorSelect";
import HumanResource from "../../types/HumanResource";

type StandInPickerProps = {
  correspondingDate: string;
  allAbsences: Array<any>;
  blockedUsers: Array<string>;
  handleStandInChange: (event: SelectChangeEvent) => void;
  name: string;
  label: string;
  required?: boolean;
  absentUserId: number;
  humanResources: Array<HumanResource>;
};

export default function StandInPicker({
  correspondingDate,
  allAbsences,
  blockedUsers,
  handleStandInChange,
  name,
  label,
  absentUserId,
  humanResources,
  required = false,
}: StandInPickerProps) {
  const { lawyers } = useBackofficeUser();
  const correspondingAbsence = allAbsences.find(
    (absence) => absence.absenceDate.format("YYYY-MM-DD") === correspondingDate
  );

  if (lawyers.length < 1) {
    return <LegalbirdLoader />;
  }

  let availableUsers = humanResources.filter((humanResource: HumanResource) =>
    lawyers.find((lawyer) => lawyer["@id"] === humanResource.backofficeUser)
  );
  availableUsers = availableUsers.filter((humanResource: HumanResource) => {
    return (
      humanResource.backofficeUser !== "/backoffice_users/" + absentUserId &&
      !humanResource.absences.find((absence) => moment(absence.absenceDate).format("YYYY-MM-DD") === correspondingDate)
    );
  });

  return (
    <ValidatorSelect
      label={label}
      name={name}
      value={correspondingAbsence[name] || ""}
      onChange={handleStandInChange}
      isHighlighted={required}
      required={required} // !!!using this flag is an exception here, don't use it anywhere else!!!
    >
      <MenuItem value={""}>&nbsp;</MenuItem>
      {availableUsers.map((humanResource: HumanResource) => (
        <MenuItem
          key={humanResource.id}
          value={"/human_resources/" + humanResource.id}
          disabled={blockedUsers.includes("/human_resources/" + humanResource.id)}
        >
          <>
            {lawyers.find((lawyer) => lawyer["@id"] === humanResource.backofficeUser)?.person.fullname} (
            {humanResource?.standIns?.filter((standIn) => moment(standIn.absenceDate).isSame(correspondingDate, "day"))
              .length || 0}
            )
          </>
        </MenuItem>
      ))}
    </ValidatorSelect>
  );
}
