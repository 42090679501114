import React from "react";
import { InputLabel, Select, SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import { useSnackbar } from "notistack";
import assignedUserStyle from "./assignedUserStyle";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { Activity } from "../../../types/Activity";
import { BackofficeUser } from "../../../types/BackofficeUser";

type AssignedUserProps = {
  activity: Activity<BackofficeUser>;
  updateActivities?: Function;
};

const AssignedUser = ({ activity, updateActivities = () => {} }: AssignedUserProps) => {
  const { agents } = useBackofficeUser();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateMutation = useMutation(updateResource);

  const revertUserAssignment = () => {
    updateMutation.mutate({
      uri: "activities",
      id: activity.id,
      data: {
        assignedUser: null,
      },
    });
    closeSnackbar();
  };

  const setBackofficeUser = ({ target }: SelectChangeEvent) => {
    updateMutation
      .mutateAsync({
        uri: "activities",
        id: activity.id,
        data: {
          assignedUser: target.value,
        },
      })
      .then(() => {
        updateActivities();
        enqueueSnackbar("Änderung wurde gespeichert", {
          variant: "custom",
          buttonFunction: revertUserAssignment,
          buttonType: "function",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
  };

  if (!agents || !activity) {
    return null;
  }

  return (
    <>
      {activity.assignedUser ? (
        activity.assignedUser.person.fullname
      ) : (
        <FormControl>
          <InputLabel id={"userSelect"} sx={assignedUserStyle.smallFont}>
            Zu erledigen durch
          </InputLabel>
          <Select
            sx={assignedUserStyle.smallFont}
            onChange={setBackofficeUser}
            value={"not_assigned"}
          >
            <MenuItem value={"not_assigned"}>Nicht zugeordnet</MenuItem>
            {_.map(agents, (agent) => (
              <MenuItem key={agent.id} value={agent["@id"]}>
                {agent.person.fullname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default AssignedUser;
