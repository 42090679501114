export const activityOverviewHeaderStyles = {
  headline: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  activityCount: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  activitiesOverdue: {
    marginLeft: "2rem",
    color: "#a31b1b",
  },
  filterContainer: {
    padding: "1rem",
  },
  badge: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    borderColor: "#137f7b",
    textAlign: "center",
    padding: "5px",
    minWidth: 120,
    fontSize: 16,
  },
  activeBadge: {
    backgroundColor: "#137f7b",
    color: "#fff",
  },
  inactiveBadge: {
    color: "#333",
    cursor: "pointer",
  },
  subjectFilter: {
    height: "100%",
  },
  filterSelect: {
    borderBottom: { xs: "1px solid rgba(0, 0, 0, 0.42)", lg: "none" },
  },
};
