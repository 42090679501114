import HumanResource from "./HumanResource";
import { DateObject } from "react-multi-date-picker";

interface Absence {
  "@id"?: string;
  id?: number;
  absentHumanResource?: HumanResource | string;
  absentHumanResourceBackofficeUserId?: number;
  absentUserFullName?: string;
  absenceDate: string;
  standIn1: StandIn;
  standIn2: StandIn;
  standIn3: StandIn;
  created?: string;
  updated?: string;
}

export type StandIn = HumanResource | string | null;

export function standInIsHumanResource(standIn: StandIn): standIn is HumanResource {
  return !!standIn && typeof standIn === "object";
}

export type FormAbsence = Omit<Absence, "absenceDate"> & { absenceDate: DateObject; };

export default Absence;
