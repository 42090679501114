import React from "react";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import FormElement from "../FormElement";
import SubmitButton from "../../../Button/SubmitButton";
import useStyles from "./caseFullFormStyle";
import { getRequiredFields } from "../../../../services/StatusChangeValidators/statusChangeValidators";
import { transformToFormValues } from "../../../../services/formServiceFunctions";
import { persist } from "../../../../services/Persist/Persister";
import useForm, { UseFormProps } from "../../../../hooks/useForm";
import ValidatorForm from "../../../Validator/ValidatorForm";
import ContentBox from "../../../ContentBox/ContentBox";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../services/ReactQuery/reactQueryService";
import { getProductData } from "../../../../services/Product/ProductService";
import { HashLink as Link } from "react-router-hash-link";
import { SubFormDefinition } from "../../../../types/FormDefinitions/FullFormDefinition";
import { AbstractCase } from "../../../../types/AbstractCase";

type SubFormProps = {
  headline: string;
  formDefinition: SubFormDefinition;
  product: AbstractCase;
  formDisabled?: boolean;
  refreshPage: Function;
};

const SubForm = ({ headline, formDefinition, product, formDisabled = false, refreshPage }: SubFormProps) => {
  const classes = useStyles({});
  const mandatoryFields = getRequiredFields(product);
  const queryClient = useQueryClient();
  const initialValues = {
    [product.productClassName]: transformToFormValues(product),
  };

  const onSubmit: UseFormProps["onSubmit"] = async ({ values }) => {
    await persist(values, product);
    await queryClient.invalidateQueries(queryKeys.item(getProductData(product.productClassName, "apiUri"), product.id));
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: product,
  });

  const visibleSections = formDefinition.sections.filter(
    (section) =>
      !section.isHidden ||
      !section.isHidden({
        values: useFormProps.values,
        product,
      })
  );

  const getAnchorId = (formSectionLabel: string) => {
    return encodeURIComponent(`${headline}-${formSectionLabel}`.replaceAll(" ", "-").toLowerCase());
  };

  return (
    <ContentBox headline={headline} headlineVariant="h2">
      {visibleSections.length > 1 && (
        <Grid item xs={12} lg={8}>
          <Tabs variant={"fullWidth"} scrollButtons="auto" value={false} centered>
            {visibleSections.map((formSection, index) => {
              if (!formSection.label) {
                return null;
              }
              return (
                <Tab component={Link} to={`#${getAnchorId(formSection.label)}`} label={formSection.label} key={index} />
              );
            })}
          </Tabs>
        </Grid>
      )}
      <ValidatorForm onSubmit={useFormProps.handleSubmit}>
        {_.map(visibleSections, (formSection, index) => {
          return (
            <React.Fragment key={index}>
              <Box className={classes.scrollToAnchor} id={getAnchorId(formSection.label)} />
              <Grid container className={classes.grid}>
                <Grid item xs={12} className={classes.formHeadline}>
                  <Typography variant="h3">{formSection.label}</Typography>
                </Grid>
                {_.map(formSection.elements, (formElementProps) => {
                  if (
                    formElementProps.isHidden &&
                    formElementProps.isHidden({
                      values: useFormProps.values,
                      product,
                    })
                  ) {
                    return null;
                  }

                  //important! overwrites all individual isDisabled configurations
                  if (formDisabled) {
                    formElementProps.isDisabled = () => true;
                  }

                  const fullWidth = formElementProps.additionalProps && formElementProps.additionalProps.fullWidth;

                  return (
                    <Grid key={formElementProps.path} item xs={12} md={!fullWidth && 6} lg={!fullWidth && 4}>
                      <FormElement
                        product={product}
                        {...formElementProps}
                        {...useFormProps}
                        mandatoryFields={mandatoryFields}
                        refreshPage={refreshPage}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          );
        })}
        {!formDefinition.withoutSubmit && (
          <Grid container className={classes.grid}>
            <Grid item xs={12}>
              <SubmitButton
                error={_.keys(useFormProps.errors).length > 0}
                isLoading={useFormProps.isLoading}
                disabled={_.keys(useFormProps.touchedValues).length === 0}
                variant={"contained"}
                type={"submit"}
              >
                Änderungen Speichern
              </SubmitButton>
            </Grid>
          </Grid>
        )}
      </ValidatorForm>
    </ContentBox>
  );
};

export default SubForm;
