import React from "react";
import CircleIcon from "../../../CircleIcon/CircleIcon";
import _ from "lodash";
import { getActivityIcon } from "../../../../services/Activities/activityTypes";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import CaseHistoryActivityCalendlyAssessment from "./CaseHistoryActivityCalendlyAssessment";
import caseHistoryActivityStyle from "./caseHistoryActivityStyle";
import { ActivityHistoryLog } from "../../../../types/HistoryLog";
import { BackofficeUser } from "../../../../types/BackofficeUser";
import { Activity } from "../../../../types/Activity";

type CaseHistoryActivityProps = {
  historyItem: ActivityHistoryLog;
  productClassName: string;
};

const CaseHistoryActivity = ({ historyItem, productClassName }: CaseHistoryActivityProps) => {
  const activity: Activity<BackofficeUser> = historyItem.activity;

  const dateString = moment(activity.dueDate).format("DD.MM.YYYY");
  const timeString = activity.dueTime !== "allDay" ? "um " + activity.dueTime + " Uhr" : "";
  const subject =
    activity.subject +
    " " +
    dateString +
    (timeString ? " " + timeString : "") +
    " | " +
    (activity.assignedUser ? activity.assignedUser.person.fullname : "Nicht zugewiesen");

  const activityEventType = _.get(historyItem, "logData.activityEventType");

  return (
    <Box sx={caseHistoryActivityStyle.outerActivityContainer}>
      <Grid container sx={caseHistoryActivityStyle.activityContainer}>
        <Grid sx={caseHistoryActivityStyle.imageGridItem} item>
          <CircleIcon icon={getActivityIcon(productClassName, activity.type)} type={"muiIcon"} />
        </Grid>
        <Grid item sx={caseHistoryActivityStyle.activityContent}>
          <Grid container alignItems={"center"} wrap={"nowrap"}>
            <Grid item sx={caseHistoryActivityStyle.headline}>
              {subject}
            </Grid>
          </Grid>
          {activityEventType !== "deleted" &&
            "Erledigt: " +
              moment(historyItem.logDate).format("DD.MM.YYYY HH:mm") +
              " Uhr | " +
              historyItem.backofficeUser.person.fullname}
        </Grid>
      </Grid>
      {activity.note && (
        <Box sx={caseHistoryActivityStyle.noteContainer} dangerouslySetInnerHTML={{ __html: activity.note }} />
      )}
      {activity.deleted && (
        <>
          <Box sx={caseHistoryActivityStyle.overlay} />
          <Box sx={caseHistoryActivityStyle.deletedInfo}>
            {activityEventType === "deleted" &&
              "Gelöscht durch " +
                historyItem.backofficeUser.person.fullname +
                " | " +
                moment(historyItem.logDate).format("DD.MM.YYYY | HH:mm")}
          </Box>
        </>
      )}
      {["calendly_event", "lawyer_counseling_call"].includes(activity.type) &&
        activityEventType === "done" &&
        historyItem.logData?.calendlyAssessment && (
          <Box sx={{ margin: ".5rem 0 0 4.5rem" }}>
            <CaseHistoryActivityCalendlyAssessment calendlyAssessment={historyItem.logData.calendlyAssessment} />
          </Box>
        )}
    </Box>
  );
};

export default CaseHistoryActivity;
