import { useState } from "react";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { MediaObject } from "../../types/MediaObject";

type ChangeMediaObjectVisibilityModalProps = {
  mediaObject: MediaObject;
  updateMediaObjects: Function;
  changeVisibilityOpen: boolean;
  setChangeVisibilityOpen: Function;
};

export default function ChangeMediaObjectVisibilityModal({
  mediaObject,
  updateMediaObjects,
  changeVisibilityOpen,
  setChangeVisibilityOpen,
}: ChangeMediaObjectVisibilityModalProps) {
  const updateMutation = useMutation(updateResource);
  const [showInGallery, setShowInGallery] = useState(mediaObject.showInGallery);
  const [showInExternalView, setShowInExternalView] = useState(mediaObject.showInExternalView || false);

  const handleSave = () => {
    updateMutation
      .mutateAsync({
        uri: "media_objects",
        id: mediaObject.id,
        data: {
          showInGallery: showInGallery,
          showInExternalView: showInExternalView,
        },
      })
      .then(() => updateMediaObjects());
    setChangeVisibilityOpen(false);
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setChangeVisibilityOpen(false);
      }}
      open={changeVisibilityOpen}
      title={"Sichtbarkeit einstellen"}
      submitButton={
        <Button
          variant={"contained"}
          onClick={handleSave}
          disabled={
            showInExternalView === mediaObject.showInExternalView && showInGallery === mediaObject.showInGallery
          }
        >
          Speichern
        </Button>
      }
    >
      <Stack>
        Für wen soll das Dokument sichtbar sein?
        <FormControlLabel
          control={
            <Checkbox checked={showInExternalView} onChange={(event) => setShowInExternalView(event.target.checked)} />
          }
          label={"Für Extern sichtbar"}
        />
        <FormControlLabel
          control={<Checkbox checked={showInGallery} onChange={(event) => setShowInGallery(event.target.checked)} />}
          label={"Für Mandantschaft sichtbar"}
        />
      </Stack>
    </LegalbirdIoModal>
  );
}
