import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import ActivityForm from "./ActivityForm";
import { getProductNameBy } from "../../../services/Product/ProductService";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import ReactGA from "react-ga4";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { Activity } from "../../../types/Activity";
import { UseFormProps } from "../../../hooks/useForm";

type EditActivityProps = {
  open: boolean;
  closeDialog: Function;
  activity: Activity;
  updateActivities: Function;
  productClassName?: string;
};

const EditActivity = ({ open, closeDialog, activity, updateActivities, productClassName }: EditActivityProps) => {
  const updateMutation = useMutation(updateResource);
  const [initialValues, setInitialValues] = useState<Record<string, any>>();
  const [activityFormKey, setActivityFormKey] = useState(0);
  const { getUserData } = useBackofficeUser();

  useEffect(() => {
    const formValues = ["type", "subject", "dueDate", "dueTime", "mandatory", "assignedUser", "note", "duration"];
    let activityValues: Record<string, any> = _.merge({}, _.pick(activity, formValues));
    if (typeof activity.assignedUser === "object" && activity.assignedUser) {
      activityValues.assignedUser = activity.assignedUser["@id"]!;
    }
    if (activityValues.dueDate && moment(activityValues.dueDate).isValid()) {
      activityValues.dueDate = moment(activityValues.dueDate);
    }
    if (activityValues.dueTime === "allDay") {
      activityValues.dueTime = "";
    }
    activityValues.note = htmlToEditorState(activityValues.note);
    setInitialValues(activityValues);
  }, [activity]);

  useEffect(() => {
    if (initialValues) {
      setActivityFormKey((prevState) => prevState + 1);
    }
  }, [initialValues]);

  const onSubmit: UseFormProps["onSubmit"] = async ({ values }) => {
    let updateData = _.merge({}, values);
    updateData.dueTime = updateData.dueTime !== "" ? updateData.dueTime : "allDay";
    updateData.note = editorStateToHTML(updateData.note, true);
    updateData.mandatory = updateData.mandatory === "true";

    await updateMutation.mutateAsync({ id: activity.id || 0, uri: "activities", data: updateData });
    await updateActivities();
    setInitialValues(values);

    closeDialog();
    const assignedUser =
      typeof activity.assignedUser === "object" ? activity.assignedUser : getUserData(activity.assignedUser);
    if (!assignedUser) {
      return;
    }
    if (moment(activity.dueDate) !== updateData.dueDate && moment(activity.dueDate) < updateData.dueDate) {
      ReactGA.set({ userId: assignedUser.id });
      ReactGA.event({
        category: "Activity",
        action: "Postponed",
        label: assignedUser.person.givenName + " " + assignedUser.person.familyName,
      });
    }
  };

  const calculatedProductClassName =
    productClassName || getProductNameBy("lbProductClass", activity.case.lbProductClass);

  if (!initialValues || !calculatedProductClassName) {
    return null;
  }

  return (
    <ActivityForm
      key={"activityForm" + activityFormKey}
      open={open}
      initialValues={initialValues}
      onSubmit={onSubmit}
      closeDialog={closeDialog}
      productClassName={calculatedProductClassName}
      activity={activity}
      title={"Aktivität bearbeiten"}
    />
  );
};

export default EditActivity;
