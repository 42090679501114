import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  menuButton: {
    margin: "0 2rem",
  },
  filterHeadline: {
    fontSize: "1rem",
    fontWeight: 700,
    textAlign: "center",
    marginTop: "1rem",
  },
  menu: {
    minWidth: 250,
  },
  filterIcon: {
    color: "#333333",
  },
  clearRefinements: {
    marginBottom: "1rem",
  },
}));
