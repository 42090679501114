import React from "react";
import { Chip, Grid } from "@mui/material";
import { FolderOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { MediaObject } from "../../types/MediaObject";

type MediaObjectChipsProps = {
  mediaObject: MediaObject;
  showFolder: boolean;
};

export default function MediaObjectChips({ mediaObject, showFolder = true }: MediaObjectChipsProps) {
  return (
    <Grid container spacing={1}>
      {showFolder && (
        <Grid item>
          <Chip
            label={mediaObject.electronicFileFolderPath}
            icon={<FolderOutlined />}
            size={"small"}
            variant={"outlined"}
          />
        </Grid>
      )}
      {mediaObject.showInExternalView && (
        <Grid item>
          <Chip label={"Für Extern sichtbar"} icon={<VisibilityOutlined />} size={"small"} variant={"outlined"} />
        </Grid>
      )}
      {mediaObject.showInGallery && (
        <Grid item>
          <Chip
            label={"Für Mandantschaft sichtbar"}
            icon={<VisibilityOutlined />}
            size={"small"}
            variant={"outlined"}
          />
        </Grid>
      )}
      {!mediaObject.showInExternalView && !mediaObject.showInGallery && (
        <Grid item>
          <Chip label={"Nur intern sichtbar"} icon={<VisibilityOffOutlined />} size={"small"} variant={"outlined"} />
        </Grid>
      )}
    </Grid>
  );
}

