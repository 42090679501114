import { IconButton, Menu, MenuItem } from "@mui/material";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { MoreHoriz } from "@mui/icons-material";
import ChangeInvoiceTypeModal from "./ChangeInvoiceTypeModal";
import React, { useState } from "react";
import { BookingEntryWithDifference } from "../../types/BookingEntry";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import InvoiceToCreditNoteModal from "./InvoiceToCreditNoteModal";
import DeleteBookingEntryModal from "./DeleteBookingEntryModal";
import ReassignBookingEntryModal from "./ReassignBookingEntryModal";
import IsThirdPartyFundsModal from "./IsThirdPartyFundsModal";

type BookingEntryAdditionalActionsProps = {
  bookingEntry: BookingEntryWithDifference;
  updateBookingEntries: () => void;
};
export default function AdditionalAccountingActions({
  bookingEntry,
  updateBookingEntries,
}: BookingEntryAdditionalActionsProps) {
  const currentUser = useCurrentUser();
  const userIsAdminOrAccounting = userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.accounting]);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const moreMenuAnchorRef = React.useRef(null);
  const [modalOpen, setModalOpen] = useState("");

  const handleOpen = (modalName: string) => {
    setModalOpen(modalName);
    setMoreMenuOpen(false);
  };
  const handleClose = () => {
    setModalOpen("");
  };

  if (!userIsAdminOrAccounting || (bookingEntry.type === "invoice" && bookingEntry.debitOrCredit === "credit")) {
    return null;
  }

  return (
    <>
      <IconButton onClick={() => setMoreMenuOpen(!moreMenuOpen)} size="medium">
        <MoreHoriz ref={moreMenuAnchorRef} />
      </IconButton>
      {bookingEntry.type === "invoice" && bookingEntry.debitOrCredit === "debit" && (
        //rechnung
        <>
          <Menu
            open={moreMenuOpen}
            anchorEl={moreMenuAnchorRef.current}
            onClose={() => setMoreMenuOpen(false)}
            disableScrollLock={true}
          >
            <MenuItem
              onClick={() => {
                handleOpen("changeInvoiceType");
              }}
            >
              Rechnungstyp editieren
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpen("invoiceToCreditNote");
              }}
            >
              Rechnung ausbuchen
            </MenuItem>
          </Menu>
          <ChangeInvoiceTypeModal
            bookingEntry={bookingEntry}
            open={modalOpen === "changeInvoiceType"}
            handleClose={handleClose}
            updateBookingEntries={updateBookingEntries}
          />
          <InvoiceToCreditNoteModal
            bookingEntry={bookingEntry}
            open={modalOpen === "invoiceToCreditNote"}
            handleClose={handleClose}
            updateBookingEntries={updateBookingEntries}
          />
        </>
      )}
      {bookingEntry.type === "payment" && bookingEntry.debitOrCredit === "debit" && (
        //auszahlung
        <>
          <Menu
            open={moreMenuOpen}
            anchorEl={moreMenuAnchorRef.current}
            onClose={() => setMoreMenuOpen(false)}
            disableScrollLock={true}
          >
            <MenuItem
              onClick={() => {
                handleOpen("deleteBookingEntry");
              }}
            >
              Buchungseintrag löschen
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpen("isThirdPartyFunds");
              }}
            >
              Fremdgeld / kein Fremdgeld
            </MenuItem>
          </Menu>
          <DeleteBookingEntryModal
            bookingEntry={bookingEntry}
            open={modalOpen === "deleteBookingEntry"}
            handleClose={handleClose}
            updateBookingEntries={updateBookingEntries}
          />
          <IsThirdPartyFundsModal
            bookingEntry={bookingEntry}
            open={modalOpen === "isThirdPartyFunds"}
            handleClose={handleClose}
            updateBookingEntries={updateBookingEntries}
          />
        </>
      )}
      {bookingEntry.type === "payment" && bookingEntry.debitOrCredit === "credit" && (
        //einzahlung
        <>
          <Menu
            open={moreMenuOpen}
            anchorEl={moreMenuAnchorRef.current}
            onClose={() => setMoreMenuOpen(false)}
            disableScrollLock={true}
          >
            <MenuItem
              onClick={() => {
                handleOpen("deleteBookingEntry");
              }}
            >
              Buchungseintrag löschen
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpen("isThirdPartyFunds");
              }}
            >
              Fremdgeld / kein Fremdgeld
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpen("reassignBookingEntry");
              }}
            >
              Auf anderen Fall umschlüsseln
            </MenuItem>
          </Menu>
          <DeleteBookingEntryModal
            bookingEntry={bookingEntry}
            open={modalOpen === "deleteBookingEntry"}
            handleClose={handleClose}
            updateBookingEntries={updateBookingEntries}
          />
          <IsThirdPartyFundsModal
            bookingEntry={bookingEntry}
            open={modalOpen === "isThirdPartyFunds"}
            handleClose={handleClose}
            updateBookingEntries={updateBookingEntries}
          />
          <ReassignBookingEntryModal
            bookingEntry={bookingEntry}
            open={modalOpen === "reassignBookingEntry"}
            handleClose={handleClose}
            updateBookingEntries={updateBookingEntries}
          />
        </>
      )}
    </>
  );
}
