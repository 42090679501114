const casePaidTaskStyle = {
  formElementsContainer: {
    margin: "0 auto",
  },
  formElement: {
    margin: "2rem 0",
  },
  subHeadline: {
    textAlign: "center",
    margin: "2rem 0 3rem 0",
  },
  submitButton: {
    minWidth: 200,
  },
  contentPadding: {
    padding: "2rem 0",
  },
  buttonArea: {
    marginTop: "2rem",
  },
  verticalSpace: {
    height: "2rem",
  },
  container: {
    textAlign: "center",
  },
};

export default casePaidTaskStyle;
