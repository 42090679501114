import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../services/ReactQuery/reactQueryService";

interface InvoiceTypeIdentifiersContextInterface {
  getInvoiceTypeIdentifiersForProduct: (product: string) => Record<string, string> | null;
  isLoading: boolean;
  translateInvoiceTypeIdentifier: (product: string, identifier: string) => string;
}

const InvoiceTypeIdentifiersContext = React.createContext<InvoiceTypeIdentifiersContextInterface | null>(null);

export default function InvoiceTypeIdentifiersProvider({ children }: { children?: React.ReactNode }) {
  const { data: invoiceTypeIdentifiers, isLoading } = useQuery(
    queryKeys.all("invoiceTypeIdentifiers"),
    () => fetchCollection("lb/services/invoice_type_identifiers"),
    {
      retry: false,
    }
  );

  const getInvoiceTypeIdentifiersForProduct = (product: string) => {
    if (!invoiceTypeIdentifiers) {
      return null;
    }
    return Object.assign({}, invoiceTypeIdentifiers.common, invoiceTypeIdentifiers[product]);
  };

  const translateInvoiceTypeIdentifier = (product: string, identifier: string): string => {
    if (identifier === "none") {
      // This used to happen when creating a new booking entry from the backoffice
      return "Kein Typ";
    }
    const availableInvoiceTypeIdentifiers = getInvoiceTypeIdentifiersForProduct(product);
    if (!availableInvoiceTypeIdentifiers) {
      return "";
    }
    if (!availableInvoiceTypeIdentifiers[identifier]) {
      return `Übersetzung fehlt: ${identifier}`;
    }
    return availableInvoiceTypeIdentifiers[identifier];
  };

  const data: InvoiceTypeIdentifiersContextInterface = {
    getInvoiceTypeIdentifiersForProduct: getInvoiceTypeIdentifiersForProduct,
    isLoading,
    translateInvoiceTypeIdentifier,
  };

  return <InvoiceTypeIdentifiersContext.Provider value={data}>{children}</InvoiceTypeIdentifiersContext.Provider>;
}

export const useInvoiceTypeIdentifiers = () => {
  const context = useContext(InvoiceTypeIdentifiersContext);
  if (!context) {
    throw new Error("useInvoiceTypeIdentifiers can only be used inside InvoiceTypeIdentifiersContext");
  }
  return context;
};
