import { Box, Chip, Menu, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { DraftsOutlined, MailOutline, MoreHoriz, Reply, UnfoldLess, UnfoldMore } from "@mui/icons-material";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { Conversation } from "../../types/Messaging/Conversation";
import CaseMessage from "./CaseMessage";
import Button from "@mui/material/Button";
import apiClient from "../../services/ApiClient";
import { translate } from "../../services/Translations/translatorService";
import { markMessagesAsRead } from "../../services/messagingService";
import { AbstractCase } from "../../types/AbstractCase";
import { Channel } from "../../types/Messaging/Channel";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import ComposeEmailOrMessageDrawer from "../Case/CaseWriteEmail/ComposeEmailOrMessageDrawer";

function CaseMessagesConversation({
  conversation,
  refetchConversations,
  product,
}: {
  conversation: Conversation;
  refetchConversations: Function;
  product: AbstractCase;
}) {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [answerMessageDrawerOpen, setAnswerMessageDrawerOpen] = useState(false);
  const [answerMessageDrawerOpenMaximized, setAnswerMessageDrawerOpenMaximized] = useState(true);
  const allMessagesRead = conversation.containedMessages?.every((message) => message.readByMe);
  const isHiddenFromExternals = conversation.channel.type === "internalPlusCustomer";
  const currentUser = useCurrentUser();

  const sortedMessages = conversation.containedMessages?.sort((a, b) => {
    return moment(b.created).unix() - moment(a.created).unix();
  });

  async function toggleHiddenFromExternals() {
    const newHiddenFromExternals = !isHiddenFromExternals;
    const allChannels = await apiClient.get("/channels?" + new URLSearchParams({ case: String(product.id) }));
    const newChannel = allChannels["hydra:member"].find(
      (channel: Channel) => channel.type === (newHiddenFromExternals ? "internalPlusCustomer" : "all")
    );
    await apiClient.put("/conversations/" + conversation.id, {
      body: JSON.stringify({
        channel: `/channels/${newChannel.id}`,
      }),
    });
    refetchConversations();
  }

  return (
    <>
      <TableRow sx={expanded ? { "& td": { borderBottom: "none" } } : null}>
        <TableCell padding={"checkbox"}>
          <Stack alignItems={"center"}>
            {allMessagesRead ? <DraftsOutlined color={"secondary"} /> : <MailOutline color={"secondary"} />}
            {conversation.channel.type === "internalPlusLegalExpenseInsurance" && <Chip label={"RSV"}/>}
          </Stack>
        </TableCell>
        <TableCell>
          <Typography fontWeight={allMessagesRead ? "normal" : "bold"}>{conversation.subject}</Typography>
          {conversation.latestMessageCreatedDate !== conversation.created && (
            <Typography>
              Neuste Nachricht: {moment(conversation.latestMessageCreatedDate).format("DD.MM.YYYY, HH:mm")} Uhr
            </Typography>
          )}
        </TableCell>
        <TableCell align={"right"}>
          <Typography>{moment(conversation.created).format("DD.MM.YYYY, HH:mm") + " Uhr"}</Typography>
        </TableCell>
        <TableCell align={"right"}>
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setActionMenuOpen(!actionMenuOpen);
            }}
            size="medium"
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            open={actionMenuOpen}
            anchorEl={anchorEl}
            onClose={() => {
              setActionMenuOpen(false);
            }}
          >
            {!currentUser.isExternal && (
              <MenuItem
                onClick={() => {
                  setActionMenuOpen(false);
                  toggleHiddenFromExternals();
                }}
              >
                {isHiddenFromExternals ? "Für Extern sichtbar machen" : "Für Extern unsichtbar machen"}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setActionMenuOpen(false);
                apiClient
                  .put("/conversations/" + conversation.id, {
                    body: JSON.stringify({
                      isClosed: !conversation.isClosed,
                    }),
                  })
                  .then(() => {
                    refetchConversations();
                  });
              }}
            >
              {conversation.isClosed ? "Unterhaltung öffnen" : "Unterhaltung schließen"}
            </MenuItem>
          </Menu>
          <IconButton
            onClick={() => {
              setExpanded(!expanded);
              markMessagesAsRead(conversation.containedMessages || []).then(() => {
                refetchConversations();
              });
            }}
            size="medium"
          >
            {expanded ? <UnfoldLess /> : <UnfoldMore />}
          </IconButton>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell colSpan={8} padding={"none"}>
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems={"end"}
              sx={{
                marginBottom: "0.5rem",
              }}
            >
              <Box>
                <Button
                  disabled={conversation.isClosed}
                  startIcon={<Reply />}
                  onClick={() => setAnswerMessageDrawerOpen(true)}
                >
                  Antworten
                </Button>
                {answerMessageDrawerOpen && (
                  <ComposeEmailOrMessageDrawer
                    setMessageDrawerOpen={setAnswerMessageDrawerOpen}
                    messageConfig={{ conversationIri: "/conversations/" + conversation.id }}
                    closeDialog={() => setAnswerMessageDrawerOpen(false)}
                    messageDrawerMaximized={answerMessageDrawerOpenMaximized}
                    setMessageDrawerMaximized={() => setAnswerMessageDrawerOpenMaximized((prevState) => !prevState)}
                  />
                )}
              </Box>
            </Stack>
            {conversation.isClosed && (
              <Typography textAlign={"center"} fontWeight={"bold"}>
                Diese Unterhaltung wurde beendet. Sie können nicht mehr darauf antworten.
              </Typography>
            )}
            {sortedMessages?.map((message) => {
              return <CaseMessage key={message.id} message={message} />;
            })}
            <Typography sx={{ marginBottom: "1rem" }}>
              <strong>Kategorie:</strong> {translate(`messaging.conversation.type.values.${conversation.type}`)}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default CaseMessagesConversation;
