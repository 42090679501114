import PageContentContainer from "../../../Container/PageContentContainer";
import CaseOverviewPageLoader from "../../../ContentLoader/CaseOverviewPageLoader";
import CaseNotFound from "../../../Case/CaseNotFound/CaseNotFound";
import CaseError from "../../../Case/CaseError/CaseError";
import { Grid } from "@mui/material";
import ExternalsCaseHeader from "../../../Externals/Case/ExternalsCaseHeader/ExternalsCaseHeader";
import Contact from "../../../Externals/Case/Contact/Contact";
import CaseData from "../../../Externals/Case/CaseData/CaseData";
import MediaObjects from "../../../MediaObjectSection/MediaObjects";
import PinnedNotes from "../../../PinnedNotes/PinnedNotes";
import PaidTasksCase from "../../../PaidTask/PaidTasksCase/PaidTasksCase";
import Emails from "../../../Email/Emails";
import NavigationExtensionCasePage from "../../../NavigationBar/NavigationExtensionCasePage";
import { useCase } from "../../../../provider/CaseProvider";
import CaseNotAllowed from "../../../Case/CaseNotFound/CaseNotAllowed";
import CaseActivities from "../../../Activities/CaseActivities";
import CaseMessagesBox from "../../../Messages/CaseMessagesBox";

const ExternalsCasePage = () => {
  const { product, error, isLoading } = useCase();

  if (isLoading) {
    return (
      <PageContentContainer>
        <CaseOverviewPageLoader />
      </PageContentContainer>
    );
  }

  if (error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  if (error?.status === 403) {
    return <CaseNotAllowed />;
  }

  if (!!error) {
    return <CaseError errorMessage={error.error} errorStatus={error.status} />;
  }

  if (!product) {
    return null;
  }

  return (
    <>
      <NavigationExtensionCasePage product={product} />
      <PageContentContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ExternalsCaseHeader product={product} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Contact product={product} />
            <CaseData product={product} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <PinnedNotes product={product} isEditable={false} />
            <PaidTasksCase product={product} />
            <CaseActivities product={product} />
            <MediaObjects product={product} />
            <CaseMessagesBox product={product} />
            <Emails product={product} />
          </Grid>
        </Grid>
      </PageContentContainer>
    </>
  );
};

export default ExternalsCasePage;
