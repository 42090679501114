import React, { useState } from "react";
import { Typography } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { MediaObject } from "../../types/MediaObject";
import { apiPut } from "../../services/Api/apiCall";
import { useSnackbar } from "notistack";

import ButtonLoading from "../Button/ButtonLoading";

type CopyMediaObjectModalProps = {
  mediaObject: MediaObject;
  updateMediaObjects: Function;
  changeFieldNameOpen: boolean;
  setChangeFieldNameOpen: Function;
};

export default function ChangeFieldNameModal({
  mediaObject,
  updateMediaObjects,
  changeFieldNameOpen,
  setChangeFieldNameOpen,
}: CopyMediaObjectModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    setIsLoading(true);
    let snackbarMessage = "Dokument wurde erfolgreich als außergerichtliches Schreiben markiert";
    try {
      await apiPut("media_objects", mediaObject.id, { fieldname: "extrajudicialLetter" });
    } catch (e) {
      snackbarMessage = "Dokument konnte nicht als außergerichtliches Schreiben markiert werden";
    }

    enqueueSnackbar(snackbarMessage, {
      variant: "custom",
      isNonInteractive: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      persist: true,
    });

    updateMediaObjects();
    setIsLoading(false);
    setChangeFieldNameOpen(false);
  };

  return (
    <LegalbirdIoModal
      title={"Als außergerichtliches Schreiben kennzeichnen"}
      open={changeFieldNameOpen}
      handleClose={() => setChangeFieldNameOpen(false)}
      submitButton={
        <ButtonLoading isLoading={isLoading} onClick={onSubmit} variant={"contained"}>
          Dokument kennzeichnen
        </ButtonLoading>
      }
    >
      <Typography>
        Mit dieser Funktion kann das Dokument als außergerichtliches Schreiben gekennzeichnet werden. Das sorgt dafür,
        dass dieses Dokument automatisch an eine Deckungsanfrage für das gerichtliche Verfahren angehängt wird.
      </Typography>
    </LegalbirdIoModal>
  );
}
