import React from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import _ from "lodash";
import MenuItem from "@mui/material/MenuItem";
import { CheckBoxOutlineBlank, Done } from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import makeStyles from "@mui/styles/makeStyles";
import { AlgoliaRefinement } from "../../../types/Algolia/AlgoliaRefinement";
import { RefinementListProvided } from "react-instantsearch-core";
import { useStyles } from "./legalbirdRefinementListStyle";

type LegalbirdRefinementListProps = Pick<RefinementListProvided, "refine" | "currentRefinement" | "items"> & {
  refinement: AlgoliaRefinement;
};
const LegalbirdRefinementList = (props: LegalbirdRefinementListProps) => {
  const { refinement, currentRefinement, items, refine } = props;
  const { staticItems, attribute } = refinement;
  const classes = useStyles(props);

  const doRefine = (value: string) => {
    const next = _.includes(currentRefinement, value)
      ? _.without(currentRefinement, value)
      : _.concat(currentRefinement, value);
    refine(next);
  };

  return (
    <div className={classes.filterCategory}>
      <div className={classes.categoryName}>{attribute.label}</div>
      {_.map(staticItems, (staticItem) => {
        const item = _.find(items, ["label", staticItem.value]);
        const isRefined = !!item && item.isRefined;

        return (
          <MenuItem key={staticItem.value} className={classes.menuItem} onClick={() => doRefine(staticItem.value)}>
            {isRefined ? <Done /> : <CheckBoxOutlineBlank />}
            <Badge badgeContent={item && item.count} color={"primary"}>
              <span className={classes.itemLabel}>{staticItem.label}</span>
            </Badge>
          </MenuItem>
        );
      })}
    </div>
  );
};

export default connectRefinementList(LegalbirdRefinementList);
