import _ from "lodash";
import { AbstractCase } from "../../../types/AbstractCase";
import { getResponsibleInstitutes } from "../../../services/Product/ProductService";
import { getExternalReference } from "../CasePdfGenerator/casePdfGeneratorService";
import { MediaObject } from "../../../types/MediaObject";

export const hasFaxOrBea = (product: AbstractCase, path: string): boolean => {
  return _.get(product, path + ".fax") || _.get(product, path + ".beaSafeId");
};

export const getEntityPathByRecipientType = (product: AbstractCase, recipientType: string): string => {
  const { courtPath, authorityPath, prosecutionPath } = getResponsibleInstitutes(product);
  switch (true) {
    case recipientType === "court":
      return courtPath;
    case recipientType === "authority":
      return authorityPath;
    case recipientType === "prosecution":
      return prosecutionPath;
    case recipientType === "debtCollectionAgency":
    case recipientType === "contractualPartnerLegalEntity":
    case _.startsWith(recipientType, "processParticipantsOrganizations"):
      return recipientType;
    case recipientType === "opponentLawyer":
      return "opponentLawFirm";
    case recipientType === "propertyManager":
      return "propertyManager";
    case recipientType === "custom":
    default:
      return "";
  }
};

export const getBeaSafeId = (product: AbstractCase, recipientType: string): string => {
  const entityPath = getEntityPathByRecipientType(product, recipientType);
  return _.get(product, entityPath + ".beaSafeId", "");
};

export const getFaxNumber = (product: AbstractCase, recipientType: string): string => {
  const entityPath = getEntityPathByRecipientType(product, recipientType);
  return _.get(product, entityPath + ".fax", "");
};

export const getCommunicationMedium = (product: AbstractCase, recipientType: string): string => {
  if (!product?.backofficeCase?.lawyer?.person?.beaData) {
    return "fax";
  }

  const entityPath = getEntityPathByRecipientType(product, recipientType);
  if (entityPath && !_.get(product, entityPath + ".beaSafeId")) {
    return "fax";
  }

  if (recipientType === "authority" && _.get(product, entityPath + ".communicationMedium")) {
    const { authorityPath } = getResponsibleInstitutes(product);
    return _.get(product, authorityPath + ".communicationMedium", "fax");
  }

  if (_.includes(["court", "authority", "prosecution", "opponentLawyer", "custom"], recipientType)) {
    return "bea";
  }
  return "fax";
};

export const handleReferenceLogic = (product: AbstractCase, recipientType: string) => {
  switch (true) {
    case recipientType === "contractualPartnerLegalEntity":
    case _.startsWith(recipientType, "processParticipantsOrganizations"):
      return {
        externalReference: "",
        externalReferenceIsEditable: false,
      };
    case recipientType === "custom":
      return {
        externalReference: "",
        externalReferenceIsEditable: true,
      };
    default:
      const externalReference = getExternalReference(product, recipientType);
      return {
        externalReference: externalReference || "",
        externalReferenceIsEditable: !externalReference,
      };
  }
};

const FILE_SIZE_LIMIT = 20000000;
export const isValidBeaFile = (mediaObject: MediaObject): boolean => {
  if (mediaObject.size > FILE_SIZE_LIMIT) {
    return false;
  }
  return (
    mediaObject.mimeType === "application/pdf" ||
    (mediaObject.mimeType === "text/plain" && _.endsWith(mediaObject.originalName, ".eda"))
  );
};

export const getSubject = (product: AbstractCase): string => {
  if (product.productCategory === "contractLaw") {
    return product.vsString;
  }

  switch (product.productClassName) {
    case "divorce":
      return `Familiensache ${product.client?.fullName} ./. ${product.partner?.fullName}`;
    case "traffic":
      return `Ordnungswidrigkeitsverfahren ${product.client?.fullName}`;
    case "settlement":
      return `${product.client?.familyName} ./. ${product.employerOrganization?.name}`;
    case "rentalContract":
      return product.vsString;
    default:
      return "";
  }
};
