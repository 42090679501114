const ComposeEmailOrMessageDrawerStyle = {
  stackDrawer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 1300,
    width: "300px",
  },
  stackDrawerBox: {
    textAlign: "center",
    fontSize: "1.2rem",
    flexGrow: 1,
    padding: "0.5rem 1rem",
  },
  drawerStackBox: {
    textAlign: "center",
    fontSize: "1.2rem",
    flexGrow: 1,
  },
  boxIcon: {
    width: "96px",
  },
  drawerPaperProps: {
    elevation: 16,
    sx: {
      width: "36.5%",
      maxWidth: "680px",
    },
  },
  drawerForm: {
    alignItems: "center",
    padding: "1rem",
  },
  drawerFormMediaObject: {
    maxWidth: "630px",
  },
};

export default ComposeEmailOrMessageDrawerStyle;
