import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { AbstractCase } from "../../../types/AbstractCase";
import { UseForm } from "../../../types/UseForm";
import useForm from "../../../hooks/useForm";
import { createNoteAsFile } from "../../../services/Files/fileService";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import ValidatorElectronicFileFolderPathSelect from "../../Validator/ValidatorElectronicFileFolderPathSelect";
import { FileGenerateData } from "../../../types/FileGenerateData";

type NoteSaveModalProps = {
  product: AbstractCase;
  note: string;
  open: boolean;
  handleClose: Function;
};

const RequestSaveNoteModal = ({ product, note, open, handleClose }: NoteSaveModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // const onSubmit = async () => {
  const onSubmit = async () => {
    setIsLoading(true);

    let data: FileGenerateData = {
      ...values,
      productId: product.id,
      productClassName: product.productClassName,
      documentIdentifier: "activitySavedNote",
      createdBy:
        currentUser.person.gender.replace("female", "RAin").replace("male", "RA") + " " + currentUser.person.fullname,
    };

    let messageText = await createNoteAsFile(data);
    enqueueSnackbar(messageText, {
      variant: "custom",
      isNonInteractive: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });

    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));

    clearForm();
    handleClose();
  };

  const initialValues = {
    electronicFileFolderPath: "none",
    note: note,
  };

  const { values, handleChange, errors, clearForm }: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  return (
    <LegalbirdIoModal
      title={"Notiz als Dokument speichern"}
      open={open}
      handleClose={handleClose}
      submitButton={
        <ButtonLoading
          type={"submit"}
          variant={"contained"}
          disabled={values.electronicFileFolderPath === "none"}
          isLoading={isLoading}
          onClick={onSubmit}
        >
          {"Dokument Speichern"}
        </ButtonLoading>
      }
    >
      <Typography>In welchem Ordner soll die Notiz als Dokument abgelegt werden?</Typography>
      <ValidatorElectronicFileFolderPathSelect handleChange={handleChange} values={values} errors={errors} />
    </LegalbirdIoModal>
  );
};

export default RequestSaveNoteModal;
