import { useState } from "react";
import CaseOverviewPageLoader from "../../ContentLoader/CaseOverviewPageLoader";
import CaseError from "../../Case/CaseError/CaseError";
import CaseNotFound from "../../Case/CaseNotFound/CaseNotFound";
import PageContentContainer from "../../Container/PageContentContainer";
import { Grid } from "@mui/material";
import CaseAlerts from "../../Case/CaseAlerts/CaseAlerts";
import CaseOverviewBox from "../../Case/CaseOverviewBox/CaseOverviewBox";
import CaseContact from "../../Case/CaseContact/CaseContact";
import PreleadInfoBox from "../../Case/CasePreleadInfo/PreleadInfoBox";
import CaseDetailsForm from "../../Case/CaseForm/Details/CaseDetailsForm";
import CaseCostBox from "../../Case/CaseCostBox/CaseCostBox";
import PinnedNotes from "../../PinnedNotes/PinnedNotes";
import PaidTasksCase from "../../PaidTask/PaidTasksCase/PaidTasksCase";
import Emails from "../../Email/Emails";
import MediaObjects from "../../MediaObjectSection/MediaObjects";
import InvestigationAssessment from "../../InvestigationAssessment/InvestigationAssessment";
import CaseRelatedMediaObjects from "../../Case/CaseRelatedMediaObjects/CaseRelatedMediaObjects";
import CourtAppointments from "../../CourtAppointments/CourtAppointments";
import BeaMessages from "../../Bea/BeaMessages";
import NavigationExtensionCasePage from "../../NavigationBar/NavigationExtensionCasePage";
import { useCase } from "../../../provider/CaseProvider";
import CaseActivities from "../../Activities/CaseActivities";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../services/ReactQuery/reactQueryService";
import CaseMessagesBox from "../../Messages/CaseMessagesBox";
import CaseNotifications from "../../CaseNotifications/CaseNotifications";
import CaseInquiriesToCustomer from "../../Case/CaseInquiryToCustomer/CaseInquiriesToCustomer";

const CasePage = () => {
  const [hasUpdates, setHasUpdates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { product, isLoading: isCaseLoading, error, refreshCase } = useCase();
  const queryClient = useQueryClient();

  if (!product) {
    return null;
  }

  const refreshPage = async () => {
    setIsLoading(true);
    await refreshCase();
    await queryClient.invalidateQueries(queryKeys.collections("activities"));
    await queryClient.invalidateQueries(queryKeys.collections("email_messages"));
    await queryClient.invalidateQueries(queryKeys.collections("inquiry_to_customers"));
    setHasUpdates(false);
    setIsLoading(false);
  };

  if (isLoading || isCaseLoading) {
    return <CaseOverviewPageLoader />;
  }

  /* customer.isFake is a synonym for customer.isScheduledForDeletion */
  if (!product || error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  if (!!error) {
    return <CaseError errorMessage={error.error} errorStatus={error.status} />;
  }

  return (
    <>
      <NavigationExtensionCasePage product={product} />
      <PageContentContainer>
        <CaseAlerts product={product} />
        <CaseInquiriesToCustomer product={product} />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <CaseOverviewBox product={product} refreshPage={refreshPage} hasUpdates={hasUpdates} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CaseContact product={product} />
            <PreleadInfoBox product={product} />
            <CaseDetailsForm product={product} key={product.id} refreshCase={refreshCase} />
            <CaseCostBox product={product} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <PinnedNotes product={product} />
            <PaidTasksCase product={product} />
            <CaseActivities product={product} />
            <BeaMessages product={product} />
            <MediaObjects product={product} />
            <InvestigationAssessment product={product} />
            <CaseRelatedMediaObjects product={product} refreshPage={refreshPage} />
            <CourtAppointments product={product} refreshPage={refreshPage} />
            <CaseMessagesBox product={product} />
            <Emails product={product} />
          </Grid>
        </Grid>
      </PageContentContainer>
      <CaseNotifications product={product} refreshPage={refreshPage} setHasUpdates={setHasUpdates} />
    </>
  );
};

export default CasePage;
