import React from "react";
import { Box, Divider } from "@mui/material";
import _ from "lodash";
import paidTaskListStyle from "./paidTaskListStyle";
import Typography from "@mui/material/Typography";
import { PAID_TASK_STATUS_REQUESTED } from "../../../components/PaidTask/paidTaskStatus";
import PaidTaskListItem from "./PaidTaskListItem";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

const RequestedPaidTasks = () => {
  const currentUser = useCurrentUser();
  const paidTasksFilters = {
    "exists[deletedDate]": false,
    status: PAID_TASK_STATUS_REQUESTED,
    requestedUser: currentUser["@id"],
  };
  const { data: paidTasks } = useQuery(queryKeys.collection("paid_tasks", paidTasksFilters), () =>
    fetchCollection("paid_tasks", paidTasksFilters)
  );

  if (!paidTasks || !paidTasks["hydra:member"] || paidTasks["hydra:member"].length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant={"h2"} align={"center"}>
        Anfragen für Aufgaben
      </Typography>
      <br />
      {_.map(paidTasks["hydra:member"], (paidTask, index: number) => (
        <React.Fragment key={paidTask.id}>
          <PaidTaskListItem paidTask={paidTask} hideCase isExternalView />
          {index !== paidTasks.length - 1 && (
            <Box sx={paidTaskListStyle.divider}>
              <Divider />
            </Box>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default RequestedPaidTasks;
