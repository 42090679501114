import { Button, Stack, Typography } from "@mui/material";
import { mimeTypes } from "../../services/Files/fileService";
import { FileDownloadOutlined } from "@mui/icons-material";
import React from "react";
import { MediaObject } from "../../types/MediaObject";

export type DisplayMediaObjectContentPlaybackErrorMessageProps = {
  mediaObject: MediaObject;
  downloadUrl: string;
};
export default function DisplayMediaObjectContentPlaybackErrorMessage({
  mediaObject,
  downloadUrl,
}: DisplayMediaObjectContentPlaybackErrorMessageProps) {
  return (
    <Stack padding={4} spacing={2}>
      <Typography variant={"body1"}>
        {mimeTypes.video.includes(mediaObject.mimeType!) ? "Das Video" : "Die Audiodatei"} konnte nicht geladen werden.
      </Typography>
      <a href={downloadUrl} target={"_blank"}>
        <Button variant={"contained"} fullWidth={false} startIcon={<FileDownloadOutlined />}>
          Hier herunterladen
        </Button>
      </a>
    </Stack>
  );
}
