import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import Button from "@mui/material/Button";
import { Box, IconButton } from "@mui/material";
import { DeleteForeverOutlined } from "@mui/icons-material";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { DropzoneArea } from "mui-file-dropzone";
import ButtonLoading from "../../Button/ButtonLoading";
import Typography from "@mui/material/Typography";
import { fileSectionStyle } from "./fileSectionStyle";
import { MediaObject } from "../../../types/MediaObject";

type FileSectionProps = {
  headline: string;
  setMediaObjectToDisplay: Function;
  subText?: string;
  deleteFile: Function;
  uploadFile: Function;
  currentMediaObjects: MediaObject[];
  fileLimit?: number;
  readOnly?: boolean;
  acceptedFiles?: Array<string>;
};

const FileSection = ({
  headline,
  setMediaObjectToDisplay,
  subText,
  deleteFile,
  uploadFile,
  currentMediaObjects,
  fileLimit,
  readOnly = false,
  acceptedFiles = [],
}: FileSectionProps) => {
  const [filePickerOpen, setFilePickerOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseFilePicker = () => {
    setFilePickerOpen(false);
    setFiles([]);
  };

  const handleUploadFile = async () => {
    setIsLoading(true);
    await uploadFile(files);
    setFiles([]);
    setFilePickerOpen(false);
    setIsLoading(false);
  };

  const handleDeleteFile = async (id: number) => {
    setIsLoading(true);
    await deleteFile(id);
    setIsLoading(false);
    setFileToDelete(0);
  };

  return (
    <Box sx={fileSectionStyle.fileSectionContainer}>
      <Typography variant={"h3"}>{headline}</Typography>
      {subText && (
        <>
          <br />
          <Typography>{subText}</Typography>
        </>
      )}
      <Grid container sx={fileSectionStyle.container}>
        {_.map(currentMediaObjects, (receipt, key) => {
          return (
            <Grid item xs={12} key={key}>
              <Grid container justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
                <Grid item sx={fileSectionStyle.file}>
                  <Box onClick={() => setMediaObjectToDisplay(receipt)} sx={fileSectionStyle.fileName}>
                    {receipt.originalName}
                  </Box>
                </Grid>
                {!readOnly && (
                  <Grid item>
                    <IconButton
                      onClick={() => setFileToDelete(receipt.id)}
                      disabled={isLoading}
                      sx={fileSectionStyle.icon}
                      size="large"
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {(!fileLimit || currentMediaObjects.length < fileLimit) && (
        <Grid container justifyContent={"flex-end"}>
          <Grid item sx={fileSectionStyle.addFileButton}>
            <Button onClick={() => setFilePickerOpen(true)}>Datei hinzufügen</Button>
          </Grid>
        </Grid>
      )}
      <LegalbirdIoModal
        handleClose={handleCloseFilePicker}
        open={filePickerOpen}
        title={""}
        submitButton={
          <ButtonLoading
            disabled={files.length !== 1}
            variant={"contained"}
            onClick={handleUploadFile}
            isLoading={isLoading}
          >
            Datei am Fall speichern
          </ButtonLoading>
        }
      >
        <DropzoneArea
          onChange={(files) => setFiles(files)}
          filesLimit={1}
          dropzoneText={"Datei hierher ziehen oder Fläche anklicken"}
          showFileNames
          showAlerts={false}
          maxFileSize={300000000}
          useChipsForPreview
          acceptedFiles={acceptedFiles}
          fileObjects={files}
          onDelete={() => {}}
        />
      </LegalbirdIoModal>
      <LegalbirdIoModal
        handleClose={() => setFileToDelete(0)}
        open={fileToDelete > 0}
        title={"Datei löschen?"}
        submitButton={
          <ButtonLoading
            variant={"contained"}
            onClick={async () => {
              await handleDeleteFile(fileToDelete);
            }}
            isLoading={isLoading}
          >
            Datei löschen
          </ButtonLoading>
        }
      >
        <Box sx={fileSectionStyle.deleteModalText}>Möchten Sie diese Datei unwiderruflich löschen?</Box>
      </LegalbirdIoModal>
    </Box>
  );
};

export default FileSection;
