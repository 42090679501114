export const displayMediaObjectStyles = {
  multimediaContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "55vh",
  },
  imageContainer: {
    maxHeight: "65vh",
    overflow: "auto",
  },
  embedContainer: {
    minWidth: 800,
    width: "100%",
    height: "60vh",
  },
  imageDialog: {
    textAlign: "center",
  },
  image: {
    maxHeight: 768,
    maxWidth: 1024,
  },
  closeIcon: {
    margin: "0 0 2rem auto",
    display: "block",
  },
  buttonContainer: {
    marginTop: "2rem",
  },
  headline: {
    fontSize: "1rem",
    color: "#317ae2",
    cursor: "pointer",
    display: "inline-block",
  },
  loadingWrapper: {
    marginTop: "5rem",
    marginBottom: "5rem",
  },
};
