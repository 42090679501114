import { useEffect, useState } from "react";
import ContentBox from "../../../ContentBox/ContentBox";
import ButtonLoading from "../../../Button/ButtonLoading";
import { Theme, Typography } from "@mui/material";
import ApiClient from "../../../../services/ApiClient";
import _ from "lodash";
import { RegistryEntry } from "../../../../types/RegistryEntry";
import { HydraResult } from "../../../../types/HydraResult";

export default function PauseBeaRetries() {
  const [isLoading, setIsLoading] = useState(false);
  const [retriesRegistryEntry, setRetriesRegistryEntry] = useState<RegistryEntry | null>(null);

  useEffect(() => {
    ApiClient.get("registry_entries?registryKey=beaRetriesStatus").then(
      (resultCollection: HydraResult<RegistryEntry>) =>
        setRetriesRegistryEntry(_.head(resultCollection["hydra:member"]) || null)
    );
  }, []);

  const retriesPaused = retriesRegistryEntry?.registryValue === "paused";

  const toggleRetriesPaused = async () => {
    if (!retriesRegistryEntry) {
      return;
    }
    setIsLoading(true);
    await ApiClient.put(retriesRegistryEntry["@id"], {
      body: JSON.stringify({
        registryValue: retriesPaused ? "running" : "paused",
      }),
    });
    setIsLoading(false);
  };

  return (
    <ContentBox headline="beA-Retries pausieren">
      <Typography>
        Im Fall einer beA-Störung können hier die automatischen Retries pausiert werden. Wenn die Störung behoben wurde,
        können die Retries durch erneuten Klick wieder aufgenommen werden.
      </Typography>
      <ButtonLoading
        onClick={toggleRetriesPaused}
        isLoading={isLoading}
        variant={"contained"}
        sx={{
          marginTop: "1rem",
          backgroundColor: (theme: Theme) => (retriesPaused ? "red" : theme.palette.primary.main),
        }}
      >
        {retriesPaused ? "beA-Retries wieder aktivieren" : "beA-Retries pausieren"}
      </ButtonLoading>
    </ContentBox>
  );
}
