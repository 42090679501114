import React, { useState } from "react";
import { bookingEntryStyle } from "./bookingEntryStyle";
import { Box, IconButton, MenuItem, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { Attachment, LockPersonOutlined, NoteAddOutlined } from "@mui/icons-material";
import ApiClient from "../../services/ApiClient";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import Button from "@mui/material/Button";
import _ from "lodash";
import ValidatorSelect from "../Validator/ValidatorSelect";
import ButtonLoading from "../Button/ButtonLoading";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { AbstractCase } from "../../types/AbstractCase";
import { BackofficeUser } from "../../types/BackofficeUser";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { BookingEntry as BookingEntryInterface, BookingEntryWithDifference } from "../../types/BookingEntry";
import { useInvoiceTypeIdentifiers } from "../../provider/InvoiceTypeIdentifiersProvider";
import AdditionalAccountingActions from "./AdditionalAccountingActions";

type BookingEntryProps = {
  bookingEntry: BookingEntryWithDifference;
  updateBookingEntries: () => void;
  product: AbstractCase;
  connectedReceiptIds: Array<number | null>;
  backofficeUsers: BackofficeUser[];
};

const BookingEntry = ({
  bookingEntry,
  updateBookingEntries,
  product,
  connectedReceiptIds,
  backofficeUsers,
}: BookingEntryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [displayMediaObject, setDisplayMediaObject] = useState(false);
  const [mediaObjectToUpload, setMediaObjectToUpload] = useState(0);
  const [addReceiptOpen, setAddReceiptOpen] = useState(false);
  const [markAsThirdPartyFundsModalOpen, setMarkAsThirdPartyFundsModalOpen] = useState(false);
  const [markAsNotThirdPartyFundsModalOpen, setMarkAsNotThirdPartyFundsModalOpen] = useState(false);

  const { isLoading: isLoadingInvoiceTypeIdentifiers, translateInvoiceTypeIdentifier } = useInvoiceTypeIdentifiers();

  let mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };

  const { data: mediaObjects } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const isAdminUser = true;

  if (!mediaObjects || isLoadingInvoiceTypeIdentifiers) {
    return null;
  }

  const availableMediaObjects = _.filter(mediaObjects["hydra:member"], (mediaObject) => {
    return mediaObject.mimeType === "application/pdf" && !connectedReceiptIds.includes(mediaObject.id);
  });

  const saveReceipt = async () => {
    setIsLoading(true);
    await ApiClient.put("booking_entries/" + bookingEntry.id, {
      body: JSON.stringify({
        receipt: "/media_objects/" + mediaObjectToUpload,
        organization: "/organizations/" + bookingEntry.organization!.id,
      }),
    });
    updateBookingEntries();
    setAddReceiptOpen(false);
    setIsLoading(false);
  };

  const onThirdPartyFundsChange = async (event: SelectChangeEvent) => {
    if (event.target.value === "true") {
      setMarkAsThirdPartyFundsModalOpen(true);
    }
    if (event.target.value === "false") {
      setMarkAsNotThirdPartyFundsModalOpen(true);
    }
  };

  const updateIsThirdPartyFunds = async (newValue: boolean) => {
    setIsLoading(true);
    await ApiClient.put("booking_entries/" + bookingEntry.id, {
      body: JSON.stringify({
        isThirdPartyFunds: newValue,
      }),
    });
    setIsLoading(false);
    setMarkAsThirdPartyFundsModalOpen(false);
    setMarkAsNotThirdPartyFundsModalOpen(false);
    updateBookingEntries();
  };

  const BookingType = ({ bookingEntry }: { bookingEntry: BookingEntryInterface }) => {
    let displayedType;
    switch (true) {
      case bookingEntry.type === "invoice" && bookingEntry.debitOrCredit === "debit":
        displayedType = "Rechnung";
        break;
      case bookingEntry.type === "invoice" && bookingEntry.debitOrCredit === "credit":
        displayedType = "Gutschrift";
        break;
      case bookingEntry.type === "payment" && bookingEntry.debitOrCredit === "debit":
        displayedType = "Auszahlung";
        break;
      case bookingEntry.type === "payment" && bookingEntry.debitOrCredit === "credit":
        displayedType = "Einzahlung";
        break;
      default:
        displayedType = "";
    }

    if (bookingEntry.invoiceTypeIdentifier) {
      return (
        <Tooltip title={translateInvoiceTypeIdentifier(bookingEntry.productType!, bookingEntry.invoiceTypeIdentifier)}>
          <div>{displayedType}</div>
        </Tooltip>
      );
    }
    return <>{displayedType}</>;
  };

  const assignedUser = _.find(backofficeUsers, { id: parseInt(_.split(bookingEntry.createdBy, "/")[2]) });
  return (
    <TableRow>
      <TableCell>
        <BookingType bookingEntry={bookingEntry} />
      </TableCell>
      <TableCell>{moment(bookingEntry.bookingDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{bookingEntry.invoiceNumber !== "unknown" && bookingEntry.invoiceNumber}</TableCell>
      <TableCell>{bookingEntry.dueDate && moment(bookingEntry.dueDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell>
        <Tooltip
          placement={"top-start"}
          title={<div dangerouslySetInnerHTML={{ __html: bookingEntry.bookingText || "" }} />}
        >
          <div>{_.truncate(bookingEntry.bookingText || "")}</div>
        </Tooltip>
      </TableCell>
      <TableCell>{_.get(assignedUser, "person.fullname")}</TableCell>
      <TableCell align={"right"}>
        {bookingEntry.debitOrCredit === "debit" &&
          bookingEntry.amount?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
      </TableCell>
      <TableCell align={"right"}>
        {bookingEntry.debitOrCredit === "credit" &&
          bookingEntry.amount?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
      </TableCell>
      <TableCell align={"right"}>
        {Math.abs(bookingEntry.difference).toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}{" "}
        {bookingEntry.difference < 0 ? "S" : "H"}
      </TableCell>
      <TableCell align={"right"}>{bookingEntry?.organization?.abbreviation || ""}</TableCell>
      <TableCell>
        {bookingEntry.type === "payment" &&
          bookingEntry.debitOrCredit === "credit" &&
          bookingEntry.isThirdPartyFunds === null && (
            <>
              <ValidatorSelect
                label={"Fremdgeld?"}
                sx={{ minWidth: "120px" }}
                value={""}
                onChange={onThirdPartyFundsChange}
              >
                <MenuItem value={"false"}>Nein</MenuItem>
                <MenuItem value={"true"}>Ja</MenuItem>
              </ValidatorSelect>

              <LegalbirdIoModal
                handleClose={() => setMarkAsThirdPartyFundsModalOpen(false)}
                open={markAsThirdPartyFundsModalOpen}
                title={"Art der Einzahlung speichern"}
                submitButton={
                  <Button onClick={() => updateIsThirdPartyFunds(true)} variant={"contained"}>
                    Als Fremdgeld markieren
                  </Button>
                }
              >
                <Typography>
                  Möchten Sie die Zahlung als Fremdgeld markieren? Dies kann nicht rückgängig gemacht werden.
                </Typography>
              </LegalbirdIoModal>
              <LegalbirdIoModal
                handleClose={() => setMarkAsNotThirdPartyFundsModalOpen(false)}
                open={markAsNotThirdPartyFundsModalOpen}
                title={"Art der Einzahlung speichern"}
                submitButton={
                  <Button onClick={() => updateIsThirdPartyFunds(false)} variant={"contained"}>
                    Als Standardzahlung markieren
                  </Button>
                }
              >
                <Typography>
                  Möchten Sie die Zahlung als Standardzahlung (kein Fremdgeld) markieren? Dies kann nicht rückgängig
                  gemacht werden.
                </Typography>
              </LegalbirdIoModal>
            </>
          )}
      </TableCell>
      <TableCell>
        <Grid container>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6}>
            {bookingEntry.isThirdPartyFunds === true && (
              <Tooltip title={"Fremdgeld"}>
                <Box display="flex" justifyContent="center" alignItems="center" height={"100%"}>
                  <LockPersonOutlined />
                </Box>
              </Tooltip>
            )}
            {bookingEntry.receipt && (
              <>
                <Tooltip title={"Rechnung anzeigen"}>
                  <IconButton sx={bookingEntryStyle.icon} onClick={() => setDisplayMediaObject(true)} size="large">
                    <Attachment />
                  </IconButton>
                </Tooltip>
                <DisplayMediaObject
                  closeDialog={() => {
                    setDisplayMediaObject(false);
                  }}
                  mediaObject={displayMediaObject ? bookingEntry.receipt : null}
                />
              </>
            )}
            {!bookingEntry.receipt && isAdminUser && bookingEntry.type === "invoice" && (
              <>
                <IconButton sx={bookingEntryStyle.icon} onClick={() => setAddReceiptOpen(true)} size="large">
                  <NoteAddOutlined />
                </IconButton>
                <LegalbirdIoModal
                  handleClose={() => setAddReceiptOpen(false)}
                  open={addReceiptOpen}
                  title={"Beleg verknüpfen"}
                  submitButton={
                    <ButtonLoading isLoading={isLoading} onClick={saveReceipt} variant={"contained"}>
                      Beleg verknüpfen
                    </ButtonLoading>
                  }
                >
                  <ValidatorSelect
                    label={"Beleg"}
                    value={mediaObjectToUpload}
                    onChange={(event: SelectChangeEvent) => setMediaObjectToUpload(parseInt(event.target.value))}
                  >
                    <MenuItem value={0} disabled>
                      Bitte auswählen
                    </MenuItem>
                    {_.map(availableMediaObjects, (mediaObject) => (
                      <MenuItem key={mediaObject.id} value={mediaObject.id}>
                        {mediaObject.description}
                      </MenuItem>
                    ))}
                  </ValidatorSelect>
                </LegalbirdIoModal>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align={"right"}>
        <AdditionalAccountingActions bookingEntry={bookingEntry} updateBookingEntries={updateBookingEntries} />
      </TableCell>
    </TableRow>
  );
};

export default BookingEntry;
