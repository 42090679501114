import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { alimonyClaimsAmount } from "../../services/formServiceFunctions";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import ApiClient from "../../services/ApiClient";
import ButtonLoading from "../Button/ButtonLoading";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import * as PropTypes from "prop-types";
import ProcessCostSupportFileSection from "./Receipts/ProcessCostSupportFileSection";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { processCostSupportStyle } from "./processCostSupportStyle";
import { Box } from "@mui/material";

const ProcessCostSupportReceipts = ({ processCostSupport }) => {
  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState(null);
  const [signature, setSignature] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteSignatureOpen, setDeleteSignatureOpen] = useState(false);
  const updateMutation = useMutation(updateResource);

  useEffect(() => {
    if (processCostSupport.petitioner.signature) {
      ApiClient.get("media_objects/" + processCostSupport.petitioner.signature.id).then((img) => {
        setSignature(img);
      });
      return;
    }
    setSignature("");
  }, [processCostSupport]);

  const handleDeleteSignature = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      uri: "people",
      id: processCostSupport.petitioner.id,
      data: {
        signature: null,
      },
    });
    setDeleteSignatureOpen(false);
    setIsLoading(false);
  };

  let counter = 1;

  return (
    <Paper sx={processCostSupportStyle.paper}>
      <Box sx={processCostSupportStyle.paperHeadline}>Belege und Unterschrift</Box>
      <Box sx={processCostSupportStyle.receiptContainer}>
        {processCostSupport.isCostCovered && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Rechtsschutzversicherung"}
            fieldname={"pcs_receipt_b"}
            description={"B"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasAlimonyClaims && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Unterhaltsanpruch"}
            fieldname={"pcs_receipt_c"}
            description={"C"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasRelativesAlimonyClaims && alimonyClaimsAmount(processCostSupport) > 0 && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Unterhaltszahlungen"}
            fieldname={"pcs_receipt_d"}
            description={"D"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasSocialWelfare && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Sozialhilfe"}
            fieldname={"pcs_receipt_social_welfare"}
            description={"Sozialhilfe"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeEmployment && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Ihr Einkommen (Angestelltes Arbeitsverhältnis)"}
            fieldname={"pcs_receipt_e1"}
            description={"E1"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeSelfEmployment && (
          <ProcessCostSupportFileSection
            headline={
              counter++ + ". Einkommen aus selbstständiger Arbeit / Gewerbebetrieb oder Land- und Forstwirtschaft"
            }
            fieldname={"pcs_receipt_e2"}
            description={"E2"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeRent && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Vermietung / Verpachtung"}
            fieldname={"pcs_receipt_e3"}
            description={"E3"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeAssets && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Kapitalvermögen"}
            fieldname={"pcs_receipt_e4"}
            description={"E4"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeLiving && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Wohngeld"}
            fieldname={"pcs_eceipt_e6"}
            description={"E6"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomePension && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Rente/Pension"}
            fieldname={"pcs_receipt_e8"}
            description={"E8"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeUnemploymentAllowance && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Arbeitslosengeld I"}
            fieldname={"pcs_receipt_e9"}
            description={"E9"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeUnemploymentAllowance2 && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Arbeitslosengeld II"}
            fieldname={"pcs_receipt_e10"}
            description={"E10"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeSickness && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Krankengeld"}
            fieldname={"pcs_receipt_e11"}
            description={"E11"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeParentalAllowance && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Elterngeld"}
            fieldname={"pcs_receipt_e12"}
            description={"E12"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeOther1 && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". " + processCostSupport.incomeOther1Name + " (Sonstige Einnahmen)"}
            fieldname={"pcs_receipt_e13"}
            description={"E13"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeOther2 && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". " + processCostSupport.incomeOther2Name + " (Sonstige Einnahmen)"}
            fieldname={"pcs_receipt_e14"}
            description={"E14"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasOtherInsuranceDeduction && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Versicherungen"}
            fieldname={"pcs_receipt_f3"}
            description={"F3"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasMobilityCostDeduction && processCostSupport.mobilityCostUnit !== "km" && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Fahrkosten"}
            fieldname={"pcs_receipt_f4"}
            description={"F4"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasOtherExpensesDeduction && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Werbekosten / Betriebsausgaben"}
            fieldname={"pcs_receipt_f5"}
            description={"F5"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasIncomeLiving === false && (
          <>
            <ProcessCostSupportFileSection
              headline={counter++ + ". Wohnkosten"}
              fieldname={"pcs_receipt_h1"}
              description={"H1"}
              processCostSupport={processCostSupport}
              setMediaObjectToDisplay={setMediaObjectToDisplay}
            />
            {processCostSupport.hasPropertyLoans &&
              _.map(processCostSupport.propertyLoans, (loan, key) => {
                return (
                  <ProcessCostSupportFileSection
                    key={key}
                    headline={counter++ + ". Kreditvertrag für Eigentum vom " + loan.date}
                    fieldname={"pcs_receipt_h6" + (key + 1)}
                    description={"H6" + (key + 1)}
                    processCostSupport={processCostSupport}
                    setMediaObjectToDisplay={setMediaObjectToDisplay}
                  />
                );
              })}
          </>
        )}
        {processCostSupport.hasOtherPaymentObligations &&
          _.map(processCostSupport.paymentObligations, (obligation, key) => {
            return (
              <ProcessCostSupportFileSection
                key={key}
                headline={counter++ + ". " + obligation.name}
                fieldname={"pcs_receipt_i" + (key + 1)}
                description={"I" + (key + 1)}
                processCostSupport={processCostSupport}
                setMediaObjectToDisplay={setMediaObjectToDisplay}
              />
            );
          })}

        {processCostSupport.hasSpecialFinancialBurdens &&
          _.map(processCostSupport.specialFinancialBurdens, (burden, key) => {
            return (
              <ProcessCostSupportFileSection
                key={key}
                headline={counter++ + ". " + burden.description}
                fieldname={"pcs_receipt_j" + (key + 1)}
                description={"J" + (key + 1)}
                processCostSupport={processCostSupport}
                setMediaObjectToDisplay={setMediaObjectToDisplay}
              />
            );
          })}

        {processCostSupport.hasBankAccount &&
          _.map(processCostSupport.bankAccounts, (account, key) => {
            return (
              <ProcessCostSupportFileSection
                key={key}
                headline={counter++ + ". " + account.type + ", " + account.owner + ", " + account.name}
                fieldname={"pcs_receipt_g1" + (key + 1)}
                description={"G1." + (key + 1)}
                processCostSupport={processCostSupport}
                setMediaObjectToDisplay={setMediaObjectToDisplay}
              />
            );
          })}
        {processCostSupport.hasPensionInsurance && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Lebens- oder Rentenversicherung"}
            fieldname={"pcs_receipt_g5"}
            description={"G5"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
        {processCostSupport.hasOtherAssets && (
          <ProcessCostSupportFileSection
            headline={counter++ + ". Sonstige Vermögenswerte"}
            fieldname={"pcs_receipt_g6"}
            description={"G6"}
            processCostSupport={processCostSupport}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
          />
        )}
      </Box>
      <Typography variant={"h5"} sx={processCostSupportStyle.signatureHeadline}>
        Unterschrift
      </Typography>
      <Paper sx={processCostSupportStyle.signature}>
        {signature && <img src={signature.displayUrl} alt={"signature"} />}
      </Paper>
      <ButtonLoading
        isLoading={isLoading}
        disabled={!signature}
        variant={"contained"}
        sx={processCostSupportStyle.deleteSignatureButton}
        onClick={() => setDeleteSignatureOpen(true)}
      >
        Unterschrift löschen
      </ButtonLoading>
      <DisplayMediaObject closeDialog={() => setMediaObjectToDisplay(null)} mediaObject={mediaObjectToDisplay} />
      <LegalbirdIoModal
        handleClose={() => setDeleteSignatureOpen(false)}
        open={deleteSignatureOpen}
        title={"Unterschrift löschen?"}
        submitButton={
          <ButtonLoading variant={"contained"} onClick={handleDeleteSignature} isLoading={isLoading}>
            Unterschrift löschen
          </ButtonLoading>
        }
      >
        <Box sx={processCostSupportStyle.deleteModalText}>Möchten Sie die Unterschrift unwiderruflich löschen?</Box>
      </LegalbirdIoModal>
    </Paper>
  );
};

ProcessCostSupportReceipts.propTypes = {
  processCostSupport: PropTypes.object.isRequired,
};

export default ProcessCostSupportReceipts;
