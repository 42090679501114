import React, { useState } from "react";
import editActivityNoteStyle from "./editActivityNoteStyle";
import { Box, IconButton } from "@mui/material";
import { NoteAddOutlined } from "@mui/icons-material";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { formValue } from "../../../services/formServiceFunctions";
import { Editor } from "react-draft-wysiwyg";
import useForm from "../../../hooks/useForm";
import _ from "lodash";
import SubmitButton from "../../Button/SubmitButton";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { Activity } from "../../../types/Activity";
import { EditorState } from "draft-js";


type EditActivityNoteProps = {
  activity: Activity;
  updateActivities: Function;
};

export default function EditActivityNote({ activity, updateActivities }: EditActivityNoteProps) {
  const updateMutation = useMutation(updateResource);
  const [editNoteOpen, setEditNoteOpen] = useState(false);

  const initialValues = {
    note: htmlToEditorState(activity.note),
  };

  const onSubmit = async () => {
    let updateData: Record<string, any> = _.merge({}, values);
    updateData.note = editorStateToHTML(updateData.note, true);
    await updateMutation.mutateAsync({ uri: "activities", id: activity.id, data: updateData });
    await updateActivities();
    setEditNoteOpen(false);
  };

  const { values, handleChange, handleSubmit, touchedValues, isLoading } = useForm({
    initialValues,
    onSubmit,
    identifier: activity.id.toString(),
    clearFormAfterSubmit: true,
  });

  const handleEditorStateChange = (editorState: EditorState) => {
    handleChange({
      target: {
        name: "note",
        value: editorState,
      },
    });
  };

  return (
    <Box sx={editActivityNoteStyle.iconButtonContainer}>
      <IconButton onClick={() => setEditNoteOpen(true)}>
        <NoteAddOutlined />
      </IconButton>
      <LegalbirdIoModal
        handleClose={() => setEditNoteOpen(false)}
        open={editNoteOpen}
        submitButton={
          <SubmitButton
            onClick={handleSubmit}
            variant={"contained"}
            disabled={_.keys(touchedValues).length === 0}
            isLoading={isLoading}
          >
            Speichern
          </SubmitButton>
        }
        title={"Notiz für Beratungstermin"}
      >
        <Editor
          toolbar={{
            options: ["inline", "list", "link"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          stripPastedStyles
          editorState={formValue(values, "note")}
          onEditorStateChange={handleEditorStateChange}
          editorStyle={editActivityNoteStyle.editor}
          placeholder={"Notiz"}
          localization={{ locale: "de" }}
        />
      </LegalbirdIoModal>
    </Box>
  );
}
