import React, { useState } from "react";
import { Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import ApiClient from "../../services/ApiClient";
import { AbstractCase } from "../../types/AbstractCase";
import { BeaAttachment as BeaAttachmentType } from "../../types/Bea/BeaAttachment";

type BeaAttachmentProps = {
  attachment: BeaAttachmentType;
  product: AbstractCase;
};
export default function BeaAttachment({ attachment, product }: BeaAttachmentProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const uploadToCase = async () => {
    setIsLoading(true);
    await ApiClient.put("/media_objects/" + attachment.mediaObjectId, {
      body: JSON.stringify({
        product: product.productClassName,
        productId: product.id,
      }),
    });
    setIsLoading(false);
    setMenuOpen(false);
  };

  return (
    <Box display={"flex"} sx={{ border: "1px solid #d9d9d9", padding: ".5rem", height: 40 }}>
      <Typography>{attachment.reference}</Typography>
      {attachment.mediaObjectId && (
        <>
          <IconButton
            sx={{ margin: "0 0 0 1rem", padding: 0 }}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setMenuOpen(!menuOpen);
            }}
            size="large"
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={(e, reason) => {
              if (reason === "backdropClick") {
                setMenuOpen(false);
                setAnchorEl(null);
              }
            }}
          >
            <MenuItem onClick={() => uploadToCase()}>
              {isLoading ? <CircularProgress size={28} sx={{ margin: "0 auto" }} /> : "Datei zu Fall hinzufügen"}
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
}
