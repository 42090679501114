import ContentBox from "../../../ContentBox/ContentBox";
import { Typography } from "@mui/material";
import ButtonLoading from "../../../Button/ButtonLoading";
import { useState } from "react";
import { useSnackbar } from "notistack";
import ApiClient from "../../../../services/ApiClient";

export default function AlgoliaIndexUpdate() {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (process.env.REACT_APP_DEPLOYMENT === "production") {
    return null;
  }

  const updateAlgoliaIndices = async () => {
    setIsLoading(true);
    try {
      await ApiClient.get("/lb/services/update_algolia_indices");
      enqueueSnackbar("Algolia-Update erfolgreich", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar("Algolia-Update fehlgeschlagen", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <ContentBox headline="Testing - Algoliasuche updaten">
      <Typography margin={"1rem 0"}>
        Mit Klick auf “Algoliasuche updaten” werden die Suchen auf dem Testsystem gecleared und die neuen Entities zu
        Algolia gepushed.
      </Typography>
      <ButtonLoading variant={"contained"} onClick={updateAlgoliaIndices} isLoading={isLoading}>
        Algoliasuche updaten
      </ButtonLoading>
    </ContentBox>
  );
}
