import * as stagesSettlement from "../../Stages/StagesSettlement";
import { AbstractCase } from "../../../types/AbstractCase";

export const settlementRequiredFields = {
  [stagesSettlement.STAGE_INCOMPLETE]: (settlement: AbstractCase) => {
    return [];
  },
  [stagesSettlement.STAGE_READY]: (settlement: AbstractCase) => {
    return [];
  },
  [stagesSettlement.STAGE_CHECKED]: (settlement: AbstractCase) => {
    let requiredFields = [
      "contractTerminationDate",
      "lastWorkDayCalculated",
      "lastWorkDayCancellation",
      "contractDate",
      "bargainingCoverage",
      "representedBy",
      "employerOrganization.legalForm",
      "employerOrganization.name",
      //needed for court crawler
      "employerOrganization.address.postalCode",
      "responsibleLawyer",
      "paymentType",
      "terminationType",
    ];

    if (settlement.legalExpenseInsurance) {
      requiredFields.push("insurance.deductible", "insurance.insurance", "insurance.insurancePolicyNumber");
    }

    return requiredFields;
  },
  [stagesSettlement.STAGE_SUBMITTED]: (settlement: AbstractCase) => {
    return [];
  },
  [stagesSettlement.STAGE_PAID]: (settlement: AbstractCase) => {
    let requiredFields = [];
    if (settlement.legalExpenseInsurance) {
      requiredFields.push("insuranceCoverageDate");
    }
    return requiredFields;
  },
  [stagesSettlement.STAGE_DATE_SET_SETTLEMENT]: (settlement: AbstractCase) => {
    return ["referenceCourt"];
  },
  [stagesSettlement.STAGE_DATE_SET_TRIAL]: (settlement: AbstractCase) => {
    return [];
  },
  [stagesSettlement.STAGE_DECISION_MADE]: (settlement: AbstractCase) => {
    return [];
  },
  [stagesSettlement.STAGE_COMPLETED]: (settlement: AbstractCase) => {
    let requiredFields = [];
    if (settlement.legalExpenseInsurance) {
      requiredFields.push("insuranceCoverageDate");
    }
    return requiredFields;
  },
};
