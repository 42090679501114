import ApiClient from "../../../../services/ApiClient";
import { AbstractCase } from "../../../../types/AbstractCase";
import { CaseDeletionPossibleStatus } from "../../../../types/CaseDeletionPossibleStatus";
import DeleteCaseOrAccountModalBaseContent from "./DeleteCaseOrAccountModalContentBase";
import { useCurrentUser } from "../../../../provider/CurrentUserProvider";
import { userHasOneOfTheseRoles, userHasRole } from "../../../../services/backofficeUserService";
import { Roles } from "../../../../types/BackofficeUser";
import { useEffect, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";

type DeleteCaseModalContentProps = {
  product: AbstractCase;
  postDeletionCallback: Function;
};

export default function DeleteCaseModalContent({ product, postDeletionCallback }: DeleteCaseModalContentProps) {
  const currentUser = useCurrentUser();
  const userIsAdminOrAccounting = userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.accounting]);
  const userIsLeadAccountManager = userHasRole(currentUser, Roles.leadAccountmanager);
  const [caseDeletionPossibleStatus, setCaseDeletionPossibleStatus] = useState<CaseDeletionPossibleStatus | null>(null);

  useEffect(() => {
    ApiClient.get(`/lb/case_deletion/get_case_deletion_possible_status/${product.id}`).then(
      (result: CaseDeletionPossibleStatus) => {
        setCaseDeletionPossibleStatus(result);
      }
    );
  }, []);

  if (caseDeletionPossibleStatus === null) {
    return (
      <Stack direction={"row"} paddingY={2} justifyContent={"center"}>
        <CircularProgress />
      </Stack>
    );
  }

  let deletionAllowed =
    (userIsAdminOrAccounting && caseDeletionPossibleStatus.hasAcquisitionPartnerAdvocard) ||
    (userIsLeadAccountManager &&
      !caseDeletionPossibleStatus.isAdvancedCase &&
      caseDeletionPossibleStatus.hasAcquisitionPartnerAdvocard) ||
    (!caseDeletionPossibleStatus.hasAcquisitionPartnerAdvocard && !caseDeletionPossibleStatus.hasBookingEntry);

  const deletionWarnings = [];

  if (caseDeletionPossibleStatus.hasAcquisitionPartnerAdvocard) {
    deletionWarnings.push(
      userIsAdminOrAccounting
        ? "Achtung: Dieser Fall wurde von der Advocard übermittelt. Es kann also noch eine Zahlung eingehen. Diese wird dann nicht zugeordnet werden können!"
        : "Achtung: Dieser Fall wurde von der Advocard übermittelt. Es kann also noch zu Buchungseinträgen kommen und der Fall kann daher nicht gelöscht werden."
    );
  }

  if (caseDeletionPossibleStatus.hasBookingEntry) {
    deletionWarnings.push("Achtung: Dieser Fall hat Buchungseinträge!");
  }

  if (caseDeletionPossibleStatus.isAdvancedCase) {
    deletionWarnings.push(
      "Achtung: Dieser Fall ist schon zu weit fortgeschritten und unterliegt der Aufbewahrungspflicht!"
    );
  }

  async function confirmDeletion() {
    await ApiClient.delete("backoffice_cases/" + product.backofficeCase.id);
  }

  return (
    <DeleteCaseOrAccountModalBaseContent
      confirmDeletion={confirmDeletion}
      deletionWarnings={deletionWarnings}
      deletionAllowed={deletionAllowed}
      postDeletionCallback={postDeletionCallback}
    />
  );
}
