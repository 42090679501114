import { Route, Routes } from "react-router-dom";
import ExternalsCaseAllDataPage from "../components/_Pages/ExternalsPages/ExternalsAllDataPage/ExternalsCaseAllDataPage";
import CasePaidTaskPage from "../components/_Pages/CasePaidTaskPage/CasePaidTaskPage";
import ExternalsCasePage from "../components/_Pages/ExternalsPages/ExternalsCasePage/ExternalsCasePage";
import React from "react";
import useProductParams from "../hooks/useProductParams";
import CaseProvider from "../provider/CaseProvider";
import CaseRelatedActions from "../components/Case/CaseRelatedActions/CaseRelatedActions";

const ExternalCaseRoutes = () => {
  const { productId, productUrlPart } = useProductParams();
  if (!productUrlPart) {
    return null;
  }

  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseRelatedActions />

      <Routes>
        <Route path={"/"} element={<ExternalsCasePage />} />
        <Route path={"/alle-daten"} element={<ExternalsCaseAllDataPage />} />
        <Route path={"/ausschreibung/:paidTaskId"} element={<CasePaidTaskPage />} />
      </Routes>
    </CaseProvider>
  );
};

export default ExternalCaseRoutes;
