import React from "react";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import ActivityIcon from "../ActivityIcon";
import { getCaseLink, getProductData, getProductNameBy } from "../../../services/Product/ProductService";
import activityStyle from "./activityStyle";
import { Link } from "react-router-dom";
import moment from "moment";
import { CommentOutlined, ReportProblemOutlined } from "@mui/icons-material";
import ActivityActions from "../ActivityActions/ActivityActions";
import AssignedUser from "./AssignedUser";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import _ from "lodash";
import { Activity } from "../../../types/Activity";
import { BackofficeUser } from "../../../types/BackofficeUser";
import Typography from "@mui/material/Typography";

type ActivityTableRowProps = {
  activity: Activity<BackofficeUser>;
  update: () => Promise<void> | void;
  isExternalView: boolean;
};
const ActivityTableRow = ({ activity, update, isExternalView }: ActivityTableRowProps) => {
  const productClassName = getProductNameBy("lbProductClass", activity.case.lbProductClass);

  const getStyles = (activity: Activity<BackofficeUser>, baseStyles: Record<string, any> = {}) => {
    if (activity.done) {
      return { ...baseStyles, ...activityStyle.done };
    }
    if (activity.activityStatus === ACTIVITY_OVERDUE) {
      return { ...baseStyles, ...activityStyle.overdue };
    }
    return baseStyles;
  };

  return (
    <TableRow sx={activity.done ? activityStyle.done : undefined}>
      <TableCell sx={getStyles(activity)}>
        <ActivityIcon activity={activity} withToolTip />
      </TableCell>
      <TableCell sx={getStyles(activity, activityStyle.descriptionCell)}>{activity.subject}</TableCell>
      <TableCell sx={getStyles(activity)}>{getProductData(productClassName, "generalLabel")}</TableCell>
      <TableCell sx={getStyles(activity)}>
        {!_.isEmpty(activity.note) && (
          <Tooltip
            placement={"right"}
            title={<div dangerouslySetInnerHTML={{ __html: activity.note || "" }} />}
            sx={activityStyle.note}
          >
            <CommentOutlined />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Typography
          component={Link}
          sx={activityStyle.link}
          to={getCaseLink(
            { productClassName: productClassName, id: activity.case.lbProductId },
            isExternalView ? "/extern" : ""
          )}
        >
          {activity.case.label || activity.case.lbProductId}
        </Typography>
      </TableCell>
      <TableCell sx={getStyles(activity)}>{moment(activity.dueDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell sx={getStyles(activity)}>
        {activity.dueTime !== "allDay" ? <span>{activity.dueTime}&nbsp;Uhr</span> : "-"}
      </TableCell>
      {isExternalView ? (
        <TableCell sx={getStyles(activity)}>{activity.duration} Minuten</TableCell>
      ) : (
        <TableCell sx={getStyles(activity)}>
          {activity.mandatory ? (
            <Tooltip title={"Pflichtaktivität"}>
              <ReportProblemOutlined />
            </Tooltip>
          ) : (
            "-"
          )}
        </TableCell>
      )}
      <TableCell sx={getStyles(activity)}>
        <AssignedUser activity={activity} updateActivities={update} />
      </TableCell>
      {!isExternalView && (
        <TableCell>
          <ActivityActions activity={activity} updateActivities={update} />
        </TableCell>
      )}
    </TableRow>
  );
};

export default ActivityTableRow;
