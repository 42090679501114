import { AbstractCase } from "../../../../types/AbstractCase";
import _ from "lodash";
import React from "react";
import AdditionalClientForm from "./AdditionalClientForm";

type AdditionalClientsFormProps = { product: AbstractCase; refreshPage: Function };
export default function AdditionalClientsForm({ product, refreshPage }: AdditionalClientsFormProps) {
  function allowCreatingAdditionalClient() {
    if (product.productClassName === "rentalContract") {
      return product.additionalClients.length <= 8;
    }
    return product.additionalClients.length === 0;
  }

  return (
    <>
      {_.map(_.sortBy(product.additionalClients, "id"), (client) => (
        <AdditionalClientForm product={product} client={client} refreshPage={refreshPage} key={client.id} />
      ))}
      {allowCreatingAdditionalClient() && <AdditionalClientForm product={product} refreshPage={refreshPage} />}
    </>
  );
}
