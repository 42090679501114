import React, { useEffect, useMemo, useState } from "react";
import casePaidTaskStyle from "./casePaidTaskStyle";
import DeletePaidTaskModal from "../PaidTask/PaidTaskView/DeletePaidTaskModal";
import PaidTaskViewHeader from "../PaidTask/PaidTaskView/PaidTaskViewHeader";
import Grid from "@mui/material/Grid";
import { convertFromFloat, convertToFloat, formValue } from "../../services/formServiceFunctions";
import { getTemplates, updateFeeValidators } from "../../services/paidTaskService";
import InternalPaidTaskDefinitionButton from "../PaidTask/PaidTaskView/InternalPaidTaskDefinitionButton";
import ValidatorForm from "../Validator/ValidatorForm";
import moment from "moment";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { Box, MenuItem } from "@mui/material";
import ValidatorSelect from "../Validator/ValidatorSelect";
import PaidTaskFormFields from "./PaidTaskFormFields";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../PaidTask/paidTaskStatus";
import { getProductData } from "../../services/Product/ProductService";
import ContentBox from "../ContentBox/ContentBox";
import PaidTaskLog from "./PaidTaskLog";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { PaidTask } from "../../types/PaidTask";
import { AbstractCase } from "../../types/AbstractCase";

type CasePaidTaskDefinitionProps = {
  paidTask: PaidTask;
  product: AbstractCase;
};

const CasePaidTaskDefinition = ({ paidTask, product }: CasePaidTaskDefinitionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const currentUser = useCurrentUser();
  const updateMutation = useMutation(updateResource);

  const initialValues = {
    subject: paidTask.subject,
    description: paidTask.description,
    definitionOfDone: paidTask.definitionOfDone,
    reviewRemarks: paidTask.reviewRemarks,
    assignmentDeadline: moment(paidTask.assignmentDeadline),
    doneDeadline: moment(paidTask.doneDeadline),
    fee: convertFromFloat(paidTask.fee),
    type: paidTask.type,
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        subject: formValue(values, "subject"),
        description: formValue(values, "description"),
        definitionOfDone: formValue(values, "definitionOfDone"),
        reviewRemarks: formValue(values, "reviewRemarks"),
        assignmentDeadline: formValue(values, "assignmentDeadline"),
        doneDeadline: formValue(values, "doneDeadline"),
        fee: convertToFloat(formValue(values, "fee")),
      },
    });
    setIsLoading(false);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    registerValidators,
    handleBlur,
    handleDateChange,
    touchedValues,
  } = useForm({ initialValues, onSubmit });

  const templates = useMemo(() => {
    const productStages = getProductData(product.productClassName, "stageConstants");
    return _.filter(getTemplates(product), (template) => !template.isHidden({ product, productStages }));
  }, [product]);

  useEffect(() => {
    updateFeeValidators(registerValidators, values.type, product);
  }, [values.type]);

  return (
    <ContentBox headline="Aufgabe" headlineVariant="h2">
      <Box sx={casePaidTaskStyle.verticalSpace} />
      <ValidatorForm onSubmit={handleSubmit}>
        <PaidTaskViewHeader paidTask={paidTask} />
        <Grid container alignItems={"flex-start"} spacing={2}>
          <Grid item xs={12} md={6}>
            <ValidatorSelect
              label={"Typ"}
              name={"type"}
              value={formValue(values, "type")}
              disabled
              onChange={handleChange}
            >
              {_.map(templates, (template) => {
                return (
                  <MenuItem key={template.type} value={template.type}>
                    {template.label}
                  </MenuItem>
                );
              })}
            </ValidatorSelect>
          </Grid>
          <PaidTaskFormFields
            registerValidators={registerValidators}
            values={values}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            isDisabled={
              currentUser.isExternal ||
              !_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={casePaidTaskStyle.verticalSpace} />
        </Grid>
        <Grid item xs={12} sx={casePaidTaskStyle.buttonArea}>
          {!currentUser.isExternal && (
            <>
              <InternalPaidTaskDefinitionButton
                paidTask={paidTask}
                isLoading={isLoading}
                touchedValues={touchedValues}
                openDeleteModal={() => setConfirmDeleteOpen(true)}
              />
              <PaidTaskLog paidTask={paidTask} />
            </>
          )}
        </Grid>
      </ValidatorForm>
      <DeletePaidTaskModal
        paidTask={paidTask}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleClose={() => setConfirmDeleteOpen(false)}
        open={confirmDeleteOpen}
      />
    </ContentBox>
  );
};

export default CasePaidTaskDefinition;
