import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import ApiClient from "../../../services/ApiClient";
import moment, { Moment } from "moment";
import { ReportProblemOutlined } from "@mui/icons-material";
import _ from "lodash";
import HumanResource from "../../../types/HumanResource";

const weekDaysNumberToName = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
};

type WorkingHoursHintProps = {
  assignedUser: string;
  dueDate: Moment | string;
  dueTime?: string;
};

export default function WorkingHoursHint({ assignedUser, dueDate, dueTime = "allDay" }: WorkingHoursHintProps) {
  const [humanResource, setHumanResource] = useState<HumanResource>();
  useEffect(() => {
    ApiClient.get("/human_resources?backofficeUser=" + assignedUser).then((humanResourceResponse) =>
      setHumanResource(humanResourceResponse["hydra:member"][0])
    );
  }, [assignedUser, dueDate, dueTime]);

  const dueDateObject = moment(dueDate);
  if (
    !humanResource ||
    !humanResource.workingHours ||
    !dueDateObject.isValid() ||
    ((_.isEmpty(dueTime) || dueTime === "allDay") && !moment(dueDate).isSame(new Date(), "day"))
  ) {
    return null;
  }

  function isWeekday(dayOfTheWeekNumber: number): dayOfTheWeekNumber is keyof typeof weekDaysNumberToName {
    return dayOfTheWeekNumber in weekDaysNumberToName;
  }

  const dayOfTheWeekNumber = parseInt(dueDateObject.format("d"));

  if (!isWeekday(dayOfTheWeekNumber)) {
    return null;
  }

  const workingHours = humanResource.workingHours[weekDaysNumberToName[dayOfTheWeekNumber]];
  const checkTime = !dueTime || dueTime === "allDay" ? moment().format("HH:mm") : dueTime;

  if (checkTime >= workingHours.start && checkTime <= workingHours.end) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: ".5rem", color: "#ce7c12" }}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        <ReportProblemOutlined />
        <Box>
          Die Arbeitszeit des Nutzers am {moment(dueDate).format("DD.MM.YYYY")} ist von {workingHours.start} bis{" "}
          {workingHours.end} Uhr
        </Box>
      </Stack>
    </Box>
  );
}
