import React, { useMemo, useState } from "react";
import casePaidTaskStyle from "./casePaidTaskStyle";
import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { getInitialValuesForPaidTask, getSubmissionCardEnabled, getTemplates } from "../../services/paidTaskService";
import _ from "lodash";
import CasePaidTaskPdfGenerator from "./CasePaidTaskPdfGenerator";
import FormElementAnnotated from "../Case/CaseForm/FormElementAnnotated";
import CasePaidTaskFileUploadArea from "./CasePaidTaskFileUploadArea";
import { ContentContainer } from "../Container/PageContentContainer";
import ButtonLoading from "../Button/ButtonLoading";
import { editorStateToHTML } from "../../services/Editor/editorFunctions";
import moment from "moment";
import { buildAddress } from "../Case/CasePdfGenerator/casePdfGeneratorService";
import PropTypes from "prop-types";
import { clearSessionStorageState } from "../../services/browserStorageService";
import { PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_IN_REVIEW } from "../PaidTask/paidTaskStatus";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useSnackbar } from "notistack";

import ContentBox from "../ContentBox/ContentBox";
import Typography from "@mui/material/Typography";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { FormContainer, RadioButtonGroup, useForm } from "react-hook-form-mui";
import { flatten } from "flat";

const CasePaidTaskSubmission = ({ paidTask, product }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalData, setErrorModalData] = useState("");
  const [choiceConfirmed, setChoiceConfirmed] = useState(false);
  const updateMutation = useMutation(updateResource);
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const paidTaskTypeConfig = useMemo(
    () => _.find(getTemplates(product), (template) => template.type === paidTask.type),
    [paidTask.type]
  );

  const checkNonValidatorConditions = () => {
    if (paidTaskTypeConfig.hasEditor && editorStateToHTML(formContext.getValues().content).length < 200) {
      setErrorModalData("editorContentLength");
      return false;
    }

    if (
      paidTaskTypeConfig.mainDocumentConfig &&
      !_.find(paidTask.mediaObjects, (mediaObject) => mediaObject.identifier === "paidTaskMainDocument")
    ) {
      setErrorModalData("requiredFileMissing");
      return false;
    }
    return true;
  };

  //triggers status change when externals execute it
  const onSubmit = async () => {
    if (!checkNonValidatorConditions()) {
      return;
    }

    const values = formContext.getValues();

    //todo improve when theres enough time. this shouldn't even need to flatten anything. the other code should be able to handle nested objects. (temporary fields)
    const objectFilter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

    const temporaryFields = objectFilter(values, ([key]) => key.startsWith("temporary"));
    const normalFields = objectFilter(values, ([key]) => !key.startsWith("temporary"));

    const convertedValues = {
      ...normalFields,
      ...flatten(temporaryFields, { maxDepth: 2 }),
    };

    // end todo

    setIsLoading(true);
    const formData = _.merge({}, convertedValues);
    if (formData?.content?.getCurrentContent) {
      //not convertToRaw because, even though it's the right format for saving, we need the html in the backend subscriber
      formData.content = editorStateToHTML(formData.content);
      formData.address = buildAddress(convertedValues, product);
      clearSessionStorageState("customPdf");
    }

    let payload = {
      formData: formData,
    };

    if (currentUser.isExternal) {
      payload.markedAsDoneDate = moment().format("DD.MM.YYYY");
      payload.changesRequested = false;
    }

    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: payload,
    });

    await queryClient.invalidateQueries(queryKeys.item("paid_tasks", paidTask.id));

    if (currentUser.isExternal) {
      enqueueSnackbar("Sie haben diese Aufgabe erledigt", {
        variant: "custom",
        buttonType: "link",
        buttonLink: "/services/extern/aufgaben",
        buttonTarget: "_self",
        buttonText: "Meine Aufgaben",

        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        persist: true,
      });
    }
    setIsLoading(false);
  };

  const initialValues = useMemo(
    () => getInitialValuesForPaidTask(paidTaskTypeConfig, product, paidTask),
    [paidTaskTypeConfig, product, paidTask]
  );

  const formContext = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  if (!paidTaskTypeConfig) {
    return <Stack justifyContent={"center"}>Für diesen Ausschreibentyp ist keine Konfiguration hinterlegt</Stack>;
  }

  const formFieldsEnabled =
    currentUser.isExternal || (paidTaskTypeConfig.hasEditor && paidTask.status === PAID_TASK_STATUS_IN_REVIEW);

  const isDisabled = !getSubmissionCardEnabled(paidTask.status, currentUser.isExternal);

  return (
    <Box
      sx={
        isDisabled
          ? {
              opacity: 0.5,
              pointerEvents: "none",
            }
          : {}
      }
    >
      <ContentBox headline="Bearbeitung" headlineVariant="h2">
        <Box sx={casePaidTaskStyle.verticalSpace} />
        <FormContainer formContext={formContext}>
          {paidTaskTypeConfig.hasEditor && (
            <CasePaidTaskPdfGenerator paidTask={paidTask} product={product} initialValues={initialValues} />
          )}
          <ContentContainer size={"tiny"} sx={casePaidTaskStyle.formElementsContainer}>
            <CasePaidTaskFileUploadArea
              paidTaskTypeConfig={paidTaskTypeConfig}
              product={product}
              paidTask={paidTask}
              readOnly={paidTask.status !== PAID_TASK_STATUS_ASSIGNED}
            />
            <Box sx={casePaidTaskStyle.verticalSpace} />
            {_.map(paidTaskTypeConfig.fields, (formElementProps) => (
              <Box sx={casePaidTaskStyle.formElement} key={formElementProps.path}>
                <FormElementAnnotated
                  headline={formElementProps.headline}
                  description={formElementProps.description}
                  product={product}
                  {...formElementProps}
                  path={"temporaryField." + formElementProps.path}
                  translationPath={product.productClassName + "." + formElementProps.path}
                  isDisabled={() => !formFieldsEnabled}
                />
              </Box>
            ))}
            <Stack direction={"column"} alignItems={"center"} spacing={2} sx={casePaidTaskStyle.buttonArea}>
              {currentUser.isExternal && (
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox checked={choiceConfirmed} onChange={({ target }) => setChoiceConfirmed(target.checked)} />
                  }
                  label={"Ich möchten diese Aufgabe als erledigt markieren"}
                />
              )}
              {currentUser.isExternal && (
                <ButtonLoading
                  variant={"contained"}
                  isLoading={isLoading}
                  sx={casePaidTaskStyle.submitButton}
                  disabled={!choiceConfirmed}
                  onClick={formContext.handleSubmit(onSubmit)}
                  fullWidth={false}
                >
                  Aufgabe erledigt
                </ButtonLoading>
              )}
              {!currentUser.isExternal &&
                paidTask.status === PAID_TASK_STATUS_IN_REVIEW &&
                paidTaskTypeConfig.hasEditor && (
                  <>
                    <Typography variant={"h3"}>Auswahl der Unterschrift</Typography>
                    <RadioButtonGroup
                      row
                      options={[
                        {
                          id: "internal",
                          label: paidTask.backofficeCase?.lawyer?.person?.fullname || "Interner Anwalt",
                        },
                        { id: "external", label: paidTask.assignee?.person?.fullname || "Externer Anwalt" },
                      ]}
                      name={"sigantureType"}
                    />
                    <ButtonLoading
                      variant={"contained"}
                      isLoading={isLoading}
                      sx={casePaidTaskStyle.submitButton}
                      onClick={formContext.handleSubmit(onSubmit)}
                      fullWidth={false}
                    >
                      Änderungen speichern
                    </ButtonLoading>
                  </>
                )}
            </Stack>
          </ContentContainer>
        </FormContainer>
        <LegalbirdIoModal
          handleClose={() => setErrorModalData("")}
          open={!!errorModalData}
          title={"Erledigung nicht möglich"}
          hideCancelButton
          submitButton={
            <Button onClick={() => setErrorModalData("")} variant={"contained"} color={"primary"}>
              Ok
            </Button>
          }
        >
          {errorModalData === "editorContentLength" &&
            "Der Text im Editor für das Schreiben ist leider mit weniger als 200 Zeichen zu kurz. Ist das Schreiben tatsächlich so kurz, oder haben Sie vergessen, den Text einzufügen?"}
          {errorModalData === "requiredFileMissing" &&
            "Leider haben Sie nicht alle geforderten Dokumente hochgeladen. Bitte prüfen Sie die hochgeladenen Dokumente."}
        </LegalbirdIoModal>
      </ContentBox>
    </Box>
  );
};

CasePaidTaskSubmission.propTypes = {
  paidTask: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

export default CasePaidTaskSubmission;
