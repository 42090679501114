
import PageContentContainer from "../../../Container/PageContentContainer";
import PaidTaskContainer from "../../../PaidTask/PaidTaskTable/PaidTaskContainer";

const ExternalsPaidTasksPage = () => {
  return (
    <PageContentContainer>
      <PaidTaskContainer headline={"Ausgeschriebene Aufgaben"} displayType={"assignableView"} />
    </PageContentContainer>
  );
};

export default ExternalsPaidTasksPage;
