export const fileSectionStyle = {
  container: {
    padding: "1rem",
  },
  headline: {
    fontSize: "1rem",
    padding: "1.5rem",
    fontWeight: 700,
  },
  paper: {
    margin: "2rem 0",
  },
  file: {
    textAlign: "center",
  },
  icon: {
    color: "black",
  },
  fileName: {
    color: "#137f7b",
    fontSize: "1rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteModalText: {
    textAlign: "center",
    marginTop: "1rem",
  },
  fileSectionContainer: {
    marginBottom: "2rem",
  },
  addFileButton: {
    paddingRight: "2rem",
  },
};
