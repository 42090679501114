import React, { useState } from "react";
import ClearableSearchField from "../../../Search/ClearableSearchField";

type SubjectFilterProps = {
  setSubjectFilter: (value: string) => void;
};

export default function SubjectFilter({ setSubjectFilter }: SubjectFilterProps) {
  const [searchFieldContent, setSearchFieldContent] = useState("");

  const triggerSearch = () => {
    setSubjectFilter(searchFieldContent);
  };

  const handleClearFilter = () => {
    setSearchFieldContent("");
    setSubjectFilter("");
  };

  return (
    <ClearableSearchField
      filter={searchFieldContent}
      placeholder={"Beschreibung"}
      setFilter={setSearchFieldContent}
      handleClearFilter={handleClearFilter}
      showSearchButton
      handleSearchClick={triggerSearch}
    />
  );
}
