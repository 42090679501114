import React, { useState } from "react";
import ContentBox from "../../ContentBox/ContentBox";
import BillingForm from "./BillingForm";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { userHasRole } from "../../../services/backofficeUserService";
import { Box, Button, Divider, Typography } from "@mui/material";
import { scrollToAnchor } from "../../../theme/commonStyles";
import _ from "lodash";
import ExternalLawyerInvoiceRow from "./ExternalLawyerInvoiceRow";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Roles } from "../../../types/BackofficeUser";

export default function Billing() {
  const currentUser = useCurrentUser();
  const pageSize = 3;
  const [invoicesToShowCount, setInvoicesToShowCount] = useState(pageSize);

  const backofficeUserFilter = {
    backofficeUser: currentUser.id,
  };

  const { data: externalUserCollection, isLoading: isLoadingExternalUser } = useQuery(
    queryKeys.collection("external_users", backofficeUserFilter),
    () => fetchCollection("external_users", backofficeUserFilter),
    { enabled: currentUser.isExternal }
  );

  const { data: invoicesCollection, isLoading: isLoadingInvoices } = useQuery(
    queryKeys.collection("external_invoices", backofficeUserFilter),
    () => fetchCollection("external_invoices", backofficeUserFilter),
    { enabled: currentUser.isExternal }
  );

  if (!userHasRole(currentUser, Roles.external)) {
    return null;
  }

  if (!currentUser || isLoadingInvoices || isLoadingExternalUser) {
    return <LegalbirdLoader centered />;
  }

  const sortedInvoices = _.orderBy(invoicesCollection["hydra:member"], ["updated"], ["desc"]);

  return (
    <>
      <Box sx={scrollToAnchor} id={"billing"} />
      <ContentBox headline={"Abrechnung"}>
        <BillingForm
          bankData={_.get(externalUserCollection, '["hydra:member"][0].bankData')}
          backofficeUser={currentUser}
        />
        <Divider sx={{ margin: "3rem 0" }} />
        <Typography sx={{ textAlign: "center" }} variant={"h3"}>
          Meine Abrechnungen
        </Typography>
        <Box sx={{ marginTop: "4rem" }}>
          {invoicesCollection["hydra:totalItems"] === 0 ? (
            <Typography sx={{ textAlign: "center", color: "text.light" }}>Noch kein Dokument vorhanden</Typography>
          ) : (
            <>
              {_.map(sortedInvoices.slice(0, invoicesToShowCount), (invoice) => (
                <ExternalLawyerInvoiceRow key={invoice.id} invoice={invoice} />
              ))}
              {sortedInvoices.length > invoicesToShowCount && (
                <Button onClick={() => setInvoicesToShowCount(invoicesToShowCount + 2)}>Mehr Anzeigen</Button>
              )}
              {(sortedInvoices.length <= invoicesToShowCount || invoicesToShowCount > pageSize) && (
                <Button onClick={() => setInvoicesToShowCount(pageSize)}>Weniger Anzeigen</Button>
              )}
            </>
          )}
        </Box>
      </ContentBox>
    </>
  );
}
