const VAT = 0.19;
const VAT_MULTIPLIER = 1.19;
const VAT_OLD = 0.16;
const VAT_MULTIPLIER_OLD = 1.16;
const SERVICE_EMAIL = "service@legalbird.de";
const SERVICE_PHONE = "0221/98651940";

const LAW_FIRM_NAME = "Legalbird Rechtsanwälte Rechtsanwalts GmbH";
const LAW_FIRM_ADDRESS = "Im Zollhafen 24";
const LAW_FIRM_POSTAL_CODE = 50678;
const LAW_FIRM_ADDRESS_LOCALITY = "Köln";
const RS_NAME = "Legalbird GmbH";
const RS_ADDRESS = "Im Zollhafen 24";
const RS_POSTAL_CODE = 50678;
const RS_ADDRESS_LOCALITY = "Köln";

export {
  VAT,
  VAT_MULTIPLIER,
  VAT_OLD,
  VAT_MULTIPLIER_OLD,
  SERVICE_EMAIL,
  SERVICE_PHONE,
  LAW_FIRM_NAME,
  LAW_FIRM_ADDRESS,
  LAW_FIRM_POSTAL_CODE,
  LAW_FIRM_ADDRESS_LOCALITY,
  RS_NAME,
  RS_ADDRESS,
  RS_POSTAL_CODE,
  RS_ADDRESS_LOCALITY,
};
