/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { dateFieldDefault, textFieldDefault } from "../../../validationRules";
import * as stagesSettlement from "../../../Stages/StagesSettlement";
import { formValue } from "../../../formServiceFunctions";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import { AbstractCase } from "../../../../types/AbstractCase";
import { UseForm } from "../../../../types/UseForm";

const lateFinancialInfo = [
  {
    type: "ValidatorDateField",
    path: "settlement.settlementRevocationDate",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.valueInDispute",
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.nWnFAmount",
    isHidden: ({ product }: { product: AbstractCase }) => product.paymentType !== "nwnf",
    fieldType: "money",
    numberType: "float",
  },
];

const settlementCourtFields = [
  {
    type: "ValidatorDateField",
    path: "settlement.settlementReachedDate",
    validators: dateFieldDefault,
  },
];

const preChecked = [
  {
    type: "ValidatorDateField",
    path: "settlement.dismissalProtectionSuitDeadline",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorTextField",
    path: "settlement.powerOfAttorney",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "settlement.paymentType",
    options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "nwnf" }, { value: "private" }],
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.nWnFAmount",
    isHidden: ({ values }: { values: UseForm["values"] }) => formValue(values, "settlement.paymentType") !== "nwnf",
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorSelect",
    path: "settlement.contractTerminated",
    options: () => trueFalseDefaultOptions("settlement.contractTerminated"),
  },
  {
    type: "ValidatorSelect",
    path: "settlement.numberOfEmployees",
    options: () => [{ value: "__null__" }, { value: 10 }, { value: 50 }, { value: 500 }, { value: 1000 }],
  },
  {
    type: "ValidatorDateField",
    path: "settlement.employedSince",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.grossSalary",
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorTextField",
    path: "settlement.jobDescription",
    validators: [...textFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.worksCouncil",
    options: () => trueFalseDefaultOptions("settlement.worksCouncil"),
  },
  {
    type: "ValidatorSelect",
    path: "settlement.terminationObstacles",
    options: ({ values, product }: { values: UseForm["values"]; product: AbstractCase }) => {
      let obstacles = [];
      obstacles.push({ value: "parent" });
      product.petitioner.gender === "female" && obstacles.push({ value: "pregnant" });
      obstacles.push({ value: "disabled" });
      formValue(values, "settlement.worksCouncil") === true && obstacles.push({ value: "council" });
      obstacles.push({ value: "privacyOfficer" });
      obstacles.push({ value: "apprentice" });
      return obstacles;
    },
    additionalProps: {
      multiple: true,
    },
  },
  {
    type: "ValidatorDateField",
    path: "settlement.contractTerminationReceivedDate",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.terminationNoticeType",
    options: () => [{ value: "__null__" }, { value: "withoutNotice" }, { value: "notice" }, { value: "unsure" }],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.preferredOutcome",
    options: () => [{ value: "__null__" }, { value: "settlement" }, { value: "employment" }],
  },
];

export const stageFormDefinition = {
  [stagesSettlement.STAGE_INCOMPLETE]: preChecked,
  [stagesSettlement.STAGE_READY]: preChecked,
  [stagesSettlement.STAGE_CHECKED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.dismissalProtectionSuitDeadline",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.insuranceCoverageDate",
      isHidden: ({ product }: { product: AbstractCase }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
  ],
  [stagesSettlement.STAGE_SUBMITTED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.dismissalProtectionSuitDeadline",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.dismissalProtectionSuitSubmittedDate",
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.insuranceCoverageDate",
      isHidden: ({ product }: { product: AbstractCase }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
    ...settlementCourtFields,
  ],
  [stagesSettlement.STAGE_PAID]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.insuranceCoverageDate",
      isHidden: ({ product }: { product: AbstractCase }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
    ...settlementCourtFields,
  ],
  [stagesSettlement.STAGE_DATE_SET_SETTLEMENT]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    ...settlementCourtFields,
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_DATE_SET_TRIAL]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.settlementReachedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.finalJudgement",
      validators: dateFieldDefault,
      isHidden: ({ values }: { values: UseForm["values"] }) => !!formValue(values, "settlement.settlementReachedDate"),
    },
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_DECISION_MADE]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.settlementReachedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.finalJudgement",
      validators: dateFieldDefault,
      isHidden: ({ values }: { values: UseForm["values"] }) => !!formValue(values, "settlement.settlementReachedDate"),
    },
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_COMPLETED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.settlementReachedDate",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => !product.settlementReachedDate,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.finalJudgement",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }: { product: AbstractCase }) => !product.finalJudgement,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
  ],
};
