import React, { useMemo } from "react";
import { paidTaskTableStyle } from "./paidTaskTableStyle";
import {
  getCaseLinkByBackofficeCase,
  getFullProductLabel,
  getProductData,
  getProductNameBy,
} from "../../../services/Product/ProductService";
import { Avatar, Button, TableCell } from "@mui/material";
import { Link } from "react-router-dom";
import { convertFromFloat } from "../../../services/formServiceFunctions";
import moment from "moment";
import {
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_REQUESTED,
} from "../paidTaskStatus";
import _ from "lodash";
import CasePaidTaskAccept from "../../CasePaidTask/CasePaidTaskAccept";
import { MoveToInboxOutlined, TaskAltOutlined } from "@mui/icons-material";
import { PaidTask } from "../../../types/PaidTask";
import Typography from "@mui/material/Typography";

type ExternalPaidTaskTableRowProps = {
  paidTask: PaidTask;
  isMinimalView?: boolean;
  isRequestedView?: boolean;
};
export default function ExternalPaidTaskTableRow({
  paidTask,
  isMinimalView = false,
  isRequestedView = false,
}: ExternalPaidTaskTableRowProps) {
  const productClassName = useMemo(() => {
    return getProductNameBy("lbProductClass", paidTask.backofficeCase.lbProductClass);
  }, [paidTask.backofficeCase.lbProductClass]);
  const fullProductLabel = useMemo(() => {
    return getFullProductLabel({
      productClassName,
      productCategory: getProductData(productClassName, "productCategory"),
    });
  }, [productClassName]);

  const caseLink = getCaseLinkByBackofficeCase(paidTask.backofficeCase, "/extern");
  return (
    <>
      <TableCell size={"small"} padding={"checkbox"}>
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
          {isRequestedView ? <MoveToInboxOutlined /> : <TaskAltOutlined />}
        </Avatar>
      </TableCell>
      <TableCell>
        {paidTask.status < PAID_TASK_STATUS_ASSIGNED ? (
          <>{paidTask.backofficeCase.label}</>
        ) : (
          <Typography sx={paidTaskTableStyle.link}>
            <Link to={caseLink}>{paidTask.backofficeCase.label}</Link>
          </Typography>
        )}
      </TableCell>
      <TableCell>{fullProductLabel}</TableCell>
      <TableCell>{paidTask.subject}</TableCell>
      {!isMinimalView && <TableCell>{convertFromFloat(paidTask.fee)} €</TableCell>}
      {isRequestedView && <TableCell>{moment(paidTask.assignmentDeadline).format("DD.MM.YYYY")}</TableCell>}
      <TableCell>{moment(paidTask.doneDeadline).format("DD.MM.YYYY")}</TableCell>
      <TableCell>
        {_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status) ? (
          <CasePaidTaskAccept paidTask={paidTask} />
        ) : (
          <Button component={Link} sx={paidTaskTableStyle.link} to={caseLink + "/ausschreibung/" + paidTask.id}>
            Details
          </Button>
        )}
      </TableCell>
    </>
  );
}
