import customerTranslations from "./customerTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const purchaseContractTranslations = {
  customer: customerTranslations,
  ...contractClaimTranslations,
  label: "Kaufvertrag",
  reasonForDispute: {
    label: "Problem des Kunden",
    values: {
      other: "Anderes Problem",
    },
  },
  buyerOrSeller: {
    label: "Käufer oder Verkäufer",
    values: {
      buyer: "Käufer",
      seller: "Verkäufer",
    },
  },
  channel: {
    label: "Verkaufskanal",
    values: {
      amazon: "Amazon",
      ebay: "Ebay",
      kleinanzeigen: "Internetportal “Kleinanzeigen”",
      onlineOther: "Anderer Onlineshop",
      offline: "Stationärer Handel/Laden vor Ort",
      fleaMarket: "Flohmarkt",
      vinted: "Vinted",
      other: "Sonstiges",
    },
  },
  contractNumber: {
    label: "Bestell-/Anzeigennummer",
  },
  itemTitle: {
    label: "Bezeichnung Kaufsache",
  },
  articleNumber: {
    label: "Artikelnummer",
  },
  purchasePrice: {
    label: "Kaufpreis",
  },
  locationOfGood: {
    label: "Standort der Ware",
    values: {
      buyer: "beim Käufer",
      seller: "beim Verkäufer",
      elsewhere: "an einem anderen Ort",
      unknown: "unbekannt",
    },
  },
  hasDefects: {
    label: "Mängel vorhanden",
  },
  defectsDescriptionClient: {
    label: "Mängelbeschreibung Mandant",
  },
  defectsDescriptionLawFirm: {
    label: "Mängelbeschreibung Kanzlei",
  },
  numberRepairAttempts: {
    label: "Nachbesserungsversuche",
    values: {
      0: "Keine",
      1: "Einmal",
      2: "Zweimal",
      3: "Dreimal oder häufiger",
    },
  },
  numberReplacementAttempts: {
    label: "Ersatzversuche",
    values: {
      0: "Keine",
      1: "Einmal",
      2: "Zweimal",
      3: "Dreimal oder häufiger",
    },
  },
};

export default purchaseContractTranslations;
