import React, { useEffect, useState } from "react";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import { BackofficeUser, Roles } from "../../types/BackofficeUser";
import _ from "lodash";
import { translate } from "../../services/Translations/translatorService";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import ApiClient from "../../services/ApiClient";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import BackofficeUserDataFormElements from "./BackofficeUserDataFormElements";
import ButtonLoading from "../Button/ButtonLoading";
import PopUpMenu from "../PopUpMenu/PopUpMenu";
import useForm from "../../hooks/useForm";
import { userHasRole } from "../../services/backofficeUserService";
import { ExternalUser } from "../../types/ExternalUser";
import { UseForm } from "../../types/UseForm";

import { useSnackbar } from "notistack";

type UserRowProps = {
  user: BackofficeUser;
  fetchBackofficeUsers: Function;
};

export default function UserRow({ user, fetchBackofficeUsers }: UserRowProps) {
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [externalUserEntity, setExternalUserEntity] = useState<ExternalUser | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async () => {
    setIsLoading(true);
    await ApiClient.post("reset_user_password", { body: JSON.stringify({ email: user.email }) });
    enqueueSnackbar("Passwort-Mail versendet", {
      variant: "custom",
      isNonInteractive: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsLoading(false);
  };

  const fetchExternalUserEntity = async () => {
    if (!userHasRole(user, Roles.external)) {
      return;
    }
    const externalUserCollection = await ApiClient.get("external_users?backofficeUser=" + user.id);
    setExternalUserEntity(externalUserCollection["hydra:member"][0]);
  };

  useEffect(() => {
    fetchExternalUserEntity();
  }, [user]);

  useEffect(() => {
    if (!externalUserEntity || !useFormProps) {
      return;
    }
    const processableTaskTypes = _.map(
      externalUserEntity.processableTaskTypes,
      (processableTaskType) => processableTaskType.taskType
    );

    useFormProps.handleChange({ target: { name: "processableTaskTypes", value: processableTaskTypes } });
  }, [externalUserEntity]);

  const initialValues = {
    gender: user.person.gender,
    givenName: user.person.givenName,
    familyName: user.person.familyName,
    email: user.email,
    roles: user.roles,
    processableTaskTypes: [],
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const { values } = useFormProps;

    let promiseList = [];

    promiseList.push(
      ApiClient.put(user.person["@id"], {
        body: JSON.stringify({
          gender: values.gender,
          givenName: values.givenName,
          familyName: values.familyName,
        }),
      })
    );
    promiseList.push(
      ApiClient.put(user["@id"], {
        body: JSON.stringify({
          email: values.email.toLowerCase(),
          roles: values.roles,
        }),
      })
    );

    if (userHasRole(user, Roles.external) && externalUserEntity) {
      _.forEach(externalUserEntity.processableTaskTypes, (processableTaskType) => {
        promiseList.push(ApiClient.delete(processableTaskType["@id"]));
      });

      _.forEach(values.processableTaskTypes, (processableTaskType) => {
        promiseList.push(
          ApiClient.post("external_task_types", {
            body: JSON.stringify({
              externalUser: externalUserEntity["@id"],
              taskType: processableTaskType,
            }),
          })
        );
      });
    }
    await Promise.all(promiseList);
    await fetchBackofficeUsers();
    setIsLoading(false);
    setEditUserModalOpen(false);
  };

  const useFormProps: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  const handleDeactivateUser = async () => {
    try {
      await ApiClient.put("backoffice_users/" + user.id, {
        body: JSON.stringify({
          status: "inactive",
        }),
      });
      enqueueSnackbar("Nutzer erfolgreich deaktiviert", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      await fetchBackofficeUsers();
    } catch (e: any) {
      enqueueSnackbar(e?.errors?.backofficeUser || "Es ist ein Fehler aufgetreten", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        persist: true,
      });
    }

  };

  return (
    <TableRow>
      <TableCell>
        {translate("backofficeUser.person.gender.values." + user.person.gender)} {user.person.fullname}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{translate("backofficeUser.status.values." + user.status)}</TableCell>
      <TableCell>
        {_.join(
          _.map(
            _.filter(user.roles, (role) => role !== Roles.user),
            (role) => translate("backofficeUser.roles.values." + role)
          ),
          ", "
        )}
      </TableCell>
      <TableCell>
        {externalUserEntity &&
          _.join(
            _.map(externalUserEntity.processableTaskTypes, (taskType) =>
              translate("externalUser.processableTaskTypes.values." + taskType.taskType)
            ),
            ", "
          )}
      </TableCell>
      <TableCell>
        <PopUpMenu>
          <MenuItem onClick={() => setEditUserModalOpen(true)}>Daten ändern</MenuItem>
          <MenuItem disabled={user.status === "inactive" || userHasRole(user, Roles.admin)} onClick={() => setDeactivateUserModalOpen(true)}>
            Nutzer deaktivieren
          </MenuItem>
          <MenuItem onClick={handleResetPassword}>Passwort neu</MenuItem>
        </PopUpMenu>
        <LegalbirdIoConfirm
          handleClose={() => setDeactivateUserModalOpen(false)}
          open={deactivateUserModalOpen}
          confirmText={"Löschen"}
          content={"Möchten Sie den Nutzer deaktivieren? Dies kann nicht rückgängig gemacht werden."}
          handleConfirm={handleDeactivateUser}
          headline={"Nutzer deaktivieren"}
        />
        <LegalbirdIoModal
          title={"Nutzerdaten ändern"}
          submitButton={
            <ButtonLoading variant={"contained"} onClick={useFormProps.handleSubmit} isLoading={isLoading}>
              Speichern
            </ButtonLoading>
          }
          handleClose={() => setEditUserModalOpen(false)}
          open={editUserModalOpen}
        >
          <BackofficeUserDataFormElements
            useFormProps={useFormProps}
            showExternalFields={userHasRole(user, Roles.external)}
            initialValues={initialValues}
          />
        </LegalbirdIoModal>
      </TableCell>
    </TableRow>
  );
}
