import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import ApiClient from "../../../services/ApiClient";
import {
  buildApiFilters,
  buildSearchParams,
  postProcessFilterActivities,
  prepareFilters,
} from "../../../services/Activities/activityFunctions";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import PageContentContainer from "../../../components/Container/PageContentContainer";
import OverviewActivityEntry from "./OverviewActivityEntry";
import {Box, Divider, Grid, Tab, Tabs} from "@mui/material";
import ActivityOverviewHeader from "../ActivityOverviewHeader/ActivityOverviewHeader";
import activityOverviewStyle from "./activityOverviewStyle";
import UnassignedActivities from "../UnassignedActivities/UnassignedActivities";
import { TabContext, TabPanel } from "@mui/lab";
import { FormatListBulleted, MailOutline, PlaylistAdd, SvgIconComponent } from "@mui/icons-material";
import { userHasOneOfTheseRoles } from "../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { BackofficeUser, Roles } from "../../../types/BackofficeUser";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Activity } from "../../../types/Activity";

const ActivityOverview = () => {
  const currentUser = useCurrentUser();
  const { agents, backofficeUsers } = useBackofficeUser();

  const initialFilters = prepareFilters(currentUser, agents, "activityFiltersMobile", "all", "open");
  const initialTab = localStorage.getItem("savedTab") || "activities";
  const [productFilter, setProductFilter] = useState(initialFilters.productFilter);
  const [activityTypeFilter, setActivityTypeFilter] = useState(initialFilters.activityTypeFilter);
  const [userFilter, setUserFilter] = useState(initialFilters.userFilter);
  const [timespanFilter, setTimespanFilter] = useState(initialFilters.timespanFilter);
  const [userActivitiesToday, setUserActivitiesToday] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [filteredActivities, setFilteredActivities] = useState<Activity<BackofficeUser>[]>([]);
  const [mailAndDocumentActivities, setMailAndDocumentActivities] = useState<Activity<BackofficeUser>[]>([]);
  const [checkCaseActivities, setCheckCaseActivities] = useState<Activity<BackofficeUser>[]>([]);
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [openOrDoneFilter, setOpenOrDoneFilter] = useState(initialFilters.openOrDoneFilter);

  const dataSource = buildSearchParams(
    _.merge(
      buildApiFilters({
        productFilter: productFilter,
        activityTypeFilter: activityTypeFilter,
        userFilter: userFilter,
        timespanFilter: timespanFilter,
        openOrDoneFilter: openOrDoneFilter,
        backofficeUsers: backofficeUsers,
      }),
      {
        refreshCounter: refreshCounter,
      }
    )
  );
  const { activities, isLoading } = useActivitiesFetching(dataSource.toString(), backofficeUsers);

  const updateUserActivitiesToday = useCallback(async () => {
    if (userFilter === "all") {
      setUserActivitiesToday([]);
      return;
    }
    const filters = {
      assignedUser: userFilter,
      "dueDate[before]": moment().format("YYYY-MM-DD"),
      done: false,
      deleted: false,
      pagination: false,
    };

    let searchParams = new URLSearchParams();
    _.map(filters, (value, key) => {
      searchParams.append(key, value);
    });

    const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
    setUserActivitiesToday(userActivitiesResult["hydra:member"]);
  }, [userFilter]);

  const updateActivities = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    localStorage.setItem(
      "activityFiltersMobile",
      JSON.stringify(
        _.merge(
          {},
          {
            productFilter: productFilter,
            activityTypeFilter: activityTypeFilter,
            userFilter: userFilter,
            timespanFilter: timespanFilter,
            openOrDoneFilter: openOrDoneFilter,
          }
        )
      )
    );
  }, [productFilter, userFilter, timespanFilter, activityTypeFilter]);

  useEffect(() => {
    updateUserActivitiesToday();
  }, [userFilter, updateUserActivitiesToday]);

  useEffect(() => {
    if (!activities) {
      return;
    }
    let filteredActivities = isLoading ? [] : postProcessFilterActivities(activities, timespanFilter);
    setMailAndDocumentActivities(
      _.remove(filteredActivities, (activity) => _.includes(["mail", "check_document"], activity.type))
    );
    setCheckCaseActivities(_.remove(filteredActivities, (activity) => activity.type === "check_case"));
    setFilteredActivities(filteredActivities);
  }, [activities, isLoading, setMailAndDocumentActivities, setCheckCaseActivities, setFilteredActivities]);

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    localStorage.setItem("savedTab", newValue);
  };

  const TabIcon = ({ icon: Icon, listLength }: { icon: SvgIconComponent; listLength: number }) => {
    return (
      <Grid container alignItems={"flex-start"}>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Icon sx={activityOverviewStyle.icon} />
        </Grid>
        <Grid item xs={3}>
          {!isLoading && listLength > 0 && <span className={"MuiTabItem-tag"}>{listLength}</span>}
        </Grid>
      </Grid>
    );
  };

  return (
    <PageContentContainer>
      {userHasOneOfTheseRoles(currentUser, [Roles.accountmanager, Roles.admin]) && (
        <UnassignedActivities updateFilteredActivities={() => setRefreshCounter(refreshCounter + 1)} />
      )}
      <ActivityOverviewHeader
        setProductFilter={(data) => {
          updateActivities();
          setProductFilter(data);
        }}
        productFilter={productFilter}
        setActivityTypeFilter={(data: string) => {
          updateActivities();
          setActivityTypeFilter(data);
        }}
        activityTypeFilter={activityTypeFilter}
        userFilter={userFilter}
        setUserFilter={(data: string) => {
          updateActivities();
          setUserFilter(data);
        }}
        timespanFilter={timespanFilter}
        setTimespanFilter={(data: string) => {
          updateActivities();
          setTimespanFilter(data);
        }}
        openOrDoneFilter={openOrDoneFilter}
        setOpenOrDoneFilter={(data: string) => {
          updateActivities();
          setOpenOrDoneFilter(data);
        }}
        userActivitiesToday={userActivitiesToday}
        isLoading={isLoading}
        agents={agents}
      />
      <TabContext value={selectedTab}>
        <Tabs value={selectedTab} sx={activityOverviewStyle.tabs} onChange={handleTabChange} variant={"fullWidth"}>
          <Tab icon={<TabIcon icon={FormatListBulleted} listLength={filteredActivities.length} />} value="activities" />
          <Tab icon={<TabIcon icon={MailOutline} listLength={mailAndDocumentActivities.length} />} value="inbox" />
          <Tab icon={<TabIcon icon={PlaylistAdd} listLength={checkCaseActivities.length} />} value="checkCases" />
        </Tabs>
        <TabPanel sx={activityOverviewStyle.tabPanel} value="activities">
          {selectedTab === "activities" &&
            _.map(filteredActivities, (activity, index) => (
              <React.Fragment key={activity.id}>
                <OverviewActivityEntry activity={activity} update={updateActivities} />
                {index !== filteredActivities.length - 1 && (
                  <Box sx={activityOverviewStyle.divider}>
                    <Divider />
                  </Box>
                )}
              </React.Fragment>
            ))}
        </TabPanel>
        <TabPanel sx={activityOverviewStyle.tabPanel} value="inbox">
          {selectedTab === "inbox" &&
            _.map(mailAndDocumentActivities, (activity, index) => (
              <React.Fragment key={activity.id}>
                <OverviewActivityEntry activity={activity} update={updateActivities} />
                {index !== mailAndDocumentActivities.length - 1 && (
                  <Box sx={activityOverviewStyle.divider}>
                    <Divider />
                  </Box>
                )}
              </React.Fragment>
            ))}
        </TabPanel>
        <TabPanel sx={activityOverviewStyle.tabPanel} value="checkCases">
          {selectedTab === "checkCases" &&
            _.map(checkCaseActivities, (activity, index) => (
              <React.Fragment key={activity.id}>
                <OverviewActivityEntry activity={activity} update={updateActivities} />
                {index !== checkCaseActivities.length - 1 && (
                  <Box sx={activityOverviewStyle.divider}>
                    <Divider />
                  </Box>
                )}
              </React.Fragment>
            ))}
        </TabPanel>
      </TabContext>
    </PageContentContainer>
  );
};

export default ActivityOverview;
