import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { ACTIVITY_OVERDUE } from "../../services/Stages/Stages";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { Theme } from "@mui/material";
import { Activity } from "../../types/Activity";

const useStyles = makeStyles((theme: Theme) => ({
  today: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  overdue: {
    color: "#ca0d0d",
  },
  todayAndOverdue: {
    color: "#ca0d0d",
    fontWeight: 700,
  },
  spanContainer: {
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap",
    },
  },
}));

type ActivityInfoProps = {
  activity: Activity<string>;
};

const ActivityInfo = ({ activity }: ActivityInfoProps) => {
  const classes = useStyles({});
  const { getUserData } = useBackofficeUser();

  const getClassName = (activity: Activity, isToday: boolean) => {
    if (isToday && activity.activityStatus === ACTIVITY_OVERDUE) {
      return classes.todayAndOverdue;
    }
    if (isToday) {
      return classes.today;
    }
    if (activity.activityStatus === ACTIVITY_OVERDUE) {
      return classes.overdue;
    }
  };

  const isToday = moment(activity.dueDate).isSame(moment(), "day");
  const dateString = isToday ? "Heute" : moment(activity.dueDate).format("DD.MM.YYYY");
  const timeString = activity.dueTime !== "allDay" ? "um " + activity.dueTime + " Uhr" : "";

  return (
    <div className={classes.spanContainer}>
      <span className={getClassName(activity, isToday)}>
        {dateString} {timeString}
      </span>{" "}
      |{" "}
      <span className={activity.activityStatus === ACTIVITY_OVERDUE ? classes.overdue : undefined}>
        {activity.assignedUser ? getUserData(activity.assignedUser)!.person.fullname : "Nicht zugewiesen"}
      </span>
    </div>
  );
};

export default ActivityInfo;
