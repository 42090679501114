const activityStyle = {
  headline: {
    textAlign: "center",
  },
  activityCount: {
    fontSize: "1rem",
    fontWeight: 700,
    display: "inline"
  },
  activitiesOverdue: {
    marginLeft: "2rem",
    color: "#a31b1b",
  },
  filterContainer: {
    padding: { xs: "0", sm: "1rem" },
  },
  activityTable: {
    minWidth: "650px",
  },
  spinner: {
    display: "block",
    margin: "0 auto",
  },
  note: {
    paddingRight: "10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxHeight: "48px",
    maxWidth: "100px",
  },
  subject: {
    maxHeight: "48px",
    maxWidth: "170px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  link: {
    color: "primary.main",
    textDecoration: "none",
  },
  done: {
    textDecoration: "line-through",
  },
  overdue: {
    color: "#db1919",
  },
  mandatoryCell: {
    minWidth: "130px",
  },
  descriptionCell: {
    minWidth: "170px",
  },
  noteCell: {
    minWidth: "170px",
  },
  activeSorting: {
    color: "#137F7B",
  },
  sortableHeadline: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableHeadline: {
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "2rem",
  },
  tabContainer: {
    borderBottom: "1px solid #ddd",
  },
  tabPanel: {
    padding: 0,
  },
};

export default activityStyle;
