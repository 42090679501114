import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import casePdfGeneratorStyle from "./casePdfGeneratorStyle";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import { previewCaseFile } from "../../../services/Files/fileService";
import { AbstractCase } from "../../../types/AbstractCase";
import { MediaObject } from "../../../types/MediaObject";

// @ts-ignore
const useStyles = makeStyles(casePdfGeneratorStyle);

type PdfGeneratorPreviewProps = {
  product: AbstractCase;
  values: Record<string, any>;
  mediaObjects: MediaObject[];
  getParams: Function;
};

const PdfGeneratorPreview = ({ product, values, mediaObjects, getParams }: PdfGeneratorPreviewProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const classes = useStyles();

  const summedMediaObjectsSize = useMemo(() => {
    let totalMediaObjectSize = 0;

    if (!mediaObjects.length) {
      return 0;
    }

    _.forEach(values.selectedMediaObjects, (selectedMediaObject) => {
      totalMediaObjectSize +=
        _.find(mediaObjects, (mediaObject) => mediaObject.id === selectedMediaObject)?.["size"] || 0;
    });
    return totalMediaObjectSize;
  }, [values.selectedMediaObjects]);

  const generatePreview = async () => {
    setPreviewIsLoading(true);
    const previewSrc = await previewCaseFile({
      product: product,
      documentType: "pdf",
      documentIdentifier: "customPdf",
      params: getParams(),
    });
    setPreviewSrc(previewSrc);
    setPreviewOpen(true);
    setPreviewIsLoading(false);
  };

  const previewFileSizeLimitReached = summedMediaObjectsSize > 1500000;

  return (
    <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
      <Grid item xs={12} className={classes.buttonContainer}>
        {previewFileSizeLimitReached ? (
          <div className={classes.fileSizeWarning}>Vorschau nicht verfügbar - Anhang zu groß</div>
        ) : (
          <>
            <ButtonLoading
              className={classes.previewButton}
              variant={"text"}
              fullWidth={false}
              onClick={generatePreview}
              isLoading={previewIsLoading}
              disabled={previewFileSizeLimitReached}
            >
              Vorschau anzeigen
            </ButtonLoading>
            <LegalbirdIoModal
              handleClose={() => setPreviewOpen(false)}
              open={previewOpen}
              title={"Vorschau"}
              hasActions={false}
              maxWidth={"xl"}
            >
              <div className={classes.imageContainer}>
                <div className={classes.embedContainer}>
                  <embed src={previewSrc} type="application/pdf" width="100%" height="100%" />
                </div>
              </div>
            </LegalbirdIoModal>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PdfGeneratorPreview;
