import React, { useRef, useState } from "react";
import { Box, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { ExpandMore } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import _ from "lodash";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { agentPickerStyles } from "./agentPickerStyles";
import { BackofficeUser } from "../../types/BackofficeUser";
import { AbstractCase } from "../../types/AbstractCase";


type AgentPickerProps = {
  agentList: BackofficeUser[];
  currentAgent?: BackofficeUser;
  product: AbstractCase;
  type: "lawyer" | "accountManager";
  label: string;
  refreshPage?: Function;
  disabled?: boolean;
};
const AgentPicker = ({
  agentList,
  currentAgent,
  product,
  type,
  label,
  refreshPage,
  disabled = false,
}: AgentPickerProps) => {
  const myAnchor = useRef<HTMLDivElement>(null);
  const [selectedAgent, setSelectedAgent] = useState(currentAgent);
  const [menuOpen, setMenuOpen] = useState(false);
  const updateMutation = useMutation(updateResource);

  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Box sx={agentPickerStyles.container} onClick={!disabled ? handleOpenMenu : () => {}}>
      {selectedAgent ? (
        <>
          <Avatar src={selectedAgent.avatar || undefined} sx={agentPickerStyles.avatar}>
            {selectedAgent.person.givenName.charAt(0) + selectedAgent.person.familyName.charAt(0)}
          </Avatar>
          <Box>
            <Box sx={agentPickerStyles.label}>{label}</Box>
            <Box ref={myAnchor} />
            <Box sx={agentPickerStyles.text}>
              {selectedAgent.person.givenName + " " + selectedAgent.person.familyName}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Avatar sx={agentPickerStyles.avatar}>!</Avatar>
          <Box>
            <Box sx={agentPickerStyles.label}>{label}</Box>
            <Box ref={myAnchor} />
            <Box sx={agentPickerStyles.text}>Bitte auswählen</Box>
          </Box>
        </>
      )}
      {!disabled && <ExpandMore />}
      <Menu open={menuOpen} anchorEl={myAnchor.current} onClose={handleCloseMenu}>
        {_.map(agentList, (agent) => (
          <MenuItem
            onClick={async (event) => {
              event.stopPropagation();
              setSelectedAgent(agent);
              await updateMutation.mutateAsync({
                id: product.backofficeCase.id,
                uri: "backoffice_cases",
                data: { [type]: agent["@id"] },
              });
              setMenuOpen(false);
              if (refreshPage) {
                await refreshPage();
              }
            }}
            key={agent.id}
            value={agent.id}
          >
            {agent.person.givenName + " " + agent.person.familyName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AgentPicker;
