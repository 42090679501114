import { Link } from "react-router-dom";
import { Box, Button, Paper } from "@mui/material";
import error404PageStyle from "./error404PageStyle";
import Typography from "@mui/material/Typography";

const Error404Page = () => {
  return (
    <Box sx={error404PageStyle.grey}>
      <Paper sx={error404PageStyle.noAccessBox}>
        <Typography variant={"h3"}>Die aufgerufene Seite konnte nicht gefunden werden</Typography>
        <Button component={Link} variant={"text"} to={"/services"}>
          Zurück zur Übersicht
        </Button>
      </Paper>
    </Box>
  );
};

export default Error404Page;
