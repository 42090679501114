/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import { Chip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { activityFilterChipStyles } from "./ActivityFilterChipStyles";

type ActivityFilterChipProps = {
  label: string;
  isActive?: boolean;
  amount?: number;
  onClick?: () => void;
};

export default function ActivityFilterChip({
  label,
  amount = undefined,
  isActive = false,
  onClick = () => {},
}: ActivityFilterChipProps) {
  return (
    <Chip
      sx={activityFilterChipStyles.chip}
      avatar={amount !== undefined ? <Avatar style={activityFilterChipStyles.avatar}>{amount}</Avatar> : undefined}
      label={label}
      variant={"filled"}
      color={isActive === true ? "primary" : "default"}
      onClick={() => onClick()}
    />
  );
}
