import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { badgeStyles } from "./BadgeStyles";

type BadgeProps = {
  isActive?: boolean;
  text: string;
  linkTo?: string;
  size?: "small" | "large";
};

const Badge = ({ isActive = true, text, linkTo, size = "large" }: BadgeProps) => {
  if (isActive) {
    return <Box sx={{ ...badgeStyles.badge, ...badgeStyles.activeBadge, ...badgeStyles[size] }}>{text}</Box>;
  }
  if (linkTo) {
    return (
      <Box sx={{ ...badgeStyles.badge, ...badgeStyles.inactiveBadge, ...badgeStyles[size] }}>
        <Typography component={Link} to={linkTo!} sx={badgeStyles.link}>
          {text}
        </Typography>
      </Box>
    );
  }
  return null;
};

export default Badge;
