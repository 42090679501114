import { dateFieldDefault, postalCodeDefault, requiredFieldDefault, textFieldDefault } from "../../validationRules";
import { trueFalseDefaultOptions, yesNoDefaultOptions } from "./formDefinitionFunctions";
import { formValue } from "../../formServiceFunctions";

const representativeFields = () => {
  let fields = [];

  for (let i = 0; i < 10; i++) {
    fields.push(
      {
        type: "ValidatorSelect",
        path: "patientDecree.representatives[" + i + "].person.gender",
        options: () => [{ value: "male" }, { value: "female" }],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "patientDecree.representatives[" + i + "].person.givenName",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "patientDecree.representatives[" + i + "].person.familyName",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorDateField",
        path: "patientDecree.representatives[" + i + "].person.birthDate",
        validators: [...requiredFieldDefault, ...dateFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "patientDecree.representatives[" + i + "].person.residenceAddress.streetAddress",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "patientDecree.representatives[" + i + "].person.residenceAddress.postalCode",
        validators: [...textFieldDefault, ...requiredFieldDefault, postalCodeDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      },
      {
        type: "ValidatorTextField",
        path: "patientDecree.representatives[" + i + "].person.residenceAddress.addressLocality",
        validators: [...textFieldDefault, ...requiredFieldDefault],
        isHidden: ({ product }) => product.representatives.length < i + 1,
        isDisabled: () => true,
      }
    );
  }
  return fields;
};

export const fullFormDefinition = {
  product: {
    sections: [
      {
        label: "Angaben zur Patientenverfügung",
        elements: [
          {
            type: "ValidatorSelect",
            path: "patientDecree.petitionerSelection",
            isDisabled: () => true,
            options: () => [{ value: "self" }, { value: "other" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.petitioner.gender",
            isDisabled: () => true,
            options: () => [{ value: "male" }, { value: "female" },{value:"diverse"}],
          },
          {
            type: "ValidatorTextField",
            path: "patientDecree.petitioner.givenName",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "patientDecree.petitioner.familyName",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorDateField",
            path: "patientDecree.petitioner.birthDate",
            validators: [...requiredFieldDefault, ...dateFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "patientDecree.petitioner.residenceAddress.streetAddress",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "patientDecree.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, ...requiredFieldDefault, postalCodeDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "patientDecree.petitioner.residenceAddress.addressLocality",
            validators: [...textFieldDefault, ...requiredFieldDefault],
            isDisabled: () => true,
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.reason",
            isDisabled: () => true,
            options: () => [{ value: "general" }, { value: "medical" }, { value: "other" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.lifeSupport",
            isDisabled: () => true,
            options: () => [{ value: "always" }, { value: "limited" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.health",
            isDisabled: () => true,
            options: () => [{ value: "healthy" }, { value: "chronic" }, { value: "incurable" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.currentLifeSupport",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.currentLifeSupport"),
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.euthanasia",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.euthanasia"),
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.care",
            isDisabled: () => true,
            options: () => [{ value: "care_with_degree" }, { value: "care_no_degree" }, { value: "none" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.careDegree",
            isDisabled: () => true,
            isHidden: ({ values }) => formValue(values, "patientDecree.care") !== "care_with_degree",
            options: () => [{ value: "1" }, { value: "2" }, { value: "3" }, { value: "4" }, { value: "5" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.place",
            isDisabled: () => true,
            options: () => [{ value: "home" }, { value: "hospital" }, { value: "hospice" }, { value: "relatives" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.reanimationDeny",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.reanimationDeny"),
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.reanimationType",
            additionalProps: {
              multiple: true,
            },
            isDisabled: () => true,
            options: () => [{ value: "long" }, { value: "unattended" }, { value: "always" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.diagnosis",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.diagnosis"),
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.noSupportDiagnosis",
            isDisabled: () => true,
            additionalProps: {
              multiple: true,
            },
            options: () => [
              { value: "coma_diagnosis" },
              { value: "lockedin_diagnosis" },
              { value: "tumor_diagnosis" },
              { value: "braindamage_diagnosis" },
              { value: "none_diagnosis" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.noSupportSituation",
            isDisabled: () => true,
            additionalProps: {
              multiple: true,
            },
            options: () => [
              { value: "death_situation" },
              { value: "incurable_situation" },
              { value: "braindamage_situation" },
              { value: "dementia_situation" },
              { value: "none_situation" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.supportTypes",
            isDisabled: () => true,
            options: () => [
              { value: "artificial_feeding" },
              { value: "respiration" },
              { value: "dyalize" },
              { value: "transfusion" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.medication",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.medication"),
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.lifeQuality",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.lifeQuality"),
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.qualitySee",
            isDisabled: () => true,
            options: () => [{ value: "full" }, { value: "medium" }, { value: "low" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.qualityHear",
            isDisabled: () => true,
            options: () => [{ value: "full" }, { value: "medium" }, { value: "low" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.qualitySpeak",
            isDisabled: () => true,
            options: () => [{ value: "full" }, { value: "medium" }, { value: "low" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.qualityDeafAndBlind",
            isDisabled: () => true,
            options: () => [{ value: "full" }, { value: "low" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.qualityMobility",
            isDisabled: () => true,
            options: () => [{ value: "full" }, { value: "medium" }, { value: "low" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.qualityCare",
            isDisabled: () => true,
            options: () => [{ value: "full" }, { value: "medium" }, { value: "low" }, { value: "unsure" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.organReceive",
            isDisabled: () => true,
            options: () => [{ value: "no" }, { value: "yes" }, { value: "some" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.organReceiveSet",
            isDisabled: () => true,
            isHidden: ({ values }) => formValue(values, "patientDecree.organReceive") !== "some",
            options: () => [
              { value: "heart" },
              { value: "lung" },
              { value: "liver" },
              { value: "kidney" },
              { value: "pancreas" },
              { value: "gut" },
              { value: "eyes" },
              { value: "valves" },
              { value: "skin" },
              { value: "vessels" },
              { value: "bonetissue" },
              { value: "cartilage" },
              { value: "softtissue" },
            ],
            additionalProps: {
              multiple: true,
            },
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.organDonateSet",
            isDisabled: () => true,
            isHidden: ({ values }) => formValue(values, "patientDecree.organReceive") !== "some",
            options: () => [
              { value: "heart" },
              { value: "lung" },
              { value: "liver" },
              { value: "kidney" },
              { value: "pancreas" },
              { value: "gut" },
              { value: "eyes" },
              { value: "valves" },
              { value: "skin" },
              { value: "vessels" },
              { value: "bonetissue" },
              { value: "cartilage" },
              { value: "softtissue" },
            ],
            additionalProps: {
              multiple: true,
            },
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.organDonatePriority",
            isDisabled: () => true,
            options: () => [{ value: "donate" }, { value: "patientDecree" }],
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.hasRepresentatives",
            isDisabled: () => true,
            options: () => trueFalseDefaultOptions("patientDecree.hasRepresentatives"),
          },
          ...representativeFields(),
          {
            type: "ValidatorSelect",
            path: "patientDecree.doctorSignature",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.doctorSignature"),
          },
          {
            type: "ValidatorSelect",
            path: "patientDecree.personalText",
            isDisabled: () => true,
            options: () => yesNoDefaultOptions("patientDecree.personalText"),
          },
          {
            type: "ValidatorTextField",
            path: "patientDecree.personalTextMessage",
            validators: textFieldDefault,
            isDisabled: () => true,
            isHidden: ({ values }) => formValue(values, "patientDecree.personalText") !== "yes",
            additionalProps: {
              multiline: true,
              rows: 12,
              fullWidth: true,
            },
          },
        ],
      },
    ],
  },
};
