/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import NavigationBarBrand from "../NavigationBarBrand/NavigationBarBrand";
import React, { ReactNode } from "react";
import Footer from "../Footer/Footer";
import { Box } from "@mui/material";

interface LayoutProps {
  children: ReactNode;
}

export default function LayoutPublicPage({ children }: LayoutProps) {
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <NavigationBarBrand />
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
      <Footer />
    </Box>
  );
}
