import React, { useEffect, useState } from "react";
import calendlyAssessmentStyle from "./calendlyAssessmentStyle";
import { Box, Grid } from "@mui/material";
import { Rating } from "@mui/material";
import { translate } from "../../services/Translations/translatorService";
import { nl2br } from "../../services/formServiceFunctions";
import ApiClient from "../../services/ApiClient";
import { Activity } from "../../types/Activity";
import { CalendlyAssessment } from "../../types/Calendly/CalendlyAssessment";

export default function CalendlyAssessmentActivity({ activity }: { activity: Activity }) {
  const [calendlyEvent, setCalendlyEvent] = useState<any>(null);

  useEffect(() => {
    if (!!activity.calendlyEvent && !calendlyEvent) {
      ApiClient.get(activity.calendlyEvent).then((response: any) => setCalendlyEvent(response));
    }
  }, [activity, calendlyEvent]);

  if (
    !calendlyEvent ||
    !activity.done ||
    !["calendly_event", "lawyer_counseling_call"].includes(activity.type) ||
    !calendlyEvent ||
    !calendlyEvent.assessment ||
    calendlyEvent.assessment.processed === null
  ) {
    return null;
  }

  const { assessment }: { assessment: CalendlyAssessment } = calendlyEvent;

  const boolToText = (value: boolean | null) => {
    if (value === null) {
      return "-";
    }
    return value ? "Ja" : "Nein";
  };

  return (
    <Box sx={calendlyAssessmentStyle.assessmentContainer}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          Gespräch stattgefunden: {boolToText(assessment.processed)}
        </Grid>
        <Grid item xs={12} md={6}>
          {assessment.notProcessedReason && (
            <>
              Warum nicht:{" "}
              {translate("assessment.short.notProcessedReason.values." + assessment.notProcessedReason, "-")}
            </>
          )}
        </Grid>
        {activity.type === "calendly_event" && (
          <>
            <Grid item xs={12} md={6}>
              Weitere Mandatstätigkeiten notwendig: {boolToText(assessment.followUp)}
            </Grid>
            <Grid item xs={12} md={6}>
              {assessment.followUpAction && (
                <>
                  Weitere Tätigkeit:{" "}
                  {translate("assessment.short.followUpAction.values." + assessment.followUpAction, "-")}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              Übernahme des Mandats: {boolToText(assessment.acceptsMandateTransferal)}
            </Grid>
            <Grid item xs={12}>
              Zufriedenheit Mandant:
              <Rating size={"small"} value={assessment.customerSatisfaction} readOnly={true} />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {assessment.note && (
            <>
              Hinweise:
              <Box
                sx={calendlyAssessmentStyle.noteContainer}
                dangerouslySetInnerHTML={{ __html: nl2br(assessment.note) }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
