import * as stagesBasicCase from "../../../Stages/StagesCase";
import basicInsuranceFields from "../modules/basicInsuranceFields";

export const stageFormDefinition = (product) => {
  const fields = [
    {
      type: "ValidatorSelect",
      path: `${product.productClassName}.client.gender`,
      isDisabled: ({ product }) => product.stage === stagesBasicCase.STAGE_COMPLETED,
      options: () => [{ value: "male" }, { value: "female" },{value:"diverse"}],
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.client.givenName`,
      isDisabled: ({ product }) => product.stage === stagesBasicCase.STAGE_COMPLETED,
    },
    {
      type: "ValidatorTextField",
      path: `${product.productClassName}.client.familyName`,
      isDisabled: ({ product }) => product.stage === stagesBasicCase.STAGE_COMPLETED,
    },
    {
      type: "ExpandableTextField",
      path: `${product.productClassName}.personalTextMessage`,
      isDisabled: () => true,
      isHidden: ({ product }) => product.stage < stagesBasicCase.STAGE_DATA_COMPLETED,
      additionalProps: {
        multiline: true,
      },
    },
    ...basicInsuranceFields({
      path: `${product.productClassName}.insurance`,
      product: product,
      isHidden: ({ product }) => product.stage < stagesBasicCase.STAGE_DATA_COMPLETED,
    }),
  ];
  return {
    [stagesBasicCase.STAGE_CREATED]: fields,
    [stagesBasicCase.STAGE_DATA_COMPLETED]: fields,
    [stagesBasicCase.STAGE_CHECKED]: fields,
    [stagesBasicCase.STAGE_IN_PROGRESS]: fields,
    [stagesBasicCase.STAGE_COMPLETED]: fields,
  };
};
