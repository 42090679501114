
import CreateCase from "../../CreateCase/CreateCase";
import { Grid } from "@mui/material";
import NewCases from "../../CreateCase/NewCases";
import PageContentContainer from "../../Container/PageContentContainer";

const CreateCasePage = () => {
  return (
    <PageContentContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <NewCases />
        </Grid>
        <Grid item xs={12} md={9}>
          <CreateCase />
        </Grid>
      </Grid>
    </PageContentContainer>
  );
};

export default CreateCasePage;
