
import PageContentContainer from "../../../Container/PageContentContainer";
import ExternalsActivitiesContainer from "../../../Activities/ActivityTable/ExternalsActivitiesContainer";

const ExternalsActivitiesPage = () => {
  return (
    <PageContentContainer>
      <ExternalsActivitiesContainer />
    </PageContentContainer>
  );
};

export default ExternalsActivitiesPage;
