export const caseHistoryPageStyle = {
  historyContainer: {
    maxWidth: "875px",
    margin: "0 auto",
  },
  moreButton: {
    marginTop: "1rem",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "200px",
    marginBottom: "200px",
  },
  faded: {
    textAlign: "center",
    color: "#dcdcdc",
    fontSize: "1.25rem",
    margin: "2rem",
  },
};
