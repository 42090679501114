import * as stagesSettlement from "../../Stages/StagesSettlement";
import { AbstractCase } from "../../../types/AbstractCase";
import { translate } from "../../Translations/translatorService";
import moment from "moment/moment";
import { validateFields } from "../statusChangeValidators";
import { settlementRequiredFields } from "./settlementRequiredFields";

export const settlementValidators = {
  [stagesSettlement.STAGE_INCOMPLETE]: (settlement: AbstractCase) => {
    return validateFields(settlementRequiredFields[stagesSettlement.STAGE_INCOMPLETE](settlement), settlement);
  },
  [stagesSettlement.STAGE_READY]: (settlement: AbstractCase) => {
    return validateFields(settlementRequiredFields[stagesSettlement.STAGE_READY](settlement), settlement);
  },
  [stagesSettlement.STAGE_CHECKED]: (settlement: AbstractCase) => {
    let errorMessages = validateFields(
      settlementRequiredFields[stagesSettlement.STAGE_CHECKED](settlement),
      settlement
    );

    const hasCompleteNormalAddress =
      settlement.employerOrganization.address.streetAddress &&
      settlement.employerOrganization.address.postalCode &&
      settlement.employerOrganization.address.addressLocality;

    const hasCompletePostOfficeBoxAddress =
      settlement.employerOrganization.address.postOfficeBoxNumber &&
      settlement.employerOrganization.address.postOfficeBoxPostalCode &&
      settlement.employerOrganization.address.postOfficeBoxAddressLocality;

    if (!hasCompleteNormalAddress && !hasCompletePostOfficeBoxAddress) {
      errorMessages.push(
        "Entweder die Standardadresse oder die Postfachadresse des Arbeitgebers müssen vollständig befüllt sein"
      );
    }

    if (settlement.backofficeCase!.accountManager === null) {
      errorMessages.push("Fall hat keinen Zuständigen - bitte zuordnen!");
    }

    if (settlement.contract === null) {
      errorMessages.push('Das Dokument "Arbeitsvertrag" fehlt!');
    }

    if (settlement.contractTermination === null) {
      errorMessages.push('Das Dokument "Kündigung" fehlt!');
    }

    return errorMessages;
  },
  [stagesSettlement.STAGE_SUBMITTED]: (settlement: AbstractCase) => {
    return validateFields(settlementRequiredFields[stagesSettlement.STAGE_SUBMITTED](settlement), settlement);
  },
  [stagesSettlement.STAGE_PAID]: (settlement: AbstractCase) => {
    return validateFields(settlementRequiredFields[stagesSettlement.STAGE_PAID](settlement), settlement);
  },
  [stagesSettlement.STAGE_DATE_SET_SETTLEMENT]: (settlement: AbstractCase) => {
    return validateFields(settlementRequiredFields[stagesSettlement.STAGE_DATE_SET_SETTLEMENT](settlement), settlement);
  },
  [stagesSettlement.STAGE_DATE_SET_TRIAL]: (settlement: AbstractCase) => {
    return validateFields(settlementRequiredFields[stagesSettlement.STAGE_DATE_SET_TRIAL](settlement), settlement);
  },
  [stagesSettlement.STAGE_DECISION_MADE]: (settlement: AbstractCase) => {
    let errorMessages = validateFields(
      settlementRequiredFields[stagesSettlement.STAGE_DECISION_MADE](settlement),
      settlement
    );

    if (settlement.settlementReachedDate && settlement.finalJudgement) {
      errorMessages.push(
        `${translate("settlement.settlementReachedDate.label")} und ${translate(
          "settlement.finalJudgement.label"
        )} dürfen nicht beide befüllt sein!`
      );
    }
    if (!settlement.settlementReachedDate && !settlement.finalJudgement) {
      errorMessages.push(
        `${translate("settlement.settlementReachedDate.label")} ODER ${translate(
          "settlement.finalJudgement.label"
        )} müssen befüllt sein!`
      );
    }

    return errorMessages;
  },
  [stagesSettlement.STAGE_COMPLETED]: (settlement: AbstractCase) => {
    let errorMessages = validateFields(
      settlementRequiredFields[stagesSettlement.STAGE_COMPLETED](settlement),
      settlement
    );
    if (
      !(
        settlement.finalJudgement ||
        (settlement.settlementReachedDate &&
          settlement.settlementRevocationDate &&
          moment(settlement.settlementRevocationDate).isBefore(moment()))
      )
    ) {
      errorMessages.push(`Die Widerrufsfrist des Urteils muss abgelaufen sein!`);
    }

    return errorMessages;
  },
};
