import React from "react";
import moment from "moment/moment";
import { Button, Stack } from "@mui/material";

type DateChangeButtonsToolbarProps = {
  onChange: Function;
};

export default function DateChangeButtonsToolbar({ onChange }: DateChangeButtonsToolbarProps) {
  const handleSkipWeek = (weeksToSkip: number) => {
    onChange(moment().add(weeksToSkip, "weeks"));
  };

  return (
    <Stack direction={"row"} sx={{ marginTop: "1rem", gridColumn: 2 }}>
      <Button onClick={() => handleSkipWeek(2)}>In 2 Wochen</Button>
      <Button onClick={() => handleSkipWeek(4)}>In 4 Wochen</Button>
    </Stack>
  );
}
