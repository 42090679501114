import React, { useEffect, useState } from "react";
import { Box, ClickAwayListener, Divider, Popper } from "@mui/material";
import { FixedSizeList } from "react-window";
import { HitProperties } from "../Case/CaseEntityPicker/Hit/hitType";
import { Hit } from "@algolia/client-search";
import { Placement, VirtualElement } from "@popperjs/core";
import searchBarStyles from "./searchBarStyles";
import _ from "lodash";
import { LbResultListConfig } from "../DatasetEntityPicker/datasetTypes";
import { OnHitClickCallback } from "../../types/Algolia/OnHitClickCallback";

interface SearchResultListProps {
  HitComponent: (props: HitProperties) => JSX.Element | null;
  onHitClick?: OnHitClickCallback;
  searchHits: Array<Hit<any>>;
  resetSearchHits: () => void;
  anchorEl: VirtualElement | HTMLElement | undefined;
  placement?: Placement;
  resultListConfig: LbResultListConfig;
}

export default function SearchResultList({
  HitComponent,
  searchHits,
  resetSearchHits,
  onHitClick,
  resultListConfig,
  anchorEl,
  placement = "bottom-start",
}: SearchResultListProps) {
  const [open, setOpen] = useState<boolean>(searchHits.length > 0);

  useEffect(() => {
    setOpen(searchHits.length > 0);
  }, [searchHits]);

  const listItem = ({ index, style }: { index: number; style: any }) => (
    <div style={style}>
      <HitComponent
        hit={searchHits[index]}
        resetHits={resetSearchHits}
        onHitClick={onHitClick}
        itemSize={resultListConfig.itemSize}
      />
      {index !== searchHits.length - 1 && <Divider variant={"middle"} />}
    </div>
  );

  return (
    <Popper style={{ zIndex: "1300" }} open={open} anchorEl={anchorEl} placement={placement} disablePortal={true}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box sx={searchBarStyles.listContent}>
          <FixedSizeList
            width={resultListConfig.width || _.get(anchorEl, "offsetWidth", 500)}
            height={resultListConfig.height || resultListConfig.itemSize * 3}
            itemSize={resultListConfig.itemSize}
            itemCount={searchHits.length}
          >
            {listItem}
          </FixedSizeList>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
}
