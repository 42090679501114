import React, { useEffect } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import _ from "lodash";
import { editorStateToHTML } from "../../services/Editor/editorFunctions";
import { loadSessionStorageState } from "../../services/browserStorageService";
import { buildAddress } from "../Case/CasePdfGenerator/casePdfGeneratorService";
import PdfGeneratorFormElements from "../Case/CasePdfGenerator/PdfGeneratorFormElements";
import PdfGeneratorPreview from "../Case/CasePdfGenerator/PdfGeneratorPreview";
import casePaidTaskStyle from "./casePaidTaskPdfGeneratorStyle";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { getMediaObjectFilters } from "../../services/mediaObjectService";
import { useFormContext } from "react-hook-form-mui";
import { PaidTask } from "../../types/PaidTask";
import { AbstractCase } from "../../types/AbstractCase";
import { Box } from "@mui/material";

type CasePaidTaskPdfGeneratorProps = {
  product: AbstractCase;
  paidTask: PaidTask;
  initialValues: Object;
};

const CasePaidTaskPdfGenerator = ({ product, paidTask, initialValues }: CasePaidTaskPdfGeneratorProps) => {
  const currentUser = useCurrentUser();
  const formContext = useFormContext();

  const mediaObjectsFilter = getMediaObjectFilters(product, currentUser);

  const { data: mediaObjects } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  const getParams = () => {
    const values = formContext.getValues();

    return _.merge({}, values, {
      content: editorStateToHTML(values.content),
      address: buildAddress(values, product),
      externalReference: !_.isEmpty(values.externalReference) ? values.externalReference : null,
      senderMedium: values.senderMedium === "none" ? null : values.senderMedium,
      externalLawyer: paidTask.assignee,
    });
  };

  useEffect(() => {
    const sessionStorageValues = loadSessionStorageState("customPdf");
    if (sessionStorageValues) {
      formContext.reset(
        _.merge(initialValues, {
          recipientType: sessionStorageValues.recipientType,
          address: {
            name: sessionStorageValues.address.name,
            department: sessionStorageValues.address.department,
            subDepartment: sessionStorageValues.address.subDepartment,
            streetAddress: sessionStorageValues.address.streetAddress,
            postalCode: sessionStorageValues.address.postalCode,
            addressLocality: sessionStorageValues.address.addressLocality,
          },
          senderMedium: sessionStorageValues.senderMedium,
          externalReference: sessionStorageValues.externalReference,
          externalReferenceSelect: sessionStorageValues.externalReferenceSelect,
          filename: sessionStorageValues.filename,
          content: EditorState.createWithContent(convertFromRaw(sessionStorageValues.content)),
          showInGallery: sessionStorageValues.showInGallery,
          sendEmailToCustomer: sessionStorageValues.sendEmailToCustomer,
          selectedMediaObjects: sessionStorageValues.selectedMediaObjects,
        })
      );
    }
  }, [initialValues]);

  return (
    <Box sx={casePaidTaskStyle.pdfGenerator}>
      <PdfGeneratorFormElements product={product} mediaObjects={mediaObjects?.["hydra:member"] || ""} minimalView />
      <PdfGeneratorPreview
        product={product}
        values={formContext.getValues()}
        getParams={getParams}
        mediaObjects={mediaObjects || []}
      />
    </Box>
  );
};

export default CasePaidTaskPdfGenerator;
