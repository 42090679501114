import React from "react";
import { Grid, Paper } from "@mui/material";
import useStyles from "./generalActionsStyle";
import ActionButton from "../Actions/ActionButton";
import _ from "lodash";
import { getGeneralActionsByUser } from "../../services/navigationActionsService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const GeneralActions = () => {
  const classes = useStyles({});
  const currentUser = useCurrentUser();
  const actions = getGeneralActionsByUser(currentUser);

  return (
    <Grid component={Paper} container alignItems={"center"} direction={"column"} className={classes.actionsContainer}>
      {_.map(actions, (action, index) => (
        <Grid item key={index}>
          <ActionButton {...action} href={undefined} useCondensedView={false} />
        </Grid>
      ))}
    </Grid>
  );
};

export default GeneralActions;
