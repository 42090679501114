import React from "react";
import { Grid } from "@mui/material";
import TimeFilter from "../ActivityOverviewHeader/TimeFilter/TimeFilter";
import DateRangeFilter from "../ActivityOverviewHeader/DateRangeFilter/DateRangeFilter";
import activityStyle from "./activityStyle";
import _ from "lodash";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import { Activity } from "../../../types/Activity";
import Typography from "@mui/material/Typography";

type ActivityFilterProps = {
  isLoading: boolean;
  userActivitiesToday: Activity[];
  timespanFilter: string;
  setTimespanFilter: (value: string) => void;
};

const ActivityFilter = ({ isLoading, userActivitiesToday, timespanFilter, setTimespanFilter }: ActivityFilterProps) => {
  const userActivitiesOverdue = _.filter(
    userActivitiesToday,
    (activity) => activity.activityStatus === ACTIVITY_OVERDUE
  );
  return (
    <Grid container sx={activityStyle.filterContainer} spacing={2} alignItems={"center"}>
      <Grid item md={3} xs={12}>
        {!isLoading && (
          <>
            <Typography sx={activityStyle.activityCount}>Heute noch: {userActivitiesToday.length}</Typography>
            <Typography sx={{ ...activityStyle.activityCount, ...activityStyle.activitiesOverdue }}>
              Davon überfällig: {userActivitiesOverdue.length}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item md={7} xs={12}>
        <TimeFilter timespanFilter={timespanFilter} setTimespanFilter={setTimespanFilter} isCompletedView={true} />
      </Grid>
      <Grid item md={2} xs={12}>
        <DateRangeFilter timespanFilter={timespanFilter} setTimespanFilter={setTimespanFilter} />
      </Grid>
    </Grid>
  );
};

export default ActivityFilter;
