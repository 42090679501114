import React from "react";
import { Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import settlementCalculator from "../../../services/settlementCalculator";
import { AbstractCase } from "../../../types/AbstractCase";

const useStyles = makeStyles(() => ({
  paper: {
    margin: "2rem 0 2rem 0",
    padding: "2rem",
    fontSize: "1rem",
  },
  headline: {
    marginBottom: "1rem",
    textAlign: "center",
    fontSize: "1.25rem",
  },
  realCostBox: {
    marginTop: "3rem",
  },
}));

type CostBoxSettlementProps = {
  settlement: AbstractCase;
};

const CostBoxSettlement = (props: CostBoxSettlementProps) => {
  const classes = useStyles(props);
  const { settlement } = props;

  const oldCosts = settlementCalculator(settlement, settlement.valueInDispute);

  return (
    <Paper className={classes.paper}>
      <div className={classes.headline}>{"Berechnete Kosten"}</div>
      <p>
        <strong>Erfolgsprovision</strong>
        <br />
        Geschätzte Abfindung: {oldCosts.settlementValue.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        {settlement.paymentType === "nwnf" && settlement.nWnFAmount && (
          <>
            NWNF-Gebühr (berechnet):{" "}
            {settlement.nWnFAmount.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
          </>
        )}
      </p>
      <p>
        <strong>Nach RVG</strong>
        <br />
        Streitwert in Klage: {settlement.valueInDispute.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Verfahrensgebühr: {oldCosts.processCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Terminsgebühr: {oldCosts.hearingCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        TG&VG inkl. Auslage: {oldCosts.fullCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Einigungsgebühr: {oldCosts.settlementCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </p>
    </Paper>
  );
};


export default CostBoxSettlement;
