export const paidTaskTableStyle = {
  headline: {
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  activityCount: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  filterContainer: {
    padding: "1rem",
  },
  tableHeadline: {
    textAlign: "center",
    padding: "1.5rem",
  },
  requestedPaidTasksPaper: {
    border: "1px solid #db1919",
    marginBottom: "3rem",
  },
  requestedPaidTasksHeadline: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "400px",
  },
  activityTable: {
    minWidth: "650px",
  },
  spinner: {
    display: "block",
    margin: "0 auto",
  },
  note: {
    paddingRight: "10px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxHeight: "48px",
    maxWidth: "100px",
  },
  subject: {
    maxHeight: "48px",
    maxWidth: "170px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  link: {
    color: "primary.main",
    textDecoration: "none",
  },
  done: {
    textDecoration: "line-through",
  },
  overdue: {
    "&> td": {
      color: "#db1919",
    },
  },
  mandatoryCell: {
    minWidth: "130px",
  },
  descriptionCell: {
    minWidth: "170px",
  },
  noteCell: {
    minWidth: "170px",
  },
  activeSorting: {
    color: "#137F7B",
  },
  sortableHeadline: {
    "&:hover": {
      cursor: "pointer",
    },
  },
};

