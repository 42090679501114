import React from "react";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import Paper from "@mui/material/Paper";
import { dutyNotificationStyles } from "../Notifications/dutyNotificationStyles";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";

export type NewMessageNotificationSnackbarProps = {
  notification: string;
};

export const NewMessageNotificationSnackbar = React.forwardRef<
  HTMLDivElement,
  NewMessageNotificationSnackbarProps & CustomContentProps
>(({ notification }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const closeNotification = () => {
    closeSnackbar();
  };

  return (
    <SnackbarContent ref={ref}>
      <Paper sx={dutyNotificationStyles.notificationContainer}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={2} />
          <Grid item xs={8} sx={dutyNotificationStyles.headline}>
            Neue Nachricht!
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={dutyNotificationStyles.icon} onClick={closeNotification} size="large">
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <strong>Nachricht: </strong>
            {notification}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button onClick={closeNotification}>Ok</Button>
          </Grid>
        </Grid>
      </Paper>
    </SnackbarContent>
  );
});
