import { infoBoxStyles } from "./infoBoxStyles";
import { Box } from "@mui/material";
import { ReactNode } from "react";

export type InfoBoxProps = {
  children: ReactNode;
};

export default function InfoBox({ children }: InfoBoxProps) {
  return <Box sx={infoBoxStyles.infoBox}>{children}</Box>;
}
