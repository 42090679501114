import _ from "lodash";

export type LegalbirdError = Error & {
  status?: number;
  errors?: string[];
  error?: string;
};

export const isLegalbirdError = (obj: any): obj is LegalbirdError => {
  return _.isError(obj);
};
