import React from "react";
import { IMaskInput } from "react-imask";

// TODO combine this with ThousandsNumberInputFloat
// these are only separate because its hard or impossible to pass props from NumberFieldConfigured to the inputComponent
export const ThousandsNumberInputFloatScaleThree = React.forwardRef(function ThousandsNumberInput(props, ref) {
  const { onChange, name, numberType, ...other }: any = props;
  return (
    <IMaskInput
      {...other}
      mask={Number}
      scale={3}
      radix={","}
      unmask
      thousandsSeparator={"."}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: name, value } })}
      mapToRadix={[]}
    />
  );
});
