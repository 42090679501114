import React from "react";
import { progressionButtonStyle } from "./progressionButtonStyle";
import ButtonLoading from "../../Button/ButtonLoading";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import Button from "@mui/material/Button";
import { PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import { PaidTask } from "../../../types/PaidTask";

type InternalPaidTaskDefinitionButtonProps = {
  paidTask: PaidTask;
  isLoading: boolean;
  touchedValues: Record<string, any>;
  openDeleteModal: Function;
};

const InternalPaidTaskDefinitionButton = ({
  paidTask,
  isLoading,
  touchedValues,
  openDeleteModal,
}: InternalPaidTaskDefinitionButtonProps) => {
  switch (true) {
    case _.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status):
      return (
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"}>
              <Grid item>
                <ButtonLoading
                  fullWidth={false}
                  sx={progressionButtonStyle.button}
                  variant={"contained"}
                  type={"submit"}
                  isLoading={isLoading}
                  disabled={_.keys(touchedValues).length === 0}
                >
                  Speichern
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button fullWidth={false} sx={progressionButtonStyle.button} onClick={() => openDeleteModal()}>
              Löschen
            </Button>
          </Grid>
        </Grid>
      );
    case paidTask.status === PAID_TASK_STATUS_ASSIGNED:
      return (
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Button
              fullWidth={false}
              variant={"contained"}
              sx={progressionButtonStyle.button}
              onClick={() => openDeleteModal()}
            >
              Löschen
            </Button>
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};

export default InternalPaidTaskDefinitionButton;
