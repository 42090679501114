/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Stack } from "@mui/material";
import React, { ReactNode } from "react";

type NumberedParagraphProps = {
  paragraphNumber: string;
  paragraph: ReactNode;
};

export default function NumberedParagraph({ paragraphNumber, paragraph }: NumberedParagraphProps) {
  return (
    <p>
      <Stack direction={"row"} spacing={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
        <div>{paragraphNumber}</div>
        {paragraph}
      </Stack>
    </p>
  );
}
