import React, { ComponentType, useEffect, useState } from "react";

const withScroll = (ComposedComponent: ComponentType<{ scrollPosition: number }>) => {
  return (props: Record<string, any>) => {
    const [scrollPosition, setScrollPosition] = useState<number>(
      window.pageYOffset || document.documentElement.scrollTop
    );

    useEffect(() => {
      const handleScroll = () => {
        const newScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        if (newScrollPosition !== scrollPosition) {
          setScrollPosition(newScrollPosition);
        }
      };

      const intervalID = setInterval(() => {
        handleScroll();
      }, 250);

      return () => {
        clearInterval(intervalID);
      };
    }, [scrollPosition]);

    return <ComposedComponent {...props} scrollPosition={scrollPosition} />;
  };
};

export default withScroll;
