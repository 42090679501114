import React from "react";

type ValidatorFormProps = {
  children: React.ReactNode;
  submitOnEnter?: boolean;
} & React.FormHTMLAttributes<HTMLFormElement>;

const ValidatorForm = ({ children, submitOnEnter = true, ...rest }: ValidatorFormProps) => {
  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (!submitOnEnter && e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <form autoComplete={"off"} {...rest} onKeyDown={(e) => checkKeyDown(e)}>
      {children}
    </form>
  );
};

export default ValidatorForm;
