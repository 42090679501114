import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ValidatorTextField from "../Validator/ValidatorTextField";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import moment from "moment";
import ContentBox from "../ContentBox/ContentBox";

interface TechnicalError {
  "@id": string;
  id: number;
  errorMessage: string;
  isActive: boolean;
  showInternal: boolean;
  showExternal: boolean;
}

interface Release {
  "@id": string;
  id: number;
  travisId: string;
  startedAt: string;
  isRunning: boolean;
}

export default function TechnicalErrorForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showInternal, setShowInternal] = useState<boolean>(true);
  const [showExternal, setShowExternal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [activeReleases, setActiveReleases] = useState<Release[]>([]);
  const [technicalError, setTechnicalError] = useState<TechnicalError | null>(null);

  useEffect(() => {
    ApiClient.get("/technical_errors").then((technicalErrorCollection) => {
      const technicalErrorResponse = technicalErrorCollection["hydra:member"][0];
      setTechnicalError(technicalErrorResponse);
      setErrorMessage(technicalErrorResponse.errorMessage);
      setShowInternal(technicalErrorResponse.showInternal);
      setShowExternal(technicalErrorResponse.showExternal);
    });

    ApiClient.get("/releases?isRunning=1").then((releaseCollection) =>
      setActiveReleases(releaseCollection["hydra:member"])
    );
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!technicalError) {
      return;
    }

    setIsLoading(true);
    ApiClient.put(technicalError["@id"], {
      body: JSON.stringify({
        isActive: !technicalError.isActive,
        errorMessage: !technicalError.isActive ? errorMessage : "",
        showInternal,
        showExternal,
      }),
    }).then((technicalErrorResponse) => {
      setTechnicalError(technicalErrorResponse);
      setErrorMessage(technicalErrorResponse.errorMessage);
      setIsLoading(false);
    });
  };

  const handleRelease = (release: Release) => {
    ApiClient.put(release["@id"], {
      body: JSON.stringify({
        isRunning: false,
      }),
    }).then(() => {
      ApiClient.get("/releases?isRunning=1").then((releaseCollection) =>
        setActiveReleases(releaseCollection["hydra:member"])
      );
    });
  };

  if (!technicalError) {
    return null;
  }

  return (
    <>
      <ContentBox headline="Störung">
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={"center"} spacing={3}>
            <Grid item xs={12}>
              <ValidatorTextField
                label={"Fehlertext"}
                name={"errorMessage"}
                value={errorMessage}
                onChange={({ target }: any) => {
                  setErrorMessage(target.value);
                }}
                isHighlighted={undefined}
                registerValidators={undefined}
                validators={undefined}
                initialDependentValidationFields={undefined}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonLoading
                isLoading={isLoading}
                disabled={isLoading}
                type={"submit"}
                variant={"contained"}
                fullWidth={true}
              >
                {!technicalError.isActive ? "Störung melden" : "Störung behoben"}
              </ButtonLoading>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showInternal"
                      checked={showInternal}
                      onChange={() => setShowInternal(!showInternal)}
                      value="showInternal"
                      color="primary"
                    />
                  }
                  label="Intern"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showExternal"
                      checked={showExternal}
                      onChange={() => setShowExternal(!showExternal)}
                      value="preventAccounting"
                      color="primary"
                    />
                  }
                  label="Extern"
                />
              </Stack>
            </Grid>
          </Grid>
        </form>
      </ContentBox>
      {activeReleases.length > 0 && (
        <ContentBox headline="Releases">
          <Grid container alignItems={"center"}>
            {_.map(activeReleases, (activeRelease, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={4}>
                    {activeRelease.travisId}
                  </Grid>
                  <Grid item xs={4}>
                    {moment(activeRelease.startedAt).format("DD.MM.YYYY HH:mm")}h
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={() => handleRelease(activeRelease)}>Release abgeschlossen</Button>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </ContentBox>
      )}
    </>
  );
}
