import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Grid, Hidden, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { navigationExtensionEmotionStyle } from "./navigationExtensionAllDataStyle";
import { ContentContainer } from "../Container/PageContentContainer";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import CaseAdditionalClientsTooltipIcon from "../Case/CaseAdditionalClientsTooltipIcon/CaseAdditionalClientsTooltipIcon";

const NavigationExtensionCasePage = ({ product }: any) => {
  const [topOffset, setTopOffset] = useState(69);
  const currentUser = useCurrentUser();

  if (!product) {
    return null;
  }

  return (
    <Hidden lgDown>
      <Paper
        square
        ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
        sx={navigationExtensionEmotionStyle.tabs({ top: topOffset })}
      >
        <ContentContainer size={"large"}>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Stack justifyContent={"center"} sx={{ marginLeft: "2rem" }}>
                <Stack direction={"row"} spacing={1}>
                  <Typography>
                    <strong>{product.backofficeCase.label}</strong>{" "}
                    {!!product.opposingParty && <>./. {product.opposingParty}</>}
                  </Typography>
                  <CaseAdditionalClientsTooltipIcon product={product} fontSize={"small"} />
                </Stack>
                <Typography>Unser Zeichen: {product.reference}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Tabs variant={"fullWidth"} scrollButtons="auto" value={false} centered>
                <Tab component={Link} to={"#activities"} label={"Aktivitäten"} />
                {!currentUser.isExternal && <Tab component={Link} to={"#beaMessages"} label={"beA"} />}
                <Tab component={Link} to={"#documents"} label={"Dokumente"} />
                {!currentUser.isExternal && product.courtAppointments?.length > 0 && (
                  <Tab component={Link} to={"#courtAppointments"} label={"Gerichtstermin"} />
                )}
                <Tab component={Link} to={"#messages"} label={"Nachrichten"} />
                <Tab component={Link} to={"#mails"} label={"E-Mails"} />
              </Tabs>
            </Grid>
          </Grid>
        </ContentContainer>
      </Paper>
    </Hidden>
  );
};

export default NavigationExtensionCasePage;
