import React, { ChangeEvent, SyntheticEvent, useMemo, useRef, useState } from "react";
import { Hit } from "@algolia/client-search";
import { HitProperties } from "../Case/CaseEntityPicker/Hit/hitType";
import algoliasearch from "algoliasearch/lite";
import { ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY } from "../../config/_entrypoint";
import { IconButton, Input } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import searchBarStyles from "./searchBarStyles";
import SearchResultList from "./SearchResultList";
import { LbResultListConfig, LbSearchConfig } from "../DatasetEntityPicker/datasetTypes";
import { OnHitClickCallback } from "../../types/Algolia/OnHitClickCallback";

interface SearchBarProps {
  searchConfig: LbSearchConfig;
  resultListConfig: LbResultListConfig;
  HitComponent: (props: HitProperties) => JSX.Element | null;
  onHitClick?: OnHitClickCallback;
  placeHolder?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
}

export default function SearchBar({
  searchConfig,
  HitComponent,
  onHitClick,
  placeHolder = "SUCHE",
  disabled = false,
  resultListConfig,
  endAdornment,
}: SearchBarProps) {
  const [searchHits, setSearchHits] = useState<Array<Hit<any>>>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const searchBarAnchor = useRef<HTMLInputElement>();

  const searchIndex = useMemo(() => {
    return algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_SEARCH_API_KEY).initIndex(searchConfig.indexName);
  }, [searchConfig.indexName]);

  const resetSearchHits = () => {
    setSearchTerm("");
    setSearchHits([]);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    if (searchValue.length === 0) {
      resetSearchHits();
      return;
    }
    setSearchTerm(searchValue);
    const { hits } = await searchIndex.search(searchValue, searchConfig.requestOptions);
    setSearchHits(searchConfig.postSearchFilter ? searchConfig.postSearchFilter(hits) : hits);
  };

  return (
    <>
      <Input
        onChange={handleChange}
        sx={searchBarStyles.searchInput}
        placeholder={placeHolder}
        value={searchTerm}
        disabled={disabled}
        disableUnderline={true}
        ref={searchBarAnchor}
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          endAdornment || (
            <InputAdornment position="end">
              <IconButton onClick={() => resetSearchHits()} disabled={disabled} size="large">
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </InputAdornment>
          )
        }
      />
      <SearchResultList
        HitComponent={HitComponent}
        onHitClick={onHitClick}
        searchHits={searchHits}
        resetSearchHits={resetSearchHits}
        resultListConfig={resultListConfig}
        anchorEl={searchBarAnchor.current}
      />
    </>
  );
}
