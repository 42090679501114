import { Theme } from "@mui/material";

const navigationBarStyle = (theme: Theme) => ({
  loginIcon: {
    height: 40,
    width: "auto",
  },
  navBarIcon: {
    height: 40,
    width: "auto",
    margin: 12,
  },
  icon: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "60%",
    minWidth: "40px",
    minHeight: "40px",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      backgroundSize: "80%",
    },
  },
  navigationButton: {
    fontWeight: 400,
  },
  highlightedNavButton: {
    fontWeight: 700,
  },
  subNaviContainer: {
    maxWidth: 1400,
    margin: "0 auto",
    padding: "1rem",
  },
  toolbar: {
    paddingLeft: 4,
    paddingRight: 8,
  },
});
export default navigationBarStyle;
