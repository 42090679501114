import React, { useEffect, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import ApiClient from "../../services/ApiClient";
import useForm from "../../hooks/useForm";
import { Box, Grid } from "@mui/material";
import SubmitButton from "../Button/SubmitButton";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import ValidatorForm from "../Validator/ValidatorForm";
import { scrollToAnchor } from "../../theme/commonStyles";
import WorkingHoursFormElements from "../HumanResourceManagement/WorkingHoursFormElements";
import { UseForm } from "../../types/UseForm";
import HumanResource from "../../types/HumanResource";

type UserWorkingHoursManagementProps = {
  userId: number;
};

export default function UserWorkingHoursManagement({ userId }: UserWorkingHoursManagementProps) {
  const [humanResource, setHumanResource] = useState<HumanResource | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { internals } = useBackofficeUser();

  const initialValues = {
    workingHours: {},
  };

  const onSubmit = async ({ values }: UseForm["values"]) => {
    setIsLoading(true);
    if (humanResource) {
      await ApiClient.put("/human_resources/" + humanResource.id, {
        body: JSON.stringify({
          workingHours: values.workingHours,
        }),
      });
      await fetchHumanResource();
    }
    setIsLoading(false);
  };

  const { values, handleChange, handleSubmit, updateAllValues, touchedValues, errors, handleBlur } = useForm({
    initialValues,
    onSubmit,
  });

  const fetchHumanResource = async () => {
    setIsLoading(true);
    const fetchedHumanResource = (await ApiClient.get("/human_resources?backofficeUser=" + userId))["hydra:member"][0];
    setHumanResource(fetchedHumanResource);
    if (fetchedHumanResource) {
      updateAllValues({
        workingHours: fetchedHumanResource.workingHours,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchHumanResource();
  }, [userId]);

  return (
    <>
      <Box sx={scrollToAnchor} id={"workingHours"} />
      <ContentBox headline={"Arbeitszeit"}>
        {humanResource && (
          <ValidatorForm onSubmit={handleSubmit}>
            <WorkingHoursFormElements values={values} onChange={handleChange} onBlur={handleBlur} />
            <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
              {/*second disabled part (_.find) so that the initial user cant save his humanresource entity if hes not internal*/}
              <SubmitButton
                error={_.keys(errors).length > 0}
                isLoading={isLoading}
                disabled={
                  _.keys(touchedValues).length === 0 || !_.find(internals, (internalUser) => internalUser.id === userId)
                }
                variant={"contained"}
                type={"submit"}
              >
                Speichern
              </SubmitButton>
            </Grid>
          </ValidatorForm>
        )}
      </ContentBox>
    </>
  );
}
