import customerTranslations from "./customerTranslations";
import documentTranslations from "./documentTranslations";

const patientDecreeTranslations = {
  label: "Patientenverfügung",
  customer: customerTranslations,
  ...documentTranslations,
  personalTextMessage: {
    label: "Persönlicher Text",
  },
  petitionerSelection: {
    label: "Patientenverfügung für?",
    values: {
      self: "Für mich",
      other: "Für jemand anderes",
    },
  },
  doctorSignature: {
    label: "Unterschrift von Ihrem Hausarzt ergänzen?",
  },
  personalText: {
    label: "Um persönlichen Text ergänzen?",
  },
  reason: {
    label: "Grund für das Erstellen",
    values: {
      general: "Allgemeine Vorsorgemaßnahme",
      medical: "Medizinische Behandlung geplant",
      other: "Andere Gründe",
    },
  },
  petitionerSignature: {
    label: "Direkt unterzeichnen?",
  },
  lifeSupport: {
    label: "Immer lebenserhaltende Maßnahmen?",
    values: {
      always: "Ja",
      limited: "Eingeschränkt",
    },
  },
  hasRepresentatives: {
    label: "Vertrauenspersonen angeben?",
  },
  representatives: [
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 1",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 1",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 1",
        },
        familyName: {
          label: "Nachname Vertrauensperson 1",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 1",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 1",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 1",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 1",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 2",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 2",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 2",
        },
        familyName: {
          label: "Nachname Vertrauensperson 2",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 2",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 2",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 2",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 2",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 3",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 3",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 3",
        },
        familyName: {
          label: "Nachname Vertrauensperson 3",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 3",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 3",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 3",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 3",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 4",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 4",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 4",
        },
        familyName: {
          label: "Nachname Vertrauensperson 4",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 4",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 4",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 4",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 4",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 5",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 5",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 5",
        },
        familyName: {
          label: "Nachname Vertrauensperson 5",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 5",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 5",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 5",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 5",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 6",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 6",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 6",
        },
        familyName: {
          label: "Nachname Vertrauensperson 6",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 6",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 6",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 6",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 6",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 7",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 7",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 7",
        },
        familyName: {
          label: "Nachname Vertrauensperson 7",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 7",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 7",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 7",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 7",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 8",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 8",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 8",
        },
        familyName: {
          label: "Nachname Vertrauensperson 8",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 8",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 8",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 8",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 8",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 9",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 9",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 9",
        },
        familyName: {
          label: "Nachname Vertrauensperson 9",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 9",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 9",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 9",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 9",
          },
        },
      },
    },
    {
      telephone: {
        label: "Telefonnummer Vertrauensperson 10",
      },
      person: {
        gender: {
          label: "Anrede Vertrauensperson 10",
          values: {
            male: "Herr",
            female: "Frau",
          },
        },
        givenName: {
          label: "Vorname Vertrauensperson 10",
        },
        familyName: {
          label: "Nachname Vertrauensperson 10",
        },
        birthDate: {
          label: "Geburtsdatum Vertrauensperson 10",
        },
        residenceAddress: {
          streetAddress: {
            label: "Straße und Hausnummer Vertrauensperson 10",
          },
          postalCode: {
            label: "PLZ Vertrauensperson 10",
          },
          addressLocality: {
            label: "Ort Vertrauensperson 10",
          },
        },
      },
    },
  ],
  health: {
    label: "Gesundheitszustand",
    values: {
      healthy: "Ich bin gesund",
      chronic: "chronische Erkrankung",
      incurable: "unheilbare Krankheit",
    },
  },
  care: {
    label: "Auf Pflege angewiesen?",
    values: {
      care_with_degree: "Ja mit Pflegegrad",
      care_no_degree: "Ja ohne Pflegegrad",
      none: "Nein",
    },
  },
  careDegree: {
    label: "Pflegegrad",
    values: {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
    },
  },
  place: {
    label: "Wo möchten Sie sterben?",
    values: {
      home: "Zuhause",
      hospital: "Im Krankenhaus",
      hospice: "Im Hospiz",
      relatives: "Entscheidung der Angehörigen",
    },
  },
  reanimationType: {
    label: "Nicht wiederbeleben wenn?",
    values: {
      long: "10 Minuten Herzstillstand",
      unattended: "Unbekannte Dauer",
      always: "Immer Wiederbeleben",
    },
  },
  noSupportDiagnosis: {
    label: "Welche Diagnosen?",
    values: {
      coma_diagnosis: "Wachkoma",
      lockedin_diagnosis: "Locked-In-Syndrom",
      tumor_diagnosis: "Bösartiger Tumor",
      braindamage_diagnosis: "Bleibender Hirnschaden",
      none_diagnosis: "Immer weiterleben",
    },
  },
  noSupportSituation: {
    label: "Welche Situationen?",
    values: {
      death_situation: "In unmittelbarer Todesnähe",
      incurable_situation: "Bei unheilbarer, tödlicher Krankheit",
      braindamage_situation: "Bei schweren Hirnschäden",
      dementia_situation: "Bei fortgeschrittener Demenz",
      none_situation: "Keine der genannten Situationen.",
    },
  },
  supportTypes: {
    label: "Nicht durchzuführende Maßnahmen?",
    values: {
      artificial_feeding: "Künstliche Ernährung",
      respiration: "Künstliche Beatmung",
      dyalize: "Dialyse",
      transfusion: "Bluttransfusion",
      none: "Keine der genannten",
    },
  },
  qualitySee: {
    label: "Einschränkung Ihrer Sehfähigkeit OK?",
    values: {
      full: "Vollständiger Verlust",
      medium: "Personen Erkennen",
      low: "Nie",
      unsure: "Ich weiß es nicht",
    },
  },
  qualityHear: {
    label: "Einschränkung Ihres Hörvermögens OK?",
    values: {
      full: "Vollständiger Verlust",
      medium: "Umgebungsgeräusche wahrnehmen",
      low: "Nie",
      unsure: "Ich weiß es nicht",
    },
  },
  qualitySpeak: {
    label: "Einschränkung Ihres Sprachvermögens OK?",
    values: {
      full: "Vollständiger Verlust",
      medium: "Deutliche Probleme",
      low: "Nie",
      unsure: "Ich weiß es nicht",
    },
  },
  qualityDeafAndBlind: {
    label: "Bei gleichzeitiger Taub- und Blindheit leben?",
    values: {
      full: "Vollständiger Verlust",
      low: "Nie",
      unsure: "Ich weiß es nicht",
    },
  },
  qualityMobility: {
    label: "Einschränkung Beweglichkeit OK?",
    values: {
      full: "Rollstuhl",
      medium: "Krücken",
      low: "Nie",
      unsure: "Ich weiß es nicht",
    },
  },
  qualityCare: {
    label: "Pflege OK bis?",
    values: {
      full: "auch ständige Pflegebedürftigkeit",
      medium: "Bettlägerigkeit",
      low: "Nie",
      unsure: "Ich weiß es nicht",
    },
  },
  organDonatePriority: {
    label: "Was wenn ausgeschlossene ärztliche Maßnahmen?",
    values: {
      donate: "Organspende hat Vorrang",
      patientDecree: "Patientenverfügung hat Vorrang",
    },
  },
  organDonate: {
    label: "Organe spenden?",
    values: {
      no: "Nein",
      yes: "Ja",
      some: "Mit Ausnahmen",
    },
  },
  organReceive: {
    label: "Organspenden empfangen?",
    values: {
      no: "Nein",
      yes: "Ja",
      some: "Mit Ausnahmen",
    },
  },
  organReceiveSet: {
    label: "Organe auswählen",
    values: {
      heart: "Herz",
      lung: "Lunge",
      liver: "Leber",
      kidney: "Niere",
      pancreas: "Bauchspeicheldrüse",
      gut: "Darm",
      eyes: "Horn- und Lederhaut der Augen",
      valves: "Herzklappen",
      skin: "Haut",
      vessels: "Blutgefäße",
      bonetissue: "Knochengewebe",
      cartilage: "Knorpelgewebe",
      softtissue: "Weichteilgewebe",
    },
  },
  organDonateSet: {
    label: "Organe auswählen",
    values: {
      heart: "Herz",
      lung: "Lunge",
      liver: "Leber",
      kidney: "Niere",
      pancreas: "Bauchspeicheldrüse",
      gut: "Darm",
      eyes: "Horn- und Lederhaut der Augen",
      valves: "Herzklappen",
      skin: "Haut",
      vessels: "Blutgefäße",
      bonetissue: "Knochengewebe",
      cartilage: "Knorpelgewebe",
      softtissue: "Weichteilgewebe",
    },
  },
  lifeQuality: {
    label: "Minimale Lebensqualität",
  },
  medication: {
    label: "Immer leidensreduzierende Medikamente?",
  },
  diagnosis: {
    label: "Nicht wiederbeleben Diagnosen?",
  },
  reanimationDeny: {
    label: "Reanimation widersprechen?",
  },
  euthanasia: {
    label: "Sterbehilfe",
  },
  currentLifeSupport: {
    label: "Lebenserhaltende Maßnahmen?",
  },
  petitioner: {
    gender: {
      label: "Anrede",
      values: {
        male: "Herr",
        female: "Frau",
        diverse:"Divers"
      },
    },
    givenName: {
      label: "Vorname",
    },
    familyName: {
      label: "Nachname",
    },
    birthDate: {
      label: "Geburtsdatum",
    },
    residenceAddress: {
      streetAddress: {
        label: "Straße und Hausnummer",
      },
      postalCode: {
        label: "PLZ",
      },
      addressLocality: {
        label: "Ort",
      },
    },
  },
};

export default patientDecreeTranslations;
