

export default function StatsPage() {
  return (
    <>
      <iframe width="100%" height="1600"
              src="https://datastudio.google.com/embed/reporting/ce03bf9d-c91e-4855-9a81-a70b508423eb/page/p_pupwb4ucqc"
              allowFullScreen></iframe>
    </>
  );
}
