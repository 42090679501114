import React from "react";
import {
  STAGE_DATA_COMPLETED,
  STAGE_DATA_COMPLETED_LAWFIRM,
  STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT,
  STAGE_WAITING_FOR_FEEDBACK,
} from "../../Stages/StagesCase";
import PreviewButton from "../../../components/Preview/PreviewButton";
import { AbstractCase } from "../../../types/AbstractCase";

export const contractCaseDetailsSpecialActions = (product:AbstractCase) => {
  let specialActions = [];
  if (
    (product.stage === STAGE_DATA_COMPLETED || product.stage === STAGE_WAITING_FOR_FEEDBACK) &&
    product.caseCategoryAssessment === "reject" &&
    product.assessmentRejectionText
  ) {
    specialActions.push(
      <PreviewButton
        key={"previewCaseRejectedMessage"}
        product={product}
        documentType={"message"}
        documentIdentifier={"message/contractLaw/case-rejected"}
        buttonText={"Vorschau Für Nachricht"}
        modalHeadline={"Vorschau Nachricht"}
      />
    );
  }

  if (product.stage === STAGE_DATA_COMPLETED_LAWFIRM) {
    specialActions.push(
      <PreviewButton
        key={"preview-claimLetter"}
        product={product}
        documentType={"pdf"}
        documentIdentifier={"claimLetter"}
        buttonText={"Vorschau für Schreiben"}
        modalHeadline={"Vorschau außergerichtliches Schreiben"}
      />
    );
  }

  if (product.stage === STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT) {
    specialActions.push(
      <PreviewButton
        key={"preview-feedbackMessage"}
        product={product}
        documentType={"message"}
        documentIdentifier={"message/contractLaw/feedback"}
        buttonText={"Vorschau für Feedback"}
        modalHeadline={"Vorschau Feedback Nachricht"}
      />
    );
  }

  if (specialActions.length === 0) {
    return null;
  }

  return specialActions;
};
