import { WireTransfer } from "../types/WireTransfer";

export function getWiretransferDisplayStatus(wiretransfer: WireTransfer) {
  if (!!wiretransfer.paymentRun) {
    return "Im Zahllauf";
  }
  if (!!wiretransfer.factualCorrectDate && !wiretransfer.calculativeCorrectDate) {
    return "Sachlich geprüft";
  }
  if (!!wiretransfer.factualCorrectDate && !!wiretransfer.calculativeCorrectDate) {
    return "Rechnerisch geprüft";
  }
  return "Erstellt";
}
