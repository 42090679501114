import React from "react";
import { Box, Tab, TabProps } from "@mui/material";
import taggedTabStyles from "./taggedTabStyles";

type TaggedTabProps = {
  label: string;
  entryCount: number;
} & TabProps;

export default function TaggedTab({ label, value, entryCount, ...rest }: TaggedTabProps) {
  return (
    <Tab
      {...rest}
      sx={taggedTabStyles.tableHeadline}
      label={
        <Box sx={taggedTabStyles.tabItemLabelGroupStyle}>
          <Box sx={taggedTabStyles.tabItemLabelStyle}>
            {label + " "}
            {entryCount > 0 && (
              <Box sx={taggedTabStyles.tabItemTagStyle}>{entryCount}</Box>
            )}
          </Box>
        </Box>
      }
      value={value}
    />
  );
};
