import React, { useEffect, useState } from "react";
import { Box, Chip, Paper, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import lawyerAbsenceActivitiesStyles from "./LawyerAbsenceActivitiesStyles";
import moment from "moment/moment";
import Avatar from "@mui/material/Avatar";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import Absence, { StandIn, standInIsHumanResource } from "../../../types/Absence";
import { apiGet } from "../../../services/Api/apiCall";
import _ from "lodash";
import { Activity } from "../../../types/Activity";
import { deadlineActivityTypes } from "../../../services/Activities/activityTypes";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";

type LawyerAbsenceActivitiesProps = {
  refreshCounter: number;
  isLoading: boolean;
  setSubjectFilter: Function;
  setOpenOrDoneFilter: Function;
  setProductFilter: Function;
  setActivityTypeFilter: Function;
  setUserFilter: Function;
  setTimespanFilter: Function;
};

export default function LawyerAbsenceActivities({
  refreshCounter,
  isLoading,
  setSubjectFilter,
  setOpenOrDoneFilter,
  setProductFilter,
  setActivityTypeFilter,
  setUserFilter,
  setTimespanFilter,
}: LawyerAbsenceActivitiesProps) {
  const currentUser = useCurrentUser();
  const [standIns, setStandIns] = useState<Array<ExtendedAbsence>>([]);

  const { lawyers } = useBackofficeUser();

  const humanResourcesFilter = {
    backofficeUser: currentUser["@id"],
  };

  const { data: humanResources } = useQuery(queryKeys.collection("human_resources", humanResourcesFilter), () =>
    fetchCollection("human_resources", humanResourcesFilter)
  );

  useEffect(() => {
    if (humanResources) {
      const standInsToday = humanResources["hydra:member"][0]?.standIns.filter(
        (StandIn: Absence) => moment(StandIn.absenceDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      );

      if (standInsToday && standInsToday.length > 0) {
        enrichAndSetUserStandIns(standInsToday);
      }
    }
  }, [humanResources, refreshCounter]);

  const enrichAndSetUserStandIns = async (standIns: Absence[]) => {
    if (!standIns) {
      return;
    }

    const convertedSandIns = standIns.map((standIn) => {
      return { ...standIn, hasMandatoryActivities: false, activitiesCount: 0 };
    });

    for (const standIn of convertedSandIns) {
      const queryParams = new URLSearchParams({
        assignedUser: "/backoffice_users/" + standIn.absentHumanResourceBackofficeUserId,
        deleted: "false",
        "dueDate[strictly_before]": moment().add(1, "d").format("YYYY-MM-DD"),
        "exists[doneTime]": "false",
        pagination: "false",
      });

      await apiGet("activities?" + queryParams.toString()).then((activitiesResponse) => {
        standIn.hasMandatoryActivities = !!activitiesResponse["hydra:member"].find((activity: Activity) =>
          deadlineActivityTypes.includes(activity.type)
        );
        standIn.activitiesCount = activitiesResponse["hydra:totalItems"];
      });
    }

    setStandIns(convertedSandIns);
  };

  if (isLoading || !humanResources || standIns.length === 0) {
    return null;
  }

  const handleFilter = (standIn: ExtendedAbsence) => {
    setSubjectFilter("");
    setOpenOrDoneFilter("open");
    setProductFilter("all");
    setActivityTypeFilter("all");
    setUserFilter("/backoffice_users/" + standIn.absentHumanResourceBackofficeUserId);
    setTimespanFilter("today");
  };

  const getFormatedName = (absentUserFullName?: string) => {
    if (!absentUserFullName) {
      return "Kein Name";
    }
    const [firstName, lastName] = absentUserFullName.split(" ");
    return lastName ? firstName.charAt(0) + ". " + lastName : absentUserFullName;
  };

  const getOtherStandInsAsString = (absence: Absence) => {
    let standInNames = [];

    standInNames.push(getStandInName(absence.standIn1));
    standInNames.push(getStandInName(absence.standIn2));
    standInNames.push(getStandInName(absence.standIn3));

    standInNames = standInNames.filter((standInName) => !!standInName);
    return standInNames.join(", ");
  };

  const getStandInName = (standIn: StandIn) => {
    if (standIn === humanResources["hydra:member"][0]["@id"]) {
      return currentUser.person.fullname;
    }
    if (standIn && standInIsHumanResource(standIn)) {
      return lawyers.find((user) => user["@id"] === standIn.backofficeUser)?.person.fullname;
    }
    return "";
  };

  return (
    <>
      <Grid container justifyContent={"flex-end"}>
        <Grid item>
          <Paper sx={lawyerAbsenceActivitiesStyles.paper}>
            <Typography sx={lawyerAbsenceActivitiesStyles.headline}>Heute Vertretung für:</Typography>
            <Box textAlign={"center"}>
              {_.map(standIns, (standIn: ExtendedAbsence) => (
                <Tooltip key={standIn.id} title={getOtherStandInsAsString(standIn)}>
                  <Chip
                    sx={lawyerAbsenceActivitiesStyles.chip}
                    avatar={
                      <Avatar sx={{ border: standIn.hasMandatoryActivities ? "2px solid #A31B1B" : "" }}>
                        {standIn.activitiesCount}
                      </Avatar>
                    }
                    label={getFormatedName(standIn.absentUserFullName)}
                    variant={"filled"}
                    onClick={() => handleFilter(standIn)}
                  />
                </Tooltip>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

type ExtendedAbsence = Absence & {
  hasMandatoryActivities: boolean;
  activitiesCount: number;
};
