import React, { useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import casePdfGeneratorStyle from "./casePdfGeneratorStyle";
import { Checkbox, FormControl, FormLabel, ListItemText, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { convertToRaw, EditorState } from "draft-js";
import _ from "lodash";
import {
  getExternalReference,
  getExternalReferenceSelectValue,
  getDocumentName,
  getPdfGeneratorRecipients,
  getReferenceOptions,
} from "./casePdfGeneratorService";
import { saveSessionStorageState } from "../../../services/browserStorageService";
import SelectElement from "../../ReactHookFormElements/SelectElement";
import { RadioButtonGroup, TextFieldElement, useFormContext } from "react-hook-form-mui";
import LbEditor from "../../LbEditor/LbEditor";
import { MediaObject } from "../../../types/MediaObject";
import { AbstractCase } from "../../../types/AbstractCase";
import { EditorProps } from "react-draft-wysiwyg";

// @ts-ignore
const useStyles = makeStyles(casePdfGeneratorStyle);

type PdfGeneratorFormElementsProps = {
  product: AbstractCase;
  mediaObjects: MediaObject[];
  minimalView?: boolean;
  editorToolBarCustomButtons?: EditorProps["toolbarCustomButtons"];
};

const PdfGeneratorFormElements = ({
  product,
  mediaObjects,
  minimalView = false,
  editorToolBarCustomButtons = [],
}: PdfGeneratorFormElementsProps) => {
  const classes = useStyles();

  const formContext = useFormContext();

  const handleEditorStateChange = (editorState: EditorState) => {
    formContext.setValue("content", editorState);
    saveFormDataToSessionStorage();
  };

  useEffect(() => {
    formContext.setValue(
      "externalReference",
      getExternalReference(product, formContext.getValues("externalReferenceSelect"))
    );
  }, [formContext.watch("externalReferenceSelect")]);

  useEffect(() => {
    if (formContext.getValues("sendEmailToCustomer") === "true") {
      formContext.setValue("showInGallery", "true");
    }
  }, [formContext.watch("sendEmailToCustomer")]);

  useEffect(() => {
    formContext.setValue("filename", getDocumentName(formContext.getValues("recipientType")));
    formContext.setValue(
      "externalReferenceSelect",
      getExternalReferenceSelectValue(product, formContext.watch("recipientType"))
    );
  }, [formContext.watch("recipientType")]);

  const saveFormDataToSessionStorage = _.throttle(() => {
    let valuesToPersist = _.merge({}, formContext.getValues());
    valuesToPersist.content = convertToRaw(formContext.getValues("content").getCurrentContent());
    saveSessionStorageState("customPdf", valuesToPersist);
  }, 3000);

  const renderSelectedMediaObject = (selected: number[]) => {
    //map over the selected array and find the related mediaobject to get its description
    if (!mediaObjects.length) {
      return "";
    }
    return _.map(
      selected,
      (selectedMediaObject) =>
        _.find(mediaObjects, (mediaObject) => mediaObject.id === selectedMediaObject)?.description
    ).join(", ");
  };

  const referenceOptions = useMemo(
    () => _.filter(getReferenceOptions(product), (option) => !option.isHidden),
    [product]
  );

  return (
    <Grid container alignItems={"flex-start"} justifyContent={"center"} spacing={3}>
      <Grid item xs={12} md={6}>
        <SelectElement
          label={"Empfänger"}
          name={"recipientType"}
          helperText={"An wen soll das Schreiben adressiert sein?"}
        >
          {getPdfGeneratorRecipients(product).map((recipient) => (
            <MenuItem key={recipient.value} value={recipient.value}>
              {recipient.label}
            </MenuItem>
          ))}
        </SelectElement>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldElement
          label={"Dateiname"}
          name={"filename"}
          rules={{
            pattern: {
              value: /^([a-zA-Z]|_|-|[0-9])*$/,
              message: "Bitte nur Buchstaben (keine Umlaute), Ganzzahlen, Binde- und Unterstriche verwenden.",
            },
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">.pdf</InputAdornment>,
          }}
        />
      </Grid>
      {formContext.watch("recipientType") === "custom" && (
        <>
          <Grid item xs={12} md={6}>
            <TextFieldElement label={"Empfänger"} name={"address.name"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldElement label={"Empfänger Zeile 2"} name={"address.department"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldElement label={"Empfänger Zeile 3"} name={"address.subDepartment"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldElement label={"Straße und Hausnummer"} name={"address.streetAddress"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldElement label={"Postleitzahl"} name={"address.postalCode"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldElement label={"Ort"} name={"address.addressLocality"} />
          </Grid>
        </>
      )}
      {!minimalView && (
        <>
          <Grid item xs={12} md={4}>
            <SelectElement label={"Zustellung per"} name={"senderMedium"}>
              <MenuItem value={"none"}>Keine Anzeige</MenuItem>
              <MenuItem value={"fax"}>per Fax</MenuItem>
              <MenuItem value={"bea"}>über beA</MenuItem>
              <MenuItem value={"email"}>per E-Mail</MenuItem>
              <MenuItem value={"registeredMail"}>per Einschreiben</MenuItem>
            </SelectElement>
          </Grid>
          {formContext.watch("recipientType") === "legalExpenseInsurance" && (
            <>
              <Grid item xs={12} md={4}>
                <TextFieldElement
                  label={"Schadennummer"}
                  name={"caseReferenceNumber"}
                  value={product.insurance.caseReferenceNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldElement
                  label={"Versicherungsnummer"}
                  name={"insurancePolicyNumber"}
                  value={product.insurance.insurancePolicyNumber}
                  disabled
                />
              </Grid>
            </>
          )}
        </>
      )}
      <Grid item xs={12} md={4}>
        <SelectElement label={"Aktenzeichenauswahl"} name={"externalReferenceSelect"}>
          <MenuItem value={"free"}>Aktenzeichen frei eingeben</MenuItem>
          {_.map(referenceOptions, (referenceOption, index) => (
            <MenuItem value={referenceOption.type} key={index}>
              {referenceOption.label}
            </MenuItem>
          ))}
        </SelectElement>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldElement
          label={"Aktenzeichen"}
          name={"externalReference"}
          variant={"outlined"}
          InputProps={{
            readOnly: formContext.watch("externalReferenceSelect") !== "free",
          }}
        />
      </Grid>
      {!minimalView && (
        <>
          <Grid item xs={12} md={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Für Mandantschaft sichtbar</FormLabel>
              <RadioButtonGroup
                row
                options={[
                  { id: "true", label: "Ja" },
                  { id: "false", label: "Nein" },
                ]}
                name={"showInGallery"}
                disabled={formContext.watch("sendEmailToCustomer") === "true"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Mandantschaft benachrichtigen</FormLabel>
              <RadioButtonGroup
                row
                options={[
                  { id: "true", label: "Ja" },
                  { id: "false", label: "Nein" },
                ]}
                name={"sendEmailToCustomer"}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={4}>
        <SelectElement
          label={"Dokument anhängen"}
          name={"selectedMediaObjects"}
          multiple
          renderValue={renderSelectedMediaObject}
        >
          {_.map(mediaObjects, (mediaObject) => (
            <MenuItem
              key={mediaObject.id}
              value={mediaObject.id}
              disabled={mediaObject.mimeType !== "application/pdf" || mediaObject.size > 2000000}
            >
              <Checkbox
                checked={
                  !!_.find(
                    formContext.watch("selectedMediaObjects"),
                    (selectedMediaObject) => selectedMediaObject === mediaObject.id
                  )
                }
              />
              <ListItemText primary={mediaObject.description} />
            </MenuItem>
          ))}
        </SelectElement>
      </Grid>
      <Grid item style={{ width: "100%", maxWidth: 670 }}>
        <Paper className={classes.paper}>
          <LbEditor
            toolbar={{
              options: ["inline", "list", "textAlign", "history"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
              textAlign: {
                inDropdown: true,
              },
            }}
            editorStyle={{
              fontFamily: "RobotoSlab",
              fontWeight: 300,
              fontSize: "15px",
              color: "#555",
            }}
            stripPastedStyles
            editorState={formContext.watch("content")}
            onEditorStateChange={handleEditorStateChange}
            editorClassName={classes.editor}
            placeholder={"Freitext"}
            toolbarCustomButtons={editorToolBarCustomButtons}
            spellCheck
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PdfGeneratorFormElements;
