import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import ApiClient from "../../services/ApiClient";
import ButtonLoading from "../Button/ButtonLoading";
import authorityInvoicesStyle from "./authorityInvoiceStyle";

const AuthorityInvoices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const processAuthorityInvoices = async () => {
    setIsLoading(true);
    try {
      await ApiClient.post("service/process_authority_invoices");

      enqueueSnackbar("Erstellung der EA-Rechnungsdaten CSV wurde erfolgreich angestoßen. Es kann etwas dauern...", {

        variant: "custom",
        buttonText: "zur CSV Datei",
        buttonLink: "https://drive.google.com/drive/folders/1TtVXM83YB4wAf6Kz_86iGvFP8UzJkTT8",
        linkIsExternal: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e: any) {
      enqueueSnackbar(e.response.res, {
        variant:"custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <Grid item xs={12} sx={authorityInvoicesStyle.buttonContainer}>
      <ButtonLoading onClick={processAuthorityInvoices} variant={"contained"} isLoading={isLoading}>
        Ordner EA Rechnungen auslesen
      </ButtonLoading>
    </Grid>
  );
};

export default AuthorityInvoices;
