import { RefObject, useRef, useState } from "react";
import { emailStyle } from "./emailStyle";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CircleIcon from "../CircleIcon/CircleIcon";
import { Attachment, MailOutline, UnfoldLess, UnfoldMore, VisibilityOffOutlined } from "@mui/icons-material";
import moment from "moment";
import EmailAttachment from "./EmailAttachment";
import _ from "lodash";
import EmailMoreMenu from "./EmailMoreMenu";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { EmailMessage } from "../../types/EmailMessage";
import { AbstractCase } from "../../types/AbstractCase";

interface EmailProps {
  emailMessage: EmailMessage;
  isNonInteractive?: boolean;
  refetchEmails?: Function;
  product: AbstractCase;
}

const Email = ({ emailMessage, isNonInteractive = false, refetchEmails = () => {}, product }: EmailProps) => {
  const currentUser = useCurrentUser();
  const [emailFolded, setEmailFolded] = useState(true);

  const Icon = <MailOutline color={"secondary"} />;

  const getValidAttachmentsArray = (attachments: any) => {
    return _.filter(attachments, function (attachment) {
      if (attachment.mimeType !== "text/html" && attachment.mimeType !== "text/plain") {
        return attachment;
      }
    });
  };

  const EmailIFrame = ({ emailMessage }: { emailMessage: EmailMessage }) => {
    const content =
      emailMessage.messageContent.html ||
      String(emailMessage.messageContent.text) ||
      "Legalbird-Systemhinweis: Diese Mail enthält keinen Inhalt";
    const iFrameRef: RefObject<HTMLIFrameElement> = useRef() as RefObject<HTMLIFrameElement>;
    const [iFrameHeight, setIFrameHeight] = useState(0);

    function addStylesToIFrame() {
      if (/<\/?[a-z][\s\S]*>/i.test(content)) {
        return;
      }
      const styleElement = document.createElement("style");
      styleElement.textContent = "body { white-space: pre-wrap; }";
      iFrameRef.current!.contentWindow!.document.head.appendChild(styleElement);
    }

    function onIFrameLoad() {
      addStylesToIFrame();
      setIFrameHeight(iFrameRef.current!.contentWindow!.document.body.scrollHeight + 30);
    }

    return (
      <iframe
        title={"email-" + emailMessage.id}
        ref={iFrameRef}
        onLoad={onIFrameLoad}
        srcDoc={content}
        style={{ width: "100%", border: "none", overflow: "hidden" }}
        height={iFrameHeight}
      />
    );
  };

  return (
    <Grid container sx={emailStyle.emailContainer} alignItems={"flex-start"}>
      <Grid item sx={emailStyle.icon}>
        <CircleIcon icon={Icon} type={"muiIcon"} />
      </Grid>
      <Grid item sx={emailStyle.mailContent}>
        <Grid container>
          <Grid item xs={12} sx={emailStyle.headline}>
            {emailMessage.messageContent.subject}
            {emailMessage.attachments && getValidAttachmentsArray(emailMessage.attachments).length > 0 && (
              <Tooltip title={"Anhang vorhanden"}>
                <Attachment />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={12} sx={emailStyle.info}>
            {moment(emailMessage.created).format("DD.MM.YYYY")} um {moment(emailMessage.created).format("HH:mm")} Uhr
            {emailMessage.from && emailMessage.to && (
              <>
                {" "}
                | {emailMessage.from.email} `{">>"}` {emailMessage.to.email}
              </>
            )}
            {emailMessage.cc && <div> Cc: {emailMessage.cc}</div>}
          </Grid>
          {emailFolded ? (
            <Grid item xs={12}>
              {!!emailMessage.messageContent.snippet && emailMessage.messageContent.snippet.length > 83
                ? emailMessage.messageContent.snippet.substring(0, 80) + "..."
                : emailMessage.messageContent.snippet}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <EmailIFrame emailMessage={emailMessage} />
              <Grid container spacing={3} sx={emailStyle.attachmentContainer}>
                {getValidAttachmentsArray(emailMessage.attachments) &&
                  _.map(getValidAttachmentsArray(emailMessage.attachments), (attachment) => (
                    <Grid item xs={6} key={attachment.attachmentId}>
                      <EmailAttachment attachment={attachment} emailId={emailMessage.id} product={product} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        {!isNonInteractive && (
          <Stack direction="row" justifyContent="flex-end">
            {!currentUser.isExternal && (
              <>
                {emailMessage.hiddenExternally && (
                  <Box sx={{ padding: "12px" }}>
                    <VisibilityOffOutlined />
                  </Box>
                )}
                <Box>
                  <EmailMoreMenu emailMessage={emailMessage} product={product} refetchEmails={refetchEmails} />
                </Box>
              </>
            )}
            <Box>
              <IconButton sx={emailStyle.expandButton} onClick={() => setEmailFolded(!emailFolded)} size="large">
                {emailFolded ? <UnfoldMore /> : <UnfoldLess />}
              </IconButton>
            </Box>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Email;
