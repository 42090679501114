import PageContentContainer from "../../../Container/PageContentContainer";
import PageHeadline from "../../../PageHeadline/PageHeadline";

import ExternalsDashboard from "../../../Externals/Dashboard/ExternalsDashboard";
import LayoutDeviceBased from "../../../../routeHelperComponents/LayoutDeviceBased";

export default function ExternalsDashboardPage() {
  return (
    <LayoutDeviceBased isExternal>
      <PageContentContainer size={"large"}>
        <PageHeadline main="Übersicht" />
        <ExternalsDashboard />
      </PageContentContainer>
    </LayoutDeviceBased>
  );
}
