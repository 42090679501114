import React, { useState } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import BankPaymentEntryRow from "./BankPaymentEntryRow";
import PageContentContainer from "../Container/PageContentContainer";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import moment from "moment";
import BarsLoader from "../ContentLoader/BarsLoader";
import PageHeadline from "../PageHeadline/PageHeadline";
import ContentBox from "../ContentBox/ContentBox";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { HydraResult } from "../../types/HydraResult";
import { BankPaymentEntry } from "../../types/BankPaymentEntry";
import { bankPaymentEntriesStyle } from "./bankPaymentEntriesStyle";

const BankPaymentEntries = () => {
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const queryClient = useQueryClient();

  let paymentFilters = {
    "exists[bookedDate]": false,
  };
  const { data: bankPaymentEntries, isLoading } = useQuery(
    queryKeys.collection("bank_payment_entries", paymentFilters),
    () => fetchCollection("bank_payment_entries", paymentFilters)
  );

  const handleBookingClick = async () => {
    const date = moment();
    let promises: Promise<BankPaymentEntry>[] = [];
    _.forEach(bankPaymentEntries["hydra:member"], (bankPaymentEntry) => {
      if (!bankPaymentEntry.checkedDate) {
        return;
      }
      promises.push(
        ApiClient.put("bank_payment_entries/" + bankPaymentEntry.id, {
          body: JSON.stringify({
            bookedDate: date,
          }),
        })
      );
    });
    await Promise.all(promises);
    await queryClient.invalidateQueries(queryKeys.collection("bank_payment_entries", paymentFilters));
    setBookModalOpen(false);
  };

  const hasBookableEntries = (unbookedPaymentEntries: HydraResult<BankPaymentEntry>) => {
    if (!unbookedPaymentEntries) {
      return false;
    }
    return _.some(unbookedPaymentEntries["hydra:member"], (paymentEntry) => paymentEntry.checkedDate !== null);
  };

  const refreshList = () => {
    queryClient.invalidateQueries(queryKeys.collection("bank_payment_entries", paymentFilters));
  };

  return (
    <PageContentContainer size={"large"}>
      <PageHeadline main="Zahlungen buchen" />
      <ContentBox>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Verwendungszweck</TableCell>
              <TableCell>Betrag</TableCell>
              <TableCell>Fremdgeld?</TableCell>
              <TableCell>Zugeordneter Fall</TableCell>
              <TableCell>Suche</TableCell>
              <TableCell>Geprüft</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!(isLoading || !bankPaymentEntries) &&
              _.map(bankPaymentEntries["hydra:member"], (bankPaymentEntry) => (
                <BankPaymentEntryRow
                  key={bankPaymentEntry.id}
                  bankPaymentEntry={bankPaymentEntry}
                  refreshList={refreshList}
                />
              ))}
          </TableBody>
        </Table>
        {(isLoading || !bankPaymentEntries) && (
          <Box sx={bankPaymentEntriesStyle.spinner}>
            <BarsLoader />
          </Box>
        )}
        <ButtonLoading
          fullWidth={false}
          sx={bankPaymentEntriesStyle.button}
          onClick={() => setBookModalOpen(true)}
          disabled={!hasBookableEntries(bankPaymentEntries)}
          variant={"contained"}
        >
          Alle Einträge buchen
        </ButtonLoading>
      </ContentBox>
      <LegalbirdIoModal
        handleClose={() => setBookModalOpen(false)}
        open={bookModalOpen}
        title={"Geprüfte Einträge buchen?"}
        submitButton={
          <ButtonLoading variant={"contained"} isLoading={false} onClick={handleBookingClick}>
            Jetzt buchen
          </ButtonLoading>
        }
      >
        <Box sx={bankPaymentEntriesStyle.modalText}>
          Sind Sie sicher, dass alle geprüften Einträge gebucht werden sollen?
        </Box>
      </LegalbirdIoModal>
    </PageContentContainer>
  );
};

export default BankPaymentEntries;
