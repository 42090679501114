import React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { getBadgesMinimal, getCaseLabel, getIsApplicationComplete } from "../../../../services/Product/ProductService";
import { makeStyles } from "@mui/styles";
import externalsCaseHeaderStyle from "./externalsCaseHeaderStyle";
import BadgesList from "../../../Badges/BadgesList";
import AgentPicker from "../../../AgentPicker/AgentPicker";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";
import { AbstractCase } from "../../../../types/AbstractCase";
import CaseAdditionalClientsTooltipIcon from "../../../Case/CaseAdditionalClientsTooltipIcon/CaseAdditionalClientsTooltipIcon";
import CaseSubBarInfo from "../../../Case/CaseSubBarInfo/CaseSubBarInfo";

//@ts-ignore
const useStyles = makeStyles(externalsCaseHeaderStyle);

type ExternalsCaseHeaderProps = {
  product: AbstractCase;
};
const ExternalsCaseHeader = ({ product }: ExternalsCaseHeaderProps) => {
  const classes = useStyles();
  const { backofficeUsers, accountManagers, lawyers } = useBackofficeUser();

  if (!backofficeUsers) {
    return null;
  }

  const isApplicationComplete = getIsApplicationComplete(product);

  return (
    <Paper className={classes.caseHeaderContainer}>
      <Grid container>
        <Grid item xs={12} lg={7} sx={{ paddingTop: "1rem" }}>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="h1">{getCaseLabel(product)}</Typography>
            <CaseAdditionalClientsTooltipIcon product={product} fontSize={"large"} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              {isApplicationComplete && (
                <AgentPicker
                  label={"Anwalt"}
                  currentAgent={product.backofficeCase?.lawyer!}
                  product={product}
                  type={"lawyer"}
                  agentList={lawyers}
                  disabled
                />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <AgentPicker
                label={"Zuständiger"}
                currentAgent={product.backofficeCase?.accountManager!}
                product={product}
                type={"accountManager"}
                agentList={accountManagers}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BadgesList badges={getBadgesMinimal(product)} />
          <CaseSubBarInfo product={product} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExternalsCaseHeader;
