import React, { useEffect, useState } from "react";
import unassignedActivitiesStyles from "./unassignedActivitiesStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import IconButton from "@mui/material/IconButton";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ApiClient from "../../../services/ApiClient";
import OverviewActivityEntry from "../ActivityOverview/OverviewActivityEntry";
import { Box, Divider } from "@mui/material";
import { Activity } from "../../../types/Activity";
import { BackofficeUser } from "../../../types/BackofficeUser";

type UnassignedActivitiesProps = {
  updateFilteredActivities: Function;
};

const UnassignedActivities = ({ updateFilteredActivities }: UnassignedActivitiesProps) => {
  const [expanded, setExpanded] = useState(false);
  const [unassignedActivities, setUnassignedActivities] = useState<Activity<BackofficeUser>[]>([]);

  const updateAllActivities = () => {
    fetchUnassignedActivities();
    updateFilteredActivities();
  };

  const fetchUnassignedActivities = () => {
    ApiClient.get("activities?exists[assignedUser]=false&pagination=false&deleted=false").then((activitiesResponse) => {
      setUnassignedActivities(activitiesResponse["hydra:member"]);
    });
  };

  useEffect(() => {
    fetchUnassignedActivities();
    let checkUnassignedActivitiesTimer = setTimeout(fetchUnassignedActivities, 30000);
    return () => {
      clearTimeout(checkUnassignedActivitiesTimer);
    };
  }, []);

  if (unassignedActivities.length === 0) {
    return null;
  }

  return (
    <Paper sx={unassignedActivitiesStyles.paper}>
      <Grid container alignItems={"center"}>
        <Grid item xs={1} />
        <Grid item xs={10} sx={unassignedActivitiesStyles.headline}>
          {unassignedActivities.length} Aktivitäten nicht zugeordnet
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => setExpanded(!expanded)} size="large">
            {!expanded ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </Grid>
      </Grid>
      {expanded && (
        <>
          {_.map(unassignedActivities, (activity, index) => {
            return (
              <React.Fragment key={activity.id}>
                <OverviewActivityEntry activity={activity} update={updateAllActivities} />
                {index !== unassignedActivities.length - 1 && (
                  <Box sx={unassignedActivitiesStyles.divider}>
                    <Divider />
                  </Box>
                )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </Paper>
  );
};

export default UnassignedActivities;
