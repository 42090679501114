import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import validatorStyle from "./validatorStyle";
import { Button } from "@mui/material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { ValidatorType } from "../../types/ValidatorType";
import {TextFieldProps} from "@mui/material/TextField/TextField";

type ExpandableTextFieldProps = {
  isMandatory?: boolean;
  name: string;
  value: string | number;
  registerValidators?: Function;
  validators?: Array<ValidatorType>;
  dependentValidationFields: Array<string>;
  label: string;
  maxRows?: number;
  buttonLabel?: string;
} & TextFieldProps;

// use ref, make props
const ExpandableTextField = ({
  isMandatory = false,
  name,
  value,
  registerValidators,
  validators,
  dependentValidationFields = [],
  label,
  maxRows = 15,
  buttonLabel = "Text in neuem Fenster anzeigen",
  ...rest
}: ExpandableTextFieldProps) => {
  useEffect(() => {
    registerValidators && validators && registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators && registerValidators(name, []);
    };
  }, [registerValidators, dependentValidationFields, name]);
  const [showShowFullTextButton, setShowShowFullTextButton] = useState(false);
  const [showFullTextModal, setShowFullTextModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const scrollHeight = inputRef.current.scrollHeight;
      const clientHeight = inputRef.current.clientHeight;
      setShowShowFullTextButton(scrollHeight > clientHeight);
    }
  });

  return (
    <>
      <TextField
        inputRef={inputRef}
        {...rest}
        name={name}
        value={value}
        label={label}
        sx={isMandatory && (value === null || value === "") ? validatorStyle.mandatory : null}
        maxRows={maxRows}
      />
      {showShowFullTextButton ? <Button onClick={() => setShowFullTextModal(true)}>{buttonLabel}</Button> : null}
      <LegalbirdIoModal
        handleClose={() => setShowFullTextModal(false)}
        open={showFullTextModal}
        maxWidth={"xl"}
        hasActions={false}
        hideCancelButton={true}
        title={label}
      >
        <div style={{ whiteSpace: "pre-line" }}>{value}</div>
      </LegalbirdIoModal>
    </>
  );
};

export default ExpandableTextField;
