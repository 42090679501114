import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import LegalbirdLoader from "./LegalbirdLoader";

type LegalBirdPageLoadingProps = {
  type?: "bird" | "spinner";
};
const LegalBirdPageLoading = ({ type = "bird" }: LegalBirdPageLoadingProps) => {
  return (
    <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
      <Grid item>{type === "spinner" ? <CircularProgress /> : <LegalbirdLoader />}</Grid>
    </Grid>
  );
};

export default LegalBirdPageLoading;
