import React, { useMemo, useState } from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import AcceptPaidTaskButton from "../PaidTask/PaidTaskView/AcceptPaidTaskButton";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { translate } from "../../services/Translations/translatorService";
import { convertFromFloat } from "../../services/formServiceFunctions";
import moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import { PaidTask } from "../../types/PaidTask";
import { getFullProductLabel, getProductData, getProductNameBy } from "../../services/Product/ProductService";

export default function CasePaidTaskAccept({ paidTask }: {paidTask: PaidTask}) {
  const [open, setOpen] = useState<boolean>(false);
  const fullProductLabel = useMemo<string>(() => {
    const productClassName = getProductNameBy("lbProductClass", paidTask.backofficeCase.lbProductClass);
    return getFullProductLabel({
      productClassName,
      productCategory: getProductData(productClassName, "productCategory")
    });
  }, [paidTask.backofficeCase.lbProductClass]);

  return (
    <>
      <Button onClick={() => setOpen(true)} color={"primary"}>
        Details und Annahme
      </Button>
      <LegalbirdIoModal
        handleClose={() => setOpen(false)}
        open={open}
        title={"Aufgabe"}
        hasActions={true}
        hideCancelButton={false}
        submitButton={<AcceptPaidTaskButton paidTask={paidTask} />}
        disableBackdropClick={false}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {fullProductLabel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField disabled={true} label={translate("paidTask.subject")} value={paidTask.subject} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              disabled={true}
              label={translate("paidTask.description")}
              value={paidTask.description}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              disabled={true}
              label={translate("paidTask.definitionOfDone")}
              value={paidTask.definitionOfDone}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={true}
              label={translate("paidTask.fee")}
              value={convertFromFloat(paidTask.fee)}
              InputProps={{
                endAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={5} sx={{ padding: "0 0.5rem" }}>
            <TextField
              disabled={true}
              label={translate("paidTask.assignmentDeadline")}
              value={moment(paidTask.assignmentDeadline).format("DD.MM.YYYY")}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              disabled={true}
              label={translate("paidTask.doneDeadline")}
              value={moment(paidTask.doneDeadline).format("DD.MM.YYYY")}
            />
          </Grid>
        </Grid>
      </LegalbirdIoModal>
    </>
  );
}
