import CaseUpdate from "../../types/Mercure/CaseUpdate";
import React from "react";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import Paper from "@mui/material/Paper";
import { caseRefreshNotificationStyle } from "../Notifications/caseRefreshNotificationStyle";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { translate } from "../../services/Translations/translatorService";
import Button from "@mui/material/Button";

export type CaseRefreshNotificationSnackbarProps = {
  refreshPage: Function;
  caseUpdate: CaseUpdate;
};

export const CaseRefreshNotificationSnackbar = React.forwardRef<
  HTMLDivElement,
  CaseRefreshNotificationSnackbarProps & CustomContentProps
>(({ refreshPage, caseUpdate }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const closeNotification = () => {
    closeSnackbar();
  };

  const handleRefreshPageClick = () => {
    closeNotification();
    refreshPage();
  };

  return (
    <SnackbarContent ref={ref}>
      <Paper sx={caseRefreshNotificationStyle.notificationContainer}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={2} />
          <Grid item xs={8} sx={caseRefreshNotificationStyle.headline}>
            Neuigkeiten zu diesem Fall
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={caseRefreshNotificationStyle.icon} onClick={closeNotification} size="large">
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            Es liegen neue Daten zu diesem Fall vor. Für einen aktuellen Stand bitte die Seite neu laden.
          </Grid>
          <Grid item xs={12}>
            Folgender Bereich ist betroffen:
          </Grid>
          <Grid item xs={12}>
            {caseUpdate.type === "historyLog" ? (
              <>[ {translate(`historyItem.${caseUpdate.historyLog?.type}.label`)} ]</>
            ) : (
              <>[ Aktivitäten ]</>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleRefreshPageClick}>Fall neu laden</Button>
          </Grid>
        </Grid>
      </Paper>
    </SnackbarContent>
  );
});
