import { AbstractCase } from "../../../types/AbstractCase";
import { translate } from "../../Translations/translatorService";
import { STAGE_DATA_COMPLETED } from "../../Stages/StagesCase";

export const getContractLawBadges = (product: AbstractCase) => {
  let badges = [];

  if (product.productCategory !== "contractLaw") {
    return [];
  }

  if (product.stage && product.stage >= STAGE_DATA_COMPLETED) {
    badges.push({
      text: translate(product.productClassName + ".caseCategory.values." + product.caseCategory, "Noch nicht gesetzt"),
      isActive: true,
    });
  }

  return badges;
};
