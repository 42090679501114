import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
  filterCategory: {
    margin: "1rem 2rem",
  },
  itemLabel: {
    padding: "0 .5rem",
  },
  categoryName: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  menuItem: {
    fontSize: "1rem",
    minHeight: "3rem",
  },
}));
