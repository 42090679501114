import React, { useState } from "react";
import MediaObjectFormModal from "./MediaObjectFormModal";
import DocumentClassLabelAiProvider from "../../../provider/DocumentClassLabelAiProvider";
import ActionIdentifierProvider from "../../../provider/ActionIdentifierProvider";
import LegalbirdDropzone from "./LegalbirdDropzone";
import { FILE_SIZE_LIMIT_EXCEEDED } from "../../../services/mediaObjectService";
import { AbstractCase } from "../../../types/AbstractCase";

type UploadFileProps = {
  open: boolean;
  closeDialog: Function;
  product: AbstractCase;
};

const UploadFile = ({ open, closeDialog, product }: UploadFileProps) => {
  const [files, setFiles] = useState<Array<File>>([]);

  const resetDialog = () => {
    setFiles([]);
    closeDialog();
  };

  const fileSize = files[0] ? files[0].size : 0;

  return (
    <DocumentClassLabelAiProvider>
      <ActionIdentifierProvider>
        <MediaObjectFormModal
          product={product}
          closeDialog={resetDialog}
          open={open}
          fileData={{ file: files[0] }}
          submitDisabled={!files[0] || fileSize >= FILE_SIZE_LIMIT_EXCEEDED}
        >
          <LegalbirdDropzone files={files} setFiles={setFiles} />
        </MediaObjectFormModal>
      </ActionIdentifierProvider>
    </DocumentClassLabelAiProvider>
  );
};

export default UploadFile;
