const productDashboardStyle = {
  icon: {
    width: 40,
    height: 40,
    marginTop: "5px",
    transform: "scale(1.12)",
  },
  productItem: {
    padding: "15px 25px",
  },
  paper: {
    padding: "1rem",
  },
  pageTitle: {
    margin: "2rem 0",
    textAlign: "center",
  },
};

export default productDashboardStyle;
