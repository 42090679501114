import React from "react";
import { connectHits } from "react-instantsearch-dom";
import _ from "lodash";
import BoardCard from "../../Cards/BoardCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import IconButton from "@mui/material/IconButton";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import classNames from "classnames";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { responsive, useStyles } from "./legalbirdHitsStyles";
import { IconButtonProps } from "@mui/material";
import { Hit } from "@algolia/client-search";
import { CaseHit } from "../../Case/CaseEntityPicker/Hit/hitType";

type CustomArrowProps = {
  onClick?: IconButtonProps["onClick"];
  direction: string;
};

const CustomArrow = ({ onClick, direction }: CustomArrowProps) => {
  const classes = useStyles({});

  function isValidClassName(className: string): className is keyof typeof classes {
    return className in classes;
  }

  const directionClassName = direction + "Arrow";
  if (!isValidClassName(directionClassName)) {
    return <></>;
  }

  return (
    <IconButton
      onClick={(e) => onClick && onClick(e)}
      className={classNames(classes[directionClassName], classes.arrowColors)}
      size="large"
    >
      {direction === "right" ? <ArrowRight /> : <ArrowLeft />}
    </IconButton>
  );
};

type LegalbirdHitsProps = {
  hits: Hit<any>[];
  stages: Record<string, any>;
};

const LegalbirdHits = ({ hits, stages }: LegalbirdHitsProps) => {
  const partitionHits = _.groupBy(hits, (hit) => hit.stage);
  const classes = useStyles({});
  const { backofficeUsers } = useBackofficeUser();

  return (
    //without this div the carousel keeps expanding horizontally because of something from LayoutDeviceBased
    <div className={classes.carouselContainer}>
      <Carousel
        responsive={responsive}
        draggable={false}
        customRightArrow={<CustomArrow direction={"right"} />}
        customLeftArrow={<CustomArrow direction={"left"} />}
        removeArrowOnDeviceType={["tablet", "smallTablet", "chonkyPhone", "phone"]}
      >
        {_.map(stages, (stage, stageId) => {
          const partition = partitionHits[stageId] || [];
          return (
            <div className={classes.column} key={stageId}>
              <div className={classes.columnLabel}>{stages[stageId].label}</div>
              <div className={classes.caseCount}>
                {partition.length} {partition.length === 1 ? "Fall" : "Fälle"}
              </div>
              {_.map(partition, (hit) => {
                return <BoardCard key={hit.objectID} hit={hit} backofficeUsers={backofficeUsers} />;
              })}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default connectHits(LegalbirdHits);
