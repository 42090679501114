const assessmentTranslations = {
  short: {
    notProcessedReason: {
      label: "Warum nicht",
      values: {
        clientNotAvailable: "Mandant  nicht erreicht",
        clientNumberIncorrect: "Falsche Rufnummer",
        clientCancelled: "Mandant hat Termin abgesagt",
        lawyerUnavailable: "Ich konnte nicht teilnehmen"
      },
    },
    followUpAction: {
      label: "Weitere Tätigkeit",
      values: {
        counsultation: "Weitere kurze Beratung",
        additionalActions: "Außergerichtliches Schreiben",
        documentCheck: "Prüfung von Dokumenten",
        courtProcess: "Anwaltliche Betreuuung und ggf. Prozess",
      },
    },
  },
};

export default assessmentTranslations;
