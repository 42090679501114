import React, { useEffect, useState } from "react";
import { EditorState, Modifier } from "draft-js";
import { Box } from "@mui/material";
import _ from "lodash";
import * as PropTypes from "prop-types";
import ApiClient from "../../services/ApiClient";
import { fillTemplate } from "../../services/Template/templateService";
import { htmlToBlockMap } from "../../services/Editor/editorFunctions";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";

const AddTextBlock = ({ editorState, onChange, product, formValues }) => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    ApiClient.get("templates").then((result) => {
      const textTemplates = _.filter(result["hydra:member"], (template) => {
        return (
          template.type === "text" &&
          _.includes(
            [
              "general",
              product.productCategory === "contractLaw" && product.productClassName !== "dataLeakContract"
                ? "contractLaw"
                : product.productClassName,
            ],
            template.productClassName
          )
        );
      });
      setTemplates(textTemplates);
    });
  }, []);

  const addTextBlock = (templateContent) => {
    if (!templateContent) {
      return;
    }
    const contentToInsert = htmlToBlockMap(fillTemplate(templateContent, product, formValues));
    const contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      contentToInsert,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const handlePlaceholderClick = (template) => {
    addTextBlock(`${template.content}`);
  };
  return (
    <Box width={"150px"}>
      <LegalbirdAutoComplete
        value={null}
        setValue={(event, option) => {
          const template = templates.find((t) => t.id === option.value);
          if (template) {
            handlePlaceholderClick(template);
          }
        }}
        textFieldProps={{ variant: "standard", fullWidth: true, placeholder: "Text einfügen" }}
        textFieldStyles={{ margin: 0 }}
        options={[
          ...templates.map((template) => ({
            value: template.id,
            label: template.label,
          })),
        ]}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        label={""}
      />
    </Box>
  );
};

AddTextBlock.propTypes = {
  product: PropTypes.object.isRequired,
  formValues: PropTypes.object,
};

export default AddTextBlock;
