import addressTranslations from "./addressTranslations";
import contactTranslations from "./contactTranslations";

const personTranslations = {
  gender: {
    label: "Anrede",
    values: {
      male: "Herr",
      female: "Frau",
      diverse:"Divers"
    },
  },
  givenName: {
    label: "Vorname",
  },
  familyName: {
    label: "Nachname",
  },
  nationality: {
    label: "Staatsangehörigkeit",
  },
  birthDate: {
    label: "Geburtsdatum",
  },
  residenceAddress: addressTranslations,
  addresses: [addressTranslations],
  ...contactTranslations,
};

export default personTranslations;
