import Grid from "@mui/material/Grid";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import React, { ReactNode } from "react";
import { useCommunicationMedium } from "./CommunicationMediumProvider";

export default function SendForm({ children }: { children: ReactNode }) {
  const {
    recipientType,
    setRecipientType,
    recipientMenuItems,
    communicationMedium,
    setCommunicationMedium,
    communicationMediumEditable,
  } = useCommunicationMedium();
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <ValidatorSelect
          label={"Empfänger"}
          name={"recipientType"}
          value={recipientType}
          onChange={(event) => setRecipientType(event.target.value)}
        >
          {recipientMenuItems}
        </ValidatorSelect>
      </Grid>
      <Grid item xs={6}>
        <ValidatorSelect
          label={"Versand per"}
          name={"communicationMedium"}
          disabled={!communicationMediumEditable}
          value={communicationMedium}
          onChange={(event) => setCommunicationMedium(event.target.value)}
        >
          <MenuItem value={"bea"}>per beA</MenuItem>
          <MenuItem value={"fax"}>per Fax</MenuItem>
        </ValidatorSelect>
      </Grid>
      {children}
    </Grid>
  );
}
