import React from "react";
import { Box, Typography } from "@mui/material";
import { loginTeaserStyle } from "./loginTeaserStyle";

const LoginTeaser = () => {
  return (
    <Box sx={loginTeaserStyle.teaserContainer}>
      <Typography variant="h3">Mehr Zeit für das Wesentliche:</Typography>
      <Typography component={"h2"} variant="h1">
        Ihre Mandanten
      </Typography>
      <Typography sx={{ marginTop: "2rem" }} variant="h4">
        Lassen Sie unproduktive, administrative und zeitraubende Aufgaben nicht die Oberhand gewinnen. Sie haben
        wichtigere Dinge zu tun.
      </Typography>
    </Box>
  );
};
export default LoginTeaser;
