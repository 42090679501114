import ApiClient from "../../services/ApiClient";
import { loginToken, logoutToken } from "../../services/localStorage";

export const login = async (credentials: { email: string; password: string }) => {
  const token = await ApiClient.getToken(credentials);
  loginToken(token);
};

export const logout = () => {
  logoutToken();
};
