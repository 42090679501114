import React from "react";
import { Box } from "@mui/material";
import circleIconStyle from "./circleIconStyle";

type CircleIconProps = {
  iconSize?: string | number;
  iconContainerSize?: string | number;
  icon: any;
  type?: string;
};

const CircleIcon = ({ iconSize = 24, iconContainerSize = 40, icon, type }: CircleIconProps) => {
  return (
    <Box sx={circleIconStyle.imageCircleContainer(iconContainerSize)}>
      {type === "muiIcon" ? icon : <img style={circleIconStyle.icon(iconSize)} src={icon} alt={"activityIcon"} />}
    </Box>
  );
};

export default CircleIcon;
