import React from "react";
import { Route, Routes } from "react-router-dom";
import ExternalsActivitiesPage from "../components/_Pages/ExternalsPages/ExtrenalsActivitiesPage/ExternalsActivitiesPage";
import ExternalsPaidTasksPage from "../components/_Pages/ExternalsPages/ExternalsPaidTasksPage/ExternalsPaidTasksPage";
import Externals404Page from "../components/_Pages/ExternalsPages/External404Page/Externals404Page";
import ExternalsAcceptedPaidTasksPage from "../components/_Pages/ExternalsPages/ExternalsAcceptedPaidTasksPage/ExternalsAcceptedPaidTasksPage";
import LayoutDeviceBased from "../routeHelperComponents/LayoutDeviceBased";
import ReleaseUpdatesPage from "../components/_Pages/ReleaseUpdatesPage/ReleaseUpdatesPage";
import ExternalsDashboardPage from "../components/_Pages/ExternalsPages/ExternalsDashboardPage/ExternalsDashboardPage";
import MyAccountPage from "../components/_Pages/MyAccountPage/MyAccountPage";
import ExternalCaseRoutes from "./ExternalCaseRoutes";

export default function ExternalRoutes() {
  return (
    <LayoutDeviceBased isExternal={true}>
      <Routes>
        <Route path={"/"} element={<ExternalsActivitiesPage />} />
        <Route path={"/fall/:productUrlPart/:productId/*"} element={<ExternalCaseRoutes />} />
        <Route path={"/termine"} element={<ExternalsActivitiesPage />} />
        <Route path={"/release-updates"} element={<ReleaseUpdatesPage />} />
        <Route path={"/dashboard"} element={<ExternalsDashboardPage />} />
        <Route path={"/aufgaben"} element={<ExternalsAcceptedPaidTasksPage />} />
        <Route path={"/ausgeschriebene-aufgaben"} element={<ExternalsPaidTasksPage />} />
        <Route path={"/mein-konto"} element={<MyAccountPage />} />
        <Route path={"/*"} element={<Externals404Page />} />
      </Routes>
    </LayoutDeviceBased>
  );
}
