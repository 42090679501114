import React from "react";
import DivorceRelatedMediaObjects from "./Divorce/DivorceRelatedMediaObjects";
import SettlementRelatedMediaObjects from "./Settlement/SettlementRelatedMediaObjects";
import TrafficRelatedMediaObjects from "./Traffic/TrafficRelatedMediaObjects";
import { getIsReady } from "../../../services/Product/ProductService";
import ContentBox from "../../ContentBox/ContentBox";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { AbstractCase } from "../../../types/AbstractCase";
import { MediaObject } from "../../../types/MediaObject";

type CaseRelatedMediaObjectsProps = {
  product: AbstractCase;
  refreshPage: Function;
};

type CaseRelatedFieldsProps = {
  product: AbstractCase;
  mediaObjects: MediaObject[];
  refreshPage: Function;
};

const CaseRelatedMediaObjects = ({ product, refreshPage }: CaseRelatedMediaObjectsProps) => {
  let mediaObjectsFilter = {
    product: product.productClassName,
    productId: product.id,
  };
  const { data: mediaObjectsCollection, isLoading } = useQuery(
    queryKeys.collection("media_objects", mediaObjectsFilter),
    () => fetchCollection("media_objects", mediaObjectsFilter),
    {
      enabled: !!product,
    }
  );

  if (
    !mediaObjectsCollection ||
    !getIsReady(product) ||
    !["divorce", "settlement", "traffic"].includes(product.productClassName)
  ) {
    return null;
  }

  return (
    <ContentBox headline="Prozessrelevante Dokumente">
      <CaseRelatedFields
        product={product}
        mediaObjects={mediaObjectsCollection["hydra:member"]}
        refreshPage={refreshPage}
      />
    </ContentBox>
  );
};

const CaseRelatedFields = ({ product, mediaObjects, refreshPage }: CaseRelatedFieldsProps) => {
  switch (product.productClassName) {
    case "divorce":
      return <DivorceRelatedMediaObjects product={product} mediaObjects={mediaObjects} refreshPage={refreshPage} />;
    case "settlement":
      return <SettlementRelatedMediaObjects product={product} mediaObjects={mediaObjects} refreshPage={refreshPage} />;
    case "traffic":
      return <TrafficRelatedMediaObjects product={product} mediaObjects={mediaObjects} refreshPage={refreshPage} />;
    default:
      return null;
  }
};

export default CaseRelatedMediaObjects;
