import React, { SyntheticEvent, useState } from "react";
import _ from "lodash";
import { useSnackbar } from "notistack";
import LegalbirdAutoComplete from "../../AutoComplete/LegalbirdAutoComplete";
import { getRoleToDisplay, sortByRole } from "../../../services/backofficeUserService";
import { Box } from "@mui/material";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { Activity } from "../../../types/Activity";
import { BackofficeUser } from "../../../types/BackofficeUser";
import { AutoCompleteOptionWithId } from "../../../types/AutoCompleteOptionWithId";

type AssignedUserProps = {
  activity: Activity<BackofficeUser>;
  updateActivities?: Function;
};

const AssignedUser = ({ activity, updateActivities = () => {} }: AssignedUserProps) => {
  const { agents } = useBackofficeUser();
  const [assignedUser, setAssignedUser] = useState("not_assigned");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateMutation = useMutation(updateResource);

  const revertUserAssignment = () => {
    updateMutation.mutateAsync({
      uri: "activities",
      id: activity.id || 0,
      data: {
        assignedUser: null,
      },
    });
    closeSnackbar();
  };

  if (!agents || !activity) {
    return null;
  }

  const handleAssignedUserChange = (event: SyntheticEvent, value: AutoCompleteOptionWithId | null) => {
    if (!value) {
      return;
    }
    setAssignedUser(value.id);

    updateMutation
      .mutateAsync({
        uri: "activities",
        id: activity.id || 0,
        data: {
          assignedUser: value.id,
        },
      })
      .then(() => {
        updateActivities();
        enqueueSnackbar("Änderung wurde gespeichert", {
          variant: "custom",
          buttonFunction: () => revertUserAssignment(),
          buttonType: "function",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
  };

  const options = getOptions(agents);

  const value = _.find(options, (option) => option.id === assignedUser);

  return (
    <>
      {activity.assignedUser ? (
        activity.assignedUser.person.fullname
      ) : (
        <Box sx={{ width: 180 }}>
          <LegalbirdAutoComplete<AutoCompleteOptionWithId>
            label="Zu erledigen durch"
            value={value}
            getOptionDisabled={(option) => option.disabled || false}
            options={options}
            groupBy={(option) => option.groupBy || ""}
            setValue={handleAssignedUserChange}
          />
        </Box>
      )}
    </>
  );
};

const getOptions = (agents: BackofficeUser[]) => {
  const users = _.map(sortByRole(agents), (agent) => {
    return {
      label: agent.person.fullname,
      id: agent["@id"],
      groupBy: getRoleToDisplay(agent),
    };
  });

  return [{ label: "Nicht zugeordnet", id: "not_assigned", disabled: true }, ...users];
};

export default AssignedUser;
