import useProductParams from "../hooks/useProductParams";
import React from "react";
import CaseProvider from "../provider/CaseProvider";
import CaseRelatedActions from "../components/Case/CaseRelatedActions/CaseRelatedActions";
import { Route, Routes } from "react-router-dom";
import CallcenterCasePage from "../components/_Pages/CallCenterPages/CallcenterCasePage/CallcenterCasePage";

const CallcenterCaseRoutes = () => {
  const { productId, productUrlPart } = useProductParams();
  if (!productUrlPart) {
    return null;
  }

  return (
    <CaseProvider caseUrlPart={productUrlPart} caseId={productId}>
      <CaseRelatedActions />

      <Routes>
        <Route path={"/"} element={<CallcenterCasePage />} />
      </Routes>
    </CaseProvider>
  );
};

export default CallcenterCaseRoutes;
