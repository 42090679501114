import { previewCaseFile } from "../../services/Files/fileService";
import React, { useState } from "react";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import previewButtonStyle from "./previewButtonStyle";
import { Box } from "@mui/material";
import { AbstractCase } from "../../types/AbstractCase";

type PreviewButtonProps = {
  product: AbstractCase;
  documentIdentifier: string;
  documentType?: "pdf" | "email" | "message";
  previewFunction?: Function;
  buttonText: string;
  modalHeadline?: string;
  modalSize?: "xs" | "md" | "xl";
  buttonProps?: object;
};

const PreviewButton = ({
  product,
  documentType = "pdf",
  documentIdentifier,
  previewFunction = previewCaseFile,
  buttonText = "Vorschau Dokument",
  modalHeadline = "Vorschau Dokument",
  modalSize = "xl",
  buttonProps = {},
}: PreviewButtonProps) => {
  const [open, setIsOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const openPreview = async () => {
    setIsLoading(true);
    const base64src = await previewFunction({ product, documentType, documentIdentifier });
    setSrc(base64src);
    setIsOpen(true);
    setIsLoading(false);
  };

  return (
    <>
      <ButtonLoading onClick={openPreview} isLoading={isLoading} {...buttonProps}>
        {buttonText}
      </ButtonLoading>
      <LegalbirdIoModal
        handleClose={() => setIsOpen(false)}
        open={open}
        title={modalHeadline}
        hasActions={false}
        maxWidth={modalSize}
      >
        <Box sx={previewButtonStyle.imageContainer}>
          <Box sx={previewButtonStyle.embedContainer}>
            <embed src={src} type="application/pdf" width="100%" height="100%" />
          </Box>
        </Box>
      </LegalbirdIoModal>
    </>
  );
};

export default PreviewButton;
