import React from "react";
import _ from "lodash";
import moment from "moment";
import ActivityForm from "./ActivityForm";
import { EditorState } from "draft-js";
import { editorStateToHTML } from "../../../services/Editor/editorFunctions";
import { useMutation } from "@tanstack/react-query";
import { createResource } from "../../../services/ReactQuery/reactQueryService";
import { AbstractCase } from "../../../types/AbstractCase";
import { UseFormProps } from "../../../hooks/useForm";

type CreateActivityProps = {
  open: boolean;
  closeDialog: Function;
  product: AbstractCase;
  caseId: string;
  updateActivities: () => void;
  defaultAssignedUser?: string;
};

const CreateActivity = ({
  open,
  closeDialog,
  product,
  caseId,
  updateActivities,
  defaultAssignedUser,
}: CreateActivityProps) => {
  const createMutation = useMutation(createResource);

  const onSubmit: UseFormProps["onSubmit"] = async ({ values }) => {
    let activityData = _.merge({}, values);
    activityData.dueTime = activityData.dueTime !== "" ? activityData.dueTime : "allDay";
    activityData.case = caseId;
    activityData.note = editorStateToHTML(activityData.note, true);
    activityData.mandatory = activityData.mandatory === "true";
    await createMutation.mutateAsync({ uri: "activities", data: activityData });
    updateActivities();
    closeDialog();
  };

  const baseValues = {
    type: "no_type",
    subject: "",
    dueDate: moment(),
    dueTime: "",
    mandatory: "false",
    assignedUser: defaultAssignedUser || "",
    note: EditorState.createEmpty(),
  };

  return (
    <ActivityForm
      open={open}
      initialValues={baseValues}
      onSubmit={onSubmit}
      closeDialog={closeDialog}
      product={product}
      productClassName={product.productClassName}
      title={"Aktivität erstellen"}
    />
  );
};

export default CreateActivity;
