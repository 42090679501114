import React from "react";
import humanResourceManagementStyle from "./humanResourceManagementStyle";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import { Calendar, DateObject } from "react-multi-date-picker";
import useStyles from "./calendarStyle";
import Grid from "@mui/material/Grid";
import moment from "moment";
import AbsenceRow from "./AbsenceRow";
import { UseForm } from "../../types/UseForm";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import { FormAbsence } from "../../types/Absence";
import HumanResource from "../../types/HumanResource";

type UserPresenceConfiguratorFormElementsProps = {
  useFormProps: UseForm;
  absentUserId: number;
  humanResources: Array<HumanResource>;
};

const UserAbsenceFormElements = ({
  useFormProps,
  absentUserId,
  humanResources,
}: UserPresenceConfiguratorFormElementsProps) => {
  const calendarClasses = useStyles();

  const calendarToAugmentedAbsences = (
    dates: Array<DateObject> | DateObject | null,
    previousAbsences: Array<FormAbsence>
  ) => {
    if (!dates) {
      return [];
    }

    const datesToHandle = Array.isArray(dates) ? dates : [dates];
    return datesToHandle.map((date) => {
      const correspondingAbsence = previousAbsences.find(
        (previousAbsence) => date.format("YYYY-MM-DD") === previousAbsence.absenceDate.format("YYYY-MM-DD")
      );

      if (correspondingAbsence) {
        return {
          absenceDate: date,
          standIn1: correspondingAbsence.standIn1,
          standIn2: correspondingAbsence.standIn2,
          standIn3: correspondingAbsence.standIn3,
        };
      }

      return {
        absenceDate: date,
        standIn1: null,
        standIn2: null,
        standIn3: null,
      };
    });
  };

  const sortComparisonFunction = (absenceA: FormAbsence, absenceB: FormAbsence) => {
    if (absenceA.absenceDate.format("YYYY-MM-DD") > absenceB.absenceDate.format("YYYY-MM-DD")) {
      return 1;
    }
    if (absenceA.absenceDate.format("YYYY-MM-DD") < absenceB.absenceDate.format("YYYY-MM-DD")) {
      return -1;
    }
    return 0;
  };

  const futureAbsences = useFormProps.values.absences
    .filter((absence: FormAbsence) => moment(absence.absenceDate.format("YYYY-MM-DD")).isSameOrAfter(new Date(), "day"))
    .sort(sortComparisonFunction);
  const { backofficeUsers } = useBackofficeUser();

  const absentUser = backofficeUsers.find((user) => user.id === absentUserId);

  return (
    <>
      <Stack alignItems={"center"}>
        <Typography variant="h4" sx={humanResourceManagementStyle.subHeadline}>
          Abwesenheit eintragen
        </Typography>
        <Calendar
          className={calendarClasses.calender}
          value={useFormProps.values.absences.map((absence: { absenceDate: DateObject }) => absence.absenceDate)}
          onChange={(dates) =>
            useFormProps.handleChange({
              target: {
                name: "absences",
                value: calendarToAugmentedAbsences(dates, useFormProps.values.absences),
              },
            })
          }
          currentDate={new DateObject()}
          showOtherDays
          weekStartDayIndex={1}
          weekDays={["S", "M", "D", "M", "D", "F", "S"]}
          months={[
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
          ]}
          mapDays={({ date }) => {
            const isWeekend = _.includes([0, 6], date.weekDay.index);
            if (isWeekend) {
              return {
                disabled: true,
              };
            }

            const standInsAtCurrentDate = humanResources
              .find(
                (humanResource) =>
                  humanResource.backofficeUser && humanResource.backofficeUser === "/backoffice_users/" + absentUserId
              )
              ?.standIns?.filter(
                (standIn) => new DateObject(standIn.absenceDate).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
              );

            if (!standInsAtCurrentDate || standInsAtCurrentDate.length === 0) {
              return;
            }

            return {
              disabled: true,
              title: standInsAtCurrentDate.map((standIn) => standIn.absentUserFullName).join(", "),
            };
          }}
        />
      </Stack>
      {absentUser && userHasRole(absentUser, Roles.lawyer) && futureAbsences.length > 0 && (
        <Grid container alignItems={"center"} spacing={2} sx={{ marginTop: 4 }}>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center" }} variant={"h4"}>
              Vertretungen für Abwesenheiten eintragen
            </Typography>
          </Grid>
          {futureAbsences.map((absence: FormAbsence) => (
            <AbsenceRow
              key={absence.absenceDate.format("YYYY-MM-DD")}
              absenceDateString={absence.absenceDate.format("YYYY-MM-DD")}
              handleChange={useFormProps.handleChange}
              allAbsences={useFormProps.values.absences}
              absentUserId={absentUserId}
              humanResources={humanResources}
            />
          ))}
        </Grid>
      )}
    </>
  );
};

export default UserAbsenceFormElements;
