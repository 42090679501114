import React, { useContext } from "react";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../services/ReactQuery/reactQueryService";

interface DocumentClassLabelsAi {
  [identifier: string]: string;
}

interface DocumentClassLabelsAiByProductClass {
  [productClass: string]: DocumentClassLabelsAi;
}

interface DocumentClassLabelAiContextInterface {
  documentClassLabelsAi: DocumentClassLabelsAiByProductClass | null;
  getDocumentClassLabelsAiByProductClass: (a: string) => DocumentClassLabelsAi;
  prepareAndSortLabels: (a: DocumentClassLabelsAi) => { label: string; value: string }[];
  getPreparedDocumentClassLabelsAiByProductAndLabelValue: (
    a: string,
    b: string
  ) => {
    label: string;
    value: string;
  } | null;
  isLoading: boolean;
  translateDocumentClassLabel: (productClassName: string, label: string) => string;
}

const DocumentsClassLabelAiContext = React.createContext<DocumentClassLabelAiContextInterface | null>(null);

export default function DocumentClassLabelAiProvider({ children }: { children?: React.ReactNode }) {
  const { data: documentClassLabelsAi, isLoading } = useQuery(
    queryKeys.all("documentLabels"),
    () => fetchCollection("lb/services/document_labels"),
    {
      retry: false,
    }
  );

  const getDocumentClassLabelsAiByProductClass = (productClassName: string) => {
    if (!documentClassLabelsAi) {
      return null;
    }
    return _.merge(documentClassLabelsAi[productClassName], documentClassLabelsAi.common);
  };

  const prepareAndSortLabels = (labels: DocumentClassLabelsAi) => {
    let productSpecificLabels = _.map(labels, (translation, key) => {
      return {
        label: key === "NO_LABEL" ? translation : key + " - " + translation,
        value: key,
      };
    });

    return _.sortBy(productSpecificLabels, (option) => (option.value === "NO_LABEL" ? "0" : option.label));
  };

  const getPreparedDocumentClassLabelsAiByProductAndLabelValue = (productClassName: string, label: string) => {
    if (
      !documentClassLabelsAi ||
      !documentClassLabelsAi[productClassName] ||
      !documentClassLabelsAi[productClassName][label]
    ) {
      return null;
    }
    return {
      label:
        label === "NO_LABEL"
          ? documentClassLabelsAi[productClassName][label]
          : label + " - " + documentClassLabelsAi[productClassName][label],
      value: label,
    };
  };

  const translateDocumentClassLabel = (productClassName: string, label: string) => {
    const labels = getDocumentClassLabelsAiByProductClass(productClassName);
    if (!labels) {
      return "";
    }
    if (!labels[label]) {
      return `Übersetzung fehlt: ${label}`;
    }
    return labels[label];
  };

  const data: DocumentClassLabelAiContextInterface = {
    documentClassLabelsAi: documentClassLabelsAi,
    getDocumentClassLabelsAiByProductClass: getDocumentClassLabelsAiByProductClass,
    prepareAndSortLabels: prepareAndSortLabels,
    getPreparedDocumentClassLabelsAiByProductAndLabelValue: getPreparedDocumentClassLabelsAiByProductAndLabelValue,
    isLoading,
    translateDocumentClassLabel,
  };

  return <DocumentsClassLabelAiContext.Provider value={data}>{children}</DocumentsClassLabelAiContext.Provider>;
}

export const useDocumentClassLabelAi = () => {
  const documentClassLabelAiContext = useContext(DocumentsClassLabelAiContext);
  if (!documentClassLabelAiContext) {
    throw new Error("useDocumentClassLabelAi can only be used inside DocumentClassLabelAiProvider");
  }
  return documentClassLabelAiContext;
};
