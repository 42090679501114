import { contractValidators } from "../Contract/contractValidators";
import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";
import { validateFields } from "../statusChangeValidators";
import { rentalContractRequiredFields } from "./rentalContractRequiredFields";

export const rentalContractValidators = {
  ...contractValidators,
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (rentalContractCase: AbstractCase) =>
    validateFields(
      rentalContractRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](rentalContractCase),
      rentalContractCase
    ),
};
