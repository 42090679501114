/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */
import React from "react";
import "./LbEditor.css";

import { Editor } from "react-draft-wysiwyg";

export default function LbEditor({ ...props }) {
  return <Editor {...props} />;
}
