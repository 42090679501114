import React, { useState } from "react";
import ActionButton, { ActionButtonProps } from "../../Actions/ActionButton";
import { caseRelatedActions, caseRelatedAdditionalActions } from "../../../services/navigationActionsService";
import CaseRelatedOverlays from "./CaseRelatedOverlays";
import CaseRelatedContainer from "./CaseRelatedContainer";
import { queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { useCase } from "../../../provider/CaseProvider";

const CaseRelatedActions = () => {
  const currentUser = useCurrentUser();
  const [open, setOpen] = useState("");
  const [messageDrawerOpen, setMessageDrawerOpen] = useState(false);
  const [messageDrawerMaximized, setMessageDrawerMaximized] = useState(false);
  const [useCondensedView, setUseCondensedView] = useState(false);
  const queryClient = useQueryClient();
  const { product, refreshCase } = useCase();

  if (!product) {
    return null;
  }

  const Actions = ({ actions }: { actions: ActionButtonProps[] }) => {
    return (
      <>
        {actions.map((actionProps, index) => (
          <ActionButton key={index} useCondensedView={useCondensedView} {...actionProps} />
        ))}
      </>
    );
  };

  const openAndMaximizeMessageDrawer = () => {
    setMessageDrawerOpen(true);
    setMessageDrawerMaximized(true);
  };

  const refreshActivities = () => {
    queryClient.invalidateQueries(queryKeys.collections("activities"));
  };

  return (
    <div>
      <CaseRelatedContainer
        mainActions={
          <Actions actions={caseRelatedActions(product, setOpen, openAndMaximizeMessageDrawer, currentUser)} />
        }
        moreActions={<Actions actions={caseRelatedAdditionalActions(product, setOpen)} />}
        useCondensedView={useCondensedView}
        setUseCondensedView={setUseCondensedView}
      />
      <CaseRelatedOverlays
        product={product}
        open={open}
        setOpen={setOpen}
        messageDrawerOpen={messageDrawerOpen}
        setMessageDrawerOpen={setMessageDrawerOpen}
        messageDrawerMaximized={messageDrawerMaximized}
        setMessageDrawerMaximized={setMessageDrawerMaximized}
        refreshPage={refreshCase}
        refreshActivities={refreshActivities}
      />
    </div>
  );
};

export default CaseRelatedActions;
