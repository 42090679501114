import React, { ReactElement } from "react";
import Email from "../../Email/Email";
import { Grid } from "@mui/material";
import {
  AccountBalanceOutlined,
  ArrowRightAlt,
  AssignmentTurnedInOutlined,
  Close,
  Create,
  EmailOutlined,
  HourglassEmptyOutlined,
  Launch,
  NoteOutlined,
  OpenInBrowser,
  PersonAdd,
  PrintOutlined,
  SendOutlined,
} from "@mui/icons-material";
import EmojiEvents from "../../../assets/icon/emoji_events-24px.svg";
import CircleIcon from "../../CircleIcon/CircleIcon";
import CaseHistoryActivity from "./customCaseHistoryItemTypes/CaseHistoryActivity";
import Note from "../CaseNote/Note";
import { translate } from "../../../services/Translations/translatorService";
import PaidTaskHistoryLog from "./customCaseHistoryItemTypes/PaidTaskHistoryLog";
import CaseHistoryMediaObject from "./customCaseHistoryItemTypes/CaseHistoryMediaObject";
import CaseHistoryInquiryToCustomer from "./customCaseHistoryItemTypes/CaseHistoryInquiryToCustomer";
import { AbstractCase } from "../../../types/AbstractCase";
import { HistoryLog } from "../../../types/HistoryLog";
import caseHistoryItemStyle from "./caseHistoryItemStyle";
import GenericLogEntry from "./GenericLogEntry";
import CaseHistoryMessage from "./customCaseHistoryItemTypes/CaseHistoryMessage";
import CaseHistoryInvoiceTypeIdentifierChange from "./customCaseHistoryItemTypes/CaseHistoryInvoiceTypeIdentifierChange";
import CaseHistoryInvoiceDelete from "./customCaseHistoryItemTypes/CaseHistoryInvoiceDelete";
import CaseHistoryInvoiceReassign from "./customCaseHistoryItemTypes/CaseHistoryInvoiceReassign";

interface CaseHistoryItemProps {
  historyItem: HistoryLog;
  fetchHistory: Function;
  product: AbstractCase;
}

const CaseHistoryItem = ({ historyItem, fetchHistory, product }: CaseHistoryItemProps): null | ReactElement => {
  const { productClassName } = product;

  switch (historyItem.type) {
    // GENERIC LOG ENTRY DISPLAY COMPONENTS
    case "customerRegistration":
      return (
        <GenericLogEntry
          subject={translate("historyItem.customerRegistration.label")}
          Icon={PersonAdd}
          historyItem={historyItem}
        />
      );
    case "caseCreated":
      return (
        <GenericLogEntry subject={translate("historyItem.caseCreated.label")} Icon={Launch} historyItem={historyItem} />
      );
    case "entityStatusChange":
      return (
        <GenericLogEntry
          subject={translate("historyItem.entityStatusChange.label")}
          Icon={ArrowRightAlt}
          info={
            <div>
              {historyItem.logData.leadStatus?.previous || "Leer"} {" >> "}
              {historyItem.logData.leadStatus?.current || "Leer"}
            </div>
          }
          historyItem={historyItem}
        />
      );
    case "assignedUserChange":
      return (
        <GenericLogEntry
          subject={translate("historyItem.assignedUserChange.label")}
          Icon={Create}
          info={["lawyer", "accountManager"].map((key: string) => {
            if (!(key in historyItem.logData)) return null;
            return (
              <div key={key}>
                {translate(`historyItem.${key}.label`)}:{" "}
                {historyItem.logData[key].previous + " >> " + historyItem.logData[key].current}
              </div>
            );
          })}
          historyItem={historyItem}
        />
      );
    case "customerDataChange":
      return (
        <GenericLogEntry
          subject={translate("historyItem.customerDataChange.label")}
          Icon={Create}
          info={Object.entries(historyItem.logData).map(([key, logItem]: [key: string, logItem: any]) => {
            return (
              <div key={key}>
                {translate(`customer.${key}.label`)}:
                {!!logItem.previous
                  ? translate(`customer.${key}.values.${logItem.previous}`, String(logItem.previous))
                  : "Leer "}
                {" >> "}
                {!!logItem.current
                  ? translate(`customer.${key}.values.${logItem.current}`, String(logItem.current))
                  : "Leer"}
              </div>
            );
          })}
          historyItem={historyItem}
        />
      );
    case "entityDataChange":
      return (
        <GenericLogEntry
          subject={translate("historyItem.entityDataChange.label")}
          Icon={Create}
          info={Object.entries(historyItem.logData).map(([key, value]: [key: string, value: any]) => {
            return (
              <div key={key}>
                {translate(`${productClassName}.${key}.label`)}:{" "}
                {!!value.previous
                  ? translate(`${productClassName}.${key}.values.${value.previous}`, String(value.previous))
                  : "Leer "}
                {" >> "}
                {!!value.current
                  ? translate(`${productClassName}.${key}.values.${value.current}`, String(value.current))
                  : "Leer"}
              </div>
            );
          })}
          historyItem={historyItem}
        />
      );
    case "fax":
      return historyItem.logData.fax ? (
        <GenericLogEntry
          subject={(() => {
            switch (historyItem.logData.fax.status) {
              case "INIT":
                return `Fax (${historyItem.logData.fax.sessionId}) an +49${historyItem.logData.fax.targetNumber} abgeschickt`;
              case "FAILED":
                return `Fax (${historyItem.logData.fax.sessionId}) an +49${historyItem.logData.fax.targetNumber} konnte nicht zugestellt werden`;
              case "SENT":
                return `Fax (${historyItem.logData.fax.sessionId}) an +49${historyItem.logData.fax.targetNumber} wurde zugestellt`;
              default:
                return `Fax (${historyItem.logData.fax.sessionId}) an +49${historyItem.logData.fax.targetNumber}`;
            }
          })()}
          Icon={PrintOutlined}
          historyItem={historyItem}
        />
      ) : null;
    case "beaMessage":
      return (
        <GenericLogEntry
          subject={(() => {
            const baseSubject = `beA ${historyItem.logData.beaMessage!.beaMessageId} (${
              historyItem.logData.beaMessage!.subject
            }) an ${historyItem.logData.recipientName}, ${historyItem.logData.beaMessage!.recipient.safeId} wurde `;

            switch (historyItem.logData.beaMessage!.transmissionDirection) {
              case "sent":
                return baseSubject + "abgeschickt";
              case "received":
                return baseSubject + "zugestellt";
              default:
                return "";
            }
          })()}
          Icon={SendOutlined}
          historyItem={historyItem}
        />
      );
    case "beaOrFaxPause":
      return (
        <GenericLogEntry
          subject={`Automatische beA/Faxe ${
            historyItem.logData.preventAllSystemBeaOrFax ? "pausiert" : "wieder angeschaltet"
          }`}
          Icon={SendOutlined}
          historyItem={historyItem}
        />
      );
    case "passwordReset":
      return (
        <GenericLogEntry subject={"Passwort-Setzen-E-Mail versendet"} Icon={EmailOutlined} historyItem={historyItem} />
      );

    case "activitiesPause":
      return (
        <GenericLogEntry
          subject={`Setzen von Aktivitäten ${
            historyItem.logData.preventAllSystemActivities ? "pausiert" : "wieder angeschaltet"
          }`}
          Icon={AssignmentTurnedInOutlined}
          historyItem={historyItem}
        />
      );
    case "caseStatusChange":
      switch (historyItem.logData.caseStatus?.current) {
        case "inactive":
          return (
            <GenericLogEntry
              subject={translate("historyItem.caseStatusChange.inactive.label")}
              Icon={HourglassEmptyOutlined}
              historyItem={historyItem}
            />
          );
        case "lost":
          return (
            <GenericLogEntry
              subject={translate("historyItem.caseStatusChange.lost.label")}
              Icon={Close}
              info={
                historyItem.logData.caseStatus?.current === "lost" && (
                  <>
                    {historyItem.logData?.lostCategory?.current && (
                      <div>
                        Verlustkategorie:{" "}
                        {translate(
                          "historyItem.caseStatusChange.lost.lostCategory." + historyItem.logData.lostCategory.current
                        )}
                      </div>
                    )}
                    {historyItem.logData?.lostReason?.current && (
                      <div>Verlustgrund: {historyItem.logData.lostReason.current}</div>
                    )}
                  </>
                )
              }
              historyItem={historyItem}
            />
          );
        case "won":
          return (
            <GenericLogEntry
              subject={translate("historyItem.caseStatusChange.won.label")}
              Icon={EmojiEvents}
              iconType={"iconFile"}
              historyItem={historyItem}
            />
          );
        case "open":
          return (
            <GenericLogEntry
              subject={translate("historyItem.caseStatusChange.open.label")}
              Icon={OpenInBrowser}
              historyItem={historyItem}
            />
          );
        default:
          return null;
      }
    // HISTORY LOGS EXTRACTED FROM 'entityDataChange' ONES (SPOOFED)
    case "accountingPause":
      return (
        <GenericLogEntry
          subject={`Automatische Konto/Rechnungen ${
            historyItem.logData.preventAccounting!.current ? "pausiert" : "wieder angeschaltet"
          }`}
          Icon={AccountBalanceOutlined}
          historyItem={historyItem}
        />
      );

    case "mailPause":
      return (
        <GenericLogEntry
          subject={`Automatische Emails und Nachrichten an die Mandantschaft ${
            historyItem.logData.preventAllSystemEmails!.current ? "pausiert" : "wieder angeschaltet"
          }`}
          Icon={EmailOutlined}
          historyItem={historyItem}
        />
      );
    case "invoiceTypeIdentifierChange":
      return <CaseHistoryInvoiceTypeIdentifierChange productClassName={productClassName} historyItem={historyItem} />;
    case "invoiceDelete":
      return <CaseHistoryInvoiceDelete historyItem={historyItem} />;
    case "invoiceReassign":
      return <CaseHistoryInvoiceReassign historyItem={historyItem} />;
    case "inquiryToCustomer":
      return <CaseHistoryInquiryToCustomer historyLog={historyItem} />;
    case "activity":
      return <CaseHistoryActivity productClassName={productClassName} historyItem={historyItem} />;
    case "paidTask":
      return historyItem.paidTask ? <PaidTaskHistoryLog paidTask={historyItem.paidTask} /> : <></>;
    case "emailMessage":
      return <Email isNonInteractive={true} emailMessage={historyItem.emailMessage!} product={product} />;
    case "message":
      return (
        <CaseHistoryMessage
          message={historyItem.logData.message!}
          conversationSubject={historyItem.logData.conversationSubject!}
        />
      );
    case "note":
      return (
        <Grid container>
          <Grid sx={caseHistoryItemStyle.imageGridItem} item>
            <CircleIcon icon={<NoteOutlined />} type={"muiIcon"} />
          </Grid>
          <Grid item sx={caseHistoryItemStyle.noteGridItem}>
            <Note note={historyItem.note} handleChange={fetchHistory} product={product} />
          </Grid>
        </Grid>
      );
    case "mediaObject":
    case "mediaObjectDelete":
    case "mediaObjectIdentifierChange":
      return <CaseHistoryMediaObject historyLog={historyItem} />;
    default:
      return null;
  }
};

export default CaseHistoryItem;
