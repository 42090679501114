import React from "react";
import AllDataContact from "../../../Form/AllDataContact";
import useStyles from "./caseFullFormStyle";
import SubForm from "./SubForm";
import ScrollTopButton from "../../../Button/ScrollTopButton";
import _ from "lodash";
import { translate } from "../../../../services/Translations/translatorService";
import { getFullFormDefinition } from "../../../../services/Product/ProductService";
import ContentBox from "../../../ContentBox/ContentBox";
import { AbstractCase } from "../../../../types/AbstractCase";
import { FullFormDefinition } from "../../../../types/FormDefinitions/FullFormDefinition";

type CaseFullFormProps = {
  product: AbstractCase;
  contactHeadline?: string;
  formDisabled?: boolean;
  refreshPage: Function;
};
const CaseFullForm = ({
  product,
  contactHeadline = "Kontaktdaten",
  formDisabled = false,
  refreshPage,
}: CaseFullFormProps) => {
  const classes = useStyles({});
  const formDefinition: FullFormDefinition = getFullFormDefinition(product);

  return (
    <>
      <div className={classes.scrollToAnchor} id={"contact"} />
      <ContentBox headline={contactHeadline} headlineVariant="h2">
        <AllDataContact formDisabled={formDisabled} />
      </ContentBox>
      {_.map(formDefinition, (currentFormDefinition, key) => {
        return (
          <React.Fragment key={key}>
            <div className={classes.scrollToAnchor} id={key} />
            <SubForm
              headline={translate("allDataForm." + key)}
              formDefinition={currentFormDefinition}
              product={product}
              formDisabled={formDisabled}
              refreshPage={refreshPage}
            />
          </React.Fragment>
        );
      })}
      <ScrollTopButton />
    </>
  );
};


export default CaseFullForm;
