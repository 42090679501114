export const deleteCaseOrAccountModalStyle = {
  warning: {
    color: "red",
    border: "1px solid red",
    borderRadius: "5px",
    marginY: "1rem",
    padding: "1rem",
    textAlign: "center",
  },
};
