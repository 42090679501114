import { useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import RightBarPlaceholder from "../../Layout/RightBarPlaceholder";
import { translate } from "../../../services/Translations/translatorService";
import { useParams } from "react-router-dom";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import ExternalApplication from "../../ExternalApplications/ExternalApplication";
import { ExternalLawyerApplication } from "../../../types/ExternalLawyerApplication";
import ApiClient from "../../../services/ApiClient";

const dummyApplication = {
  deniedDate: null,
  checkedBy: null,
};

export default function ExternalApplicationPage() {
  const { applicationId } = useParams();
  const [application, setApplication] = useState<ExternalLawyerApplication | null>(null);
  useEffect(() => {
    ApiClient.get("external_lawyer_applications/" + applicationId).then(
      (apiResponse) => setApplication(apiResponse)
    );
  }, [applicationId]);

  if (!application) {
    return <LegalbirdLoader centered />;
  }

  return (
    <>
      <RightBarPlaceholder />
      <PageContentContainer>
        <PageHeadline
          main={"Bewerbung Partneranwaltschaft von"}
          sub={`${translate("general.petitioner.gender.values." + application.personalData.gender)} ${application.personalData.fullName}`}
        />
        <ExternalApplication application={application} />
      </PageContentContainer>
    </>
  );
}
