import React, { ReactElement } from "react";
import { isMobile } from "react-device-detect";
import Layout from "../components/Layout/Layout";
import LayoutMobile from "../mobileComponents/Layout/Layout";
import LayoutExternal from "../components/Externals/Layout/Layout";
import LayoutExternalMobile from "../mobileComponents/Externals/Layout/Layout";

type LayoutDeviceBasedProps = {
  children: ReactElement | ReactElement[];
  isExternal?: boolean;
};

export default function LayoutDeviceBased({ children, isExternal = false }: LayoutDeviceBasedProps) {
  if (isExternal) {
    return isMobile ? (
      <LayoutExternalMobile>{children}</LayoutExternalMobile>
    ) : (
      <LayoutExternal>{children}</LayoutExternal>
    );
  }

  return isMobile ? <LayoutMobile>{children}</LayoutMobile> : <Layout>{children}</Layout>;
}
