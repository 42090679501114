import * as stagesDivorce from "../../Stages/StagesDivorce";
import { AbstractCase } from "../../../types/AbstractCase";

export const divorceRequiredFields = {
  [stagesDivorce.STAGE_INCOMPLETE]: (divorce: AbstractCase) => {
    return [];
  },
  [stagesDivorce.STAGE_READY]: (divorce: AbstractCase) => {
    return [];
  },
  [stagesDivorce.STAGE_CHECKED]: (divorce: AbstractCase) => {
    let requiredFields = ["netIncomePetitioner", "netIncomePartner", "marriageRegistrationNumber", "responsibleLawyer"];

    if (divorce.hasProcessCostSupport) {
      requiredFields.push("processCostSupportVerified");
    }

    return requiredFields;
  },
  [stagesDivorce.STAGE_PROCESS_PAID]: (divorce: AbstractCase) => {
    return [];
  },
  [stagesDivorce.STAGE_SUBMITTED]: (divorce: AbstractCase) => {
    return [];
  },
  [stagesDivorce.STAGE_JUDICIAL_PROCESS]: (divorce: AbstractCase) => {
    return ["referenceCourt"];
  },
  [stagesDivorce.STAGE_DATE_SET]: (divorce: AbstractCase) => {
    return ["referenceCourt"];
  },
  [stagesDivorce.STAGE_HEARING_PAID]: (divorce: AbstractCase) => {
    return [];
  },
  [stagesDivorce.STAGE_DIVORCE_DONE]: (divorce: AbstractCase) => {
    return ["valueInDisputeReal"];
  },
  [stagesDivorce.STAGE_COMPLETED]: (divorce: AbstractCase) => {
    return [];
  },
};
