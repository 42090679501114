import React, { MouseEvent, useState } from "react";
import { IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ButtonLoading from "../Button/ButtonLoading";

import { useSnackbar } from "notistack";
import _ from "lodash";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { useWatch } from "react-hook-form-mui";
import { apiPost, apiToken } from "../../services/Api/apiCall";

export default function EmailChangeModal({
  setIsEmailModalOpen,
  isEmailModalOpen,
}: {
  setIsEmailModalOpen: Function;
  isEmailModalOpen: boolean;
}) {
  const newEmail = useWatch({ name: "email" });
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  const handleSubmit = (e: MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsLoading(true);
    apiToken({ email: currentUser.email, password: password }).then(
      () => {
        apiPost("change_user_email", { newEmail: _.toLower(newEmail) }).then(() => {
          createSnackbar("Es wurde Ihnen eine Bestätigungsmail zugesendet");
          setIsLoading(false);
          setIsEmailModalOpen(false);
        });
      },
      () => {
        createSnackbar("Das eingegebene Passwort ist falsch");
        setIsLoading(false);
      }
    );
  };

  const createSnackbar = (snackbarMessage: string) => {
    enqueueSnackbar(snackbarMessage, {
      variant: "custom",
      isNonInteractive: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  return (
    <LegalbirdIoModal
      handleClose={() => setIsEmailModalOpen(false)}
      open={isEmailModalOpen}
      title={"E-Mail-Adresse ändern"}
      hideCancelButton
      submitButton={
        <ButtonLoading color="primary" variant="contained" onClick={handleSubmit} fullWidth isLoading={isLoading}>
          E-Mail-Adresse ändern
        </ButtonLoading>
      }
    >
      <form>
        <Stack spacing={2}>
          <Typography>
            Zu Ihrer Sicherheit: Bitte bestätigen Sie die Änderung der E-Mail-Adresse mit Ihrem Passwort.
          </Typography>
          <TextField
            fullWidth={true}
            type={showPassword ? "text" : "password"}
            label="Passwort"
            name="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography>
            Sie müssen die neue E-Mail-Adresse noch bestätigen, bevor die Änderung endgültig übernommen wird. Hierzu
            erhalten Sie eine E-Mail von uns.
          </Typography>
        </Stack>
      </form>
    </LegalbirdIoModal>
  );
}
