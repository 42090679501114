import { ReactElement, useEffect, useState } from "react";
import { caseHistoryPageStyle } from "./caseHistoryPageStyle";
import PageContentContainer from "../../Container/PageContentContainer";
import { Box, CircularProgress } from "@mui/material";
import CaseHistoryFilter, { TimespanFilter } from "../../Case/CaseHistory/CaseHistoryFilter";
import ApiClient from "../../../services/ApiClient";
import _ from "lodash";
import CaseHistoryItem from "../../Case/CaseHistory/CaseHistoryItem";
import { prepareHistory } from "../../../services/historyService";
import ButtonLoading from "../../Button/ButtonLoading";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import ContentBox from "../../ContentBox/ContentBox";
import { useCase } from "../../../provider/CaseProvider";
import InvoiceTypeIdentifiersProvider from "../../../provider/InvoiceTypeIdentifiersProvider";
import DocumentClassLabelAiProvider from "../../../provider/DocumentClassLabelAiProvider";
import ActionIdentifierProvider from "../../../provider/ActionIdentifierProvider";

function filterToParams(filter: TimespanFilter) {
  if (typeof filter === "object") {
    return `logDate[after]=${filter.startDate.format("YYYY-MM-DD")}&logDate[before]=${filter.endDate.format(
      "YYYY-MM-DD"
    )}T23:59:59`;
  }
  switch (filter) {
    case "all":
      return "";
    case "changes":
      return "type[]=entityDataChange&type[]=entityStatusChange&type[]=customerRegistration&type[]=caseCreated&type[]=customerDataChange&type[]=invoiceTypeIdentifierChange";
    case "mediaObject":
      return "type[]=mediaObject&type[]=mediaObjectDelete&type[]=mediaObjectIdentifierChange&";
    case "emailMessage":
      return "type[]=customerDataChange&type[]=passwordReset";
    default:
      return `type=${filter}`;
  }
}

const CaseHistoryPage = (): ReactElement | null => {
  const { product, isLoading: productIsLoading } = useCase();
  const [filter, setFilter] = useState<TimespanFilter>("all");
  const [updateCounter, setUpdateCounter] = useState(1);
  const [history, setHistory] = useState([]);
  const [historyCallResult, setHistoryCallResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const dataSource =
    !!product &&
    `backofficeCase=${product.backofficeCase["@id"]}&page=1&updateCounter=${updateCounter}&` + filterToParams(filter);

  async function fetchHistoryLogs() {
    if (!dataSource) return;
    setIsLoading(true);
    const historyLogs = await ApiClient.get("history_logs?" + dataSource);
    setHistory(historyLogs["hydra:member"]);
    setHistoryCallResult(historyLogs);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchHistoryLogs();
  }, [dataSource]);

  if (!product) {
    return null;
  }
  const handleMoreButton = async () => {
    if (!historyCallResult || !historyCallResult["hydra:view"]["hydra:next"]) {
      return;
    }
    const url = historyCallResult["hydra:view"]["hydra:next"].substring(1);
    setIsLoadingMore(true);
    const result = await ApiClient.get(
      `${url}&backofficeCase=${product.backofficeCase["@id"]}&${filterToParams(filter)}`
    );
    setHistoryCallResult(result);
    setHistory([...history, ...result["hydra:member"]] as any);
    setIsLoadingMore(false);
  };

  if (productIsLoading || !historyCallResult) {
    return <LegalBirdPageLoading />;
  }

  let translatedHistory = prepareHistory(history, product.productClassName);
  return (
    <>
      <PageContentContainer size={"small"}>
        <InvoiceTypeIdentifiersProvider>
          <DocumentClassLabelAiProvider>
            <ActionIdentifierProvider>
              <ProductHeadline title="Verlauf von" product={product} />
              <CaseHistoryFilter handleFilterChange={(filter) => setFilter(filter)} filter={filter} />
              <ContentBox>
                <Box sx={caseHistoryPageStyle.historyContainer}>
                  {isLoading ? (
                    <Box sx={caseHistoryPageStyle.spinner}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {translatedHistory.length ? (
                        _.map(translatedHistory, (historyItem, index) => (
                          <Box sx={{ margin: "1.5rem 0" }} key={index}>
                            <CaseHistoryItem
                              fetchHistory={() => setUpdateCounter(updateCounter + 1)}
                              product={product}
                              historyItem={historyItem}
                            />
                          </Box>
                        ))
                      ) : (
                        <Box sx={caseHistoryPageStyle.faded}>Kein Eintrag vorhanden</Box>
                      )}
                    </>
                  )}
                  <ButtonLoading
                    sx={caseHistoryPageStyle.moreButton}
                    onClick={handleMoreButton}
                    isLoading={isLoading}
                    disabled={!historyCallResult["hydra:view"]["hydra:next"] || isLoadingMore}
                  >
                    Mehr Anzeigen
                  </ButtonLoading>
                </Box>
              </ContentBox>
            </ActionIdentifierProvider>
          </DocumentClassLabelAiProvider>
        </InvoiceTypeIdentifiersProvider>
      </PageContentContainer>
    </>
  );
};

export default CaseHistoryPage;
