export const navigationExtensionAllDataStyle = {
  tabs: ({ top }: { top?: number }) => ({
    position: "sticky",
    top: top || 69,
    zIndex: 1099,
    clipPath: "polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%)",
    paddingTop: 1,
  }),
};

export const navigationExtensionEmotionStyle = {
  tabs: ({ top }: { top?: number }) => ({
    position: "sticky",
    top: top || 69,
    zIndex: 1099,
    paddingTop: 1,
  }),
};

export default navigationExtensionAllDataStyle;
