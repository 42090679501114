import React from "react";
import courtHitStyles from "./courtHitStyles";
import { Box, CardActionArea } from "@mui/material";
import _ from "lodash";
import { HitProperties } from "../Case/CaseEntityPicker/Hit/hitType";

const CourtHit = ({ hit, resetHits, onHitClick }: HitProperties) => {
  const highlightResult = hit._highlightResult;
  const courtId = _.replace(hit.objectID, "court::", "");

  if (!highlightResult || !courtId) {
    return null;
  }

  const handleCardClick = () => {
    resetHits();
    onHitClick && onHitClick(courtId);
  };

  return (
    <CardActionArea sx={courtHitStyles.hitContainer} onClick={handleCardClick}>
      <Box sx={courtHitStyles.highlighted}>
        {highlightResult.name && <span dangerouslySetInnerHTML={{ __html: highlightResult.name.value + " " }} />}
      </Box>
      {highlightResult.streetAddress && (
        <Box sx={courtHitStyles.entry} dangerouslySetInnerHTML={{ __html: highlightResult.streetAddress.value }} />
      )}
      {highlightResult.postalCode && (
        <Box
          component={"span"}
          sx={courtHitStyles.entry}
          dangerouslySetInnerHTML={{ __html: highlightResult.postalCode.value + " " }}
        />
      )}
      {highlightResult.addressLocality && (
        <Box
          component={"span"}
          sx={courtHitStyles.entry}
          dangerouslySetInnerHTML={{ __html: highlightResult.addressLocality.value }}
        />
      )}
      {highlightResult.telephone && (
        <Box
          sx={courtHitStyles.entry}
          dangerouslySetInnerHTML={{ __html: "Tel: " + highlightResult.telephone.value }}
        />
      )}
      {highlightResult.fax && (
        <Box sx={courtHitStyles.entry} dangerouslySetInnerHTML={{ __html: "Fax: " + highlightResult.fax.value }} />
      )}
      {highlightResult.postOfficeBoxNumber && (
        <Box
          sx={courtHitStyles.entry}
          dangerouslySetInnerHTML={{ __html: "Postfach: " + highlightResult.postOfficeBoxNumber.value }}
        />
      )}
      {highlightResult.postOfficeBoxPostalCode && (
        <Box
          component={"span"}
          sx={courtHitStyles.entry}
          dangerouslySetInnerHTML={{
            __html: "Postfach PLZ/Ort: " + highlightResult.postOfficeBoxPostalCode.value + " ",
          }}
        />
      )}
      {highlightResult.postOfficeBoxAddressLocality && (
        <Box
          component={"span"}
          sx={courtHitStyles.entry}
          dangerouslySetInnerHTML={{ __html: highlightResult.postOfficeBoxAddressLocality.value }}
        />
      )}
    </CardActionArea>
  );
};

export default CourtHit;
