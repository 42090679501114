/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

// Please keep ordered alphabetically (execpt "custom")
export const objectivesLawFirmValues = {
  acknowledgeClaim: "Anerkennung der Forderung",
  cancellationConfirmation: "Kündigungsbestätigung",
  cancellationObsoleteConfirmation: "Unwirksame Kündigung",
  compensation: "Schadensersatz",
  contestation: "Anfechtung",
  contractNotMaterialized: "Vertrag nicht zustande gekommen",
  contractNotMaterializedPlusAppeal: "Vertrag nicht zustande gekommen (+Anfechtung)",
  dataDeletion: "Datenlöschung",
  fulfillment: "Erfüllung des Vertrags",
  reduction: "Minderung",
  refund: "Erstattung",
  removalOfDefects: "Mängelbeseitigung",
  rentReduction: "Mietminderung",
  repair: "Nachbesserung oder Reparatur",
  replacement: "Nacherfüllung",
  revocation: "Fristgerechter Widerruf Fernabsatz",
  specialTermination: "Sonderkündigung",
  unlawfulSchufaPositiveData: "Widerrechtlicher Positiveintrag",
  willfulDeceptionContestation: "Anfechtung mit argl. Täuschung",
  willfulDeceptionContestationTerminationRevocation: "Argl. Täuschung: Anfechtung, Kündigung, Widerruf",
  withdrawal: "Rücktritt vom Vertrag",
  custom: "Individuell (leer)",
};
