const createCaseFormStyle = () => ({
  container: {
    maxWidth: 1024,
  },
  headline: {
    textAlign: "center",
  },
  formPaper: {
    padding: "2rem 5rem",
  },
  textField: {
    backgroundColor: "#f8f4d0",
  },
});

export default createCaseFormStyle;
