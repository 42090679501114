export const infoBoxStyles = {
  infoBox: {
    border: "1px solid",
    borderColor: "primary.main",
    borderRadius: "5px",
    padding: "1rem",
    textAlign: "center",
    width: "100%",
  },
};
