
import PageHeadline from "../../PageHeadline/PageHeadline";
import PageContentContainer from "../../Container/PageContentContainer";
import ScannedMediaObjectList from "../../ScannedMediaObjectList/ScannedMediaObjectList";

export default function ScannedMediaObjectListPage({}) {
  return (
    <PageContentContainer size={"large"}>
      <PageHeadline main="Dokumente prüfen" />
      <ScannedMediaObjectList/>
    </PageContentContainer>
  );
};
