export const activityFilterChipStyles = {
  chip: {
    padding: '5px',
    height: '48px',
    borderRadius: '24px',
    fontSize: '1rem',
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '0.75rem',
  },
};

export default activityFilterChipStyles;
