export const investigationAssessmentFormStyle = {
  container: {
    padding: "2rem",
    marginTop: "2rem",
    width: "100%",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  headlineContainer: {
    marginBottom: "1rem",
  },
  subHeadline: {
    fontSize: "1rem",
  },
  editorWrapper: {
    padding: "1rem",
  },
  editor: {
    minHeight: "10rem",
  },
};
