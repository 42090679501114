import { scrollToAnchor } from "../../theme/commonStyles";

export const emailsStyle = {
  container: {
    padding: "2rem",
    marginTop: "2rem",
    marginBottom: "2rem",
    width: "100%",
  },
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
    marginBottom: "1rem",
  },
  button: {
    margin: "1rem auto 0 0",
  },
  noMails: {
    textAlign: "center",
    color: "#dcdcdc",
    fontSize: "1.25rem",
    margin: "2rem",
  },
  scrollToAnchor: {
    ...scrollToAnchor,
  },
};
