import { AbstractCase } from "../../../types/AbstractCase";
import {InquiryToCustomer} from "../../../types/InquiryToCustomer";

export const getRequestDataCopyAllowed = (product: AbstractCase, inquiryToCustomers: Array<InquiryToCustomer>) => {
  if (!product.responsibleLawyer) {
    return {
      allowed: false,
      message: "Datenauskunft kann nur gestellt werden, wenn ein Anwalt am Fall hinterlegt wurde.",
    };
  }
  if (inquiryToCustomers.length >= 2) {
    return {
      allowed: false,
      message: "Es sind bereits zwei Anfragen an die Mandantschaft gestellt worden.",
    };
  }
  return { allowed: true };
};
