import { PaidTask } from "../../../types/PaidTask";
import React, { useState } from "react";
import { PAID_TASK_STATUS_ASSIGNED } from "../paidTaskStatus";
import Button from "@mui/material/Button";
import RemindExternalLawyerModal from "./RemindExternalLawyerModal";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function RemindButton({ paidTask }: { paidTask: PaidTask }) {
  const currentUser = useCurrentUser();
  const [modalOpen, setModalOpen] = useState("");

  if (currentUser.isExternal || paidTask.status !== PAID_TASK_STATUS_ASSIGNED) {
    return null;
  }

  return (
    <>
      <Button color={"primary"} onClick={() => setModalOpen("remindLawyer")}>
        Anwalt erinnern
      </Button>
      <RemindExternalLawyerModal
        paidTask={paidTask}
        open={modalOpen === "remindLawyer"}
        closeModal={() => setModalOpen("")}
      />
    </>
  );
}
