import React from "react";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import { Badge as BadgeType } from "../../types/Badge";
import Badge from "./Badge";
import { BadgesListStyle } from "./BadgesListStyle";

type BadgesListProps = {
  badges: BadgeType[];
};

const BadgesList = (props: BadgesListProps) => {
  const { badges } = props;
  return (
    <Grid container sx={BadgesListStyle.container}>
      {_.map(badges, (badge, index) => (
        <Grid item key={index}>
          <Badge isActive={badge.isActive} text={badge.text} linkTo={badge.linkTo} />
        </Grid>
      ))}
    </Grid>
  );
};

export default BadgesList;
