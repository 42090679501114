export const actionButtonStyle = {
  actionTitle: {
    fontSize: "10px",
    textAlign: "center",
    marginTop: "-5px",
    wordBreak: "break-all",
  },
  actionButtonDivider: {
    height: "1px",
    background: "#aaa",
    width: "70%",
    marginTop: "4px",
  },
};
