import { Activity } from "../../types/Activity";
import _ from "lodash";
import React from "react";
import AdvisoryCallWidget from "./AdvisoryCallWidget";
import { Box } from "@mui/material";

type AdvisoryCallListProps = {
  advisoryCalls: Activity[];
  noCallsText: string;
};

export default function AdvisoryCallList({ advisoryCalls, noCallsText }: AdvisoryCallListProps) {
  if (advisoryCalls.length === 0) {
    return <Box sx={{ borderTop: "1px solid #ddd", padding: "1.5rem 0", textAlign: "center" }}>{noCallsText}</Box>;
  }
  return (
    <>
      {_.map(advisoryCalls, (advisoryCall) => (
        <AdvisoryCallWidget key={advisoryCall.id} advisoryCall={advisoryCall} />
      ))}
    </>
  );
}
