import { BookingEntryWithDifference } from "../../types/BookingEntry";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { apiPut } from "../../services/Api/apiCall";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";

type isThirdPartyFundsModalProps = {
  bookingEntry: BookingEntryWithDifference;
  open: boolean;
  handleClose: () => void;
  updateBookingEntries: Function;
};

export default function IsThirdPartyFundsModal({
  bookingEntry,
  open,
  handleClose,
  updateBookingEntries,
}: isThirdPartyFundsModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isThirdPartyFunds, setIsThirdPartyFunds] = useState<string>(
    bookingEntry.isThirdPartyFunds !== undefined ? String(bookingEntry.isThirdPartyFunds) : "null"
  );

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  if (!bookingEntry.productType) {
    return null;
  }

  const saveThirdPartyFunds = async () => {
    try {
      setIsLoading(true);
      await apiPut("/booking_entries", bookingEntry.id, {
        isThirdPartyFunds: isThirdPartyFunds !== "null" ? isThirdPartyFunds === "true" : null,
      });
      await queryClient.invalidateQueries(queryKeys.collections("booking_entries"));
      handleClose();
    } catch (e) {
      enqueueSnackbar("Es ist ein Fehler aufgetreten", {
        variant: "custom",
        isNonInteractive: true,

        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setIsLoading(false);
      updateBookingEntries();
    }
  };

  return (
    <LegalbirdIoModal
      handleClose={() => handleClose()}
      open={open}
      title={"Fremdgeld?"}
      submitButton={
        <ButtonLoading isLoading={isLoading} onClick={saveThirdPartyFunds} variant={"contained"}>
          Speichern
        </ButtonLoading>
      }
    >
      <ValidatorSelect
        label={"Fremdgeld?"}
        value={isThirdPartyFunds}
        onChange={(e: SelectChangeEvent) => setIsThirdPartyFunds(e.target.value)}
      >
        {isThirdPartyFunds === "null" && <MenuItem value={"null"}>-</MenuItem>}
        <MenuItem value={"false"}>Kein Fremdgeld</MenuItem>
        <MenuItem value={"true"}>Fremdgeld</MenuItem>
      </ValidatorSelect>
    </LegalbirdIoModal>
  );
}
