import _ from "lodash";
import { getAdvoAssistRepresentative, isContractProduct } from "../../../services/Product/ProductService";
import { translate } from "../../../services/Translations/translatorService";
import moment from "moment";
import { AbstractCase } from "../../../types/AbstractCase";
import { Address } from "../../../types/Address";
import { Person } from "../../../types/Person";
import { Organization } from "../../../types/Organization";

const isOpponentLawyerDataComplete = (product: AbstractCase) => {
  // Required fields for opponentLawFirm. name is NOT required
  return !!(
    product.opponentLawyer &&
    product.opponentLawFirm &&
    product.opponentLawyer.gender &&
    product.opponentLawyer.givenName &&
    product.opponentLawyer.familyName &&
    product.opponentLawFirm.address &&
    isAddressDataComplete(product.opponentLawFirm.address)
  );
};

const isDebtCollectionAgencyDataComplete = (product: AbstractCase) => {
  return !!(
    product.debtCollectionAgency &&
    product.debtCollectionAgency.name &&
    product.debtCollectionAgency.address &&
    isAddressDataComplete(product.debtCollectionAgency.address)
  );
};

const isContractualPartnerPersonDataComplete = (product: AbstractCase) => {
  // Required fields for contractualPartnerPerson
  return !!(
    product.contractualPartnerPerson &&
    product.contractualPartnerPerson.gender &&
    product.contractualPartnerPerson.givenName &&
    product.contractualPartnerPerson.familyName &&
    product.contractualPartnerPerson.addresses[0] &&
    isAddressDataComplete(product.contractualPartnerPerson.addresses[0])
  );
};

const isPropertyManagerDataComplete = (product: AbstractCase) => {
  return product.propertyManager && isAddressDataComplete(product.propertyManager.address);
};

const isContractualPartnerLegalEntityDataComplete = (product: AbstractCase) => {
  // Required fields for contractualPartnerLegalEntity --> No streetAddress required!
  return !!(
    product.contractualPartnerLegalEntity &&
    product.contractualPartnerLegalEntity.legalName &&
    product.contractualPartnerLegalEntity.address &&
    (isAddressDataComplete(product.contractualPartnerLegalEntity.address, true) ||
      isPostOfficeBoxDataComplete(product.contractualPartnerLegalEntity.address))
  );
};

const isAddressDataComplete = (address: Address, isStreetAddressOptional = false) => {
  return (isStreetAddressOptional || address.streetAddress) && address.addressLocality && address.postalCode;
};

const isPostOfficeBoxDataComplete = (address: Address) =>
  address.postOfficeBoxNumber && address.postOfficeBoxPostalCode && address.postOfficeBoxAddressLocality;

const isContractualPartnerDataComplete = (product: AbstractCase) => {
  if (product.contractualPartnerType === "contractualPartnerLegalEntity") {
    return isContractualPartnerLegalEntityDataComplete(product);
  }
  if (product.contractualPartnerType === "contractualPartnerPerson") {
    return isContractualPartnerPersonDataComplete(product);
  }
};

const getContractualPartnerPersonLetterheadData = (product: AbstractCase) => {
  return {
    gender: product.contractualPartnerPerson.gender,
    name: product.contractualPartnerPerson.givenName + " " + product.contractualPartnerPerson.familyName,
    streetAddress: product.contractualPartnerPerson.residenceAddress.streetAddress,
    postalCode: product.contractualPartnerPerson.residenceAddress.postalCode,
    addressLocality: product.contractualPartnerPerson.residenceAddress.addressLocality,
    postOfficeBoxAddressLocality: product.contractualPartnerPerson.residenceAddress.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.contractualPartnerPerson.residenceAddress.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.contractualPartnerPerson.residenceAddress.postOfficeBoxPostalCode,
  };
};

const getContractualPartnerLegalEntityLetterheadData = (product: AbstractCase) => {
  return getOrganizationLetterheadData("contractualPartnerLegalEntity", product);
};

const getOrganizationLetterheadData = (path: string, product: AbstractCase) => {
  return {
    firm: _.get(product, path + ".legalName"),
    streetAddress: _.get(product, path + ".address.streetAddress"),
    addressAddition: _.get(product, path + ".address.addressAddition"),
    postalCode: _.get(product, path + ".address.postalCode"),
    addressLocality: _.get(product, path + ".address.addressLocality"),
    postOfficeBoxAddressLocality: _.get(product, path + ".address.postOfficeBoxAddressLocality"),
    postOfficeBoxNumber: _.get(product, path + ".address.postOfficeBoxNumber"),
    postOfficeBoxPostalCode: _.get(product, path + ".address.postOfficeBoxPostalCode"),
  };
};

const getContractualPartnerLetterheadData = (product: AbstractCase) => {
  if (product.contractualPartnerType === "contractualPartnerLegalEntity") {
    return getContractualPartnerLegalEntityLetterheadData(product);
  }
  if (product.contractualPartnerType === "contractualPartnerPerson") {
    return getContractualPartnerPersonLetterheadData(product);
  }
};

const getAuthorityLetterheadData = (product: AbstractCase) => {
  return {
    name: product.responsibleAuthority.name,
    department: product.responsibleAuthority.department,
    subDepartment: product.responsibleAuthority.subDepartment,
    streetAddress: product.responsibleAuthority.address.streetAddress,
    postalCode: product.responsibleAuthority.address.postalCode,
    addressLocality: product.responsibleAuthority.address.addressLocality,
    postOfficeBoxAddressLocality: product.responsibleAuthority.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.responsibleAuthority.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.responsibleAuthority.address.postOfficeBoxPostalCode,
  };
};

const getProsecutionLetterheadData = (product: AbstractCase) => {
  const responsibleProsecution = _.includes(["traffic"], product.productClassName)
    ? product.responsibleProsecution
    : product.responsibleProsecution;

  return {
    name: responsibleProsecution.name,
    department: responsibleProsecution.department,
    subDepartment: responsibleProsecution.subDepartment,
    streetAddress: responsibleProsecution.address.streetAddress,
    postalCode: responsibleProsecution.address.postalCode,
    addressLocality: responsibleProsecution.address.addressLocality,
    postOfficeBoxAddressLocality: responsibleProsecution.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: responsibleProsecution.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: responsibleProsecution.address.postOfficeBoxPostalCode,
  };
};

const getPetitionerLetterheadData = (product: AbstractCase) => {
  return getPersonLetterheadData("client", product);
};

const getPersonLetterheadData = (path: string, product: AbstractCase) => {
  return {
    gender: _.get(product, path + ".gender"),
    name: _.get(product, path + ".givenName") + " " + _.get(product, path + ".familyName"),
    streetAddress: _.get(product, path + ".residenceAddress.streetAddress"),
    postalCode: _.get(product, path + ".residenceAddress.postalCode"),
    addressLocality: _.get(product, path + ".residenceAddress.addressLocality"),
    postOfficeBoxAddressLocality: _.get(product, path + ".residenceAddress.postOfficeBoxAddressLocality"),
    postOfficeBoxNumber: _.get(product, path + ".residenceAddress.postOfficeBoxNumber"),
    postOfficeBoxPostalCode: _.get(product, path + ".residenceAddress.postOfficeBoxPostalCode"),
  };
};
const getLegalExpenseInsuranceLetterheadData = (product: AbstractCase) => {
  return {
    firm: product.insurance.insurance.name,
    streetAddress: product.insurance.insurance.address.streetAddress,
    postalCode: product.insurance.insurance.address.postalCode,
    addressLocality: product.insurance.insurance.address.addressLocality,
    postOfficeBoxAddressLocality: product.insurance.insurance.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.insurance.insurance.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.insurance.insurance.address.postOfficeBoxPostalCode,
  };
};
const getEmployerOrganizationLetterheadData = (product: AbstractCase) => {
  return {
    firm: product.employerOrganization.name,
    streetAddress: product.employerOrganization.address.streetAddress,
    postalCode: product.employerOrganization.address.postalCode,
    addressLocality: product.employerOrganization.address.addressLocality,
    postOfficeBoxAddressLocality: product.employerOrganization.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.employerOrganization.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.employerOrganization.address.postOfficeBoxPostalCode,
  };
};

const getCourtLetterheadData = (product: AbstractCase) => {
  return {
    firm: product.responsibleCourt.name,
    streetAddress: product.responsibleCourt.address.streetAddress,
    postalCode: product.responsibleCourt.address.postalCode,
    addressLocality: product.responsibleCourt.address.addressLocality,
    postOfficeBoxAddressLocality: product.responsibleCourt.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.responsibleCourt.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.responsibleCourt.address.postOfficeBoxPostalCode,
  };
};

const getOpponentLawFirmLetterheadData = (product: AbstractCase) => {
  return {
    firm: product.opponentLawFirm.name ?? null,
    name:
      (product.opponentLawyer.gender === "female" ? "RAin " : "RA ") +
      product.opponentLawyer.givenName +
      " " +
      product.opponentLawyer.familyName,
    streetAddress: product.opponentLawFirm.address.streetAddress,
    postalCode: product.opponentLawFirm.address.postalCode,
    addressLocality: product.opponentLawFirm.address.addressLocality,
    postOfficeBoxAddressLocality: product.opponentLawFirm.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.opponentLawFirm.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.opponentLawFirm.address.postOfficeBoxPostalCode,
  };
};

const getDebtCollectionAgencyLetterheadData = (product: AbstractCase) => {
  return {
    name: product.debtCollectionAgency.name,
    streetAddress: product.debtCollectionAgency.address.streetAddress,
    postalCode: product.debtCollectionAgency.address.postalCode,
    addressLocality: product.debtCollectionAgency.address.addressLocality,
    postOfficeBoxAddressLocality: product.debtCollectionAgency.address.postOfficeBoxAddressLocality,
    postOfficeBoxNumber: product.debtCollectionAgency.address.postOfficeBoxNumber,
    postOfficeBoxPostalCode: product.debtCollectionAgency.address.postOfficeBoxPostalCode,
  };
};

const getAdvoAssistRepresentativeLetterheadData = (product: AbstractCase) => {
  const advoAssistRepresentative = getAdvoAssistRepresentative(product);

  return {
    name: advoAssistRepresentative.fullName,
    streetAddress: advoAssistRepresentative.residenceAddress.streetAddress,
    postalCode: advoAssistRepresentative.residenceAddress.postalCode,
    addressLocality: advoAssistRepresentative.residenceAddress.addressLocality,
  };
};

const buildAddress = (values: Record<string, any>, product: AbstractCase) => {
  const baseData = {
    firm: null,
    gender: null,
    name: null,
    department: null,
    subDepartment: null,
    streetAddress: null,
    addressAddition: null,
    postalCode: null,
    addressLocality: null,
    postOfficeBoxNumber: null,
    postOfficeBoxPostalCode: null,
    postOfficeBoxAddressLocality: null,
  };

  const recipientType = values.recipientType.split("[")[0];
  switch (recipientType) {
    case "custom":
      return _.merge({}, baseData, values.address);
    case "petitioner":
      return _.merge({}, baseData, getPetitionerLetterheadData(product));
    case "court":
      return _.merge({}, baseData, getCourtLetterheadData(product));
    case "authority":
      return _.merge({}, baseData, getAuthorityLetterheadData(product));
    case "prosecution":
      return _.merge({}, baseData, getProsecutionLetterheadData(product));
    case "contractualPartnerLegalEntity":
      return _.merge({}, baseData, getContractualPartnerLetterheadData(product));
    case "opponentLawFirm":
      return _.merge({}, baseData, getOpponentLawFirmLetterheadData(product));
    case "debtCollectionAgency":
      return _.merge({}, baseData, getDebtCollectionAgencyLetterheadData(product));
    case "advoAssistRepresentative":
      return _.merge({}, baseData, getAdvoAssistRepresentativeLetterheadData(product));
    case "processParticipantsOrganizations":
      return _.merge({}, baseData, getOrganizationLetterheadData(values.recipientType, product));
    case "processParticipantsPeople":
      return _.merge({}, baseData, getPersonLetterheadData(values.recipientType, product));
    case "legalExpenseInsurance":
      return _.merge({}, baseData, getLegalExpenseInsuranceLetterheadData(product));
    case "employerOrganization":
      return _.merge({}, baseData, getEmployerOrganizationLetterheadData(product));
    case "propertyManager":
      return _.merge({}, baseData, getOrganizationLetterheadData("propertyManager", product));

    default:
      return baseData;
  }
};

const getExternalReference = (product: AbstractCase, switchValue: string) => {
  switch (product.productClassName) {
    case "traffic":
      switch (switchValue) {
        case "court":
          return product.courtReference;
        case "authority":
          return product.fineNoticeReference;
        case "prosecution":
          return product.prosecutionReference;
        default:
          return null;
      }
    case "divorce":
      switch (switchValue) {
        case "court":
          return product.referenceCourt;
        case "opponentLawyer":
          return product.referenceOpponentLawyer;
        default:
          return null;
      }
    case "settlement":
      switch (switchValue) {
        case "court":
          return product.referenceCourt;
        case "opponentLawyer":
          return product.referenceOpponentLawyer;
        default:
          return null;
      }
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "dataLeakContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
      switch (switchValue) {
        case "debtCollectionAgency":
          return product.debtCollectionAgencyReference;
        case "court":
          return product.courtReference;
        case "opponentLawyer":
          return product.referenceOpponentLawyer;
        case "contractNumber":
          return product.contractNumber;
        case "prosecution":
          return product.prosecutionReference;
        default:
          return null;
      }
    case "rentalContract":
      switch (switchValue) {
        case "debtCollectionAgency":
          return product.debtCollectionAgencyReference;
        case "propertyManager":
          return product.propertyManagerReference;
        case "opponentLawyer":
          return product.referenceOpponentLawyer;
        case "court":
          return product.courtReference;
        case "prosecution":
          return product.prosecutionReference;
        default:
          return null;
      }
    default:
      return null;
  }
};

const getExternalReferenceSelectValue = (product: AbstractCase, recipientType: string) => {
  const defaultFieldName = "free";

  switch (recipientType) {
    case "court":
      return product.referenceCourt || product.courtReference ? "court" : defaultFieldName;

    case "contractualPartnerLegalEntity":
      return product.contractNumber ? "contractNumber" : defaultFieldName;

    case "opponentLawyer":
    case "opponentLawFirm":
      return product.referenceOpponentLawyer ? "opponentLawyer" : defaultFieldName;

    case "debtCollectionAgency":
      return product.debtCollectionAgencyReference ? "debtCollectionAgency" : defaultFieldName;

    case "propertyManager":
      return product.propertyManagerReference ? "propertyManager" : defaultFieldName;

    case "authority":
      return product.fineNoticeReference ? "authority" : defaultFieldName;

    case "prosecution":
      return product.prosecutionReference ? "prosecution" : defaultFieldName;

    case "custom":
    case "legalExpenseInsurance":
    case "petitioner":
    case "advoAssistRepresentative":
    default:
      return defaultFieldName;
  }
};

const getReferenceOptions = (product: AbstractCase) => {
  let references = [];

  switch (product.productClassName) {
    case "settlement":
    case "divorce":
      references.push(
        {
          type: "court",
          label: "Aktenzeichen Gericht",
          isHidden: !product.referenceCourt,
        },
        {
          type: "opponentLawyer",
          label: "Aktenzeichen Anwalt Gegenseite",
          isHidden: !product.referenceOpponentLawyer,
        }
      );
      break;
    case "traffic":
      references.push(
        {
          type: "court",
          label: "Aktenzeichen Gericht",
          isHidden: !product.courtReference,
        },
        {
          type: "authority",
          label: "Aktenzeichen Behörde",
          isHidden: !product.fineNoticeReference,
        },
        {
          type: "prosecution",
          label: "Aktenzeichen Staatsanwaltschaft",
          isHidden: !product.prosecutionReference,
        }
      );
      break;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "dataLeakContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
      references.push(
        {
          type: "court",
          label: "Aktenzeichen Gericht",
          isHidden: !product.courtReference,
        },
        {
          type: "debtCollectionAgency",
          label: "Aktenzeichen Inkasso",
          isHidden: !product.debtCollectionAgencyReference,
        },
        {
          type: "opponentLawyer",
          label: "Aktenzeichen Anwalt Gegenseite",
          isHidden: !product.referenceOpponentLawyer,
        },
        {
          type: "contractNumber",
          label: "Vertragsnummer",
          isHidden: !product.contractNumber,
        },
        {
          type: "prosecution",
          label: "Aktenzeichen Staatsanwaltschaft",
          isHidden: !product.prosecutionReference,
        }
      );
      break;
    case "rentalContract":
      references.push(
        {
          type: "propertyManager",
          label: "Aktenzeichen Hausverwaltung",
          isHidden: !product.propertyManagerReference,
        },
        {
          type: "opponentLawyer",
          label: "Aktenzeichen Anwalt Gegenseite",
          isHidden: !product.referenceOpponentLawyer,
        },
        {
          type: "court",
          label: "Aktenzeichen Gericht",
          isHidden: !product.courtReference,
        }
      );
      break;
    default:
      break;
  }

  return references;
};

const getPdfGeneratorRecipients = (product: AbstractCase) => {
  let recipients = [];
  if (product.insurance?.insurance) {
    recipients.push({ value: "legalExpenseInsurance", label: "Rechtsschutzversicherung" });
  }
  if (product.responsibleCourt) {
    recipients.push({ value: "court", label: "Zuständiges Gericht" });
  }
  if (product.responsibleAuthority) {
    recipients.push({ value: "authority", label: "Zuständige Behörde" });
  }
  if (product.responsibleProsecution) {
    recipients.push({ value: "prosecution", label: "Zuständige Staatsanwaltschaft" });
  }
  if (product.employerOrganization) {
    recipients.push({ value: "employerOrganization", label: "Adresse Gegenseite" });
  }

  recipients.push({ value: "petitioner", label: "Mandantschaft" });

  if (isContractProduct(product.productClassName) && isContractualPartnerDataComplete(product)) {
    recipients.push({ value: "contractualPartnerLegalEntity", label: "Gegenseite" });
  }

  if (product.productClassName === "rentalContract" && isPropertyManagerDataComplete(product)) {
    recipients.push({ value: "propertyManager", label: "Hausverwaltung" });
  }
  if (
    (isContractProduct(product.productClassName) ||
      ["settlement", "divorce", "rentalContract"].includes(product.productClassName)) &&
    isOpponentLawyerDataComplete(product)
  ) {
    recipients.push({ value: "opponentLawFirm", label: "Anwalt Gegenseite" });
  }
  if (isContractProduct(product.productClassName) && isDebtCollectionAgencyDataComplete(product)) {
    recipients.push({ value: "debtCollectionAgency", label: "Inkassobüro" });
  }
  if (getAdvoAssistRepresentative(product)) {
    recipients.push({ value: "advoAssistRepresentative", label: "Terminsvertreter" });
  }
  if (!!product.processParticipantsPeople && product.processParticipantsPeople.length > 0) {
    product.processParticipantsPeople.forEach((person: Person, index: number) =>
      recipients.push({
        value: "processParticipantsPeople[" + index + "]",
        label:
          translate("participant.labelType.values." + person.labelType) +
          " - " +
          person.givenName +
          " " +
          person.familyName,
      })
    );
  }
  if (!!product.processParticipantsOrganizations) {
    product.processParticipantsOrganizations.forEach((organization: Organization, index: number) =>
      recipients.push({
        value: "processParticipantsOrganizations[" + index + "]",
        label: translate("participant.labelType.values." + organization.labelType) + " - " + organization.legalName,
      })
    );
  }
  recipients.push({ value: "custom", label: "Empfänger frei wählen" });

  return recipients;
};

const getDocumentName = (recipientType: string) => {
  const date = moment().format("YYYYMMDD");
  const documentName = "_Schreiben";
  let documentSuffix = "";

  switch (recipientType) {
    case "legalExpenseInsurance":
      documentSuffix = "_aRSV";
      break;
    case "court":
      documentSuffix = "_aG";
      break;
    case "contractualPartnerLegalEntity":
      documentSuffix = "_aGs";
      break;
    case "petitioner":
      documentSuffix = "_aM";
      break;
    case "advoAssistRepresentative":
      documentSuffix = "_aTV";
      break;
    case "opponentLawyer":
    case "opponentLawFirm":
      documentSuffix = "_agRA";
      break;
    case "debtCollectionAgency":
      documentSuffix = "_aI";
      break;
    case "propertyManager":
      documentSuffix = "_aHv";
      break;
    case "authority":
      documentSuffix = "_aB";
      break;
    case "prosecution":
      documentSuffix = "_aSta";
      break;
    default:
      break;
  }

  return date + documentName + documentSuffix;
};

export {
  isOpponentLawyerDataComplete,
  isContractualPartnerDataComplete,
  buildAddress,
  getExternalReference,
  getExternalReferenceSelectValue,
  isDebtCollectionAgencyDataComplete,
  getReferenceOptions,
  getPdfGeneratorRecipients,
  getDocumentName,
};
