import React, { ChangeEvent, useState } from "react";
import { bankPaymentEntryRowStyle } from "./bankPaymentEntryRowStyle";
import { Checkbox, Grid, SelectChangeEvent, TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../services/Product/ProductService";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import ApiClient from "../../services/ApiClient";
import ToolTipIconButton from "../Button/ToolTipIconButton";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import SearchBar from "../Algolia/SearchBar";
import CaseHit from "../Case/CaseEntityPicker/Hit/CaseHit";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import MenuItem from "@mui/material/MenuItem";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { OnHitClickCallback } from "../../types/Algolia/OnHitClickCallback";
import { BankPaymentEntry } from "../../types/BankPaymentEntry";

type BankPaymentEntryRowProps = {
  bankPaymentEntry: BankPaymentEntry;
  refreshList: Function;
};

const BankPaymentEntryRow = ({ bankPaymentEntry, refreshList }: BankPaymentEntryRowProps) => {
  const [currentPaymentEntry, setCurrentPaymentEntry] = useState<BankPaymentEntry>(bankPaymentEntry);
  const [isBlocked, setIsBlocked] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const currentUser = useCurrentUser();

  const onHitClick: OnHitClickCallback = async ({ product: productType, productId }, event) => {
    event && event.preventDefault();
    if (isBlocked) {
      return;
    }
    setIsBlocked(true);
    const currentEntry = await ApiClient.put(bankPaymentEntry["@id"], {
      body: JSON.stringify({
        productType: productType,
        productId: productId,
      }),
    });
    setCurrentPaymentEntry(currentEntry);
    setIsBlocked(false);
    refreshList();
  };

  const onCheckboxChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (isBlocked) {
      return;
    }
    const now = moment();
    setIsBlocked(true);
    const currentEntry = await ApiClient.put(bankPaymentEntry["@id"], {
      body: JSON.stringify({
        checkedDate: event.target.checked ? now : null,
      }),
    });
    setCurrentPaymentEntry(currentEntry);
    setIsBlocked(false);
    refreshList();
  };

  const onThirdPartyFundsChange = async (event: SelectChangeEvent) => {
    if (isBlocked) {
      return;
    }
    setIsBlocked(true);
    const currentEntry = await ApiClient.put(bankPaymentEntry["@id"], {
      body: JSON.stringify({
        isThirdPartyFunds: event.target.value === "true",
      }),
    });
    setCurrentPaymentEntry(currentEntry);
    setIsBlocked(false);
    refreshList();
  };

  const handleDeleteBankPaymentEntry = async () => {
    await ApiClient.delete(bankPaymentEntry["@id"]);
    refreshList();
  };

  return (
    <TableRow>
      <TableCell>{moment(currentPaymentEntry.bookingDate).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{currentPaymentEntry?.organization?.abbreviation || ""}</TableCell>
      <TableCell>{currentPaymentEntry.bookingText}</TableCell>
      <TableCell>
        {currentPaymentEntry.amount!.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </TableCell>
      <TableCell sx={{ minWidth: "120px" }}>
        <ValidatorSelect
          label={""}
          value={currentPaymentEntry.isThirdPartyFunds?.toString() || ""}
          onChange={onThirdPartyFundsChange}
        >
          <MenuItem value={"false"}>Nein</MenuItem>
          <MenuItem value={"true"}>Ja</MenuItem>
        </ValidatorSelect>
      </TableCell>
      <TableCell>
        {currentPaymentEntry.backofficeCase && (
          <Typography
            sx={bankPaymentEntryRowStyle.link}
            component={Link}
            to={
              getCaseLink({ productClassName: currentPaymentEntry.productType, id: currentPaymentEntry.productId }) +
              "/konto"
            }
          >
            {currentPaymentEntry.backofficeCase.label || currentPaymentEntry.backofficeCase.lbProductId}
          </Typography>
        )}
      </TableCell>
      <TableCell sx={{ minWidth: "200px" }}>
        <Grid container alignItems={"center"} justifyContent={"flex-start"}>
          <Grid item xs={10}>
            <SearchBar
              searchConfig={{
                indexName: ALGOLIA_INDEX,
              }}
              resultListConfig={{
                itemSize: 270,
                width: "100%",
              }}
              HitComponent={CaseHit}
              onHitClick={onHitClick}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Checkbox
          disabled={
            !currentPaymentEntry.productType ||
            !currentPaymentEntry.productId ||
            currentPaymentEntry.isThirdPartyFunds === null
          }
          onChange={onCheckboxChange}
          checked={!!currentPaymentEntry.checkedDate}
        />
      </TableCell>
      <TableCell>
        <ToolTipIconButton
          disabled={!userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.accounting])}
          icon={DeleteOutlineOutlined}
          tooltip={"Zahlungseingang löschen"}
          onClick={() => setDeleteModalOpen(true)}
        />
        <LegalbirdIoConfirm
          handleClose={() => setDeleteModalOpen(false)}
          open={deleteModalOpen}
          content={"Mit Klick auf “Zahlungseingang löschen” wird der ausgewählte Eintrag aus der Liste gelöscht."}
          confirmText={"Zahlungseintrag löschen"}
          handleConfirm={handleDeleteBankPaymentEntry}
          headline={"Zahlungseingang löschen"}
        />
      </TableCell>
    </TableRow>
  );
};

export default BankPaymentEntryRow;
