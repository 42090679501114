import UploadFile from "../CaseUploadFile/UploadFile";
import CreateActivity from "../../Activities/EditActivity/CreateActivity";
import EditNote from "../CaseCreateNote/EditNote";
import React from "react";
import CreatePaidTaskModal from "../CaseModals/CreatePaidTaskModal";
import PauseModal from "../CaseModals/PauseModal";
import StatusModal from "../CaseModals/StatusModal";
import DeleteCaseOrAccountModal from "../CaseModals/DeleteCaseOrAccountModal";
import ContractResetModal from "../CaseModals/ContractResetModal";
import StageModal from "../CaseModals/StageModal";
import PowerOfAttorneyModal from "../CaseModals/PowerOfAttorneyModal";
import ResendEmailModal from "../CaseModals/ResendEmailModal";
import PaymentTypeSwitchModal from "../CaseModals/PaymentTypeSwitchModal";
import CourtAppointmentModal from "../CaseModals/CourtAppointmentModal";
import CreateActivityNoteModal from "../CaseModals/CreateActivityNoteModal";
import CommunicationMedium from "../CaseBeaAndFax/CommunicationMedium";
import AddInquiryToCustomerModal from "../CaseModals/AddInquiryToCustomerModal";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import RequestDataCopyModal from "../CaseModals/RequestDataCopyModal";
import { AbstractCase } from "../../../types/AbstractCase";
import ComposeEmailOrMessageDrawer from "../CaseWriteEmail/ComposeEmailOrMessageDrawer";
import RequestPersonalAffectionModal from "../CaseModals/requestPersonalAffectionModal";

type CaseRelatedOverlaysProps = {
  open: string;
  setOpen: Function;
  messageDrawerOpen: boolean;
  setMessageDrawerOpen: Function;
  messageDrawerMaximized: boolean;
  setMessageDrawerMaximized: Function;
  refreshActivities: Function;
  refreshPage: Function;
  product: AbstractCase;
};
const CaseRelatedOverlays = ({
  open,
  setOpen,
  messageDrawerOpen,
  setMessageDrawerOpen,
  messageDrawerMaximized,
  setMessageDrawerMaximized,
  refreshActivities,
  refreshPage,
  product,
}: CaseRelatedOverlaysProps) => {
  const currentUser = useCurrentUser();
  const getDefaultAssignedUser = () => {
    if (currentUser.isLawyer || currentUser.isAccountManager) {
      return currentUser["@id"];
    }
    return product.backofficeCase.accountManager ? product.backofficeCase.accountManager["@id"] : "";
  };

  const handleClose = () => {
    setOpen("");
  };

  return (
    <>
      <UploadFile open={open === "upload"} product={product} closeDialog={handleClose} />
      <EditNote open={open === "note"} product={product} closeDialog={handleClose} />
      {messageDrawerOpen && (
        <ComposeEmailOrMessageDrawer
          open={open}
          setMessageDrawerOpen={setMessageDrawerOpen}
          closeDialog={() => setMessageDrawerOpen(false)}
          messageDrawerMaximized={messageDrawerMaximized}
          setMessageDrawerMaximized={setMessageDrawerMaximized}
        />
      )}
      {!currentUser.isExternal && (
        <>
          <CreateActivity
            open={open === "activity"}
            product={product}
            closeDialog={handleClose}
            updateActivities={() => refreshActivities()}
            caseId={product.backofficeCase["@id"]}
            defaultAssignedUser={getDefaultAssignedUser()}
          />
          <CreateActivityNoteModal
            open={open === "callNotes"}
            product={product}
            closeDialog={handleClose}
            updateActivities={() => refreshActivities()}
          />
          <CommunicationMedium open={open === "bea_fax"} product={product} handleClose={handleClose} />
          <CreatePaidTaskModal
            open={open === "paidTask"}
            product={product}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <PauseModal open={open === "pause"} product={product} handleClose={handleClose} refreshPage={refreshPage} />
          <StatusModal open={open === "status"} product={product} handleClose={handleClose} refreshPage={refreshPage} />
          <DeleteCaseOrAccountModal
            open={open === "delete"}
            product={product}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <ContractResetModal
            open={open === "resetContract"}
            product={product}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <StageModal open={open === "stage"} product={product} handleClose={handleClose} refreshPage={refreshPage} />
          <PowerOfAttorneyModal
            open={open === "powerOfAttorney"}
            product={product}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <ResendEmailModal open={open === "resendEmail"} product={product} handleClose={handleClose} />
          <PaymentTypeSwitchModal
            open={open === "privatePayer"}
            product={product}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <CourtAppointmentModal
            open={open === "courtAppointment"}
            product={product}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <RequestDataCopyModal open={open === "requestDataCopy"} product={product} handleClose={handleClose} />
          <RequestPersonalAffectionModal
            product={product}
            handleClose={handleClose}
            open={open === "requestPersonalAffection"}
          />
          <AddInquiryToCustomerModal open={open === "addRequest"} product={product} handleClose={handleClose} />
        </>
      )}
    </>
  );
};
export default CaseRelatedOverlays;
