import PageContentContainer from "../../Container/PageContentContainer";
import CaseFileGenerateForm from "../../CaseFileGenerate/CaseFileGenerateForm";
import CasePdfGenerator from "../../Case/CasePdfGenerator/CasePdfGenerator";
import CaseNotFound from "../../Case/CaseNotFound/CaseNotFound";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import { useCase } from "../../../provider/CaseProvider";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import NavigationExtensionCaseFileGenerate from "../../NavigationBar/NavigationExtensionCaseFileGenerate";
import { generatableFilesAndAttachments } from "../../../services/generatableFilesService";
import ButtonLoading from "../../Button/ButtonLoading";
import React from "react";

const CaseFileGeneratePage = () => {
  const { product, isLoading, error } = useCase();

  if (isLoading) {
    return <LegalBirdPageLoading />;
  }

  if (error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  if (!product) {
    return null;
  }

  const caseFileAttachmentGenerateCfg = {
    scrollToAnchor: "contractFilesAttachment",
    title: "Schriftsatz und Anlagen",
    postUrl: "filesAndAttachment/generate/",
    helperText: "Art der Datei: z.B. Klageschrift, Replik",
    submitButton: ({ isLoading }: { isLoading: boolean }) => (
      <ButtonLoading
        sx={{ width: "350px" }}
        isLoading={isLoading}
        disabled={isLoading}
        type={"submit"}
        color={"primary"}
        variant={"contained"}
      >
        Dokument mit Anlagen generieren
      </ButtonLoading>
    ),
    menuItems: generatableFilesAndAttachments,
  };

  return (
    <>
      <NavigationExtensionCaseFileGenerate product={product} />
      <PageContentContainer size={"small"}>
        <ProductHeadline title="Schriftsatz erstellen für" product={product} />
        <CaseFileGenerateForm product={product} />
        <CaseFileGenerateForm product={product} componentCfg={caseFileAttachmentGenerateCfg} />
        <CasePdfGenerator product={product} />
      </PageContentContainer>
    </>
  );
};

export default CaseFileGeneratePage;
