import React, { useRef, useState } from "react";
import { Menu, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { FilterList } from "@mui/icons-material";
import _ from "lodash";
import LegalbirdRefinementList from "./LegalbirdRefinementList";
import Overlay from "../../Overlay/Overlay";
import LegalbirdClearRefinements from "./LegalbirdClearRefinements";
import { useStyles } from "./boardRefinementsStyle";
import { AlgoliaRefinement } from "../../../types/Algolia/AlgoliaRefinement";

type BoardRefinementsProps = {
  refinements: AlgoliaRefinement[];
  filterHeadline?: string;
};
const BoardRefinements = (props: BoardRefinementsProps) => {
  const { refinements, filterHeadline = "Filtern nach" } = props;
  const classes = useStyles(props);
  const anchor = useRef<HTMLDivElement>(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  return (
    <>
      {filterMenuOpen && <Overlay />}
      <Tooltip title={"Filter"}>
        <IconButton className={classes.menuButton} onClick={() => setFilterMenuOpen(true)} size="large">
          <FilterList className={classes.filterIcon} />
        </IconButton>
      </Tooltip>
      <div ref={anchor} />
      <Menu
        className={classes.menu}
        open={filterMenuOpen}
        anchorEl={anchor.current}
        onClose={() => setFilterMenuOpen(false)}
        keepMounted={true}
      >
        <div className={classes.filterHeadline}>{filterHeadline}</div>
        {_.map(refinements, (refinement) => {
          return (
            <LegalbirdRefinementList
              key={refinement.attribute.name}
              attribute={refinement.attribute.name}
              refinement={refinement}
            />
          );
        })}
        <LegalbirdClearRefinements />
      </Menu>
    </>
  );
};

export default BoardRefinements;
