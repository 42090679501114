/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { AbstractCase } from "../../../types/AbstractCase";
import { getContractLawBadges } from "./getContractLawBadges";

export const getDataLeakContractBadges = (product: AbstractCase) => {
  let badges = getContractLawBadges(product);

  if (product.primaryCreditAgency) {
    badges.push({
      text: product.primaryCreditAgency.toUpperCase(),
      isActive: true,
    });
  }

  return badges;
};
