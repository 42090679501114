import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    position: "fixed",
    bottom: 10,
    right: "5%",
    zIndex: 3,
    [theme.breakpoints.down("lg")]: {
      bottom: "8rem",
      right: 10,
    },
  },
  imageCircle: {
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    width: 60,
    height: 60,
    borderRadius: "50% !important",
    "& > img": {
      maxHeight: 60,
    },
  },
}));
