import { AbstractCase } from "../../../../types/AbstractCase";
import { Person } from "../../../../types/Person";
import React, { useState } from "react";
import ApiClient from "../../../../services/ApiClient";
import { persistPerson, sanitizePersonData } from "../../../../services/Persist/Persister";
import useForm from "../../../../hooks/useForm";
import { UseForm } from "../../../../types/UseForm";
import { Box, Button, Grid, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { formValue } from "../../../../services/formServiceFunctions";
import ValidatorTextField from "../../../Validator/ValidatorTextField";
import ValidatorSelect from "../../../Validator/ValidatorSelect";
import ButtonLoading from "../../../Button/ButtonLoading";
import CircularProgress from "@mui/material/CircularProgress";
import { requiredFieldDefault } from "../../../../services/validationRules";

type AdditionalClientFormProps = { product: AbstractCase; refreshPage: Function; client?: Person };
export default function AdditionalClientForm({ product, refreshPage, client = undefined }: AdditionalClientFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const onSubmit = async () => {
    if (!client) {
      setIsLoading(true);
      await ApiClient.post("people", {
        body: JSON.stringify(sanitizePersonData(clientData)),
      });
    } else {
      setButtonIsLoading(true);
      await persistPerson(client.id, clientData);
    }
    await refreshPage();
    setIsLoading(false);
    setButtonIsLoading(false);
  };

  const initialValues = {
    givenName: client?.givenName || "",
    familyName: client?.familyName || "",
    gender: client?.gender || "",
  };

  const { values, handleSubmit, handleChange, registerValidators, errors, handleBlur }: UseForm = useForm({
    initialValues,
    onSubmit,
    identifier: product.id + "-additionalClient-" + (client?.id ?? ""),
    clearFormAfterSubmit: !client,
  });

  const clientData = {
    ...values,
    type: "additionalClient",
    customer: product.customer!["@id"],
    caseId: product.id,
    backofficeCaseId: product.backofficeCase.id,
    basePath: "additionalClient",
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", margin: "5rem" }}>
        <CircularProgress />
      </Box>
    );
  }

  async function deleteAdditionalClient() {
    setIsLoading(true);
    await ApiClient.delete("people/" + client!.id, {
      body: JSON.stringify({ backofficeCaseId: product.backofficeCase.id, basePath: "additionalClient" }),
    });
    await refreshPage();
    setIsLoading(false);
  }

  return (
    <>
      <Grid container spacing={3} paddingBottom={3}>
        {!client && (
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ mt: 1, mb: 1 }}>
              Mandantschaft hinzufügen
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorSelect
            label={"Anrede"}
            name={"gender"}
            value={formValue(values, "gender")}
            isHighlighted={false}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            error={!!errors["gender"]}
            helperText={errors["gender"]}
            onBlur={handleBlur}
          >
            <MenuItem value={"male"}>Herr</MenuItem>
            <MenuItem value={"female"}>Frau</MenuItem>
            <MenuItem value={"diverse"}>Divers</MenuItem>
          </ValidatorSelect>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            label={"Vorname"}
            name={"givenName"}
            value={formValue(values, "givenName")}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            isHighlighted={false}
            initialDependentValidationFields={undefined}
            error={!!errors["givenName"]}
            helperText={errors["givenName"]}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ValidatorTextField
            label={"Nachname"}
            name={"familyName"}
            value={formValue(values, "familyName")}
            onChange={handleChange}
            registerValidators={registerValidators}
            validators={requiredFieldDefault}
            isHighlighted={false}
            initialDependentValidationFields={undefined}
            error={!!errors["familyName"]}
            helperText={errors["familyName"]}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          {client && (
            <Button fullWidth onClick={deleteAdditionalClient}>
              Entfernen
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <ButtonLoading fullWidth variant="contained" onClick={handleSubmit} isLoading={buttonIsLoading}>
            Speichern
          </ButtonLoading>
        </Grid>
      </Grid>
    </>
  );
}
