import React, { SyntheticEvent, useState } from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import { Box, Typography } from "@mui/material";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import SearchBar from "../Algolia/SearchBar";
import MainSearchHit from "../Case/CaseEntityPicker/Hit/MainSearchHit";
import { Hit } from "@algolia/client-search";
import { CaseHit } from "../Case/CaseEntityPicker/Hit/hitType";
import { translate } from "../../services/Translations/translatorService";
import { apiPut } from "../../services/Api/apiCall";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { BookingEntryWithDifference } from "../../types/BookingEntry";
import { getCaseLink } from "../../services/Product/ProductService";

type ReassignBookingEntryModalProps = {
  bookingEntry: BookingEntryWithDifference;
  open: boolean;
  handleClose: () => void;
  updateBookingEntries: Function;
};

export default function ReassignBookingEntryModal({
  bookingEntry,
  open,
  handleClose,
  updateBookingEntries,
}: ReassignBookingEntryModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeField, setActiveField] = useState("");
  const [selectedCase, setSelectedCase] = useState<null | CaseHit>(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const onHitClick = (caseHit: Hit<CaseHit>, event: SyntheticEvent<HTMLElement> | undefined) => {
    if (event !== undefined) {
      event.preventDefault();
      setSelectedCase(caseHit);
    }
  };

  const closeModal = () => {
    setSelectedCase(null);
    handleClose();
  };

  const saveReassignBookingEntry = async () => {
    if (selectedCase === null || selectedCase.product === undefined || selectedCase.productId === undefined) {
      return false;
    }

    try {
      setIsLoading(true);
      await apiPut("/booking_entries", bookingEntry.id, {
        productId: selectedCase.productId,
        productType: selectedCase.product,
      });
      await queryClient.invalidateQueries(queryKeys.collections("booking_entries"));
      enqueueSnackbar("Einzahlung wurde umgeschlüsselt", {

        variant: "custom",
        buttonText: "ZUM NEUEN FALL",
        buttonLink: getCaseLink({ productClassName: selectedCase.product, id: selectedCase.productId }),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar("Es ist ein Fehler aufgetreten", {
         isNonInteractive: true,
        variant: "custom",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setIsLoading(false);
      updateBookingEntries();
    }
  };

  function getSelectedCaseHitDetails(selectedCase: CaseHit) {
    return (
      translate("person.gender.values." + selectedCase.gender) +
      " " +
      selectedCase.givenName +
      " " +
      selectedCase.familyName +
      " - " +
      selectedCase.reference
    );
  }

  // The SEARCHBAR (because of the Popper inside it) in a MODAL IS NOT by our
  // standards and should not be used - This here is an Exception!!!

  return (
    <LegalbirdIoModal
      handleClose={closeModal}
      open={open}
      title={"Einzahlung umschlüsseln"}
      submitButton={
        <ButtonLoading
          isLoading={isLoading}
          onClick={saveReassignBookingEntry}
          variant={"contained"}
          disabled={!selectedCase}
        >
          Einzahlung umschlüsseln
        </ButtonLoading>
      }
    >
      <Box sx={{ height: "650px" }}>
        <Typography sx={{ marginBottom: "2rem" }}>
          Bitte wählen Sie den Fall aus, auf den die Einzahlung umgeschlüsselt werden soll
        </Typography>
        <SearchBar
          searchConfig={{
            indexName: ALGOLIA_INDEX,
          }}
          resultListConfig={{
            itemSize: 260,
          }}
          HitComponent={MainSearchHit}
          onHitClick={onHitClick}
          disabled={isLoading || !["search", ""].includes(activeField)}
        />
        {selectedCase && (
          <Typography sx={{ padding: 2 }} variant={"h4"}>
            {getSelectedCaseHitDetails(selectedCase)}
          </Typography>
        )}
      </Box>
    </LegalbirdIoModal>
  );
}
