import ApiClient from "../../../../services/ApiClient";
import { AbstractCase } from "../../../../types/AbstractCase";
import { AccountDeletionPossibleStatus } from "../../../../types/AccountDeletionPossibleStatus";
import DeleteCaseOrAccountModalContentBase from "./DeleteCaseOrAccountModalContentBase";
import { useCurrentUser } from "../../../../provider/CurrentUserProvider";
import { userHasOneOfTheseRoles } from "../../../../services/backofficeUserService";
import { Roles } from "../../../../types/BackofficeUser";
import { useEffect, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";

type DeleteAccountModalProps = {
  product: AbstractCase;
  postDeletionCallback: Function;
};

export default function DeleteAccountModalContent({ product, postDeletionCallback }: DeleteAccountModalProps) {
  const currentUser = useCurrentUser();
  const userIsAdminOrAccounting = userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.accounting]);
  const [accountDeletionPossibleStatus, setAccountDeletionPossibleStatus] =
    useState<AccountDeletionPossibleStatus | null>(null);

  useEffect(() => {
    ApiClient.get(`/lb/account_deletion/get_account_deletion_possible_status/${product.customer!.id}`).then(
      (result: AccountDeletionPossibleStatus) => {
        setAccountDeletionPossibleStatus(result);
      }
    );
  }, []);

  if (accountDeletionPossibleStatus === null) {
    return (
      <Stack direction={"row"} paddingY={2} justifyContent={"center"}>
        <CircularProgress />
      </Stack>
    );
  }

  const deletionAllowed =
    (userIsAdminOrAccounting && !accountDeletionPossibleStatus.hasBookingEntry) ||
    (!accountDeletionPossibleStatus.hasAcquisitionPartnerAdvocardCase &&
      !accountDeletionPossibleStatus.hasBookingEntry);
  let deletionWarnings = [];

  if (accountDeletionPossibleStatus.hasAcquisitionPartnerAdvocardCase) {
    deletionWarnings.push(
      userIsAdminOrAccounting
        ? "Sind Sie sicher, dass Sie alle Daten und Fälle löschen möchten? Es gibt mindestens einen Fall von der Advocard, bei dem noch eine Zahlung eingehen kann. Diese wird dann nicht zugeordnet werden können."
        : "Achtung: Es gibt mindestens einen Fall von der Advocard, bei dem noch eine Zahlung eingehen kann. Diese Zahlung könnte bei einer Löschung nicht mehr zugeordnet werden. Daher kann das Konto nicht gelöscht werden. Bitte bestätige dem Kunden die Löschung manuell per E-Mail und verfälsche die E-Mail dann mit “xxx_”."
    );
  }

  if (accountDeletionPossibleStatus.hasBookingEntry) {
    deletionWarnings.push(
      "Es gibt mindestens einen Fall mit einem Buchungseintrag. Es kann somit nicht alles gelöscht werden, sondern ggf. nur einzelne Fälle oder der Kundenzugang muss verfälscht werden (mit xxx_ vor der E-Mail)."
    );
  }

  if (accountDeletionPossibleStatus.hasAdvancedCase) {
    deletionWarnings.push(
      "Sind Sie sicher, dass Sie alle Daten und Fälle löschen möchten? Es gibt mindestens einen Fall, der so weit fortgeschritten ist, dass uns entweder eine Vollmacht vorliegt und/oder wir sogar bereits rechtlich tätig geworden sind. Beim Löschen könnten somit Aufbewahrungspflichten verletzt werden!"
    );
  }

  async function confirmDeletion() {
    await ApiClient.get(`/lb/services/account_deletion/force/${product.customer!.id}`);
  }

  return (
    <DeleteCaseOrAccountModalContentBase
      confirmDeletion={confirmDeletion}
      deletionWarnings={deletionWarnings}
      deletionAllowed={deletionAllowed}
      postDeletionCallback={postDeletionCallback}
    />
  );
}
