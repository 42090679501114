import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import PageContentContainer from "../../Container/PageContentContainer";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import { useCase } from "../../../provider/CaseProvider";
import CaseReferenceFile from "../../Case/CaseReferenceFile/CaseReferenceFile";
import _ from "lodash";
import { Box } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useParams } from "react-router-dom";
import { ReferenceFile } from "../../../types/ReferenceFile";

const CaseReferenceFilePage = () => {
  const { courtAppointmentId } = useParams();
  const { product } = useCase();
  const queryClient = useQueryClient();
  const referenceFilesUri = "reference_files";
  const referenceFilesFilters = { courtAppointmentId };
  const { data: referenceFiles, isLoading } = useQuery(
    queryKeys.collection(referenceFilesUri, referenceFilesFilters),
    () => fetchCollection(referenceFilesUri, referenceFilesFilters),
    {
      enabled: !!product,
    }
  );

  if (isLoading || !referenceFiles) {
    return <LegalBirdPageLoading />;
  }

  if (!product) {
    return null;
  }

  const referenceFile: undefined | ReferenceFile = _.head(referenceFiles["hydra:member"]);

  return (
    <>
      <PageContentContainer size={"small"}>
        <ProductHeadline title="Terminsvertreter-Handakte zu" product={product} />
        {!referenceFile ? (
          <Box sx={{ textAlign: "center" }}>Es gibt keine Handakte für diesen Termin</Box>
        ) : (
          <CaseReferenceFile
            product={product}
            referenceFile={referenceFile}
            refreshReferenceFile={() =>
              queryClient.invalidateQueries(queryKeys.collection(referenceFilesUri, referenceFilesFilters))
            }
          />
        )}
      </PageContentContainer>
    </>
  );
};

export default CaseReferenceFilePage;
