import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import ApiClient from "../services/ApiClient";
import _ from "lodash";
import DutyNotification from "../components/Notifications/DutyNotification";

const setSeen = (notification) => {
  const seen = JSON.parse(sessionStorage.getItem("notifications")) || [];
  seen.push(notification.id);
  sessionStorage.setItem("notifications", JSON.stringify(seen));
};

const handleNotifications = (notifications, enqueueSnackbar) => {
  const acknowledged = JSON.parse(localStorage.getItem("notifications")) || [];
  const seen = JSON.parse(sessionStorage.getItem("notifications")) || [];
  const newNotifications = _.filter(notifications, (notification) => {
    return !_.includes(acknowledged, notification.id) && !_.includes(seen, notification.id);
  });
  _.forEach(newNotifications, (newNotification) => {
    enqueueSnackbar("", {
      variant: "dutyNotification",
      description: newNotification.subject,
      timeString: newNotification.dueTime === "allDay" ? "Ganztägig" : newNotification.dueTime + " Uhr",
      link: newNotification.link,
      persist: true,
    });
    setSeen(newNotification);
  });
};

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (localStorage.getItem("notificationsEnabled") !== "paused") return;
    const checkNotifications = () => {
      ApiClient.get("notifications").then((notifications) => {
        handleNotifications(notifications, enqueueSnackbar);
      });
    };
    const notificationInterval = setInterval(checkNotifications, 30000);
    return () => {
      clearInterval(notificationInterval);
    };
  }, [enqueueSnackbar]);
};

export default useNotifications;
