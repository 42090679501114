/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { AbstractCase } from "../../../../types/AbstractCase";
import basicInsuranceFields from "../modules/basicInsuranceFields";

export const fullFormDefinition = (product: AbstractCase) => {
  return {
    processParticipants: {
      sections: [
        {
          label: "Versicherung",
          elements: [
            ...basicInsuranceFields({
              path: `${product.productClassName}.insurance`,
              product: product,
            }),
          ],
        },
      ],
    },
  };
};
