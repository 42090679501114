const allDataFormTranslations = {
  additional: "Angaben zum Verfahren",
  additionalProcessParticipants: "Weitere Prozessbeteiligte",
  authority: "Behörde",
  court: "Gericht",
  dataLeakContract: "Daten Datenweitergabe",
  processParticipants: "Prozessbeteiligte",
  product: "Angaben zum Fall",
  prosecution: "Staatsanwaltschaft",
};

export default allDataFormTranslations;
