import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { Activity } from "../../../types/Activity";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getCaseLink, getProductData, getProductNameBy } from "../../../services/Product/ProductService";
import ActivityIcon from "../../Activities/ActivityIcon";
import activityStyle from "../../Activities/ActivityTable/activityStyle";

type LawyerDashboardActivitiesTableProps = {
  activities: Activity[];
};

export default function LawyerDashboardActivitiesTable({ activities }: LawyerDashboardActivitiesTableProps) {
  if (activities.length === 0) {
    return (
      <Box sx={{ borderTop: "1px solid #ddd", padding: "1.5rem 0", textAlign: "center" }}>
        <Typography textAlign={"center"}>Keine offenen Fristen</Typography>
      </Box>
    );
  }

  return (
    <Table sx={{ borderTop: "1px solid #ddd" }}>
      <TableHead>
        <TableCell width={"5%"}>Typ</TableCell>
        <TableCell width={"25%"}>Beschreibung</TableCell>
        <TableCell>Rechtsprodukt</TableCell>
        <TableCell>Fall</TableCell>
      </TableHead>
      <TableBody>
        {_.map(activities, (activity) => (
          <TableRow key={activity.id}>
            <TableCell>
              <ActivityIcon activity={activity} withToolTip />
            </TableCell>
            <TableCell>{activity.subject}</TableCell>
            <TableCell>
              {getProductData(getProductNameBy("lbProductClass", activity.case.lbProductClass), "generalLabel")}
            </TableCell>
            <TableCell>
              <Typography
                component={Link}
                sx={activityStyle.link}
                to={getCaseLink({
                  productClassName: getProductNameBy("lbProductClass", activity.case.lbProductClass),
                  id: activity.case.lbProductId,
                })}
              >
                {activity.case.label || activity.case.lbProductId}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
