import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutDeviceBased from "../routeHelperComponents/LayoutDeviceBased";
import CreateCasePage from "../components/_Pages/CreateCasePage/CreateCasePage";
import Callcenter404Page from "../components/_Pages/CallCenterPages/Callcenter404Page/Callcenter404Page";
import CallcenterCaseRoutes from "./CallcenterCaseRoutes";

export default function CallcenterRoutes() {
  return (
    <LayoutDeviceBased>
      <Routes>
        <Route path={"/fall-anlegen"} element={<CreateCasePage />} />
        <Route path={"/fall/:productUrlPart/:productId/*"} element={<CallcenterCaseRoutes />} />
        <Route path={"/*"} element={<Callcenter404Page/>} />
      </Routes>
    </LayoutDeviceBased>
  );
}
