import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { getBadges, getCaseLabel } from "../../../services/Product/ProductService";
import { AbstractCase } from "../../../types/AbstractCase";
import BadgesList from "../../Badges/BadgesList";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import AgentPicker from "../../AgentPicker/AgentPicker";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import CaseAdditionalClientsTooltipIcon from "../../Case/CaseAdditionalClientsTooltipIcon/CaseAdditionalClientsTooltipIcon";
import CaseSubBarInfo from "../../Case/CaseSubBarInfo/CaseSubBarInfo";

type CallcenterCaseHeaderProps = {
  product: AbstractCase;
};

export default function CallcenterCaseHeader({ product }: CallcenterCaseHeaderProps) {
  const currentUser = useCurrentUser();
  const { accountManagers, lawyers } = useBackofficeUser();

  return (
    <Paper sx={{ width: "100%", padding: "2rem" }}>
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="h1">{getCaseLabel(product)}</Typography>
            <CaseAdditionalClientsTooltipIcon product={product} fontSize={"large"} />
          </Stack>
          <Stack direction={"row"}>
            {product.backofficeCase?.lawyer && (
              <AgentPicker
                label={"Anwalt"}
                currentAgent={product.backofficeCase?.lawyer}
                product={product}
                type={"lawyer"}
                agentList={lawyers}
                disabled
              />
            )}
            {product.backofficeCase?.accountManager && (
              <AgentPicker
                label={"Zuständiger"}
                currentAgent={product.backofficeCase?.accountManager}
                product={product}
                type={"accountManager"}
                agentList={accountManagers}
                disabled
              />
            )}
          </Stack>
        </Stack>
        {!!product.opposingParty && <>./. {product.opposingParty}</>}
        <BadgesList badges={getBadges(product, currentUser)} />
        <CaseSubBarInfo product={product} />
      </Stack>
    </Paper>
  );
}
