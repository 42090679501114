import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { getBadges, getIsApplicationComplete } from "../../../services/Product/ProductService";
import AgentPicker from "../../AgentPicker/AgentPicker";
import BadgesList from "../../Badges/BadgesList";
import CaseProgressBar from "../CaseProgressBar/CaseProgressBar";
import classNames from "classnames";
import useStyles from "./caseOverviewBoxStyle";
import Button from "@mui/material/Button";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import CaseAdditionalClientsTooltipIcon from "../CaseAdditionalClientsTooltipIcon/CaseAdditionalClientsTooltipIcon";
import { AbstractCase } from "../../../types/AbstractCase";

type CaseOverviewBoxProps = {
  product: AbstractCase;
  refreshPage: Function;
  hasUpdates: boolean;
};

const CaseOverviewBox = ({ product, refreshPage, hasUpdates }: CaseOverviewBoxProps) => {
  const classes = useStyles();
  const { backofficeUsers, accountManagers, lawyers } = useBackofficeUser();

  const isApplicationComplete = getIsApplicationComplete(product);
  if (!backofficeUsers) {
    return null;
  }

  return (
    <Paper
      className={classNames(
        classes.fullWidth,
        product.backofficeCase?.status ? classes[product.backofficeCase.status] : null
      )}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems={"center"} justifyContent={"space-between"}>
            <Grid item xs={12} lg={7}>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography variant={"h1"} className={classes.label}>
                      {product.backofficeCase.label}
                    </Typography>
                    <CaseAdditionalClientsTooltipIcon product={product} fontSize={"large"} />
                  </Stack>
                  {!!product.opposingParty && <>./. {product.opposingParty}</>}
                </Grid>
                {hasUpdates && (
                  <Grid item>
                    <Button onClick={() => refreshPage()}>Neu laden</Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  {(isApplicationComplete || product.backofficeCase.lawyer !== null ) && (
                    <AgentPicker
                      label={"Anwalt"}
                      currentAgent={product.backofficeCase.lawyer}
                      product={product}
                      type={"lawyer"}
                      agentList={lawyers}
                      refreshPage={refreshPage}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <AgentPicker
                    label={"Zuständiger"}
                    currentAgent={product.backofficeCase?.accountManager}
                    product={product}
                    type={"accountManager"}
                    agentList={accountManagers}
                    refreshPage={refreshPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BadgesList badges={getBadges(product)} />
      {isApplicationComplete && <CaseProgressBar product={product} refreshPage={refreshPage} />}
    </Paper>
  );
};

export default CaseOverviewBox;
