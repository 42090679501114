
import { Link } from "react-router-dom";
import { Box, Button, Paper } from "@mui/material";
import callcenter404PageStyle from "./callcenter404PageStyle";
import Typography from "@mui/material/Typography";

const Callcenter404Page = () => {
  return (
    <Box sx={callcenter404PageStyle.grey}>
      <Paper sx={callcenter404PageStyle.noAccessBox}>
        <Typography variant={"h3"}>Leider ist auf diese Seite kein Zugriff möglich</Typography>
        <Button component={Link} variant={"text"} to={"/"}>
          Zurück
        </Button>
      </Paper>
    </Box>
  );
};

export default Callcenter404Page;
