export const getCommonContractLawCaseRejectionCategorySelectOptions = () => [
    { value: "none"},
    { value: "individual"},
    { value: "electricityProvider"},
    { value: "opponentNonEu"},
    { value: "fraudLottery"},
    { value: "fraudNoOpponent"},
    { value: "fraudNoOpponentAlreadyFiled"},
    { value: "businessToBusinessContract"},
    { value: "specialistLawyerNeeded"},
    { value: "claimEnforcable"},
    { value: "enforceClaimTimeBarred"},
    { value: "defendClaimTimeBarred"},
    { value: "unlikelySuccessful"},
    { value: "schufaNoPositiveData"},
];

export const getRentalLawCaseRejectionCategorySelectOptions = () => [
    { value: "none"},
    { value: "individual"},
    { value: "unlikelySuccessful"},
];
