import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import _ from "lodash";
import React, { useState } from "react";
import { getActivityLabel } from "../../../services/Activities/activityTypes";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { Activity } from "../../../types/Activity";
import Typography from "@mui/material/Typography";
import activityHistoryStyles from "./activityHistoryStyles";
import { Box } from "@mui/material";
import {
  BooleanActivityLog,
  NullableStringActivityLog,
  StringActivityLog,
} from "../../../types/Activities/ActivityLog";

type ActivityHistoryProps = {
  activity: Activity;
  productClassName: string;
};

const ActivityHistory = ({ activity, productClassName }: ActivityHistoryProps) => {
  const [showHistory, setShowHistory] = useState(false);
  const { backofficeUsers, getUserData } = useBackofficeUser();

  const historyTranslator = ({ type, current, previous }: LogEntryWithType) => {
    switch (type) {
      case "mandatory":
        return (
          "Pflicht?: " + (previous ? "Pflicht" : "keine Pflicht") + " >> " + (current ? "Pflicht" : "keine Pflicht")
        );
      case "dueDate":
        return "Datum: " + moment(previous).format("DD.MM.YYYY") + " >> " + moment(current).format("DD.MM.YYYY");
      case "dueTime":
        const newValue = current === "allDay" ? "Ganztägig" : current + " Uhr";
        const oldValue = previous === "allDay" ? "Ganztägig" : previous + " Uhr";
        return "Uhrzeit: " + oldValue + " >> " + newValue;
      case "type":
        return (
          "Aktivitätentyp: " +
          getActivityLabel(productClassName, previous) +
          " >> " +
          getActivityLabel(productClassName, current)
        );
      case "subject":
        return "Beschreibung: " + previous + " >> " + current;
      case "note":
        return (
          <>
            Notiz: <Typography sx={activityHistoryStyles.note}>{previous?.replace(/(<([^>]+)>)/gi, "")}</Typography>{" "}
            {">> "}
            <Typography sx={activityHistoryStyles.note}>{current?.replace(/(<([^>]+)>)/gi, "")}</Typography>
          </>
        );
      case "assignedUser":
        const newUser = _.find(backofficeUsers, (user) => user["@id"] === current);
        const oldUser = _.find(backofficeUsers, (user) => user["@id"] === previous);
        return (
          "Zu erledigen durch: " +
          (oldUser ? oldUser.person.fullname : "Unbekannt") +
          " >> " +
          (newUser ? newUser.person.fullname : "Unbekannt")
        );
      default:
        break;
    }
  };

  const differenceHeadline = (done: boolean | undefined) => {
    switch (done) {
      case true:
        return "Erledigt";
      case false:
        return "Wieder geöffnet";
      default:
        return "Geändert";
    }
  };

  const creator = _.isObject(activity.creator) ? activity.creator : getUserData(activity.creator);

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12}>
        <Button sx={activityHistoryStyles.button} fullWidth={false} onClick={() => setShowHistory(!showHistory)}>
          Historie {showHistory ? "ausblenden" : "anzeigen"}
        </Button>
      </Grid>
      {showHistory && (
        <Grid item xs={10} sx={activityHistoryStyles.listContainer}>
          <div>
            <strong>Erstellt:</strong> {moment(activity.created).format("DD.MM.YYYY | HH:mm")} Uhr |{" "}
            {creator?.person?.fullname || "Unbekannt"}
          </div>
          {_.map(activity.activityLog, (logEntry, created) => (
            <div key={created}>
              <strong>{differenceHeadline(logEntry.done && logEntry.done.current)}:</strong>{" "}
              {moment(created).format("DD.MM.YYYY | HH:mm")} Uhr | {logEntry.updatedBy}
              <Box sx={activityHistoryStyles.activityLogEntries}>
                {_.map(logEntry, (entry, key) => {
                  if (typeof entry !== "object") {
                    return null;
                  }
                  const logEntryWithType = { current: entry?.current, previous: entry?.previous, type: key };
                  if (!isLogEntryWithValidType(logEntryWithType)) {
                    return null;
                  }
                  return (
                    <Box key={key} sx={activityHistoryStyles.changeRow}>
                      {historyTranslator(logEntryWithType)}
                    </Box>
                  );
                })}
              </Box>
            </div>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
const validActivityLogTypes = ["mandatory", "dueDate", "dueTime", "type", "subject", "note", "assignedUser"] as const;

type MandatoryActivityLogWithType = {
  type: "mandatory";
} & BooleanActivityLog;

type DueDateActivityLogWithType = {
  type: "dueDate";
} & StringActivityLog;

type DueTimeActivityLogWithType = {
  type: "dueTime";
} & StringActivityLog;

type TypeActivityLogWithType = {
  type: "type";
} & StringActivityLog;

type AssignedUserActivityLogWithType = {
  type: "assignedUser";
} & StringActivityLog;
type SubjectActivityLogWithType = {
  type: "subject";
} & StringActivityLog;

type NoteActivityLogWithType = {
  type: "note";
} & NullableStringActivityLog;

type LogEntryWithType =
  | MandatoryActivityLogWithType
  | DueDateActivityLogWithType
  | DueTimeActivityLogWithType
  | TypeActivityLogWithType
  | AssignedUserActivityLogWithType
  | SubjectActivityLogWithType
  | NoteActivityLogWithType;

const isLogEntryWithValidType = (entry: any): entry is LogEntryWithType => {
  for (const key of Object.keys(entry)) {
    if (validActivityLogTypes.some((type) => type === key)) {
      return true;
    }
  }

  return false;
};
export default ActivityHistory;
