import { trueFalseDefaultOptions } from "./formDefinitionFunctions";
import * as stagesCase from "../../Stages/StagesCase";

const purchaseProductSpecificDefinition = (product) => [
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.buyerOrSeller`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "buyer" }, { value: "seller" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.channel`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [
      { value: "amazon" },
      { value: "ebay" },
      { value: "kleinanzeigen" },
      { value: "vinted" },
      { value: "onlineOther" },
      { value: "offline" },
      { value: "fleaMarket" },
      { value: "other" },
    ],
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_LAWSUIT,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.itemTitle`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.articleNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
  },
  {
    type: "ValidatorNumberField",
    path: `${product.productClassName}.purchasePrice`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.buyerPaidStatus`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "fully" }, { value: "partially" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.goodsOrServiceProvidedStatus`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "fully" }, { value: "partially" }, { value: "none" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.locationOfGood`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => [{ value: "buyer" }, { value: "seller" }, { value: "elsewhere" }, { value: "unknown" }],
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.hasDefects`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    options: () => trueFalseDefaultOptions(`${product.productClassName}.hasDefects`),
  },
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.defectsDescriptionClient`,
    isDisabled: () => true,
    isHidden: ({ product }) => product.hasDefects !== true,
    additionalProps: {
      multiline: true,
      fullWidth: true,
    },
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.numberRepairAttempts`,
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.hasDefects !== true,
  },
  {
    type: "ValidatorSelect",
    path: `${product.productClassName}.numberReplacementAttempts`,
    options: () => [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => product.hasDefects !== true,
  },
];

export default purchaseProductSpecificDefinition;
