import { transformFromFormValues, transformMomentsToStrings, transformToFormValues } from "../formServiceFunctions";
import _ from "lodash";
import ApiClient from "../ApiClient";
import { getProductData } from "../Product/ProductService";
import { apiPut } from "../Api/apiCall";

export const persist = async (values, origProduct) => {
  const transformedDateValues = transformMomentsToStrings(
    values[origProduct.productClassName],
    origProduct.productClassName
  );
  let updateData = difference(transformedDateValues, transformToFormValues(origProduct));
  if (_.has(updateData, "terminationObstacles")) {
    updateData.terminationObstacles = transformedDateValues.terminationObstacles;
  }
  if (_.has(updateData, "contractualPartnerLegalEntity.additionalRepresentatives")) {
    updateData.contractualPartnerLegalEntity.additionalRepresentatives =
      transformedDateValues.contractualPartnerLegalEntity.additionalRepresentatives;
  }
  updateData = transformFromFormValues(origProduct.productClassName, updateData);
  const productPersister = getProductData(origProduct.productClassName, "persist");
  await productPersister(updateData, origProduct);
};

export const persistDivorce = async (updateData, origDivorce) => {
  const subEntityRequests = [];

  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origDivorce, "customer");
    subEntityRequests.push(() => persistCustomer(origDivorce.customer.id, customerUpdateData));
  }

  if (_.isObject(updateData.courtDate)) {
    const courtDateUpdateData = getUpdateData(updateData.courtDate, origDivorce, "courtDate");
    subEntityRequests.push(() => persistAppointment(origDivorce.courtDate.id, courtDateUpdateData));
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origDivorce, "petitioner");
    subEntityRequests.push(() => persistPerson(origDivorce.petitioner.id, petitionerUpdateData));
  }

  if (_.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origDivorce, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origDivorce.responsibleCourt.id, courtUpdateData));
  }

  if (_.isObject(updateData.opponentLawyer)) {
    const opponentLawyerUpdateData = getUpdateData(updateData.opponentLawyer, origDivorce, "opponentLawyer");
    await persistPerson(origDivorce.opponentLawyer.id, opponentLawyerUpdateData);
  }

  if (_.isObject(updateData.opponentLawFirm)) {
    const opponentLawFirmUpdateData = getUpdateData(updateData.opponentLawFirm, origDivorce, "opponentLawFirm");
    await apiPut("organizations", origDivorce.opponentLawFirm.id, opponentLawFirmUpdateData);
  }

  if (_.has(updateData, "opponentLawFirm.address") && _.isObject(updateData.opponentLawFirm.address)) {
    const opponentLawFirmAddressUpdateData = getUpdateData(
      updateData.opponentLawFirm.address,
      origDivorce,
      "opponentLawFirm.address"
    );
    await persistAddress(origDivorce.opponentLawFirm.address.id, opponentLawFirmAddressUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origDivorce, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    subEntityRequests.push(() => persistInsurance(origDivorce.insurance.id, insuranceUpdateData));
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origDivorce,
      "insurance.policyHolder"
    );
    await persistPerson(origDivorce.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origDivorce,
      "petitioner.residenceAddress"
    );
    subEntityRequests.push(() =>
      persistAddress(origDivorce.petitioner.residenceAddress.id, petitionerAddressUpdateData)
    );
  }

  if (_.isObject(updateData.partner)) {
    const partnerUpdateData = getUpdateData(updateData.partner, origDivorce, "partner");
    subEntityRequests.push(() => persistPerson(origDivorce.partner.id, partnerUpdateData));
  }

  if (_.has(updateData, "partner.residenceAddress") && _.isObject(updateData.partner.residenceAddress)) {
    const partnerAddressUpdateData = getUpdateData(
      updateData.partner.residenceAddress,
      origDivorce,
      "partner.residenceAddress"
    );
    subEntityRequests.push(() => persistAddress(origDivorce.partner.residenceAddress.id, partnerAddressUpdateData));
  }

  if (_.has(updateData, "commonAddress") && _.isObject(updateData.commonAddress)) {
    const commonAddressUpdateData = getUpdateData(updateData.commonAddress, origDivorce, "commonAddress");
    subEntityRequests.push(() => persistAddress(origDivorce.commonAddress.id, commonAddressUpdateData));
  }

  if (_.has(updateData, "children")) {
    _.forEach(updateData.children, (child, index) => {
      const childUpdateData = getUpdateData(child, origDivorce, `children[${index}]`);
      const childId = origDivorce.children[index].id;
      subEntityRequests.push(() => persistChild(childId, childUpdateData));
    });
  }

  await Promise.all(_.map(subEntityRequests, (request) => request()));

  const divorceUpdateData = getUpdateData(updateData, origDivorce);
  await apiPut("divorces", origDivorce.id, divorceUpdateData);
};

export const persistSettlement = async (updateData, origSettlement) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origSettlement, "customer");
    await persistCustomer(origSettlement.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origSettlement, "petitioner");
    await persistPerson(origSettlement.petitioner.id, petitionerUpdateData);
  }

  if (_.isObject(updateData.clientBankData)) {
    const clientBankUpdateData = getUpdateData(updateData.clientBankData, origSettlement, "clientBankData");
    await apiPut("bank_datas", origSettlement.clientBankData.id, clientBankUpdateData);
  }

  if (_.isObject(updateData.opponentLawyer)) {
    const opponentLawyerUpdateData = getUpdateData(updateData.opponentLawyer, origSettlement, "opponentLawyer");
    await persistPerson(origSettlement.opponentLawyer.id, opponentLawyerUpdateData);
  }

  if (_.isObject(updateData.opponentLawFirm)) {
    const opponentLawFirmUpdateData = getUpdateData(updateData.opponentLawFirm, origSettlement, "opponentLawFirm");
    await apiPut("organizations", origSettlement.opponentLawFirm.id, opponentLawFirmUpdateData);
  }

  if (_.has(updateData, "opponentLawFirm.address") && _.isObject(updateData.opponentLawFirm.address)) {
    const opponentLawFirmAddressUpdateData = getUpdateData(
      updateData.opponentLawFirm.address,
      origSettlement,
      "opponentLawFirm.address"
    );
    await persistAddress(origSettlement.opponentLawFirm.address.id, opponentLawFirmAddressUpdateData);
  }

  if (_.has(updateData, "responsibleCourt") && _.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origSettlement, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origSettlement.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origSettlement,
      "petitioner.residenceAddress"
    );
    await persistAddress(origSettlement.petitioner.residenceAddress.id, petitionerAddressUpdateData);
  }

  if (_.isObject(updateData.employerOrganization)) {
    const employerUpdateData = getUpdateData(updateData.employerOrganization, origSettlement, "employerOrganization");
    await ApiClient.put(`organizations/${origSettlement.employerOrganization.id}`, {
      body: JSON.stringify(sanitizeEmployerData(employerUpdateData)),
    });
  }

  if (_.has(updateData, "employerOrganization.address") && _.isObject(updateData.employerOrganization.address)) {
    const employerAddressUpdateData = getUpdateData(
      updateData.employerOrganization.address,
      origSettlement,
      "employerOrganization.address"
    );
    await persistAddress(origSettlement.employerOrganization.address.id, employerAddressUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origSettlement, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await ApiClient.put(`customer_insurances/${origSettlement.insurance.id}`, {
      body: JSON.stringify(insuranceUpdateData),
    });
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origSettlement,
      "insurance.policyHolder"
    );
    await persistPerson(origSettlement.insurance.policyHolder.id, policyHolderUpdateData);
  }

  let settlementUpdateData = getUpdateData(updateData, origSettlement);
  if (updateData.terminationObstacles) {
    settlementUpdateData["terminationObstacles"] = updateData.terminationObstacles;
  }
  await apiPut("settlements", origSettlement.id, settlementUpdateData);
};

export const persistTraffic = async (updateData, origTraffic) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origTraffic, "customer");
    !_.isEmpty(customerUpdateData) && (await persistCustomer(origTraffic.customer.id, customerUpdateData));
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origTraffic, "petitioner");
    !_.isEmpty(petitionerUpdateData) && (await persistPerson(origTraffic.petitioner.id, petitionerUpdateData));
  }

  if (_.has(updateData, "responsibleCourt") && _.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origTraffic, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origTraffic.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origTraffic,
      "petitioner.residenceAddress"
    );
    !_.isEmpty(petitionerAddressUpdateData) &&
      (await persistAddress(origTraffic.petitioner.residenceAddress.id, petitionerAddressUpdateData));
  }

  if (_.has(updateData, "responsibleCourtAddress") && _.isObject(updateData.responsibleCourtAddress)) {
    const responsibleCourtAddressUpdateData = getUpdateData(
      updateData.responsibleCourtAddress,
      origTraffic,
      "responsibleCourtAddress"
    );
    !_.isEmpty(responsibleCourtAddressUpdateData) &&
      (await persistAddress(origTraffic.responsibleCourtAddress.id, responsibleCourtAddressUpdateData));
  }

  if (_.has(updateData, "investigation") && _.isObject(updateData.investigation)) {
    const investigationUpdateData = getUpdateData(updateData.investigation, origTraffic, "investigation");
    !_.isEmpty(investigationUpdateData) &&
      (await ApiClient.put(`traffic_investigations/${origTraffic.investigation.id}`, {
        body: JSON.stringify(investigationUpdateData),
      }));
  }

  if (_.has(updateData, "insurance") && _.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origTraffic, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    !_.isEmpty(insuranceUpdateData) &&
      (await ApiClient.put(`customer_insurances/${origTraffic.insurance.id}`, {
        body: JSON.stringify(insuranceUpdateData),
      }));
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origTraffic,
      "insurance.policyHolder"
    );
    await persistPerson(origTraffic.insurance.policyHolder.id, policyHolderUpdateData);
  }

  const trafficUpdateData = getUpdateData(updateData, origTraffic);
  await apiPut("traffic", origTraffic.id, trafficUpdateData);
};

export const persistDocument = async (updateData, origDocument) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origDocument, "customer");
    await persistCustomer(origDocument.customer.id, customerUpdateData);
  }
  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origDocument, "petitioner");
    await persistPerson(origDocument.petitioner.id, petitionerUpdateData);
  }
  if (_.has(updateData, "insurance") && _.isObject(updateData.insurance)) {
    let insuranceUpdateData = getUpdateData(updateData.insurance, origDocument, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await ApiClient.put(`customer_insurances/${origDocument.insurance.id}`, {
      body: JSON.stringify(insuranceUpdateData),
    });
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origDocument,
      "insurance.policyHolder"
    );
    await persistPerson(origDocument.insurance.policyHolder.id, policyHolderUpdateData);
  }
  const apiUri = getProductData(origDocument.productClassName, "apiUri");
  const documentUpdateData = getUpdateData(updateData, origDocument);
  await apiPut(apiUri, origDocument.id, documentUpdateData);
};

//only update alimony specific document fields here, the others are handled in persistDocument
export const persistAlimony = async (updateData, origDocument) => {
  if (_.isObject(updateData.alimonyPetitioner?.person)) {
    const alimonyPetitionerPersonData = getUpdateData(
      updateData.alimonyPetitioner.person,
      origDocument,
      "alimonyPetitioner.person"
    );
    await persistPerson(origDocument.alimonyPetitioner.person.id, alimonyPetitionerPersonData);
  }

  if (_.isObject(updateData.alimonyPetitioner?.person?.residenceAddress)) {
    const alimonyPetitionerPersonResidenceAddress = getUpdateData(
      updateData.alimonyPetitioner.person.residenceAddress,
      origDocument,
      "alimonyPetitioner.person.residenceAddress"
    );
    await persistAddress(
      origDocument.alimonyPetitioner.person.residenceAddress.id,
      alimonyPetitionerPersonResidenceAddress
    );
  }

  if (_.isObject(updateData.alimonyPetitioner)) {
    const alimonyPetitionerUpdateData = getUpdateData(updateData.alimonyPetitioner, origDocument, "alimonyPetitioner");
    await persistAlimonyPerson(origDocument.alimonyPetitioner.id, alimonyPetitionerUpdateData);
  }

  if (_.isObject(updateData.alimonyPartner?.person)) {
    const alimonyPartnerPerson = getUpdateData(updateData.alimonyPartner.person, origDocument, "alimonyPartner.person");
    await persistPerson(origDocument.alimonyPartner.person.id, alimonyPartnerPerson);
  }

  if (_.isObject(updateData.alimonyPartner?.person?.residenceAddress)) {
    const alimonyPartnerPersonResidenceAddress = getUpdateData(
      updateData.alimonyPartner.person.residenceAddress,
      origDocument,
      "alimonyPartner.person.residenceAddress"
    );
    await persistAlimonyPerson(
      origDocument.alimonyPartner.person.residenceAddress.id,
      alimonyPartnerPersonResidenceAddress
    );
  }

  if (_.isObject(updateData.alimonyPartner)) {
    const alimonyPartnerUpdateData = getUpdateData(updateData.alimonyPartner, origDocument, "alimonyPartner");
    await persistAlimonyPerson(origDocument.alimonyPartner.id, alimonyPartnerUpdateData);
  }

  if (_.isObject(updateData.client?.residenceAddress)) {
    const clientResidenceAddress = getUpdateData(updateData.client.residenceAddress, origDocument, "residenceAddress");
    await persistAddress(origDocument.client.residenceAddress.id, clientResidenceAddress);
  }

  if (_.isObject(updateData.client)) {
    const alimonyClientUpdateData = getUpdateData(updateData.client, origDocument, "client");
    await persistPerson(origDocument.client.id, alimonyClientUpdateData);
  }

  const subEntityRequests = [];
  if (_.isObject(updateData.children)) {
    _.forEach(updateData.children, (child, index) => {
      const childUpdateData = getUpdateData(child, origDocument, `children[${index}]`);
      const childId = origDocument.children[index].id;
      subEntityRequests.push(() => persistAlimonyChild(childId, childUpdateData));
    });
  }
  await Promise.all(_.map(subEntityRequests, (request) => request()));
  await persistDocument(updateData, origDocument);
};

export const persistFamilyLawCase = async (updateData, origFamilyLawCase) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origFamilyLawCase, "customer");
    await persistCustomer(origFamilyLawCase.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.petitioner)) {
    const petitionerUpdateData = getUpdateData(updateData.petitioner, origFamilyLawCase, "petitioner");
    await persistPerson(origFamilyLawCase.petitioner.id, petitionerUpdateData);
  }

  if (_.isObject(updateData.client)) {
    const clientUpdateData = getUpdateData(updateData.client, origFamilyLawCase, "client");
    await persistPerson(origFamilyLawCase.client.id, clientUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    const insuranceUpdateData = getUpdateData(updateData.insurance, origFamilyLawCase, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await ApiClient.put(`customer_insurances/${origFamilyLawCase.insurance.id}`, {
      body: JSON.stringify(insuranceUpdateData),
    });
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origFamilyLawCase,
      "insurance.policyHolder"
    );
    await persistPerson(origFamilyLawCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.has(updateData, "petitioner.residenceAddress") && _.isObject(updateData.petitioner.residenceAddress)) {
    const petitionerAddressUpdateData = getUpdateData(
      updateData.petitioner.residenceAddress,
      origFamilyLawCase,
      "petitioner.residenceAddress"
    );
    await persistAddress(origFamilyLawCase.petitioner.residenceAddress.id, petitionerAddressUpdateData);
  }

  const apiUri = getProductData(origFamilyLawCase.productClassName, "apiUri");
  const basicCaseUpdateData = getUpdateData(updateData, origFamilyLawCase);
  await apiPut(apiUri, origFamilyLawCase.id, basicCaseUpdateData);
};

export const persistContractCase = async (updateData, origContractCase) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origContractCase, "customer");
    await persistCustomer(origContractCase.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    const insuranceUpdateData = getUpdateData(updateData.insurance, origContractCase, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await apiPut("customer_insurances", origContractCase.insurance.id, insuranceUpdateData);
  }

  if (_.isObject(updateData.clientBankData)) {
    const clientBankUpdateData = getUpdateData(updateData.clientBankData, origContractCase, "clientBankData");
    await apiPut("bank_datas", origContractCase.clientBankData.id, clientBankUpdateData);
  }

  if (_.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origContractCase, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origContractCase.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origContractCase,
      "insurance.policyHolder"
    );
    await persistPerson(origContractCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.isObject(updateData.client)) {
    const petitionerUpdateData = getUpdateData(updateData.client, origContractCase, "petitioner");
    await persistPerson(origContractCase.client.id, petitionerUpdateData);
  }

  if (_.isObject(updateData.clientLegalRepresentative)) {
    const clientLegalRepresentativeUpdateData = getUpdateData(
      updateData.clientLegalRepresentative,
      origContractCase,
      "clientLegalRepresentative"
    );
    await persistPerson(origContractCase.clientLegalRepresentative.id, clientLegalRepresentativeUpdateData);
  }

  if (
    _.has(updateData, "clientLegalRepresentative.residenceAddress") &&
    _.isObject(updateData.clientLegalRepresentative.residenceAddress)
  ) {
    const clientLegalRepresentativeAddressUpdateData = getUpdateData(
      updateData.clientLegalRepresentative.residenceAddress,
      origContractCase,
      "clientLegalRepresentative.residenceAddress"
    );
    await persistAddress(
      origContractCase.clientLegalRepresentative.residenceAddress.id,
      clientLegalRepresentativeAddressUpdateData
    );
  }

  if (_.isObject(updateData.secondClientLegalRepresentative)) {
    const secondClientLegalRepresentativeUpdateData = getUpdateData(
      updateData.secondClientLegalRepresentative,
      origContractCase,
      "secondClientLegalRepresentative"
    );
    await persistPerson(origContractCase.secondClientLegalRepresentative.id, secondClientLegalRepresentativeUpdateData);
  }

  if (
    _.has(updateData, "secondClientLegalRepresentative.residenceAddress") &&
    _.isObject(updateData.secondClientLegalRepresentative.residenceAddress)
  ) {
    const secondClientLegalRepresentativeAddressUpdateData = getUpdateData(
      updateData.secondClientLegalRepresentative.residenceAddress,
      origContractCase,
      "secondClientLegalRepresentative.residenceAddress"
    );
    await persistAddress(
      origContractCase.secondClientLegalRepresentative.residenceAddress.id,
      secondClientLegalRepresentativeAddressUpdateData
    );
  }

  if (_.has(updateData, "client.residenceAddress") && _.isObject(updateData.client.residenceAddress)) {
    const clientAddressUpdateData = getUpdateData(
      updateData.client.residenceAddress,
      origContractCase,
      "client.residenceAddress"
    );
    await persistAddress(origContractCase.client.residenceAddress.id, clientAddressUpdateData);
  }

  if (_.isObject(updateData.contractualPartnerPerson)) {
    const contractualPartnerPersonUpdateData = getUpdateData(
      updateData.contractualPartnerPerson,
      origContractCase,
      "contractualPartnerPerson"
    );
    await persistPerson(origContractCase.contractualPartnerPerson.id, contractualPartnerPersonUpdateData);
  }

  if (
    _.has(updateData, "contractualPartnerPerson.residenceAddress") &&
    _.isObject(updateData.contractualPartnerPerson.residenceAddress)
  ) {
    const contractualPartnerPersonAddressUpdateData = getUpdateData(
      updateData.contractualPartnerPerson.residenceAddress,
      origContractCase,
      "contractualPartnerPerson.residenceAddress"
    );
    await persistAddress(
      origContractCase.contractualPartnerPerson.residenceAddress.id,
      contractualPartnerPersonAddressUpdateData
    );
  }

  if (_.isObject(updateData.opponentLawyer)) {
    const opponentLawyerUpdateData = getUpdateData(updateData.opponentLawyer, origContractCase, "opponentLawyer");
    await persistPerson(origContractCase.opponentLawyer.id, opponentLawyerUpdateData);
  }

  if (_.isObject(updateData.opponentLawFirm)) {
    const opponentLawFirmUpdateData = getUpdateData(updateData.opponentLawFirm, origContractCase, "opponentLawFirm");
    await apiPut("organizations", origContractCase.opponentLawFirm.id, opponentLawFirmUpdateData);
  }

  if (_.has(updateData, "opponentLawFirm.address") && _.isObject(updateData.opponentLawFirm.address)) {
    const opponentLawFirmAddressUpdateData = getUpdateData(
      updateData.opponentLawFirm.address,
      origContractCase,
      "opponentLawFirm.address"
    );
    await persistAddress(origContractCase.opponentLawFirm.address.id, opponentLawFirmAddressUpdateData);
  }

  if (_.isObject(updateData.courtDate)) {
    const courtDateUpdateData = getUpdateData(updateData.courtDate, origContractCase, "courtDate");
    await persistAppointment(origContractCase.courtDate.id, courtDateUpdateData);
  }

  const apiUri = getProductData(origContractCase.productClassName, "apiUri");
  const contractCaseUpdateData = getUpdateData(updateData, origContractCase);

  await apiPut(apiUri, origContractCase.id, contractCaseUpdateData);
};

export const persistDataLeakContract = async (updateData, origContractCase) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origContractCase, "customer");
    await persistCustomer(origContractCase.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.personalAffection)) {
    const personalAffectionUpdateData = getUpdateData(
      updateData.personalAffection,
      origContractCase,
      "personalAffection"
    );

    await apiPut("data_leak_personal_affections", origContractCase.personalAffection.id, personalAffectionUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    const insuranceUpdateData = getUpdateData(updateData.insurance, origContractCase, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await apiPut("customer_insurances", origContractCase.insurance.id, insuranceUpdateData);
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origContractCase,
      "insurance.policyHolder"
    );
    await persistPerson(origContractCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.isObject(updateData.clientBankData)) {
    const clientBankUpdateData = getUpdateData(updateData.clientBankData, origContractCase, "clientBankData");
    await apiPut("bank_datas", origContractCase.clientBankData.id, clientBankUpdateData);
  }

  if (_.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origContractCase, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origContractCase.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origContractCase,
      "insurance.policyHolder"
    );
    await persistPerson(origContractCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.isObject(updateData.client)) {
    const petitionerUpdateData = getUpdateData(updateData.client, origContractCase, "petitioner");
    await persistPerson(origContractCase.client.id, petitionerUpdateData);
  }

  if (_.isObject(updateData.clientLegalRepresentative)) {
    const clientLegalRepresentativeUpdateData = getUpdateData(
      updateData.clientLegalRepresentative,
      origContractCase,
      "clientLegalRepresentative"
    );
    await persistPerson(origContractCase.clientLegalRepresentative.id, clientLegalRepresentativeUpdateData);
  }

  if (
    _.has(updateData, "clientLegalRepresentative.residenceAddress") &&
    _.isObject(updateData.clientLegalRepresentative.residenceAddress)
  ) {
    const clientLegalRepresentativeAddressUpdateData = getUpdateData(
      updateData.clientLegalRepresentative.residenceAddress,
      origContractCase,
      "clientLegalRepresentative.residenceAddress"
    );
    await persistAddress(
      origContractCase.clientLegalRepresentative.residenceAddress.id,
      clientLegalRepresentativeAddressUpdateData
    );
  }

  if (_.has(updateData, "client.residenceAddress") && _.isObject(updateData.client.residenceAddress)) {
    const clientAddressUpdateData = getUpdateData(
      updateData.client.residenceAddress,
      origContractCase,
      "client.residenceAddress"
    );
    await persistAddress(origContractCase.client.residenceAddress.id, clientAddressUpdateData);
  }

  if (_.isObject(updateData.contractualPartnerPerson)) {
    const contractualPartnerPersonUpdateData = getUpdateData(
      updateData.contractualPartnerPerson,
      origContractCase,
      "contractualPartnerPerson"
    );
    await persistPerson(origContractCase.contractualPartnerPerson.id, contractualPartnerPersonUpdateData);
  }

  if (
    _.has(updateData, "contractualPartnerPerson.residenceAddress") &&
    _.isObject(updateData.contractualPartnerPerson.residenceAddress)
  ) {
    const contractualPartnerPersonAddressUpdateData = getUpdateData(
      updateData.contractualPartnerPerson.residenceAddress,
      origContractCase,
      "contractualPartnerPerson.residenceAddress"
    );
    await persistAddress(
      origContractCase.contractualPartnerPerson.residenceAddress.id,
      contractualPartnerPersonAddressUpdateData
    );
  }

  if (_.isObject(updateData.opponentLawyer)) {
    const opponentLawyerUpdateData = getUpdateData(updateData.opponentLawyer, origContractCase, "opponentLawyer");
    await persistPerson(origContractCase.opponentLawyer.id, opponentLawyerUpdateData);
  }

  if (_.isObject(updateData.opponentLawFirm)) {
    const opponentLawFirmUpdateData = getUpdateData(updateData.opponentLawFirm, origContractCase, "opponentLawFirm");
    await apiPut("organizations", origContractCase.opponentLawFirm.id, opponentLawFirmUpdateData);
  }

  if (_.has(updateData, "opponentLawFirm.address") && _.isObject(updateData.opponentLawFirm.address)) {
    const opponentLawFirmAddressUpdateData = getUpdateData(
      updateData.opponentLawFirm.address,
      origContractCase,
      "opponentLawFirm.address"
    );
    await persistAddress(origContractCase.opponentLawFirm.address.id, opponentLawFirmAddressUpdateData);
  }

  if (_.isObject(updateData.courtDate)) {
    const courtDateUpdateData = getUpdateData(updateData.courtDate, origContractCase, "courtDate");
    await persistAppointment(origContractCase.courtDate.id, courtDateUpdateData);
  }

  const apiUri = getProductData(origContractCase.productClassName, "apiUri");
  const contractCaseUpdateData = getUpdateData(updateData, origContractCase);
  await apiPut(apiUri, origContractCase.id, contractCaseUpdateData);
};

export const persistRentalContractCase = async (updateData, origRentalContractCase) => {
  if (_.isObject(updateData.customer)) {
    const customerUpdateData = getUpdateData(updateData.customer, origRentalContractCase, "customer");
    await persistCustomer(origRentalContractCase.customer.id, customerUpdateData);
  }

  if (_.isObject(updateData.insurance)) {
    const insuranceUpdateData = getUpdateData(updateData.insurance, origRentalContractCase, "insurance");
    if (_.has(updateData.insurance, "insurance.id")) {
      insuranceUpdateData.insurance = `/insurances/${updateData.insurance.insurance.id}`;
    }
    await apiPut("customer_insurances", origRentalContractCase.insurance.id, insuranceUpdateData);
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origRentalContractCase,
      "insurance.policyHolder"
    );
    await persistPerson(origRentalContractCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.isObject(updateData.clientBankData)) {
    const clientBankUpdateData = getUpdateData(updateData.clientBankData, origRentalContractCase, "clientBankData");
    await apiPut("bank_datas", origRentalContractCase.clientBankData.id, clientBankUpdateData);
  }

  if (_.isObject(updateData.responsibleCourt)) {
    const courtUpdateData = getUpdateData(updateData.responsibleCourt, origRentalContractCase, "responsibleCourt");
    !_.isEmpty(courtUpdateData) && (await persistCourt(origRentalContractCase.responsibleCourt.id, courtUpdateData));
  }

  if (_.has(updateData, "insurance.policyHolder") && _.isObject(updateData.insurance.policyHolder)) {
    const policyHolderUpdateData = getUpdateData(
      updateData.insurance.policyHolder,
      origRentalContractCase,
      "insurance.policyHolder"
    );
    await persistPerson(origRentalContractCase.insurance.policyHolder.id, policyHolderUpdateData);
  }

  if (_.isObject(updateData.client)) {
    const petitionerUpdateData = getUpdateData(updateData.client, origRentalContractCase, "petitioner");
    await persistPerson(origRentalContractCase.client.id, petitionerUpdateData);
  }

  if (_.isObject(updateData.clientLegalRepresentative)) {
    const clientLegalRepresentativeUpdateData = getUpdateData(
      updateData.clientLegalRepresentative,
      origRentalContractCase,
      "clientLegalRepresentative"
    );
    await persistPerson(origRentalContractCase.clientLegalRepresentative.id, clientLegalRepresentativeUpdateData);
  }

  if (
    _.has(updateData, "clientLegalRepresentative.residenceAddress") &&
    _.isObject(updateData.clientLegalRepresentative.residenceAddress)
  ) {
    const clientLegalRepresentativeAddressUpdateData = getUpdateData(
      updateData.clientLegalRepresentative.residenceAddress,
      origRentalContractCase,
      "clientLegalRepresentative.residenceAddress"
    );
    await persistAddress(
      origRentalContractCase.clientLegalRepresentative.residenceAddress.id,
      clientLegalRepresentativeAddressUpdateData
    );
  }

  if (_.has(updateData, "client.residenceAddress") && _.isObject(updateData.client.residenceAddress)) {
    const clientAddressUpdateData = getUpdateData(
      updateData.client.residenceAddress,
      origRentalContractCase,
      "client.residenceAddress"
    );
    await persistAddress(origRentalContractCase.client.residenceAddress.id, clientAddressUpdateData);
  }

  if (_.isObject(updateData.contractualPartnerPerson)) {
    const contractualPartnerPersonUpdateData = getUpdateData(
      updateData.contractualPartnerPerson,
      origRentalContractCase,
      "contractualPartnerPerson"
    );
    await persistPerson(origRentalContractCase.contractualPartnerPerson.id, contractualPartnerPersonUpdateData);
  }

  if (
    _.has(updateData, "contractualPartnerPerson.residenceAddress") &&
    _.isObject(updateData.contractualPartnerPerson.residenceAddress)
  ) {
    const contractualPartnerPersonAddressUpdateData = getUpdateData(
      updateData.contractualPartnerPerson.residenceAddress,
      origRentalContractCase,
      "contractualPartnerPerson.residenceAddress"
    );
    await persistAddress(
      origRentalContractCase.contractualPartnerPerson.residenceAddress.id,
      contractualPartnerPersonAddressUpdateData
    );
  }

  if (_.isObject(updateData.opponentLawyer)) {
    const opponentLawyerUpdateData = getUpdateData(updateData.opponentLawyer, origRentalContractCase, "opponentLawyer");
    await persistPerson(origRentalContractCase.opponentLawyer.id, opponentLawyerUpdateData);
  }

  if (_.isObject(updateData.opponentLawFirm)) {
    const opponentLawFirmUpdateData = getUpdateData(
      updateData.opponentLawFirm,
      origRentalContractCase,
      "opponentLawFirm"
    );
    await apiPut("organizations", origRentalContractCase.opponentLawFirm.id, opponentLawFirmUpdateData);
  }

  if (_.has(updateData, "opponentLawFirm.address") && _.isObject(updateData.opponentLawFirm.address)) {
    const opponentLawFirmAddressUpdateData = getUpdateData(
      updateData.opponentLawFirm.address,
      origRentalContractCase,
      "opponentLawFirm.address"
    );
    await persistAddress(origRentalContractCase.opponentLawFirm.address.id, opponentLawFirmAddressUpdateData);
  }

  if (_.isObject(updateData.courtDate)) {
    const courtDateUpdateData = getUpdateData(updateData.courtDate, origRentalContractCase, "courtDate");
    await persistAppointment(origRentalContractCase.courtDate.id, courtDateUpdateData);
  }

  if (_.isObject(updateData.contractualPartnerLegalEntity)) {
    let landlordUpdateData = getUpdateData(
      updateData.contractualPartnerLegalEntity,
      origRentalContractCase,
      "contractualPartnerLegalEntity"
    );

    landlordUpdateData.additionalRepresentatives = updateData.contractualPartnerLegalEntity.additionalRepresentatives;

    await ApiClient.put(`organizations/${origRentalContractCase.contractualPartnerLegalEntity.id}`, {
      body: JSON.stringify(sanitizeLandlordData(landlordUpdateData)),
    });
  }

  if (
    _.has(updateData, "contractualPartnerLegalEntity.address") &&
    _.isObject(updateData.contractualPartnerLegalEntity.address)
  ) {
    const landlordAddressUpdateData = getUpdateData(
      updateData.contractualPartnerLegalEntity.address,
      origRentalContractCase,
      "contractualPartnerLegalEntity.address"
    );
    await persistAddress(origRentalContractCase.contractualPartnerLegalEntity.address.id, landlordAddressUpdateData);
  }

  if (_.isObject(updateData.propertyManager)) {
    const propertyManagerUpdateData = getUpdateData(
      updateData.propertyManager,
      origRentalContractCase,
      "propertyManager"
    );
    await ApiClient.put(`organizations/${origRentalContractCase.propertyManager.id}`, {
      body: JSON.stringify(sanitizeOrganizationData(propertyManagerUpdateData)),
    });
  }

  if (_.has(updateData, "propertyManager.address") && _.isObject(updateData.propertyManager.address)) {
    const propertyManagerAddressUpdateData = getUpdateData(
      updateData.propertyManager.address,
      origRentalContractCase,
      "propertyManager.address"
    );
    await persistAddress(
      origRentalContractCase.propertyManager.address.id,
      sanitizeAddressData(propertyManagerAddressUpdateData)
    );
  }

  const apiUri = getProductData(origRentalContractCase.productClassName, "apiUri");
  const contractCaseUpdateData = getUpdateData(updateData, origRentalContractCase);
  await apiPut(apiUri, origRentalContractCase.id, contractCaseUpdateData);
};

export const persistAddress = async (id, addressData) => {
  return await apiPut("addresses", id, addressData);
};

const persistInsurance = async (id, insuranceUpdateData) => {
  return await apiPut("customer_insurances", id, insuranceUpdateData);
};

const persistChild = async (id, childData) => {
  return await apiPut("divorce_children", id, childData);
};

export const persistCustomer = async (id, customerData) => {
  return await apiPut("customers", id, customerData);
};

export const persistPerson = async (id, personData) => {
  return await apiPut("people", id, personData);
};

export const persistCourt = async (id, courtData) => {
  return await apiPut("courts", id, courtData);
};

const persistAppointment = async (id, appointmentData) => {
  return await apiPut("appointments", id, appointmentData);
};

const persistAlimonyPerson = async (id, alimonyData) => {
  return await apiPut("alimony_people", id, alimonyData);
};

const persistAlimonyChild = async (id, childData) => {
  return await apiPut("alimony_children", id, childData);
};

const getUpdateData = (object, product = null, basePath = "") => {
  let resultSet = _.pickBy(object, (value) => {
    return !_.isObjectLike(value);
  });

  if (!_.isEmpty(resultSet)) {
    if (product !== null) {
      resultSet.backofficeCaseId = product.backofficeCase.id;
    }
    resultSet.basePath = basePath;
  }

  resultSet = _.mapValues(resultSet, (value) => {
    return value !== "" ? value : null;
  });

  return resultSet;
};

export const difference = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
};

const trimValueIfSet = (field, values) => {
  if (values[field]) {
    values[field] = _.trim(values[field]);
  }
  return values;
};

export const sanitizePersonData = (personData) => {
  let sanitizedPersonData = _.cloneDeep(personData);
  sanitizedPersonData = trimValueIfSet("givenName", sanitizedPersonData);
  sanitizedPersonData = trimValueIfSet("familyName", sanitizedPersonData);
  return sanitizedPersonData;
};

const sanitizeAddressData = (addressData) => {
  let sanitizedAddressData = _.cloneDeep(addressData);
  sanitizedAddressData = trimValueIfSet("streetAddress", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("postalCode", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("addressLocality", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("postOfficeBoxAddressLocality", sanitizedAddressData);
  sanitizedAddressData = trimValueIfSet("postOfficeBoxPostalCode", sanitizedAddressData);
  return sanitizedAddressData;
};

export const sanitizeOrganizationData = (organizationData) => {
  let sanitizedOrganizationData = _.cloneDeep(organizationData);
  sanitizedOrganizationData = trimValueIfSet("name", sanitizedOrganizationData);
  sanitizedOrganizationData = trimValueIfSet("legalName", sanitizedOrganizationData);
  sanitizedOrganizationData = trimValueIfSet("email", sanitizedOrganizationData);
  sanitizedOrganizationData = trimValueIfSet("telephone", sanitizedOrganizationData);
  return sanitizedOrganizationData;
};

export const sanitizeEmployerData = (employerData) => {
  let sanitizedEmployerData = _.cloneDeep(employerData);
  sanitizedEmployerData = trimValueIfSet("name", sanitizedEmployerData);
  sanitizedEmployerData = trimValueIfSet("address.streetAddress", sanitizedEmployerData);
  sanitizedEmployerData = trimValueIfSet("postalCode", sanitizedEmployerData);
  sanitizedEmployerData = trimValueIfSet("addressLocality", sanitizedEmployerData);
  return sanitizedEmployerData;
};

export const sanitizeLandlordData = (landlordData) => {
  let sanitizedLandlordData = _.cloneDeep(landlordData);
  sanitizedLandlordData = trimValueIfSet("name", sanitizedLandlordData);
  sanitizedLandlordData = trimValueIfSet("address.streetAddress", sanitizedLandlordData);
  sanitizedLandlordData = trimValueIfSet("postalCode", sanitizedLandlordData);
  sanitizedLandlordData = trimValueIfSet("addressLocality", sanitizedLandlordData);
  return sanitizedLandlordData;
};
