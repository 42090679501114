import { getProductNameBy } from "../../services/Product/ProductService";
import { getActivityIcon, getActivityLabel } from "../../services/Activities/activityTypes";
import { Tooltip } from "@mui/material";
import React from "react";
import { Activity } from "../../types/Activity";

type ActivityIconProps = {
  activity: Activity;
  withToolTip?: boolean;
};
const ActivityIcon = ({ activity, withToolTip = false }: ActivityIconProps) => {
  const productClassName = getProductNameBy("lbProductClass", activity.case.lbProductClass)!;
  return (
    <Tooltip title={withToolTip ? getActivityLabel(productClassName, activity.type) : ""}>
      {getActivityIcon(productClassName, activity.type)}
    </Tooltip>
  );
};

export default ActivityIcon;
