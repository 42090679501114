import React from "react";
import {FormControl, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import paidTaskListStyle from "./paidTaskListStyle";

type PaidTaskFilterProps = {
  paidTaskFilter: string;
  setPaidTaskFilter: Function;
  displayType: string;
};

const PaidTaskFilter = ({ paidTaskFilter, setPaidTaskFilter, displayType }: PaidTaskFilterProps) => {

  if (displayType === "assignableView") {
    return null;
  }

  const handleFilterChange = (event: SelectChangeEvent) => {
    setPaidTaskFilter(event.target.value);
  };

  const getMenuItems = () => {
    switch (displayType) {
      case "assigneeView":
        return [
          <MenuItem value={"assigned"} key={"assigned"}>
            Offen
          </MenuItem>,
          <MenuItem value={"inReview"} key={"inReview"}>
            In Prüfung
          </MenuItem>,
          <MenuItem value={"approved"} key={"approved"}>
            Abgenommen
          </MenuItem>,
        ];
      case "internalView":
        return [
          <MenuItem value={"assignmentDeadline"} key={"assignmentDeadline"}>
            Annahme überfällig
          </MenuItem>,
          <MenuItem value={"doneDeadline"} key={"doneDeadline"}>
            Abgabe überfällig
          </MenuItem>,
          <MenuItem value={"open"} key={"open"}>
            Offen
          </MenuItem>,
          <MenuItem value={"requested"} key={"requested"}>
            Angefragt
          </MenuItem>,
          <MenuItem value={"assigned"} key={"assigned"}>
            In Bearbeitung
          </MenuItem>,
          <MenuItem value={"inReview"} key={"inReview"}>
            In Prüfung
          </MenuItem>,
          <MenuItem value={"approved"} key={"approved"}>
            Abgenommen
          </MenuItem>,
        ];
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Status Ausschreibung</InputLabel>
      <Select label={"Status Ausschreibung"} onChange={handleFilterChange} value={paidTaskFilter} sx={paidTaskListStyle.select}>
        {getMenuItems()}
      </Select>
    </FormControl>
  );
};

export default PaidTaskFilter;
