import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import useForm, { UseFormProps } from "../../../../hooks/useForm";
import _ from "lodash";
import FormElement, { FormElementPropsWithoutProduct } from "../FormElement";
import addressFields from "../../../../services/Product/FormDefinitions/modules/addressFields";
import useStyles from "../Full/caseFullFormStyle";
import ApiClient from "../../../../services/ApiClient";
import { persistAddress, persistPerson, sanitizePersonData } from "../../../../services/Persist/Persister";
import { translate } from "../../../../services/Translations/translatorService";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonLoading from "../../../Button/ButtonLoading";
import {
  emailDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  requiredFieldDefault,
} from "../../../../services/validationRules";
import Typography from "@mui/material/Typography";
import { AbstractCase } from "../../../../types/AbstractCase";
import { Person } from "../../../../types/Person";

type ProcessParticipantPersonFormProps = {
  product: AbstractCase;
  participant?: Person;
  refreshPage: Function;
};
const ProcessParticipantPersonForm = ({ product, participant, refreshPage }: ProcessParticipantPersonFormProps) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const formElements: FormElementPropsWithoutProduct[] = [
    {
      type: "ValidatorSelect",
      path: "participant.labelType",
      validators: requiredFieldDefault,
      options: () => [
        { value: "expert" },
        { value: "witness" },
        { value: "legalRepresentative" },
        { value: "judge" },
        { value: "interpreter" },
        { value: "insolvencyAdministrator" },
        { value: "other" },
      ],
    },
    {
      type: "ValidatorTextField",
      path: `participant.email`,
      validators: emailDefault,
    },
    {
      type: "ValidatorTextField",
      path: `participant.telephone`,
      validators: isPhoneNumberWithoutSpecialCharactersDefault,
    },
    {
      type: "ValidatorSelect",
      path: `participant.gender`,
      validators: requiredFieldDefault,
      options: () => [{ value: "male" }, { value: "female" }],
    },
    {
      type: "ValidatorTextField",
      path: `participant.givenName`,
      validators: requiredFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `participant.familyName`,
      validators: requiredFieldDefault,
    },
    ...addressFields({ path: "participant.residenceAddress" }),
  ];

  const deleteParticipant = async () => {
    setIsLoading(true);
    await ApiClient.delete("people/" + participant!.id);
    await refreshPage();
    setIsLoading(false);
  };

  const onSubmit: UseFormProps["onSubmit"] = async ({ values }) => {
    const participantData = _.merge({}, values.participant, {
      residenceAddress: undefined,
      type: "processParticipant",
      customer: product.customer!["@id"],
      caseId: product.id,
    });

    if (!participant) {
      setIsLoading(true);
      const participantPerson = await ApiClient.post("people", {
        body: JSON.stringify(sanitizePersonData(participantData)),
      });
      await persistAddress(participantPerson.residenceAddress.id, values.participant.residenceAddress);
    } else {
      setButtonIsLoading(true);
      await persistAddress(participant.residenceAddress.id, values.participant.residenceAddress);
      await persistPerson(participant.id, participantData);
    }
    await refreshPage();
    setIsLoading(false);
    setButtonIsLoading(false);
  };

  const initialValues = {
    participant: _.merge(
      {},
      participant || {
        labelType: "",
        gender: "",
        givenName: "",
        familyName: "",
        email: "",
        telephone: "",
        residenceAddress: {
          streetAddress: "",
          postalCode: "",
          addressLocality: "",
        },
      }
    ),
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: product.id + "-" + (participant && participant.id),
    clearFormAfterSubmit: !participant,
  });

  const getLabel = (participant: Person | undefined) => {
    if (!participant) {
      return "Neue Person hinzufügen";
    }

    const labelType = translate("participant.labelType.values." + participant.labelType);
    const gender = translate("participant.gender.values." + participant.gender);
    return labelType + ": " + gender + " " + participant.givenName + " " + participant.familyName;
  };

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ mt: 1, mb: 1 }}>
          {getLabel(participant)}
        </Typography>
      </Grid>
      {_.map(formElements, (formElementProps) => {
        if (formElementProps.isHidden && formElementProps.isHidden({ values: useFormProps.values, product })) {
          return null;
        }

        const fullWidth = formElementProps.additionalProps && formElementProps.additionalProps.fullWidth;
        return (
          <Grid key={formElementProps.path} item xs={12} md={!fullWidth && 6} lg={!fullWidth && 4}>
            <FormElement product={product} {...formElementProps} {...useFormProps} />
          </Grid>
        );
      })}
      <Grid item xs={12} md={4} />
      <Grid item xs={12} md={4}>
        {participant && (
          <Button fullWidth onClick={deleteParticipant}>
            Entfernen
          </Button>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <ButtonLoading fullWidth variant="contained" onClick={useFormProps.handleSubmit} isLoading={buttonIsLoading}>
          Speichern
        </ButtonLoading>
      </Grid>
    </Grid>
  );
};
export default ProcessParticipantPersonForm;
