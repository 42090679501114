export const overlayStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "auto",
    background: "#33333380",
    zIndex: 1200,
  },
};
