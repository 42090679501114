import React, { useCallback, useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import _ from "lodash";
import ActivityOverviewHeader from "../ActivityOverviewHeader/ActivityOverviewHeader";
import UnassignedActivities from "../UnassignedActivities/UnassignedActivities";
import moment from "moment";
import ApiClient from "../../../services/ApiClient";
import {
  buildApiFilters,
  buildSearchParams,
  postProcessFilterActivities,
  prepareFilters,
} from "../../../services/Activities/activityFunctions";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import activityStyle from "../ActivityTable/activityStyle";
import { Paper, TableContainer, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import ActivityTable from "../ActivityTable/ActivityTable";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { userHasOneOfTheseRoles } from "../../../services/backofficeUserService";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { BackofficeUser, Roles } from "../../../types/BackofficeUser";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Activity } from "../../../types/Activity";
import LawyerAbsenceActivities from "../LawyerAbsenceActivities/LawyerAbsenceActivities";
import TaggedTab from "../../Tabs/TaggedTab";

const ActivityOverview = () => {
  const currentUser = useCurrentUser();
  const { agents, backofficeUsers } = useBackofficeUser();
  const initialFilters = prepareFilters(currentUser, agents, "activityFilters", "all", "open");
  const initialTab = localStorage.getItem("savedTab") || "activities";
  const [productFilter, setProductFilter] = useState(initialFilters.productFilter);
  const [activityTypeFilter, setActivityTypeFilter] = useState<string>(initialFilters.activityTypeFilter);
  const [userFilter, setUserFilter] = useState(initialFilters.userFilter);
  const [timespanFilter, setTimespanFilter] = useState(initialFilters.timespanFilter);
  const [subjectFilter, setSubjectFilter] = useState(initialFilters.subjectFilter);
  const [openOrDoneFilter, setOpenOrDoneFilter] = useState(initialFilters.openOrDoneFilter);
  const [userActivitiesToday, setUserActivitiesToday] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [filteredActivities, setFilteredActivities] = useState<Activity<BackofficeUser>[]>([]);
  const [mailAndDocumentActivities, setMailAndDocumentActivities] = useState<Activity<BackofficeUser>[]>([]);
  const [checkCaseActivities, setCheckCaseActivities] = useState<Activity<BackofficeUser>[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const dataSource = buildSearchParams(
    _.merge(
      buildApiFilters({
        productFilter: productFilter,
        activityTypeFilter: activityTypeFilter,
        subjectFilter: subjectFilter,
        userFilter: userFilter,
        timespanFilter: timespanFilter,
        openOrDoneFilter: openOrDoneFilter,
        backofficeUsers: backofficeUsers,
      }),
      {
        refreshCounter: refreshCounter,
      }
    )
  );

  const { activities, isLoading } = useActivitiesFetching(dataSource.toString(), backofficeUsers);

  const updateUserActivitiesToday = useCallback(async () => {
    if (["all", "accountManager", "lawyer"].includes(userFilter)) {
      // this is only used to show the number of activities, which is not needed if all users' activities are shown
      setUserActivitiesToday([]);
      return;
    }

    const filters = {
      assignedUser: userFilter,
      "dueDate[before]": moment().format("YYYY-MM-DD"),
      done: false,
      deleted: false,
      pagination: false,
    };

    let searchParams = new URLSearchParams();
    _.map(filters, (value, key) => {
      searchParams.append(key, value);
    });

    const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
    setUserActivitiesToday(userActivitiesResult["hydra:member"]);
  }, [userFilter]);

  const updateActivities = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    localStorage.setItem(
      "activityFilters",
      JSON.stringify(
        _.merge(
          {},
          {
            productFilter: productFilter,
            activityTypeFilter: activityTypeFilter,
            userFilter: userFilter,
            timespanFilter: timespanFilter,
            openOrDoneFilter: openOrDoneFilter,
          }
        )
      )
    );
  }, [productFilter, userFilter, timespanFilter, activityTypeFilter, openOrDoneFilter]);

  useEffect(() => {
    updateUserActivitiesToday();
  }, [userFilter, updateUserActivitiesToday]);

  useEffect(() => {
    let filteredActivities = isLoading ? [] : postProcessFilterActivities(activities!, timespanFilter);
    setMailAndDocumentActivities(
      _.remove(filteredActivities, (activity) =>
        _.includes(["mail", "email", "check_document", "message"], activity.type)
      )
    );
    setCheckCaseActivities(_.remove(filteredActivities, (activity) => activity.type === "check_case"));
    setFilteredActivities(filteredActivities);
  }, [
    activities,
    isLoading,
    setMailAndDocumentActivities,
    setCheckCaseActivities,
    setFilteredActivities,
    timespanFilter,
    openOrDoneFilter,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    localStorage.setItem("savedTab", newValue);
  };

  return (
    <PageContentContainer>
      {userHasOneOfTheseRoles(currentUser, [Roles.accountmanager, Roles.admin]) && (
        <UnassignedActivities updateFilteredActivities={() => setRefreshCounter(refreshCounter + 1)} />
      )}

      {userHasOneOfTheseRoles(currentUser, [Roles.lawyer]) && (
        <LawyerAbsenceActivities
          refreshCounter={refreshCounter}
          isLoading={isLoading}
          setSubjectFilter={setSubjectFilter}
          setOpenOrDoneFilter={setOpenOrDoneFilter}
          setProductFilter={setProductFilter}
          setActivityTypeFilter={setActivityTypeFilter}
          setUserFilter={setUserFilter}
          setTimespanFilter={setTimespanFilter}
        />
      )}

      <PageHeadline main="Aktivitätenübersicht" />
      <ActivityOverviewHeader
        setProductFilter={(data) => {
          updateActivities();
          setProductFilter(data);
        }}
        productFilter={productFilter}
        setActivityTypeFilter={(data: string) => {
          updateActivities();
          setActivityTypeFilter(data);
        }}
        activityTypeFilter={activityTypeFilter}
        userFilter={userFilter}
        setUserFilter={(data) => {
          updateActivities();
          setUserFilter(data);
        }}
        timespanFilter={timespanFilter}
        setTimespanFilter={(data) => {
          updateActivities();
          setTimespanFilter(data);
        }}
        setSubjectFilter={(data) => {
          updateActivities();
          setSubjectFilter(data);
        }}
        userActivitiesToday={userActivitiesToday}
        agents={agents}
        openOrDoneFilter={openOrDoneFilter}
        setOpenOrDoneFilter={(data) => {
          updateActivities();
          setOpenOrDoneFilter(data);
        }}
      />
      <TableContainer component={Paper}>
        <TabContext value={selectedTab}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <TaggedTab label={"Aktivitäten"} value={"activities"} entryCount={filteredActivities?.length} />
            <TaggedTab label={"Posteingang"} value={"inbox"} entryCount={mailAndDocumentActivities?.length} />
            <TaggedTab label={"Neue Fälle"} value={"checkCases"} entryCount={checkCaseActivities?.length} />
          </Tabs>
          <TabPanel sx={activityStyle.tabPanel} value="activities">
            {selectedTab === "activities" && (
              <ActivityTable
                isLoading={isLoading}
                activities={filteredActivities}
                update={updateActivities}
                isCompletedView={openOrDoneFilter === "done"}
              />
            )}
          </TabPanel>
          <TabPanel sx={activityStyle.tabPanel} value="inbox">
            {selectedTab === "inbox" && (
              <ActivityTable
                isLoading={isLoading}
                activities={mailAndDocumentActivities}
                update={updateActivities}
                isCompletedView={openOrDoneFilter === "done"}
              />
            )}
          </TabPanel>
          <TabPanel sx={activityStyle.tabPanel} value="checkCases">
            {selectedTab === "checkCases" && (
              <ActivityTable
                isLoading={isLoading}
                activities={checkCaseActivities}
                update={updateActivities}
                isCompletedView={openOrDoneFilter === "done"}
              />
            )}
          </TabPanel>
        </TabContext>
      </TableContainer>
    </PageContentContainer>
  );
};

export default ActivityOverview;
