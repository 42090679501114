import React, { useState } from "react";
import { PAID_TASK_STATUS_ASSIGNED } from "../paidTaskStatus";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import { useSnackbar } from "notistack";
import ApiClient from "../../../services/ApiClient";
import { PaidTask } from "../../../types/PaidTask";

type RemindExternalLawyerModalProps = {
  paidTask: PaidTask;
  open: boolean;
  closeModal: Function;
};

const RemindExternalLawyerModal = ({ paidTask, open, closeModal }: RemindExternalLawyerModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (paidTask.status !== PAID_TASK_STATUS_ASSIGNED) {
    return null;
  }

  const sendReminder = async () => {
    setIsLoading(true);

    await ApiClient.post("paid_task_actions/" + paidTask.id + "/reminder");

    enqueueSnackbar("Erinnerung erfolgreich versendet", {

      variant: "custom",
      buttonType: "link",
      buttonLink: getCaseLinkByBackofficeCase(paidTask.backofficeCase),
      buttonTarget: "_self",
      buttonText: "Zum Fall",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsLoading(false);
    closeModal();
  };

  return (
    <LegalbirdIoModal
      handleClose={() => closeModal()}
      open={open}
      title={"Anwalt an Erledigung erinnern"}
      submitButton={
        <ButtonLoading variant={"contained"} color={"primary"} onClick={sendReminder} isLoading={isLoading}>
          Anwalt erinnern
        </ButtonLoading>
      }
    >
      Möchten Sie den Anwalt an die Erledigung der Aufgabe erinnern? Es wird dann eine E-Mail an den betreffenden Anwalt
      samt Link zu dieser Aufgabe verschickt.
    </LegalbirdIoModal>
  );
};

export default RemindExternalLawyerModal;
