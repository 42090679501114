import React, { useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import ButtonLoading from "../Button/ButtonLoading";
import ApiClient from "../../services/ApiClient";
import { editorStateToHTML, htmlToEditorState } from "../../services/Editor/editorFunctions";
import { ReleaseUpdate } from "../../types/ReleaseUpdate";
import _ from "lodash";
import { Roles } from "../../types/BackofficeUser";
import releaseUpdateStyles from "./releaseUpdatesStyles";
import { Link, useNavigate } from "react-router-dom";

type EditReleaseUpdateProps = {
  releaseUpdate: ReleaseUpdate;
};

export default function EditReleaseUpdate({ releaseUpdate }: EditReleaseUpdateProps) {
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(htmlToEditorState(releaseUpdate.content));
  //only check role accountmanager because its always there for internal release updates
  const isInternalReleaseUpdate = _.includes(releaseUpdate.visibleToRoles, Roles.accountmanager);
  const isExternalReleaseUpdate = _.includes(releaseUpdate.visibleToRoles, Roles.external);
  const [isLoading, setIsLoading] = useState(false);

  const releaseUpdatesPageLink = "/services/tools/release-updates";

  const handleSaveChanges = async () => {
    setIsLoading(true);
    await ApiClient.put(releaseUpdate["@id"], {
      body: JSON.stringify({
        content: editorStateToHTML(editorState),
      }),
    });
    setIsLoading(false);
    navigate(releaseUpdatesPageLink);
  };

  return (
    <ContentBox>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
        <Grid item xs={12}>
          <Editor
            toolbar={{
              options: ["inline", "list", "textAlign", "history", "link"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            stripPastedStyles
            editorState={editorState}
            onEditorStateChange={setEditorState}
            localization={{ locale: "de" }}
            editorStyle={releaseUpdateStyles.editorStyle}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h4"} sx={{ textAlign: "center" }}>
            Sichtbarkeit
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            labelPlacement="start"
            control={<Checkbox checked={isInternalReleaseUpdate} disabled />}
            label={"Intern"}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            labelPlacement="start"
            control={<Checkbox checked={isExternalReleaseUpdate} disabled />}
            label={"Extern"}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item>
          <Button fullWidth={false} sx={releaseUpdateStyles.button} component={Link} to={releaseUpdatesPageLink}>
            Abbrechen
          </Button>
        </Grid>
        <Grid item>
          <ButtonLoading
            isLoading={isLoading}
            fullWidth={false}
            variant={"contained"}
            disabled={
              (!isExternalReleaseUpdate && !isInternalReleaseUpdate) || !editorState.getCurrentContent().hasText()
            }
            onClick={handleSaveChanges}
            sx={releaseUpdateStyles.button}
          >
            Änderung speichern
          </ButtonLoading>
        </Grid>
      </Grid>
    </ContentBox>
  );
}
