/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import { apiPost } from "../../../services/Api/apiCall";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import useForm from "../../../hooks/useForm";
import { UseForm } from "../../../types/UseForm";
import { InquiryToCustomer } from "../../../types/InquiryToCustomer";

type RequestPersonalAffectionModalProps = {
  product: AbstractCase;
  open: boolean;
  handleClose: Function;
};

export default function RequestPersonalAffectionModal({
  open,
  handleClose,
  product,
}: RequestPersonalAffectionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  let inquiryToCustomerFilter = {
    productId: product.id,
    "status[lt]": 40,
  };
  const { data: inquiryToCustomerCollection } = useQuery(
    queryKeys.collection("inquiry_to_customers", inquiryToCustomerFilter),
    () => fetchCollection("inquiry_to_customers", inquiryToCustomerFilter)
  );

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await apiPost(`/lb/services/request_personal_affection/${product.id}`, {
        createdBy: currentUser["@id"],
      });
      enqueueSnackbar("Die persönliche Betroffenheit wurde erfolgreich angefragt.", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar("Die Anfrage zur persönlichen Betroffenheit konnte nicht erstellt werden.", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    clearForm();
    setIsLoading(false);
    handleClose();
  };

  const { clearForm }: UseForm = useForm({
    onSubmit,
  });

  if (!inquiryToCustomerCollection) {
    return null;
  }

  const getRequestPersonalAffectionAllowed = (product: AbstractCase, inquiryToCustomers: Array<InquiryToCustomer>) => {
    if (!product.responsibleLawyer) {
      return {
        allowed: false,
        message: "Persönliche Betroffenheit kann nur abgefragt werden, wenn ein Anwalt am Fall hinterlegt wurde.",
      };
    }
    if (inquiryToCustomers.length >= 2) {
      return {
        allowed: false,
        message: "Es sind bereits zwei Anfragen an die Mandantschaft gestellt worden.",
      };
    }
    return { allowed: true };
  };

  const { allowed, message } = getRequestPersonalAffectionAllowed(product, inquiryToCustomerCollection["hydra:member"]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Persönliche Betroffenheit"}
      actionsInfo={message ? <Typography sx={{ textAlign: "center", color: "red" }}>{message}</Typography> : null}
      submitButton={
        <ButtonLoading
          onClick={onSubmit}
          color="primary"
          variant={"contained"}
          isLoading={isLoading}
          disabled={!allowed}
        >
          Betroffenheit abfragen
        </ButtonLoading>
      }
    >
      <Typography align={"center"}>
        Mit dem Klick auf “Betroffenheit abfragen” wird dem Mandanten eine Abfrage erstellt, in dem er seine persönliche
        Betroffenheit durch die Datenweitergabe an die Auskunfteien schildern kann.
      </Typography>
    </LegalbirdIoModal>
  );
}
