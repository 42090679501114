export const bankPaymentEntriesStyle = {
  paper: {
    padding: "2rem",
  },
  headline: {
    textAlign: "center",
  },
  cell: {
    padding: "7px",
    width: "18%",
  },
  cellSmall: {
    padding: "7px",
    maxWidth: "8%",
  },
  cellText: {
    padding: "7px",
    maxWidth: "30%",
  },
  button: {
    margin: "2rem auto",
    display: "block",
  },
  modalText: {
    textAlign: "center",
  },
  spinner: {
    margin: "70px auto",
    alignContent: "center",
    display: "block",
    width: "140px",
  },
};
