import React, { useEffect, useMemo, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import useStyles from "./fineCalculatorStyles";
import Typography from "@mui/material/Typography";
import useForm from "../../hooks/useForm";
import { formValue } from "../../services/formServiceFunctions";
import { apiPost } from "../../services/Api/apiCall";
import TrafficFineCalculatorResponse from "../../types/TrafficFineCalculatorResponse";

interface TrafficProxy {
  accusation: string;
  locality: string;
  speeding: string;
  speed: string;
  distance: string;
  redLight: string;
  roadUsersEndangered: string;
  damage: string;
  accusationOther: string;
  probationTime: string;
}

const initialValues: TrafficProxy = {
  accusation: "",
  locality: "",
  speeding: "",
  speed: "",
  distance: "",
  redLight: "",
  roadUsersEndangered: "",
  damage: "",
  accusationOther: "",
  probationTime: "",
};

const FineCalculator = () => {
  const classes = useStyles();
  const [fineCalculatorResponse, setFineCalculatorResponse] = useState<TrafficFineCalculatorResponse | null>(null);
  const [formComplete, setFormComplete] = useState<boolean>(false);

  let { values, handleChange } = useForm({
    initialValues,
    onSubmit: async () => {},
  });

  const requiredFields = useMemo(() => {
    const accusation = formValue(values, "accusation");
    switch (accusation) {
      case "speeding":
        return ["locality", "speeding"];
      case "distance":
        return ["speed", "distance"];
      case "red_light":
        return ["damage", "redLight", "roadUsersEndangered"];
    }
    return [];
  }, [values]);

  useEffect(() => {
    const newFormComplete = [...requiredFields, "accusation"].every((field: string) => !!formValue(values, field));

    setFormComplete(newFormComplete);

    if (!newFormComplete) {
      return;
    }

    setFineCalculatorResponse(null);
    apiPost("lb/calculate_traffic_fine", values).then((response) => {
      setFineCalculatorResponse(response);
    });
  }, [values]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Vorwurf</InputLabel>
          <Select label="Vorwurf" value={formValue(values, "accusation")} onChange={handleChange} name={"accusation"}>
            <MenuItem value={"distance"}>Abstandsverstoß</MenuItem>
            <MenuItem value={"speeding"}>Geschwindigkeitsverstoß</MenuItem>
            <MenuItem value={"red_light"}>Rotlichtverstoß</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Führerscheinbesitz</InputLabel>
          <Select
            label="Führerscheinbesitz"
            value={formValue(values, "probationTime")}
            onChange={handleChange}
            name={"probationTime"}
          >
            <MenuItem value={"<2"}>Kürzer als 2 Jahre (Probezeit)</MenuItem>
            <MenuItem value={"2-10"}>2-10 Jahre</MenuItem>
            <MenuItem value={">10"}>Über 10 Jahre</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {requiredFields.includes("speed") && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Geschwindigkeit</InputLabel>
            <Select label="Geschwindigkeit" value={formValue(values, "speed")} onChange={handleChange} name={"speed"}>
              <MenuItem value={"<81"}>Weniger als 81 km/h</MenuItem>
              <MenuItem value={"81-100"}>81-100 km/h</MenuItem>
              <MenuItem value={"101-130"}>101-130 km/h</MenuItem>
              <MenuItem value={">130"}>Mehr als 130 km/h</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {requiredFields.includes("distance") && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Abstand</InputLabel>
            <Select label="Abstand" value={formValue(values, "distance")} onChange={handleChange} name={"distance"}>
              <MenuItem value={"5/10"}>5/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"4/10"}>4/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"3/10"}>3/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"2/10"}>2/10 des halben Tachowerts</MenuItem>
              <MenuItem value={"1/10"}>1/10 des halben Tachowerts</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {requiredFields.includes("locality") && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Ort</InputLabel>
            <Select label="Ort" value={formValue(values, "locality")} onChange={handleChange} name={"locality"}>
              <MenuItem value={"in_town"}>Innerorts</MenuItem>
              <MenuItem value={"out_of_town"}>Außerorts</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {requiredFields.includes("speeding") && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Geschwindigkeit über dem Limit</InputLabel>
            <Select
              label="Geschwindigkeit über dem Limit"
              value={formValue(values, "speeding")}
              onChange={handleChange}
              name={"speeding"}
            >
              <MenuItem value={"0-10"}>0-10 km/h</MenuItem>
              <MenuItem value={"11-15"}>11-15 km/h</MenuItem>
              <MenuItem value={"16-20"}>16-20 km/h</MenuItem>
              <MenuItem value={"21-25"}>21-25 km/h</MenuItem>
              <MenuItem value={"26-30"}>26-30 km/h</MenuItem>
              <MenuItem value={"31-40"}>31-40 km/h</MenuItem>
              <MenuItem value={"41-50"}>41-50 km/h</MenuItem>
              <MenuItem value={"51-60"}>51-60 km/h</MenuItem>
              <MenuItem value={"61-70"}>61-70 km/h</MenuItem>
              <MenuItem value={">70"}>Mehr als 70 km/h</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {requiredFields.includes("redLight") && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Dauer Rotphase</InputLabel>
            <Select
              label="Dauer Rotphase"
              value={formValue(values, "redLight")}
              onChange={handleChange}
              name={"redLight"}
            >
              <MenuItem value={"under_1_sec"}>Unter einer Sekunde</MenuItem>
              <MenuItem value={"1_sec_or_more"}>Eine Sekunde oder mehr</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {requiredFields.includes("roadUsersEndangered") && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Verkehrsteilnehmer gefährdet</InputLabel>
            <Select
              label="Verkehrsteilnehmer gefährdet"
              value={formValue(values, "roadUsersEndangered")}
              onChange={handleChange}
              name={"roadUsersEndangered"}
            >
              <MenuItem value={"yes"}>Ja</MenuItem>
              <MenuItem value={"no"}>Nein</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {requiredFields.includes("damage") && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Schaden verursacht</InputLabel>
            <Select
              label="Schaden verursacht"
              value={formValue(values, "damage")}
              onChange={handleChange}
              name={"damage"}
            >
              <MenuItem value={"yes"}>Ja</MenuItem>
              <MenuItem value={"no"}>Nein</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        {formComplete && fineCalculatorResponse && (
          <Grid container className={classes.centered} spacing={3}>
            <Grid item>
              <Typography variant={"h2"} sx={{ mt: 1, mb: 1 }}>
                Ergebnis:
              </Typography>
              Bußgeld: {fineCalculatorResponse.fine} €<br />
              Punkte: {fineCalculatorResponse.flensburgPoints}
              <br />
              Fahrverbot in Monaten: {fineCalculatorResponse.drivingBan}
              <br />
              Ergebnis ist genau: {fineCalculatorResponse.isExact ? "Ja" : "Nein"} <br />
            </Grid>
          </Grid>
        )}
        {!formValue(values, "accusation") && "Bitte wählen Sie einen Vorwurf aus"}
        {!!formValue(values, "accusation") && !formComplete && "Bitte füllen Sie alle Felder aus"}
        {formComplete &&
          formValue(values, "probationTime") === "<2" &&
          ((formValue(values, "accusation") === "speeding" &&
            !["0-10", "11-15", "16-20"].includes(formValue(values, "speeding"))) ||
            (formValue(values, "accusation") === "distance" && formValue(values, "speed") != "<81") ||
            formValue(values, "accusation") === "red_light") && (
            <Typography marginTop={2}>
              Achtung, Sie sind in der Probezeit! Dies erhöht die Strafe deutlich! Ihnen droht, zusätzlich zu Bußgeld
              und Punkten, die Verlängerung der Probezeit und ein Aufbauseminar. Sollte dies bereits der zweite Verstoß
              in der Probezeit sein, droht Ihnen sogar der Entzug der Fahrerlaubnis!
            </Typography>
          )}
      </Grid>
    </Grid>
  );
};
export default FineCalculator;
