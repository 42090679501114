import React, { useEffect, useState } from "react";
import _ from "lodash";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../paidTaskStatus";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import { formValue } from "../../../services/formServiceFunctions";
import { MenuItem } from "@mui/material";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import useForm from "../../../hooks/useForm";
import { requiredFieldDefault } from "../../../services/validationRules";
import ButtonLoading from "../../Button/ButtonLoading";
import moment from "moment";
import { useSnackbar } from "notistack";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import { apiGet } from "../../../services/Api/apiCall";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { PaidTask as PaidTaskType } from "../../../types/PaidTask";
import { LawyerWithCapacity } from "../../../types/LawyerWithCapacity";
import { getTemplates } from "../../../services/paidTaskService";
import { useCase } from "../../../provider/CaseProvider";

type RequestExternalLawyerModalProps = {
  paidTask: PaidTaskType;
  open: boolean;
  closeModal: Function;
};
const RequestExternalLawyerModal = ({ paidTask, open, closeModal }: RequestExternalLawyerModalProps) => {
  const [externalLawyersCapacities, setExternalLawyersCapacities] = useState<LawyerWithCapacity[]>();
  const updateMutation = useMutation(updateResource);
  const { enqueueSnackbar } = useSnackbar();
  const { product } = useCase();

  const initializeData = async () => {
    let capacitiesResponse = await apiGet("/services/external/capacities");
    capacitiesResponse = _.map(capacitiesResponse, (capacitiesResponse) => capacitiesResponse);
    setExternalLawyersCapacities(capacitiesResponse);
    const recommendationResponse = await apiGet("/services/external/recommendation", null, {
      backofficeCaseId: paidTask.backofficeCase?.id,
    });
    const recommendedUsersCapacity = capacitiesResponse.find(
      (capacity: LawyerWithCapacity) => capacity.backofficeUser.id === recommendationResponse.recommendation
    );
    if (recommendedUsersCapacity && !isExternalLawyerDisabled(recommendedUsersCapacity))
      handleChange({
        target: {
          name: "requestedUser",
          value: recommendationResponse?.recommendation
            ? "backoffice_users/" + recommendationResponse.recommendation
            : "",
        },
      });
  };

  useEffect(() => {
    initializeData();
  }, [paidTask]);

  const initialValues = {
    requestedUser: "",
  };

  const onSubmit = async () => {
    await updateMutation.mutateAsync({
      uri: "paid_tasks",
      id: paidTask.id,
      data: {
        requestedUser: formValue(values, "requestedUser"),
        requestedUserDate: moment().format("DD.MM.YYYY"),
      },
    });
    enqueueSnackbar("Anfrage erfolgreich versendet", {

      variant: "custom",
      buttonType: "link",
      buttonLink: getCaseLinkByBackofficeCase(paidTask.backofficeCase),
      buttonTarget: "_self",
      buttonText: "Zum Fall",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    closeModal();
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, isLoading } = useForm({
    initialValues,
    onSubmit,
    clearFormAfterSubmit: true,
  });

  if (!product) {
    return null;
  }

  if (!_.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status)) {
    return null;
  }

  const isExternalLawyerDisabled = (externalLawyerCapacity: LawyerWithCapacity) => {
    const paidTaskTemplate = _.find(getTemplates(product), (template) => template.type === paidTask.type);
    return !paidTaskTemplate?.getIsUserRequestable({ externalLawyerCapacity });
  };

  return (
    <LegalbirdIoModal
      handleClose={() => closeModal()}
      open={open}
      title={"Anwalt für Aufgabe anfragen"}
      submitButton={
        <ButtonLoading variant={"contained"} color={"primary"} onClick={handleSubmit} isLoading={isLoading}>
          Anwalt anfragen
        </ButtonLoading>
      }
    >
      Bitte wählen Sie aus, welchen Anwalt Sie bezüglich der Übernahme der Aufgabe anfragen möchten. Es wird dann eine
      E-Mail an den betreffenden Anwalt samt Link zu dieser Aufgabe verschickt.
      <form>
        <ValidatorSelect
          label={"Anfrage für Übernahme senden an"}
          name={"requestedUser"}
          value={formValue(values, "requestedUser")}
          onChange={handleChange}
          onBlur={handleBlur}
          validators={requiredFieldDefault}
          registerValidators={registerValidators}
          error={!!errors["requestedUser"]}
          helperText={errors["requestedUser"]}
        >
          {_.map(externalLawyersCapacities, (externalLawyerCapacity: LawyerWithCapacity) => {
            return (
              <MenuItem
                key={externalLawyerCapacity.backofficeUser.id}
                value={"backoffice_users/" + externalLawyerCapacity.backofficeUser.id}
                disabled={isExternalLawyerDisabled(externalLawyerCapacity)}
              >
                {externalLawyerCapacity.backofficeUser.person.fullname} ({externalLawyerCapacity.requestedTasks}{" "}
                Anfragen | {externalLawyerCapacity.activeTasks} Aufgaben)
              </MenuItem>
            );
          })}
        </ValidatorSelect>
      </form>
    </LegalbirdIoModal>
  );
};

export default RequestExternalLawyerModal;
