import React, { useCallback, useEffect, useRef, useState } from "react";
import { formValidate } from "../services/formServiceFunctions";
import _ from "lodash";
import { FormChangeEvent, UseForm } from "../types/UseForm";
import { ValidatorType } from "../types/ValidatorType";

export type UseFormProps = {
  initialValues?: Record<string, any>;
  onSubmit: (props: { values: Record<string, any>; e: Record<string, any> }) => Promise<void>;
  hasRedirectOnSubmit?: boolean;
  clearFormAfterSubmit?: boolean;
  identifier?: any;
};
const useForm = ({
  initialValues = {},
  onSubmit,
  hasRedirectOnSubmit = false,
  identifier = "",
  clearFormAfterSubmit = false,
}: UseFormProps): UseForm => {
  const [values, setValues] = useState(initialValues);
  const [touchedValues, setTouchedValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const validators = useRef<Record<string, any>>({});
  const dependentValidationFields = useRef<Record<string, any>>({});

  const clearForm = () => {
    setValues(initialValues);
    setErrors({});
    setTouchedValues({});
    setIsLoading(false);
  };

  useEffect(clearForm, [identifier]);

  const handleChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement> | FormChangeEvent) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setValues((prevData) => {
      return _.set(_.merge({}, prevData), name, value);
    });
    setTouchedValues((prevData) => {
      return _.set(_.merge({}, prevData), name, true);
    });
  }, []);

  const handleBlur = ({ target }: React.FocusEvent<HTMLInputElement> | FormChangeEvent) => {
    const { name, value } = target;
    setTouchedValues((prevData) => {
      return _.set(_.merge({}, prevData), name, true);
    });
    let valuesToValidate = _.pick(values, dependentValidationFields.current[name] || []);
    valuesToValidate[name] = value;
    formValidate(valuesToValidate, validators.current).then((currentFieldError) =>
      setErrors(() => {
        return _.pickBy(_.merge({}, errors, currentFieldError));
      })
    );
  };

  const updateAllValues = useCallback((updatedValues: Record<string, any>) => {
    setValues(updatedValues);
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    formValidate(values, validators.current).then((currentErrors) => {
      currentErrors = _.pickBy(currentErrors);
      setErrors(currentErrors);
      if (!_.isEmpty(currentErrors)) {
        return;
      }
      setIsLoading(true);
      onSubmit({ values, e: currentErrors }).then(
        () => {
          if (hasRedirectOnSubmit) {
            return;
          }
          if (clearFormAfterSubmit) {
            clearForm();
          } else {
            setTouchedValues({});
          }
          setIsLoading(false);
        },
        (error: any) => {
          throw error;
        }
      );
    });
  };

  const registerValidators = useCallback(
    (registerName: string, registerValidators: ValidatorType[], dependentFields: string[] = []) => {
      validators.current[registerName] = registerValidators;
      dependentValidationFields.current[registerName] = dependentFields;
    },
    []
  );

  const handleDateChange: UseForm["handleDateChange"] = useCallback(
    (date, name) => {
      handleChange({ target: { name: name, value: date } });
    },
    [handleChange]
  );

  return {
    values,
    touchedValues,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    registerValidators,
    clearForm,
    isLoading,
    handleDateChange,
    updateAllValues,
  };
};

export default useForm;
