const legalbirdAutoCompleteStyle = {
  textField: {
    //because the formcontrol form the autocomplete has uneven margins and i dont know how to remove them
    paddingBottom: "7px",
  },
  autoWidthPopper: {
    width: "fit-content",
  },
};

export default legalbirdAutoCompleteStyle;
