import React from "react";
import { getCaseDetailsSpecialActions } from "../../../../services/Product/ProductService";
import { Box } from "@mui/material";
import { AbstractCase } from "../../../../types/AbstractCase";

type CaseDetailsSpecialActionsProps = {
  product: AbstractCase;
};
const CaseDetailsSpecialActions = ({ product }: CaseDetailsSpecialActionsProps) => {
  const caseDetailsSpecialActions = getCaseDetailsSpecialActions(product);
  if (caseDetailsSpecialActions) {
    return <Box sx={{ marginTop: "1rem" }}>{caseDetailsSpecialActions}</Box>;
  }
  return null;
};
export default CaseDetailsSpecialActions;
