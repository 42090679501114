import _ from "lodash";
import ApiClient from "./ApiClient";
import {BookingEntry, BookingEntryWithDifference} from "../types/BookingEntry";
import {AbstractCase} from "../types/AbstractCase";

export const getBookingEntriesWithDifferenceAndSummedValues = (bookingEntries: BookingEntry[]): {
  bookingEntriesWithDifference: BookingEntryWithDifference[],
  summedValues: { debit: number; credit: number; difference: number; },
} => {

  const clonedBookingEntries: BookingEntryWithDifference[] = _.map(bookingEntries, (bookingEntry) => {
    return { ...bookingEntry, difference: 0 };
  });
  let difference = 0;
  let debit = 0;
  let credit = 0;

  _.forEach(clonedBookingEntries, (bookingEntry) => {
    if (bookingEntry.debitOrCredit === "debit") {
      difference -= bookingEntry.amount;
      debit += bookingEntry.amount;
    }
    if (bookingEntry.debitOrCredit === "credit") {
      difference += bookingEntry.amount;
      credit += bookingEntry.amount;
    }
    difference = _.round(difference, 2);
    debit = _.round(debit, 2);
    credit = _.round(credit, 2);
    bookingEntry.difference = difference;
  });
  return {
    bookingEntriesWithDifference: clonedBookingEntries,
    summedValues: { debit: debit, credit: credit, difference: difference },
  };
};

const fetchBookingEntriesForCase = async (product: AbstractCase) => {
  return await ApiClient.get(
    `booking_entries?deleted=false&productType=${product.productClassName}&productId=${product.id}`
  );
};

export const getIsAccountBalanced = async (product: AbstractCase) => {
  const result = await fetchBookingEntriesForCase(product);
  return (
    _.round(getBookingEntriesWithDifferenceAndSummedValues(result["hydra:member"]).summedValues.difference, 2) === 0.0
  );
};

export const hasCreditPayment = async (product: AbstractCase) => {
  const result = await fetchBookingEntriesForCase(product);
  return _.some(
    result["hydra:member"],
    (bookingEntry) => bookingEntry.type === "payment" && bookingEntry.debitOrCredit === "credit"
  );
};
