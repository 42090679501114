const wireTransferTranslations = {
  typeOfPayment: {
      label: "Art der Zahlung",
      values: {
        standard: "Normale Zahlung",
        thirdPartyFunds: "Fremdgeld",
        caseUnrelated: "Nicht am Fall buchen",
        feeRefund: "Gebührenrückerstattung",
      },
  },
};
export default wireTransferTranslations;
