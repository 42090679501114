import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import _ from "lodash";
import { translate } from "../../services/Translations/translatorService";
import ApiClient from "../../services/ApiClient";
import useForm, { UseFormProps } from "../../hooks/useForm";
import ValidatorForm from "../Validator/ValidatorForm";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { numberFieldDefault, requiredFieldDefault } from "../../services/validationRules";
import { formValue } from "../../services/formServiceFunctions";
import SubmitButton from "../Button/SubmitButton";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import { getRoleToDisplay, sortByRole, userHasRole } from "../../services/backofficeUserService";
import HolidaySelection from "./HolidaySelection";
import PageContentContainer from "../Container/PageContentContainer";
import ContentBox from "../ContentBox/ContentBox";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { BackofficeUser, Roles } from "../../types/BackofficeUser";
import { UseForm } from "../../types/UseForm";
import HumanResource from "../../types/HumanResource";
import { AutoCompleteOptionWithValue } from "../../types/AutoCompleteOptionWithValue";
import UserAbsenceManagement from "../MyAccount/UserAbsenceManagement";
import UserWorkingHoursManagement from "../MyAccount/UserWorkingHoursManagement";
import NavigationExtensionHumanResourceManagement from "../NavigationBar/NavigationExtensionHumanResourceManagement";
import { scrollToAnchor } from "../../theme/commonStyles";

const HumanResourceManagement = () => {
  const currentUser = useCurrentUser();

  const { internals } = useBackofficeUser();
  const [userId, setUserId] = useState<number>(currentUser.id);
  const [humanResource, setHumanResource] = useState<HumanResource>();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    availableMinutesPerDay: "0",
    trainedProducts: [],
  };

  const onSubmit: UseFormProps["onSubmit"] = async ({ values }) => {
    setIsLoading(true);
    if (humanResource) {
      await ApiClient.put("/human_resources/" + humanResource.id, {
        body: JSON.stringify({
          trainedProducts: values.trainedProducts,
          availableMinutesPerDay: _.toNumber(values.availableMinutesPerDay),
        }),
      });
      await fetchHumanResource(userId);
    }
    setIsLoading(false);
  };

  const useFormProps: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  const { values, handleChange, handleSubmit, updateAllValues, registerValidators, touchedValues, errors, handleBlur } =
    useFormProps;

  const fetchHumanResource = async (userId: number) => {
    setIsLoading(true);
    const fetchedHumanResource = (await ApiClient.get("/human_resources?backofficeUser=" + userId))["hydra:member"][0];
    setHumanResource(fetchedHumanResource);
    if (fetchedHumanResource) {
      updateAllValues({
        trainedProducts: fetchedHumanResource.trainedProducts,
        availableMinutesPerDay: "" + fetchedHumanResource.availableMinutesPerDay,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchHumanResource(userId);
  }, []);

  const handleUserChange = async (_e: React.SyntheticEvent, value: AutoCompleteOptionWithValue | null) => {
    if (!value) {
      return;
    }
    setUserId(value.value!);
    await fetchHumanResource(value.value);
  };

  if (internals.length < 1) {
    return null;
  }

  const options = getOptions(internals);
  const value = _.find(options, (option) => option.value === userId);
  return (
    <>
      <NavigationExtensionHumanResourceManagement />
      <PageContentContainer size="small">
        <Box sx={scrollToAnchor} id={"hrPlaning"} />
        <ContentBox headline={"HR Planung"}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                <LegalbirdAutoComplete
                  value={value || null}
                  options={options}
                  setValue={handleUserChange}
                  groupBy={(option: AutoCompleteOptionWithValue) => option.groupBy || ""}
                  label={"Mitarbeiter"}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
              </Grid>
              {userHasRole(currentUser, Roles.admin) && humanResource && (
                <>
                  <Grid item xs={12}>
                    <ValidatorTextField
                      name={"availableMinutesPerDay"}
                      validators={[...numberFieldDefault, ...requiredFieldDefault]}
                      registerValidators={registerValidators}
                      label={"Verfügbare Minuten pro Tag"}
                      value={formValue(values, "availableMinutesPerDay")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors["availableMinutesPerDay"]}
                      helperText={errors["availableMinutesPerDay"]}
                      initialDependentValidationFields={[]}
                      isHighlighted={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="trained-products-select">Trainierte Produkte</InputLabel>
                      <Select
                        label="Trainierte Produkte"
                        labelId="trained-products-select"
                        multiple
                        name={"trainedProducts"}
                        value={formValue(values, "trainedProducts")}
                        input={<OutlinedInput label="Trainierte Produkte" />}
                        onChange={handleChange}
                        renderValue={(selected: any) => (
                          <Grid container spacing={1}>
                            {selected.map((value: string) => (
                              <Grid item key={value}>
                                <Chip
                                  key={value}
                                  label={
                                    value === "contractLaw"
                                      ? _.capitalize(translate("general.productCategory.contractLaw"))
                                      : translate(value + ".label")
                                  }
                                />
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      >
                        <ListSubheader>{translate("general.productCategory.familyLaw")}</ListSubheader>
                        <MenuItem value={"familyLawCase"}>{translate("familyLawCase.label")}</MenuItem>
                        <MenuItem value={"alimony"}>{translate("alimony.label")}</MenuItem>
                        <MenuItem value={"death"}>{translate("death.label")}</MenuItem>
                        <MenuItem value={"divorce"}>{translate("divorce.label")}</MenuItem>
                        <ListSubheader>{translate("general.productCategory.employmentLaw")}</ListSubheader>
                        <MenuItem value={"settlement"}>{translate("settlement.label")}</MenuItem>
                        <ListSubheader>{translate("general.productCategory.tenancyLaw")}</ListSubheader>
                        <MenuItem value={"rentalContract"}>{translate("rentalContract.label")}</MenuItem>
                        <ListSubheader>{translate("general.productCategory.contractLaw")}</ListSubheader>
                        <MenuItem value={"contractLaw"}>
                          {_.capitalize(translate("general.productCategory.contractLaw"))}
                        </MenuItem>
                        <MenuItem value={"dataLeakContract"}>{translate("dataLeakContract.label")}</MenuItem>
                        <ListSubheader>{translate("general.productCategory.trafficLaw")}</ListSubheader>
                        <MenuItem value={"traffic"}>{translate("traffic.label")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              {humanResource && (
                <>
                  <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
                    {/*second disabled part (_.find) so that the initial user can't save his humanresource entity if he is not an internal user */}
                    <SubmitButton
                      error={_.keys(errors).length > 0}
                      isLoading={isLoading}
                      disabled={
                        _.keys(touchedValues).length === 0 ||
                        !_.find(internals, (internalUser) => internalUser.id === userId)
                      }
                      variant={"contained"}
                      type={"submit"}
                    >
                      Speichern
                    </SubmitButton>
                  </Grid>
                </>
              )}
              {!humanResource && !!_.find(internals, (internalUser) => internalUser.id === userId) && (
                <Grid item xs={12}>
                  Für diesen Benutzer können keine Ressourcen festgelegt werden.
                </Grid>
              )}
            </Grid>
          </ValidatorForm>
        </ContentBox>
        {humanResource && <UserAbsenceManagement userId={userId} key={"absence" + userId} />}
        {humanResource && <UserWorkingHoursManagement userId={userId} key={"workingHours" + userId} />}
        {_.includes(currentUser.roles, Roles.admin) && <HolidaySelection />}
      </PageContentContainer>
    </>
  );
};

const getOptions = (users: BackofficeUser[]): AutoCompleteOptionWithValue[] => {
  return _.map(sortByRole(users), (user) => {
    return {
      label: user.person.fullname,
      value: user.id,
      groupBy: getRoleToDisplay(user),
    };
  });
};

export default HumanResourceManagement;
