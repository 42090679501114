const contentBoxStyle = {
  defaultPadding: {
    padding: "2rem",
  },
  nonePadding: {
    padding: 0,
  },
  defaultMargin: {
    margin: "1rem 0 2rem 0",
  },
  noneMargin: {
    margin: 0,
  },
  headline: {
    textAlign: "center",
    marginBottom: "1rem",
  }
};

export default contentBoxStyle;
