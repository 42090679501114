import React from "react";
import {Box, Link, Toolbar} from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import BirdIcon from "../../assets/icon/legalbird.svg";
import { navigationBarBrandStyles } from "./navigationBarBrandStyles";


const NavigationBarBrand = () => {
  return (
    <>
      <AppBar position={"sticky"} color={"inherit"}>
        <Toolbar>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={12} sx={navigationBarBrandStyles.iconContainer}>
              <Link href={"/"}>
                <Box component={"img"} sx={navigationBarBrandStyles.navBarIcon} src={BirdIcon} alt={"Legalbird"} />
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavigationBarBrand;
