import { Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import Typography from "@mui/material/Typography";
import { AdvoAssist } from "../../types/AdvoAssist/AdvoAssist";
import { Appointment } from "../../types/Appointment";

type AdvoAssistDataProps = {
  advoAssist: AdvoAssist;
  legalbirdAppointment: Appointment;
};
export default function AdvoAssistData({ advoAssist, legalbirdAppointment }: AdvoAssistDataProps) {
  const details = advoAssist.appointmentDetails!;
  const advoAssistAppointmentDate = moment(details.termin);
  const legalbirdAppointmentDate = moment(legalbirdAppointment.appointmentDateTime);
  return (
    <>
      <Grid item xs={12}>
        <Typography variant={"h5"} color={"primary"} sx={{ mt: 1, mb: 1 }}>
          Basisdaten
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={2} sm={6} xs={12}>
            Datum (LB):
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            {legalbirdAppointmentDate.format("DD.MM.YYYY HH:mm:ss")}
          </Grid>

          <Grid item md={2} sm={6} xs={12}>
            Datum (AA):
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            {advoAssistAppointmentDate.format("DD.MM.YYYY HH:mm:ss")}
          </Grid>

          <Grid item md={2} sm={6} xs={12}>
            Eingestellt:
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            {moment(details.einstellzeit).format("DD.MM.YYYY HH:mm:ss")}
          </Grid>

          <Grid item md={2} sm={6} xs={12}>
            Angenommen:
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            {details.annahmezeit !== "0000-00-00 00:00:00"
              ? moment(details.annahmezeit).format("DD.MM.YYYY HH:mm:ss")
              : "Noch nicht angenommen"}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={"h5"} color={"primary"} sx={{ mt: 1, mb: 1 }}>
          Terminsvertreter
        </Typography>
        <Grid container spacing={2}>
          {!advoAssist.lawyerDetails ? (
            <Grid item xs={12}>
              Es wurde noch kein Terminsvertreter ausgewählt
            </Grid>
          ) : (
            <>
              <Grid item md={2} sm={6} xs={12}>
                Anwalt:
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                {advoAssist.lawyerDetails.adressat}
              </Grid>

              <Grid item md={2} sm={6} xs={12}>
                Kanzlei:
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                {advoAssist.lawyerDetails.name}
              </Grid>

              <Grid item md={2} sm={6} xs={12}>
                E-Mail:
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                {advoAssist.lawyerDetails.email}
              </Grid>

              <Grid item md={2} sm={6} xs={12}>
                Telefon:
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <a href={"tel:" + advoAssist.lawyerDetails.telefon}>{advoAssist.lawyerDetails.telefon}</a>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={"h5"} color={"primary"} sx={{ mt: 1, mb: 1 }}>
          Gutschrift
        </Typography>
        <Grid container spacing={2}>
          {!advoAssist.creditNoteCreated ? (
            <>
              <Grid item xs={12}>
                Es wurde noch keine Terminsvertreter Gutschrift erstellt
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={2} sm={6} xs={12}>
                Erstellt am:
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                {moment(advoAssist.creditNoteCreated).format("DD.MM.YYYY")}
              </Grid>

              <Grid item md={2} sm={6} xs={12}>
                Zahllauf:
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                {advoAssist.paymentRun
                  ? (advoAssist.paymentRun.paid && moment(advoAssist.paymentRun.paid).format("DD.MM.YYYY")) +
                    " [id:" +
                    advoAssist.paymentRun.id +
                    "]"
                  : "noch nicht im Zahllauf"}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
