import React from "react";
import LinkListItemWithIcon from "../LinkListItemWithIcon/LinkListItemWithIcon";
import { List } from "@mui/material";
import authorityImage from "../../assets/icon/icon_behoerde.svg";
import familyImage from "../../assets/icon/product/icon_familienrecht.svg";
import techStatusImage from "../../assets/icon/icon_admintool.svg";
import createReleaseUpdatesImage from "../../assets/icon/release_updates_creation.svg";
import releaseUpdatesImage from "../../assets/icon/release_updates.svg";
import applicationImage from "../../assets/icon/icon_antrag_erstellen.svg";
import lawyerIcon from "../../assets/icon/icon_anwalt.svg";
import personIcon from "../../assets/icon/person_faceless.svg";
import statsIcon from "../../assets/icon/icon_statistik.svg";
import { userHasOneOfTheseRoles } from "../../services/backofficeUserService";
import { BackofficeUser, Roles } from "../../types/BackofficeUser";

type AdministrationProps = {
  currentUser: BackofficeUser;
};
const Administration = ({ currentUser } : AdministrationProps) => {

  return (
    <List>
      <LinkListItemWithIcon
        title={"Datensätze verwalten"}
        description={"Daten für Behörde, StA, Inkasso, Gegenseite und Gerichte bearbeiten"}
        to={"/services/tools/datensaetze"}
        imageIcon={authorityImage}
      />
      <LinkListItemWithIcon
        title={"HR Planung"}
        description={"Arbeitsressourcen definieren und planen"}
        to={"/services/tools/hr-planung"}
        imageIcon={familyImage}
      />
      <LinkListItemWithIcon
        title={"Übersicht Release-Updates"}
        description={"Archiv aktueller und vergangener Release-Updates"}
        to={"/services/tools/release-updates"}
        imageIcon={releaseUpdatesImage}
      />
      {userHasOneOfTheseRoles(currentUser, [Roles.admin, Roles.developer]) && (
        <>
          <LinkListItemWithIcon
            title={"Editor Release-Update"}
            description={"Updates erstellen und veröffentlichen"}
            to={"/services/tools/release-update/neu"}
            imageIcon={createReleaseUpdatesImage}
          />
          <LinkListItemWithIcon
            title={"Nutzerverwaltung"}
            description={"Nutzer hinzufügen, deaktivieren und bearbeiten"}
            to={"/services/tools/nutzer-verwalten"}
            imageIcon={personIcon}
          />
          <LinkListItemWithIcon
            title={"Admin-Board"}
            description={"Tools und Funktionen zum Beheben technischer Probleme"}
            to={"/services/tools/admin-board"}
            imageIcon={techStatusImage}
          />
          <LinkListItemWithIcon
            title={"Nutzeransichten"}
            description={"Software aus Sicht verschiedener Nutzerrollen ansehen"}
            to={"/services/papageienaufstand"}
            imageIcon={familyImage}
          />
          <LinkListItemWithIcon
            title={"Vorlagen"}
            description={"Produktspezifische Vorlagen mit Platzhaltern erstellen"}
            to={"/services/tools/vorlagen"}
            imageIcon={applicationImage}
          />
        </>
      )}
      <LinkListItemWithIcon
        title={"Bewerbungen"}
        description={"Eingehende Bewerbungen externer Anwälte prüfen"}
        to={"/services/tools/bewerbungen"}
        imageIcon={lawyerIcon}
      />
      <LinkListItemWithIcon
        title={"Statistiken"}
        description={"Customer Happiness, Perfect Days & Aufgaben im Überblick"}
        to={"/services/tools/statistiken"}
        imageIcon={statsIcon}
      />
    </List>
  );
};

export default Administration;
