import React from "react";
import activityOverviewHeaderStyles from "./activityOverviewHeaderStyles";
import Grid from "@mui/material/Grid";
import ProductFilter from "../../../components/Activities/ActivityOverviewHeader/ProductFilter/ProductFilter";
import ActivityTypeFilter from "../../../components/Activities/ActivityOverviewHeader/ActivityTypeFilter/ActivityTypeFilter";
import UserFilter from "../../../components/Activities/ActivityOverviewHeader/UserFilter/UserFilter";
import { MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { ACTIVITY_OVERDUE } from "../../../services/Stages/Stages";
import ValidatorSelect from "../../../components/Validator/ValidatorSelect";
import { BackofficeUser } from "../../../types/BackofficeUser";
import { Activity } from "../../../types/Activity";

type ActivityOverviewHeaderProps = {
  isDeadlineView?: boolean;
  productFilter: string;
  setProductFilter: (value: string) => void;
  activityTypeFilter: string;
  setActivityTypeFilter: Function;
  userFilter: string;
  setUserFilter: Function;
  timespanFilter: string;
  setTimespanFilter: Function;
  agents: BackofficeUser[];
  userActivitiesToday: Activity[];
  isLoading?: boolean;
  setOpenOrDoneFilter?: Function;
  openOrDoneFilter?: string;
};

const ActivityOverviewHeader = ({
  productFilter,
  isDeadlineView,
  setProductFilter,
  activityTypeFilter,
  setActivityTypeFilter,
  userFilter,
  setUserFilter,
  timespanFilter,
  setTimespanFilter,
  agents,
  userActivitiesToday,
  isLoading,
  setOpenOrDoneFilter,
  openOrDoneFilter,
}: ActivityOverviewHeaderProps) => {
  const isCompletedView = openOrDoneFilter === "done";
  const userActivitiesOverdue = _.filter(
    userActivitiesToday,
    (activity) => activity.activityStatus === ACTIVITY_OVERDUE
  );

  if (
    (isCompletedView && !["today", "yesterday"].includes(timespanFilter)) ||
    (!isCompletedView && timespanFilter === "yesterday")
  ) {
    setTimespanFilter("today");
  }
  return (
    <Grid container sx={activityOverviewHeaderStyles.filterContainer} spacing={2} alignItems={"center"}>
      <Grid item xs={6} />
      {!isDeadlineView && (
        <Grid item xs={12}>
          <ValidatorSelect
            label="Status"
            onChange={({ target }) => {
              _.isFunction(setOpenOrDoneFilter) && setOpenOrDoneFilter(target.value);
            }}
            value={openOrDoneFilter}
            fullWidth
          >
            <MenuItem value={"open"}>Offen</MenuItem>
            <MenuItem value={"done"}>Erledigt</MenuItem>
          </ValidatorSelect>
        </Grid>
      )}
      <Grid item xs={12}>
        <ProductFilter productFilter={productFilter} setProductFilter={setProductFilter} />
      </Grid>
      <Grid item xs={12}>
        <ActivityTypeFilter
          activityTypeFilter={activityTypeFilter}
          setActivityTypeFilter={setActivityTypeFilter}
          isDeadlineView={!!isDeadlineView}
        />
      </Grid>
      <Grid item xs={12}>
        <UserFilter
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          agents={agents}
          isDeadlineView={!!isDeadlineView}
        />
      </Grid>
      <Grid item xs={12}>
        <Select value={timespanFilter} onChange={(event) => setTimespanFilter(event.target.value)} fullWidth>
          {!isCompletedView && <MenuItem value={"overdue"}>Überfällig</MenuItem>}
          {isCompletedView && <MenuItem value={"yesterday"}>Gestern</MenuItem>}
          <MenuItem value={"today"}>Heute</MenuItem>
          {!isCompletedView && <MenuItem value={"tomorrow"}>Morgen</MenuItem>}
        </Select>
      </Grid>
      <Grid item xs={6} sx={activityOverviewHeaderStyles.activitiesToday}>
        {userFilter !== "all" && !isLoading && <span>Heute noch: {userActivitiesToday.length}</span>}
      </Grid>
      <Grid item xs={6} sx={activityOverviewHeaderStyles.activitiesOverdue}>
        {userFilter !== "all" && !isLoading && <span>Davon überfällig: {userActivitiesOverdue.length}</span>}
      </Grid>
    </Grid>
  );
};

export default ActivityOverviewHeader;
