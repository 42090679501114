import { displayOrDownloadMediaObjectCellStyle } from "./displayOrDownloadMediaObjectCellStyle";
import { isMobile } from "react-device-detect";
import { MediaObject } from "../../types/MediaObject";
import { Box } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { apiGet } from "../../services/Api/apiCall";

type DisplayOrDownloadMediaObjectWrapperProps = {
  mediaObject: MediaObject;
  setMediaObjectToDisplay: Function;
  loadDisplayUrl?: boolean; // this is currently only passed as true for message attachments as they may have outdated display- and downloadUrls
};

export default function DisplayOrDownloadMediaObjectWrapper({
  children,
  mediaObject,
  setMediaObjectToDisplay,
  loadDisplayUrl = false,
}: PropsWithChildren<DisplayOrDownloadMediaObjectWrapperProps>) {
  const [displayUrl, setDisplayUrl] = useState<string>();

  if (isMobile) {
    if (loadDisplayUrl && !displayUrl) {
      apiGet("media_objects", mediaObject.id).then((responseMediaObject: MediaObject) => {
        setDisplayUrl(responseMediaObject.displayUrl);
      });
      return null;
    }

    //using displayUrl here lets the browser decide which files it wants to display or download
    return (
      <Box sx={displayOrDownloadMediaObjectCellStyle.link}>
        <a href={loadDisplayUrl ? displayUrl : mediaObject.displayUrl} target={"_blank"} download>
          {children}
        </a>
      </Box>
    );
  }
  return <div onClick={() => setMediaObjectToDisplay(mediaObject)}>{children}</div>;
}
