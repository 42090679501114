import React, { useState } from "react";
import { Box, Checkbox, List, ListItem, Stack } from "@mui/material";
import _ from "lodash";
import { Folder, FolderOpen } from "@mui/icons-material";
import MediaObject from "./MediaObject";
import DisplayMediaObjects from "../MediaObject/DisplayMediaObjects";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import {MediaObject as MediaObjectType} from "../../types/MediaObject";

type MediaObjectFolderViewProps = {
  mediaObjects: Array<MediaObjectType>;
  selectableView?: boolean;
  selectedMediaObjects?: Array<number>;
  setSelectedMediaObjects?: Function;
};

export default function MediaObjectFolderView({
  mediaObjects,
  selectableView = false,
  selectedMediaObjects,
  setSelectedMediaObjects,
}: MediaObjectFolderViewProps) {
  const [openFolderStructure, setOpenFolderStructure] = useState<string[]>([]);
  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState<MediaObjectType|null>(null);
  const queryClient = useQueryClient();

  const handleFolderClick = (path: string) => {
    if (_.includes(openFolderStructure, path)) {
      setOpenFolderStructure(_.filter(openFolderStructure, (entry) => entry !== path));
      return;
    }
    setOpenFolderStructure([...openFolderStructure, path]);
  };

  const refreshFiles = async () => {
    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
  };

  const fileTree = convertMediaObjectsToFileBrowserData(mediaObjects);

  const getFolderSelectionStatus = (node: FolderType) => {
    const childrenIdArray = _.map(node.children, (childNode) => childNode.id);

    switch (true) {
      case _.difference(childrenIdArray, selectedMediaObjects || []).length === 0:
        return "fullySelected";
      case _.intersection(childrenIdArray, selectedMediaObjects).length > 0:
        return "partlySelected";
      default:
        return "nothingSelected";
    }
  };

  const deselectFolderContent = (node: FolderType) => {
    if (!setSelectedMediaObjects) {
      return;
    }
    setSelectedMediaObjects(
      _.filter(
        selectedMediaObjects,
        (mediaObjectId) => !_.find(node.children, (childNode) => childNode.id === mediaObjectId)
      )
    );
  };

  const selectFolderContent = (node: FolderType) => {
    if (!selectedMediaObjects || !setSelectedMediaObjects) {
      return;
    }
    let newSelectedMediaObjects = [...selectedMediaObjects];
    _.forEach(node.children, (childNode) => newSelectedMediaObjects.push(childNode.id));
    setSelectedMediaObjects(_.uniq(newSelectedMediaObjects));
  };

  const handleCheckBoxClick = (node: FolderType|MediaObjectType) => {
    if (!selectedMediaObjects || !setSelectedMediaObjects) {
      return;
    }

    if (isFolder(node)) {
      if (getFolderSelectionStatus(node) === "fullySelected") {
        deselectFolderContent(node);
        return;
      }
      selectFolderContent(node);
      return;
    }

    if (_.includes(selectedMediaObjects, node.id)) {
      setSelectedMediaObjects(_.filter(selectedMediaObjects, (id) => id !== node.id));
      return;
    }
    setSelectedMediaObjects([...selectedMediaObjects, node.id]);
  };

  const getIndentation = (indentationLevel: number) => {
    return <div style={{ marginLeft: indentationLevel + "rem", display: "inline-block" }} />;
  };

  const renderTree = (nodes: Array<FolderType|MediaObjectType>, indentationLevel = 0, path?: string) =>
    _.map(nodes, (node) => {
      if (isFolder(node)) {
        const nodePath = path + "/" + node.name;
        const isOpen = _.includes(openFolderStructure, nodePath);
        const folderSelectionStatus = selectableView && getFolderSelectionStatus(node);
        const hasChildren = node.children.length !== 0;
        return (
          <React.Fragment key={nodePath}>
            <ListItem divider sx={{ minHeight: 60 }}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {getIndentation(indentationLevel)}
                {selectableView && (
                  <Checkbox
                    disabled={!hasChildren}
                    checked={hasChildren && folderSelectionStatus === "fullySelected"}
                    indeterminate={hasChildren && folderSelectionStatus === "partlySelected"}
                    onClick={() => handleCheckBoxClick(node)}
                  />
                )}
                {isOpen ? <FolderOpen /> : <Folder />}
                <Box sx={{ fontSize: "1rem", cursor: "pointer" }} onClick={() => handleFolderClick(nodePath)}>
                  {node.name} ({node.children?.length})
                </Box>
              </Stack>
            </ListItem>
            {node.children.length > 0 && isOpen && (
              <>
                {renderTree(node.children, indentationLevel + 1, nodePath)}
                <DisplayMediaObjects
                  mediaObjectToDisplay={mediaObjectToDisplay}
                  mediaObjects={node.children}
                  setMediaObjectToDisplay={setMediaObjectToDisplay}
                />
              </>
            )}
          </React.Fragment>
        );
      }

      return (
        <ListItem key={node.id} divider sx={{ cursor: "pointer" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {getIndentation(indentationLevel)}
            {selectableView && (
              <Checkbox
                checked={_.includes(selectedMediaObjects, node.id)}
                onClick={() => handleCheckBoxClick(node)}
              />
            )}
            <MediaObject
              mediaObject={node}
              updateMediaObjects={refreshFiles}
              handleOpenMediaObject={(mediaObject: MediaObjectType) => setMediaObjectToDisplay(mediaObject)}
              folderStructureView
            />
          </Stack>
        </ListItem>
      );
    });

  return <List>{renderTree(fileTree)}</List>;
}

function isFolder(node: FolderType|MediaObjectType): node is FolderType {
  return "type" in node && node.type === "folder";
}

const convertMediaObjectsToFileBrowserData = (mediaObjects: MediaObjectType[]) => {
  let fileStructure: Array<FolderType|MediaObjectType> = [
    {
      name: "Mandantschaft",
      type: "folder",
      children: [],
    },
    {
      name: "Gerichtliches Verfahren",
      type: "folder",
      children: [],
    },
    {
      name: "Außergerichtliches Verfahren",
      type: "folder",
      children: [],
    },
    {
      name: "RSV",
      type: "folder",
      children: [],
    },
    {
      name: "ToDo",
      type: "folder",
      children: [],
    },
    {
      name: "Rechnungen",
      type: "folder",
      children: [],
    },
    {
      name: "Sonstiges",
      type: "folder",
      children: [],
    },
  ];

  _.forEach(mediaObjects, (mediaObject) => {
    if (mediaObject.electronicFileFolderPath) {
      const folderInFileStructure = _.find(fileStructure, (folder) => isFolder(folder) && folder.name === mediaObject.electronicFileFolderPath);
      if (folderInFileStructure && isFolder(folderInFileStructure)) {
        folderInFileStructure.children.push(mediaObject);
      } else {
        fileStructure.push({
          name: mediaObject.electronicFileFolderPath,
          type: "folder",
          children: [mediaObject],
        });
      }
    }
  });

  return fileStructure;

  // working without mediaobject content, may be refined to contain the needed stuff
  /*let fileTree = [];
  let level = {fileTree}
  let paths = [];

  _.forEach(electronicFileMediaObjects, electronicFileMediaObject => {
      paths.push((electronicFileMediaObject.electronicFileFolderPath ? electronicFileMediaObject.electronicFileFolderPath + "/" : "") + electronicFileMediaObject.originalName);
  });

  paths.forEach(path => {
    path.split('/').reduce((r, name) => {
      if(!r[name]) {
        r[name] = {fileTree: []};
        r.fileTree.push({name, children: r[name].fileTree, isFolder: !_.includes(name, ".")})
      }
      return r[name];
    }, level)
  });

  return fileTree;*/
};


type FolderType = {
  children: Array<MediaObjectType>;
  type: "folder";
  name: string;
};
