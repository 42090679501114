import * as stagesDivorce from "../../Stages/StagesDivorce";
import { AbstractCase } from "../../../types/AbstractCase";
import _ from "lodash";
import { validateFields } from "../statusChangeValidators";
import { divorceRequiredFields } from "./divorceRequiredFields";

export const divorceValidators = {
  [stagesDivorce.STAGE_INCOMPLETE]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_INCOMPLETE](divorce), divorce);
  },
  [stagesDivorce.STAGE_READY]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_READY](divorce), divorce);
  },
  [stagesDivorce.STAGE_CHECKED]: (divorce: AbstractCase) => {
    let errorMessages = validateFields(divorceRequiredFields[stagesDivorce.STAGE_CHECKED](divorce), divorce);
    if (divorce.backofficeCase!.accountManager === null) {
      errorMessages.push("Fall hat keinen Zuständigen - bitte zuordnen!");
    }

    if (divorce.marriageCertificate === null) {
      errorMessages.push('Das Dokument "Heiratsurkunde" fehlt!');
    }

    _.forEach(divorce.children, (child) => {
      if (child.birthCertificate === null) {
        errorMessages.push(`Das Dokument "Geburtsurkunde ${child.name}" fehlt!`);
      }
    });

    return errorMessages;
  },
  [stagesDivorce.STAGE_PROCESS_PAID]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_PROCESS_PAID](divorce), divorce);
  },
  [stagesDivorce.STAGE_SUBMITTED]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_SUBMITTED](divorce), divorce);
  },
  [stagesDivorce.STAGE_JUDICIAL_PROCESS]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_JUDICIAL_PROCESS](divorce), divorce);
  },
  [stagesDivorce.STAGE_DATE_SET]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_DATE_SET](divorce), divorce);
  },
  [stagesDivorce.STAGE_HEARING_PAID]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_HEARING_PAID](divorce), divorce);
  },
  [stagesDivorce.STAGE_DIVORCE_DONE]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_DIVORCE_DONE](divorce), divorce);
  },
  [stagesDivorce.STAGE_COMPLETED]: (divorce: AbstractCase) => {
    return validateFields(divorceRequiredFields[stagesDivorce.STAGE_COMPLETED](divorce), divorce);
  },
};
