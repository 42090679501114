/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid, InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { ThousandsNumberInput } from "../FormFields/MaskedInputs/ThousandsNumberInput";
import { ThousandsNumberInputFloat } from "../FormFields/MaskedInputs/ThousandsNumberInputFloat";
import { ThousandsNumberInputFloatScaleThree } from "../FormFields/MaskedInputs/ThousandsNumberInputFloatScaleThree";
import { TextFieldElement, TextFieldElementProps } from "react-hook-form-mui";
import { floatFieldDefault, numberFieldDefault } from "../../services/reactHookFormValidationRules";

export type NumberFieldElementProps = {
  name: string;
  label: string;
  validators?: Record<string, any>;
  numberType?: "float" | "int";
  fieldType?: "money" | "percentage" | "kilometers" | null;
  floatScaleThree?: boolean;
  inputProps?: any;
} & TextFieldElementProps;
export default function NumberFieldElement({
  name,
  label,
  validators = {},
  numberType = "int",
  fieldType = null,
  inputProps = null,
  floatScaleThree = false,
  ...rest
}: NumberFieldElementProps) {
  useEffect(() => {
    if (numberType === "int") {
      validators = { ...validators, ...numberFieldDefault };
    }
    if (numberType === "float") {
      validators = { ...validators, ...floatFieldDefault };
    }
  }, []);

  const endAdornments = {
    money: "€",
    percentage: "%",
    kilometers: "km",
  };

  function getInputComponent() {
    if (numberType === "int") {
      return ThousandsNumberInput;
    }
    return floatScaleThree ? ThousandsNumberInputFloatScaleThree : ThousandsNumberInputFloat;
  }

  return (
    <Grid item xs={12}>
      <TextFieldElement
        name={name}
        label={label}
        rules={validators}
        InputProps={{
          inputComponent: getInputComponent(),
          endAdornment: !!fieldType ? (
            <InputAdornment position={"end"}>{endAdornments[fieldType]}</InputAdornment>
          ) : null,
          ...inputProps,
        }}
        {...rest}
      />
    </Grid>
  );
}
