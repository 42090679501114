import React, { useState } from "react";
import useForm from "../../hooks/useForm";
import ValidatorForm from "../Validator/ValidatorForm";
import ValidatorTextField from "../Validator/ValidatorTextField";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { formValue } from "../../services/formServiceFunctions";
import { postalCodeDefault } from "../../services/validationRules";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import ButtonLoading from "../Button/ButtonLoading";
import InputAdornment from "@mui/material/InputAdornment";
import { HelpOutline, InfoOutlined } from "@mui/icons-material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import BeaSearchResultRow from "./BeaSearchResultRow";
import { UseForm } from "../../types/UseForm";
import { BeaSearchResult } from "../../types/Bea/BeaSearchResult";

const MIN_SEARCH_VALUES = 2;
const MAX_SEARCH_VALUES = 4;

const checkAndCompletePayload = (payload: { surname: string; firstname: string; postalCode: string; city: string }) => {
  let emptyValues = 0;
  _.forEach(payload, (value) => {
    if (!value) {
      emptyValues++;
    }
  });

  if (_.keys(payload).length - emptyValues > MAX_SEARCH_VALUES) {
    return null;
  }

  if (_.keys(payload).length - emptyValues < MIN_SEARCH_VALUES) {
    switch (false) {
      case !!payload.surname:
        payload.surname = "**";
        break;
      case !!payload.firstname:
        payload.firstname = "**";
        break;
      default:
        break;
    }
  }

  return payload;
};

type BeaSearchProps = {
  ResultRowExtension?: (prop: { searchResult: BeaSearchResult }) => JSX.Element;
  initialValues?: {
    surname: string;
    firstname: string;
    postalCode: string;
    city: string;
  };
  searchResultTypeFilter?: string;
};

const defaultInitialValues = {
  surname: "",
  firstname: "",
  postalCode: "",
  city: "",
};
export default function BeaSearch({
  ResultRowExtension,
  searchResultTypeFilter,
  initialValues = defaultInitialValues,
}: BeaSearchProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [nameInfoModalOpen, setNameInfoModalOpen] = useState(false);
  const [furtherInfoModalOpen, setFurtherInfoModalOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const onSubmit = async () => {
    setIsLoading(true);
    setError("");
    let payload = {
      surname: formValue(values, "surname"),
      firstname: formValue(values, "firstname"),
      postalCode: formValue(values, "postalCode"),
      city: formValue(values, "city"),
    };

    const checkedPayload = checkAndCompletePayload(payload);

    if (!checkedPayload) {
      setError("Bitte maximal 4 Suchfelder befüllen");
      setIsLoading(false);
      return;
    }

    const searchResults = await ApiClient.post("bea/search", {
      body: JSON.stringify(checkedPayload),
    });

    const results = searchResultTypeFilter
      ? _.filter(searchResults, (searchResult) => searchResult.type === searchResultTypeFilter)
      : searchResults;

    setSearchResult(results);
    setIsLoading(false);
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, clearForm }: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  const resetSearch = () => {
    setSearchResult([]);
    setError("");
    clearForm();
  };

  const handleNameInfoClick = () => {
    setNameInfoModalOpen(true);
  };

  return (
    <>
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ValidatorTextField
              name={"surname"}
              label={"Name"}
              value={formValue(values, "surname")}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleNameInfoClick}>
                      <InfoOutlined />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              isHighlighted={false}
              registerValidators={() => {}}
              validators={[]}
              initialDependentValidationFields={[]}
            />
            <LegalbirdIoModal
              title={"Suche nach Name"}
              hasActions={false}
              handleClose={() => setNameInfoModalOpen(false)}
              open={nameInfoModalOpen}
              submitButton={<></>}
            >
              <p>
                In diesem Feld kann einerseits nach Namen von Anwälten, aber auch nach Behörden und Gerichten gesucht
                werden.
              </p>
              <p>
                Es kann hier also auch beispielsweise nach "Amtsgericht Meiningen" oder "Landespolizeiamt
                Schleswig-Holstein" gesucht werden.
              </p>
            </LegalbirdIoModal>
          </Grid>
          <Grid item xs={6}>
            <ValidatorTextField
              name={"firstname"}
              label={"Vorname"}
              onChange={handleChange}
              value={formValue(values, "firstname")}
              isHighlighted={false}
              registerValidators={() => {}}
              validators={[]}
              initialDependentValidationFields={[]}
            />
          </Grid>
          <Grid item xs={6}>
            <ValidatorTextField
              name={"postalCode"}
              label={"PLZ"}
              value={formValue(values, "postalCode")}
              onChange={handleChange}
              onBlur={handleBlur}
              registerValidators={registerValidators}
              validators={[postalCodeDefault]}
              error={!!errors["postalCode"]}
              helperText={errors["postalCode"]}
              isHighlighted={false}
              initialDependentValidationFields={[]}
            />
          </Grid>
          <Grid item xs={6}>
            <ValidatorTextField
              name={"city"}
              label={"Ort"}
              onChange={handleChange}
              value={formValue(values, "city")}
              isHighlighted={false}
              registerValidators={() => {}}
              validators={[]}
              initialDependentValidationFields={[]}
            />
          </Grid>
          <Grid item xs={6}>
            <Button onClick={() => setFurtherInfoModalOpen(true)} startIcon={<HelpOutline />} fullWidth={false}>
              Weitere Informationen
            </Button>
            <LegalbirdIoModal
              title={"Weitere Informationen"}
              hasActions={false}
              handleClose={() => setFurtherInfoModalOpen(false)}
              open={furtherInfoModalOpen}
              submitButton={<></>}
            >
                <ul>
                  <li>Es dürfen maximal 4 Felder zur Suche gefüllt werden</li>
                  <li>"*" kann als Platzhalter bei der Suche verwendet werden</li>
                </ul>
              <p>Beispiel: "*bauer" im Feld Name würde alle Nutzer finden, deren Name auf "bauer" endet.</p>
            </LegalbirdIoModal>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={resetSearch}>Zurücksetzen</Button>
          </Grid>
          <Grid item xs={3}>
            {error && <Box sx={{ textAlign: "center", color: "red" }}>{error}</Box>}
            <ButtonLoading
              type={"submit"}
              isLoading={isLoading}
              variant={"contained"}
              disabled={_.isEqual(defaultInitialValues, values)}
            >
              Suchen
            </ButtonLoading>
          </Grid>
        </Grid>
      </ValidatorForm>
      <TableContainer sx={{ marginTop: "3rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Straße + Hausnummer</TableCell>
              <TableCell>PLZ</TableCell>
              <TableCell>Ort</TableCell>
              <TableCell>Safe_ID</TableCell>
              <TableCell>Typ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(searchResult, (searchResult, index) => (
              <BeaSearchResultRow key={index} searchResult={searchResult} RowExtension={ResultRowExtension} />
            ))}
          </TableBody>
        </Table>
        {searchResult.length === 0 && <Box sx={{ textAlign: "center", margin: "4rem" }}>Keine Ergebnisse gefunden</Box>}
      </TableContainer>
    </>
  );
}
