import React from "react";
import {
  emailDefault,
  faxNumberDefault,
  isPhoneNumberWithoutSpecialCharactersDefault,
  requiredFieldDefault,
} from "../../../validationRules";
import addressFields from "./addressFields";
import _ from "lodash";
import legalForms from "../../../legalForms";
import { InputAdornment } from "@mui/material";

export const buildPaths = (path: string, translationPrefix: string) => {
  if (path !== "" && !_.endsWith(".", path)) {
    path = path + ".";
  }
  if (translationPrefix !== "" && !_.endsWith(".", translationPrefix)) {
    translationPrefix = translationPrefix + ".";
  }
  return {
    dataPath: path,
    translationPath: translationPrefix + path,
  };
};

export function contractualPartnerFields({
  path = "",
  translationPrefix = "",
  hideAddressAddition = true,
}: {
  path?: string;
  translationPrefix?: string;
  hideAddressAddition?: boolean;
}) {
  const { dataPath, translationPath } = buildPaths(path, translationPrefix);
  return [
    {
      type: "ValidatorTextField",
      path: `${dataPath}legalName`,
      translationPath: `${translationPath}legalName`,
      validators: requiredFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}name`,
      translationPath: `${translationPath}name`,
      helperText: "Erleichtert die Suche z.B. “Mc Fit” bei der RSG Group oder “XXXLutz” bei der BDSK Handels GmbH.",
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}email`,
      translationPath: `${translationPath}email`,
      validators: emailDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}telephone`,
      translationPath: `${translationPath}telephone`,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}fax`,
      translationPath: `${translationPath}fax`,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
    ...addressFields({
      path: `${dataPath}address`,
      translationPath: `${translationPath}address`,
      hidePostOfficeBoxFields: () => false,
      hideAddressAddition: hideAddressAddition,
    }),
    {
      type: "ValidatorSelect",
      path: `${dataPath}legalForm`,
      translationPath: `${translationPath}legalForm`,
      options: () => [
        { value: "__null__", label: "Keine Gesellschaftsform" },
        ..._.map(legalForms, (legalForm) => {
          return {
            value: legalForm.label,
            label: legalForm.label,
          };
        }),
      ],
    },
    {
      type: "ContractRepresentatives",
      path: `${dataPath}additionalRepresentatives`,
      translationPath: `${translationPath}additionalRepresentatives`,
    },
  ];
}

export function debtCollectionAgencyFields({
  path = "",
  translationPrefix = "",
}: {
  path?: string;
  translationPrefix?: string;
}) {
  const { dataPath, translationPath } = buildPaths(path, translationPrefix);
  return [
    {
      type: "ValidatorTextField",
      path: `${dataPath}name`,
      translationPath: `${translationPath}name`,
      validators: requiredFieldDefault,
    },
    ...addressFields({
      path: `${dataPath}address`,
      translationPath: `${translationPath}address`,
    }),
    {
      type: "ValidatorTextField",
      path: `${dataPath}email`,
      translationPath: `${translationPath}email`,
      validators: emailDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}telephone`,
      translationPath: `${translationPath}telephone`,
      validators: isPhoneNumberWithoutSpecialCharactersDefault,
    },
    {
      type: "ValidatorTextField",
      path: `${dataPath}fax`,
      translationPath: `${translationPath}fax`,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
  ];
}
