const paidTaskListStyle = {
  select: {
    marginBottom: "1rem",
  },
  headline: {
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  activityCount: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  filterContainer: {
    padding: "1rem",
  },
  activityTable: {
    minWidth: 650,
  },
  spinner: {
    display: "block",
    margin: "0 auto",
  },
  note: {
    paddingRight: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxHeight: 48,
    maxWidth: 100,
  },
  subject: {
    maxHeight: 48,
    maxWidth: 170,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  link: {
    color: "primary.main",
    textDecoration: "none",
    whiteSpace: "nowrap",
  },
  done: {
    textDecoration: "line-through",
  },
  overdue: {
    "&> td": {
      color: "#db1919",
    },
  },
  mandatoryCell: {
    minWidth: 130,
  },
  descriptionCell: {
    minWidth: 170,
  },
  noteCell: {
    minWidth: 170,
  },
  activeSorting: {
    color: "#137F7B",
  },
  sortableHeadline: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  smallFont: {
    fontSize: 10,
  },
  singleLineOverflowHidden: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  paidTaskSubject: {
    fontWeight: 500,
  },
  paidTaskButton: {
    fontSize: 10,
    padding: 3,
  },
  divider: {
    margin: "1rem 0",
  },
};

export default paidTaskListStyle;
