import Typography from "@mui/material/Typography";
import { deleteCaseOrAccountModalStyle } from "../deleteCaseOrAccountModalStyle";
import SubmitButton from "../../../Button/SubmitButton";
import { useState } from "react";
export type DeleteCaseOrAccountModalContentBaseProps = {
  confirmDeletion: Function;
  postDeletionCallback: Function;
  deletionWarnings: string[];
  deletionAllowed: boolean;
};
export default function DeleteCaseOrAccountModalContentBase({
  confirmDeletion,
  postDeletionCallback,
  deletionWarnings,
  deletionAllowed,
}: DeleteCaseOrAccountModalContentBaseProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleConfirmDeletion() {
    setIsDeleting(true);
    await confirmDeletion();
    setIsDeleting(false);
    await postDeletionCallback();
  }

  return (
    <>
      {deletionWarnings.map((warning) => (
        <Typography sx={deleteCaseOrAccountModalStyle.warning}>{warning}</Typography>
      ))}
      {deletionAllowed && (
        <SubmitButton
          isLoading={isDeleting}
          onClick={handleConfirmDeletion}
          variant={"contained"}
          sx={{ marginTop: 2 }}
        >
          {deletionWarnings.length > 0 ? "Trotzdem löschen" : "Löschen"}
        </SubmitButton>
      )}
    </>
  );
}
