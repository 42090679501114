import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

type LinkListItemWithIconProps = {
  to: string;
  MuiIcon?: SvgIconComponent;
  title: string;
  description: string;
  imageIcon?: string;
};
const LinkListItemWithIcon = ({ to, MuiIcon, title, description, imageIcon }: LinkListItemWithIconProps) => {
  return (
    <ListItem alignItems="flex-start" component={Link} to={to} disableGutters>
      <ListItemAvatar>
        {MuiIcon ? (
          <Avatar>
            <MuiIcon />
          </Avatar>
        ) : (
          <Avatar variant={"square"} src={imageIcon} sx={{ transform: "scale(1.12)" }} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box
            sx={{
              fontWeight: 500,
              fontSize: "1rem",
              color: "black",
            }}
          >
            {title}
          </Box>
        }
        secondary={description}
      />
    </ListItem>
  );
};

export default LinkListItemWithIcon;
