export const agentPickerStyles = {
  container: {
    height: "50px",
    width: "230px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "1rem 0",
  },
  avatar: {
    backgroundColor: "#a1ccca",
    marginRight: "1rem",
  },
  label: {
    fontSize: "10px",
    fontWeight: 300,
  },
  text: {
    fontSize: "16px",
    fontWeight: 500,
    width: "150px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};
