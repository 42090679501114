import { Box, Stack } from "@mui/material";
import { displayMediaObjectStyles } from "./displayMediaObjectStyle";
import { useState } from "react";
import { MediaObject } from "../../types/MediaObject";
import DisplayMediaObjectContentPlaybackErrorMessage from "./DisplayMediaObjectContentPlaybackErrorMessage";

export type DisplayMediaObjectContentAudioProps = {
  mediaObject: MediaObject;
  displayUrl: string;
  downloadUrl: string;
};

export default function DisplayMediaObjectContentAudio({
  mediaObject,
  displayUrl,
  downloadUrl,
}: DisplayMediaObjectContentAudioProps) {
  const [playbackError, setPlaybackError] = useState(false);

  if (playbackError) {
    return <DisplayMediaObjectContentPlaybackErrorMessage mediaObject={mediaObject} downloadUrl={downloadUrl} />;
  }

  return (
    <Box sx={displayMediaObjectStyles.embedContainer}>
      <Stack
        sx={{
          ...displayMediaObjectStyles.multimediaContainer,
        }}
      >
        <audio controls onError={() => setPlaybackError(true)} key={mediaObject.id}>
          <source src={displayUrl} type={mediaObject.mimeType!} />
          <DisplayMediaObjectContentPlaybackErrorMessage downloadUrl={downloadUrl} mediaObject={mediaObject} />
        </audio>
      </Stack>
    </Box>
  );
}
