import {
  isEmail,
  isFloat,
  isNotDuplicateMail,
  isNumber,
  isPhoneNumberWithoutSpecialCharacters,
  isValidDate,
  notNone,
} from "./validationRules";

export const isPhoneNumberWithoutSpecialCharactersDefault = {
  isPhoneNumberWithoutSpecialCharacters: (value: string) =>
    isPhoneNumberWithoutSpecialCharacters(value) ||
    "Die Telefonnummer darf keine Sonderzeichen enthalten oder mehrere führende Nullen haben.",
};
export const isEmailDefault = {
  isEmail: (value: string) => isEmail(value) || "Dies ist keine gültige E-Mail Adresse",
};
export const isDuplicateMailDefault = (originalMail: string) => ({
  duplicateMail: async (value: string) =>
    (await isNotDuplicateMail(value, originalMail)) || "Diese E-Mail Adresse existiert bereits",
});

export const requiredFieldDefault = {
  required: "Dies ist ein Pflichtfeld",
};

export const textFieldDefault = {
  minLength: {
    value: 2,
    message: "Der Text ist zu kurz",
  },
};

export const dateFieldDefault = {
  isValidDate: (value: string) => isValidDate(value) || "Das Datum hat ein falsches Format",
};

export const notNoneSelectValidatorDefault = {
  notNone: (value: string) => notNone(value) || "Bitte treffen Sie eine Auswahl",
};

export const numberFieldDefault = {
  isNumber: (value: string) => isNumber(value) || "Die Eingabe muss eine Ganzzahl sein",
};

export const floatFieldDefault = [
  {
    isFloat: (value: string) =>
      isFloat(value) || "Die Eingabe muss eine Zahl sein und darf maximal zwei Nachkommastellen haben.",
  },
];
