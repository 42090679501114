import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import MaintenancePage from "./components/_Pages/MaintenancePage/MaintenancePage";


const MAINTENANCE_MODE = false;

const container = document.getElementById("root");
// @ts-ignore
let root = createRoot(container);


if (MAINTENANCE_MODE) {
  root.render(<MaintenancePage />);
} else {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}


