import { useState } from "react";
import { displayMediaObjectStyles } from "./displayMediaObjectStyle";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos, ArrowForwardIos, RotateLeft, RotateRight } from "@mui/icons-material";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { Box, Grid } from "@mui/material";
import EditableTitle from "./EditableTitle";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import DisplayMediaObjectContent from "./DisplayMediaObjectContent";
import { MediaObject } from "../../types/MediaObject";

type DisplayMediaObjectProps = {
  mediaObject?: MediaObject | null;
  closeDialog: Function;
  switchImage?: Function;
  hasPreviousImage?: boolean;
  hasNextImage?: boolean;
  refreshMediaObjects?: Function;
  setMediaObjectToPreview?: Function;
  titleIsEditable?: boolean;
};

const DisplayMediaObject = ({
  mediaObject,
  closeDialog,
  switchImage = () => {},
  hasPreviousImage = false,
  hasNextImage = false,
  refreshMediaObjects = () => {},
  setMediaObjectToPreview = () => {},
  titleIsEditable = false,
}: DisplayMediaObjectProps) => {
  const [mediaRotation, setMediaRotation] = useState(0);

  if (!mediaObject) {
    return null;
  }
  const rotateMedia = (direction: string) => {
    if (direction === "clockwise") {
      if (mediaRotation > 270) {
        setMediaRotation(90);
        return;
      }
      setMediaRotation(mediaRotation + 90);
    }
    if (direction === "counterclockwise") {
      if (mediaRotation < 90) {
        setMediaRotation(270);
        return;
      }
      setMediaRotation(mediaRotation - 90);
    }
  };

  const handleTitleSave = async (newDescription: string) => {
    const updatedMediaObject = await ApiClient.put(mediaObject["@id"], {
      body: JSON.stringify({ description: newDescription }),
    });
    await refreshMediaObjects();
    setMediaObjectToPreview(updatedMediaObject);
  };

  return (
    <LegalbirdIoModal
      handleClose={closeDialog}
      open={!!mediaObject}
      title={
        titleIsEditable ? (
          <EditableTitle title={mediaObject.description || ""} handleSave={handleTitleSave} />
        ) : (
          _.truncate(mediaObject.description || "", { length: 50 })
        )
      }
      hasActions={false}
      maxWidth={"md"}
      submitButton={null}
    >
      <Box sx={displayMediaObjectStyles.imageDialog}>
        {(hasNextImage || hasPreviousImage) && (
          <Grid container>
            <Grid item xs={12}>
              <IconButton onClick={() => switchImage("previous")} disabled={!hasPreviousImage} size="large">
                <ArrowBackIos />
              </IconButton>
              <IconButton onClick={() => switchImage("next")} disabled={!hasNextImage} size="large">
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Box sx={displayMediaObjectStyles.imageContainer}>
          <DisplayMediaObjectContent mediaObject={mediaObject} mediaRotation={mediaRotation} />
        </Box>
        <Box sx={displayMediaObjectStyles.buttonContainer}>
          <IconButton onClick={() => rotateMedia("counterclockwise")} size="large">
            <RotateLeft />
          </IconButton>
          <IconButton onClick={() => rotateMedia("clockwise")} size="large">
            <RotateRight />
          </IconButton>
        </Box>
      </Box>
    </LegalbirdIoModal>
  );
};

export default DisplayMediaObject;
