const validatorStyle = {
  mandatory: {
    backgroundColor: "#c4dfde",
  },
  icon: {
    marginRight: "1.5rem",
  },
};

export default validatorStyle;
