import Typography from "@mui/material/Typography";
import { AbstractCase } from "../../../types/AbstractCase";
import activitiesStyle from "../activitiesStyle";
import { Box } from "@mui/material";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useQuery } from "@tanstack/react-query";
import {
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_IN_REVIEW,
  PAID_TASK_STATUS_REQUESTED,
} from "../../PaidTask/paidTaskStatus";
import React from "react";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { getProductData, getStageList } from "../../../services/Product/ProductService";
import moment from "moment";

type MissingActivityWarningProps = {
  product: AbstractCase;
};

const STAGE_VALIDATION_WARNING_TIME = 60;

export default function ZeroActivitiesInfo({ product }: MissingActivityWarningProps) {
  const currentUser = useCurrentUser();
  const paidTaskFilters: {
    backofficeCase: string;
    "exists[deletedDate]": boolean;
    "status[0]": number;
    "status[1]": number;
    "status[2]": number;
    "status[3]"?: number;
  } = {
    backofficeCase: product.backofficeCase["@id"],
    "exists[deletedDate]": false,
    "status[0]": PAID_TASK_STATUS_CREATED,
    "status[1]": PAID_TASK_STATUS_ASSIGNED,
    "status[2]": PAID_TASK_STATUS_IN_REVIEW,
    "status[3]": PAID_TASK_STATUS_REQUESTED,
  };
  const inquiryFilter = {
    productId: product.id,
    "status[lt]": 40,
  };

  const { data: paidTasks, isFetching: paidTaskIsFetching } = useQuery(
    queryKeys.collection("paid_tasks", paidTaskFilters),
    () => fetchCollection("paid_tasks", paidTaskFilters)
  );
  const { data: openCaseInquiries, isFetching: inquiriesIsFetching } = useQuery(
    queryKeys.collection("inquiry_to_customers", inquiryFilter),
    () => fetchCollection("inquiry_to_customers", inquiryFilter)
  );

  if (currentUser.isExternal) {
    return <Box sx={activitiesStyle.faded}>Es ist keine Aktivität geplant</Box>;
  }

  if (inquiriesIsFetching || paidTaskIsFetching) {
    return <LegalbirdLoader centered={true} />;
  }

  if (
    paidTasks["hydra:totalItems"] !== 0 ||
    openCaseInquiries["hydra:totalItems"] !== 0 ||
    product.caseCategory === "advisory" ||
    product.productClassName === "death" ||
    product.productClassName === "alimony" ||
    !validateProductStage(product)
  ) {
    return <Box sx={activitiesStyle.faded}>Es ist keine Aktivität geplant</Box>;
  }

  return (
    <Typography sx={{ color: "red", textAlign: "center", margin: "2rem" }}>
      Es ist keine Aktivität geplant, obwohl der Fall im Status “Offen” ist. Bitte sicherstellen, dass der Fall in
      Zukunft weiterbearbeitet wird, oder den Status ändern.
    </Typography>
  );
}

function validateProductStage(product: AbstractCase) {
  const lastProductStage = Number(Object.keys(getStageList(product.productClassName)).at(-1));

  return !!(
    product.stage &&
    product.productClassName &&
    product.backofficeCase.status === "open" &&
    ((product.stage > getProductData(product.productClassName, "minimumCaseStage") &&
        product.stage < lastProductStage) ||
      (product.stage === lastProductStage &&
        moment().diff(product.stageLog[product.stage], "days") >= STAGE_VALIDATION_WARNING_TIME))
  );
}
