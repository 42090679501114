import React from "react";
import { Stack } from "@mui/material";
import _ from "lodash";
import { getProductData, getProductStageList } from "../../../services/Product/ProductService";
import Grid from "@mui/material/Grid";
import { userHasOneOfTheseRoles, userHasRole } from "../../../services/backofficeUserService";
import { Roles } from "../../../types/BackofficeUser";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { AbstractCase } from "../../../types/AbstractCase";
import InsuranceData from "../CaseProgressBar/InsuranceData";
import { caseSubBarInfoStyles } from "./caseSubBarInfoStyles";

type CaseSubBarInfoProps = {
  product: AbstractCase;
};

function CaseSubBarInfo({ product }: CaseSubBarInfoProps) {
  const currentUser = useCurrentUser();
  const stageList = getProductStageList(product);

  if (!stageList) {
    return null;
  }

  const statusAndPaymentType = [];

  if (!userHasOneOfTheseRoles(currentUser, [Roles.external, Roles.callcenter])) {
    statusAndPaymentType.push(`Status: ${stageList[product.stage!].label || ""}`);
  }
  if (!userHasRole(currentUser, Roles.external)) {
    statusAndPaymentType.push(
      `Zahlart: ${getProductData(product.productClassName, "paymentType." + product.paymentType, product.paymentType)}`
    );
  }

  return (
    <>
      <Grid container sx={caseSubBarInfoStyles.CaseSubBarInfo}>
        <Stack>
          {userHasRole(currentUser, Roles.callcenter) && <Grid item>Unser Zeichen: {product.reference}</Grid>}
          {!!statusAndPaymentType.length && <Grid item>{statusAndPaymentType.join(" | ")}</Grid>}
          {product.acquisitionPartner && (
            <Grid item>Kooperationspartner: {_.capitalize(product.acquisitionPartner)}</Grid>
          )}
        </Stack>
        {!userHasOneOfTheseRoles(currentUser, [Roles.external]) && !!product.insurance.insurance && (
          <Grid item>
            <InsuranceData product={product} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default CaseSubBarInfo;
