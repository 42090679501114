import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Paper, Tab, Tabs, useMediaQuery } from "@mui/material";
import { getFullFormDefinition } from "../../services/Product/ProductService";
import { translate } from "../../services/Translations/translatorService";
import _ from "lodash";
import navigationExtensionAllDataStyle from "./navigationExtensionAllDataStyle";
import { useCase } from "../../provider/CaseProvider";

const NavigationExtensionAllData = () => {
  const [topOffset, setTopOffset] = useState(69);
  const smDown = useMediaQuery("(max-width:1149px)");
  const { product } = useCase();

  if (!product) {
    return null;
  }

  return (
    <Paper
      square
      sx={navigationExtensionAllDataStyle.tabs({ top: topOffset })}
      ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
    >
      <Tabs variant={smDown ? "scrollable" : "standard"} scrollButtons="auto" value={false} centered={!smDown}>
        <Tab component={Link} to={"#contact"} label={"Kontaktdaten"} />
        {_.map(getFullFormDefinition(product), (currentFormDefinition, key) => (
          <Tab key={key} component={Link} to={"#" + key} label={translate("allDataForm." + key)} />
        ))}
      </Tabs>
    </Paper>
  );
};

export default NavigationExtensionAllData;
