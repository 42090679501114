import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import ButtonLoading from "../../Button/ButtonLoading";
import { useSnackbar } from "notistack";
import ApiClient from "../../../services/ApiClient";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function FetchBeaMessages() {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  if (!currentUser.person.beaData) {
    return null;
  }

  const fetchMessages = async () => {
    setIsLoading(true);
    //should return null for the 204 response otherwisse it must be an error
    const result = await ApiClient.get("/bea/fetch/" + currentUser.id);
    enqueueSnackbar(result
      ? "beA-Nachrichten konnten nicht importiert werden. Bitte versuchen Sie es später noch einmal"
      : "beA-Nachrichten wurden erfolgreich importiert", {
      variant:"custom",
      isNonInteractive: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsLoading(false);
  };

  return (
    <Stack alignItems={"center"} spacing={3} sx={{ marginTop: "5rem", textAlign: "center" }}>
      <Typography variant={"h3"}>beA-Nachrichten abrufen</Typography>
      <Typography>
        Eingehende beA-Nachrichten werden regelmäßig automatisch in unser System überführt. Soll der Abruf für das
        beA-Postfach (beispielsweise für die zeitnahe Bearbeitung einer dringenden Nachricht) jetzt manuell durchgeführt
        werden, dann klick bitte auf:
      </Typography>
      <ButtonLoading onClick={fetchMessages} isLoading={isLoading} variant={"contained"} sx={{ width: "300px" }}>
        beA-Nachrichten abrufen
      </ButtonLoading>
    </Stack>
  );
}
