import { contractRequiredFields } from "../Contract/contractRequiredFields";
import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";

export const rentalContractRequiredFields = {
  ...contractRequiredFields,
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (rentalContractCase: AbstractCase) => {
    let requiredFields = contractRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](rentalContractCase);

    switch (rentalContractCase.reasonForDispute) {
      case "landlordHasCancelled":
        requiredFields.push("cancellationReceivedDate");
        break;
      case "deposit":
        requiredFields.push("depositAmount");
        break;
      case "rentIncrease":
        requiredFields.push("previousBasicRent");
        requiredFields.push("futureBasicRent");
        requiredFields.push("previousRentIncludingHeating");
        requiredFields.push("futureRentIncludingHeating");
        break;
      default:
        break;
    }

    return requiredFields;
  },
};
