import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { apiPut } from "../../services/Api/apiCall";
import { BookingEntryWithDifference } from "../../types/BookingEntry";

type DeleteBookingEntryModalProps = {
  bookingEntry: BookingEntryWithDifference;
  open: boolean;
  handleClose: () => void;
  updateBookingEntries: Function;
};

export default function DeleteBookingEntryModal({
  bookingEntry,
  open,
  handleClose,
  updateBookingEntries,
}: DeleteBookingEntryModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const saveDeleteBookingEntry = async () => {
    try {
      setIsLoading(true);
      await apiPut("/booking_entries", bookingEntry.id, {
        deleted: true,
        receipt: null,
        organization: null,
      });
      enqueueSnackbar("Buchungseintrag wurde gelöscht", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      await queryClient.invalidateQueries(queryKeys.collections("booking_entries"));
      handleClose();
    } catch (e) {
      enqueueSnackbar("Es ist ein Fehler aufgetreten", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setIsLoading(false);
      updateBookingEntries();
    }
  };

  return (
    <LegalbirdIoModal
      title={"Buchungseintrag löschen?"}
      open={open}
      handleClose={handleClose}
      submitButton={
        <ButtonLoading isLoading={isLoading} onClick={saveDeleteBookingEntry} variant={"contained"}>
          Buchungseintrag Löschen
        </ButtonLoading>
      }
    >
      <Typography color={"red"}>
        Das Löschen darf AUSSCHLIEẞLICH für falsche oder doppelt eingebuchte Ein- oder Auszahlungen ausgeführt werden!
        Zum Beispiel irrelevante Zahlungen am Fall oder Doppelbuchungen, die aber gar nicht doppelt ein- oder aus
        gezahlt wurden. Das Mandantenkonto hat stets den realen Ein- und Auszahlungen des Bankkontos zu entsprechen!
      </Typography>
    </LegalbirdIoModal>
  );
}
