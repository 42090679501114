import React from "react";
import { BottomNavigation, BottomNavigationAction, Box, Paper } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import generalActionsStyle from "./generalActionsStyle";
import _ from "lodash";
import { getGeneralActionsByUser } from "../../services/navigationActionsService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

const GeneralActions = () => {
  const currentUser = useCurrentUser();
  const actions = getGeneralActionsByUser(currentUser);

  return (
    <Paper sx={generalActionsStyle.bottomNavigationContainer}>
      <BottomNavigation showLabels sx={generalActionsStyle.navigation}>
        {_.map(actions, (action, index) => {
          if (action.isHidden) {
            return null;
          }
          const ActionIcon = action.icon;
          return (
            <BottomNavigationAction
              key={index}
              sx={generalActionsStyle.navigationAction}
              label={
                <Box component={"span"} sx={generalActionsStyle.navigationActionLabel}>
                  {action.text}
                </Box>
              }
              component={Link}
              icon={<ActionIcon />}
              to={action.to}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};

export default GeneralActions;
