import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
  assignedUserToast: {
    padding: ".5rem 1rem",
    width: 600,
    backgroundColor: "#999999",
    borderRadius: 5,
    color: "#fff",
    fontSize: "1rem",
  },
  revertButton: {
    color: theme.palette.primary.main,
  },
  alignRight: {
    textAlign: "right",
  },
  message: {
    textAlign: "center",
  },
}));

export default useStyles;
