import React, { useState } from "react";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import classNames from "classnames";
import PopUpMenu from "../../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import EditNote from "../CaseCreateNote/EditNote";
import LegalbirdIoConfirm from "../../Modal/LegalbirdIoConfirm";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import RequestSaveNoteModal from "./RequestSaveNoteModal";
import { AbstractCase } from "../../../types/AbstractCase";
import { Note as NoteType } from "../../../types/Note";
import useStyles from "./NoteStyles";
import { apiGet } from "../../../services/Api/apiCall";
import { enqueueSnackbar } from "notistack";

type NoteProps = {
  note: NoteType;
  handleChange?: Function;
  startsCollapsed?: boolean;
  product?: AbstractCase;
  isEditable?: boolean;
  authorNameOverride?: string;
};
const Note = ({
  note,
  handleChange = () => {},
  startsCollapsed = false,
  product,
  isEditable = true,
  authorNameOverride,
}: NoteProps) => {
  const classes = useStyles({});
  const updateMutation = useMutation(updateResource);
  const [noteFolded, setNoteFolded] = useState(startsCollapsed);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [deleteNoteOpen, setDeleteNoteOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const unpinNote = async () => {
    if (!note.id) {
      return;
    }
    await updateMutation.mutateAsync({ id: note.id, uri: "notes", data: { pinned: false } });
    handleChange();
  };

  const pinNote = async () => {
    if (!note.id || !product) {
      return;
    }
    const pinnedNotes = await apiGet(`/notes?case=${product.backofficeCase["@id"]}&pinned=true&deleted=false`);

    if (pinnedNotes["hydra:totalItems"] >= 4) {
      enqueueSnackbar(
        "Es ist bereits die maximal mögliche Anzahl an Notizen am Fall angeheftet. Bitte vorhandene Notizen abheften oder aktualisieren. Sobald weniger als 4 Notizen am Fall sind, kann eine neue Notiz angelegt werden.",
        {
          variant: "custom",
          isNonInteractive: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      );
    } else {
      await updateMutation.mutateAsync({ id: note.id, uri: "notes", data: { pinned: true } });
      handleChange();
    }
  };

  const deleteNote = async () => {
    if (!note.id) {
      return;
    }
    await updateMutation.mutateAsync({ id: note.id, uri: "notes", data: { deleted: true, pinned: false } });
    handleChange();
  };

  const closeDialog = () => {
    setEditNoteOpen(false);
    handleChange();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper className={classNames(classes.paper, note.isWarning ? classes.warning : null)}>
      <Grid container>
        <Grid item className={classes.info}>
          {authorNameOverride || note.author?.person.fullname || "System"} | {moment(note.created).format("DD.MM.YYYY")}{" "}
          | {moment(note.created).format("HH:mm")}
        </Grid>
        {note.content &&
          (note.content.length > 200 || note.content.includes("<br>") || note.content.includes("<br/>")) && (
            <Grid item>
              <IconButton onClick={() => setNoteFolded(!noteFolded)} className={classes.icon} size="large">
                {noteFolded ? <UnfoldMore /> : <UnfoldLess />}
              </IconButton>
            </Grid>
          )}
        {isEditable && (
          <Grid item>
            <PopUpMenu>
              <MenuItem onClick={() => setEditNoteOpen(true)}>Bearbeiten</MenuItem>
              <MenuItem onClick={note.pinned ? unpinNote : pinNote}>{note.pinned ? "Losheften" : "Anheften"}</MenuItem>
              <MenuItem onClick={() => setOpen(true)}>Als Dokument speichern</MenuItem>
              <MenuItem onClick={() => setDeleteNoteOpen(true)}>Löschen</MenuItem>
            </PopUpMenu>
            {!!product && <EditNote open={editNoteOpen} closeDialog={closeDialog} product={product} note={note} />}
            <LegalbirdIoConfirm
              handleClose={() => setDeleteNoteOpen(false)}
              open={deleteNoteOpen}
              handleConfirm={deleteNote}
              content="Möchten Sie diese Notiz tatsächlich löschen? Dies kann nicht rückgängig gemacht werden."
              confirmText="Notiz löschen"
              headline="Notiz löschen?"
            />
          </Grid>
        )}
      </Grid>
      {open && note.content && !!product && (
        <RequestSaveNoteModal product={product} note={note.content} handleClose={handleClose} open={open} />
      )}
      <div
        className={classNames(classes.note, noteFolded ? classes.collapsedNote : null)}
        dangerouslySetInnerHTML={{ __html: note.content! }}
      />
      {note.deleted && <div className={classes.overlay} />}
      {note.lastTouchedBy && (
        <div className={classes.lastChange}>
          {(note.deleted ? "Gelöscht durch " : "Zuletzt geändert durch ") +
            note.lastTouchedBy.person.fullname +
            " | " +
            moment(note.updated).format("DD.MM.YYYY | HH:mm")}
        </div>
      )}
    </Paper>
  );
};

export default Note;
