import ContentBox from "../../../ContentBox/ContentBox";
import { Box, Typography } from "@mui/material";
import ButtonLoading from "../../../Button/ButtonLoading";
import { useState } from "react";
import { useSnackbar } from "notistack";
import ApiClient from "../../../../services/ApiClient";

export default function EmailProcessing() {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const startWatchBcc = async () => {
    setIsLoading(true);
    try {
      await ApiClient.get("/start_watch_bcc");
      enqueueSnackbar("Mailverarbeitung wurde neu gestartet", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar("Mailverarbeitung konnte nicht neu gestartet werden", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <ContentBox headline="Mailverarbeitung neu starten">
      <Typography margin={"1rem 0"}>
        Falls es bei der Mailverarbeitung zu Fehlern kommt, kann diese durch einen Klick auf “Mailverarbeitung neu
        starten” neu angestoßen werden.
      </Typography>
      <ButtonLoading variant={"contained"} onClick={startWatchBcc} isLoading={isLoading}>
        Mailverarbeitung neu starten
      </ButtonLoading>
    </ContentBox>
  );
}
