const editActivityStyles = {
  headline: {
    textAlign: "center",
    fontSize: "1.25rem",
  },
  availabilityStatus: {
    marginBottom: ".5rem",
    color: "#ce7c12",
  },
  dialogContainer: {
    padding: "1rem",
    maxWidth: "700px",
  },
  checkbox: {
    color: "#137f7b",
  },
  centered: {
    display: "block",
    width: "100px",
    margin: "1rem auto 0 auto",
  },
  activitiesChosenDay: {
    textAlign: "center",
    padding: "1rem",
    fontSize: "1rem",
  },
  editor: {
    minHeight: "250px",
    backgroundColor: "#f8f4d0",
  },
  activityForm: {
    padding: "1rem",
    borderRight: { xs: "none", sm: "1px solid #000" },
  },
  activity: {
    fontSize: ".75rem",
    textAlign: "left",
    margin: ".5rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  activityGridItem: {
    padding: "0 1rem",
  },
};

export default editActivityStyles;
