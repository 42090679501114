import customerTranslations from "./customerTranslations";
import documentTranslations from "./documentTranslations";
import personTranslations from "./personTranslations";
import insuranceTranslations from "./insuranceTranslations";
import abstractCaseTranslations from "./abstractCaseTranslations";

const alimonyTranslations = {
  label: "Erstberatung Unterhalt",
  customer: customerTranslations,
  ...documentTranslations,
  petitioner: personTranslations,
  client: personTranslations,
  ...abstractCaseTranslations,
  basicChoice: {
    label: "Typ der Analyse",
    values: {
      complete: "Komplette Berechnung",
      children: "Kindesunterhalt",
      other: "Keine Berechnung",
    },
  },
  advisoryAppointment: {
    label: "Beratungsgespräch",
  },
  alimonyPetitioner: {
    person: personTranslations,
    isEmployed: {
      label: "Erwerbstätig",
    },
    netIncome: {
      label: "Nettoeinkommen",
    },
    otherIncome: {
      label: "Sonstiges Einkommen (Netto)",
    },
    hasMarriageRelatedDisadvantages: {
      label: "Ehebedingte Nachteile",
    },
    livingExpenses: {
      label: "Wohnkosten",
    },
    otherExpenses: {
      label: "Sonstige Ausgaben",
    },
  },
  alimonyPartner: {
    person: personTranslations,
    isEmployed: {
      label: "Erwerbstätig",
    },
    netIncome: {
      label: "Nettoeinkommen",
    },
    otherIncome: {
      label: "Sonstiges Einkommen (Netto)",
    },
    hasMarriageRelatedDisadvantages: {
      label: "Ehebedingte Nachteile",
    },
    livingExpenses: {
      label: "Wohnkosten",
    },
    otherExpenses: {
      label: "Sonstige Ausgaben",
    },
  },
  insurance: insuranceTranslations,
  numberOfChildren: {
    label: "Anzahl Kinder",
  },
  dateMarriage: {
    label: "Heiratsdatum",
  },
  areSeparated: {
    label: "Getrennt",
  },
  dateSeparation: {
    label: "Trennungsdatum",
  },
  areDivorced: {
    label: "Geschieden",
  },
  dateDivorce: {
    label: "Scheidungsdatum",
  },
  personalTextMessage: {
    label: "Fragen und Anmerkungen zum Fall",
  },
  children: {
    name: { label: "Name" },
    birthDate: { label: "Geburtsdatum" },
    childOf: {
      label: "Kind von",
      values: {
        petitioner: "Antragssteller",
        partner: "Partner",
        both: "Beiden",
      },
    },
    livesWith: {
      label: "Kind lebt bei",
      values: {
        petitioner: "Antragssteller",
        partner: "Partner",
        both: "Beiden",
        none: "Keinem",
      },
    },
    childAllowanceReceiver: {
      label: "Kindergeldempfänger",
      values: {
        petitioner: "Antragssteller",
        partner: "Partner",
        both: "Beide",
        none: "Keiner",
      },
    },
  },
  residenceAddress: {
    streetAddress: {
      label: "Straße und Hausnummer",
    },
    postalCode: {
      label: "PLZ",
    },
    addressLocality: {
      label: "Ort",
    },
  },
};

export default alimonyTranslations;
