import React from "react";
import { Route, Routes } from "react-router-dom";
import ProcessCostSupportListPage from "../components/_Pages/ProcessCostSupportListPage/ProcessCostSupportListPage";
import DashBoardPage from "../components/_Pages/DashboardPage/DashboardPage";
import DivorceCalculatorPage from "../components/_Pages/DivorceCalculatorPage/DivorceCalculatorPage";
import FineCalculatorPage from "../components/_Pages/FineCalculatorPage/FineCalculatorPage";
import ProcessCostSupportPage from "../components/_Pages/ProcessCostSupportPage/ProcessCostSupportPage";
import ControllingPage from "../components/_Pages/ControllingPage/ControllingPage";
import ActivityOverviewPage from "../components/_Pages/ActivityOverviewPage/ActivityOverviewPage";
import ActivityOverviewPageMobile from "../mobileComponents/_Pages/ActivityOverviewPage/ActivityOverviewPage";
import BoardPage from "../components/_Pages/BoardPage/BoardPage";
import TemplatesPage from "../components/_Pages/TemplatesPage/TemplatesPage";
import CreateCasePage from "../components/_Pages/CreateCasePage/CreateCasePage";
import PaymentRunPage from "../components/_Pages/PaymentRunPage/PaymentRunPage";
import AdminBoard from "../components/_Pages/AdminBoard/AdminBoard";
import UploadBankPaymentEntriesPage from "../components/_Pages/UploadBankPaymentEntriesPage/UploadBankPaymentEntriesPage";
import BankPaymentEntryPage from "../components/_Pages/BankPaymentEntryPage/BankPaymentEntryPage";
import ExternalInvoicePage from "../components/_Pages/ExternalInvoicePage/ExternalInvoicePage";
import AuthorityInvoicePage from "../components/_Pages/AuthorityInvoicePage/AuthorityInvoicePage";
import PaidTaskCollectionPage from "../components/_Pages/PaidTaskPage/PaidTaskCollectionPage";
import PaidTaskCollectionPageMobile from "../mobileComponents/_Pages/PaidTaskPage/PaidTaskCollectionPage";
import DeadlineOverviewPage from "../components/_Pages/DeadlineOverviewPage/DeadlineOverviewPage";
import DeadlineOverviewPageMobile from "../mobileComponents/_Pages/DeadlineOverviewPage/DeadlineOverviewPage";
import ProductsPage from "../components/_Pages/ProductsPage/ProductsPage";
import MyAccountPage from "../components/_Pages/MyAccountPage/MyAccountPage";
import { isMobile } from "react-device-detect";
import Error404Page from "../components/_Pages/Error404Page/Error404Page";
import LayoutDeviceBased from "../routeHelperComponents/LayoutDeviceBased";
import RequireRole from "../components/RequireAuth/RequireRole";
import ScannedMediaObjectListPage from "../components/_Pages/ScannedMediaObjectListPage/ScannedMediaObjectListPage";
import BeaAttachmentsPage from "../components/_Pages/BeaAttachmentsPage/BeaAttachmentsPage";
import BeaSearchPage from "../components/_Pages/BeaSearchPage/BeaSearchPage";
import BeaMessagesPage from "../components/_Pages/BeaMessagesPage/BeaMessagesPage";
import CustomerPage from "../components/_Pages/CustomerPage/CustomerPage";
import ExternalApplicationsPage from "../components/_Pages/ExternalApplicationsPage/ExternalApplicationsPage";
import ExternalApplicationPage from "../components/_Pages/ExternalApplicationPage/ExternalApplicationPage";
import HumanResourceManagement from "../components/HumanResourceManagement/HumanResourceManagement";
import MediaObjectsGalleryPage from "../components/_Pages/MediaObjectsGalleryPage/MediaObjectsGalleryPage";
import BeaAttachmentFilterPage from "../components/_Pages/BeaAttachmentFilterPage/BeaAttachmentFilterPage";
import UserManagementPage from "../components/_Pages/UserManagementPage/UserManagementPage";
import CreateReleaseUpdatePage from "../components/_Pages/CreateReleaseUpdatePage/CreateReleaseUpdatePage";
import ReleaseUpdatesPage from "../components/_Pages/ReleaseUpdatesPage/ReleaseUpdatesPage";
import EditReleaseUpdatePage from "../components/_Pages/EditReleaseUpdatePage/EditReleaseUpdatePage";
import { Roles } from "../types/BackofficeUser";
import DatasetsPage from "../components/_Pages/DatasetsPage/DatasetsPage";
import StatsPage from "../components/_Pages/StatsPage/StatsPage";
import WireTransferPage from "../components/_Pages/WiretransferPage/WireTransferPage";
import LawyerDashboardPage from "../components/_Pages/LawyerDashboardPage/LawyerDashboardPage";
import CaseRoutes from "./CaseRoutes";

const ServicesRoutes = () => {
  return (
    <LayoutDeviceBased>
      <Routes>
        <Route path="/" element={isMobile ? <ActivityOverviewPageMobile /> : <ActivityOverviewPage />} />
        <Route path={"/fall/:productUrlPart/:productId/*"} element={<CaseRoutes />} />
        <Route path={"/board/:productUrlPart/:caseType"} element={<BoardPage />} />
        <Route path={"/mandant/:customerId"} element={<CustomerPage />} />
        <Route path={"/board/uebersicht"} element={<ProductsPage />} />
        <Route path={"/aktivitaeten"} element={isMobile ? <ActivityOverviewPageMobile /> : <ActivityOverviewPage />} />
        <Route path={"/dashboard/anwalt"} element={<LawyerDashboardPage />} />
        <Route
          path={"/fristenkalender"}
          element={isMobile ? <DeadlineOverviewPageMobile /> : <DeadlineOverviewPage />}
        />
        <Route
          path={"/ausgeschriebene-aufgaben/verwalten"}
          element={isMobile ? <PaidTaskCollectionPageMobile /> : <PaidTaskCollectionPage />}
        />
        <Route path={"/tools/verfahrenskostenhilfe/antrag/:id"} element={<ProcessCostSupportPage />} />
        <Route path={"/tools"} element={<DashBoardPage />} />
        <Route path={"/tools/mandant-anlegen"} element={<CreateCasePage />} />
        <Route path={"/tools/verfahrenskostenhilfe"} element={<ProcessCostSupportListPage />} />
        <Route path={"/tools/hr-planung"} element={<HumanResourceManagement />} />
        <Route path={"/tools/bewerbungen"} element={<ExternalApplicationsPage />} />
        <Route path={"/tools/statistiken"} element={<StatsPage />} />
        <Route path={"/tools/bewerbungen/:applicationId"} element={<ExternalApplicationPage />} />
        <Route path={"/tools/bussgeldrechner"} element={<FineCalculatorPage />} />
        <Route path={"/tools/scheidungskostenrechner"} element={<DivorceCalculatorPage />} />
        <Route
          path={"/tools/vorlagen"}
          element={
            <RequireRole allowedRoles={[Roles.admin, Roles.developer]}>
              <TemplatesPage />
            </RequireRole>
          }
        />
        <Route path={"/tools/bea/suche"} element={<BeaSearchPage />} />
        <Route path={"/tools/bea/anhangs-filter"} element={<BeaAttachmentFilterPage />} />
        <Route path={"/tools/bea"} element={<BeaMessagesPage />} />
        <Route path={"/tools/bea/:beaId"} element={<BeaAttachmentsPage />} />
        <Route
          path={"/tools/zahlungen-hochladen"}
          element={
            <RequireRole allowedRoles={[Roles.admin, Roles.banking]}>
              <UploadBankPaymentEntriesPage />
            </RequireRole>
          }
        />
        <Route
          path={"/tools/zahlungseingaenge-buchen"}
          element={
            <RequireRole allowedRoles={[Roles.admin, Roles.accountmanager, Roles.accounting]}>
              <BankPaymentEntryPage />
            </RequireRole>
          }
        />
        <Route path={"/tools/zahllauf"} element={<PaymentRunPage />} />
        <Route path={"/tools/controlling"} element={<ControllingPage />} />
        <Route path={"/tools/externe-rechnungen-erstellen"} element={<ExternalInvoicePage />} />
        <Route path={"/tools/bearbeitung-rechnungen-ermittlungsakten"} element={<AuthorityInvoicePage />} />
        <Route path={"/tools/datensaetze"} element={<DatasetsPage />} />
        <Route path={"/tools/admin-board"} element={<AdminBoard />} />
        <Route path={"/tools/ueberweisung"} element={<WireTransferPage />} />
        <Route path={"/tools/dokumente-pruefen"} element={<ScannedMediaObjectListPage />} />
        <Route
          path={"/tools/nutzer-verwalten"}
          element={
            <RequireRole allowedRoles={[Roles.admin, Roles.accountmanager]}>
              <UserManagementPage />
            </RequireRole>
          }
        />
        <Route path={"/mein-konto"} element={<MyAccountPage />} />
        <Route path={"/tools/gallerie"} element={<MediaObjectsGalleryPage />} />
        <Route
          path={"/tools/release-update/neu"}
          element={
            <RequireRole allowedRoles={[Roles.admin, Roles.developer]}>
              <CreateReleaseUpdatePage />
            </RequireRole>
          }
        />
        <Route
          path={"/tools/release-update/:releaseUpdateId"}
          element={
            <RequireRole allowedRoles={[Roles.admin, Roles.developer]}>
              <EditReleaseUpdatePage />
            </RequireRole>
          }
        />
        <Route path={"/tools/release-updates"} element={<ReleaseUpdatesPage />} />
        <Route path={"/*"} element={<Error404Page />} />
      </Routes>
    </LayoutDeviceBased>
  );
};

export default ServicesRoutes;
