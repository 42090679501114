import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";

const serviceContractTranslations = {
  customer: customerTranslations,
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Handwerker / Dienstleistung",
  reasonForDispute: {
    label: "Problem mit Vertrag",
    values: {
      costEstimateExceeded: "Rechnung höher als Kostenvoranschlag",
      unfinishedWork: "Leistung unvollständig",
      hasDefects: "Leistung mangelhaft",
      other: "Anderes Problem",
    },
  },
  contractNumber: {
    label: "Auftragsnummer",
  },
  serviceTitle: {
    label: "Beschreibung der Dienstleistung",
  },
  costEstimate: {
    label: "Kostenvoranschlag",
  },
  costEstimateAdjustment: {
    label: "Information über Erhöhung",
    values: {
      notAtAll: "Nicht informiert",
      asap: "Umgehend informiert",
      tooLate: "Zu spät informiert",
    },
  },
  costEstimateAdjustmentDate: {
    label: "Datum Information Erhöhung",
  },
  costReal: {
    label: "Rechnungsbetrag",
  },
  defectsDescriptionClient: {
    label: "Beschreibung Mängel",
  },
  defectsDescriptionLawFirm: {
    label: "Mängelbeschreibung Kanzlei",
  },
  numberRepairAttempts: {
    label: "Ausbesserungsversuche",
    values: {
      0: "Nein",
      1: "Ja, 1 Mal",
      2: "Ja, 2 Mal",
      3: "Ja, 3 oder mehrere Male",
    },
  },
  endOfContractDate: {
    label: "Datum Vertragsende",
  },
};

export default serviceContractTranslations;
