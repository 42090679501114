import React, { useEffect, useState } from "react";
import _ from "lodash";
import ActivityFilter from "./ActivityFilter";
import {
  buildApiFilters,
  buildSearchParams,
  getUserActivitiesToday,
  postProcessFilterActivities,
  prepareFilters,
} from "../../../services/Activities/activityFunctions";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import { Paper } from "@mui/material";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import ExternalAdvisoryActivities from "../../Externals/Activities/ExternalAdvisoryActivities";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Activity } from "../../../types/Activity";

const ExternalsActivitiesContainer = () => {
  const currentUser = useCurrentUser();
  const { backofficeUsers } = useBackofficeUser();
  const [timespanFilter, setTimespanFilter] = useState(prepareFilters(currentUser).timespanFilter);
  const [userActivitiesToday, setUserActivitiesToday] = useState<Activity[]>([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const dataSource = buildSearchParams(
    _.merge(
      buildApiFilters({
        productFilter: "all",
        userFilter: currentUser["@id"],
        timespanFilter: timespanFilter,
      }),
      {
        refreshCounter: refreshCounter,
      }
    )
  );
  const { activities, isLoading } = useActivitiesFetching(dataSource.toString(), backofficeUsers);

  useEffect(() => {
    getUserActivitiesToday(currentUser).then((userActivitiesTodayResult) =>
      setUserActivitiesToday(userActivitiesTodayResult)
    );
  }, [currentUser]);

  const filteredActivities = isLoading ? [] : postProcessFilterActivities(activities!, timespanFilter);

  return (
    <PageContentContainer>
      <PageHeadline main="Geplante Termine" />
      <ActivityFilter
        isLoading={isLoading}
        timespanFilter={timespanFilter}
        setTimespanFilter={setTimespanFilter}
        userActivitiesToday={userActivitiesToday}
      />
      <Paper>
        <ExternalAdvisoryActivities activities={filteredActivities} />
      </Paper>
    </PageContentContainer>
  );
};

export default ExternalsActivitiesContainer;
