import React from "react";
import _ from "lodash";
import ValidatorTextField from "../Validator/ValidatorTextField";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

type ContractLawRepresentativesTypes = {
  name: string;
  values: Array<string>;
  disabled?: boolean;
  onChange: Function;
  gridSpacing?: number;
  gridSize?: number;
  buttonFullWidth?: boolean;
};

const ContractLawRepresentatives = ({
  name,
  values,
  disabled,
  onChange,
  gridSpacing = 0,
  gridSize = 12,
  buttonFullWidth = true,
}: ContractLawRepresentativesTypes) => {
  const handleChange = (value: string, index: number) => {
    let newArray = [...values];
    newArray[index] = value;
    onChange({ target: { name: name, value: newArray } });
  };

  const handleAddRepresentative = () => {
    let newArray: Array<string> = [];
    if (values) {
      newArray = [...values];
    }
    newArray.push("");
    onChange({ target: { name: name, value: newArray } });
  };

  return (
    <Grid container spacing={gridSpacing}>
      {_.map(values, (representative, index) => {
        return (
          <Grid item xs={gridSize} key={index}>
            <ValidatorTextField
              label={"Vertretungsberechtigter " + (index + 1)}
              name={"additionalRepresentative" + index}
              value={representative}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)}
              disabled={disabled}
              registerValidators={() => {}}
              validators={[]}
              isHighlighted={false}
              initialDependentValidationFields={[]}
            />
          </Grid>
        );
      })}
      {!disabled && (
        <Grid item xs={12}>
          <Button fullWidth={buttonFullWidth} onClick={handleAddRepresentative}>
            Vertretungsberechtigten hinzufügen
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ContractLawRepresentatives;
