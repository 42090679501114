import { Theme } from "@mui/material";

const pageContentContainerStyle = {
  pageContainer: (theme: Theme) => ({
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  }),
  default: {
    backgroundColor: "background.default",
  },
  white: {
    backgroundColor: "background.white",
  },
  contentContainer: {
    margin: "0 auto",
  },
  large: {
    maxWidth: 1400,
  },
  small: {
    maxWidth: 1100,
  },
  tiny: {
    maxWidth: 750,
  },
};

export default pageContentContainerStyle
