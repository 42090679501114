import React, { useState } from "react";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import ValidatorSelect from "../../Validator/ValidatorSelect";

type AdvoAssistOpenFeeAgreementModalProps = {
  submitAction: Function;
  open: boolean;
  handleClose: Function;
  refreshPage: Function;
};
export default function AdvoAssistOpenFeeAgreementModal({
  submitAction,
  open,
  handleClose,
  refreshPage,
}: AdvoAssistOpenFeeAgreementModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [fee, setFee] = useState("open");

  const handleConfirm = async () => {
    setIsLoading(true);
    await submitAction("open_fee_appointment", {
      isOpenFee: fee === "open",
    });
    await refreshPage();
    setIsLoading(false);
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title="Gerichtstermin neu ausschreiben"
      submitButton={
        <ButtonLoading onClick={handleConfirm} color="primary" variant={"contained"} isLoading={isLoading}>
          Neu Ausschreiben
        </ButtonLoading>
      }
    >
      <p>
        Sollte mit dem Standardtarif kein Terminsvertreter gefunden werden können, bitte "Offen Ausschreiben" auswählen,
        ansonsten "Standardtarif"
      </p>
      <Grid container>
        <Grid item xs={12}>
          <ValidatorSelect
            label="Tarifauswahl"
            name="fee"
            value={fee}
            onChange={(e: SelectChangeEvent) => setFee(e.target.value)}
          >
            <MenuItem value="open">Offene Ausschreibung - Honorarvorschläge einholen</MenuItem>
            <MenuItem value="default">Standardtarif</MenuItem>
          </ValidatorSelect>
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
}
