import { MERCURE_PREFIX, MERCURE_URL } from "../config/_entrypoint";
import _ from "lodash";
import ApiClient from "./ApiClient";

export const isCookieFetched = () => {
  return new Date().getTime() - parseInt(sessionStorage.getItem("mercureAuthorization") || "0") < 3600000;
};

export const mercureFetchCookie = () => {
  if (!_.includes(["fetching", "success"], sessionStorage.getItem("mercureAuthorization"))) {
    sessionStorage.setItem("mercureAuthorization", "fetching");
    ApiClient.get("mercure/discover", { credentials: "include" }).then(
      () => sessionStorage.setItem("mercureAuthorization", new Date().getTime().toString()),
      (err) => sessionStorage.setItem("mercureAuthorization", "error: " + err.message)
    );
  }
};

export const buildTopic = (topic: string) => {
  return "/" + MERCURE_PREFIX + topic;
};

export const mercureSubscribe = (topic: string | string[], setNotification: Function) => {
  const url = new URL(MERCURE_URL as string);

  if (_.isArray(topic)) {
    _.forEach(topic, (t) => url.searchParams.append("topic", buildTopic(t)));
  } else {
    url.searchParams.append("topic", buildTopic(topic));
  }
  const eventSource = new EventSource(url.toString(), { withCredentials: true });
  eventSource.onmessage = (e) => {
    setNotification(JSON.parse(e.data));
  };
  return eventSource;
};
