import React, { useMemo } from "react";
import CourtHit from "./CourtHit";
import ApiClient from "../../services/ApiClient";
import { ALGOLIA_COURT_INDEX } from "../../config/_entrypoint";
import { useCase } from "../../provider/CaseProvider";
import _ from "lodash";
import SearchBar from "../Algolia/SearchBar";

type CourtPickerProps = {
  disabled: boolean;
  courtTypes: Array<string>;
};
const CourtPicker = ({ disabled = false, courtTypes }: CourtPickerProps) => {
  const { product, refreshCase } = useCase();

  const algoliaSearchOptions = useMemo(() => {
    let filterString = "";

    _.forEach(courtTypes, (courtType, index) => {
      if (index === 0) {
        filterString += "type:" + courtType;
        return;
      }
      filterString += " OR type:" + courtType;
    });

    return {
      filters: filterString,
    };
  }, [courtTypes]);

  if (!product) {
    return null;
  }

  const parentEntityUrl = product["@id"];

  const onHitClick = async (courtId: number) => {
    await ApiClient.put(parentEntityUrl, {
      body: JSON.stringify({
        responsibleCourt: "/courts/" + courtId,
        backofficeCaseId: product.backofficeCase.id,
      }),
    });
    await refreshCase();
  };

  return (
    <SearchBar
      disabled={disabled}
      searchConfig={{
        indexName: ALGOLIA_COURT_INDEX,
        requestOptions: algoliaSearchOptions,
      }}
      resultListConfig={{
        itemSize: 200,
      }}
      HitComponent={CourtHit}
      onHitClick={onHitClick}
    />
  );
};

export default CourtPicker;
