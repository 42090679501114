import React, { useEffect, useRef, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import ApiClient from "../../services/ApiClient";
import useForm from "../../hooks/useForm";
import UserAbsenceFormElements from "../HumanResourceManagement/UserAbsenceFormElements";
import { Box, Grid } from "@mui/material";
import SubmitButton from "../Button/SubmitButton";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import ValidatorForm from "../Validator/ValidatorForm";
import { scrollToAnchor } from "../../theme/commonStyles";
import moment from "moment";
import { DateObject } from "react-multi-date-picker";
import { useSnackbar } from "notistack";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import HumanResource from "../../types/HumanResource";
import { HydraResult } from "../../types/HydraResult";
import Absence, { FormAbsence, standInIsHumanResource } from "../../types/Absence";
import { UseForm } from "../../types/UseForm";

type UserAbsenceManagementProps = {
  userId: number;
};

export default function UserAbsenceManagement({ userId }: UserAbsenceManagementProps) {
  const isMounted = useRef(false);
  const [currentHumanResource, setCurrentHumanResource] = useState<HumanResource | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { internals } = useBackofficeUser();
  const { enqueueSnackbar } = useSnackbar();
  const hrApiUrl = "human_resources";

  const updateCurrentHumanResource = (data: HydraResult<HumanResource>) => {
    const fetchedHumanResource = data["hydra:member"].find(
      (humanResource: HumanResource) => humanResource.backofficeUser === "/backoffice_users/" + userId
    );
    setCurrentHumanResource(fetchedHumanResource || null);
    if (fetchedHumanResource) {
      const absences = toAugmentedAbsences(fetchedHumanResource.absences);
      updateAllValues({
        absences: absences,
        trainedProducts: fetchedHumanResource.trainedProducts,
      });
    }
  };

  const { data: humanResources, isFetching: isFetchingHumanResources } = useQuery(
    queryKeys.collection(hrApiUrl),
    () => fetchCollection(hrApiUrl),
    {
      onSuccess: updateCurrentHumanResource,
    }
  );
  const queryClient = useQueryClient();

  const initialValues = {
    absences: [],
  };

  useEffect(() => {
    if (isMounted.current) {
      queryClient.invalidateQueries(queryKeys.collection(hrApiUrl));
      return;
    }
    isMounted.current = true;
  }, [userId]);

  const toAugmentedAbsences = (absences: Absence[]) => {
    const iriBase = "/human_resources/";
    return absences.map((absence) => ({
      absenceDate: new DateObject(absence.absenceDate),
      standIn1: standInIsHumanResource(absence.standIn1) ? iriBase + absence.standIn1.id : null,
      standIn2: standInIsHumanResource(absence.standIn2) ? iriBase + absence.standIn2.id : null,
      standIn3: standInIsHumanResource(absence.standIn3) ? iriBase + absence.standIn3.id : null,
    }));
  };

  const fromFormAbsences = (absences: FormAbsence[], humanResourceIri: string, originalAbsences: Absence[]) =>
    _.map(absences, (absence) => {
      let absenceToPersist: Partial<Absence> = {
        absenceDate: absence.absenceDate.format("YYYY-MM-DD"),
        absentHumanResource: humanResourceIri,
        standIn1: absence.standIn1,
        standIn2: absence.standIn2,
        standIn3: absence.standIn3 || null,
      };

      const previousAbsence = originalAbsences.find(
        (origAbsence) => moment(origAbsence.absenceDate).format("YYYY-MM-DD") === absenceToPersist.absenceDate
      );

      if (previousAbsence) {
        //so that old ones are overwritten and no duplicates are created
        absenceToPersist["@id"] = previousAbsence["@id"];
      }
      return absenceToPersist;
    });

  const onSubmit = async ({ values }: UseForm["values"]) => {
    setIsLoading(true);
    if (currentHumanResource) {
      const humanResourceIri = "/human_resources/" + currentHumanResource.id;
      const convertedAbsences = fromFormAbsences(values.absences, humanResourceIri, currentHumanResource.absences);

      const absenceIdsToBeDeleted = currentHumanResource.absences
        .filter((absence) => convertedAbsences.every((convertedAbsence) => convertedAbsence["@id"] !== absence["@id"]))
        .map((absence) => absence.id);

      if (absenceIdsToBeDeleted.length > 0) {
        await ApiClient.delete("/batch_delete/absence", {
          body: JSON.stringify({ ids: absenceIdsToBeDeleted }),
        });
      }

      try {
        await ApiClient.put(humanResourceIri, {
          body: JSON.stringify({
            absences: convertedAbsences,
          }),
        });
      } catch (e: any) {
        enqueueSnackbar(e.errors["humanResource.absences"] || "Es ist ein Fehler aufgetreten", {
          variant: "custom",
          isNonInteractive: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
      await queryClient.invalidateQueries(queryKeys.collection(hrApiUrl));
    }
    setIsLoading(false);
  };

  const useFormProps = useForm({
    initialValues,
    onSubmit,
  });

  const { handleSubmit, updateAllValues, touchedValues, errors } = useFormProps;

  const anchorId = "absence";

  if (isFetchingHumanResources) {
    return (
      <>
        <Box sx={scrollToAnchor} id={anchorId} />
        <LegalbirdLoader />
      </>
    );
  }

  return (
    <>
      <Box sx={scrollToAnchor} id={anchorId} />
      <ContentBox headline={"Abwesenheit & Vertretung"}>
        {humanResources && currentHumanResource && (
          <ValidatorForm onSubmit={handleSubmit}>
            <UserAbsenceFormElements
              useFormProps={useFormProps}
              absentUserId={userId}
              humanResources={humanResources["hydra:member"]}
            />
            <Grid item xs={12} sx={{ paddingTop: "1rem" }}>
              {/*second disabled part (_.find) so that the initial user cant save his human resource entity if he's not internal*/}
              <SubmitButton
                error={_.keys(errors).length > 0}
                isLoading={isLoading}
                disabled={
                  _.keys(touchedValues).length === 0 || !_.find(internals, (internalUser) => internalUser.id === userId)
                }
                variant={"contained"}
                type={"submit"}
              >
                Speichern
              </SubmitButton>
            </Grid>
          </ValidatorForm>
        )}
      </ContentBox>
    </>
  );
}
