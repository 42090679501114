export const emailStyle = {
  emailContainer: {
    fontSize: "1rem",
    flexWrap: {
      md: "nowrap",
    },
  },
  headline: {
    fontWeight: 700,
  },
  info: {
    fontSize: ".875rem",
  },
  icon: {
    paddingRight: "2rem",
  },
  expandButton: {
    color: "#000000",
  },
  expandGridItem: {
    display: "block",
    margin: "0 0 0 auto",
  },
  attachmentIcon: {
    display: "block",
    margin: "5px 0 0 auto",
    color: "#000000",
  },
  attachmentContainer: {
    marginTop: "1rem",
  },
  mailContent: {
    width: "75%",
  },
};
