import React from "react";
import _ from "lodash";
import Note from "../Case/CaseNote/Note";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { Box } from "@mui/material";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { AbstractCase } from "../../types/AbstractCase";

type PinnedNotesProps = {
  product: AbstractCase;
  isEditable?: boolean;
};

const PinnedNotes = ({ product, isEditable = true }: PinnedNotesProps) => {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const noteFilters = {
    case: product.backofficeCase["@id"],
    pinned: true,
  };
  const { data: pinnedNotes } = useQuery(
    queryKeys.collection("notes", noteFilters),
    () => fetchCollection("notes", noteFilters),
    {
      enabled: !!product,
    }
  );

  return (
    <div>
      {pinnedNotes &&
        _.map(pinnedNotes["hydra:member"], (pinnedNote) => (
          <Box key={pinnedNote.id} sx={{ margin: "1rem 0 2rem" }}>
            <Note
              note={pinnedNote}
              handleChange={() => queryClient.invalidateQueries(queryKeys.collection("notes", noteFilters))}
              startsCollapsed
              product={product}
              isEditable={isEditable || pinnedNote.author.id === currentUser.id}
            />
          </Box>
        ))}
    </div>
  );
};


export default PinnedNotes;
