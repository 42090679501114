const casePdfGeneratorStyle = {
  editor: {
    minHeight: "20rem",
    maxWidth: "670px",
  },
  paper: {
    border: "1px solid #999",
    margin: "1rem 0 2rem 0",
    padding: "2rem",
  },
  buttonContainer: {
    textAlign: "center",
  },
  embedContainer: {
    minWidth: "880px",
    width: "100%",
    height: "80vh",
  },
  imageContainer: {
    maxHeight: "85vh",
    overflow: "auto",
  },
  previewButton: {
    margin: "0 auto",
    display: "block",
    width: "230px",
    height: "40px",
  },
  submitButton: {
    margin: "0 auto",
    display: "block",
    marginTop: "1rem",
    width: "230px",
  },
  fileSizeWarning: {
    margin: "0 auto",
    display: "block",
    fontSize: "1rem",
    color: "#0000003f",
  },
};

export default casePdfGeneratorStyle;
