import React, { useState } from "react";
import Button from "@mui/material/Button";
import moment from "moment";
import DateRangePicker from "../../../DateRangePicker/DateRangePicker";
import { TimespanFilter } from "../../../Case/CaseHistory/CaseHistoryFilter";

type DateRangeFilterProps = {
  timespanFilter: TimespanFilter;
  setTimespanFilter: (value: string) => void;
};
const DateRangeFilter = ({ timespanFilter, setTimespanFilter }: DateRangeFilterProps) => {
  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const isDateRange = typeof timespanFilter === "object";
  return (
    <>
      <Button
        variant={typeof timespanFilter === "object" ? "contained" : "text"}
        onClick={() => setDateRangePickerOpen(true)}
      >
        {isDateRange
          ? moment(timespanFilter.startDate).format("DD.MM.YY") +
            " - " +
            moment(timespanFilter.endDate).format("DD.MM.YY")
          : "Zeitraum auswählen"}
      </Button>
      <DateRangePicker
        setDateRange={setTimespanFilter}
        open={dateRangePickerOpen}
        closeModal={() => setDateRangePickerOpen(false)}
      />
    </>
  );
};

export default DateRangeFilter;
