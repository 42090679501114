export const submitButtonStyles = {
  error: {
    color: "#db1919",
    textAlign: "center",
    marginBottom: "1rem",
  },
  button: {
    margin: "0 auto",
    display: "block",
    width: 230,
    height: 40,
  },
};
