import PageContentContainer from "../../Container/PageContentContainer";
import PersonalData from "../../MyAccount/PersonalData";
import Billing from "../../MyAccount/Billing/Billing";
import Signature from "../../MyAccount/Signature/Signature";
import BeAData from "../../MyAccount/BeA/BeAData";
import UserAbsenceManagement from "../../MyAccount/UserAbsenceManagement";
import PageHeadline from "../../PageHeadline/PageHeadline";
import NavigationExtensionMyAccount from "../../NavigationBar/NavigationExtensionMyAccount";
import { userHasOneOfTheseRoles, userHasRole } from "../../../services/backofficeUserService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Roles } from "../../../types/BackofficeUser";
import UserWorkingHoursManagement from "../../MyAccount/UserWorkingHoursManagement";

const MyAccountPage = () => {
  const currentUser = useCurrentUser();

  return (
    <>
      <NavigationExtensionMyAccount />
      <PageContentContainer size={"small"}>
        <PageHeadline
          main={"Alle Details zum Konto von"}
          sub={`${currentUser.person.gender === "female" ? "Frau" : "Herrn"} ${currentUser.person.givenName} ${
            currentUser.person.familyName
          }`}
        />
        <PersonalData />
        {userHasRole(currentUser, Roles.external) && <Billing />}
        <Signature />
        <BeAData />
        {userHasOneOfTheseRoles(currentUser, [Roles.accountmanager, Roles.lawyer, Roles.traineeLawyer]) && (
          <>
            <UserAbsenceManagement userId={currentUser.id} />
            <UserWorkingHoursManagement userId={currentUser.id} />
          </>
        )}
      </PageContentContainer>
    </>
  );
};
export default MyAccountPage;
