import React, { SyntheticEvent } from "react";
import { Autocomplete, AutocompleteProps } from "@mui/material";
import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { AutoCompleteOptionWithId } from "../../types/AutoCompleteOptionWithId";
import { AutoCompleteOptionWithValue } from "../../types/AutoCompleteOptionWithValue";
import legalbirdAutoCompleteStyle from "./legalbirdAutoCompleteStyle";

type LegalbirdAutoCompleteProps<TOption> = {
  setValue: (event: SyntheticEvent, value: TOption | null) => void;
  label: string;
  onFocus?: TextFieldProps["onFocus"];
  textFieldProps?: TextFieldProps;
  textFieldStyles?: TextFieldProps["sx"];
} & Omit<AutocompleteProps<TOption, false, boolean, false, React.ElementType>, "renderInput">;

export function LegalbirdAutoComplete<TOption extends AutoCompleteOptionWithValue | AutoCompleteOptionWithId>({
  setValue,
  label = "",
  onFocus = () => {},
  textFieldProps,
  textFieldStyles = {},
  ...rest
}: LegalbirdAutoCompleteProps<TOption>) {
  return (
    <Autocomplete<TOption, false, boolean, false>
      renderInput={(params) => (
        <TextField
          {...params}
          onFocus={onFocus}
          label={label}
          sx={{ ...legalbirdAutoCompleteStyle.textField, ...textFieldStyles }}
          {...textFieldProps}
        />
      )}
      getOptionLabel={(option: TOption) => {
        if (!option) {
          return "";
        }
        return option.label;
      }}
      disableClearable
      onChange={setValue}
      {...rest}
    />
  );
}

export default LegalbirdAutoComplete;
