import React, { useEffect, useState } from "react";
import { Box, MenuItem, Paper, Stack } from "@mui/material";
import ValidatorSelect from "../Validator/ValidatorSelect";
import DatasetEntityPicker from "../DatasetEntityPicker/DatasetEntityPicker";

export default function Datasets() {
  const [datasetType, setDatasetType] = useState<string>("contractualPartnerLegalEntity");
  const [datasetAction, setDatasetAction] = useState<string>("add");

  useEffect(() => {
      if (datasetType === "court") {
        setDatasetAction("edit")
      }
  },[datasetType]);

  return (
    <>
      <Paper sx={{ padding: "1rem" }}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
          <Box width={"33%"}>
            <ValidatorSelect
              label={"Datensatztyp auswählen"}
              onChange={(event) => setDatasetType(event.target.value)}
              value={datasetType}
            >
              <MenuItem value={"contractualPartnerLegalEntity"}>Gegenseite</MenuItem>
              <MenuItem value={"authority"}>Behörde</MenuItem>
              <MenuItem value={"prosecution"}>Staatsanwaltschaft</MenuItem>
              <MenuItem value={"debtCollectionAgency"}>Inkasso</MenuItem>
              <MenuItem value={"court"}>Gerichte</MenuItem>
            </ValidatorSelect>
          </Box>
          <Box width={"33%"}>
            <ValidatorSelect
              label={"Funktion auswählen"}
              onChange={(event) => setDatasetAction(event.target.value)}
              value={datasetAction}
            >
              <MenuItem value={"add"} disabled={datasetType === "court"}>
                Neu Anlegen
              </MenuItem>
              <MenuItem value={"edit"}>Bearbeiten</MenuItem>
            </ValidatorSelect>
          </Box>
        </Stack>
      </Paper>
      <DatasetEntityPicker datasetType={datasetType} datasetAction={datasetAction} />
    </>
  );
}
