import React, { useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { ExpandLess, ExpandMore, Send } from "@mui/icons-material";
import _ from "lodash";
import moment from "moment";
import CircleIcon from "../CircleIcon/CircleIcon";
import BeaAttachment from "./BeaAttachment";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import ApiClient from "../../services/ApiClient";
import BeaMessageActions from "./BeaMessageActions";
import { translate } from "../../services/Translations/translatorService";
import IconButton from "@mui/material/IconButton";
import { BeaMessage as BeaMessageType } from "../../types/Bea/BeaMessage";
import { AbstractCase } from "../../types/AbstractCase";

type BeaMessageProps = {
  beaMessage: BeaMessageType;
  product: AbstractCase;
  refreshBeaMessages: Function;
};

export default function BeaMessage({ beaMessage, product, refreshBeaMessages }: BeaMessageProps) {
  const [transmissionReport, setTransmissionReport] = useState(null);
  const [displayAllAttachments, setDisplayAllAttachments] = useState(false);

  const fetchTransmissionReport = () => {
    ApiClient.get(beaMessage.transmissionReport!).then((result) => {
      setTransmissionReport(result);
    });
  };

  const attachmentsToDisplay = displayAllAttachments ? beaMessage.attachments : beaMessage.attachments.slice(0, 3);

  return (
    <Stack direction={"row"} spacing={2} sx={{ marginBottom: "2rem" }}>
      <Box sx={{ width: 40 }}>
        <CircleIcon icon={<Send />} type={"muiIcon"} />
      </Box>
      <Grid container>
        <Grid item xs={12} sx={{ fontSize: "1rem" }}>
          <strong>{beaMessage.subject}</strong>
        </Grid>
        <Grid item xs={12}>
          {moment(beaMessage.created).format("DD.MM.YYYY")} um {moment(beaMessage.created).format("HH:mm")} Uhr |{" "}
          {beaMessage?.sender?.firstname} {beaMessage?.sender?.surname} {">>"} {beaMessage?.recipient?.firstname}{" "}
          {beaMessage?.recipient?.surname} (ID: {beaMessage?.beaMessageId})
        </Grid>
        {beaMessage.messageConfirmationResponse && beaMessage.messageConfirmationResponse.payload && (
          <Grid item xs={12}>
            Zustellantwort:{" "}
            {_.isArray(beaMessage.messageConfirmationResponse.payload)
              ? beaMessage.messageConfirmationResponse.payload.map((entry) => entry.replaceAll("|", "; "))
              : beaMessage.messageConfirmationResponse.payload}
          </Grid>
        )}
        {beaMessage.message && (
          <Grid item xs={12}>
            {beaMessage.message}
          </Grid>
        )}
        {beaMessage.transmissionDirection === "sent" && beaMessage.transmissionReport && (
          <Grid item sx={{ padding: ".5rem" }}>
            <Box
              sx={{ border: "1px solid #d9d9d9", padding: ".5rem", cursor: "pointer" }}
              onClick={() => fetchTransmissionReport()}
            >
              Sendebericht
            </Box>
            <DisplayMediaObject closeDialog={() => setTransmissionReport(null)} mediaObject={transmissionReport} />
          </Grid>
        )}
        {_.map(attachmentsToDisplay, (attachment) => (
          <Grid item key={attachment.id} sx={{ padding: ".5rem .5rem .5rem 0" }}>
            <BeaAttachment product={product} attachment={attachment} />
          </Grid>
        ))}
        {beaMessage.attachments?.length > 3 && (
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => setDisplayAllAttachments(!displayAllAttachments)}>
              {displayAllAttachments ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        )}
        {beaMessage.eEBStatus === "submitted" && (
          <Grid item xs={12}>
            eEB abgegeben am {moment(beaMessage.acknowledgementOfReceipt?.submitOrRejectDateTime).format("DD.MM.YYYY")}{" "}
            um {moment(beaMessage.acknowledgementOfReceipt?.submitOrRejectDateTime).format("HH:mm")} Uhr
          </Grid>
        )}
        {beaMessage.eEBStatus === "rejected" && (
          <Grid item xs={12}>
            eEB abgelehnt am {moment(beaMessage.acknowledgementOfReceipt?.submitOrRejectDateTime).format("DD.MM.YYYY")}{" "}
            um {moment(beaMessage.acknowledgementOfReceipt?.submitOrRejectDateTime).format("HH:mm")} Uhr | Grund:{" "}
            {translate(
              "beaMessage.acknowledgementOfReceipt.rejectionReason." +
                beaMessage.acknowledgementOfReceipt?.rejectionReason
            )}{" "}
            | Erläuterung: {beaMessage.acknowledgementOfReceipt?.explanation}
          </Grid>
        )}
      </Grid>
      <BeaMessageActions beaMessage={beaMessage} refreshBeaMessages={refreshBeaMessages} product={product} />
    </Stack>
  );
}
