import ValidatorSelect from "./ValidatorSelect";
import { formValue } from "../../services/formServiceFunctions";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { UseForm } from "../../types/UseForm";

export default function ValidatorElectronicFileFolderPathSelect({
  handleChange,
  values,
  errors,
}: Pick<UseForm, "handleChange"| "values" | "errors" >) {
  return (
    <ValidatorSelect
      label={"Ordner"}
      name={"electronicFileFolderPath"}
      value={formValue(values, "electronicFileFolderPath")}
      onChange={handleChange}
      error={!!errors["electronicFileFolderPath"]}
      helperText={errors["electronicFileFolderPath"]}
    >
      <MenuItem value={"none"} disabled>
        Ordner
      </MenuItem>{" "}
      <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
      <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
      <MenuItem value={"Mandantschaft"}>Mandantschaft</MenuItem>
      <MenuItem value={"RSV"}>RSV</MenuItem>
      <MenuItem value={"ToDo"}>ToDo</MenuItem>
      <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
    </ValidatorSelect>
  );
}
