import React, { useMemo } from "react";
import humanResourceManagementStyle from "./humanResourceManagementStyle";
import { Grid, MenuItem, Typography } from "@mui/material";
import _ from "lodash";

import { translate } from "../../services/Translations/translatorService";
import { formValue } from "../../services/formServiceFunctions";
import ValidatorSelect, { ValidatorSelectProps } from "../Validator/ValidatorSelect";
import DailyWorkHours from "../../types/DailyWorkHours";

type WorkingHoursFormElementsProps = {
  values: Record<string, any>;
  onChange: Function;
  onBlur: Function;
} & Pick<ValidatorSelectProps, "onChange" | "onBlur">;

const WorkingHoursFormElements = ({ values, onChange, onBlur }: WorkingHoursFormElementsProps) => {
  const timeSlots = useMemo(() => {
    const startTime = 7;
    const endTime = 20;

    let slots = [];
    for (let i = startTime; i <= endTime; i++) {
      const timeSlotFullHour = i.toLocaleString("de-DE", { minimumIntegerDigits: 2 }) + ":00";
      const timeSlotHalfHour = i.toLocaleString("de-DE", { minimumIntegerDigits: 2 }) + ":30";

      slots.push(
        <MenuItem key={timeSlotFullHour} value={timeSlotFullHour}>
          {timeSlotFullHour} Uhr
        </MenuItem>
      );

      if (i < endTime) {
        slots.push(
          <MenuItem key={timeSlotHalfHour} value={timeSlotHalfHour}>
            {timeSlotHalfHour} Uhr
          </MenuItem>
        );
      }
    }
    return slots;
  }, []);

  function timeStringToHours(timeString: string): number {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours + minutes / 60;
  }

  const weeklyWorkHours = Object.keys(values.workingHours).reduce((acc, day) => {
    return acc + getDailyWorkHours(day).hoursExcludingBreak;
  }, 0);

  function getDailyWorkHours(dayName: string): DailyWorkHours {
    const hoursIncludingBreak =
      timeStringToHours(formValue(values, "workingHours." + dayName + ".end")) -
      timeStringToHours(formValue(values, "workingHours." + dayName + ".start"));
    const breakDuration = hoursIncludingBreak >= 9 ? 0.75 : 0.5;
    const hoursExcludingBreak = hoursIncludingBreak - breakDuration;
    return {
      hoursExcludingBreak: hoursExcludingBreak,
      hoursIncludingBreak: hoursIncludingBreak,
      breakHours: breakDuration,
    };
  }

  return (
    <Grid container justifyContent={"center"} spacing={2}>
      <Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h4" sx={humanResourceManagementStyle.subHeadline}>
          Arbeitszeit eintragen
        </Typography>
        <Grid container>
          {_.map(values.workingHours, (workingHourTimes, workingHourDay) => {
            const workHours = getDailyWorkHours(workingHourDay);
            return (
              <React.Fragment key={workingHourDay}>
                <Grid item xs={4} sx={humanResourceManagementStyle.workTimeSelectGridItem}>
                  <ValidatorSelect
                    label={"Start " + translate("general.dateTime." + workingHourDay, workingHourDay)}
                    name={"workingHours." + workingHourDay + ".start"}
                    value={formValue(values, "workingHours." + workingHourDay + ".start")}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {timeSlots}
                  </ValidatorSelect>
                </Grid>
                <Grid item xs={4} sx={humanResourceManagementStyle.workTimeSelectGridItem}>
                  <ValidatorSelect
                    label={"Ende " + translate("general.dateTime." + workingHourDay, workingHourDay)}
                    name={"workingHours." + workingHourDay + ".end"}
                    value={formValue(values, "workingHours." + workingHourDay + ".end")}
                    onChange={onChange}
                    onBlur={onBlur}
                  >
                    {timeSlots}
                  </ValidatorSelect>
                </Grid>

                <Grid item xs={4} display={"flex"} justifyContent="center" alignItems="center">
                  <Typography variant={"h5"}>
                    {workHours.hoursIncludingBreak.toLocaleString("de-DE")} Std. - {workHours.breakHours * 60} Min.
                    Pause
                    <br />
                    <strong>= {workHours.hoursExcludingBreak.toLocaleString("de-DE")} Std.</strong>
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
          <Grid item xs={8}>
            {" "}
          </Grid>
          <Grid item xs={4} sx={{ paddingLeft: "2.5rem" }}>
            <Typography fontWeight={"bold"} variant={"h5"}>
              <>
                Arbeitszeit ={" "}
                {!!values?.workingHours && weeklyWorkHours.toLocaleString("de-DE", { minimumFractionDigits: 2 })} Std.
              </>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkingHoursFormElements;
