import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import { apiPost } from "../../../services/Api/apiCall";
import { getRequestDataCopyAllowed } from "../../../services/Product/RequestSchufaDataCopyModal/getRequestDataCopyAllowed";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import useForm from "../../../hooks/useForm";
import { formValue } from "../../../services/formServiceFunctions";
import { UseForm } from "../../../types/UseForm";

type RequestDataCopyModalProps = {
  product: AbstractCase;
  open: boolean;
  handleClose: Function;
};

export default function RequestDataCopyModal({ open, handleClose, product }: RequestDataCopyModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  let inquiryToCustomerFilter = {
    productId: product.id,
    "status[lt]": 40,
  };
  const { data: inquiryToCustomerCollection } = useQuery(
    queryKeys.collection("inquiry_to_customers", inquiryToCustomerFilter),
    () => fetchCollection("inquiry_to_customers", inquiryToCustomerFilter)
  );

  const getSuccessMessage = (values: { [x: string]: any }) => {
    switch (true) {
      case values.sendSchufa && values.sendCrif:
        return "Schufa / Crif-Anfrage wurde automatisch versendet";
      case values.sendSchufa:
        return "Schufa-Anfrage wurde automatisch an die Schufa versendet";
      default:
        return "Crif-Anfrage wurde automatisch an die Crif versendet";
    }
  };

  const getErrorMessage = (values: { [x: string]: any }) => {
    switch (true) {
      case values.sendSchufa && values.sendCrif:
        return "Schufa / Crif-Anfrage konnte nicht erstellt werden";
      case values.sendSchufa:
        return "Schufa-Anfrage konnte nicht erstellt werden";
      default:
        return "Crif-Anfrage konnte nicht erstellt werden";
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await apiPost(`/lb/services/request_data_copy/${product.id}`, {
        createdBy: currentUser["@id"],
        sendSchufa: values.sendSchufa,
        sendCrif: values.sendCrif,
      });
      enqueueSnackbar(getSuccessMessage(values), {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar(getErrorMessage(values), {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    clearForm();
    setIsLoading(false);
    handleClose();
  };

  const initialValues = {
    sendSchufa: false,
    sendCrif: false,
  };

  const { values, handleChange, clearForm }: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  if (!inquiryToCustomerCollection) {
    return null;
  }

  const { allowed, message } = getRequestDataCopyAllowed(product, inquiryToCustomerCollection["hydra:member"]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Datenauskunft anfordern"}
      actionsInfo={message ? <Typography sx={{ textAlign: "center", color: "red" }}>{message}</Typography> : null}
      submitButton={
        <ButtonLoading
          onClick={onSubmit}
          color="primary"
          variant={"contained"}
          isLoading={isLoading}
          disabled={!allowed || !(formValue(values, "sendSchufa") || formValue(values, "sendCrif"))}
        >
          Datenauskunft anfordern
        </ButtonLoading>
      }
    >
      <Typography align={"center"}>
        Möchten Sie eine Datenauskunft für die Mandantschaft per E-Mail anfordern? Wenn ja, wählen Sie bitte aus von
        welcher Auskunftei diese angefordert werden soll.
      </Typography>

      <Grid container alignItems="center" justifyContent="center" sx={{ marginTop: "10px" }}>
        <Grid item xs={3}>
          <FormControlLabel
            control={<Checkbox name={"sendSchufa"} onChange={handleChange} value={formValue(values, "sendSchufa")} />}
            label="Schufa"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={<Checkbox name={"sendCrif"} onChange={handleChange} value={formValue(values, "sendCrif")} />}
            label="CRIF"
          />
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
}
