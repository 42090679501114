import React from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import { Box, Grid, Paper, Typography } from "@mui/material";
import PageHeadline from "../../PageHeadline/PageHeadline";
import moment from "moment";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Activity as ActivityType } from "../../../types/Activity";
import SmallWidget from "../../Externals/Dashboard/SmallWidget";
import { DateRangeOutlined, ListOutlined, LocalPhoneOutlined, PeopleAltOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import AdvisoryCallList from "../../AdvisoryCallsTable/AdvisoryCallList";
import LawyerDashboardActivitiesTable from "./LawyerDashboardActivitiesTable";
import { buildApiFilters, buildSearchParams } from "../../../services/Activities/activityFunctions";
import { deadlineActivityTypes, lawyerCallActivityTypes } from "../../../services/Activities/activityTypes";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import Absence from "../../../types/Absence";
import { useQuery } from "@tanstack/react-query";
import HumanResource from "../../../types/HumanResource";

export default function LawyerDashboardPage() {
  const currentUser = useCurrentUser();
  const { backofficeUsers } = useBackofficeUser();
  const dataSource = buildSearchParams(
    buildApiFilters({
      productFilter: "all",
      userFilter: currentUser["@id"],
      timespanFilter: "today",
      activityTypeFilter: "all",
      subjectFilter: "",
      openOrDoneFilter: "open",
      backofficeUsers: backofficeUsers,
    })
  );

  const { activities, isLoading } = useActivitiesFetching(dataSource.toString(), backofficeUsers);

  const humanResourcesFilter = {
    backofficeUser: currentUser["@id"],
  };

  const { data: humanResources } = useQuery(queryKeys.collection("human_resources", humanResourcesFilter), () =>
    fetchCollection("human_resources", humanResourcesFilter)
  );

  if (isLoading || !activities) {
    return <LegalbirdLoader centered={true} />;
  }

  const deadlineActivities = isLoading
    ? []
    : activities.filter((activity: ActivityType) => deadlineActivityTypes.includes(activity.type));

  const callActivities = isLoading
    ? []
    : activities.filter((activity: ActivityType) => lawyerCallActivityTypes.includes(activity.type));

  let standInsToday = [];
  let standInsTodayFullNameList = "";

  if (humanResources) {
    standInsToday = humanResources["hydra:member"][0].standIns
      .filter((StandIn: Absence) => moment(StandIn.absenceDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"))
      .map((filteredStandIn: Absence) => ({
        humanResource: filteredStandIn.absentHumanResource,
        backofficeUserFullName: filteredStandIn.absentUserFullName,
      }));

    standInsTodayFullNameList = standInsToday
      .map(
        (standIn: { humanResource: HumanResource; backofficeUserFullName: string }) => standIn.backofficeUserFullName
      )
      .join(", ");
  }

  const getGreeting = () => {
    const currentHour = Number(moment().format("HH"));
    if (currentHour >= 5 && currentHour < 11) {
      return "Guten Morgen";
    } else if (currentHour >= 11 && currentHour < 17) {
      return "Guten Tag";
    } else {
      return "Guten Abend";
    }
  };

  return (
    <PageContentContainer size={"large"}>
      <PageHeadline main={moment().format("dddd, Do MMMM YYYY")} />
      <Typography variant={"h3"} marginBottom={5}>
        {getGreeting() + ", " + currentUser.person.fullname}
      </Typography>
      <Grid container alignItems={"stretch"} spacing={4}>
        <Grid item xs={12} md={3}>
          <Link to={"/services/fristenkalender"}>
            <SmallWidget subject={"Fristen"} count={deadlineActivities.length} icon={<DateRangeOutlined />} />
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Link to={"/services/aktivitaeten"}>
            <SmallWidget subject={"Aktivitäten"} count={activities.length} icon={<ListOutlined />} />
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <SmallWidget subject={"Telefontermine"} count={callActivities.length} icon={<LocalPhoneOutlined />} />
        </Grid>
        <Grid item xs={12} md={3}>
          <HashLink to={"/services/mein-konto#absence"} title={standInsTodayFullNameList}>
            <SmallWidget subject={"Vertretungen"} count={standInsToday.length} icon={<PeopleAltOutlined />} />
          </HashLink>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper sx={{ padding: "1.5rem" }}>
            <Typography sx={{ marginBottom: "1.5rem" }} variant={"h5"}>
              Meine Fristen
            </Typography>
            <LawyerDashboardActivitiesTable activities={deadlineActivities.slice(0, 5)} />
            {deadlineActivities.length > 5 && (
              <Box sx={{ textAlign: "right", marginTop: "1rem" }}>
                <Link to={"/services/fristenkalender"}>Alle anzeigen</Link>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: "1.5rem" }}>
            <Typography sx={{ marginBottom: "1.5rem" }} variant={"h5"}>
              Meine Telefontermine
            </Typography>
            <AdvisoryCallList advisoryCalls={callActivities} noCallsText={"Keine offenen Beratungstermine"} />
          </Paper>
        </Grid>
      </Grid>
    </PageContentContainer>
  );
}
