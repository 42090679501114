import PageContentContainer from "../../../Container/PageContentContainer";
import CaseOverviewPageLoader from "../../../ContentLoader/CaseOverviewPageLoader";
import CaseNotFound from "../../../Case/CaseNotFound/CaseNotFound";
import CaseError from "../../../Case/CaseError/CaseError";
import { Stack } from "@mui/material";
import { useCase } from "../../../../provider/CaseProvider";
import CallcenterCaseHeader from "../../../Callcenter/Case/CallcenterCaseHeader";
import Contact from "../../../Callcenter/Case/Contact";
import Activities from "../../../Callcenter/Case/Activities";

const CallcenterCasePage = () => {
  const { product, error, isLoading, refreshCase } = useCase();

  if (isLoading) {
    return (
      <PageContentContainer>
        <CaseOverviewPageLoader />
      </PageContentContainer>
    );
  }

  if (error?.status === 404 || product?.customer?.isFake) {
    return <CaseNotFound />;
  }

  if (!!error) {
    return <CaseError errorMessage={error.error} errorStatus={error.status} />;
  }

  if (!product) {
    return null;
  }
  return (
    <>
      <PageContentContainer>
        <Stack spacing={3}>
          <CallcenterCaseHeader product={product} />
          <Contact product={product} refreshCase={refreshCase} />
          <Activities product={product} />
        </Stack>
      </PageContentContainer>
    </>
  );
};

export default CallcenterCasePage;
