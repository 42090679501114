
import ProductsDashboard from "../../ProductsDashboard/ProductsDashboard";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";

const ProductsPage = () => {
  return (
    <PageContentContainer size="small">
      <PageHeadline main="Alle Produkte" />
      <ProductsDashboard />
    </PageContentContainer>
  );
};

export default ProductsPage;
