import React from "react";
import { paidTaskTableStyle } from "./paidTaskTableStyle";
import {
  getCaseLinkByBackofficeCase,
  getProductData,
  getProductNameBy,
} from "../../../services/Product/ProductService";
import { Box, Button, TableCell, TableRow } from "@mui/material";
import { translate } from "../../../services/Translations/translatorService";
import { Link } from "react-router-dom";
import { convertFromFloat } from "../../../services/formServiceFunctions";
import moment from "moment";
import { PAID_TASK_STATUS_APPROVED, PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED } from "../paidTaskStatus";
import { PaidTask } from "../../../types/PaidTask";
import Typography from "@mui/material/Typography";

interface PaidTaskTableRowProps {
  paidTask: PaidTask;
}

const PaidTaskTableRow = ({ paidTask, ...props }: PaidTaskTableRowProps) => {
  const productClassName = getProductNameBy("lbProductClass", paidTask.backofficeCase.lbProductClass);

  return (
    <>
      <TableCell>
        <Typography sx={paidTaskTableStyle.link}>
          <Link to={getCaseLinkByBackofficeCase(paidTask.backofficeCase)}>
            {paidTask.backofficeCase.label || paidTask.backofficeCase.lbProductId}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>{getProductData(productClassName, "generalLabel")}</TableCell>
      <TableCell>{paidTask.subject}</TableCell>
      <TableCell>{convertFromFloat(paidTask.fee)} €</TableCell>
      <TableCell>{translate("paidTask.status." + paidTask.status)}</TableCell>
      <TableCell>{paidTask.requestedUser ? paidTask.requestedUser.person.fullname : "-"}</TableCell>
      <TableCell>{paidTask.assignee ? paidTask.assignee.person.fullname : "-"}</TableCell>
      <TableCell>{moment(paidTask.assignmentDeadline).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{moment(paidTask.doneDeadline).format("DD.MM.YYYY")}</TableCell>
      <TableCell>
        <Button
          component={Link}
          sx={paidTaskTableStyle.link}
          to={getCaseLinkByBackofficeCase(paidTask.backofficeCase) + "/ausschreibung/" + paidTask.id}
        >
          Details
        </Button>
      </TableCell>
    </>
  );
};
export default PaidTaskTableRow;
