import React from "react";
import { EditorState, Modifier } from "draft-js";
import { MenuItem, Select } from "@mui/material";
import _ from "lodash";
import * as PropTypes from "prop-types";

const AddPlaceholder = ({ editorState, onChange, placeholders, productClassName }) => {
  const addPlaceholder = (placeholder) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const handlePlaceholderClick = (path) => {
    addPlaceholder(`<%= translate(product, "${path.substr(0, path.indexOf(".label"))}") %>`);
  };

  return (
    <Select value={"static"} variant="standard" disableUnderline={true}>
      <MenuItem value={"static"} disabled>
        Wert einfügen
      </MenuItem>
      <MenuItem onClick={() => addPlaceholder("<%= salutation(product, formValues) %>")}>Grußform</MenuItem>
      <MenuItem onClick={() => addPlaceholder('<%= translate(product, "calendlyLinkRoundRobin") %>')}>
        Terminlink nach Rechtsprodukt
      </MenuItem>
      <MenuItem onClick={() => addPlaceholder('<%= translate(product, "calendlyLinkLawyer") %>')}>
        Terminlink nach Anwalt
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= wrapInLinkTag("https://legalbird.io" + getCaseLink(product, "/extern"), "Zum Fall") %>')
        }
      >
        Link externer Fall
      </MenuItem>
      <MenuItem
        onClick={() => addPlaceholder('<%= wrapInLinkTag(getServiceWorldCaseLink(product), "Zu meinem Fall") %>')}
      >
        Link "Zu meinem Fall"
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= wrapInLinkTag(getServiceWorldCaseLink(product, "upload"), "Dokumente hochladen") %>')
        }
      >
        Dokumente hochladen pro Fall
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= wrapInLinkTag(getServiceWorldCaseLink(product, "status"), "Angaben vervollständigen") %>')
        }
      >
        Angaben vervollständigen pro Fall
      </MenuItem>
      <MenuItem
        onClick={() =>
          addPlaceholder('<%= getWeRepresentAllClients(product) %>')
        }
      >
        Vertretung rechtlicher Interessen
      </MenuItem>
      <MenuItem
        onClick={() => addPlaceholder('<%= translate(product, "genderedResponsibleLawyer") %>')}
      >
        Rechtsanwalt/Rechtsanwältin [Name]
      </MenuItem>
      {/* TODO if this gets bigger, extract into extensionfile and access via productclassname*/}
      {productClassName === "traffic" && (
        <MenuItem onClick={() => addPlaceholder('<%= translate(product, "appealRevokeLink") %>')}>
          Link Einspruchsrücknahme
        </MenuItem>
      )}
      {productClassName === "contractLaw" && (
        <MenuItem onClick={() => addPlaceholder('<%= translate(product, "furtherProcessLink") %>')}>
          Link Rückmeldung weiteres Vorgehen
        </MenuItem>
      )}
      {productClassName === "contractLaw" && (
        <MenuItem onClick={() => addPlaceholder('In dem Rechtsstreit\n<%= translate(product, "rubrumContractLaw") %>')}>
          Rubrum - Gerichtliches Verfahren
        </MenuItem>
      )}
      {_.map(placeholders, (label, path) => (
        <MenuItem key={path} value={path} onClick={() => handlePlaceholderClick(path)}>
          {label} ({path.substr(0, path.indexOf(".label"))})
        </MenuItem>
      ))}
    </Select>
  );
};

AddPlaceholder.propTypes = {
  placeholders: PropTypes.object.isRequired,
  productClassName: PropTypes.string.isRequired,
};

export default AddPlaceholder;
