import React from "react";
import { TableCell } from "@mui/material";
import activityStyle from "./activityStyle";
import Typography from "@mui/material/Typography";


type SortableColumnCellProps = {
  sortBy: string;
  attribute: string;
  toggleSortBy: (attribute: string) => void;
  children: React.ReactNode;
};

const SortableColumnCell = ({ sortBy, attribute, toggleSortBy, children }: SortableColumnCellProps) => {

  return (
    <TableCell>
      <Typography
        sx={{ ...(sortBy === attribute ? activityStyle.activeSorting : {}), ...activityStyle.sortableHeadline }}
        onClick={() => toggleSortBy(attribute)}
      >
        {children}
      </Typography>
    </TableCell>
  );
};

export default SortableColumnCell;
