
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import ExternalApplications from "../../ExternalApplications/ExternalApplications";

export default function ExternalApplicationsPage() {
  return (
    <PageContentContainer>
      <PageHeadline main={"Bewerbungen Partneranwälte prüfen"} />
      <ExternalApplications />
    </PageContentContainer>
  );
}
