import React, { useState } from "react";
import { EditOutlined } from "@mui/icons-material";
import _ from "lodash";
import moment from "moment";
import { Box, Grid, Hidden, IconButton } from "@mui/material";
import { getActivityIcon } from "../../services/Activities/activityTypes";
import CircleIcon from "../CircleIcon/CircleIcon";
import EditActivity from "./EditActivity/EditActivity";
import ActivityNote from "./ActivityNote";
import CalendlyAssessmentActivity from "../CalendlyAssessments/CalendlyAssessmentActivity";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { Activity } from "../../types/Activity";
import { LogEntry } from "../../types/Activities/ActivityLog";
import doneActivityStyle from "./doneActivityStyle";

type DoneActivityProps = {
  activity: Activity<string>;
  productClassName: string;
  updateActivities: () => void;
};
const DoneActivity = ({ activity, productClassName, updateActivities }: DoneActivityProps) => {
  const { getUserData } = useBackofficeUser();
  const [editOpen, setEditOpen] = useState(false);
  const currentUser = useCurrentUser();

  const dateString = moment(activity.dueDate).format("DD.MM.YYYY");
  const timeString = activity.dueTime !== "allDay" ? "um " + activity.dueTime + " Uhr" : "";
  const assignedUser = getUserData(activity.assignedUser);
  const assignedUserName = assignedUser && assignedUser.person.fullname;
  const subject =
    activity.subject +
    " " +
    dateString +
    (timeString ? " " + timeString : "") +
    " | " +
    (assignedUserName || "Nicht zugewiesen");

  const DoneEntry = ({ activity }: { activity: Activity<string> }) => {
    let timedLog: ActivityLogWithDateTimes = _.merge({}, activity.activityLog);
    _.forEach(timedLog, (logEntry, key) => {
      logEntry.logDateTime = key;
    });
    const doneLogEntry = _.head(_.filter(timedLog, "done"));
    if (!doneLogEntry) {
      if (!!activity.doneTime) {
        return (
          <div>
            Erledigt: {moment(activity.doneTime).format("DD.MM.YYYY HH:mm")} Uhr | {assignedUserName || "System"}
          </div>
        );
      }
      return null;
    }

    return (
      <div>
        Erledigt: {moment(doneLogEntry.logDateTime).format("DD.MM.YYYY HH:mm")} Uhr | {doneLogEntry.updatedBy}
      </div>
    );
  };

  return (
    <Box sx={doneActivityStyle.outerActivityContainer}>
      <Grid container sx={doneActivityStyle.activityContainer}>
        <Hidden xsDown>
          <Grid sx={doneActivityStyle.imageGridItem} item>
            <CircleIcon icon={getActivityIcon(productClassName, activity.type)} type={"muiIcon"} />
          </Grid>
        </Hidden>
        <Grid item sx={doneActivityStyle.activityContent}>
          <Grid container alignItems={"center"} wrap={"nowrap"}>
            <Grid item sx={doneActivityStyle.headline}>
              {subject}
            </Grid>
            <Grid item sx={doneActivityStyle.activityActions}>
              {!currentUser.isExternal && (
                <IconButton onClick={() => setEditOpen(true)} size="large">
                  <EditOutlined />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <DoneEntry activity={activity} />
        </Grid>
      </Grid>
      <ActivityNote noteContent={activity.note} />
      <EditActivity
        key={activity.id}
        activity={activity}
        open={editOpen}
        closeDialog={() => setEditOpen(false)}
        updateActivities={updateActivities}
      />
      <CalendlyAssessmentActivity activity={activity} />
    </Box>
  );
};
type ActivityLogWithDateTimes = { [key: string]: LogEntry & { logDateTime?: string } };

export default DoneActivity;
