import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import scannedMediaObjectListStyle from "./scannedMediaObjectListStyle";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ContentBox from "../ContentBox/ContentBox";
import _ from "lodash";
import ScannedMediaObjectRow from "./ScannedMediaObjectRow";
import ButtonLoading from "../Button/ButtonLoading";
import ActionIdentifierProvider from "../../provider/ActionIdentifierProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { apiPost } from "../../services/Api/apiCall";
import DocumentClassLabelAiProvider from "../../provider/DocumentClassLabelAiProvider";

// @ts-ignore
const useStyles = makeStyles(scannedMediaObjectListStyle);

export default function ScannedMediaObjectList({}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  let mediaObjectsFilter = {
    scanCheck: "required",
  };
  const { data: mediaObjectsCollection } = useQuery(queryKeys.collection("media_objects", mediaObjectsFilter), () =>
    fetchCollection("media_objects", mediaObjectsFilter)
  );

  const mediaObjects = mediaObjectsCollection ? mediaObjectsCollection["hydra:member"] : [];

  if (!mediaObjectsCollection) {
    return <LegalbirdLoader centered />;
  }

  const handleConfirmClick = async () => {
    setIsLoading(true);
    let checkedMediaObjects: number[] = [];
    _.forEach(mediaObjects, (mediaObject) => {
      if (mediaObject.scanMetaData?.checkedDate) {
        checkedMediaObjects.push(mediaObject.id);
      }
    });
    await apiPost("lb/services/checked_media_objects", checkedMediaObjects);
    await queryClient.invalidateQueries(queryKeys.collection("media_objects", mediaObjectsFilter));
    setIsLoading(false);
  };

  return (
    <ContentBox>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Datei</TableCell>
            <TableCell>Fall</TableCell>
            <TableCell className={classes.searchColumn} width={"25%"}>
              Suche
            </TableCell>
            <TableCell className={classes.identifierColumn}>Dokumentenart</TableCell>
            <TableCell className={classes.identifierColumn}>Aktion</TableCell>
            <TableCell>Prüfen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DocumentClassLabelAiProvider>
            <ActionIdentifierProvider>
              {mediaObjects.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={7} className={classes.centerText}>
                    Keine zuzuordnenden Dateien
                  </TableCell>
                </TableRow>
              ) : (
                _.map(mediaObjects, (mediaObject) => (
                  <ScannedMediaObjectRow
                    mediaObject={mediaObject}
                    key={mediaObject.id}
                    refreshList={() =>
                      queryClient.invalidateQueries(queryKeys.collection("media_objects", mediaObjectsFilter))
                    }
                  />
                ))
              )}
            </ActionIdentifierProvider>
          </DocumentClassLabelAiProvider>
        </TableBody>
      </Table>
      <ButtonLoading
        variant={"contained"}
        className={classes.button}
        onClick={handleConfirmClick}
        isLoading={isLoading}
        disabled={!_.find(mediaObjects, (mediaObject) => mediaObject.scanMetaData?.checkedDate)}
      >
        Prüfung bestätigen
      </ButtonLoading>
    </ContentBox>
  );
}
