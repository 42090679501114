export const badgeStyles = {
  badge: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    textAlign: "center",
    padding: "5px 0 5px 0",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  small: {
    minWidth: "60px",
    fontSize: "12px",
  },
  large: {
    paddingLeft: "5px",
    paddingRight: "5px",
    minWidth: { xs: "106px", sm: "120px" },
    fontSize: "16px",
  },
  activeBadge: {
    borderColor: "border.active",
    color: "text.primary",
  },
  inactiveBadge: {
    borderColor: "border.inActive",
    color: "text.light",
  },
  link: {
    color: "text.light",
    textDecoration: "none",
  },
};
