import { Theme } from "@mui/material";

export const popUpMenuStyles = {
  iconButton: (theme: Theme) => ({
    [theme.breakpoints.down("lg")]: {
      padding: 0,
    },
  }),
};

