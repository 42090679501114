import { userHasRole } from "./backofficeUserService";
import { BackofficeUser, Roles } from "../types/BackofficeUser";

export const getSubFolderByUser = (user : BackofficeUser) => {
  switch (true) {
    case userHasRole(user, Roles.external):
      return "/extern";
    case userHasRole(user, Roles.callcenter):
      return "/callcenter";
    default:
      return "";
  }
};


export const accessGranted = (locationSearch: string) => {
  const query = new URLSearchParams(locationSearch);
  if (query.has("testKey")) {
    localStorage.setItem("testKey", query.get("testKey") || "emptyKey");
  }
  return localStorage.getItem("testKey") === "blub";
};
