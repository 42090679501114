const rentalContractTransformations = {
  dateFields: [
    { path: "buyerPaidDate", type: "date" },
    { path: "claimedDateClient", type: "date" },
    { path: "claimedDeadlineClient", type: "date" },
    { path: "lawsuitCompletedDate", type: "date" },
    { path: "contractDate", type: "date" },
    { path: "courtDate.appointmentDate", type: "date" },
    { path: "customerLawsuitCompletedApprovalDate", type: "date" },
    { path: "defectsRecognitionDate", type: "date" },
    { path: "endOfContractDate", type: "date" },
    { path: "endOfContractDateOpponent", type: "date" },
    { path: "extrajudicialCommunicationDeadline", type: "date" },
    { path: "extrajudicialCommunicationSent", type: "date" },
    { path: "extrajudicialReactionReceived", type: "date" },
    { path: "paymentOrderDate", type: "date" },
    { path: "enforcementOrderDate", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "replicaFiledDate", type: "date" },
    { path: "replicaSentDate", type: "date" },
    { path: "statementOfClaimFiledDate", type: "date" },
    { path: "statementOfClaimSentDate", type: "date" },
    { path: "statementOfDefenseFiledDate", type: "date" },
    { path: "statementOfDefenseSentDate", type: "date" },
    { path: "timeBarredDate", type: "date" },
    { path: "client.birthDate", type: "date" },
    { path: "insurance.extrajudicialCoverageRequestAnsweredDate", type: "date" },
    { path: "insurance.judicialCoverageRequestAnsweredDate", type: "date" },
    { path: "principalClaimDefaultDate", type: "date" },
    { path: "accessoryClaimDefaultDate", type: "date" },
    { path: "cancellationReceivedDate", type: "date" },
    { path: "rentalContractStartDate", type: "date" },
    { path: "rentalContractEndDate", type: "date" },
  ],
  numberFields: ["leadStatus", "applicationStatus", "responsibleCourt.advoAssistId"],
  timeFields: ["courtDate.appointmentTime"],
  floatFields: [
    "claimedAmountClient",
    "overdueFees",
    "valueInDisputePreliminary",
    "valueInDispute",
    "netRvgAmount",
    "costBearingByOpponentFinalAbsolute",
    "courtCostsFinal",
    "claimedAmountLawFirm",
    "costReal",
    "costEstimate",
    "costBearingByOpponentRelative",
    "principalClaim",
    "accessoryClaim",
    "insurance.deductible",
    "previousBasicRent",
    "previousRentIncludingHeating",
    "futureBasicRent",
    "futureRentIncludingHeating",
    "depositAmount",
  ],
  ibanFields: ["clientBankData.iban"],
};
export default rentalContractTransformations;
