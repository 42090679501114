import PageHeadline from "../../PageHeadline/PageHeadline";
import PageContentContainer from "../../Container/PageContentContainer";
import BeaAttachmentFilter from "../../BeaAttachmentFilter/BeaAttachmentFilter";

export default function BeaAttachmentFilterPage() {
  return (
    <PageContentContainer size={"tiny"}>
      <PageHeadline main="beA-Anhangsfilter" />
      <BeaAttachmentFilter />
    </PageContentContainer>
  );
}
