import React from "react";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import StandInPicker from "./StandInPicker";
import { SelectChangeEvent, Typography } from "@mui/material";
import { DateObject } from "react-multi-date-picker";
import HumanResource from "../../types/HumanResource";

type AbsenceRowProps = {
  absenceDateString: string;
  handleChange: Function;
  allAbsences: Array<any>;
  absentUserId: number;
  humanResources: Array<HumanResource>;
};

export default function AbsenceRow({
  absenceDateString,
  handleChange,
  allAbsences,
  absentUserId,
  humanResources,
}: AbsenceRowProps) {
  const handleStandInChange = (event: SelectChangeEvent) => {
    //needed because for some reason u cant clone the calendars date objects
    const convertedDateAbsences = allAbsences.map((absence) => {
      return { ...absence, absenceDate: absence.absenceDate.format("YYYY-MM-DD") };
    });

    let newAbsences = structuredClone(convertedDateAbsences);
    const index = newAbsences.findIndex((absence) => absence.absenceDate === absenceDateString);
    newAbsences[index] = { ...newAbsences[index], [event.target.name]: event.target.value };

    //revert previous conversion
    newAbsences = newAbsences.map((absence) => {
      return { ...absence, absenceDate: new DateObject(absence.absenceDate) };
    });

    handleChange({
      target: {
        name: "absences",
        value: newAbsences,
      },
    });
  };

  const correspondingAbsence = allAbsences.find(
    (absence) => absence.absenceDate.format("YYYY-MM-DD") === absenceDateString
  );

  return (
    <>
      <Grid item xs={3}>
        <Typography variant={"h4"} component={"p"}>
          {moment(absenceDateString).format("dddd, DD.MM.YYYY")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <StandInPicker
          label={"Vertretung 1"}
          allAbsences={allAbsences}
          blockedUsers={[correspondingAbsence["standIn2"], correspondingAbsence["standIn3"]]}
          correspondingDate={absenceDateString}
          handleStandInChange={handleStandInChange}
          name={"standIn1"}
          humanResources={humanResources}
          absentUserId={absentUserId}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <StandInPicker
          label={"Vertretung 2"}
          allAbsences={allAbsences}
          blockedUsers={[correspondingAbsence["standIn1"], correspondingAbsence["standIn3"]]}
          correspondingDate={absenceDateString}
          handleStandInChange={handleStandInChange}
          name={"standIn2"}
          humanResources={humanResources}
          absentUserId={absentUserId}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <StandInPicker
          label={"Vertretung 3 (optional)"}
          allAbsences={allAbsences}
          blockedUsers={[correspondingAbsence["standIn1"], correspondingAbsence["standIn2"]]}
          correspondingDate={absenceDateString}
          handleStandInChange={handleStandInChange}
          humanResources={humanResources}
          absentUserId={absentUserId}
          name={"standIn3"}
        />
      </Grid>
    </>
  );
}
