import { caseAccountingPageStyle } from "./caseAccountingPageStyle";
import PageContentContainer from "../../Container/PageContentContainer";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import BookingEntry from "../../Accounting/BookingEntry";
import NewBookingEntry from "../../Accounting/NewBookingEntry";
import { getBookingEntriesWithDifferenceAndSummedValues } from "../../../services/accountingService";
import LegalBirdPageLoading from "../../ContentLoader/LegalBirdPageLoading";
import { ProductHeadline } from "../../PageHeadline/PageHeadline";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import { useCase } from "../../../provider/CaseProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import NavigationExtensionCaseAccountingPage from "../../NavigationBar/NavigationExtensionCaseAccountingPage";
import { scrollToAnchor } from "../../../theme/commonStyles";
import { useState } from "react";
import { BookingEntry as BookingEntryInterface } from "../../../types/BookingEntry";
import CaseInvoiceGenerateForm from "../../CaseFileGenerate/CaseInvoiceGenerateForm";
import AddWiretransfer from "../../Wiretransfer/AddWiretransfer";
import { useSnackbar } from "notistack";
import InvoiceTypeIdentifiersProvider from "../../../provider/InvoiceTypeIdentifiersProvider";

const CaseAccountingPage = () => {
  const { product } = useCase();
  const { backofficeUsers } = useBackofficeUser();
  const [organizationId, setOrganizationId] = useState<string | number>("all");
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  let bookingFilter = {
    productId: product?.id,
    deleted: false,
  };

  const { data: bookingEntries } = useQuery(
    queryKeys.collection("booking_entries", bookingFilter),
    () => fetchCollection("booking_entries", bookingFilter),
    {
      enabled: !!product,
    }
  );

  if (!product || !bookingEntries) {
    return <LegalBirdPageLoading />;
  }

  const filteredBookingEntries = bookingEntries["hydra:member"].filter(
    (bookingEntry: BookingEntryInterface) =>
      organizationId === "all" || bookingEntry.organization?.id === organizationId
  );

  const { bookingEntriesWithDifference, summedValues } = getBookingEntriesWithDifferenceAndSummedValues(
    _.sortBy(filteredBookingEntries, (bookingEntry) => bookingEntry.bookingDate)
  );

  const connectedReceiptIds = _.map(bookingEntries, (bookingEntry) =>
    bookingEntry.receipt ? bookingEntry.receipt.id : null
  );

  const wiretransferPostSubmit = () => {
    enqueueSnackbar("Überweisung erfolgreich angelegt", {
      variant: "custom",
      buttonType: "link",
      buttonLink: "/services/tools/ueberweisung",
      buttonTarget: "_self",
      buttonText: "Zur Überweisung",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  return (
    <InvoiceTypeIdentifiersProvider>
      <NavigationExtensionCaseAccountingPage product={product} />
      <PageContentContainer>
        <ProductHeadline title="Konto von" product={product} />
        <Box sx={scrollToAnchor} id={"account"} />
        <Stack
          direction={"row"}
          sx={caseAccountingPageStyle.addPaymentRow}
          alignItems={"center"}
          justifyContent={"end"}
          spacing={2}
        >
          <FormControl>
            <InputLabel>Organisation</InputLabel>
            <Select
              label="Organisation"
              value={organizationId}
              onChange={(event) => setOrganizationId(event.target.value)}
              sx={{ minWidth: 200 }}
            >
              <MenuItem value={"all"}>Alle Gesellschaften</MenuItem>
              <MenuItem value={1}>LBRA</MenuItem>
              <MenuItem value={3}>R&S</MenuItem>
            </Select>
          </FormControl>
          <NewBookingEntry
            bookingEntries={bookingEntriesWithDifference}
            product={product}
            updateBookingEntries={() => queryClient.invalidateQueries(queryKeys.collections("booking_entries"))}
          />
        </Stack>
        <TableContainer component={Paper} sx={caseAccountingPageStyle.tablePaper}>
          <Table style={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell>Typ</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Belegnummer</TableCell>
                <TableCell>Fällig am</TableCell>
                <TableCell>Notiz</TableCell>
                <TableCell>Angelegt von</TableCell>
                <TableCell align={"right"}>Soll</TableCell>
                <TableCell align={"right"}>Haben</TableCell>
                <TableCell align={"right"}>Differenz</TableCell>
                <TableCell align={"right"}>Account</TableCell>
                <TableCell />
                <TableCell width={"10%"} />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(bookingEntriesWithDifference, (bookingEntry, key) => {
                return (
                  <BookingEntry
                    key={key}
                    bookingEntry={bookingEntry}
                    updateBookingEntries={() => queryClient.invalidateQueries(queryKeys.collections("booking_entries"))}
                    product={product}
                    connectedReceiptIds={connectedReceiptIds}
                    backofficeUsers={backofficeUsers}
                  />
                );
              })}
              <TableRow selected>
                <TableCell sx={caseAccountingPageStyle.summaryText}>Summe</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell sx={caseAccountingPageStyle.summaryText} align={"right"}>
                  {summedValues.debit.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell sx={caseAccountingPageStyle.summaryText} align={"right"}>
                  {summedValues.credit.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </TableCell>
                <TableCell sx={caseAccountingPageStyle.summaryText} align={"right"}>
                  {Math.abs(summedValues.difference).toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                  {summedValues.difference < 0 ? "S" : "H"}
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell sx={caseAccountingPageStyle.summaryText} />
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ marginTop: "4rem", textAlign: "center" }}>
          <Box sx={scrollToAnchor} id={"addWiretransfer"} />
          <Typography variant="h2">Überweisung / Auskehrungen</Typography>
        </Box>
        <AddWiretransfer postSubmitCallback={wiretransferPostSubmit} currentCase={product} />
        <Box sx={scrollToAnchor} id={"addInvoice"} />
        <CaseInvoiceGenerateForm product={product} />
      </PageContentContainer>
    </InvoiceTypeIdentifiersProvider>
  );
};

export default CaseAccountingPage;
