import { translate } from "../../Translations/translatorService";
import { AbstractCase } from "../../../types/AbstractCase";
import { validateFields } from "../statusChangeValidators";
import * as stagesTraffic from "../../Stages/StagesTraffic";
import { trafficRequiredFields } from "./trafficRequiredFields";

export const trafficValidators = {
  [stagesTraffic.STAGE_INCOMPLETE]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_INCOMPLETE](traffic), traffic),
  [stagesTraffic.STAGE_READY]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_READY](traffic), traffic),
  [stagesTraffic.STAGE_CHECKED]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_CHECKED](traffic), traffic),
  [stagesTraffic.STAGE_AUTHORIZED]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_AUTHORIZED](traffic), traffic),
  [stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_INSURANCE_COVERAGE_REQUEST](traffic), traffic),
  [stagesTraffic.STAGE_ACCESS_TO_RECORDS]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_ACCESS_TO_RECORDS](traffic), traffic),
  [stagesTraffic.STAGE_EXTRAJUDICIAL_PAID]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_EXTRAJUDICIAL_PAID](traffic), traffic),
  [stagesTraffic.STAGE_OPINION_SUBMITTED]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_OPINION_SUBMITTED](traffic), traffic),
  [stagesTraffic.STAGE_AUTHORITY_ANSWERED]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_AUTHORITY_ANSWERED](traffic), traffic),
  [stagesTraffic.STAGE_TRIAL]: (traffic: AbstractCase) => {
    let errorMessages = validateFields(trafficRequiredFields[stagesTraffic.STAGE_TRIAL](traffic), traffic);
    if (traffic.appealRevoked !== null) {
      errorMessages.push(`“${translate("traffic.appealRevoked.label")}” darf nicht ausgefüllt sein.`);
    }
    if (traffic.trialWanted !== null && traffic.trialWanted !== "yes") {
      errorMessages.push(
        `“${translate("traffic.trialWanted.label")}” ist nicht “${translate("traffic.trialWanted.values.yes")}”.`
      );
    }
    if (traffic.authorityDecision !== "court") {
      errorMessages.push(
        `“${translate("traffic.authorityDecision.label")}” ist nicht “${translate(
          "traffic.authorityDecision.values.court"
        )}”.`
      );
    }
    return errorMessages;
  },
  [stagesTraffic.STAGE_TRIAL_DATE_SET]: (traffic: AbstractCase) => {
    let errorMessages = validateFields(trafficRequiredFields[stagesTraffic.STAGE_TRIAL_DATE_SET](traffic), traffic);
    if (traffic.courtNotice !== "trial") {
      errorMessages.push(
        `“${translate("traffic.courtNotice.label")}” ist nicht “${translate("traffic.courtNotice.values.trial")}”.`
      );
    }
    return errorMessages;
  },
  [stagesTraffic.STAGE_TRIAL_PAID]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_TRIAL_PAID](traffic), traffic),
  [stagesTraffic.STAGE_DECISION_MADE]: (traffic: AbstractCase) => {
    if (traffic.processStoppedDate) {
      return [];
    }

    let errorMessages = validateFields(trafficRequiredFields[stagesTraffic.STAGE_DECISION_MADE](traffic), traffic);

    if (
      traffic.appealRevoked === null &&
      !traffic.courtDecisionDate &&
      !["revoked", "changed"].includes(traffic.authorityDecision) &&
      !["revoked", "no_trial"].includes(traffic.courtNotice)
    ) {
      errorMessages.push(
        `Eine der folgenden Optionen muss erfüllt sein: "${translate(
          "traffic.appealRevoked.label"
        )}" ist befüllt, "${translate("traffic.courtDecisionDate.label")}" ist befüllt, "${translate(
          "traffic.authorityDecision.label"
        )}" ist "${translate("traffic.authorityDecision.values.revoked")}" oder "${translate(
          "traffic.courtNotice.label"
        )}" ist "${translate("traffic.courtNotice.values.revoked")}" oder "${translate(
          "traffic.courtNotice.values.no_trial"
        )}"`
      );
    }
    return errorMessages;
  },
  [stagesTraffic.STAGE_COMPLETED]: (traffic: AbstractCase) =>
    validateFields(trafficRequiredFields[stagesTraffic.STAGE_COMPLETED](traffic), traffic),
};
