import { Stack, Typography } from "@mui/material";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { apiPut } from "../../../services/Api/apiCall";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { useSnackbar } from "notistack";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";

type DeleteBeADataProps = {};

export default function DeleteBeAData({}: DeleteBeADataProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = useCurrentUser();

  async function deleteBeAData() {
    setIsDeleting(true);
    try {
      await apiPut(`/backoffice_people`, currentUser.person.id, {
        beaPin: null,
        encodedBeaCertificate: null,
      });
      enqueueSnackbar("Hinterlegte beA-Daten wurden gelöscht", {
        variant:"custom",
        isNonInteractive  : true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      setModalOpen(false);
    } catch (e) {
      enqueueSnackbar("Hinterlegte beA-Daten konnten nicht gelöscht werden", {

        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <Stack alignItems={"center"} spacing={3} sx={{ marginTop: "4rem" }}>
        <ButtonLoading variant={"contained"} onClick={() => setModalOpen(true)} sx={{ width: 350 }}>
          Hinterlegte beA-Daten löschen
        </ButtonLoading>
      </Stack>
      <LegalbirdIoModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        title={"Hinterlegte beA-Daten löschen"}
        submitButton={
          <ButtonLoading variant={"contained"} onClick={deleteBeAData} isLoading={isDeleting} sx={{ width: 350 }}>
            Hinterlegte beA-Daten löschen
          </ButtonLoading>
        }
      >
        <Typography textAlign={"center"}>
          Mit Klick auf “Hinterlegte beA-Daten löschen” werden das hinterlegte Zertifikat und die PIN gelöscht.
        </Typography>
      </LegalbirdIoModal>
    </>
  );
}
