import * as stagesBasicCase from "../Stages/StagesBasicCase";
import * as stagesCase from "../Stages/StagesCase";
import _ from "lodash";
import { translate } from "../Translations/translatorService";
import { getNextStage } from "../Product/ProductService";
import { trafficValidators } from "./Traffic/trafficValidators";
import { AbstractCase } from "../../types/AbstractCase";
import { divorceValidators } from "./Divorce/divorceValidators";
import { contractValidators } from "./Contract/contractValidators";
import { rentalContractValidators } from "./RentalContract/rentalContractValidators";
import { settlementValidators } from "./Settlement/settlementValidators";
import { dataLeakContractValidators } from "./DataLeakContract/dataleakContractValidators";
import { dataLeakRequiredFields } from "./DataLeakContract/dataLeakContractRequiredFields";
import { rentalContractRequiredFields } from "./RentalContract/rentalContractRequiredFields";
import { contractRequiredFields } from "./Contract/contractRequiredFields";
import { divorceRequiredFields } from "./Divorce/divorceRequiredFields";
import { trafficRequiredFields } from "./Traffic/trafficRequiredFields";

const basicCaseValidators = {
  [stagesBasicCase.STAGE_CREATED]: () => [],
  [stagesBasicCase.STAGE_DATA_COMPLETED]: () => [],
  [stagesCase.STAGE_IN_PROGRESS]: () => [],
  [stagesBasicCase.STAGE_COMPLETED]: () => [],
};

const statusChangeRequiredFields = {
  divorce: divorceRequiredFields,
  employmentContract: contractRequiredFields,
  eventContract: contractRequiredFields,
  familyLawCase: basicCaseValidators,
  membershipContract: contractRequiredFields,
  otherContract: contractRequiredFields,
  providerContract: contractRequiredFields,
  dataLeakContract: dataLeakRequiredFields,
  purchaseContract: contractRequiredFields,
  rentalContract: rentalContractRequiredFields,
  serviceContract: contractRequiredFields,
  settlement: settlementValidators,
  subscriptionContract: contractRequiredFields,
  traffic: trafficRequiredFields,
  travelContract: contractRequiredFields,
  vehicleContract: contractRequiredFields,
};

const statusChangeValidators = {
  divorce: divorceValidators,
  employmentContract: contractValidators,
  eventContract: contractValidators,
  familyLawCase: basicCaseValidators,
  membershipContract: contractValidators,
  otherContract: contractValidators,
  providerContract: contractValidators,
  dataLeakContract: dataLeakContractValidators,
  purchaseContract: contractValidators,
  rentalContract: rentalContractValidators,
  serviceContract: contractValidators,
  settlement: settlementValidators,
  subscriptionContract: contractValidators,
  traffic: trafficValidators,
  travelContract: contractValidators,
  vehicleContract: contractValidators,
};

export const getRequiredFields = (product: AbstractCase) => {
  const productClassName = product.productClassName;
  const nextStage = getNextStage(product);
  if (!productClassName || !nextStage) {
    return [];
  }
  // @ts-ignore
  const getRequiredFields = statusChangeRequiredFields[productClassName][nextStage.stage];
  if (!getRequiredFields) {
    return [];
  }
  return getRequiredFields(product, true);
};

export const validateFields = (requiredFields: string[], productEntity: AbstractCase) => {
  let errorMessages: string[] = [];

  _.forEach(requiredFields, (requiredField) => {
    if (
      _.get(productEntity, requiredField) === undefined ||
      _.get(productEntity, requiredField) === null ||
      _.get(productEntity, requiredField) === ""
    ) {
      errorMessages.push(
        `Das Feld "${translate(productEntity.productClassName + "." + requiredField + ".label")}" muss ausgefüllt sein!`
      );
    }
  });
  return errorMessages;
};

export const validateStatusUpdate = (product: AbstractCase, stage: number) => {
  // @ts-ignore
  return statusChangeValidators[product.productClassName][stage](product);
};

export default statusChangeValidators;
