import ActivityNote from "../../../Activities/ActivityNote";
import React from "react";
import paidTaskHistoryLogStyle from "./paidTaskHistoryLogStyle";
import moment from "moment";
import _get from "lodash/get";
import * as PropTypes from "prop-types";
import PaidTaskCaseHead from "../../../PaidTask/PaidTasksCase/PaidTaskCaseHead";
import { Box } from "@mui/material";
import { PaidTask } from "../../../../types/PaidTask";

type PaidTaskHistoryLogProps = {
  paidTask: PaidTask;
};

const PaidTaskHistoryLog = ({ paidTask }: PaidTaskHistoryLogProps) => {
  return (
    <Box sx={paidTask.deletedDate ? paidTaskHistoryLogStyle.outerPaidTaskContainer : null}>
      <PaidTaskCaseHead paidTask={paidTask} />
      <Box sx={paidTaskHistoryLogStyle.assignmentInfo}>
        {paidTask.markedAsDoneDate && (
          <>
            Erledigt {moment(paidTask.markedAsDoneDate).format("DD.MM.YYYY")} |{" "}
            {_get(paidTask, "assignee.person.fullname")}
            <br />
          </>
        )}
        {paidTask.approvedDate && (
          <>
            Abgenommen {moment(paidTask.approvedDate).format("DD.MM.YYYY")} |{" "}
            {_get(paidTask, "approvedBy.person.fullname")}
          </>
        )}
      </Box>
      <ActivityNote noteContent={paidTask.description} />
      {paidTask.deletedDate && (
        <>
          <Box sx={paidTaskHistoryLogStyle.overlay} />
          <Box sx={paidTaskHistoryLogStyle.deletedInfo}>
            Gelöscht am {moment(paidTask.deletedDate).format("DD.MM.YYYY")}
          </Box>
        </>
      )}
    </Box>
  );
};

PaidTaskHistoryLog.propTypes = {
  paidTask: PropTypes.object,
};

export default PaidTaskHistoryLog;
