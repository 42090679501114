import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { Activity } from "../../types/Activity";
import { Box } from "@mui/material";
import activityNoteStyle from "./activityNoteStyle";

type ActivityNoteProps = {
  noteContent?: Activity["note"];
  isFolded?: boolean;
  noteContainerStyles?: Record<string, any>;
};

const ActivityNote = ({ noteContent, isFolded = true, noteContainerStyles = {} }: ActivityNoteProps) => {
  const [noteFolded, setNoteFolded] = useState(isFolded);

  if (!noteContent) {
    return null;
  }

  //used to check for multi line content
  const pTagCount = (noteContent.match(/<p>/g) || []).length;

  return (
    <Box sx={{ ...activityNoteStyle.noteContainer, ...noteContainerStyles }}>
      <Grid container wrap={"nowrap"} justifyContent={"space-between"}>
        <Grid item>
          <Box
            sx={{ ...activityNoteStyle.note, ...(noteFolded ? activityNoteStyle.collapsedNote : null) }}
            dangerouslySetInnerHTML={{ __html: noteContent }}
          />
        </Grid>
        {(noteContent.length > 120 ||
          pTagCount > 1 ||
          noteContent.includes("<br>") ||
          noteContent.includes("<br/>")) && (
          <Grid item>
            <IconButton onClick={() => setNoteFolded(!noteFolded)} sx={activityNoteStyle.icon} size="large">
              {noteFolded ? <UnfoldMore /> : <UnfoldLess />}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ActivityNote;
