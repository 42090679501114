import customerTranslations from "./customerTranslations";
import basicCaseTranslations from "./basicCaseTranslations";
import contractClaimTranslations from "./contractClaimTranslations";
import mobileProviders from "../mobileProviders";

function getMobileProviderTranslations() {
  const mobileProviderTranslations = {};

  mobileProviders.forEach((provider) => {
    mobileProviderTranslations[provider.value] = provider.label;
  });

  return mobileProviderTranslations;
}

const providerContractTranslations = {
  ...basicCaseTranslations,
  ...contractClaimTranslations,
  label: "Datenweitergabe",
  mobileProviderOrganizationId: {
    label: "Ausgewählter Mobilfunkanbieter",
    values: getMobileProviderTranslations(),
  },
  salesChannel: {
    label: "Wo Vertrag abgeschlossen?",
    values: {
      providerWebsite: "Website des Anbieters",
      comparisonPortal: "Vergleichsportal",
      localStore: "Vor Ort im Laden",
      phone: "Per Telefon",
      other: "Auf anderem Wege",
    },
  },
  contractNumber: {
    label: "Kundennummer",
  },
  crifDataCopyDate: {
    label: "Crif-Auskunft erhalten am",
  },
  crifTipNumber: {
    label: "TIP-Nummer",
  },
  crifPositiveDataEntry: {
    label: "CRIF-Auszug Positiveintrag",
  },
  crifPositiveDataDate: {
    label: "Eintragsdatum CRIF ab 25.05.2018",
  },
  crifDataCopyCheckedNegativeDate: {
    label: "Datenauskunft negativ geprüft am",
  },
  schufaDataCopyDate: {
    label: "Schufa-Auskunft erhalten am",
  },
  schufaPositiveDataEntry: {
    label: "Schufa-Auszug Positiveintrag",
  },
  schufaPositiveDataDate: {
    label: "Eintragsdatum Schufa ab 25.05.2018",
  },
  schufaDataCopyCheckedNegativeDate: {
    label: "Datenauskunft negativ geprüft am",
  },
  schufaPositiveDataServiceAccountNumber: {
    label: "Servicekontonummer Schufa",
  },
  customer: customerTranslations,
  compensationAmount: {
    label: "Streitwert Schadensersatz",
  },
  declaratoryMotionAmount: {
    label: "Streitwert Feststellung",
  },
  injunctiveReliefAmount: {
    label: "Streitwert Unterlasser",
  },
  personalAffection: {
    personalAffectionClient: {
      label: "Ausführung des Mandanten",
    },
    personalAffectionLawFirm: {
      label: "Ausführung der Kanzlei",
    },
  },
};

export default providerContractTranslations;
