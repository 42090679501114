import React from "react";
import _ from "lodash";
import { getRoleToDisplay, sortByRole } from "../../../../services/backofficeUserService";
import LegalbirdAutoComplete from "../../../AutoComplete/LegalbirdAutoComplete";
import { BackofficeUser } from "../../../../types/BackofficeUser";
import { AutoCompleteOptionWithId } from "../../../../types/AutoCompleteOptionWithId";

const getOptions = (
  agents: BackofficeUser[],
  additionalOptions: Array<Omit<AutoCompleteOptionWithId, "value">> = []
) => {
  const users = _.map(sortByRole(agents), (agent) => {
    return {
      label: agent.person.fullname,
      id: agent["@id"],
      groupBy: getRoleToDisplay(agent),
    };
  });

  return [{ label: "Alle Benutzer", id: "all" }, ...additionalOptions, ...users];
};

type UserFilterProps = {
  userFilter: string;
  setUserFilter: Function;
  agents: BackofficeUser[];
  isDeadlineView: boolean;
};

const UserFilter = ({ userFilter, setUserFilter, agents, isDeadlineView }: UserFilterProps) => {
  if (_.isEmpty(agents)) {
    return null;
  }

  const options = getOptions(
    agents,
    isDeadlineView
      ? []
      : [
          {
            label: "Alle Kundenberater",
            id: "accountManager",
          },
          { label: "Alle Anwälte", id: "lawyer" },
        ]
  );

  const value = _.find(options, (option) => option.id === userFilter);

  return (
    <LegalbirdAutoComplete<AutoCompleteOptionWithId>
      label="Nutzer"
      value={value}
      options={options}
      groupBy={(option) => option.groupBy || ""}
      setValue={(event, value: AutoCompleteOptionWithId | null) => {
        if (!value) {
          return;
        }
        setUserFilter(value.id);
      }}
    />
  );
};

export default UserFilter;
