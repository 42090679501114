import { AbstractCase } from "../../../types/AbstractCase";
import { translate } from "../../Translations/translatorService";
import { STAGE_DATA_COMPLETED } from "../../Stages/StagesCase";

export const getRentalContractBadges = (product: AbstractCase) => {
  let badges = [];

  if (product.productClassName !== "rentalContract") {
    return [];
  }

  if (product.stage && product.stage >= STAGE_DATA_COMPLETED && product.caseCategory !== "advisory") {
    badges.push({
      text: translate("rentalContract.reasonForDispute.values." + product.reasonForDispute),
      isActive: true,
    });
  }

  if (product.stage && product.stage >= STAGE_DATA_COMPLETED) {
    badges.push({
      text: translate(product.productClassName + ".caseCategory.values." + product.caseCategory, "Noch nicht gesetzt"),
      isActive: true,
    });
  }

  return badges;
};
