import { Box, Button, Paper, Stack } from "@mui/material";
import caseInquiryToCustomerStyle from "./caseInquiryToCustomerStyle";
import { QuestionAnswerOutlined } from "@mui/icons-material";
import { buildInquiryShortText } from "../../../hooks/useInquiry";
import React, { useState } from "react";
import ShowInquiryToCustomerModal from "../../Request/ShowInquiryToCustomerModal";
import { InquiryToCustomer } from "../../../types/InquiryToCustomer";

type CaseInquiryToCustomerProps = { inquiryToCustomer: InquiryToCustomer };

export default function CaseInquiryToCustomer({ inquiryToCustomer }: CaseInquiryToCustomerProps) {
  const [open, setOpen] = useState(false);

  const statusClass: any = caseInquiryToCustomerStyle[inquiryToCustomer.statusText || "open"];

  return (
    <Paper sx={statusClass}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <QuestionAnswerOutlined />
        <Box flexGrow={1} paddingLeft={"1rem"}>
          {buildInquiryShortText(inquiryToCustomer)}
        </Box>
        <Box>
          <Button onClick={() => setOpen(true)}>Zur Anfrage</Button>
        </Box>
      </Stack>
      <ShowInquiryToCustomerModal
        open={open}
        handleClose={() => setOpen(false)}
        inquiryToCustomer={inquiryToCustomer}
      />
    </Paper>
  );
}
