import React, { useState } from "react";
import { MediaObject } from "../../types/MediaObject";
import { Box, Stack } from "@mui/material";
import MimeTypeIcon from "./MimeTypeIcon";
import DisplayMediaObjects from "./DisplayMediaObjects";
import DisplayOrDownloadMediaObjectWrapper from "../ElectronicFileFolder/DisplayOrDownloadMediaObjectWrapper";

type DisplayableMediaObjectProps = {
  mediaObjects: MediaObject[];
  loadDisplayUrl?: boolean;
};
export default function DisplayableMediaObjects({ mediaObjects, loadDisplayUrl = false }: DisplayableMediaObjectProps) {
  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState<MediaObject | null>(null);
  return (
    <>
      {mediaObjects.map((mediaObject, index) => (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ cursor: "pointer" }} key={index}>
          <DisplayOrDownloadMediaObjectWrapper
            mediaObject={mediaObjects[index]}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
            loadDisplayUrl={loadDisplayUrl}
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <MimeTypeIcon mimeType={mediaObject.mimeType} />{" "}
              <Box sx={{ color: "primary.main" }}>{mediaObject.description ?? mediaObject.originalName}</Box>
            </Stack>
          </DisplayOrDownloadMediaObjectWrapper>
        </Stack>
      ))}
      <DisplayMediaObjects
        setMediaObjectToDisplay={setMediaObjectToDisplay}
        mediaObjects={mediaObjects}
        mediaObjectToDisplay={mediaObjectToDisplay}
      />
    </>
  );
}
