import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { SubmissionError } from "../../../services/ApiClient";
import { getLeadStatusByStage, getNextStage, getProductData } from "../../../services/Product/ProductService";
import StatusValidationErrors from "../CaseProgressBar/StatusValidationErrors";
import { validateStatusUpdate } from "../../../services/StatusChangeValidators/statusChangeValidators";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../services/ReactQuery/reactQueryService";
import { AbstractCase } from "../../../types/AbstractCase";
import { StatusValidationError } from "../../../types/StatusValidationError";

type StageModalProps = {
  product: AbstractCase;
  open: boolean;
  handleClose: Function;
  refreshPage: Function;
};

function StageModal({ product, open, handleClose, refreshPage = () => {} }: StageModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<StatusValidationError | null>(null);
  const updateMutation = useMutation(updateResource);

  const nextStage = getNextStage(product);
  if (!nextStage) {
    return null;
  }

  const setNextStage = async () => {
    setIsLoading(true);
    const stage = getNextStage(product);

    const validationResult = validateStatusUpdate(product, stage.stage);
    if (validationResult.length > 0) {
      setErrors({
        stage: stage.label,
        error: new SubmissionError(validationResult, 422),
      });
      setIsLoading(false);
      return;
    }
    await updateMutation.mutateAsync(
      {
        id: product.id,
        uri: getProductData(product.productClassName, "apiUri"),
        data: {
          leadStatus: getLeadStatusByStage(product.productClassName, stage.stage),
        },
      },
      {
        onError: (e) => {
          setErrors({
            stage: stage.label,
            error: e,
          });
          setIsLoading(false);
        },
        onSuccess: () => {
          refreshPage();
          handleClose();
        },
      }
    );
  };

  if (errors !== null) {
    return <StatusValidationErrors error={errors.error} stage={errors.stage} closeDialog={() => setErrors(null)} />;
  }

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Fall schieben"}
      submitButton={
        <ButtonLoading onClick={setNextStage} color="primary" variant={"contained"} isLoading={isLoading}>
          Fall schieben
        </ButtonLoading>
      }
    >
      <p className={"center"}>Wollen Sie den Fall wirklich auf "{nextStage.label}" schieben?</p>
    </LegalbirdIoModal>
  );
}

export default StageModal;
