import * as stagesCase from "../../Stages/StagesCase";

const otherProductSpecificDefinition = (product) => [
  {
    type: "ValidatorTextField",
    path: `${product.productClassName}.contractNumber`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_LAWSUIT,
  },
  {
    type: "ValidatorDateField",
    path: `${product.productClassName}.endOfContractDate`,
    isDisabled: ({ product }) => product.stage >= stagesCase.STAGE_CHECKED,
    isHidden: ({ product }) => {
      return product.objectiveLawFirm !== "cancellationConfirmation";
    },
  },
];

export default otherProductSpecificDefinition;
