import React from "react";
import _ from "lodash";
import { paidTasksCaseStyle } from "./paidTasksCaseStyle";
import {
  PAID_TASK_STATUS_ASSIGNED,
  PAID_TASK_STATUS_CREATED,
  PAID_TASK_STATUS_IN_REVIEW,
  PAID_TASK_STATUS_REQUESTED,
} from "../paidTaskStatus";
import PaidTaskCase from "./PaidTaskCase";
import ContentBox from "../../ContentBox/ContentBox";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { Box } from "@mui/material";
import { AbstractCase } from "../../../types/AbstractCase";

type PaidTasksCaseProps = {
  product: AbstractCase;
};
const PaidTasksCase = ({ product }: PaidTasksCaseProps) => {
  const currentUser = useCurrentUser();
  let paidTaskFilters: {
    backofficeCase: string;
    "exists[deletedDate]": boolean;
    "status[0]": number;
    "status[1]": number;
    "status[2]": number;
    "status[3]"?: number;
  } = {
    backofficeCase: product.backofficeCase["@id"],
    "exists[deletedDate]": false,
    "status[0]": PAID_TASK_STATUS_CREATED,
    "status[1]": PAID_TASK_STATUS_ASSIGNED,
    "status[2]": PAID_TASK_STATUS_IN_REVIEW,
  };
  if (!currentUser.isExternal) {
    paidTaskFilters["status[3]"] = PAID_TASK_STATUS_REQUESTED;
  }
  const { data: paidTasks } = useQuery(
    queryKeys.collection("paid_tasks", paidTaskFilters),
    () => fetchCollection("paid_tasks", paidTaskFilters),
    {
      enabled: !!product,
    }
  );

  if (!paidTasks || paidTasks["hydra:totalItems"] === 0) {
    return null;
  }

  const paidTasksFiltered = _.filter(paidTasks["hydra:member"], (paidTask) => {
    return (
      !currentUser.isExternal || paidTask.status === PAID_TASK_STATUS_CREATED || paidTask.assignee.id === currentUser.id
    );
  });
  const paidTasksOpen = _.filter(paidTasksFiltered, (paidTask) => {
    return (
      paidTask.status === PAID_TASK_STATUS_CREATED ||
      paidTask.status === PAID_TASK_STATUS_ASSIGNED ||
      paidTask.status === PAID_TASK_STATUS_REQUESTED
    );
  });
  const paidTasksInReview = _.filter(paidTasksFiltered, (paidTask) => {
    return paidTask.status === PAID_TASK_STATUS_IN_REVIEW;
  });

  return (
    <ContentBox headline="Ausgeschriebene Aufgaben">
      {paidTasksOpen.length > 0 ? (
        _.map(paidTasksOpen, (paidTask) => (
          <Box sx={paidTasksCaseStyle.activityContainer} key={paidTask.id}>
            <PaidTaskCase paidTask={paidTask} />
          </Box>
        ))
      ) : (
        <Box sx={paidTasksCaseStyle.faded}>Keine Aufgabe vorhanden</Box>
      )}
      <Box sx={paidTasksCaseStyle.horizontalLine} />
      <Box sx={paidTasksCaseStyle.headline}>Abzunehmende Aufgaben</Box>
      {paidTasksInReview.length > 0 ? (
        _.map(paidTasksInReview, (paidTask) => (
          <Box sx={paidTasksCaseStyle.activityContainer} key={paidTask.id}>
            <PaidTaskCase paidTask={paidTask} />
          </Box>
        ))
      ) : (
        <Box sx={paidTasksCaseStyle.faded}>Keine Aufgabe vorhanden</Box>
      )}
    </ContentBox>
  );
};
export default PaidTasksCase;
