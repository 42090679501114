import { Box, CircularProgress } from "@mui/material";
import { displayMediaObjectStyles } from "./displayMediaObjectStyle";
import { useEffect, useState } from "react";
import { MediaObject } from "../../types/MediaObject";

export type DisplayImageMediaObjectProps = {
  mediaObject: MediaObject;
  displayUrl: string;
  mediaRotation: number;
};

export default function DisplayMediaObjectContentPdf({
  mediaObject,
  displayUrl,
  mediaRotation,
}: DisplayImageMediaObjectProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, [mediaObject]);

  return (
    <Box sx={displayMediaObjectStyles.embedContainer}>
      {isLoading && (
        <Box sx={displayMediaObjectStyles.loadingWrapper}>
          <CircularProgress />
        </Box>
      )}
      <embed
        onLoad={() => setIsLoading(false)}
        src={displayUrl}
        type={mediaObject.mimeType!}
        width="100%"
        height="95%"
        style={{ transform: `rotate(${mediaRotation}deg)` }}
      />
    </Box>
  );
}
