import ButtonLoading from "../Button/ButtonLoading";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { MenuItem } from "@mui/material";
import React, { useState } from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { useInvoiceTypeIdentifiers } from "../../provider/InvoiceTypeIdentifiersProvider";
import { apiPut } from "../../services/Api/apiCall";
import { useSnackbar } from "notistack";
import { BookingEntryWithDifference } from "../../types/BookingEntry";

type ChangeInvoiceTypeModalProps = {
  bookingEntry: BookingEntryWithDifference;
  open: boolean;
  handleClose: () => void;
  updateBookingEntries: Function;
};

export default function ChangeInvoiceTypeModal({
  bookingEntry,
  open,
  handleClose,
  updateBookingEntries,
}: ChangeInvoiceTypeModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [newInvoiceType, setNewInvoiceType] = useState<string>(
    [null, "none"].includes(bookingEntry.invoiceTypeIdentifier) ? "null" : bookingEntry.invoiceTypeIdentifier!
  );
  const { isLoading: isLoadingInvoiceTypeIdentifiers, getInvoiceTypeIdentifiersForProduct } =
    useInvoiceTypeIdentifiers();

  const { enqueueSnackbar } = useSnackbar();

  if (isLoadingInvoiceTypeIdentifiers || !bookingEntry.productType) {
    return null;
  }

  const availableInvoiceTypeIdentifiers = getInvoiceTypeIdentifiersForProduct(bookingEntry.productType)!;

  async function saveInvoiceType() {
    try {
      setIsLoading(true);
      await apiPut("/booking_entries", bookingEntry.id, {
        invoiceTypeIdentifier: newInvoiceType === "null" ? null : newInvoiceType, // ensure null is not send as string
      });
      enqueueSnackbar("Rechnungstyp wurde geändert", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      handleClose();
    } catch (e) {
      enqueueSnackbar("Es ist ein Fehler aufgetreten", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setIsLoading(false);
      updateBookingEntries();
    }
  }

  return (
    <LegalbirdIoModal
      handleClose={() => handleClose()}
      open={open}
      title={"Rechnungstyp editieren"}
      submitButton={
        <ButtonLoading isLoading={isLoading} onClick={saveInvoiceType} variant={"contained"}>
          Rechnungstyp speichern
        </ButtonLoading>
      }
    >
      <ValidatorSelect
        label={"Rechnungstyp"}
        value={newInvoiceType}
        onChange={(event: SelectChangeEvent) => setNewInvoiceType(String(event.target.value))}
      >
        <MenuItem value={"null"}>-</MenuItem>
        {Object.keys(availableInvoiceTypeIdentifiers).map((identifier) => (
          <MenuItem key={identifier} value={identifier}>
            {availableInvoiceTypeIdentifiers[identifier]}
          </MenuItem>
        ))}
      </ValidatorSelect>
    </LegalbirdIoModal>
  );
}
