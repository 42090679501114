import React from "react";
import paidTaskListItemStyle from "./paidTaskListItemStyle";
import { Button, Grid } from "@mui/material";
import { convertFromFloat } from "../../../services/formServiceFunctions";
import { translate } from "../../../services/Translations/translatorService";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED } from "../../../components/PaidTask/paidTaskStatus";
import { getCaseLinkByBackofficeCase } from "../../../services/Product/ProductService";
import CasePaidTaskAccept from "../../../components/CasePaidTask/CasePaidTaskAccept";
import { PaidTask } from "../../../types/PaidTask";

type PaidTaskListItemProps = {
  paidTask: PaidTask;
  isExternalView?: boolean;
  hideCase?: boolean;
};

const PaidTaskListItem = ({ paidTask, isExternalView, hideCase }: PaidTaskListItemProps) => {
  const caseLink = getCaseLinkByBackofficeCase(paidTask.backofficeCase, isExternalView ? "/extern" : "");

  return (
    <Grid container spacing={1} sx={paidTaskListItemStyle.smallFont}>
      <Grid
        item
        xs={12}
        sx={{ ...paidTaskListItemStyle.singleLineOverflowHidden, ...paidTaskListItemStyle.paidTaskSubject }}
      >
        {paidTask.subject}
      </Grid>
      <Grid item xs={9}>
        {convertFromFloat(paidTask.fee)} €
      </Grid>
      <Grid item xs={3}>
        {isExternalView ? "" : translate("paidTask.status." + paidTask.status)}
      </Grid>
      <Grid item xs={9}>
        {hideCase ? "" : paidTask.backofficeCase.label || paidTask.backofficeCase.lbProductId}
      </Grid>
      <Grid item xs={3}>
        {isExternalView ? "" : _.get(paidTask, "assignee.person.fullname", "-")}
      </Grid>
      <Grid item xs={3}>
        Ann bis.
      </Grid>
      <Grid item xs={3}>
        {moment(paidTask.assignmentDeadline).format("DD.MM.YYYY")}
      </Grid>
      <Grid item xs={3}>
        Erl bis.
      </Grid>
      <Grid item xs={3}>
        {moment(paidTask.doneDeadline).format("DD.MM.YYYY")}
      </Grid>
      <Grid item xs={9}>
        {hideCase ? (
          ""
        ) : (
          <Button
            sx={paidTaskListItemStyle.paidTaskButton}
            variant={"contained"}
            color={"primary"}
            fullWidth={false}
            component={Link}
            to={caseLink}
          >
            Zum Fall
          </Button>
        )}
      </Grid>
      <Grid item xs={3}>
        {isExternalView && _.includes([PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_REQUESTED], paidTask.status) ? (
          <CasePaidTaskAccept paidTask={paidTask} />
        ) : (
          <Button
            sx={paidTaskListItemStyle.paidTaskButton}
            component={Link}
            to={caseLink + "/ausschreibung/" + paidTask.id}
            variant={"contained"}
            color={"primary"}
          >
            Details
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default PaidTaskListItem;
