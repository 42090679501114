export const loadSessionStorageState = (key: string) => {
  try {
    const serializedState = sessionStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveSessionStorageState = (key: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignore errors for now
  }
};

export const clearSessionStorageState = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (err) {
    // Ignore errors for now
  }
};
