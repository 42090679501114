
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import CreateReleaseUpdate from "../../ReleaseUpdates/CreateReleaseUpdate";

export default function CreateReleaseUpdatePage() {

  return (
    <PageContentContainer>
      <PageHeadline main={"Release-Update erstellen"} />
      <CreateReleaseUpdate/>
    </PageContentContainer>
  );
};
