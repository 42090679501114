const circleIconStyle = {
  imageCircleContainer: (iconContainerSize: string | number) => ({
    width: iconContainerSize,
    height: iconContainerSize,
    borderRadius: "50% !important",
    border: "1px solid #333333",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#333",
  }),
  icon: (iconSize: string | number) => ({
    width: iconSize,
    height: iconSize,
  }),
};

export default circleIconStyle;
