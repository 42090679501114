import { scrollToAnchor } from "../../theme/commonStyles";
import { Theme } from "@mui/material";

export const processCostSupportStyle = {
  smallHeadline: {
    textAlign: "center",
    fontSize: "1rem",
  },
  headlineName: {
    textAlign: "center",
  },
  deleteSignatureButton: {
    maxWidth: 300,
    margin: "1rem auto 0 auto",
    display: "block",
  },
  heading: {
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  paperHeadline: {
    fontSize: "1.5rem",
    fontWeight: 700,
    textAlign: "center",
    margin: "1rem",
  },
  faded: {
    fontSize: "1.25rem",
    fontWeight: 700,
    textAlign: "center",
    margin: "2rem",
    color: "#dcdcdc",
  },
  paper: {
    padding: "1rem",
    marginBottom: "2rem",
  },
  sectionHeadline: {
    fontSize: "1.25rem",
    textAlign: "center",
    margin: "2rem",
  },
  question: {
    fontSize: "1rem",
    textAlign: "center",
    marginTop: "1rem",
  },
  missingDocumentsContainer: {
    maxWidth: 750,
    margin: "0 auto",
  },
  grid: (theme: Theme) => ({
    "& > .MuiGrid-item": {
      [theme.breakpoints.up("md")]: {
        padding: "12px",
      },
      minWidth: 320,
    },
  }),
  scrollToAnchor: {
    ...scrollToAnchor,
  },
  receiptContainer: {
    maxWidth: 750,
    margin: "0 auto",
  },
  signatureHeadline: {
    textAlign: "center",
    padding: "1.5rem",
  },
  signature: {
    margin: "0 auto",
    maxWidth: 800,
    minWidth: 500,
    minHeight: 200,
  },
  deleteModalText: {
    textAlign: "center",
    marginTop: "1rem",
  },
  naviExtension: {
    position: "sticky",
    top: 75,
    zIndex: 200,
  },
};

