import React from "react";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import Paper from "@mui/material/Paper";
import { dutyNotificationStyles } from "../Notifications/dutyNotificationStyles";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export type DutyNotificationSnackbarProps = {
  timeString: string;
  description: string;
  link: string;
};
export const DutyNotificationSnackbar = React.forwardRef<
  HTMLDivElement,
  DutyNotificationSnackbarProps & CustomContentProps
>(({ timeString, description, link }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const closeNotification = () => {
    closeSnackbar();
  };

  return (
    <SnackbarContent ref={ref}>
      <Paper sx={dutyNotificationStyles.notificationContainer}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={2} />
          <Grid item xs={8} sx={dutyNotificationStyles.headline}>
            Neue Pflicht für heute!
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={dutyNotificationStyles.icon} onClick={closeNotification} size="large">
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <strong>Zeitpunkt: </strong>
            {timeString}
          </Grid>
          <Grid item xs={12}>
            <strong>Beschreibung: </strong>
            {description}
          </Grid>
          <Grid item xs={6}>
            <Button onClick={closeNotification} component={Link} to={link}>
              Zum Fall
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={closeNotification}>Ok</Button>
          </Grid>
        </Grid>
      </Paper>
    </SnackbarContent>
  );
});
