import React from "react";
import { Paper, Typography } from "@mui/material";
import contentBoxStyle from "./contentBoxStyle";
import _ from "lodash";

type ContentBoxProps = {
  children: React.ReactNode;
  headline?: string;
  headlineVariant?: "h1" | "h2" | "h3";
  padding?: "default" | "none";
  margin?: "default" | "none";
};

export default function ContentBox({
  children,
  headline = "",
  headlineVariant = "h3",
  padding = "default",
  margin = "default",
}: ContentBoxProps) {
  const paddingStyle = `${padding}Padding` as keyof typeof contentBoxStyle;
  const marginStyle = `${margin}Margin` as keyof typeof contentBoxStyle;

  return (
    <Paper sx={{ ...contentBoxStyle[paddingStyle], ...contentBoxStyle[marginStyle] }}>
      {!_.isEmpty(headline) && (
        <Typography sx={contentBoxStyle.headline} variant={headlineVariant}>
          {headline}
        </Typography>
      )}
      {children}
    </Paper>
  );
}
