import React, { createContext, useContext } from "react";
import { AbstractCase } from "../types/AbstractCase";
import { getProductData, getProductName } from "../services/Product/ProductService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../services/ReactQuery/reactQueryService";

interface CaseProviderData {
  case: AbstractCase | null;
  product: AbstractCase | null;
  isLoading: boolean;
  error: any;
  refreshCase: () => Promise<void>;
}

const CaseProviderContext = createContext<CaseProviderData | null>(null);

export default function CaseProvider({
  children,
  caseUrlPart,
  caseId,
}: {
  children?: React.ReactNode;
  caseUrlPart: string;
  caseId: number;
}) {
  const queryClient = useQueryClient();
  const caseUrl = getProductData(getProductName(caseUrlPart), "apiUri");

  const {
    data: caseEntity,
    error,
    isLoading,
  } = useQuery(queryKeys.item(caseUrl, caseId), () => fetchResource(caseUrl, caseId), { retry: false });

  const refreshCase = async () => {
    return queryClient.invalidateQueries(queryKeys.item(caseUrl, caseId));
  };

  const data: CaseProviderData = {
    case: caseEntity,
    product: caseEntity,
    isLoading,
    error,
    refreshCase,
  };

  return <CaseProviderContext.Provider value={data}>{children}</CaseProviderContext.Provider>;
}

export const useCase = () => {
  const caseContext = useContext(CaseProviderContext);
  if (!caseContext) {
    throw new Error("useCase can only be used inside CaseProvider");
  }
  return caseContext;
};
