import React, { PropsWithChildren, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { MoreHoriz } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import { popUpMenuStyles } from "./popUpMenuStyles";
import { Tooltip } from "@mui/material";

type PopUpMenuProps = {
  disabled?: boolean;
  icon?: React.ReactNode;
  tooltipTitle?: string;
};

const PopUpMenu = ({
  children,
  disabled = false,
  icon = <MoreHoriz />,
  tooltipTitle = "",
}: PropsWithChildren<PopUpMenuProps>) => {
  const myAnchor = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton onClick={handleMenuOpen} disabled={disabled} sx={popUpMenuStyles.iconButton} size="large">
          {icon}
        </IconButton>
      </Tooltip>
      <div ref={myAnchor} />
      <Menu open={menuOpen} anchorEl={myAnchor.current} onClose={handleMenuClose} onClick={() => setMenuOpen(false)}>
        {children}
      </Menu>
    </>
  );
};

export default PopUpMenu;
