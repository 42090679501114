import React, { useState } from "react";
import { MediaObject } from "../../types/MediaObject";
import { Stack } from "@mui/material";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import { OpenInNew, FaxOutlined, SendOutlined, ScannerOutlined } from "@mui/icons-material";


type DisplayableMediaObjectProps = {
  mediaObject: MediaObject;
  label?: string;
};
export default function DisplayableMediaObject({ mediaObject }: DisplayableMediaObjectProps) {
  const [displayMediaObjectOpen, setDisplayMediaObjectOpen] = useState<boolean>(false);
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        onClick={() => setDisplayMediaObjectOpen(true)}
        sx={{ cursor: "pointer" }}
      >
        {mediaObject.fieldname === "fax" && <FaxOutlined />}
        {mediaObject.fieldname === "bea" && <SendOutlined />}
        {mediaObject.fieldname === "scan" && <ScannerOutlined />}
        <OpenInNew />
      </Stack>
      <DisplayMediaObject
        mediaObject={displayMediaObjectOpen ? mediaObject : null}
        closeDialog={() => setDisplayMediaObjectOpen(false)}
      />
    </>
  );
}
