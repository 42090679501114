import React from "react";
import Grid from "@mui/material/Grid";
import { translate } from "../../../../services/Translations/translatorService";
import IconButton from "@mui/material/IconButton";
import { Call } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import contactStyle from "./contactStyle";
import ContentBox from "../../../ContentBox/ContentBox";
import { AbstractCase } from "../../../../types/AbstractCase";

const useStyles = makeStyles(contactStyle);

type ContactProps = {
  product: AbstractCase;
};

const Contact = ({ product }: ContactProps) => {
  const classes = useStyles();
  return (
    <ContentBox headline="Kontakt">
      <Grid container alignItems={"flex-start"}>
        <Grid item xs={10}>
          <TextField
            disabled={true}
            value={product.customer?.telephone}
            label={translate("customer.telephone.label")}
          />
        </Grid>
        <Grid item xs={2} className={classes.callGridItem}>
          <IconButton
            color={"secondary"}
            className={classes.callIcon}
            href={"tel:" + product.customer?.telephone}
            disabled={!product.customer?.telephone}
            size="large"
          >
            <Call />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <TextField
            disabled={true}
            value={product.customer?.alternativeTelephone}
            label={translate("customer.alternativeTelephone.label")}
          />
        </Grid>
        <Grid item xs={2} className={classes.callGridItem}>
          <IconButton
            color={"secondary"}
            className={classes.callIcon}
            href={"tel:" + product.customer?.alternativeTelephone}
            disabled={!product.customer?.alternativeTelephone}
            size="large"
          >
            <Call />
          </IconButton>
        </Grid>
      </Grid>
    </ContentBox>
  );
};

export default Contact;
