import React from "react";
import _ from "lodash";
import FormElement from "../../../Case/CaseForm/FormElement";
import useForm from "../../../../hooks/useForm";
import { transformToFormValues } from "../../../../services/formServiceFunctions";
import { getFormDefinitionExternals } from "../../../../services/Product/ProductService";
import ContentBox from "../../../ContentBox/ContentBox";
import { AbstractCase } from "../../../../types/AbstractCase";

type CaseDataProps = {
  product: AbstractCase;
};
const CaseData = ({ product }: CaseDataProps) => {
  const initialValues = { [product.productClassName]: transformToFormValues(product) };
  const useFormProps = useForm({
    initialValues,
    onSubmit: async () => {},
    identifier: product.id.toString(),
  });

  const formDefinitionExternals = getFormDefinitionExternals(product);
  return (
    <ContentBox headline="Details zum Fall">
      {_.map(formDefinitionExternals, (formElementProps) => {
        return (
          <FormElement
            product={product}
            key={formElementProps.path}
            {...formElementProps}
            {...useFormProps}
            mandatoryFields={[]}
            isDisabled={() => true}
          />
        );
      })}
    </ContentBox>
  );
};
export default CaseData;
