import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: "#f8f4d0",
    width: "100%",
    padding: "2rem",
    position: "relative",
  },
  warning: {
    backgroundColor: "#f9d0d0",
  },
  info: {
    flexGrow: 1,
    fontWeight: 700,
    fontSize: "1rem",
  },
  note: {
    marginTop: "1rem",
    fontSize: "1rem",
    overflow: "hidden",
    minHeight: "2rem",
  },
  collapsedNote: {
    height: "4rem",
  },
  icon: {
    color: "#000",
  },
  lastChange: {
    fontSize: ".75rem",
    zIndex: 2,
    textAlign: "right",
    position: "relative",
  },
  overlay: {
    backgroundColor: "#FFFFFF99",
    zIndex: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
}));

export default useStyles;
