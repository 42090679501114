import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { apiPost } from "../../services/Api/apiCall";
import { BookingEntryWithDifference } from "../../types/BookingEntry";

type InvoiceToCreditNoteModalProps = {
  bookingEntry: BookingEntryWithDifference;
  open: boolean;
  handleClose: () => void;
  updateBookingEntries: Function;
};

export default function InvoiceToCreditNoteModal({ bookingEntry, open, handleClose }: InvoiceToCreditNoteModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const generateCreditNote = async () => {
    setIsLoading(true);
    let snackbarMessage = "Gutschrift wurde generiert";
    try {
      await apiPost("/lb/services/generate_credit_note/" + bookingEntry.id, {
        createdBy: currentUser["@id"],
      });
      await queryClient.invalidateQueries(queryKeys.collections("booking_entries"));
    } catch (e) {
      snackbarMessage = "Gutschrift konnte nicht generiert werden";
    }

    enqueueSnackbar(snackbarMessage, {
      variant: "custom",
      isNonInteractive: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });

    setIsLoading(false);
    handleClose();
  };

  return (
    <LegalbirdIoModal
      handleClose={() => handleClose()}
      open={open}
      title={"Rechnung ausbuchen"}
      submitButton={
        <ButtonLoading isLoading={isLoading} onClick={generateCreditNote} variant={"contained"}>
          Rechnung ausbuchen
        </ButtonLoading>
      }
    >
      Sind Sie sicher, dass die Rechnung {bookingEntry.invoiceNumber} in Höhe von{" "}
      {bookingEntry.amount?.toLocaleString("de-DE", { style: "currency", currency: "EUR" })} ausgebucht werden soll? Es
      wird eine Gutschrift in der Höhe der Rechnung erstellt mit einem Hinweis auf die Rechnung in der Notiz.
    </LegalbirdIoModal>
  );
}
