import React, { useState } from "react";
import dateRangePickerStyles from "./dateRangePickerStyles";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { Box } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";

interface DateRangePickerProps {
  open: boolean;
  closeModal: Function;
  setDateRange: Function;
}

const DateRangePicker = ({ open, closeModal, setDateRange }: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const onConfirm = () => {
    const dateRange = {
      startDate: startDate,
      endDate: endDate,
    };
    setDateRange(dateRange);
    closeModal();
  };

  const handleStartDateChange = (value: moment.Moment | null) => {
    if (!value) {
      return;
    }
    setStartDate(value);

    if (value.isAfter(endDate)) {
      setEndDate(value);
    }
  };

  return (
    <LegalbirdIoModal
      handleClose={() => {
        setDateRange("today");
        closeModal();
      }}
      open={open}
      title={"Zeitraum auswählen"}
      submitButton={
        <Button variant={"contained"} onClick={onConfirm}>
          Anwenden
        </Button>
      }
      maxWidth={"md"}
      fullWidth
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={5}>
          <Box sx={dateRangePickerStyles.datePickerContainer}>
            <Paper sx={dateRangePickerStyles.datePicker} elevation={8}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                slotProps={{
                  toolbar: {
                    hidden: false,
                  },
                }}
                localeText={{ toolbarTitle: 'Start' }}
                value={startDate}
                onChange={handleStartDateChange}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box sx={dateRangePickerStyles.datePickerContainer}>
            <Paper sx={dateRangePickerStyles.datePicker} elevation={8}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                slotProps={{
                  toolbar: {
                    hidden: false,
                  },
                }}
                localeText={{ toolbarTitle: 'Ende' }}
                value={endDate}
                onChange={(date) => !!date && setEndDate(date)}
                minDate={startDate}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
};

export default DateRangePicker;
