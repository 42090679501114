const abstractCaseTranslations = {
  preventAccounting: {
    label: "Konto/Rechnungen pausieren",
    values: {
      true: "inaktiv",
      false: "aktiv",
    },
  },
  preventAllSystemEmails: {
    label: "Emails und Nachrichten pausieren",
    values: {
      true: "inaktiv",
      false: "aktiv",
    },
  },
  additionalClient: {
    gender: {
      label: "Anrede weitere Mandantschaft",
      values: {
        male: "Herr",
        female: "Frau",
        diverse: "Divers",
      },
    },
    givenName: {
      label: "Vorname weitere Mandantschaft",
    },
    familyName: {
      label: "Nachhame weitere Mandantschaft",
    },
  },
};

export default abstractCaseTranslations;
