import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Paper, Tab, Tabs, useMediaQuery } from "@mui/material";
import navigationExtensionAllDataStyle from "../NavigationBar/navigationExtensionAllDataStyle";

const NavigationExtensionPaidTask = () => {
  const [topOffset, setTopOffset] = useState(69);
  const smDown = useMediaQuery("(max-width:1149px)");

  return (
    <Paper
      square
      sx={navigationExtensionAllDataStyle.tabs({ top: topOffset })}
      ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
    >
      <Tabs variant={smDown ? "scrollable" : "standard"} scrollButtons="auto" value={false} centered={!smDown}>
        <Tab component={Link} to={"#definition"} label={"Aufgabe"} />
        <Tab component={Link} to={"#submission"} label={"Bearbeitung"} />
        <Tab component={Link} to={"#approval"} label={"Abnahme"} />
      </Tabs>
    </Paper>
  );
};

export default NavigationExtensionPaidTask;
