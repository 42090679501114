import React, { useCallback, useState } from "react";
import FileSection from "../ProcessCostSupport/Receipts/FileSection";
import _ from "lodash";
import ApiClient from "../../services/ApiClient";
import moment from "moment";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import casePaidTaskStyle from "./casePaidTaskStyle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { AbstractCase } from "../../types/AbstractCase";
import { PaidTask } from "../../types/PaidTask";
import { BackofficeUser } from "../../types/BackofficeUser";

type CasePaidTaskFileUploadAreaProps = {
  paidTaskTypeConfig: {
    mainDocumentConfig: {
      headline: string;
      description: string;
      fileName: string;
      fileDescription: string;
    };
    iterableAttachmentsConfig: {
      headline: string;
      description: string;
      identifier: string;
      fileName: string;
      fileDescription: string;
    };
  };
  product: AbstractCase;
  paidTask: PaidTask;
  readOnly?: boolean;
};

const getInitialIterableAttachmentCount = (paidTask: PaidTask, currentUser: BackofficeUser) => {
  //get all filename ending numbers to find the highest one: "paidTaskDocumentK5" -> 5
  let iterableAttachmentNumbers = _.map(paidTask.mediaObjects, (mediaObject) => {
    if (!mediaObject.identifier?.match(/^paidTaskDocument[A-Z]?\d+$/)) {
      return 0;
    }
    return parseInt(mediaObject.identifier.replace(/\D/g, ""));
  });

  return Math.max(...iterableAttachmentNumbers, currentUser.isExternal ? 1 : 0);
};

const replacePlaceholders = (stringWithPlaceholders: string, product: AbstractCase, iterator?: number) => {
  let replacementString = stringWithPlaceholders;
  replacementString = replacementString.replaceAll("{{YYYYMMDD}}", moment().format("YYYYMMDD"));
  if (iterator) {
    replacementString = replacementString.replaceAll("{{iterator}}", iterator.toString());
  }
  if (product.client?.familyName) {
    replacementString = replacementString.replaceAll("{{clientFamilyName}}", product.client.familyName);
    replacementString = replacementString.replaceAll("{{DD.MM.YYYY}}", moment().format("DD.MM.YYYY"));
  }
  return replacementString;
};

const CasePaidTaskFileUploadArea = ({
  paidTaskTypeConfig,
  product,
  paidTask,
  readOnly = false,
}: CasePaidTaskFileUploadAreaProps) => {
  const updateMutation = useMutation(updateResource);
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const [mediaObjectToDisplay, setMediaObjectToDisplay] = useState(null);

  const [additionalAttachmentCount, setAdditionalAttachmentCount] = useState(
    getInitialIterableAttachmentCount(paidTask, currentUser)
  );

  const deleteFile = async (id: number, identifier: string) => {
    const truncatedMediaObjects = _.filter(
      paidTask.mediaObjects,
      (mediaObject) => mediaObject.identifier !== identifier
    );
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        mediaObjects: truncatedMediaObjects,
      },
    });
    await queryClient.invalidateQueries(queryKeys.item("paid_tasks", paidTask.id));
  };

  const uploadFile = async (files: string, originalName: string, description: string, identifier: string) => {
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("product", product.productClassName);
    formData.append("productId", product.id.toString());
    formData.append("originalName", originalName);
    formData.append("description", description);
    formData.append("fieldname", "paidTaskDocument");
    formData.append("customerId", product.customer.id.toString());
    formData.append("showInExternalView", "true");

    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");
    const mediaObject = await ApiClient.post("media_objects", {
      headers: headers,
      body: formData,
    });

    let extendedMediaObjects = _.cloneDeep(paidTask.mediaObjects);
    extendedMediaObjects?.push({ ...mediaObject, identifier: identifier });
    await updateMutation.mutateAsync({
      id: paidTask.id,
      uri: "paid_tasks",
      data: {
        mediaObjects: extendedMediaObjects,
      },
    });
    await queryClient.invalidateQueries(queryKeys.item("paid_tasks", paidTask.id));
  };

  const getIterableAttachmentFields = useCallback(() => {
    let uploadFields = [];

    if (!paidTaskTypeConfig.iterableAttachmentsConfig) {
      return [];
    }

    for (let iterator = 1; iterator < additionalAttachmentCount + 1; iterator++) {
      const identifier =
        "paidTaskDocument" +
        replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.identifier, product, iterator);

      uploadFields.push(
        <FileSection
          key={identifier}
          headline={
            paidTaskTypeConfig.iterableAttachmentsConfig.headline +
            " " +
            replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.identifier, product, iterator)
          }
          subText={replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.description, product, iterator)}
          deleteFile={(id: number) => deleteFile(id, identifier)}
          uploadFile={(files: string) =>
            uploadFile(
              files,
              replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.fileName, product, iterator),
              replacePlaceholders(paidTaskTypeConfig.iterableAttachmentsConfig.fileDescription, product, iterator),
              identifier
            )
          }
          currentMediaObjects={_.filter(paidTask.mediaObjects, (mediaObject) => mediaObject.identifier === identifier)}
          setMediaObjectToDisplay={setMediaObjectToDisplay}
          fileLimit={1}
          readOnly={readOnly}
          acceptedFiles={[".pdf"]}
        />
      );
    }

    return uploadFields;
  }, [additionalAttachmentCount, paidTaskTypeConfig, paidTask]);

  return (
    <>
      {paidTaskTypeConfig.mainDocumentConfig && (
        <>
          <Typography sx={casePaidTaskStyle.subHeadline}>
            Um die Aufgabe zu erledigen, laden Sie bitte die unten aufgeführten Dateien hoch.
          </Typography>
          <FileSection
            headline={paidTaskTypeConfig.mainDocumentConfig.headline}
            subText={paidTaskTypeConfig.mainDocumentConfig.description}
            deleteFile={(id: number) => deleteFile(id, "paidTaskMainDocument")}
            uploadFile={(files: string) =>
              uploadFile(
                files,
                replacePlaceholders(paidTaskTypeConfig.mainDocumentConfig.fileName, product),
                replacePlaceholders(paidTaskTypeConfig.mainDocumentConfig.fileDescription, product),
                "paidTaskMainDocument"
              )
            }
            currentMediaObjects={_.filter(
              paidTask.mediaObjects,
              (mediaObject) => mediaObject.identifier === "paidTaskMainDocument"
            )}
            setMediaObjectToDisplay={setMediaObjectToDisplay}
            fileLimit={1}
            readOnly={readOnly}
            acceptedFiles={[".pdf"]}
          />
        </>
      )}
      {getIterableAttachmentFields()}
      {!readOnly && (
        <Button onClick={() => setAdditionalAttachmentCount(additionalAttachmentCount + 1)}>
          Weitere Anlage hinzufügen
        </Button>
      )}
      <DisplayMediaObject closeDialog={() => setMediaObjectToDisplay(null)} mediaObject={mediaObjectToDisplay} />
    </>
  );
};

export default CasePaidTaskFileUploadArea;
