import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  carouselContainer: {
    [theme.breakpoints.up("lg")]: {
      //- left reserved action space + scrollbar 70 + 17
      width: "calc(90vw - 87px)",
    },
    [theme.breakpoints.down("lg")]: {
      //- scrollbar 17
      width: "calc(90vw - 17px)",
    },
    margin: "0 auto",
  },
  column: {
    padding: "1rem",
  },
  columnLabel: {
    textAlign: "center",
    fontSize: ".875rem",
    fontWeight: 700,
    height: "3rem",
  },
  caseCount: {
    textAlign: "center",
    fontSize: ".75rem",
  },
  leftArrow: {
    position: "fixed",
    top: "40vh",
    left: 80,
  },
  rightArrow: {
    position: "fixed",
    top: "40vh",
    right: 90,
  },
  arrowColors: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    opacity: 0.5,
  },
}));

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1651 },
    items: 9,
    slidesToSlide: 3,
  },
  shittyMonitor: {
    breakpoint: { max: 1650, min: 1251 },
    items: 6,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1250, min: 1001 },
    items: 5,
    slidesToSlide: 2,
  },
  smallTablet: {
    breakpoint: { max: 1000, min: 701 },
    items: 3,
    slidesToSlide: 1,
  },
  chonkyPhone: {
    breakpoint: { max: 700, min: 451 },
    items: 2,
    slidesToSlide: 1,
  },
  phone: {
    breakpoint: { max: 450, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
