import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import ApiClient from "../../../services/ApiClient";
import { getProductData } from "../../../services/Product/ProductService";
import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { AbstractCase } from "../../../types/AbstractCase";
import { SelectOption } from "../../../types/SelectOption";

type PaymentTypeSwitchModalProps = {
  product: AbstractCase;
  open: boolean;
  handleClose: Function;
  refreshPage?: Function;
};

function PaymentTypeSwitchModal({ product, open, handleClose, refreshPage = () => {} }: PaymentTypeSwitchModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(product.paymentType);

  const savePaymentType = async () => {
    setIsLoading(true);
    await ApiClient.put(product["@id"], {
      body: JSON.stringify({ paymentType: paymentType }),
    });
    setIsLoading(false);
    handleClose();
    refreshPage();
  };

  const paymentTypeSwitchData = getProductData(product.productClassName, "paymentTypeSwitchData");
  const { allowed, message } = paymentTypeSwitchData.getPaymentSwitchAllowed(product);
  const { options }: { options: SelectOption[] } = paymentTypeSwitchData;

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title="Zahlart ändern"
      actionsInfo={message ? <Typography sx={{ textAlign: "center", color: "red" }}>{message}</Typography> : null}
      submitButton={
        <ButtonLoading
          onClick={savePaymentType}
          color="primary"
          variant={"contained"}
          isLoading={isLoading}
          disabled={!allowed || product.paymentType === paymentType}
        >
          Zahlart ändern
        </ButtonLoading>
      }
    >
      <Stack spacing={2}>
        <Typography sx={{ textAlign: "center" }}>
          Auf welche Zahlart möchten Sie ändern? Bitte beachten Sie, dass bei Änderungen der Zahlart auch die Abrechnung
          entsprechend automatisch umgestellt wird.
        </Typography>
        <Select value={paymentType} onChange={({ target }) => setPaymentType(target.value)}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {product.paymentType !== "private" && paymentType === "private" && (
          <Typography sx={{ textAlign: "center" }}>
            Sind Sie sicher, dass Sie die Zahlart auf “Selbstzahler” ändern möchten? Der Mandant erhält dann eine
            Nachricht mit Rechnung und das Mandantenkonto wird entsprechend angepasst.
          </Typography>
        )}
      </Stack>
    </LegalbirdIoModal>
  );
}

export default PaymentTypeSwitchModal;
