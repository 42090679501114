import { SvgIconProps, Tooltip } from "@mui/material";
import { AbstractCase } from "../../../types/AbstractCase";
import { PersonAddAlt } from "@mui/icons-material";

type CaseAdditionalClientsTooltipIconProps = {
  product: AbstractCase;
  fontSize?: SvgIconProps["fontSize"];
};
export default function CaseAdditionalClientsTooltipIcon({ product, fontSize = "medium" }: CaseAdditionalClientsTooltipIconProps) {
  if (!product.additionalClients.length) {
    return null;
  }

  return (
    <Tooltip title={`Weitere Mandantschaft: ${product.additionalClients.map((client) => client.fullName).join(", ")}`}>
      <PersonAddAlt sx={{ color: "primary.main" }} fontSize={fontSize} />
    </Tooltip>
  );
}
