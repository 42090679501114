/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Paper, Tab, Tabs } from "@mui/material";
import { navigationExtensionEmotionStyle } from "./navigationExtensionAllDataStyle";
import { ContentContainer } from "../Container/PageContentContainer";
import { userHasRole } from "../../services/backofficeUserService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { Roles } from "../../types/BackofficeUser";

const NavigationExtensionHumanResourceManagement = () => {
  const [topOffset, setTopOffset] = useState(69);
  const currentUser = useCurrentUser();

  return (
    <Paper
      square
      ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
      sx={navigationExtensionEmotionStyle.tabs({ top: topOffset })}
    >
      <ContentContainer size={"large"}>
        <Tabs variant={"fullWidth"} scrollButtons="auto" value={false} centered>
          <Tab component={Link} to={"#hrPlaning"} label={"HR Planung"} />
          <Tab component={Link} to={"#absence"} label={"Abwesenheit"} />
          <Tab component={Link} to={"#workingHours"} label={"Arbeitszeit"} />
          {userHasRole(currentUser, Roles.admin) && (
            <Tab component={Link} to={"#publicHolidays"} label={"Feiertage einpflegen"} />
          )}
        </Tabs>
      </ContentContainer>
    </Paper>
  );
};

export default NavigationExtensionHumanResourceManagement;
