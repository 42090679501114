import React, { useState } from "react";
import { processCostSupportListItemStyle } from "./processCostSupportListItemStyle";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import { getCaseLink } from "../../services/Product/ProductService";
import { Link } from "react-router-dom";
import PopUpMenu from "../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import ButtonLoading from "../Button/ButtonLoading";
import _ from "lodash";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

const ProcessCostSupportListItem = ({ processCostSupport, updateProcessCostSupports }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteProcessCostSupport = async () => {
    setIsLoading(true);
    await ApiClient.delete("/process_cost_supports/" + processCostSupport.id);
    await updateProcessCostSupports();
    setIsLoading(false);
    setDeleteModalOpen(false);
  };

  const leadStatusText = (pcs) => {
    switch (pcs.leadStatus) {
      case 10:
        if (pcs.applicationStatus < 500) {
          return <span>Daten unvollständig</span>;
        }
        return <span>Belege unvollständig</span>;
      case 20:
        return <span>Zu prüfen</span>;
      case 30:
        return <span>Abgeschlossen</span>;
      default:
        return "";
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Link
          sx={processCostSupportListItemStyle.link}
          to={"/services/tools/verfahrenskostenhilfe/antrag/" + processCostSupport.id}
        >
          {processCostSupport.petitioner.givenName && processCostSupport.petitioner.familyName
            ? processCostSupport.petitioner.givenName + " " + processCostSupport.petitioner.familyName
            : processCostSupport.divorce.petitioner.givenName + " " + processCostSupport.divorce.petitioner.familyName}
        </Link>
      </TableCell>
      <TableCell>{leadStatusText(processCostSupport)}</TableCell>
      <TableCell>{moment(processCostSupport.updated).format("DD.MM.YYYY")}</TableCell>
      <TableCell>{_.get(processCostSupport, "relatedBackofficeCase.accountManager.person.fullname")}</TableCell>
      <TableCell>
        <PopUpMenu>
          <MenuItem onClick={() => setDeleteModalOpen(true)}>Löschen</MenuItem>
          <MenuItem
            component={Link}
            to={getCaseLink({ productClassName: "divorce", id: _.get(processCostSupport, "divorce.id") })}
          >
            Zum Fall
          </MenuItem>
        </PopUpMenu>
        <LegalbirdIoModal
          handleClose={() => setDeleteModalOpen(false)}
          open={deleteModalOpen}
          title={"Antrag Löschen"}
          submitButton={
            <ButtonLoading variant={"contained"} onClick={deleteProcessCostSupport} isLoading={isLoading}>
              Löschen
            </ButtonLoading>
          }
        >
          <Typography sx={processCostSupportListItemStyle.deleteText}>
            Sind Sie sicher, dass Sie diesen VKH Antrag vollständig löschen möchten?
          </Typography>
        </LegalbirdIoModal>
      </TableCell>
    </TableRow>
  );
};

ProcessCostSupportListItem.propTypes = {
  processCostSupport: PropTypes.object.isRequired,
  updateProcessCostSupports: PropTypes.func.isRequired,
};

export default ProcessCostSupportListItem;
