import React, { useCallback } from "react";
import { InputAdornment, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import NavigationBarAccountMenu from "../../../components/NavigationBar/NavigationBarAccountMenu";
import { ALGOLIA_INDEX } from "../../../config/_entrypoint";
import { useAccessibleCases } from "../../../provider/AccessibleCasesProvider";
import _ from "lodash";
import SearchBar from "../../../components/Algolia/SearchBar";
import MainSearchHit from "../../../components/Case/CaseEntityPicker/Hit/MainSearchHit";
import { Hit } from "@algolia/client-search";

const NavigationBar = () => {
  const { accessibleCases } = useAccessibleCases();
  const accessibleCaseIds = _.map(accessibleCases, (accessibleCase) => accessibleCase.lbProductId);

  const accessibleCasesFilter = useCallback(
    (hits: Hit<any>[]) => {
      return _.filter(hits, (hit) => {
        return _.includes(accessibleCaseIds, hit.productId);
      });
    },
    [accessibleCaseIds]
  );

  return (
    <>
      <AppBar position={"sticky"} color={"inherit"}>
        <Toolbar>
          <SearchBar
            HitComponent={MainSearchHit}
            resultListConfig={{
              itemSize: 270,
              height: Math.round(window.innerHeight * 0.8),
            }}
            searchConfig={{
              indexName: ALGOLIA_INDEX,
              postSearchFilter: accessibleCasesFilter,
            }}
            endAdornment={
              <InputAdornment position="end">
                <NavigationBarAccountMenu />
              </InputAdornment>
            }
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavigationBar;
