import _ from "lodash";
import moment from "moment";
import { ACTIVITY_OVERDUE } from "../Stages/Stages";
import ApiClient from "../ApiClient";
import { deadlineActivityTypes } from "./activityTypes";
import { BackofficeUser, Roles } from "../../types/BackofficeUser";
import { Activity } from "../../types/Activity";
import { AbstractCase } from "../../types/AbstractCase";
import AvailableActivityActions from "../../types/Activities/ActivityActions/AvailableActivityActions";
import { TimespanFilter } from "../../components/Case/CaseHistory/CaseHistoryFilter";
import {userHasRole} from "../backofficeUserService";

export const prepareFilters = (
  currentUser: BackofficeUser,
  backofficeUsers: BackofficeUser[] = [],
  storageKey = "activityFilters",
  defaultActivityTypeFilter = "all",
  defaultOpenOrDoneFilter = ""
) => {
  const storedFilters = JSON.parse(localStorage.getItem(storageKey) || "{}");
  let currentFilters = _.merge(
    {
      productFilter: "all",
      activityTypeFilter: defaultActivityTypeFilter,
      timespanFilter: "today",
      subjectFilter: "",
      openOrDoneFilter: defaultOpenOrDoneFilter,
    },
    storedFilters
  );

  if (_.isObjectLike(currentFilters.timespanFilter)) {
    currentFilters.timespanFilter.startDate = _.isString(currentFilters.timespanFilter.startDate)
      ? moment(currentFilters.timespanFilter.startDate)
      : currentFilters.timespanFilter.startDate;
    currentFilters.timespanFilter.endDate = _.isString(currentFilters.timespanFilter.endDate)
      ? moment(currentFilters.timespanFilter.endDate)
      : currentFilters.timespanFilter.endDate;
  }

  if (!currentFilters.userFilter) {
    currentFilters.userFilter = !!_.find(backofficeUsers, (user) => user.id === currentUser.id)
      ? currentUser["@id"]
      : "all";
  }
  return currentFilters;
};

export const postProcessFilterActivities = (activities: Activity<BackofficeUser>[], timespanFilter: string) => {
  if (timespanFilter === "overdue") {
    return _.filter(activities, ["activityStatus", ACTIVITY_OVERDUE]);
  }
  return activities;
};

export const getUserActivitiesToday = async (user: BackofficeUser): Promise<Activity[]> => {
  const filters = {
    assignedUser: user["@id"],
    "dueDate[before]": moment().format("YYYY-MM-DD"),
    done: false,
    deleted: false,
    pagination: false,
  };
  let searchParams = buildSearchParams(filters);
  const userActivitiesResult = await ApiClient.get("activities?" + searchParams.toString());
  return userActivitiesResult["hydra:member"];
};

export const buildApiFilters = ({
  productFilter,
  userFilter,
  timespanFilter,
  activityTypeFilter,
  openOrDoneFilter,
  backofficeUsers = [],
  subjectFilter = "",
}: {
  productFilter: string;
  userFilter: string;
  timespanFilter: TimespanFilter;
  activityTypeFilter?: string;
  subjectFilter?: string;
  openOrDoneFilter?: string;
  backofficeUsers?: Array<BackofficeUser>;
}) => {
  let filters: Record<string, boolean | string | string[]> = {
    "exists[assignedUser]": true,
    deleted: false,
    pagination: false,
  };

  if (subjectFilter) {
    filters.subject = subjectFilter;
  }

  switch (productFilter) {
    case "all":
      break;
    case "contractLaw":
      filters["case.lbProductClass[0]"] = "App\\Entity\\Legalbird\\ContractLaw\\EventContract\\EventContract";
      filters["case.lbProductClass[1]"] = "App\\Entity\\Legalbird\\ContractLaw\\MembershipContract\\MembershipContract";
      filters["case.lbProductClass[2]"] = "App\\Entity\\Legalbird\\ContractLaw\\OtherContract\\OtherContract";
      filters["case.lbProductClass[3]"] = "App\\Entity\\Legalbird\\ContractLaw\\ProviderContract\\ProviderContract";
      filters["case.lbProductClass[4]"] = "App\\Entity\\Legalbird\\ContractLaw\\PurchaseContract\\PurchaseContract";
      filters["case.lbProductClass[5]"] = "App\\Entity\\Legalbird\\ContractLaw\\ServiceContract\\ServiceContract";
      filters["case.lbProductClass[6]"] =
        "App\\Entity\\Legalbird\\ContractLaw\\SubscriptionContract\\SubscriptionContract";
      filters["case.lbProductClass[7]"] = "App\\Entity\\Legalbird\\ContractLaw\\TravelContract\\TravelContract";
      filters["case.lbProductClass[8]"] = "App\\Entity\\Legalbird\\ContractLaw\\VehicleContract\\VehicleContract";
      break;
    default:
      filters["case.lbProductClass"] = productFilter;
      break;
  }

  switch (userFilter) {
    case "all":
      break;
    case "accountManager":
      backofficeUsers.forEach((user, index) => {
        if (user.isAccountManager) {
          filters["assignedUser[" + index + "]"] = user["@id"];
        }
      });
      break;
    case "lawyer":
      backofficeUsers.forEach((user, index) => {
        if (user.isLawyer) {
          filters["assignedUser[" + index + "]"] = user["@id"];
        }
      });
      break;
    default:
      filters["assignedUser"] = userFilter;
      break;
  }

  switch (openOrDoneFilter) {
    case "":
      break;
    case "open":
      filters["exists[doneTime]"] = "false";
      break;
    case "done":
      break;
  }

  const timespanFilterParameter = openOrDoneFilter === "done" ? "doneTime" : "dueDate";

  switch (timespanFilter) {
    case "overdue":
      // activities are filtered for actually overdue ones in postProcessFilterActivities
      // using an api filter here is not possible because activityStatus is not a real property, but a calculated one
      filters["done"] = "false";
      break;
    case "today":
      filters[timespanFilterParameter + "[after]"] = moment().format("YYYY-MM-DD");
      filters[timespanFilterParameter + "[strictly_before]"] = moment().add(1, "d").format("YYYY-MM-DD");
      break;
    case "yesterday":
      filters[timespanFilterParameter + "[after]"] = moment().subtract(1, "d").format("YYYY-MM-DD");
      filters[timespanFilterParameter + "[strictly_before]"] = moment().format("YYYY-MM-DD");
      break;
    case "tomorrow":
      filters[timespanFilterParameter + "[after]"] = moment().add(1, "d").format("YYYY-MM-DD");
      filters[timespanFilterParameter + "[strictly_before]"] = moment().add(2, "d").format("YYYY-MM-DD");
      break;
    default:
      if (typeof timespanFilter === "object") {
        filters[timespanFilterParameter + "[after]"] = timespanFilter.startDate.format("YYYY-MM-DD");
        filters[timespanFilterParameter + "[strictly_before]"] = timespanFilter.endDate
          .clone()
          .add(1, "d")
          .format("YYYY-MM-DD");
        break;
      }
      //-- fallback is today
      filters[timespanFilterParameter + "[after]"] = moment().format("YYYY-MM-DD");
      filters[timespanFilterParameter + "[strictly_before]"] = moment().add(1, "d").format("YYYY-MM-DD");
      break;
  }

  if (activityTypeFilter) {
    switch (activityTypeFilter) {
      case "all":
        break;
      case "allDeadlines":
        filters["type"] = deadlineActivityTypes;
        break;
      default:
        filters["type"] = activityTypeFilter;
        break;
    }
  }

  return filters;
};

export const buildSearchParams = (filters: any) => {
  let searchParams = new URLSearchParams();
  _.map(filters, (value, key) => {
    if (_.isArray(value)) {
      //afaik arrays only work for exact SearchFilters!
      _.forEach(value, (arrayValue) => {
        searchParams.append(key + "[]", arrayValue);
      });
    } else {
      searchParams.append(key, value);
    }
  });
  return searchParams;
};

export const canOpenCalendlyAssessmentModal = (
  activity: Activity,
  product: AbstractCase | undefined
): product is AbstractCase => {
  return !!product && !activity.done;
};

export const getAvailableActivityActions = (
  activity: Activity,
  user: BackofficeUser,
  product?: AbstractCase
): AvailableActivityActions => {
  const userIsAdmin = userHasRole(user, Roles.admin);
  const availableActions: AvailableActivityActions = {
    canDelete: false,
    canEdit: false,
    canComplete: !!activity.assignedUser && !activity.done && (!isCalendlyActivity(activity) || !!product),
    canEditActivityNote: false,
  };
  if (user.isExternal) {
    return availableActions;
  }

  if (isCalendlyActivity(activity)) {
    availableActions.canDelete = userIsAdmin;
    availableActions.canEditActivityNote = !userIsAdmin;
    return availableActions;
  }
  availableActions.canDelete = true;
  availableActions.canEdit = true;
  availableActions.canComplete = !!activity.assignedUser && !activity.done;
  return availableActions;
};

export const isCalendlyActivity = (activity: Activity): boolean => {
  return ["calendly_event", "lawyer_counseling_call"].includes(activity.type) && !!activity.calendlyEvent;
};
