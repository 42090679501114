import useForm from "../../hooks/useForm";
import { Box, Button, Grid, Typography } from "@mui/material";
import ValidatorSelect from "../Validator/ValidatorSelect";
import { formValue } from "../../services/formServiceFunctions";
import MenuItem from "@mui/material/MenuItem";
import ValidatorDateField from "../Validator/ValidatorDatePicker";
import _ from "lodash";
import {
  dateFieldDefault,
  isTodayOrFutureDate,
  minStringLength,
  notNoneSelectValidator,
  requiredFieldDefault,
  requiredValidator,
} from "../../services/validationRules";
import React, { ChangeEvent, useState } from "react";
import ValidatorTextField from "../Validator/ValidatorTextField";
import moment from "moment";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import { AbstractCase } from "../../types/AbstractCase";
import { activitiesFilterString } from "../../hooks/useActivities";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { apiPost } from "../../services/Api/apiCall";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { getProductData } from "../../services/Product/ProductService";
import * as status from "../../services/LeadStatus/StatusBasicCase";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import addInquiryToCustomerFormStyles from "./addInquiryToCustomerFormStyles";
import { editorStateToHTML } from "../../services/Editor/editorFunctions";

export default function AddInquiryToCustomerForm({
  handleClose,
  open,
  product,
}: {
  handleClose: Function;
  open: boolean;
  product: AbstractCase;
}) {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [calendlyError, setCalendlyError] = useState(false);
  const initialValues = {
    inquiryType: "",
    deadline: moment().add(14, "d").format("YYYY-MM-DD"),
    overdueConsequences: "",
    inquiryTextAppointment: EditorState.createEmpty(),
    inquiryTextInformation: EditorState.createEmpty(),
    inquiryTextDocuments: EditorState.createEmpty(),
    requestedDocuments: [
      {
        documentName: "",
        mediaObjectId: null,
        temporaryFilesToCombine: [],
      },
    ],
  };

  const onSubmit = async ({ values }: any) => {
    setIsLoading(true);
    if (values.inquiryType === "appointment") {
      const filters = {
        done: false,
        deleted: false,
      };
      const activityCollection = await ApiClient.get(
        "activities?" +
          activitiesFilterString(product, filters) +
          "&type[]=lawyer_counseling_call&type[]=calendly_event"
      );
      if (activityCollection["hydra:totalItems"] > 0) {
        setCalendlyError(true);
        setIsLoading(false);
        return;
      }
    }

    let data = { ...values };

    data.inquiryTextAppointment = editorStateToHTML(data.inquiryTextAppointment, true);
    data.inquiryTextInformation = editorStateToHTML(data.inquiryTextInformation, true);
    data.inquiryTextDocuments = editorStateToHTML(data.inquiryTextDocuments, true);

    data.requestedDocuments = values.requestedDocuments.filter((document: any) => document.documentName.length > 0);
    data.product = product.productClassName;
    data.productId = product.id;
    data.createdBy = currentUser["@id"];
    data.feedbackRecipient = currentUser["@id"];
    await apiPost("/inquiry_to_customers", data);
    await queryClient.invalidateQueries(queryKeys.collections("inquiry_to_customers"));
    setIsLoading(false);
    handleClose();
  };
  const { values, errors, handleChange, handleDateChange, handleBlur, handleSubmit, registerValidators } = useForm({
    initialValues,
    onSubmit,
  });

  const addRequestedDocument = () => {
    let currentRequestedDocuments = [..._.get(values, "requestedDocuments")];
    currentRequestedDocuments.push({
      documentName: "",
      mediaObjectId: null,
      temporaryFilesToCombine: [],
    });
    handleChange({
      target: {
        name: "requestedDocuments",
        value: currentRequestedDocuments,
      },
    });
  };

  const handleChangeDocumentName = (event: ChangeEvent<HTMLInputElement>) => {
    let currentEvent = { ...event };
    currentEvent.target.value = event.target.value.replace(/[^äöüa-z 0-9_-]/gi, "");
    handleChange(currentEvent);
  };

  const documentHelperText = (index: number) => {
    const errorText = _.get(errors, "requestedDocuments[" + index + "].documentName");
    if (!!errorText) {
      return errorText;
    }
    const currentValue = _.get(values, "requestedDocuments[" + index + "].documentName");
    return `${currentValue.length}/100 Zeichen - keine Sonderzeichen`;
  };

  const caseSetBackStageReached =
    product.stage && product.stage >= getProductData(product.productClassName, "setBackCaseStage");

  return (
    <LegalbirdIoModal
      title={"Anfrage stellen"}
      handleClose={handleClose}
      open={open}
      submitButton={
        <>
          {calendlyError && (
            <Box color={"red"} textAlign={"center"}>
              Es gibt bereits einen anstehenden Telefontermin für diesen Fall. Bitte andere Anfrage-Art wählen, oder
              Modal schließen.
            </Box>
          )}
          {Object.keys(errors).length > 0 && (
            <Box color={"red"} textAlign={"center"}>
              Bitte prüfen Sie Ihre oben gemachten Angaben.
            </Box>
          )}
          <ButtonLoading variant={"contained"} isLoading={isLoading} onClick={handleSubmit}>
            Anfrage abschicken
          </ButtonLoading>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ValidatorSelect
            label={"Art der Anfrage"}
            name={"inquiryType"}
            value={formValue(values, "inquiryType")}
            onChange={(e) => {
              setCalendlyError(false);
              handleChange(e);
            }}
            onBlur={handleBlur}
            validators={notNoneSelectValidator}
            error={!!_.get(errors, "inquiryType")}
            helperText={_.get(errors, "inquiryType")}
            registerValidators={registerValidators}
          >
            <MenuItem value={"appointment"} disabled={product.leadStatus < status.STATUS_DATA_COMPLETED}>
              Terminvereinbarung
            </MenuItem>
            <MenuItem value={"informationAndDocuments"} disabled={!caseSetBackStageReached}>
              Informationen und Dokumente
            </MenuItem>
            <MenuItem value={"documents"} disabled={!caseSetBackStageReached}>
              Nur Dokumente
            </MenuItem>
            <MenuItem value={"information"} disabled={!caseSetBackStageReached}>
              Nur Informationen
            </MenuItem>
          </ValidatorSelect>
        </Grid>
        {_.get(values, "inquiryType") === "appointment" && (
          <Grid item xs={12}>
            <Editor
              toolbar={{
                options: ["inline", "list", "history", "link"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
                textAlign: {
                  inDropdown: true,
                },
              }}
              stripPastedStyles
              placeholder={"z.B. Ich möchte das neueste Schreiben mit Ihnen besprechen."}
              editorState={formValue(values, "inquiryTextAppointment")}
              onEditorStateChange={(editorState) =>
                handleChange({
                  target: {
                    name: "inquiryTextAppointment",
                    value: editorState,
                  },
                })
              }
              localization={{ locale: "de" }}
              editorStyle={addInquiryToCustomerFormStyles.editorStyle}
            />
          </Grid>
        )}
        {["information", "informationAndDocuments"].includes(_.get(values, "inquiryType")) && (
          <Grid item xs={12}>
            <Editor
              toolbar={{
                options: ["inline", "list", "history", "link"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
                textAlign: {
                  inDropdown: true,
                },
              }}
              stripPastedStyles
              placeholder={"Benötigte Informationen"}
              editorState={formValue(values, "inquiryTextInformation")}
              onEditorStateChange={(editorState) =>
                handleChange({
                  target: {
                    name: "inquiryTextInformation",
                    value: editorState,
                  },
                })
              }
              localization={{ locale: "de" }}
              editorStyle={addInquiryToCustomerFormStyles.editorStyle}
            />
          </Grid>
        )}
        {values.inquiryType === "informationAndDocuments" && (
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center" }} variant="h3">
              Benötigte Dokumente
            </Typography>
          </Grid>
        )}
        {["documents", "informationAndDocuments"].includes(_.get(values, "inquiryType")) && (
          <>
            <Grid item xs={12}>
              <Editor
                toolbar={{
                  options: ["inline", "list", "history", "link"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                  list: {
                    options: ["unordered", "ordered"],
                  },
                  textAlign: {
                    inDropdown: true,
                  },
                }}
                stripPastedStyles
                placeholder={"Erklärung zu Dokumenten (optional)"}
                editorState={formValue(values, "inquiryTextDocuments")}
                onEditorStateChange={(editorState) =>
                  handleChange({
                    target: {
                      name: "inquiryTextDocuments",
                      value: editorState,
                    },
                  })
                }
                localization={{ locale: "de" }}
                editorStyle={addInquiryToCustomerFormStyles.editorStyle}
              />
            </Grid>
            {_.get(values, "requestedDocuments").map((document: any, index: number) => {
              let validators: any[] = [
                {
                  validator: minStringLength,
                  params: [3],
                  message:
                    "Bitte geben Sie hier mindestens 3 Zeichen ein, oder wählen Sie eine andere Art der Anfrage aus.",
                },
              ];
              if (index === 0) {
                validators.push(requiredValidator("Bitte geben Sie hier den Namen für das benötigte Dokument an."));
              }
              return (
                <Grid item xs={12} key={index}>
                  <ValidatorTextField
                    label={"Name Dokument " + (index + 1)}
                    name={"requestedDocuments[" + index + "].documentName"}
                    value={formValue(values, "requestedDocuments[" + index + "].documentName")}
                    onChange={handleChangeDocumentName}
                    onBlur={handleBlur}
                    registerValidators={registerValidators}
                    validators={validators}
                    error={!!_.get(errors, "requestedDocuments[" + index + "].documentName")}
                    helperText={documentHelperText(index)}
                    margin={"normal"}
                    fullWidth
                    isHighlighted={false}
                    initialDependentValidationFields={[]}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              );
            })}
            {_.get(values, "requestedDocuments").length < 10 && (
              <Grid item xs={12}>
                <Button variant={"text"} onClick={addRequestedDocument}>
                  Weiteres Dokument
                </Button>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} sm={6}>
          <ValidatorDateField
            format="DD.MM.YYYY"
            label={"Fällig am"}
            name={"deadline"}
            onChange={(date: any) => handleDateChange(date, "deadline")}
            handleBlur={handleBlur}
            value={formValue(values, "deadline")}
            error={!!_.get(errors, "deadline")}
            helperText={
              _.get(errors, "deadline") || "3 Tage vor Ablauf der Frist wird automatisch beim Mandanten nachgefasst"
            }
            registerValidators={registerValidators}
            validators={[
              ...requiredFieldDefault,
              ...dateFieldDefault,
              {
                validator: isTodayOrFutureDate,
                message: "Es kann keine Anfrage in die Vergangenheit gestellt werden.",
              },
            ]}
            shouldDisableDate={(date: any) => {
              return !isTodayOrFutureDate(date);
            }}
            dependentValidationFields={[]}
            isHighlighted={false}
            withSkipWeeksToolbar={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorSelect
            label={"Bei fehlender Rückmeldung"}
            name={"overdueConsequences"}
            value={formValue(values, "overdueConsequences")}
            onChange={handleChange}
            onBlur={handleBlur}
            validators={notNoneSelectValidator}
            error={!!_.get(errors, "overdueConsequences")}
            helperText={_.get(errors, "overdueConsequences")}
            registerValidators={registerValidators}
          >
            <MenuItem value={"actionRequired"}>Weitere Bearbeitung notwendig</MenuItem>
            <MenuItem value={"noFurtherActionRequired"}>Keine weitere Bearbeitung notwendig</MenuItem>
          </ValidatorSelect>
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
}
