import _ from "lodash";
import {
  getProductData,
  getProductHeadline,
  getProductLabel,
  getProductName,
  getStageList,
  isContractProduct,
  isDocumentProduct,
} from "../Product/ProductService";
import { ACTIVITY_FUTURE, ACTIVITY_OVERDUE, ACTIVITY_TODAY, NO_OPEN_ACTIVITY } from "../Stages/Stages";
import moment from "moment";
import { STAGE_INCOMPLETE } from "../Stages/StagesTraffic";
import * as documentStages from "../Stages/StagesDocument";
import { stageMetaDataDocument } from "../Stages/StagesDocument";
import * as contractStages from "../Stages/StagesCase";
import { stageMetaDataCase } from "../Stages/StagesCase";
import { translate } from "../Translations/translatorService";
import { BackofficeUser } from "../../types/BackofficeUser";
import { Hit } from "@algolia/client-search";

const getAccountManagersRefinable = (accountManagers: Array<BackofficeUser>) =>
  _.map(accountManagers, (accountManager) => {
    return {
      label: accountManager.person.fullname,
      value: accountManager.id + "",
    };
  });

const getLawyersRefinable = (lawyers: Array<BackofficeUser>) =>
  _.map(lawyers, (lawyer) => {
    return {
      label: lawyer.person.fullname,
      value: lawyer.id + "",
    };
  });

const partnersRefinable = [
  {
    label: "Advocard",
    value: "advocard",
  },
  {
    label: "Arag",
    value: "arag",
  },
  {
    label: "HUK",
    value: "hukCoburg",
  },
  {
    label: "Roland",
    value: "roland",
  },
  {
    label: "WBS Rechtsanwälte",
    value: "wbs",
  },
  {
    label: "Blitzereinspruch",
    value: "blitzereinspruch",
  },
  {
    label: "Check24",
    value: "check24",
  },
];

export const getBoardHeadline = (productUrlPart: string) => {
  switch (productUrlPart) {
    case "dokumente":
    case "vertragsrecht":
      return _.upperFirst(productUrlPart);
    case "mietrecht":
      return _.upperFirst(productUrlPart);
    default:
      return getProductHeadline(productUrlPart);
  }
};

export const getStagesForBoard = (productUrlPart: string) => {
  let stages;
  switch (productUrlPart) {
    case "dokumente":
      stages = stageMetaDataDocument;
      break;
    case "vertragsrecht":
      stages = stageMetaDataCase;
      break;
    case "mietrecht":
      stages = stageMetaDataCase;
      break;
    default:
      stages = getStageList(getProductName(productUrlPart));
      break;
  }
  return _.keyBy(_.filter(stages, "showInBoard"), "stage");
};

export const getRefinements = ({
  accountManagers,
  lawyers,
}: {
  accountManagers: Array<BackofficeUser>;
  lawyers: Array<BackofficeUser>;
}) => {
  const lawyersRefinable = getLawyersRefinable(lawyers);
  const accountManagersRefinable = getAccountManagersRefinable(accountManagers);

  const contractLawCaseCategoryRefinable = [
    {
      label: "Forderung durchsetzen",
      value: "enforceClaim",
    },
    {
      label: "Forderung abwehren",
      value: "defendClaim",
    },
    {
      label: "Erstberatung",
      value: "advisory",
    },
  ];

  const contractLawRefinements = [
    {
      attribute: {
        name: "responsibleLawyer",
        label: "Anwalt",
      },
      staticItems: lawyersRefinable,
    },
    {
      attribute: {
        name: "accountManager",
        label: "Kundenberater",
      },
      staticItems: accountManagersRefinable,
    },
    {
      attribute: {
        name: "caseCategory",
        label: "Kategorie",
      },
      staticItems: contractLawCaseCategoryRefinable,
    },
    {
      attribute: {
        name: "product",
        label: "Vertragsart",
      },
      staticItems: [
        {
          label: "Veranstaltung",
          value: "eventContract",
        },
        {
          label: "Mitgliedschaft",
          value: "membershipContract",
        },
        {
          label: "Sonstiger Vertrag",
          value: "otherContract",
        },
        {
          label: "Versorgervertrag",
          value: "providerContract",
        },
        {
          label: "Kaufvertrag",
          value: "purchaseContract",
        },
        {
          label: "Dienstleistungsvertrag",
          value: "serviceContract",
        },
        {
          label: "Abonnement",
          value: "subscriptionContract",
        },
        {
          label: "Reisevertrag",
          value: "travelContract",
        },
        {
          label: "Fahrzeugkaufvertrag",
          value: "vehicleContract",
        },
      ],
    },
    {
      attribute: {
        name: "partner",
        label: "Partner",
      },
      staticItems: partnersRefinable,
    },
  ];

  return {
    scheidung: [
      {
        attribute: {
          name: "paymentType",
          label: "Zahlart",
        },
        staticItems: [
          { label: "VKH", value: "processCostSupport" },
          { label: "Rechnung", value: "private" },
          { label: "Raten", value: "installment" },
        ],
      },
      {
        attribute: {
          name: "responsibleLawyer",
          label: "Anwalt",
        },
        staticItems: lawyersRefinable,
      },
      {
        attribute: {
          name: "accountManager",
          label: "Kundenberater",
        },
        staticItems: accountManagersRefinable,
      },
      {
        attribute: {
          name: "partner",
          label: "Partner",
        },
        staticItems: partnersRefinable,
      },
    ],
    abfindung: [
      {
        attribute: {
          name: "paymentType",
          label: "Zahlart",
        },
        staticItems: [
          { label: "NWNF", value: "nwnf" },
          { label: "Rechnung", value: "private" },
          { label: "RSV", value: "insurance" },
        ],
      },
      {
        attribute: {
          name: "responsibleLawyer",
          label: "Anwalt",
        },
        staticItems: lawyersRefinable,
      },
      {
        attribute: {
          name: "accountManager",
          label: "Kundenberater",
        },
        staticItems: accountManagersRefinable,
      },
    ],
    verkehr: [
      {
        attribute: {
          name: "paymentType",
          label: "Zahlart",
        },
        staticItems: [
          { label: "Rechnung", value: "private" },
          { label: "RSV", value: "insurance" },
          { label: "ARAG", value: "backwards_insurance" },
        ],
      },
      {
        attribute: {
          name: "responsibleLawyer",
          label: "Anwalt",
        },
        staticItems: lawyersRefinable,
      },
      {
        attribute: {
          name: "accountManager",
          label: "Kundenberater",
        },
        staticItems: accountManagersRefinable,
      },
      {
        attribute: {
          name: "partner",
          label: "Partner",
        },
        staticItems: partnersRefinable,
      },
    ],
    kurzarbeit: [
      {
        attribute: {
          name: "responsibleLawyer",
          label: "Anwalt",
        },
        staticItems: lawyersRefinable,
      },
      {
        attribute: {
          name: "accountManager",
          label: "Kundenberater",
        },
        staticItems: accountManagersRefinable,
      },
      {
        attribute: {
          name: "partner",
          label: "Partner",
        },
        staticItems: partnersRefinable,
      },
    ],
    dokumente: [
      {
        attribute: {
          name: "product",
          label: "Dokumententyp",
        },
        staticItems: [
          {
            label: "Patientenverfügung",
            value: "patientDecree",
          },
          {
            label: "Vorsorgevollmacht",
            value: "powerOfAttorney",
          },
          {
            label: "Tod und Erbe",
            value: "death",
          },
          {
            label: "Unterhalt",
            value: "alimony",
          },
        ],
      },
      {
        attribute: {
          name: "partner",
          label: "Partner",
        },
        staticItems: partnersRefinable,
      },
    ],
    vertragsrecht: contractLawRefinements,
    datenweitergabe: [
      {
        attribute: {
          name: "responsibleLawyer",
          label: "Anwalt",
        },
        staticItems: lawyersRefinable,
      },
      {
        attribute: {
          name: "accountManager",
          label: "Kundenberater",
        },
        staticItems: accountManagersRefinable,
      },
      {
        attribute: {
          name: "caseCategory",
          label: "Kategorie",
        },
        staticItems: contractLawCaseCategoryRefinable,
      },
      {
        attribute: {
          name: "partner",
          label: "Partner",
        },
        staticItems: partnersRefinable,
      },
    ],
    familie: [
      {
        attribute: {
          name: "responsibleLawyer",
          label: "Anwalt",
        },
        staticItems: lawyersRefinable,
      },
      {
        attribute: {
          name: "accountManager",
          label: "Kundenberater",
        },
        staticItems: accountManagersRefinable,
      },
      {
        attribute: {
          name: "partner",
          label: "Partner",
        },
        staticItems: partnersRefinable,
      },
    ],
    mietrecht: [
      {
        attribute: {
          name: "responsibleLawyer",
          label: "Anwalt",
        },
        staticItems: lawyersRefinable,
      },
      {
        attribute: {
          name: "accountManager",
          label: "Kundenberater",
        },
        staticItems: accountManagersRefinable,
      },
      {
        attribute: {
          name: "caseCategory",
          label: "Kategorie",
        },
        staticItems: contractLawCaseCategoryRefinable,
      },
      {
        attribute: {
          name: "partner",
          label: "Partner",
        },
        staticItems: partnersRefinable,
      },
    ],
  };
};

export const getLabelFromHit = (hit: Hit<any>) => {
  if (!hit.gender || !hit.givenName || !hit.familyName) {
    return hit.email;
  }
  return `${translate("person.gender.values." + hit.gender)} ${hit.givenName} ${hit.familyName}`;
};

export const getActivityInfo = ({ nextActivity }: { nextActivity: string }) => {
  if (!nextActivity || nextActivity === "0000-00-00 00:00:00") {
    return NO_OPEN_ACTIVITY;
  }

  if (moment(nextActivity).isBefore(moment())) {
    return ACTIVITY_OVERDUE;
  }

  if (moment(nextActivity).isSame(moment(), "day")) {
    return ACTIVITY_TODAY;
  }

  return ACTIVITY_FUTURE;
};

export const translatePaymentType = ({
  paymentType,
  product,
  stage,
}: {
  paymentType: string;
  product: string;
  stage: number;
}) => {
  switch (product) {
    case "divorce":
      switch (paymentType) {
        case "processCostSupport":
        case "process_cost_support":
          return "VKH";
        case "installment":
          return "Raten";
        case "private":
          return "Rechnung";
        case "insurance":
          return "RSV";
        default:
          return paymentType || "N/A";
      }
    case "settlement":
      switch (paymentType) {
        case "private":
          return "SZ";
        case "insurance":
          return "RSV";
        case "nwnf":
          return "NWNF";
        default:
          return paymentType || "N/A";
      }
    case "traffic":
      switch (paymentType) {
        case "private":
          return "SZ";
        case "insurance":
          return "RSV";
        case "backwards_insurance":
          return "ARAG Verso";
        default:
          if (!paymentType) {
            return stage < STAGE_INCOMPLETE ? "N/A" : "keine RSV";
          }
          return paymentType;
      }
    case "familyLawCase": {
      switch (paymentType) {
        default:
        case "insurance":
          return "RSV";
      }
    }
    default:
      return paymentType || "-";
  }
};

export const getBadgeContent = (hit: Hit<any>) => {
  const productClassName = _.camelCase(hit.product);
  if (isDocumentProduct(productClassName)) {
    return getProductLabel(productClassName);
  }
  if (isContractProduct(productClassName)) {
    return _.upperFirst(getProductData(productClassName, "urlPart"));
  }
  return translatePaymentType(hit);
};

export const getFilterString = (productUrlPart: string, caseTypeUrlPart: string) => {
  const caseType = _.get({ verloren: "lost", gewonnen: "won", inaktiv: "inactive" }, caseTypeUrlPart, "open");
  let productFilter;
  let stage;
  switch (productUrlPart) {
    case "dokumente":
      productFilter = `product:patientDecree OR product:powerOfAttorney OR product:death OR product:alimony`;
      stage = documentStages.STAGE_INCOMPLETE;
      break;
    case "vertragsrecht":
      productFilter =
        "product:eventContract OR product:membershipContract OR product:otherContract" +
        " OR product:providerContract OR product:purchaseContract OR product:serviceContract" +
        " OR product:subscriptionContract OR product:travelContract OR product:vehicleContract";
      stage = contractStages.STAGE_DATA_COMPLETED;
      break;
    case "mietrecht":
      productFilter = "product:rentalContract";
      stage = contractStages.STAGE_DATA_COMPLETED;
      break;
    default:
      const productClassName = getProductName(productUrlPart);
      stage = getProductData(productClassName, "minimumCaseStage");
      productFilter = `product:${productClassName} OR product:${_.snakeCase(productClassName)}`;
      break;
  }
  return `(${productFilter}) AND caseType:${caseType} AND stage>=${stage}`;
};
