import { AbstractCase } from "../../../types/AbstractCase";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import CaseInquiryToCustomer from "./CaseInquiryToCustomer";
import { HydraResult } from "../../../types/HydraResult";
import { InquiryToCustomer } from "../../../types/InquiryToCustomer";
import moment from "moment";

type CaseInquiriesToCustomerProps = {
  product: AbstractCase;
};
export default function CaseInquiriesToCustomer({ product }: CaseInquiriesToCustomerProps) {
  let inquiryToCustomerFilter = {
    productId: product.id,
    "status[lt]": 40,
  };
  const { data: inquiryToCustomerCollection } = useQuery<HydraResult<InquiryToCustomer>>(
    queryKeys.collection("inquiry_to_customers", inquiryToCustomerFilter),
    () => fetchCollection("inquiry_to_customers", inquiryToCustomerFilter),
    {
      enabled: !!product,
    }
  );

  if (!inquiryToCustomerCollection || inquiryToCustomerCollection["hydra:totalItems"] === 0) {
    return null;
  }

  return (
    <>
      {inquiryToCustomerCollection["hydra:member"]
        .sort((a, b) => (moment(a.deadline).isAfter(moment(b.deadline)) ? 1 : -1))
        .map((inquiryToCustomer) => (
          <CaseInquiryToCustomer key={inquiryToCustomer.id} inquiryToCustomer={inquiryToCustomer} />
        ))}
    </>
  );
}
