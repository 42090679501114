import React from "react";
import { Avatar, Box, Stack } from "@mui/material";
import { Activity } from "../../types/Activity";
import { KeyboardArrowRight, LocalPhoneOutlined } from "@mui/icons-material";
import { getCaseLink, getProductData, getProductNameBy } from "../../services/Product/ProductService";
import { Link } from "react-router-dom";
import { useCurrentUser } from "../../provider/CurrentUserProvider";

type AdvisoryCallWidgetProps = {
  advisoryCall: Activity;
};

export default function AdvisoryCallWidget({ advisoryCall }: AdvisoryCallWidgetProps) {
  const currentUser = useCurrentUser();
  const productClassName = getProductNameBy("lbProductClass", advisoryCall.case?.lbProductClass);
  return (
    <Box
      component={Link}
      sx={{ color: (theme: any) => theme.palette.text.primary }}
      to={getCaseLink(
        { productClassName, id: advisoryCall.case?.lbProductId },
        currentUser.isExternal ? "/extern" : ""
      )}
    >
      <Stack sx={{ borderTop: "1px solid #ddd", padding: "15px 0" }} direction={"row"} alignContent={"center"}>
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main, marginRight: "1.5rem" }}>
          <LocalPhoneOutlined />
        </Avatar>
        <Box>
          <strong>
            {advisoryCall.case?.label}
            {currentUser.isLawyer && " - " + advisoryCall.subject}
          </strong>
          <br />
          Heute {advisoryCall.dueTime !== "allDay" ? "um " + advisoryCall.dueTime + " Uhr" : null} -{" "}
          {getProductData(productClassName, "generalLabel")}
        </Box>
        <Box sx={{ marginLeft: "auto", alignSelf: "flex-end" }}>
          <KeyboardArrowRight color={"primary"} />
        </Box>
      </Stack>
    </Box>
  );
}
