import React from "react";
import * as trafficStages from "../../services/Stages/StagesTraffic";
import InvestigationAssessmentForm from "./InvestigationAssessmentForm";
import { AbstractCase } from "../../types/AbstractCase";

type InvestigationAssessmentProps = {
  product: AbstractCase;
};

const InvestigationAssessment = ({ product }: InvestigationAssessmentProps) => {
  if (
    product.productClassName !== "traffic" ||
    product.stage! < trafficStages.STAGE_ACCESS_TO_RECORDS ||
    product.investigation === null
  ) {
    return null;
  }

  return <InvestigationAssessmentForm />;
};

export default InvestigationAssessment;
