import React, { useState } from "react";
import TemplateEditor from "./TemplateEditor";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";

type CreateNewTemplateProps = {
  refreshTemplateList?: Function;
  initialProduct?: string;
  type: string;
};

const CreateNewTemplate = ({
  refreshTemplateList = () => {},
  initialProduct = "divorce",
  type,
}: CreateNewTemplateProps) => {
  const [mailEditorOpen, setMailEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitCallback = async () => {
    setIsLoading(true);
    await refreshTemplateList();
    setIsLoading(false);
    setMailEditorOpen(false);
  };

  return (
    <>
      <ButtonLoading isLoading={isLoading} onClick={() => setMailEditorOpen(true)}>
        Neue Vorlage anlegen
      </ButtonLoading>
      <LegalbirdIoModal handleClose={() => setMailEditorOpen(false)} open={mailEditorOpen} title={"Vorlage"}>
        <TemplateEditor
          onSubmitCallback={onSubmitCallback}
          initialProduct={initialProduct}
          type={type}
          withSubject={type === "email"}
        />
      </LegalbirdIoModal>
    </>
  );
};

export default CreateNewTemplate;
