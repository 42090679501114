/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

// Please keep ordered alphabetically (execpt "custom")
export const objectivesLawFirm = [
  { value: "acknowledgeClaim" },
  { value: "cancellationConfirmation" },
  { value: "cancellationObsoleteConfirmation" },
  { value: "compensation" },
  { value: "contestation" },
  { value: "contractNotMaterialized" },
  { value: "contractNotMaterializedPlusAppeal" },
  { value: "dataDeletion" },
  { value: "fulfillment" },
  { value: "reduction" },
  { value: "refund" },
  { value: "removalOfDefects" },
  { value: "rentReduction" },
  { value: "repair" },
  { value: "replacement" },
  { value: "revocation" },
  { value: "specialTermination" },
  { value: "unlawfulSchufaPositiveData" },
  { value: "willfulDeceptionContestation" },
  { value: "willfulDeceptionContestationTerminationRevocation" },
  { value: "withdrawal" },
  { value: "custom" },
];
